<template>
    <img :src="image_link" class="" />
</template>

<script>
export default {
    name: 'ImageComponent',
    props: {
        src: String,
        width: String,
        height: String,
    },
    data() {
        return {
            image_link: ''
        }
    },
    components: {},
    mounted() {
        if(!this.src){
            this.image_link = '/assets/img/img_broken.png'
        }else{
            this.image_link = this.src
        }
    },
    methods: {
    }
}
</script>

<style >
</style>
