<template>
<div id="wrapper">

    <auth-styles pageNameMain="Login"></auth-styles>
    <auth-header></auth-header>
    <div class="" id="content" data-global="true" data-path="global.0.0">

        <section data-section-id="1" data-share="" data-category="content" data-component-id="fdca0d23_03_awz" class="relative overflow-hidden" data-path="0">
            <div class="relative container px-4 mx-auto" data-path="0.2">
                <div class="text-center mb-18" data-path="0.2.0">
                    <span class="inline-block py-1 px-3 mb-4 text-xs font-semibold text-orange-900 bg-orange-50 rounded-full" data-config-id="auto-txt-1-3" data-path="0.2.0.0"></span>
                    <h1 class="font-heading text-5xl xs:text-6xl md:text-7xl font-bold text-gray-900 mb-12" data-path="0.2.0.1">
                        <span data-config-id="auto-txt-2-3" data-path="0.2.0.1.0">Terms</span>
                        <span class="block font-serif italic" data-config-id="auto-txt-3-3" data-path="0.2.0.1.1">and Conditions</span>
                    </h1>
                </div>
                <div class="max-w-lg lg:max-w-3xl xl:max-w-5xl mx-auto" data-path="0.2.1">
                    <div class="flex flex-wrap -mx-4" data-path="0.2.1.0">
                        <div class="w-full px-4 mb-12 lg:mb-0" data-path="0.2.1.0.0">
                            <div>
                                <h4>Terms and Conditions and Users' Privacy for Vitisocial.com Social Media App Usage</h4>
                                <p>Welcome to Vitisocial.com! These Terms and Conditions (hereinafter, "Terms") apply to your use of our social media app (hereinafter, "App") as a user. By accessing or using our app, you agree to be bound by these terms. If you do not agree to these terms, you may not use our app.</p>
                                
                                <h5 class="mt-3">User Conduct</h5>
                                <p>You agree to use our app only for lawful purposes and in a manner that does not infringe on the rights of others. You may not post or transmit any content that is false, defamatory, obscene, or otherwise offensive. Any content that is illegal or that violates intellectual property rights is also prohibited.</p>

                                <h5 class="mt-3">Account Registration</h5>
                                <p>To use our app, you will need to create an account. You agree to provide accurate, current, and complete information during the registration process. You are responsible for maintaining the confidentiality of your account details, including your password. You agree to notify us immediately of any unauthorized use of your account.</p>

                                <h5 class="mt-3">Content Ownership</h5>
                                <p>You own the content that you post on our app. By posting content, you give us the right to display and use the content in connection with our app. You grant us a non-exclusive, transferable, sub-licensable, royalty-free, worldwide license to use any content that you post on our app for promotional and advertising purposes.</p>

                                <h5 class="mt-3">Intellectual Property</h5>
                                <p>Vitisocial.com and its contents are protected by copyright and trademark laws. You may not copy, modify, distribute, or reproduce any content from our app without our prior written consent. All trademarks, service marks, logos, and trade names that appear on our app are the property of their respective owners.</p>

                                <h5 class="mt-3">Users' Privacy</h5>
                                <p>We collect and use your personal information in accordance with our privacy policy. Our Privacy Policy outlines how we collect, use, and disclose your information when you use our app. By using our app, you acknowledge and agree to our privacy policy.</p>

                                <h5 class="mt-3">Third-Party Advertisements</h5>
                                <p>Our app may display third-party advertisements. We are not responsible for the content of any third-party advertisements that appear on our app. Any interactions that you have with third-party advertisements are solely between you and the advertiser.</p>

                                <h5 class="mt-3">Termination</h5>
                                <p>We reserve the right to terminate your use of our app at any time and for any reason, without notice or liability. If we terminate your account, you may lose access to any content that you have posted on our app.</p>

                                <h5 class="mt-3">Disclaimer of Warranties</h5>
                                <p>We make no warranties or representations about the accuracy, reliability, or completeness of the content on our app. We do not guarantee that our app will meet your requirements or operate uninterrupted or error-free.</p>

                                <h5 class="mt-3">Limitation of Liability</h5>
                                <p>We are not liable for any damages, including indirect, incidental, or consequential damages, arising out of your use of our app. We are not responsible for any lost profits, lost data, or other losses that result from your use of our app.</p>

                                <h5 class="mt-3">Governing Law</h5>
                                <p>These Terms are governed by the laws of the jurisdiction in which we are located. Any dispute arising out of or relating to these Terms shall be resolved in the courts of that jurisdiction.</p>

                                <h5 class="mt-3">Defamation and Lost Data</h5>
                                <p>We are not responsible for any defamatory content that is posted on our app by users. While we may moderate content, we have no legal obligation to do so, and we are not liable for any damages, including emotional distress or reputational harm, that may arise from defamatory content posted on our App.</p>
                                <p>We are not liable for any lost data, including data that may result from errors, omissions, or interruptions on our app. We recommend that you regularly backup your data and take other necessary precautions to protect your data.</p>

                                <h5 class="mt-3">Copyright Infringement</h5>
                                <p>We respect the intellectual property rights of others and expect our users to do the same. If you believe that your copyrighted work has been infringed on our app, please follow our Copyright Infringement Notice Policy.</p>
                                <p>We are not liable for any copyright infringement by our users. While we may take appropriate actions to remove infringing content from our App, we have no legal obligation to do so, and we are not liable for any damages, including lost profits or statutory damages, that may arise from copyright infringement by our users.</p>

                                <h5 class="mt-3">Changes to Terms and Users' Privacy</h5>
                                <p>We reserve the right to modify these Terms and Users' Privacy at any time without prior notice. Your continued use of our app after we make changes to these Terms and Users' Privacy constitutes your acceptance of the modified terms.</p> 
                                
                            </div>
                            <!-- <div class="" data-path="0.2.1.0.0.0">
                                <h4>Terms and Conditions and Users' Privacy for vitisocial.com Social Media App Usage</h4>
                                <p>Welcome to vitisocial.com! These Terms and Conditions (hereinafter, "Terms") apply to your use of our social media app (hereinafter, "App") as a user. By accessing or using our App, you agree to be bound by these Terms. If you do not agree to these Terms, you may not use our App.</p>
                                <p>1. User Conduct</p>
                                <p>You agree to use our App only for lawful purposes and in a manner that does not infringe on the rights of others. You may not post or transmit any content that is false, defamatory, obscene, or otherwise offensive. Any content that is illegal or that violates intellectual property rights is also prohibited.</p>
                                <p>2. Account Registration</p>
                                <p>To use our App, you will need to create an account. You agree to provide accurate, current, and complete information during the registration process. You are responsible for maintaining the confidentiality of your account details, including your password. You agree to notify us immediately of any unauthorized use of your account. </p>
                                <p>3. Content Ownership</p>
                                <p>You own the content that you post on our App. By posting content, you give us the right to display and use the content in connection with our App. You grant us a non-exclusive, transferable, sub-licensable, royalty-free, worldwide license to use any content that you post on our App for promotional and advertising purposes.</p>
                                <p>4. Intellectual Property</p>
                                <p>Our App and its contents are protected by copyright and trademark laws. You may not copy, modify, distribute, or reproduce any content from our App without our prior written consent. All trademarks, service marks, logos, and trade names that appear on our App are the property of their respective owners.</p>
                                <p>5. Users' Privacy</p>
                                <p>We collect and use your personal information in accordance with our Privacy Policy. Our Privacy Policy outlines how we collect, use, and disclose your information when you use our App. By using our App, you acknowledge and agree to our Privacy Policy.</p>
                                <p>6. Third-Party Advertisements</p>
                                <p>Our App may display third-party advertisements. We are not responsible for the content of any third-party advertisements that appear on our App. Any interactions that you have with third-party advertisements are solely between you and the advertiser.</p>
                                <p>7. Termination</p>
                                <p>We reserve the right to terminate your use of our App at any time and for any reason, without notice or liability. If we terminate your account, you may lose access to any content that you have posted on our App.</p>
                                <p>8. Disclaimer of Warranties</p>
                                <p>We make no warranties or representations about the accuracy, reliability, or completeness of the content on our App. We do not guarantee that our App will meet your requirements or operate uninterrupted or error-free.</p>
                                <p>9. Limitation of Liability</p>
                                <p>We are not liable for any damages, including indirect, incidental, or consequential damages, arising out of your use of our App. We are not responsible for any lost profits, lost data, or other losses that result from your use of our App.</p>
                                <p>10. Governing Law</p>
                                <p>These Terms are governed by the laws of the jurisdiction in which we are located. Any dispute arising out of or relating to these Terms shall be resolved in the courts of that jurisdiction.</p>
                                <p>11. Defamation and Lost Data</p>
                                <p>We are not responsible for any defamatory content that is posted on our App by users. While we may moderate content, we have no legal obligation to do so, and we are not liable for any damages, including emotional distress or reputational harm, that may arise from defamatory content posted on our App.</p>
                                <p>We are not liable for any lost data, including data that may result from errors, omissions, or interruptions on our App. We recommend that you regularly backup your data and take other necessary precautions to protect your data.</p>
                                <p>12. Copyright Infringement</p>
                                <p>We respect the intellectual property rights of others and expect our users to do the same. If you believe that your copyrighted work has been infringed on our App, please follow our Copyright Infringement Notice Policy. </p>
                                <p>We are not liable for any copyright infringement by our users. While we may take appropriate actions to remove infringing content from our App, we have no legal obligation to do so, and we are not liable for any damages, including lost profits or statutory damages, that may arise from copyright infringement by our users.</p>
                                <p>13. Changes to Terms and Users' Privacy</p>
                                <p>We reserve the right to modify these Terms and Users' Privacy at any time without prior notice. Your continued use of our App after we make changes to these Terms and Users' Privacy constitutes your acceptance of the modified terms.</p>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>
</template>

<script>
import {
    ApiService
} from "@/services/api.service";
import AuthStyles from '@/include/Auth/AuthStyles'
import AuthHeader from '@/include/Auth/AuthHeader'

export default {
    name: 'HomePage',
    components: {
        AuthStyles,
        AuthHeader,
    },

    data() {
        return {
            msg: 'Welcome to Index!!!',
        }
    },
    mounted: function () {},

    methods: {}
}
</script>

<style lang="scss" scoped>
.some-modal-content {
    min-width: 400px;
    padding: 25px;

    .buttons button {
        padding: 10px;
        margin: 10px;
    }
}

.single_story_div {
    width: 100%;
    height: 100%;
    position: absolute;
    padding: 10px;
    color: #fff;
    background-color: #333;
}

input[type=text] {
    -webkit-transition: all .2s ease-out;
    -moz-transition: all .2s ease-out;
    -ms-transition: all .2s ease-out;
    -o-transition: all .2s ease-out;
    transition: all .2s ease-out
}

input[type=text]:focus {
    -webkit-transition: all .2s ease-out;
    -moz-transition: all .2s ease-out;
    -ms-transition: all .2s ease-out;
    -o-transition: all .2s ease-out;
    transition: all .2s ease-out
}

@media (max-width: 768px) {

    .user_story a:nth-child(3),
    .user_story a:nth-child(4) {
        display: block;
    }
}
</style>
