<template>
<!-- story-preview -->
<div class="story-prev">
    <div class="story-sidebar uk-animation-slide-left-medium">
        <div class="md:flex justify-between items-center py-2 hidden">
            <h3 class="text-2xl font-semibold">All Story</h3>
        </div>

        <div class="story-sidebar-scrollbar" data-simplebar="">
            <h3 class="text-lg font-medium">Your Story</h3>

            <a uk-toggle="target: #save-status-modal" class="flex space-x-4 items-center hover:bg-gray-100 md:my-2 py-2 rounded-lg hover:text-gray-700 mouse-pointer">
                <svg class="w-12 h-12 p-3 bg-gray-200 rounded-full text-blue-500 ml-1" fill="none" stroke="currentColor" viewbox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path>
                </svg>
                <div class="flex-1">
                    <div class="text-lg font-semibold">Create a story</div>
                    <div class="text-sm -mt-0.5">Share a photo or write something.</div>
                </div>
            </a>
            <div class="story-users-list" >

                <template v-for="story in my_story" v-if="story.items.length > 0">
                    <a href="javascript:;" v-on:click="setStoryContent(story)"  uk-toggle="target: #view-status-modal">
                        <div class="story-media">
                            <img v-if="currentUser.profile_photo" :src="currentUser.profile_photo" alt="">
                            <img v-else :src="defaultImageUrl()" alt="">
                        </div>
                        <div class="story-text d-block">
                            <div class="story-username">
                                {{ story.name }}
                                <verified-badge v-if="story.user && story.user.verified" size="small"></verified-badge>
                            </div>
                            <p>
                                <span class="story-count text-black"> {{ unseenStoryItems(currentUser.id, story.items) }} new </span>
                                <span class="story-time"> {{ timeAgo(story.created_at) }} ago</span>
                            </p>
                        </div>
                    </a>
                    <!-- <a href="javascript:;" v-on:click="setMyStoryContent(story)" uk-toggle="target: #view-status-stat-modal">users who viewed your status</a> -->
                </template>
            </div>

            <h3 class="text-lg font-medium lg:mt-3 mt-1">Friends Story</h3>

            <div class="story-users-list" uk-switcher-old="connect: #story_slider ; toggle: > * ; animation: uk-animation-slide-right-medium, uk-animation-slide-left-medium ">

                <template v-for="story in stories" v-if="story.items.length > 0 && story.user_id != currentUser.id">
                    <a href="javascript:;" v-on:click="setStoryContent(story)"  uk-toggle="target: #view-status-modal">
                        <div class="story-media">
                            <img v-if="story.user && story.user.profile_photo" :src="story.user.profile_photo" alt="">
                            <img v-else :src="defaultImageUrl()" alt="">
                        </div>
                        <div class="story-text d-block">
                            <div class="story-username">{{ story.name }} <verified-badge v-if="story.user && story.user.verified" size="small"></verified-badge></div>
                            <p>
                                <span class="story-count text-black"> {{ unseenStoryItems(currentUser.id, story.items) }} new </span>
                                <span class="story-time"> {{ timeAgo(story.created_at) }} ago</span>
                            </p>
                        </div>
                    </a>
                </template>
            </div>
        </div>
    </div>
    <div class="m-auto col-lg-4 col-md-6">
        <div v-if="mainSection == 'viewdddddd'">
            <div v-if="story && story.items.length > 0" class="row">
                <div v-for="items in story.items" class="col">
                    <div :id="items.id" class="mx-1 story-ind-custom"></div>
                </div>
            </div>
        </div>
        <div v-if="mainSection == 'createcrecf'" class="">
            <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="message-reply d-block bg-gray-50-old p-0 border-0">
                    <div class="mt-3">
                        <template v-if="messageType == 'text'">
                            <div class="rounded" style="height: 70vh; background-color: #2563eb;display: flex;align-items: center; text-align: center;">
                                <div class="w-full ">
                                    <textarea v-model="message" id="textStory" cols="1" class="pl-2 px-1 text-white text-center" rows="1" placeholder="what's up" style="padding: 10px; background-color: #2563eb; font-size: 20px" />
                                </div>
                            </div>
                            <!-- <button v-if="messageType == 'text'" @click="saveStory" class="mt-3 button ripple-effect">Send</button> -->
                        </template>
                        <template v-if="messageType == 'link'">
                            <input v-model="message" class="pl-2 bg-gray-50 px-1" placeholder="https://example.co" type="url" />
                            <!-- <button v-if="messageType == 'link'" @click="saveStory" class="mt-3 button ripple-effect">Send</button> -->
                        </template>
                        <template v-if="messageType == 'image'">
                            <div v-if="image" class="story_image" >
                                <img :src="image" alt="story item" style="" />
                                <div class="text-center" v-html="customTextRender(caption,'','')">
                                </div>
                            </div>
                            <div v-else class="text-center">
                                <img :src="defaultImageUrl()" class="object-contain" style="height: 50vh" />
                            </div>
                            <p class="mt-3">Select image:</p>
                            <single-file-upload type="image" @uploadSuccess="onImageUploaded">
                                <div class="flex">
                                    <svg class="bg-blue-100 h-9 p-1.5 rounded-full text-blue-600 w-9 cursor-pointer" fill="none" stroke="currentColor" viewbox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"></path>
                                    </svg>
                                    <span class="mt-2.5"> Select an image</span>
                                </div>
                            </single-file-upload>
                            <p class="mt-3">Add caption:</p>
                            <input v-model="caption" class="pl-2 bg-gray-200-old px-1 mt-2" placeholder="caption" type="text" />
                            <!-- <button v-if="messageType == 'image'" @click="saveStory" class="ml-4old mt-3 button ripple-effect">Send</button> -->
                        </template>
                        <div class="flex">
                            <button v-if="image || message" @click="saveStory" class="mt-3 button btn-block ripple-effect">Send</button>
                            <button v-else disabled class="mt-3 button btn-block bg-smoke ripple-effect">Send</button>
                            <button @click="setStoryContent('view','')" class="ml-1 mt-3 button btn-block ripple-effect bg-red">Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- story colose button-->
    <span class="story-btn-close" uk-toggle="target: body ; cls: story-active" uk-tooltip="title:Close story ; pos: left">
        <svg class="w-6 h-6" fill="none" stroke="currentColor" viewbox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
        </svg>
    </span>
    <save-status-modal></save-status-modal>
    <view-status-modal :story="story"></view-status-modal>
    <view-status-stat-modal v-if="my_story_single" :story="my_story_single"></view-status-stat-modal>
</div>
</template>

<script>
import {
    ApiService
} from "@/services/api.service";
import SaveStatusModal from '@/include/Modals/SaveStatusModal'
import ViewStatusModal from '@/include/Modals/ViewStatusModal'
import ViewStatusStatModal from '@/include/Modals/ViewStatusStatModal'
import VerifiedBadge from '@/components/VerifiedBadge'
import SingleFileUpload from '@/components/SingleFileUpload'

export default {
    name: 'StoryPreview',
    components: {
        SaveStatusModal,
        ViewStatusModal,
        ViewStatusStatModal,
        VerifiedBadge,
        SingleFileUpload,
    },
    data() {
        return {
            currentYear: new Date().getFullYear(),
            appName: '-',
            mainSection: 'view',
            messageType: 'text',
            message: '',
            image: '',
            story: {},
            stories: '',
            my_story: '',
            my_story_single: '',
            storyItem: '',
            caption: '',
        }
    },
    mounted: function () {
        this.currentUser = this.$store.getters["user/currentUser"];
        this.getStory()

        let vueThis = this
        this.$root.$on("close-view-status-modal",() => {
            // console.log('closing modal')
            if($("#view-status-modal").length > 0){
                UIkit.modal('#view-status-modal').hide();
            }
            vueThis.story = {}
        })
    },
    methods: {
        async getStory() {
            this.storyLoading = true
            let apiResponse = await ApiService.GetStory()
            if (apiResponse['status'] != 'success') {} else {
                this.storyLoading = false
                this.my_story = apiResponse['data']['my_story']
                this.stories = apiResponse['data']['user_stories']
                // console.log(this.stories)
            }
        },
        setStoryContent(story) {
            // this.story = ''
            this.story = story
            this.$root.$emit("open-view-status-modal", story)
        },
        setMyStoryContent(story) {
            // this.story = ''
            this.my_story_single = story
        },
        setMessageType(messageType) {
            this.mainSection = 'create'
            this.messageType = messageType
            if(messageType == 'text'){
                $("#textStory").focus()
                console.log('focused')
            }
        },
        async saveStory() {
            if (!this.message) {
                return this.$store.commit("toast/Notify", {
                    type: "warning",
                    message: this.messageType + " is required",
                });
            }
            let apiResponse = await ApiService.SaveStory(
                this.messageType,
                this.message,
                this.caption,
            );
            this.$store.commit("toast/Notify", {
                type: apiResponse["status"],
                message: apiResponse["message"],
            });
            if (apiResponse["status"] != "success") {} else {
                location.href = ''
            }
        },
        onImageUploaded(response) {
            // Handle files like:
            // console.log('::::',response)
            if (response['status'] == 'success') {
                this.image = (response['data']['file_name'])
                this.message = (response['data']['file_name'])
            }
            this.$store.commit("toast/Notify", {
                type: response["status"],
                message: response["message"],
            });
        },
    }
}
</script>

<style lang="scss" scoped>
    .status_text_holder {
        height: 540px;
        width: 540px;
        display: flex;
        align-items: center;
        text-align: center;
        background: #333;
        color: #fff;
    }
    .status_text_holder > * {
        width: 100vw;
    }
</style>
