<template>
<!-- Share post modal -->
<div id="course-lesson-modal" class="create-post is-story" uk-modal="">
    <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical rounded-lg p-0 lg:w-8/12 relative shadow-2xl uk-animation-slide-bottom-small">
        <div class="text-center py-3 border-b">
            <h3 class="text-lg font-semibold">Course lesson Materials for {{ lesson.title }}</h3>
            <button class="uk-modal-close-default bg-gray-100 rounded-full p-2.5 right-2" type="button" uk-close="" uk-tooltip="title: Close ; pos: bottom ;offset:7"></button>
        </div>
        <div class="bsolute bottom-0 p-4 space-x-4 w-full">
            <div class="flex-old bg-gray-50 p-5 shadow-sm items-center table-responsive">
                <table class="table">
                    <thead>
                        <tr>
                            <th>Title</th>
                            <th>Material Type</th>
                            <th>Link</th>
                            <th>Mins Read</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody v-if="lesson['items'] && lesson['items'].length > 0">
                        <tr v-for="l in lesson['items']">
                            <td>{{ l.title }}</td>
                            <td>{{ l.type }}</td>
                            <td>{{ l.link }}</td>
                            <td>{{ l.mins_read }}</td>
                            <td>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    ApiService
} from '@/services/api.service'
export default {
    name: "CourseLessonMaterials",
    props: {
        lesson: Object,
    },
    data() {
        return {
            currentYear: new Date().getFullYear(),
        };
    },
    mounted: function () {},
    components: {},
    methods: {
        async open() {},
        closeModal() {
            $('#course-lesson-modal').removeClass('uk-open').hide();
        }
    },
};
</script>

<style lang="scss" scoped>

table {
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

table caption {
  font-size: 1.5em;
  margin: .5em 0 .75em;
}

table tr {
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  padding: .35em;
}

table th,
table td {
  padding: 10px;
  text-align: center;
}

table th {
  font-size: .85em;
  letter-spacing: .1em;
  text-transform: uppercase;
}

@media screen and (max-width: 600px) {
  table {
    border: 0;
  }

  table caption {
    font-size: 1.3em;
  }
  
  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  
  table tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: .625em;
  }
  
  table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: .8em;
    text-align: right;
  }
  
  table td::before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }
  
  table td:last-child {
    border-bottom: 0;
  }
}
</style>
