<template>
<div id="offcanvas-create" uk-offcanvas="flip: true; overlay: true" class="uk-offcanvas" style="display: nonehh;">
    <div class="uk-offcanvas-bar lg:w-4/12 w-full dark:bg-gray-700 dark:text-gray-300 p-0 bg-white flex flex-col justify-center shadow-2xl uk-offcanvas-bar-animation uk-offcanvas-slide">

        <button class="uk-offcanvas-close absolute uk-icon uk-close" type="button" uk-close=""><svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg" data-svg="close-icon">
                <line fill="none" stroke="#000" stroke-width="1.1" x1="1" y1="1" x2="13" y2="13"></line>
                <line fill="none" stroke="#000" stroke-width="1.1" x1="13" y1="1" x2="1" y2="13"></line>
            </svg></button>

        <!-- notivication header -->
        <div class="-mb-1 border-b font-semibold px-5 py-5 text-lg">
            <h4> Add Photo </h4>
        </div>

        <div class="p-6 space-y-3 flex-1">
            <div>
                <label class="mb-3"> Photo Description </label>
                <input v-model="photo_description" type="text" class="with-border" placeholder="">
            </div>
            <div>
                <label class="mb-3"> Reach </label>
                <select v-model="photo_reach" id="" name="" class="shadow-none selectpicker with-border">
                    <option data-icon="uil-bullseye"> Private </option>
                    <option data-icon="uil-chat-bubble-user">My Following</option>
                    <option data-icon="uil-layer-group-slash">Unlisted</option>
                    <option data-icon="uil-globe">Public</option>
                </select>
            </div>
            <div uk-form-custom="" class="w-full py-3 uk-form-custom photoUpload123">
                <single-file-upload type="image" @uploadSuccess="onImageUploaded">
                    <div class="bg-gray-100 border-2 border-dashed flex flex-col h-32 items-center justify-center relative w-full rounded-lg dark:bg-gray-800 dark:border-gray-600">
                        <img v-if="image" :src="image" style="height: 120px !important;" />
                        <svg v-else xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-12">
                            <path d="M5.5 13a3.5 3.5 0 01-.369-6.98 4 4 0 117.753-1.977A4.5 4.5 0 1113.5 13H11V9.413l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L9 9.414V13H5.5z"></path>
                            <path d="M9 13h2v5a1 1 0 11-2 0v-5z"></path>
                        </svg>
                    </div>
                </single-file-upload>
                <!-- <input type="file"> -->
            </div>

        </div>
        <div class="p-5">
            <button @click="savePhoto" type="button" class="button w-full">
                Save Now
            </button>
        </div>

    </div>
</div>
</template>

<script>
import {
    ApiService
} from "@/services/api.service";
import SingleFileUpload from '@/components/SingleFileUpload'
export default {
    name: 'CreatePhotoPost',
    props: {},
    data() {
        return {
            photo_reach: 'Public',
            photo_description: '',
            image: '',
            currentUser: ''
        }
    },
    components: {
        SingleFileUpload
    },
    mounted() {
        this.currentUser = this.$store.getters["user/currentUser"];
    },
    methods: {
        savePhoto() {
          this.$emit('savePhoto', this.photo_reach,this.photo_description, this.image)
        },
        onImageUploaded(response) {
            // Handle files like:
            // console.log('::::',response)
            if (response['status'] == 'success') {
                this.image = response['data']['file_name']
            }
            this.$store.commit("toast/Notify", {
                type: response["status"],
                message: response["message"],
            });
        },
    }
}
</script>

<style lang="scss" scoped>
</style>
