<template>
<div id="wrapper">
    <auth-styles v-if="pageNameMain" :pageNameMain="pageNameMain" :pageDescriptionMain="pageDescriptionMain" :pageImageMain="pageImageMain"></auth-styles>
    <Header />

    <sidebar></sidebar>

    <!-- Main Contents -->
    <div class="main_content">
        <div class="mcontainer" v-if="advert">

            <div class="lg:flex lg:space-x-10">

                <div class="lg:w-3/4 space-y-5">

                    <div class="card">

                        <div class="h-44 mb-4 md:h-72 overflow-hidden relative rounded-t-lg w-full">
                            <img v-if="advert.image" :src="advert.image" alt="" class="w-full h-full absolute inset-0 object-cover" />
                            <img v-else :src="defaultPostUrl()" alt="" class="w-12 h-12 rounded-full" />
                        </div>
                        <div class="p-7">

                            <h1 class="lg:text-3xl text-2xl font-semibold mb-6"> {{ advert.title }} </h1>
                            <h6 class="font-semibold mb-6">Wallet balance: <span class="text-uppercase">{{ currency }}</span> {{ formatAmount(wallet) }} </h6>

                            <div class="flex items-center space-x-3 my-4 pb-4 border-b border-gray-100">
                                <div>
                                    <div class="text-sm"> Views: {{ advert.views }} </div>
                                    <div class="text-sm"> Clicks: {{ advert.clicks }} </div>
                                    <div class="text-sm"> Amount paid: <span class="text-uppercase">{{ advert.currency }}</span> {{ formatAmount(advert.amount_paid) }} </div>
                                    <div class="text-sm" v-if="advert.status == 'active'"> Amount left: <span class="text-uppercase">{{ advert.currency }}</span> {{ formatAmount(advert.amount_left) }} </div>
                                    <div class="text-sm">
                                        Status:
                                        <span v-if="advert.status == 'pending'" class="bg-gray-100 text-gray-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-gray-300">Unpaid</span>
                                        <span v-if="advert.status == 'unapproved'" class="bg-gray-100 text-gray-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-gray-300">Unapproved</span>
                                        <span v-if="advert.status == 'active'" class="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300">Running</span>
                                        <span v-if="advert.status == 'paused'" class="bg-yellow-100 text-yellow-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-yellow-900 dark:text-yellow-300">Paused</span>
                                        <span v-if="advert.status == 'completed'" class="bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">Completed</span>
                                    </div>
                                    <div class="text-sm"> Created on: {{ formatDate(advert.created_at) }} </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="bg-white mb-3 sm:px-5 px-3 py-5 md:flex">
                        <template v-if="advert.status == 'pending'">
                            <div class="text-white">
                                <a @click="advertWalletPayment" class="h-8 custom_button rounded-md text-sm text-white hover:text-white w-full bg-indigo-700 mouse-pointer">Pay with wallet</a>
                            </div>
                            <div class="text-white ml-4" v-if="amount">
                                <pay-with-flutterwave title="Pay with flutterwave" :email="email" :phone="phone" :name="name" :amount="amount" :currency="currency" :description="description" @paymentCallback="paymentCallback" ></pay-with-flutterwave>
                            </div>
                        </template>
                        <template v-if="advert.status == 'active'">
                            <div class="text-white">
                                <a @click="confirmPause" class="h-8 px-4 py-3 rounded-md text-sm text-white hover:text-white w-full bg-pink-700 mouse-pointer">Pause Advert</a>
                            </div>
                        </template>
                        <template v-if="advert.status == 'paused'">
                            <div class="text-white ml-3">
                                <a @click="confirmActivate" class="h-8 px-4 py-3 rounded-md text-sm text-white hover:text-white w-full bg-blue-700 mouse-pointer">Activate Advert</a>
                            </div>
                        </template>
                        <div v-if="advert.status == 'unapproved'" class="bg-gray-100 text-gray-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-gray-300">
                            <h4>Our admins are currently reviewing this ad and it will go live as soon as it is approved by an admin</h4>
                        </div>
                    </div>

                </div>

            </div>

        </div>
        <div id="progressLoader">
            <progress-loader></progress-loader>
        </div>
    </div>

    <dashboard-widgets page="forum"></dashboard-widgets>
</div>
</template>

<script>
import {
    ApiService
} from '@/services/api.service'
import Header from '@/include/Dashboard/Header.vue'
import Sidebar from '@/include/Dashboard/Sidebar.vue'
import DashboardWidgets from '@/components/DashboardWidgets'
import OpenChatBox from '@/include/Modals/OpenChatBox.vue'
import Feeds from '@/include/Dashboard/Feeds.vue'
import ProfileIcon from '@/include/Dashboard/Feed/ProfileIcon'
import ProgressLoader from '@/components/ProgressLoader'
import ShareIcons from '@/components/ShareIcons'
import AuthStyles from '@/include/Auth/AuthStyles'
import VerifiedBadge from '@/components/VerifiedBadge'
import UserProfileLink from '@/components/UserProfileLink'
import PayWithFlutterwave from '@/components/PayWithFlutterwave'

export default {
    name: 'Forum',
    components: {
        Header,
        Sidebar,
        DashboardWidgets,
        OpenChatBox,
        Feeds,
        ProgressLoader,
        ShareIcons,
        AuthStyles,
        VerifiedBadge,
        UserProfileLink,
        PayWithFlutterwave
    },

    data() {
        return {
            advert: '',
            id: '',
            currency: '',
            wallet: '',
            email: '',
            phone: '',
            name: '',
            description: '',
            amount: '',
            currentUser: {},
            thisPage: '',
            pageNameMain: '',
            pageDescriptionMain: '',
            pageImageMain: '',
            replyToReply: '',
            requestProgress: 'false'
        }
    },
    mounted () {
        let id = this.$route.params['id']
        this.id = id
        this.currentUser = this.$store.getters['user/currentUser'] || {}

        this.email = this.currentUser['email']
        this.phone = this.currentUser['phone']
        this.name = this.currentUser['name']
        this.description = "advert "+this.id

        this.getAdvert()
        this.thisPage = location.href
    },
    methods: {
        async getAdvert() {
            this.toggleProgress('show')
            let apiResponse = await ApiService.GetAdvert(this.id);
            if (apiResponse["status"] != "success") {} else {
                this.advert = apiResponse["data"]['advert'];
                this.currency = apiResponse["data"]['currency'];
                this.amount = this.advert['amount_paid'];
                this.wallet = apiResponse["data"]['wallet'];
                let vueThis = this
            }
            this.toggleProgress('hide')
        },
        async pauseAdvert() {
            this.toggleProgress('show')
            let apiResponse = await ApiService.PauseAdvert(this.id);
            if (apiResponse["status"] != "success") {
                this.$store.commit("toast/Notify", {
                    type: apiResponse["status"],
                    message: apiResponse["message"],
                });
            } else {
                this.advert = apiResponse["data"];
            }
            this.toggleProgress('hide')
        },
        async activateAdvert() {
            this.toggleProgress('show')
            let apiResponse = await ApiService.ActivateAdvert(this.id);
            if (apiResponse["status"] != "success") {
                this.$store.commit("toast/Notify", {
                    type: apiResponse["status"],
                    message: apiResponse["message"],
                });
            } else {
                this.advert = apiResponse["data"];
            }
            this.toggleProgress('hide')
        },
        async advertWalletPayment() {
            this.toggleProgress('show')
            let apiResponse = await ApiService.AdvertPayWithWallet(this.id);
            if (apiResponse["status"] != "success") {
                this.$store.commit("toast/Notify", {
                    type: apiResponse["status"],
                    message: apiResponse["message"],
                });
            } else {
                setTimeout(() => {
                    location.href = ""
                }, 1000);
            }
            this.toggleProgress('hide')
        },
        async advertCardPayment(transaction_id) {
            this.toggleProgress('show')
            let apiResponse = await ApiService.AdvertPayWithRave(this.id, transaction_id);
            if (apiResponse["status"] != "success") {
                this.$store.commit("toast/Notify", {
                    type: apiResponse["status"],
                    message: apiResponse["message"],
                });
            } else {
                setTimeout(() => {
                    location.href = ""
                }, 1000);
            }
            this.toggleProgress('hide')
        },
        async paymentCallback(response) {
            // console.log("RESPONSE",response)
            let txRef = response['tx']['txRef'];
            if(txRef){
                this.toggleProgress('show')
                let apiResponse = await this.advertCardPayment(txRef);
                console.log('apiResponse', apiResponse)
                this.toggleProgress('hide')
            }else{
                this.$toastr.w('Warning', 'Transaction reference could not be found')
            }
        },
        confirmPause() {
            let proceed = confirm("Are you sure you want to proceed?");
            if (proceed) {
                this.pauseAdvert()
            } else {
                //don't proceed
            }
        },
        confirmActivate() {
            let proceed = confirm("Are you sure you want to proceed?");
            if (proceed) {
                this.activateAdvert()
            } else {
                //don't proceed
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.some-modal-content {
    min-width: 400px;
    padding: 25px;

    .buttons button {
        padding: 10px;
        margin: 10px;
    }
}
</style>
