<template>
<header style="position: relative">
    <div class="header_wrap">
        <div class="header_inner mcontainer">
            <div class="left_side">

                <div id="logo">
                    <a href="/">
                        <img src="/assets/img/logo-full-small.png" alt="" class="logo_img" />
                        <img src="/assets/img/logo-icon-smaller.jpeg" class="logo_mobile logo_img" alt="" />
                    </a>
                </div>
            </div>

            <div class="right_side">
                <div class="header_widgets">
                    <router-link to="/about" class="btn_action hover:bg-gray-100 mr-2 px-2 py-1 rounded-md">
                        About us
                    </router-link>
                    <router-link to="/faq" class="btn_action hover:bg-gray-100 mr-2 px-2 py-1 rounded-md">
                        FAQ
                    </router-link>
                    <router-link to="/contact" class="btn_action hover:bg-gray-100 mr-2 px-2 py-1 rounded-md">
                        Contact us
                    </router-link>
                    <a href="/login" class="ml-4 is_link mouse-pointer" target-old="_blank">
                        Login
                    </a>
                    <a href="/register" class="is_link mouse-pointer" target-old="_blank">
                        Register
                    </a>
                </div>
            </div>
        </div>
    </div>
</header>
</template>

<script>
export default {
    name: 'HomeHeader',
    components: {},
    data() {
        return {}
    },

    methods: {}
}
</script>

<style>
.slider {
    height: 100vh;
    display: flex;
    text-align: center;
    align-items: center;
}

.slider>div {
    width: 100vw;
}

.blue-bg-custom {
    background: #2563eb;
}

.blue-bg-light-custom {
    background: #e5edff;
}

.blue-text-custom {
    color: #2563eb;
}

.slider h2 {
    color: #000;
    font-size: 60px;
    font-weight: bold;
}

.slider h6 {
    margin-top: 20px;
    color: #666666;
    font-size: 20px;
}

.home-content {
    background: #fff;
}

.widget-title {
    color: #fff;
    font-weight: bold;
    padding-bottom: 10px;
}

.vt-footer-nav li {
    padding-bottom: 20px;
}
</style>
<style lang="scss" scoped>
.some-modal-content {
    min-width: 400px;
    padding: 25px;

    .buttons button {
        padding: 10px;
        margin: 10px;
    }
}
</style>
