<template>
  <div id="wrapper" class="flex flex-col justify-between h-screen">
      <auth-styles pageNameMain="Login"></auth-styles>
      <auth-header></auth-header>

      <!-- Content-->
      <div>
          <div class="lg:p-12 max-w-xl lg:my-0 my-12 mx-auto p-6 space-y-">
              <form class="lg:p-10 p-6 space-y-3 relative bg-white shadow-xl rounded-md text-center">
                <div>
                  <h1 class="lg:text-2xl text-xl font-semibold mb-6"> Account Activation </h1>
                </div>
                <div v-if="apiStatus == 'success'">
                    <div>
                        <p>
                            Your account has been activated successfully, <br/>proceed to <a href="/login">login</a>
                        </p>
                    </div>
                </div>
                <div v-else-if="apiStatus == 'error'">
                    <p class="text-center">
                        {{ apiMessage }}
                    </p>
                </div>
                <div v-else>
                    <p class="text-center">
                        Verifying Account...
                    </p>
                </div>
              </form>

          </div>
      </div>

      <auth-scripts></auth-scripts>

  </div>
</template>

<script>
import { ApiService } from '@/services/api.service'
import AuthStyles from '../../include/Auth/AuthStyles'
import AuthScripts from '../../include/Auth/AuthScripts'
import AuthHeader from '@/include/Auth/AuthHeader'

export default {
  name: 'AccountActivation',
  data () {
    return {
      code: '',
      expire_at: '',
      apiStatus: '',
      apiMessage: '',
      error: ''
    }
  },
  components: {
    AuthStyles,
    AuthScripts,
    AuthHeader
  },
  mounted() {
    //   console.log(this.$route.params)
      let params = this.$route.params
      this.code = params['code']
      this.expire_at = params['expire_at']

      this.verifyAccount()
  },
  methods: {
    async verifyAccount () {
        if(!this.code){
            return this.$store.commit('toast/Notify', { type: 'warning', message: 'code is required' })
        }
        if(!this.expire_at){
            return this.$store.commit('toast/Notify', { type: 'warning', message: 'expire_at is required' })
        }
        let apiResponse = await ApiService.ActivateAccount(this.code,this.expire_at)
        // console.log(apiResponse)
        if(apiResponse['status'] == 'success'){
            this.apiStatus = 'success'
            // this.$store.commit('toast/Notify', { type: apiResponse['status'], message: apiResponse['message'] })
        }else{
            this.apiStatus = 'error'
            this.apiMessage = apiResponse['message']
        }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
