<template>
<div class="flex">
    <img @click="openLinkInNewTab(img)" v-for="img in images" :src="img" style="height:50px" class="mr-2" />
</div>
</template>

    
    
<script>
export default {
    props: {
        images: Array,
    },
    name: 'ChatMessageImages',
    data() {
        return {
            currentUser: '',
            currentShop: '',
        }
    },
    mounted: function () {
        this.currentUser = this.$store.getters['user/currentUser'] || {}
    },
    methods: {
        setMessageToReply(a) {},
    },
}
</script>
