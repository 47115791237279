<template>
<div>
    <div uk-toggle="target: #offcanvas-chat" class="start-chat bg-orange text-white" id="mFloat">
        <svg class="h-7 w-7" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"></path>
        </svg>
    </div>
    <messenger-component></messenger-component>
</div>
</template>

<script>
import MessengerComponent from '@/components/MessengerComponent'
export default {
    name: 'MessengerButton',
    props: {
        link: String,
    },
    components: {
        MessengerComponent
    },
    data: function () {
        return {
            widget: ''
        }
    },
    mounted() {
        let name = this.name
        this.managePageLock()
    },
    watch: {
        $route(to, from) { 
            this.managePageLock()
        },
    },
    methods: {
        managePageLock(){
            let url = location.href
            if(url.search('/dashboard/chat') > -1){
                $('#mFloat').hide()
            }else{
                $('#mFloat').show()
            }
        },
    }
}
</script>

<style lang="scss" scoped>
</style>
