<template>
<div id="wrapper">

    <Header />

    <sidebar></sidebar>

    <!-- Main Contents -->
    <div class="main_content">
        <div class="mcontainer">

            <!--  breadcrumb -->
            <div class="breadcrumb-area py-0">
                <div class="breadcrumb">
                    <ul class="m-0">
                        <li>
                            <a href="/dashboard/courses">Courses</a>
                        </li>
                        <li class="active">
                            <a :href="'/dashboard/course/'+course.id">View Course </a>
                        </li>
                    </ul>
                </div>
            </div>

            <!-- create page-->
            <div id="add_course_announcement" class="max-w-2xl m-auto shadow-md rounded-md bg-white lg:mt-20" v-if="course">

                <!-- form header -->
                <div class="text-center border-b border-gray-100 py-6">
                    <h3 class="font-bold text-xl"> Add Course Announcement to {{ title }} </h3>
                </div>

                <!-- form body -->
                <div class="p-10 space-y-7">

                    <div>
                        <label>Title</label>
                    </div>
                    <div class="line" style="margin-top: 5px !important">
                        <input v-model="announcement_title" class="line__input" id="announcement_title" name="announcement_title" type="text" onkeyup="this.setAttribute('value', this.value);" value="" autocomplete="off">
                        <span for="announcement_title" class="line__placeholder">  </span>
                    </div>
                    <div class="">
                        <label for="" class="mb-2"> Description </label>
                        <custom-text-editor id="announcement_description" :content="announcement_description" @contentUpdated="announcementDescriptionUpdated"></custom-text-editor>
                    </div>

                </div>

                <!-- form footer -->
                <div class="border-t flex justify-between lg:space-x-10 p-7 bg-gray-50 rounded-b-md">
                    <button type="button" class="button lg:w-1/2" @click="createCourseAnnouncement">
                        Add Course Announcement
                    </button>
                </div>

            </div>

        </div>
    </div>

    <progress-loader v-if="coursePreloader == 'true'"></progress-loader>
    <dashboard-widgets page="create-product"></dashboard-widgets>
    <course-lesson-materials v-if="activeLesson" :lesson="activeLesson"></course-lesson-materials>
    <course-faq v-if="activeFaq" :faq="activeFaq"></course-faq>
    <course-announcement v-if="activeAnnouncement" :announcement="activeAnnouncement"></course-announcement>

</div>
</template>

<script>
import {
    VueEditor
} from "vue2-editor";
import {
    ApiService
} from '@/services/api.service'
import Header from '@/include/Dashboard/Header.vue'
import Sidebar from '@/include/Dashboard/Sidebar.vue'
import DashboardWidgets from '@/components/DashboardWidgets'
import OpenChatBox from '@/include/Modals/OpenChatBox.vue'
import Feeds from '@/include/Dashboard/Feeds.vue'
import ProfileIcon from '@/include/Dashboard/Feed/ProfileIcon'
import ProgressLoader from '@/components/ProgressLoader'
import SingleFileUpload from '@/components/SingleFileUpload'
import axios from 'axios'
import CustomTextEditor from '@/components/CustomTextEditor'
import CourseLessonMaterials from '@/include/Modals/CourseLessonMaterials'
import CourseFaq from '@/include/Modals/CourseFaq'
import CourseAnnouncement from '@/include/Modals/CourseAnnouncement'

export default {
    name: 'AddCourseAnnouncement',
    components: {
        Header,
        Sidebar,
        DashboardWidgets,
        OpenChatBox,
        Feeds,
        ProgressLoader,
        SingleFileUpload,
        CustomTextEditor,
        CourseLessonMaterials,
        CourseFaq,
        CourseAnnouncement,
    },

    data() {
        return {
            course: '',
            image: '',
            category: '',
            title: '',
            currency: 'NGN',
            amount: '',
            description: '',
            sub_title: '',
            level: '',
            payment: '',
            lesson_index: '',
            lesson_title: '',
            lesson_description: '',
            lesson_item_no: ['1'],
            lesson_titles: [],
            lesson_types: [],
            lesson_links: [],
            lesson_mins_read: [],
            faq_index: '',
            faq_title: '',
            faq_description: '',
            announcement_index: '',
            announcement_title: '',
            announcement_description: '',
            categories: '',
            currencies: '',
            categorySubcategory: '',
            category: '',
            sub_category: '',
            lesson_materials: {},
            coursePreloader: 'false',
            activeLesson: '',
            activeFaq: '',
            activeAnnouncement: '',
        }
    },
    mounted: function () {
        let id = this.$route.params['id']
        this.id = id
        this.getCourse(this.id)
        this.getExtraInfo()
    },
    methods: {
        async setCategorySubcategory(){
            let catSubcat = this.categorySubcategory
            let catSubcatObj = catSubcat.split("-")
            this.category_id = catSubcatObj[0]
            this.sub_category_id = catSubcatObj[1]
        },
        async getExtraInfo() {
            this.coursePreloader = 'true'
            let apiResponse = await ApiService.ExtraInfo();
            // console.log('getExtraInfo', apiResponse)
            if (apiResponse['status'] == 'success') {
                this.categories = apiResponse['data']['course_categories']
                this.currencies = apiResponse['data']['currencies']
            }
            this.coursePreloader = 'false'
        },
        async getCourse(id) {
            this.coursePreloader = 'true'
            let apiResponse = await ApiService.GetCourse(id)
            if (apiResponse['status'] != 'success') {} else {
                this.course = apiResponse['data']['course']

                if(this.course){
                    this.title = this.course['title']
                    this.sub_title = this.course['sub_title']
                    this.image = this.course['image']
                    this.payment = this.course['payment']
                    this.category = this.course['category']
                    this.sub_category = this.course['sub_category']
                    this.description = this.course['description']
                    this.level = this.course['level']
                    this.currency = this.course['currency']
                    this.amount = this.course['amount']

                    this.categorySubcategory = this.category+'-'+this.sub_category
                }
            }
            this.coursePreloader = 'false'
        },
        async updateCourse() {
            this.coursePreloader = 'true'
            let apiResponse = await ApiService.UpdateCourse(this.course.id, this.title, this.sub_title, this.image, this.level, this.payment, this.currency, this.amount, this.category, this.sub_category, this.description);
            this.$store.commit("toast/Notify", {
                type: apiResponse["status"],
                message: apiResponse["message"],
            });
            if (apiResponse["status"] != "success") {
            } else {
                if(apiResponse['data']['course']['id']){
                    location.href = ''
                }
            }
            this.coursePreloader = 'false'
        },
        async createCourseLesson() {
            let lesson_materials = Array()
            let lessonTitles = this.lesson_titles
            let lessonTypes = this.lesson_types
            let lessonLinks = this.lesson_links
            let lessonMinsRead = this.lesson_mins_read

            // console.log(lessonTitles,'LessonTitles')
            // console.log(lessonTypes,'lessonTypes')
            // console.log(lessonLinks,'lessonLinks')
            // console.log(lessonMinsRead,'lessonMinsRead')

            let lessonError = ''
            for (let i = 0; i < lessonTitles.length; i++) {
                const thisLessonTitle = lessonTitles[i];
                let lessonID = parseInt(i)+1
                
                if(!thisLessonTitle){
                    lessonError = 'Lesson Material missing title for Material '+lessonID
                    break
                }
                if(!lessonTypes[i]){
                    lessonError = 'Lesson Material missing type for Material '+lessonID
                    break
                }
                if(!lessonLinks[i]){
                    lessonError = 'Lesson Material missing link for Material '+lessonID
                    break
                }
                if(!lessonMinsRead[i]){
                    // console.log("This Min Read", lessonMinsRead[i])
                    lessonError = 'Lesson Material missing mins_read for Material '+lessonID
                    break
                }
                lesson_materials.push({
                    'title': lessonTitles[i],
                    'type': lessonTypes[i],
                    'mins_read': lessonMinsRead[i],
                    'link': lessonLinks[i]
                })
            }
            if(lessonError){
                this.$store.commit("toast/Notify", {
                    type: 'warning',
                    message: lessonError,
                });
                return
            }
            this.lesson_materials = lesson_materials
            this.coursePreloader = 'true'
            let apiResponse = await ApiService.CreateCourseLesson(this.course.id, this.lesson_title, this.lesson_description, this.lesson_index, this.lesson_materials);
            this.$store.commit("toast/Notify", {
                type: apiResponse["status"],
                message: apiResponse["message"],
            });
            if (apiResponse["status"] != "success") {
            } else {
                location.href = ''
            }
            this.coursePreloader = 'false'
        },
        async deleteCourseLesson(id) {
            let proceed = confirm("Are you sure you want to proceed?");
            if (proceed) {
                this.coursePreloader = 'true'
                let apiResponse = await ApiService.DeleteCourseLesson(this.course.id, id)
                this.$store.commit("toast/Notify", {
                    type: apiResponse["status"],
                    message: apiResponse["message"],
                });
                if (apiResponse['status'] != 'success') {} else {
                    location.href = ''
                }
                this.coursePreloader = 'false'
            } else {
                //don't proceed
                return 
            }
        },
        async createCourseFaq() {
            this.coursePreloader = 'true'
            let apiResponse = await ApiService.CreateCourseFaq(this.course.id, this.faq_title, this.faq_description, this.faq_index);
            this.$store.commit("toast/Notify", {
                type: apiResponse["status"],
                message: apiResponse["message"],
            });
            if (apiResponse["status"] != "success") {
            } else {
                location.href = ''
            }
            this.coursePreloader = 'false'
        },
        async deleteCourseFaq(id) {
            let proceed = confirm("Are you sure you want to proceed?");
            if (proceed) {
                this.coursePreloader = 'true'
                let apiResponse = await ApiService.DeleteCourseFaq(this.course.id, id)
                this.$store.commit("toast/Notify", {
                    type: apiResponse["status"],
                    message: apiResponse["message"],
                });
                if (apiResponse['status'] != 'success') {} else {
                    location.href = ''
                }
                this.coursePreloader = 'false'
            } else {
                //don't proceed
                return 
            }
        },
        async createCourseAnnouncement() {
            this.coursePreloader = 'true'
            let apiResponse = await ApiService.CreateCourseAnnouncement(this.course.id, this.announcement_title, this.announcement_description);
            this.$store.commit("toast/Notify", {
                type: apiResponse["status"],
                message: apiResponse["message"],
            });
            if (apiResponse["status"] != "success") {
            } else {
                location.href = ''
            }
            this.coursePreloader = 'false'
        },
        async deleteCourseAnnouncement(id) {
            let proceed = confirm("Are you sure you want to proceed?");
            if (proceed) {
                this.coursePreloader = 'true'
                let apiResponse = await ApiService.DeleteCourseAnnouncement(this.course.id, id)
                this.$store.commit("toast/Notify", {
                    type: apiResponse["status"],
                    message: apiResponse["message"],
                });
                if (apiResponse['status'] != 'success') {} else {
                    location.href = ''
                }
                this.coursePreloader = 'false'
            } else {
                //don't proceed
                return 
            }
        },
        addLesson(){
            this.lesson_item_no.push("1")
        },
        setActiveLesson(l){
            this.activeLesson = l
        },
        setActiveFaq(f){
            this.activeFaq = f
        },
        setActiveAnnouncement(a){
            this.activeAnnouncement = a
        },
        contentUpdated(content) {
            // console.log('::::content',content)
            this.description = content
        },
        announcementDescriptionUpdated(content) {
            // console.log('::::announcement_description',content)
            this.announcement_description = content
        },
        onImageUploaded(response) {
            // Handle files like:
            // console.log('::::',response)
            if (response['status'] == 'success') {
                this.image = response['data']['file_name']
            }
            this.$store.commit("toast/Notify", {
                type: response["status"],
                message: response["message"],
            });
        },
        handleImageAdded: function (file, Editor, cursorLocation, resetUploader) {
            // An example of using FormData
            // NOTE: Your key could be different such as:
            // formData.append('file', file)

            var formData = new FormData();
            formData.append("file", file);

            axios({
                    url: this.$store.getters['host/apiUrl'].replace('/api/v2', '/files/upload'),
                    method: "POST",
                    data: formData
                })
                .then(result => {
                    // console.log('result', result)
                    const url = result.data.data['file_name']; // Get url from response
                    Editor.insertEmbed(cursorLocation, "image", url);
                    resetUploader();
                })
                .catch(err => {
                    console.log(err);
                });
        }
    },
}
</script>

<style lang="scss" scoped>
.some-modal-content {
    min-width: 400px;
    padding: 25px;

    .buttons button {
        padding: 10px;
        margin: 10px;
    }
}

.postImgheight {
    height: 100px
}

.editor {
    height: 350px;
}
table {
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

table caption {
  font-size: 1.5em;
  margin: .5em 0 .75em;
}

table tr {
  background-color: #f8f8f8;
  border: 1px solid #ddd;
  padding: .35em;
}

table th,
table td {
  padding: 10px;
  text-align: center;
}

table th {
  font-size: .85em;
  letter-spacing: .1em;
  text-transform: uppercase;
}

@media screen and (max-width: 600px) {
  table {
    border: 0;
  }

  table caption {
    font-size: 1.3em;
  }
  
  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  
  table tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: .625em;
  }
  
  table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: .8em;
    text-align: right;
  }
  
  table td::before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }
  
  table td:last-child {
    border-bottom: 0;
  }
}
</style>
