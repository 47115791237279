<template>
<div class="card md:p-6 p-2 max-w-3xl mx-auto">

    <h2 class="text-xl font-semibold"> Friend</h2>
    <nav class="responsive-nav border-b">
        <ul>
            <li class="active"><a href="javascript:;"> {{currentUser['name']}} Video(s) </a></li>
        </ul>
    </nav>

    <div class="mt-5 pt-5">
        <div v-if="videos.length == 0 && pendingRequest == false" class="bg-white p-2">
            <h5 v-if="page == 0">{{ currentUser.name }} has no video yet</h5>
            <h5 v-else>No video here</h5>
        </div>
        <div v-if="pendingRequest == true" class="text-center my-3">
            <i class="fa fa-spin fa-spinner fa-2x"></i>
        </div>
    </div>

    <div class="grid md:grid-cols-3 grid-cols-2  gap-x-2 gap-y-4 mt-3">
        <div v-for="video in videos">
            <a href="javascript:;" class="w-full h-36 overflow-hidden rounded-lg relative block">
                <img src="/assets/images/video/img-1.png" alt="" class="w-full h-full absolute inset-0 object-cover">
                <span class="absolute bg-black bg-opacity-60 bottom-1 font-semibold px-1.5 py-0.5 right-1 rounded text-white text-xs"> 12:21</span>
                <img src="/assets/images/icon-play.svg" class="w-12 h-12 uk-position-center" alt="">
            </a>
        </div>
    </div>

    <div class="flex justify-center mt-6" v-if="videos.length > 0 && pendingRequest == false">
        <a href="javascript:;" @click="loadMoreVideos" class="bg-white dark:bg-gray-900 font-semibold my-3 px-6 py-2 rounded-full shadow-md dark:bg-gray-800 dark:text-white">
            Load more ..</a>
    </div>

</div>
</template>

<script>
import {
    ApiService
} from '@/services/api.service'
export default {
    name: "VideoScreen",
    data() {
        return {
            videos: [],
            page: 0,
            pendingRequest: false,
        };
    },
    props: {
        currentUser: Object,
    },
    components: {},
    mounted: function () {
        this.getVideos()
    },
    methods: {
        async getVideos() {
            if (this.pendingRequest == true) {
                // console.log('Pending Req')
                return
            }
            this.pendingRequest = true
            let apiResponse = await ApiService.GetFeedVideos(this.page)
            if (apiResponse['status'] != 'success') {} else {
                this.pendingRequest = false
                this.videos = apiResponse['data']['videos']
            }
        },
        async loadMoreVideos() {
            this.page++
            this.getVideos()
        },
    }
};
</script>

<style lang="scss" scoped></style>
