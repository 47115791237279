<template>
<video muted :id="'my-player-' + id" class="video-js" controls preload="auto" :poster-old="default_poster" data-setup="{}" crossorigin="anonymous">
    <source :src="default_src" :type="default_type" />
    <p class="vjs-no-js">
        To view this video please enable JavaScript, and consider upgrading to a
        web browser that
        <a href="https://videojs.com/html5-video-support/" target="_blank">
            supports HTML5 video
        </a>
    </p>
</video>
</template>

<script>
// import { Player, Video } from "@vime/vue";
export default {
    props: {
        id: String,
        src: String,
        type: String,
        poster: String,
    },
    data() {
        return {
            video_id: "",
            default_src: "",
            default_poster: "",
            default_type: "video/mp4",
        };
    },
    mounted() {
        let vueThis = this;
        // console.log("src vid", this.src, this.type, this.poster);
        if (this.src) {
            // this.playerOptions['sources'][0]['src'] = this.src
            this.default_src = this.src;
        }
        if (this.type) {
            // this.playerOptions['sources'][0]['type'] = this.type
            this.default_type = this.type;
        }
        if (this.poster) {
            // this.playerOptions['poster'] = this.poster
            this.default_poster = this.poster;
        } else {
            this.default_poster = this.defaultPostUrl();
        }
        let id = "#my-player-" + this.id;
        this.video_id = id.replace("#", "");

        let thisVideo = $(id);

        thisVideo.on("play", function (a) {
            vueThis.pauseAllVideos();
        });
        // document.getElementById('my-player-' + vueThis.id).addEventListener("play", function () {
        //     vueThis.setVideoBgColor($(this), $(this).parent());
        // })

        $(document).ready(function () {

            $('' + id).on('canplay', function () {
                vueThis.trackScroll();
            });
        })

        setTimeout(() => {
            // vueThis.setVideoBgColor($(id), $(id).parent());
        }, 2000);
    },
    computed: {},
    methods: {
        getVideoType() {
            let a = this.src.split(".");
            if (a.length > 0) {
                let b = a[a.length - 1];
                if (b) {
                    let c = "video/" + b;
                    if (c != this.default_type) {
                        console.log("newtype", this.type);
                        this.type = c;
                    }
                }
            }
        },
        setVideoBgColor(vid, backgroundElement) {
            try {
                let image = new Image();
                image.crossOrigin = "Anonymous";

                const video = document.getElementById(this.video_id);
                const canvas_video = document.createElement("canvas");
                canvas_video.width = 300;
                canvas_video.height = 500;
                const ctx_video = canvas_video.getContext("2d");
                ctx_video.drawImage(video, 0, 0);
                let originalImageData = canvas_video.toDataURL();

                image.src = originalImageData;
                image.onload = () => {
                    const canvas = document.createElement("canvas");
                    canvas.width = image.width;
                    canvas.height = image.height;
                    const ctx = canvas.getContext("2d");
                    ctx.drawImage(image, 0, 0);

                    const p = ctx.getImageData(0, 0, canvas.width, canvas.height);
                    const colorBoost = 0;
                    const colorR = p["data"][0];
                    const colorG = p["data"][1];
                    const colorB = p["data"][2];
                    //colorLayer.data[pixelPos];
                    vid.css(
                        "background",
                        "rgb(" + colorR + "," + colorG + "," + colorB + ")"
                    );
                    // console.log('rgb',colorR + "," + colorG + "," + colorB)
                };
            } catch (error) {
                console.log("===setVideoBgColor", error);
            }
        },
        playVideo() {
            $(this.video_id).play();
        },
        pauseAllVideos() {
            var $allVideos = $("video");
            $("video").on("play", function (e) {
                $allVideos.not(this).each(function () {
                    this.pause();
                });
            });
        },
        trackScroll() {
            let vueThis = this;
            var _video = document.getElementById(this.video_id);
            window.addEventListener("scroll", function () {
                try {
                    // console.log( "vueThis.isScrolledIntoView(_video)", vueThis.isScrolledIntoView(_video),vueThis.video_id, _video);
                    if (vueThis.isScrolledIntoView(_video)) {
                        if (_video) {
                            _video.play();
                        }
                    } else {
                        if (_video) {
                            _video.pause();
                        }
                    }
                } catch (error) {
                    console.log('trackScroll', error)
                }
            });
        },
        isScrolledIntoView(element) {
            let inview = false
            if ($("#" + this.video_id)) {
                let top_of_element = $("#" + this.video_id).offset().top;
                let bottom_of_element = $("#" + this.video_id).offset().top + $("#" + this.video_id).outerHeight();
                let bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();
                let top_of_screen = $(window).scrollTop();

                if (
                    bottom_of_screen > top_of_element &&
                    top_of_screen < bottom_of_element
                ) {
                    // the element is visible, do something
                    inview = true
                } else {
                    // the element is not visible, do something else
                }
            }
            return inview
        },
    },
};
</script>

<style scoped>
.video-js {
    width: 100%;
    height: auto;
    min-height: 350px;
}

@media (max-width: 520px) {
    .video-js {
        width: 100%;
        height: auto;
        min-height: 150px;
    }
}
</style>
