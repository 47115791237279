<template>
  <div id="wrapper" class="flex flex-col justify-between h-screen">
      <auth-styles pageNameMain="Login"></auth-styles>
      <auth-header></auth-header>

      <!-- Content-->
      <div>
          <div class="lg:p-12 max-w-xl lg:my-0 my-12 mx-auto p-6 space-y-">
              
              <div class="lg:p-10 p-6 space-y-3 relative bg-white shadow-xl rounded-md">
                  <h1 class="lg:text-2xl text-xl font-semibold mb-6"> Auto Login </h1>
                  <div class="text-center">
                    <i class="fa fa-spin fa-spinner"></i>
                  </div>
              </div>

          </div>
      </div>

      <auth-scripts></auth-scripts>

  </div>
</template>

<script>
import { ApiService } from '@/services/api.service'
import AuthStyles from '../../include/Auth/AuthStyles'
import AuthScripts from '../../include/Auth/AuthScripts'
import AuthHeader from '@/include/Auth/AuthHeader'

export default {
  name: 'Login',
  data () {
    return {
      token: '',
    }
  },
  components: {
    AuthStyles,
    AuthScripts,
    AuthHeader,
  },
  mounted(){
      let params = this.$route.params
      this.token = params['token']
      this.user()
  },
  methods: {
    async user () {
        let data = {
            'access_token': this.token
        }
        let userResponse = await $.ajax({
            url: this.$store.getters['host/apiUrl'] + '/autologin',
            method: 'POST',
            data: data,
            success: function(response) {
                // console.log(response)
                return response
            },
            error: function(response) {
                console.log(response)
                return response
            }
        })
        console.log('=========', userResponse);
        this.$store.commit('toast/Notify', { type: userResponse['status'], message: userResponse['message'] })
        if(userResponse['status'] != 'success'){
        }else{
            this.$store.commit('auth/SAVE_ACCESS_TOKEN', userResponse['user']['remember_token'])
            let currentUser = this.userDataToCommitToStore(userResponse['user'])
            this.$store.commit('user/SAVE_CURRENT_USER', currentUser)
            // this.$router.push("/dashboard/blogs");
            location.href = '/dashboard/feeds'
        }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
