<template>
<div id="wrapper">

    <Header />

    <sidebar></sidebar>

    <!-- Main Contents -->
    <div class="main_content">
        <div class="mcontainer" v-if="currentUser['id'] == page['user_id']">

            <!--  breadcrumb -->
            <div class="breadcrumb-area py-0">
                <div class="breadcrumb">
                    <ul class="m-0">
                        <li>
                            <a href="/dashboard/pages">Pages</a>
                        </li>
                        <li class="active">
                            <a href="">Edit Page Post </a>
                        </li>
                    </ul>
                </div>
            </div>

            <!-- create page-->
            <div class="max-w-2xl m-auto shadow-md rounded-md bg-white lg:mt-20">

                <!-- form header -->
                <div class="text-center border-b border-gray-100 py-6">
                    <h3 class="font-bold text-xl"> Update Page Post </h3>
                </div>

                <!-- form body -->
                <div class="p-10 space-y-7">

                    <div>
                        <label>Name</label>
                    </div>
                    <div class="line" style="margin-top: 5px">
                        <input required v-model="name" class="line__input" id="name" name="name" type="text" onkeyup="this.setAttribute('value', this.value);" value="" autocomplete="off">
                        <span for="name" class="line__placeholder">  </span>
                    </div>
                    <div class="flex items-center">
                        <div class="mr-2">
                            <p>Page Image</p>
                            <img v-if="image" :src="image" class="postImgheight" />
                            <img v-else :src="defaultPostUrl()" class="postImgheight" />
                        </div>
                        <single-file-upload type="image" @uploadSuccess="onImageUploaded">
                            <p>Select<br /> Image</p>
                        </single-file-upload>
                    </div>
                    <div class="flex items-center">
                        <div class="mr-2">
                            <p>Page Banner</p>
                            <img v-if="banner" :src="banner" class="postImgheight" />
                            <img v-else :src="defaultPostUrl()" class="postImgheight" />
                        </div>
                        <single-file-upload type="image" @uploadSuccess="onBannerUploaded">
                            <p>Select<br /> Image</p>
                        </single-file-upload>
                    </div>
                    <div>
                        <div>
                            <label>Category</label>
                        </div>
                        <!-- <label for=""> Category </label><br/><br/> -->
                        <select required v-model="category" id="" name="" class="shadow-none with-border ">
                            <option value="">Select Category</option>
                            <option v-for="c in postCategories">{{ c }}</option>
                        </select>
                    </div>

                    <div>
                        <label>Email</label>
                    </div>
                    <div class="line" style="margin-top: 5px">
                        <input required v-model="email" class="line__input" id="email" name="email" type="text" onkeyup="this.setAttribute('value', this.value);" value="" autocomplete="off">
                        <span for="email" class="line__placeholder">  </span>
                    </div>
                    <div>
                        <div class="mb-2">
                            <label>Dont Show Email Address In Public Page</label>
                        </div>
                        <!-- <label for=""> Category </label><br/><br/> -->
                        <select required v-model="hide_email" id="" name="" class="shadow-none with-border ">
                            <option value="false">False</option>
                            <option value="true">True</option>
                        </select>
                    </div>

                    <div>
                        <label>Website</label>
                    </div>
                    <div class="line" style="margin-top: 5px">
                        <input required v-model="website" class="line__input" id="website" name="website" type="url" onkeyup="this.setAttribute('value', this.value);" value="" autocomplete="off">
                        <span for="website" class="line__placeholder">  </span>
                    </div>

                    <div class="">
                        <label for=""> Description </label>
                        <textarea v-model="description" class="bg-smoke h-32 p-2" id="" name="" type="text" onkeyup="this.setAttribute('value', this.value);" value="" autocomplete="off" style="background-color: #f3f4f6 !important;"></textarea>
                    </div>

                    <div class="row">

                        <div class="col-sm-6">
                            <div class="mx-2 mb-3">
                                <label for="" class="mb-2"> Facebook </label>
                                <input required v-model="facebook" class="form-control" id="facebook" name="facebook" type="url" onkeyup="this.setAttribute('value', this.value);" value="" autocomplete="off" placeholder="https://facebook.com/********">
                            </div>
                            <div class="mx-2 mb-3">
                                <label for="" class="mb-2"> Instagram </label>
                                <input required v-model="instagram" class="form-control" id="instagram" name="instagram" type="url" onkeyup="this.setAttribute('value', this.value);" value="" autocomplete="off" placeholder="https://instagram.com/********">
                            </div>
                        </div>

                        <div class="col-sm-6">
                            <div class="mx-2 mb-3">
                                <label for="" class="mb-2"> Twitter </label>
                                <input required v-model="twitter" class="form-control" id="twitter" name="twitter" type="url" onkeyup="this.setAttribute('value', this.value);" value="" autocomplete="off" placeholder="https://twitter.com/********">
                            </div>
                            <div class="mx-2 mb-3">
                                <label for="" class="mb-2"> Youtube </label>
                                <input required v-model="youtube" class="form-control" id="youtube" name="youtube" type="url" onkeyup="this.setAttribute('value', this.value);" value="" autocomplete="off" placeholder="https://youtube.com/********">
                            </div>
                        </div>
                    </div>

                </div>

                <!-- form footer -->
                <div class="border-t flex justify-between lg:space-x-10 p-7 bg-gray-50 rounded-b-md">
                    <button type="button" class="button lg:w-1/2" @click="updatePage">
                        Update Page
                    </button>
                </div>

            </div>

        </div>
        <div id="progressLoader">
            <progress-loader></progress-loader>
        </div>
    </div>

    <dashboard-widgets page="edit-page"></dashboard-widgets>

</div>
</template>

<script>
import { VueEditor } from "vue2-editor";
import { ApiService } from '@/services/api.service'
import Header from '@/include/Dashboard/Header.vue'
import Sidebar from '@/include/Dashboard/Sidebar.vue'
import DashboardWidgets from '@/components/DashboardWidgets'
import OpenChatBox from '@/include/Modals/OpenChatBox.vue'
import Feeds from '@/include/Dashboard/Feeds.vue'
import ProfileIcon from '@/include/Dashboard/Feed/ProfileIcon'
import ProgressLoader from '@/components/ProgressLoader'
import SingleFileUpload from '@/components/SingleFileUpload'
import axios from 'axios'

export default {
    name: 'EditPage',
    components: {
        Header,
        Sidebar,
        DashboardWidgets,
        OpenChatBox,
        Feeds,
        ProgressLoader,
        SingleFileUpload,
        VueEditor
    },

    data() {
        return {
            image: '',
            banner: '',
            category: '',
            name: '',
            description: '',
            email: '',
            website: '',
            reach: '',
            facebook: '',
            twitter: '',
            instagram: '',
            youtube: '',
            hide_email: 'false',
            postCategories: '',
            page: '',
            currentUser: '',
        }
    },
    mounted () {
        this.currentUser = this.$store.getters['user/currentUser']
        this.getExtraInfo()
        let id = this.$route.params['id']
        this.getPage(id)
    },
    methods: {
        async getPage(id) {
            this.toggleProgress('show')
            let apiResponse = await ApiService.GetPage(id);
            if (apiResponse["status"] != "success") {} else {
                this.page = apiResponse["data"]['page'];
                if (this.page) {
                    this.name = this.page['name']
                    this.image = this.page['image']
                    this.banner = this.page['banner']
                    this.reach = this.page['reach']
                    this.description = this.page['description']
                    this.category = this.page['category']
                    this.email = this.page['email']
                    this.website = this.page['website']
                    this.hide_email = this.page['hide_email']
                    this.facebook = this.page['facebook']
                    this.twitter = this.page['twitter']
                    this.instagram = this.page['instagram']
                    this.youtube = this.page['youtube']

                    this.pageNameMain = this.page['name']
                    this.pageDescriptionMain = this.page['name']
                    this.pageImageMain = this.page['image']
                }
            }
            this.toggleProgress('hide')
        },
        async getExtraInfo() {
            let apiResponse = await ApiService.ExtraInfo();
            // console.log('getExtraInfo', apiResponse)
            if (apiResponse['status'] == 'success') {
                this.postCategories = apiResponse['data']['post_categories']
            }
        },
        async updatePage() {
            let apiResponse = await ApiService.UpdatePage(this.page['id'], this.image, this.name, this.description, this.category, this.website, this.email, this.reach, this.hide_email, this.banner, this.facebook, this.twitter, this.instagram, this.youtube);
            this.$store.commit("toast/Notify", {
                type: apiResponse["status"],
                message: apiResponse["message"],
            });
            if (apiResponse["status"] != "success") {} else {
                this.image = ''
                this.category = ''
                this.title = ''
                this.content = ''

                location.href = '/dashboard/page/' + this.page['id']
            }
        },
        async deletePagePost() {
            let apiResponse = await ApiService.DeletePagePost(this.page['id']);
            this.$store.commit("toast/Notify", {
                type: apiResponse["status"],
                message: apiResponse["message"],
            });
            if (apiResponse["status"] != "success") {} else {

                location.href = '/dashboard/pages'
            }
        },
        onImageUploaded (response) {
            // Handle files like:
            // console.log('::::',response)
            if(response['status'] == 'success'){
                this.image = response['data']['file_name']
            }
            this.$store.commit("toast/Notify", {
                type: response["status"],
                message: response["message"],
            });
        },
        handleImageAdded: function(file, Editor, cursorLocation, resetUploader) {
            // An example of using FormData
            // NOTE: Your key could be different such as:
            // formData.append('file', file)

            var formData = new FormData();
            formData.append("file", file);

            axios({
                url: this.$store.getters['host/apiUrl'].replace('/api/v2', '/files/upload'),
                method: "POST",
                data: formData
            })
            .then(result => {
                // console.log('result',result)
                const url = result.data.data['file_name']; // Get url from response
                Editor.insertEmbed(cursorLocation, "image", url);
                resetUploader();
            })
            .catch(err => {
                console.log(err);
            });
        },
        onBannerUploaded(response) {
            // Handle files like:
            // console.log('::::',response)
            if (response['status'] == 'success') {
                this.banner = response['data']['file_name']
            }
            this.$store.commit("toast/Notify", {
                type: response["status"],
                message: response["message"],
            });
        },
    },
}
</script>

<style lang="scss" scoped>
.some-modal-content {
    min-width: 400px;
    padding: 25px;

    .buttons button {
        padding: 10px;
        margin: 10px;
    }
}
.postImgheight {
    height:100px
}
.editor {
    height: 350px;
}
</style>
