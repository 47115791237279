<template>
<div id="wrapper">

    <Header />

    <sidebar></sidebar>

    <!-- Main Contents -->
    <div class="main_content">
        <div class="mcontainer">

            <!-- Profile cover -->
            <div class="profile user-profile">

                <div class="profiles_banner">
                    <template v-if="bannerPhoto">
                        <img v-if="bannerPhoto" :src="bannerPhoto" alt="">
                    </template>
                </div>
                <div class="profiles_content">

                    <div class="profile_avatar">
                        <div class="profile_avatar_holder">
                            <template v-if="profilePhoto">
                                <img v-if="profilePhoto" :src="profilePhoto" alt="">
                            </template>
                        </div>
                        <div v-if="shop['online'] == 'true'" class="user_status status_online"></div>
                        <div class="icon_change_photo" hidden="">
                        </div>
                    </div>

                    <div class="profile_info">
                        <h1> {{ shop['name'] }} </h1>
                    </div>

                </div>

                <div class="flex justify-between lg:border-t border-gray-100 flex-col-reverse lg:flex-row pt-2">
                    <nav class="responsive-nav pl-3">
                        <ul uk-switcher="connect: #timeline-tab; animation: uk-animation-fade">
                            <li><a href="#">Products </a></li>
                            <li><a href="#">Info</a></li>
                            <li @click="openShopChat" v-if="shop.user_id == currentUser['id']">
                                <a href="/dashboard/shop-chats">
                                    Messages
                                    <span class="text-white bg-green-500">
                                        <b>{{ unread_count || 0 }}</b>
                                    </span>
                                </a>
                            </li>
                            <li v-else><a href="#">Message Shop</a></li>
                        </ul>
                    </nav>

                    <!-- button actions -->
                    <div class="flex items-center space-x-1.5 flex-shrink-0 pr-4 mb-2 justify-center order-1 relative">
                    </div>

                </div>

            </div>

            <div class="uk-switcher lg:mt-8 mt-4" id="timeline-tab">

                <!-- Products  -->
                <div>
                    <div class="relative uk-slider-old" uk-slider-old="finite: true">

                        <div class="uk-slider-container px-1 py-3">
                            <ul class="uk-slider-items uk-child-width-1-5@m uk-child-width-1-3@s uk-child-width-1-2 uk-grid-small uk-grid" style="transform: translate3d(0px, 0px, 0px);">
                                <li tabindex="-1" class="uk-active" v-for="p in products">
                                    <a :href="'/dashboard/product/'+p.id">
                                        <div class="card">
                                            <div class="card-media h-40">
                                                <div class="card-media-overly"></div>
                                                <img v-if="p.image" :src="p.image" alt="">
                                                <img v-else :src="defaultPostUrl()" alt="">
                                                <span class="absolute bg-white px-2 py-1 text-sm rounded-md m-2"> Label</span>
                                            </div>
                                            <div class="card-body">
                                                <div class="-top-3 absolute bg-blue-100 font-medium px-2 py-1 right-2 rounded-full text text-blue-500 text-sm">
                                                    {{ currencySymbol(p.currency) }}{{ formatAmount(p.amount) }}
                                                </div>
                                                <div class="text-xs font-semibold uppercase text-yellow-500">
                                                    <span v-if="p.shop">{{ p.shop.name }} - </span><span v-if="p.category">{{ p.category.name }}</span>
                                                </div>
                                                <div class="ext-lg font-medium mt-1 t truncate"> {{ p.name }} </div>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                            </ul>

                        </div>
                        <div v-if="products.length == 0" class="bg-white p-2">
                            <h5>Store has no product</h5>
                        </div>
                    </div>
                </div>

                <!-- Info -->
                <div>
                    <div class="w-full space-y-6">

                        <div class="widget card p-5" v-if="shop">
                            <h4 class="text-lg font-semibold"> About {{ shop.name }} </h4>
                            <ul class="text-gray-600 space-y-3 mt-3">
                                <li class="flex items-center space-x-2">
                                    <ion-icon name="home-sharp" class="rounded-full bg-gray-200 text-xl p-1 mr-3"></ion-icon>
                                    Location <strong> {{ shop.country }} , {{ shop.state }} </strong>
                                </li>
                                <li class="flex items-center space-x-2">
                                    <ion-icon name="globe" class="rounded-full bg-gray-200 text-xl p-1 mr-3"></ion-icon>
                                    Address <strong> {{ shop.address }}, {{ shop.city }} </strong>
                                </li>
                                <li class="flex items-center space-x-2">
                                    <ion-icon name="heart-sharp" class="rounded-full bg-gray-200 text-xl p-1 mr-3"></ion-icon>
                                    Description <strong> {{ shop.description }} </strong>
                                </li>
                            </ul>
                            <a v-if="shop.user_id == currentUser['id']" href="/dashboard/shops/update" class="button gray mt-3 w-full"> Edit </a>

                            <a v-else :href="'/dashboard/chats?type=shop&id='+shop.id+'&product_link='+thisPage" class="bg-yellow-200 flex flex-1 font-semibold h-10 items-center justify-center px-4 rounded-md mt-3">
                                Message Vendor
                            </a>
                        </div>

                    </div>
                </div>

                <!-- Messages  -->
                <div v-if="shop.user_id == currentUser['id']"></div>

            </div>

        </div>
    </div>
    <dashboard-widgets></dashboard-widgets>
    <div id="progressLoader">
        <progress-loader></progress-loader>
    </div>

</div>
</template>

<script>
import {
    VueEditor
} from "vue2-editor";
import {
    ApiService
} from '@/services/api.service'
import Header from '@/include/Dashboard/Header.vue'
import Sidebar from '@/include/Dashboard/Sidebar.vue'
import DashboardWidgets from '@/components/DashboardWidgets'
import OpenChatBox from '@/include/Modals/OpenChatBox.vue'
import ProfileIcon from '@/include/Dashboard/Feed/ProfileIcon'
import ProgressLoader from '@/components/ProgressLoader'
import TimelineScreen from '@/include/Dashboard/Timeline/TimelineScreen'
import FriendScreen from '@/include/Dashboard/Timeline/FriendScreen'
import PhotoScreen from '@/include/Dashboard/Timeline/PhotoScreen'
import PageScreen from '@/include/Dashboard/Timeline/PageScreen'
import GroupScreen from '@/include/Dashboard/Timeline/GroupScreen'
import VideoScreen from '@/include/Dashboard/Timeline/VideoScreen'
import SingleFileUpload from '@/components/SingleFileUpload'
import IonIcon from '@/components/IonIcon'
import axios from 'axios'

export default {
    name: 'ShopPage',
    components: {
        Header,
        Sidebar,
        DashboardWidgets,
        OpenChatBox,
        ProgressLoader,
        VueEditor,
        TimelineScreen,
        FriendScreen,
        PhotoScreen,
        PageScreen,
        GroupScreen,
        VideoScreen,
        SingleFileUpload,
        IonIcon
    },
    data() {
        return {
            user: '',
            shop: '',
            products: '',
            currentUser: {},
            thisUser: {},
            profilePhoto: '',
            bannerPhoto: '',
            followings: '',
            followers: '',
            follow: '',
            id: '',
            unread_count: '',
            thisPage: '',
        }
    },
    mounted: function () {
        this.currentUser = this.$store.getters["user/currentUser"];
        let id = this.$route.params['id']
        this.id = id
        this.thisPage = location.href
        this.bannerPhoto = this.defaultPostUrl()
        this.profilePhoto = this.defaultImageUrl()
        this.getShop(id)
    },
    methods: {
        async getShop(id) {
            this.toggleProgress('show')
            let apiResponse = await ApiService.GetShop(id);
            this.toggleProgress('hide')
            if (apiResponse['status'] == 'success') {
                let data = apiResponse['data']
                if(data){
                    this.shop = data['shop']
                    this.products = data['products']
                    this.unread_count = data['unread_count']
                }

                if (this.shop) {
                    if (this.shop['image']) {
                        this.profilePhoto = this.shop['image']
                    }
                }
            }
        },
        async openShopChat(){
            location.href = '/dashboard/shop-chats'
        },
        onImageUploaded(response) {
            // Handle files like:
            // console.log('::::',response)
            if (response['status'] == 'success') {
                this.profilePhoto = response['data']['file_name']
            }
            this.$store.commit("toast/Notify", {
                type: response["status"],
                message: response["message"],
            });
        },
        onBannerUploaded(response) {
            // Handle files like:
            // console.log('::::',response)
            if (response['status'] == 'success') {
                this.bannerPhoto = response['data']['file_name']
            }
            this.$store.commit("toast/Notify", {
                type: response["status"],
                message: response["message"],
            });
        },
        removeBannerPhoto() {
            this.bannerPhoto = ''
        },
        removeProfilePhoto() {
            this.profilePhoto = ''
        }
    },
}
</script>

<style>
.profilePage123 input {
    width: 157px !important;
    height: 32px !important;
}
</style>
