<template>
<div id="wrapper">
    <auth-styles v-if="pageNameMain" :pageNameMain="pageNameMain" :pageDescriptionMain="pageDescriptionMain" :pageImageMain="pageImageMain"></auth-styles>
    <Header />

    <sidebar></sidebar>

    <!-- Main Contents -->
    <div class="main_content">
        <div class="mcontainer" v-if="topic">

            <div class="lg:flex lg:space-x-10">

                <div class="lg:w-3/4 space-y-5">
                    <div class="bg-white mb-3 sm:px-5 px-3 py-5 md:flex" v-if="currentUser['id'] == topic['user_id']">
                        <div class="sm:mr-5 mr-1 text-white sm:mb-1 mb-5">
                            <router-link :to="'/dashboard/edit-forum/'+topic.name_key" class="h-8 px-4 py-3 rounded-md text-sm text-white hover:text-white w-full bg-blue-700">Edit Blog Post</router-link>
                        </div>
                        <div class="text-white">
                            <a @click="confirmDelete" class="h-8 px-4 py-3 rounded-md text-sm text-white hover:text-white w-full bg-red-700 mouse-pointer">Delete Blog Post</a>
                        </div>
                        <confirm-modal title="Delete Blog Post" subTitle="Do you want to delete?" @confirm="confirmDelete"></confirm-modal>
                    </div>

                    <div class="card">

                        <div class="h-44 mb-4 md:h-72 overflow-hidden relative rounded-t-lg w-full">
                            <img v-if="topic.image" :src="topic.image" alt="" class="w-full h-full absolute inset-0 object-cover" />
                            <img v-else :src="defaultPostUrl()" alt="" class="w-12 h-12 rounded-full" />
                        </div>
                        <div class="p-7">

                            <h1 class="lg:text-3xl text-2xl font-semibold mb-6"> {{ topic.title }} </h1>

                            <div class="flex items-center space-x-3 my-4 pb-4 border-b border-gray-100">
                                <img v-if="topic.user && topic.user.profile_photo" :src="topic.user.profile_photo" alt="" class="h-10 w-10 rounded-full">
                                <img v-else :src="defaultImageUrl()" alt="" class="h-10 w-10 rounded-full">
                                <div>
                                    <div class="text-base font-semibold" v-if="topic.user"> 
                                        {{ topic.user.name }} 
                                        <verified-badge v-if="topic.user.verified == 'true'" size="small"></verified-badge>
                                    </div>
                                    <div class="text-base font-semibold" v-else> User </div>
                                    <div class="text-xs"> Published on {{ formatDate(topic.created_at) }} </div>
                                </div>
                            </div>
                            <share-icons :link="thisPage"></share-icons>

                            <div class="space-y-3 mb-5" v-html="customTextRender(topic.content)">
                            </div>

                            <share-icons :link="thisPage"></share-icons>
                        </div>

                    </div>

                    <!-- related forum article -->
                    <div class="card p-6 relative">

                        <h1 class="block text-lg font-semibold"> Related Articles </h1>

                        <div class="relative" uk-slider="finite: true">

                            <div class="uk-slider-container px-1 py-3">
                                <ul class="uk-slider-items uk-child-width-1-3@s uk-child-width-1-2 uk-grid-small uk-grid">
                                    <li v-for="rel_top in related_topics">
                                        <div>
                                            <a :href="'/dashboard/forum/'+rel_top.name_key" class="w-full md:h-32 h-28 overflow-hidden rounded-lg relative block">
                                                <img v-if="rel_top.image" :src="rel_top.image" alt="" class="w-full h-full absolute inset-0 object-cover" />
                                                <img v-else :src="defaultPostUrl()" alt="" class="w-12 h-12 rounded-full" />
                                            </a>
                                            <div class="pt-3">
                                                <a :href="'/dashboard/forum/'+rel_top.name_key" class="font-semibold line-clamp-2"> {{ rel_top.title }}</a>
                                                <div class="flex space-x-2 items-center text-sm pt-2">
                                                    <div> {{ formatDate(rel_top.created_at) }} </div>
                                                    <div class="md:block hidden">·</div>
                                                    <div class="flex items-center">
                                                        <i class="fa fa-tags mr-2"></i> {{ rel_top.page_views }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>

                            </div>
                            <h4 v-if="related_topics.length == 0">No related article</h4>

                        </div>

                    </div>

                    <div class="card p-6 mb-4">

                        <h1 class="block text-lg font-semibold mb-4"> 
                            Comments ({{ topic.comments.length }}) 
                            <button @click="toggleReplyForm('commentSectionTwo')" @onclick="toggleCommentSection" class="ml-5 custom-btn-xs flex-shrink-0 inline-block px-6 py-2 text-center font-medium bg-indigo-500 hover:bg-indigo-600 text-white rounded transition duration-250" >Add Comment</button>
                        </h1>

                        <div class="space-y-5">

                            <div id="commentSectionTwo" class="commentSection bg-gray-100-old rounded-full relative dark:bg-gray-800-old border-t" style="display:none">
                                <h5 class="mb-4" v-if="topic.comments.length == 0">Be the first</h5>
                                <form v-on:submit.prevent="addForumPostComment(topic.id)">
                                    <div class="flex items-center justify-between pl-2 py-1old pr-2 bg-gray-100 rounded-lg" data-path="0.0.0.1.0.1.0.0">
                                        <input v-model="comment" class="block px-6 w-full text-lg bg-transparent outline-none" type="text" placeholder="Add your Comment.." aria-label="" data-path="0.0.0.1.0.1.0.0.0">
                                        <button class="custom-btn-sm flex-shrink-0 inline-block px-6 py-2 text-center font-medium bg-indigo-500 hover:bg-indigo-600 text-white rounded transition duration-250" >
                                            <i class="fa fa-paper-plane"></i>
                                        </button>
                                    </div>
                                </form>
                                <div class="-m-0.5 absolute bottom-0 flex items-center right-3 text-xl">
                                </div>
                            </div>

                            <template v-for="comment in topic.comments">
                                <div class="flex gap-x-4 relative rounded-md" :id="'comment_'+comment.id">
                                    <img v-if="comment.user && comment.user.profile_photo" :src="comment.user.profile_photo" alt="" class="rounded-full shadow w-12 h-12">
                                    <img v-else :src="defaultImageUrl()" alt="" class="rounded-full shadow w-12 h-12">
                                    <a @click="toggleReplyForm(comment.id+'-comment-reply')" class="bg-gray-100 py-1.5 px-4 rounded-full absolute right-0 top-5 mouse-pointer">Reply</a>
                                    <div>
                                        <h4 class="text-base m-0 font-semibold" v-if="comment.user"> 
                                            <user-profile-link v-if="comment.user" :user="comment.user" target=""></user-profile-link>
                                            <verified-badge v-if="comment.user.verified == 'true'" size="small" mt="one"></verified-badge>
                                        </h4>
                                        <span class="text-gray-700 text-sm">{{ formatDate(comment.created_at) }}</span>
                                        <p class="mt-3 md:ml-0 -ml-16" v-html="customTextRender(comment.comment)"></p>

                                    </div>
                                </div>

                                <div class="replySection" :id="comment.id+'-comment-reply'" style="display:none">
                                    <form v-on:submit.prevent="replyForumPostComment(topic.id,comment.id)">
                                        <div class="flex items-center justify-between pl-2 py-1old pr-2 bg-gray-100 rounded-lg" data-path="0.0.0.1.0.1.0.0">
                                            <input v-model="reply" class="block px-6 w-full text-lg bg-transparent outline-none" type="text" placeholder="Add your Reply.." aria-label="" data-path="0.0.0.1.0.1.0.0.0">
                                            <button class="custom-btn-sm flex-shrink-0 inline-block px-6 py-2 text-center font-medium bg-indigo-500 hover:bg-indigo-600 text-white rounded transition duration-250" >
                                                <i class="fa fa-paper-plane"></i>
                                            </button>
                                        </div>
                                    </form>
                                </div>

                                <template v-for="reply in comment.replies">
                                    <div class="flex gap-x-4 relative rounded-md lg:ml-16" :id="'comment_reply_'+reply.id">
                                        <img v-if="reply.user && reply.user.profile_photo" :src="reply.user.profile_photo" alt="" class="rounded-full shadow w-12 h-12">
                                        <img v-else :src="defaultImageUrl()" alt="" class="rounded-full shadow w-12 h-12">
                                        <a @click="toggleReplyForm(reply.id+'-comment-reply-reply')" class="bg-gray-100 py-1.5 px-4 rounded-full absolute right-0 top-5 mouse-pointer">Reply</a>
                                        <div>
                                            <h4 class="text-base m-0 font-semibold" v-if="reply.user"> 
                                                <user-profile-link v-if="reply.user" :user="reply.user" target=""></user-profile-link>
                                                <verified-badge v-if="reply.user.verified == 'true'" size="small" mt="one"></verified-badge>
                                            </h4>
                                            <span class="text-gray-700 text-sm"> {{ formatDate(reply.created_at) }} </span>
                                            <p class="mt-3 md:ml-0 -ml-16">
                                                <!-- <a :href="'/dashboard/timeline/'+comment.user.id" >@{{ comment.user.name }}</a>  -->
                                                <span v-html="customTextRender(reply.reply)"></span>
                                            </p>
                                        </div>
                                    </div>
                                    
                                    <div :id="reply.id+'-comment-reply-reply'" style="display:none" class="replyReplySection lg:ml-16">
                                        <form class="commentReplyForm" v-on:submit.prevent="replyForumPostCommentReply(topic.id,reply.id)">
                                            <div class="flex items-center justify-between pl-2 py-1old pr-2 bg-gray-100 rounded-lg" data-path="0.0.0.1.0.1.0.0">
                                                <input v-model="replyToReply" class="block px-6 w-full text-lg bg-transparent outline-none" type="text" placeholder="Add your Reply.." aria-label="" data-path="0.0.0.1.0.1.0.0.0">
                                                <button class="custom-btn-sm flex-shrink-0 inline-block px-6 py-2 text-center font-medium bg-indigo-500 hover:bg-indigo-600 text-white rounded transition duration-250" >
                                                    <i class="fa fa-paper-plane"></i>
                                                </button>
                                            </div>
                                        </form>
                                    </div>

                                    <hr v-if="reply.replies.length > 0" />
                                    <template v-for="reply2 in reply.replies">
                                        <div class="flex gap-x-4 relative rounded-md lg:ml-16" :id="'comment_reply_reply_'+reply2.id">
                                            <img v-if="reply2.user && reply2.user.profile_photo" :src="reply2.user.profile_photo" alt="" class="rounded-full shadow w-12 h-12">
                                            <img v-else :src="defaultImageUrl()" alt="" class="rounded-full shadow w-12 h-12">
                                            <div>
                                                <h4 class="text-base m-0 font-semibold" v-if="reply2.user">
                                                    <user-profile-link v-if="reply2.user" :user="reply2.user" target=""></user-profile-link>
                                                    <verified-badge v-if="reply2.user.verified == 'true'" size="small" mt="one"></verified-badge>
                                                </h4>
                                                <span class="text-gray-700 text-sm"> {{ formatDate(reply2.created_at) }} </span>
                                                <p class="mt-3 md:ml-0 -ml-16">
                                                    <!-- <a :href="'/dashboard/timeline/'+reply.user.id" >
                                                        @{{ reply.user.name }}
                                                        <verified-badge v-if="reply.user.verified == 'true'" size="small"></verified-badge>
                                                    </a> -->
                                                    <span v-html="customTextRender(reply2.reply)"></span>
                                                </p>
                                            </div>
                                        </div>
                                    </template>
                                    <hr v-if="reply.replies.length > 0" />

                                </template>
                            </template>

                            <div v-if="topic.comments.length > 2" class="text-left mb-5">
                                <button @click="toggleReplyForm('commentSectionOne')" @onclick="toggleCommentSection" class="custom-btn-xs flex-shrink-0 inline-block px-6 py-2 text-center font-medium bg-indigo-500 hover:bg-indigo-600 text-white rounded transition duration-250" >Add Comment</button>
                            </div>
                            <div id="commentSectionOne" class="commentSection bg-gray-100-old rounded-full relative dark:bg-gray-800-old border-t" style="display:none">
                                <h5 class="mb-4" v-if="topic.comments.length == 0">Be the first</h5>
                                <form v-on:submit.prevent="addForumPostComment(topic.id)">
                                    <div class="flex items-center justify-between pl-2 py-1old pr-2 bg-gray-100 rounded-lg" data-path="0.0.0.1.0.1.0.0">
                                        <input v-model="comment" class="block px-6 w-full text-lg bg-transparent outline-none" type="text" placeholder="Add your Comment.." aria-label="" data-path="0.0.0.1.0.1.0.0.0">
                                        <button class="custom-btn-sm flex-shrink-0 inline-block px-6 py-2 text-center font-medium bg-indigo-500 hover:bg-indigo-600 text-white rounded transition duration-250" >
                                            <i class="fa fa-paper-plane"></i>
                                        </button>
                                    </div>
                                </form>
                                <div class="-m-0.5 absolute bottom-0 flex items-center right-3 text-xl">
                                </div>
                            </div>

                        </div>

                    </div>

                </div>
                <div class="lg:w-1/4 w-full card-mobile">

                    <div uk-sticky="offset:100" class="uk-sticky">

                        <h2 class="text-lg font-semibold mb-3 mt-3 lg:mt-0"> Recently Posted </h2>
                        <ul class="">
                            <li v-for="rt in recent_topics" class="">
                                <a :href="'/dashboard/forum/'+rt.name_key" class="hover:bg-gray-100 dark:bg-black rounded-md p-2 -mx-2 block">
                                    <h3 class="font-medium line-clamp-2"> {{ rt.title }} </h3>
                                    <div class="flex items-center my-auto text-xs space-x-1.5">
                                        <div> {{ formatDate(rt.created_at) }}</div>
                                        <div class="pb-1"> . </div>
                                        <i class="fa fa-tags mr-2"></i>
                                        <div> {{ rt.page_views }}</div>
                                    </div>
                                </a>
                            </li>
                        </ul>
                        <div v-if="recent_topics.length == 0" class="bg-white p-2">
                            <h4>No Recent Forum Post</h4>
                        </div>
                        <br>

                    </div>

                </div>

            </div>

        </div>
        <div id="progressLoader">
            <progress-loader></progress-loader>
        </div>
    </div>

    <dashboard-widgets page="forum"></dashboard-widgets>
</div>
</template>

<script>
import {
    ApiService
} from '@/services/api.service'
import Header from '@/include/Dashboard/Header.vue'
import Sidebar from '@/include/Dashboard/Sidebar.vue'
import DashboardWidgets from '@/components/DashboardWidgets'
import OpenChatBox from '@/include/Modals/OpenChatBox.vue'
import Feeds from '@/include/Dashboard/Feeds.vue'
import ProfileIcon from '@/include/Dashboard/Feed/ProfileIcon'
import ProgressLoader from '@/components/ProgressLoader'
import ShareIcons from '@/components/ShareIcons'
import AuthStyles from '@/include/Auth/AuthStyles'
import VerifiedBadge from '@/components/VerifiedBadge'
import UserProfileLink from '@/components/UserProfileLink'

export default {
    name: 'Forum',
    components: {
        Header,
        Sidebar,
        DashboardWidgets,
        OpenChatBox,
        Feeds,
        ProgressLoader,
        ShareIcons,
        AuthStyles,
        VerifiedBadge,
        UserProfileLink
    },

    data() {
        return {
            topic: '',
            related_topics: '',
            recent_topics: '',
            comment: '',
            reply: '',
            currentUser: {},
            thisPage: '',
            pagination: {
                limit: 20,
                offset: 0,
                total: 60
            },
            pageNameMain: '',
            pageDescriptionMain: '',
            pageImageMain: '',
            replyToReply: '',
            requestProgress: 'false'
        }
    },
    mounted: function () {
        this.currentUser = this.$store.getters['user/currentUser'] || {}
        let id = this.$route.params['id']
        this.getForumPost(id)
        this.thisPage = location.href
    },
    methods: {
        async getForumPost(id) {
            this.toggleProgress('show')
            let apiResponse = await ApiService.GetForumPost(id);
            if (apiResponse["status"] != "success") {} else {
                this.topic = apiResponse["data"]['post'];
                this.recent_topics = apiResponse["data"]['recent_forum_posts'];
                this.related_topics = apiResponse["data"]['related_forum_posts'];
                if(this.topic){
                    this.pageNameMain = this.topic['title']
                    this.pageDescriptionMain = this.topic['title']
                    this.pageImageMain = this.topic['image']
                }
                let vueThis = this
                setTimeout(() => {
                    vueThis.locateComment()
                }, 2000);
            }
            this.toggleProgress('hide')
        },
        async deleteForumPost() {
            this.toggleProgress('show')
            let apiResponse = await ApiService.DeleteForumPost(this.topic['id']);
            this.$store.commit("toast/Notify", {
                type: apiResponse["status"],
                message: apiResponse["message"],
            });
            if (apiResponse["status"] != "success") {} else {
                location.href = '/dashboard/forums'
            }
        },
        confirmDelete() {
            let proceed = confirm("Are you sure you want to proceed?");
            if (proceed) {
                this.deleteForumPost()
            } else {
                //don't proceed
            }
        },
        async addForumPostComment(post_id) {
            this.toggleProgress('show')
            let apiResponse = await ApiService.AddForumPostComment(post_id, this.comment);
            this.$store.commit("toast/Notify", {
                type: apiResponse["status"],
                message: apiResponse["message"],
            });
            if (apiResponse["status"] != "success") {} else {
                this.comment = ''
                this.topic = apiResponse["data"];
            }
            this.toggleProgress('hide')
        },
        async replyForumPostComment(post_id, comment_id) {
            this.toggleProgress('show')
            let apiResponse = await ApiService.ReplyForumPostComment(post_id, comment_id, this.reply);
            this.$store.commit("toast/Notify", {
                type: apiResponse["status"],
                message: apiResponse["message"],
            });
            if (apiResponse["status"] != "success") {} else {
                this.reply = ''
                this.topic = apiResponse["data"];
                this.closeOthersOpenComment()
            }
            this.toggleProgress('hide')
        },
        async replyForumPostCommentReply(post_id, reply_id) {
            this.toggleProgress('show')
            let apiResponse = await ApiService.ReplyForumPostCommentReply(post_id, reply_id, this.replyToReply);
            this.$store.commit("toast/Notify", {
                type: apiResponse["status"],
                message: apiResponse["message"],
            });
            if (apiResponse["status"] != "success") {} else {
                this.replyToReply = ''
                this.topic = apiResponse["data"];
                this.closeOthersOpenComment()
            }
            this.toggleProgress('hide')
        },
        locateComment(){
            let url = location.href
            let contentLocator = ''
            if(url.search('comment_id=') > -1){
                let urlSplit = url.split('comment_id=')
                let commentId = urlSplit[1]
                contentLocator = '#comment_'+commentId
            }else if(url.search('comment_reply_id=') > -1){
                let urlSplit = url.split('comment_reply_id=')
                let commentReplyId = urlSplit[1]
                contentLocator = '#comment_reply_'+commentReplyId
            }else if(url.search('comment_reply_reply_id=') > -1){
                let urlSplit = url.split('comment_reply_reply_id=')
                let commentReplyReplyId = urlSplit[1]
                contentLocator = '#comment_reply_reply_'+commentReplyReplyId
            }
            if(contentLocator){
                $(contentLocator).addClass('obvious')
                this.scrollToContent(contentLocator)
                setTimeout(() => {
                    $(contentLocator).removeClass('obvious', {duration:2000,effect:'blind'})
                }, 4000);
            }
        },
        closeOthersOpenComment(){
            $(".replySection").hide()
            $(".replyReplySection").hide()
            $("#commentSectionTwo").show()
        }
    },
}
</script>

<style lang="scss" scoped>
.some-modal-content {
    min-width: 400px;
    padding: 25px;

    .buttons button {
        padding: 10px;
        margin: 10px;
    }
}
</style>
