<template>
<div id="wrapper">
    <auth-styles pageNameMain="Dashboard"></auth-styles>

    <Header />

    <sidebar></sidebar>

    <!-- Main Contents -->
    <div class="main_content">
        <div class="mcontainer">

            <!--  Feeds  -->
            <div class="lg:flex lg:space-x-5">
                <div class="lg:w-3/4 lg:px-5 space-y-7">

                    <!-- user story -->
                    <div class="ps-12 px-4 py-2" style="overflow-x: scroll;">
                        <div uk-grid class="user_story uk-child-width-expand grid-old md:grid-cols-5-old grid-cols-3-old gap-1 lg:-mx-20-old relative" style="width: max-content;">
                            <template v-if="my_story.length == 0 && storyLoading == false">
                                <a href="javascript:;" uk-toggle="target: body ; cls: story-active" style="padding-left: 0;">
                                    <div class="single_story">
                                        <img v-if="currentUser && currentUser.banner_photo" :src="currentUser.banner_photo" alt="">
                                        <img v-else :src="defaultImageUrl()" alt="">
                                        <div class="story-avatar">
                                            <img v-if="currentUser && currentUser.profile_photo" :src="currentUser.profile_photo" alt="">
                                            <img v-else :src="defaultImageUrl()" alt="">
                                        </div>
                                        <div class="story-content">
                                            <h4 class="flex">
                                                <span style="width: 120px;text-align: center;">{{ textMinify(currentUser.name,11) }} </span>
                                                <verified-badge v-if="currentUser.verified == 'true'" size="small" mt="zero"></verified-badge>
                                            </h4>
                                        </div>
                                    </div>
                                </a>
                            </template>

                            <template v-for="story in my_story" v-if="story.items.length > 0">
                                <a href="javascript:;" v-on:click="setStoryContent(story)" uk-toggle="target: #view-status-modal ; cls: story-active" style="padding-left: 0;">
                                    <div class="single_story">
                                        <img v-if="story['last_type'] == 'image'" :src="story['last_content']" alt="">
                                        <div class="single_story_div" v-if="story['last_type'] == 'text'" :style="'background-color:'+story['last_color']">
                                            <p>{{ story['last_content'] }}</p>
                                        </div>
                                        <div class="single_story_div" v-if="story['last_type'] == 'url'" :style="'background-color:'+story['last_color']">
                                            <a :href="story['last_content']" target="_blank">{{ story['last_content'] }}</a>
                                        </div>
                                        <div class="story-avatar">
                                            <img v-if="currentUser.profile_photo" :src="currentUser.profile_photo" alt="">
                                            <img v-else :src="defaultImageUrl()" alt="">
                                        </div>
                                        <div class="story-content">
                                            <a v-if="story && story.user" :href="'/dashboard/timeline/'+story.user.id">
                                                <h4 class="flex">
                                                    <span style="width: 120px;text-align: center;">{{ textMinify(currentUser.name,11) }} </span>
                                                    <verified-badge v-if="story.user && story.user.verified == 'true'"></verified-badge>
                                                </h4>
                                            </a>
                                        </div>
                                    </div>
                                </a>
                            </template>

                            <template v-for="story in stories" v-if="story.items.length > 0">
                                <a v-on:click="setStoryContent(story)" href="javascript:;" uk-toggle="target: #view-status-modal; cls: story-active" style="padding-left: 0;">
                                    <div class="single_story">
                                        <img v-if="story['last_type'] == 'image'" :src="story['last_content']" alt="">
                                        <div class="single_story_div" v-if="story['last_type'] == 'text'" :style="'background-color:'+story['last_color']">
                                            <p>{{ story['last_content'] }}</p>
                                        </div>
                                        <div class="single_story_div" v-if="story['last_type'] == 'url'" :style="'background-color:'+story['last_color']">
                                            <a :href="story['last_content']" target="_blank">{{ story['last_content'] }}</a>
                                        </div>
                                        <div class="story-avatar">
                                            <img v-if="story.user && story.user.profile_photo" :src="story.user.profile_photo" alt="">
                                            <img v-else :src="defaultImageUrl()" alt="">
                                        </div>
                                        <div class="story-content">
                                            <a v-if="story && story.user" :href="'/dashboard/timeline/'+story.user.id">
                                                <h4 class="flex">
                                                    <span style="width: 120px;text-align: center;">{{ textMinify(story.user.name,11) }} </span>
                                                    <verified-badge v-if="story.user && story.user.verified == 'true'"></verified-badge>
                                                </h4>
                                            </a>
                                        </div>
                                    </div>
                                </a>
                            </template>

                            <template v-if="i_follow.length > 0 && storyLoading == false">
                                <a v-for="follow in i_follow" href="javascript:;" style="padding-left: 0;">
                                    <div class="single_story">
                                        <img v-if="follow && follow.user && follow.user.banner_photo" :src="follow.user.banner_photo" alt="">
                                        <img v-else :src="defaultImageUrl()" alt="">
                                        <div class="story-avatar">
                                            <img v-if="follow && follow.user && follow.user.profile_photo" :src="follow.user.profile_photo" alt="">
                                            <img v-else :src="defaultImageUrl()" alt="">
                                        </div>
                                        <div class="story-content">
                                            <a v-if="follow && follow.user" :href="'/dashboard/timeline/'+follow.user.id">
                                                <h4 class="flex">
                                                    <span style="width: 120px;text-align: center;">{{ textMinify(follow.user.name,11) }} </span>
                                                    <verified-badge v-if="follow && follow.user && follow.user.verified == 'true'" size="small" mt="zero"></verified-badge>
                                                </h4>
                                            </a>
                                        </div>
                                    </div>
                                </a>
                            </template>

                            <template v-if="i_follow.length == 0 && storyLoading == false && follow_me.length > 0">
                                <a v-for="follow in follow_me" href="javascript:;" style="padding-left: 0;">
                                    <div class="single_story">
                                        <img v-if="follow.user.banner_photo" :src="follow.user.banner_photo" alt="">
                                        <img v-else :src="defaultImageUrl()" alt="">
                                        <div class="story-avatar">
                                            <img v-if="follow.user.profile_photo" :src="follow.user.profile_photo" alt="">
                                            <img v-else :src="defaultImageUrl()" alt="">
                                        </div>
                                        <div class="story-content">
                                            <a v-if="follow && follow.follower" :href="'/dashboard/timeline/'+follow.follower.id">
                                                <h4 class="flex">
                                                    <span style="width: 120px;text-align: center;">{{ textMinify(follow.user.name,11) }} </span>
                                                    <verified-badge v-if="follow.user.verified == 'true'" size="small" mt="zero"></verified-badge>
                                                </h4>
                                            </a>
                                        </div>
                                    </div>
                                </a>
                            </template>

                            <span v-if="stories.length > 3" class="absolute bg-white flex items-center justify-center p-2 rounded-full 
                                shadow-md text-xl w-9 z-10 uk-position-center-right -mr-4" uk-toggle-old="target: body ; cls: story-active">
                                <i class="icon-feather-chevron-right"></i>
                            </span>

                        </div>

                        <div v-if="storyLoading == true" class="text-center my-3">
                            <i class="fa fa-spin fa-spinner fa-2x"></i>
                        </div>
                    </div>

                    <!-- create post -->

                    <create-post-component></create-post-component>

                    <google-banner-ad></google-banner-ad>

                    <div class="p-3 card" style="overflow-x:scroll">
                        <div style="width:620px" class="py-1">
                            <template v-for="(l, index) in quick_links.slice(0,6)">
                                <a v-if="l.link == 'autoLoginPromoter'" :class="quick_link_colors[0]+' custom_button mr-1 text-white col-xs-12'" :href="autoLoginPromoter">{{l.title}}</a>
                                <a v-else :class="quick_link_colors[0]+' custom_button mr-1 text-white col-xs-12'" :href="l.link">{{l.title}}</a>
                            </template>
                        </div>
                    </div>

                    <f-c-m-permission></f-c-m-permission>

                    <feeds></feeds>

                </div>
                <birthday-contacts-card-component></birthday-contacts-card-component>
            </div>

        </div>
    </div>

    <dashboard-widgets></dashboard-widgets>
    <progress-loader-simple v-if="saving_feed == 'true'"></progress-loader-simple>

</div>
</template>

<script>
import {
    ApiService
} from "@/services/api.service";
import Header from '@/include/Dashboard/Header.vue'
import Sidebar from '@/include/Dashboard/Sidebar.vue'
import DashboardWidgets from '@/components/DashboardWidgets'
import OpenChatBox from '@/include/Modals/OpenChatBox.vue'
import Feeds from '@/include/Dashboard/Feeds.vue'
import AuthStyles from '@/include/Auth/AuthStyles'
import CreatePostComponent from '@/components/CreatePostComponent'
import BirthdayContactsCardComponent from '@/components/BirthdayContactsCardComponent'
import GoogleBannerAd from '@/include/Dashboard/Feed/GoogleBannerAd'
import VerifiedBadge from '@/components/VerifiedBadge'
import ProgressLoaderSimple from '@/components/ProgressLoaderSimple'
import FCMPermission from '@/components/FCMPermission'

export default {
    name: 'HomePage',
    components: {
        Header,
        Sidebar,
        DashboardWidgets,
        OpenChatBox,
        Feeds,
        AuthStyles,
        CreatePostComponent,
        BirthdayContactsCardComponent,
        GoogleBannerAd,
        VerifiedBadge,
        ProgressLoaderSimple,
        FCMPermission,
    },

    data() {
        return {
            msg: 'Welcome to Index!!!',
            isShownModal: false,
            inputError: false,
            checkboxState: false,
            storyLoading: false,
            saving_feed: 'false',
            currentUser: '',
            autoLoginPromoter: "",
            stories: [],
            my_story: [],
            i_follow: [],
            follow_me: [],
            current_story: {},
            quick_links: [
                // {
                //     'title': 'Sell product',
                //     'link': '/dashboard/products',
                // },
                // {
                //     'title': 'Get a Skill',
                //     'link': '/dashboard/courses',
                // },
                {
                    'title': 'Post in Forum',
                    'link': '/dashboard/forum',
                },
                {
                    'title': 'Blog Post',
                    'link': '/dashboard/blogs',
                },
                // {
                //     'title': 'Find Job',
                //     'link': '/dashboard/jobs',
                // },
                {
                    'title': 'Advertise on vitisocial',
                    'link': '/dashboard/advertise/all',
                },
                {
                    'title': 'Earn from tasks',
                    'link': "autoLoginPromoter",
                },
            ],
            quick_link_colors: [
                'bg-purple-600',
                'bg-orange',
                'bg-blue-500',
                'bg-yellow-600',
                'bg-blue-900',
                'bg-black',
                'bg-indigo-500',
            ],
            pagination: {
                limit: 20,
                offset: 0,
                total: 60
            },
            googleAd: '',
        }
    },
    mounted () {
        let currentUser = this.$store.getters["user/currentUser"] || "";
        this.currentUser = currentUser;
        this.quick_links = this.randomizeItems(this.quick_links)
        // this.quick_link_colors = this.randomizeItems(this.quick_link_colors)
        this.getStory()
        this.setupLinks()
        // this.getPostAds()

        let vueThis = this
        this.$root.$on("close-view-status-modal",() => {
            // console.log('closing modal')
            if($("#view-status-modal").length > 0){
                UIkit.modal('#view-status-modal').hide();
            }
            vueThis.current_story = {}
        })
        this.$root.$on("saving-feed",() => {
            console.log('saving-------------------')
            vueThis.saving_feed = 'true'
        })
        this.$root.$on("saved-feed",() => {
            console.log('saved-------------------')
            vueThis.saving_feed = 'false'
        })
    },

    methods: {
        async getPostAds(){
            let apiResponse = await ApiService.GetPostAds('google', 5)
            if(apiResponse['status'] != 'success'){
            }else{
                if(apiResponse['data'].length > 0){
                    this.googleAd = apiResponse['data'].sort(() => Math.random() - 0.5)[0]
                    console.log('=============google ad', this.googleAd)
                }
            }
        },
        async getStory() {
            this.storyLoading = true
            let apiResponse = await ApiService.GetStory()
            if (apiResponse['status'] != 'success') {} else {
                this.storyLoading = false
                this.stories = apiResponse['data']['user_stories']
                this.my_story = apiResponse['data']['my_story']
                this.i_follow = apiResponse['data']['i_follow']
                this.follow_me = apiResponse['data']['follow_me']
                // console.log(this.stories)
            }
        },
        autoLoginPromoterUrl(){
            return this.autoLoginPromoter
        },
        setupLinks(){
            let accessToken = this.$store.getters['auth/accessToken']
            // console.log('cUser', accessToken)
            let promoterUrl = this.$store.getters['host/promoterUrl']+'/auto/'+accessToken
            let advertiserUrl = this.$store.getters['host/advertiserUrl']+'/auto/'+accessToken

            this.autoLoginAdvertiser = advertiserUrl
            this.autoLoginPromoter = promoterUrl
        },
        setStoryContent(story) {
            this.current_story = story
            this.$root.$emit("open-view-status-modal", story)
        },
        showToast() {
            // console.log('aaa')
            this.$store.commit('toast/NEW', {
                type: 'success',
                message: 'hello'
            })
        },
        submitModalHandler() {
            // some logic
            this.isShownModal = false
        },
        onBlur() {
            console.log('onBlur!!!')
        },
        onEnter() {
            console.log('onEnter!!!')
        },
        onEsc() {
            console.log('onEsc!!!')
        },
        onClickIcon() {
            console.log('onClickIcon!!!!')
        }
    }
}
</script>

<style lang="scss" scoped>
.some-modal-content {
    min-width: 400px;
    padding: 25px;

    .buttons button {
        padding: 10px;
        margin: 10px;
    }
}

.single_story_div {
    width: 100%;
    height: 100%;
    position: absolute;
    padding: 10px;
    color: #fff;
    background-color: #333;
    align-items: center;
    display: flex;
}
input[type=text]{
    -webkit-transition: all .2s ease-out;
    -moz-transition: all .2s ease-out;
    -ms-transition: all .2s ease-out;
    -o-transition: all .2s ease-out;
    transition: all .2s ease-out
}
input[type=text]:focus {
    -webkit-transition: all .2s ease-out;
    -moz-transition: all .2s ease-out;
    -ms-transition: all .2s ease-out;
    -o-transition: all .2s ease-out;
    transition: all .2s ease-out
}
@media (max-width: 768px) {
    .user_story a:nth-child(3),
    .user_story a:nth-child(4) {
        display: block;
    }
}
</style>
