<template>
<div class="upload-single component">
    <div v-if="loading == false">
        <div v-if="type == 'image'">
            <input class="upload-input" type="file" @change="onFileChange($event)" multiple accept="image/*" ref="file">
        </div>
        <div v-else-if="type == 'audio'">
            <input class="upload-input" type="file" @change="onFileChange($event)" multiple accept="audio/*" ref="file">
        </div>
        <div v-else-if="type == 'video'">
            <input class="upload-input" type="file" @change="onFileChange($event)" multiple accept="video/*" ref="file">
        </div>
        <div v-else>
            <input class="upload-input" type="file" @change="onFileChange($event)" ref="file">
        </div>
        <slot></slot>
    </div>
    <div v-else>
        <i class="fa fa-spin fa-spinner fa-2x"></i>
    </div>
</div>
</template>

<script>
import {
    ApiService
} from "@/services/api.service";
import {
    FileUploadService
} from 'v-file-upload'
import FileUpload from 'v-file-upload'
export default {
    name: 'SingleFilePreload',
    props: {
        type: String,
    },
    data() {
        return {
            previewImageData: '',
            imageMaxSize: "12072864",
            audioMaxSize: "2072864",
            videoMaxSize: "12072864",
            imageAccept: ".png,.jpg,.jpeg,.gif",
            audioAccept: ".mp3",
            videoAccept: ".mp4,.avi,.3gp",
            file: "",
            url: '',
            loading: false,
            headers: {}
        }
    },
    components: {
        FileUpload
    },
    mounted() {
    },
    methods: {
        onProgress(event) {
            // console.log(event)
        },
        onFileChange(e) {
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length) {
                this.$store.commit("toast/Notify", {
                    type: 'warning',
                    message: 'File not found',
                });
                return
            }
            let maxFileSizeUnits = ''
            let theFileSize = ''
            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                if(this.type == 'image'){
                    this.maxSize = this.imageMaxSize
                }else if(this.type == 'audio'){
                    this.maxSize = this.audioMaxSize
                }else if(this.type == 'video'){
                    this.maxSize = this.videoMaxSize
                }else{
                    this.maxSize = this.audioMaxSize
                }

                if (file.size > this.maxSize) {
                    theFileSize = parseInt(file.size)/1000
                    if(theFileSize > 1000){
                        theFileSize = parseInt(theFileSize)/1000
                    }else{
                        theFileSize = parseInt(theFileSize)+'kb'
                    }
                    if(theFileSize > 1000){
                        theFileSize = parseInt(theFileSize)/1000
                    }else{
                        theFileSize = parseInt(theFileSize)+'mb'
                    }
                    maxFileSizeUnits = parseInt(this.maxSize)/1000
                    if(maxFileSizeUnits > 1000){
                        maxFileSizeUnits = parseInt(maxFileSizeUnits)/1000
                    }else{
                        maxFileSizeUnits = parseInt(maxFileSizeUnits)+'kb'
                    }
                    if(maxFileSizeUnits > 1000){
                        maxFileSizeUnits = parseInt(maxFileSizeUnits)/1000
                    }else{
                        maxFileSizeUnits = parseInt(maxFileSizeUnits)+'mb'
                    }
                    this.noty('error', 'Max file size of '+maxFileSizeUnits+' exceeded: '+theFileSize)
                    continue
                }
                const element = files[i];
                
                this.file = element
                this.$emit('uploadSuccess', this.file)
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.upload-single {
    .preview {
        width: 300px;
    }
}

.upload-input {
    height: 50px;
    width: 50px;
    padding: 0;
    opacity: 0;
    cursor: pointer;
    position: absolute;
}

.file-upload .file-upload-progress {
    display: none;
}
</style>
