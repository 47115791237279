<template>
<div id="wrapper">

    <Header />

    <sidebar></sidebar>

    <!-- Main Contents -->
    <div class="main_content">
        <div class="mcontainer">

            <!--  breadcrumb -->
            <div class="breadcrumb-area py-0">
                <div class="breadcrumb">
                    <ul class="m-0">
                        <li>
                            <a href="/dashboard/courses">Courses</a>
                        </li>
                        <li class="active">
                            <a href="">Create New Course </a>
                        </li>
                    </ul>
                </div>
            </div>

            <!-- create page-->
            <div class="max-w-2xl m-auto shadow-md rounded-md bg-white lg:mt-20">

                <!-- form header -->
                <div class="text-center border-b border-gray-100 py-6">
                    <h3 class="font-bold text-xl"> Create New Course </h3>
                </div>

                <!-- form body -->
                <div class="sm:p-10 p-3 space-y-7">

                    <div class="line">
                        <input v-model="title" class="line__input" id="title" name="title" type="text" onkeyup="this.setAttribute('value', this.value);" value="" autocomplete="off">
                        <span for="title" class="line__placeholder"> Title </span>
                    </div>
                    <div class="line">
                        <input v-model="sub_title" class="line__input" id="sub_title" name="sub_title" type="text" onkeyup="this.setAttribute('value', this.value);" value="" autocomplete="off">
                        <span for="sub_title" class="line__placeholder"> Sub Title </span>
                    </div>
                    <div class="flex items-center">
                        <div class="mr-2">
                            <p>Course Image</p>
                            <img v-if="image" :src="image" class="postImgheight" />
                            <img v-else :src="defaultPostUrl()" class="postImgheight" />
                        </div>
                        <single-file-upload type="image" @uploadSuccess="onImageUploaded">
                            <p>Select<br /> Image</p>
                        </single-file-upload>
                    </div>
                    <div>
                        <!-- <label for=""> Category </label><br/><br/> -->
                        <select v-model="level" id="" name="" class="shadow-none with-border ">
                            <option value="" disabled>Select Level</option>
                            <option >Beginner</option>
                            <option >Intermediate</option>
                            <option >Advanced</option>
                        </select>
                    </div>
                    <div>
                        <!-- <label for=""> Category </label><br/><br/> -->
                        <select v-model="payment" id="" name="" class="shadow-none with-border ">
                            <option value="" disabled>Select Payment Plan</option>
                            <option value="free">Free</option>
                            <option value="paid">Paid</option>
                        </select>
                    </div>
                    <div>
                        <!-- <label for=""> Category </label><br/><br/> -->
                        <select v-model="categorySubcategory" id="" name="" class="shadow-none with-border " @change="setCategorySubcategory">
                            <option value="">Select Category</option>
                            <template v-for="c in categories">
                                <option v-for="s in c.sub_categories" :value="c.name+'-'+s">{{ c.name }} - {{ s }}</option>
                            </template>
                        </select>
                    </div>
                    <div v-if="payment == 'paid'">
                        <!-- <label for=""> Category </label><br/><br/> -->
                        <select v-model="currency" id="" name="" class="shadow-none with-border ">
                            <option value="">Select Currency</option>
                            <option v-for="c in currencies" :value="c">{{ c }}</option>
                        </select>
                    </div>

                    <div v-if="payment == 'paid'" class="line">
                        <input v-model="amount" class="line__input" id="amount" name="amount" type="phone" onkeyup="this.setAttribute('value', this.value);" value="" autocomplete="off">
                        <span for="amount" class="line__placeholder"> Amount </span>
                    </div>
                    <div class="">
                        <label for="" class="mb-2"> Description </label>
                        <!-- <vue-editor id="editor" v-model="description" useCustomImageHandler @image-added="handleImageAdded"></vue-editor> -->
                        <textarea v-model="description" class="bg-smoke h-32 p-2" id="" name="" type="text" onkeyup="this.setAttribute('value', this.value);" value="" autocomplete="off" style="background-color: #f3f4f6 !important;"></textarea>
                        <!-- <span for="username" class="line__placeholder"> Description </span> -->
                    </div>

                </div>

                <!-- form footer -->
                <div class="border-t flex justify-between lg:space-x-10 p-7 bg-gray-50 rounded-b-md">
                    <button type="button" class="button lg:w-1/2" @click="addCourse">
                        Create Course
                    </button>
                </div>

            </div>

        </div>
    </div>

    <progress-loader v-if="requestProgress == 'true'"></progress-loader>
    <dashboard-widgets page="create-course"></dashboard-widgets>

</div>
</template>

<script>
import {
    VueEditor
} from "vue2-editor";
import {
    ApiService
} from '@/services/api.service'
import Header from '@/include/Dashboard/Header.vue'
import Sidebar from '@/include/Dashboard/Sidebar.vue'
import DashboardWidgets from '@/components/DashboardWidgets'
import OpenChatBox from '@/include/Modals/OpenChatBox.vue'
import Feeds from '@/include/Dashboard/Feeds.vue'
import ProfileIcon from '@/include/Dashboard/Feed/ProfileIcon'
import ProgressLoader from '@/components/ProgressLoader'
import SingleFileUpload from '@/components/SingleFileUpload'
import axios from 'axios'

export default {
    name: 'CreateCourse',
    components: {
        Header,
        Sidebar,
        DashboardWidgets,
        OpenChatBox,
        Feeds,
        ProgressLoader,
        SingleFileUpload,
        VueEditor
    },

    data() {
        return {
            image: '',
            category: '',
            title: '',
            currency: 'USD',
            amount: '',
            description: '',
            sub_title: '',
            level: '',
            payment: '',
            curriculum: {},
            categories: '',
            currencies: '',
            categorySubcategory: '',
            category: '',
            sub_category: '',
            requestProgress: 'false'
        }
    },
    mounted: function () {
        this.getExtraInfo()
    },
    methods: {
        async setCategorySubcategory(){
            let catSubcat = this.categorySubcategory
            let catSubcatObj = catSubcat.split("-")
            this.category = catSubcatObj[0]
            this.sub_category = catSubcatObj[1]
        },
        async getExtraInfo() {
            this.requestProgress = 'true'
            let apiResponse = await ApiService.ExtraInfo();
            // console.log('getExtraInfo', apiResponse)
            if (apiResponse['status'] == 'success') {
                this.categories = apiResponse['data']['course_categories']
                this.currencies = apiResponse['data']['currencies']
            }
            this.requestProgress = 'false'
        },
        async addCourse() {
            this.requestProgress = 'true'
            let apiResponse = await ApiService.CreateCourse(this.title, this.sub_title, this.image, this.level, this.payment, this.currency, this.amount, this.category, this.sub_category, this.description);
            this.$store.commit("toast/Notify", {
                type: apiResponse["status"],
                message: apiResponse["message"],
            });
            if (apiResponse["status"] != "success") {
            } else {
                if(apiResponse['data']['course']['id']){
                    location.href = '/dashboard/update-course/'+apiResponse['data']['course']['id']
                }
            }
            this.requestProgress = 'false'
        },
        onImageUploaded(response) {
            // Handle files like:
            // console.log('::::',response)
            if (response['status'] == 'success') {
                this.image = response['data']['file_name']
            }
            this.$store.commit("toast/Notify", {
                type: response["status"],
                message: response["message"],
            });
        },
        handleImageAdded: function (file, Editor, cursorLocation, resetUploader) {
            // An example of using FormData
            // NOTE: Your key could be different such as:
            // formData.append('file', file)

            var formData = new FormData();
            formData.append("file", file);

            axios({
                    url: this.$store.getters['host/apiUrl'].replace('/api/v2', '/files/upload'),
                    method: "POST",
                    data: formData
                })
                .then(result => {
                    // console.log('result', result)
                    const url = result.data.data['file_name']; // Get url from response
                    Editor.insertEmbed(cursorLocation, "image", url);
                    resetUploader();
                })
                .catch(err => {
                    console.log(err);
                });
        }
    },
}
</script>

<style lang="scss" scoped>
.some-modal-content {
    min-width: 400px;
    padding: 25px;

    .buttons button {
        padding: 10px;
        margin: 10px;
    }
}

.postImgheight {
    height: 100px
}

.editor {
    height: 350px;
}
</style>
