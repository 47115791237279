<template>
<div id="wrapper">
    <Header />

    <sidebar></sidebar>

    <!-- Main Contents -->
    <div class="main_content">
        <div class="mcontainer">
            <div class="lg:flex lg:space-x-12">
                <div class="lg:w-2/3 flex-shirink-0">
                    <div class="flex justify-between relative md:mb-4 mb-3">
                        <div class="flex-1 text-gray-900">
                            <h2 class="text-2xl font-semibold">Forums</h2>
                            <nav class="responsive-nav md:m-0 -mx-4 style-2">
                                <ul>
                                    <li :class="recentActive">
                                        <a @click="makeActiveSection('Recent')" class="mouse-pointer lg:px-2"> Recent </a>
                                    </li>
                                    <li :class="trendingActive"><a @click="makeActiveSection('Trending')" class="mouse-pointer lg:px-2"> Trending </a></li>
                                    <li :class="oldestActive"><a @click="makeActiveSection('Oldest')" class="mouse-pointer lg:px-2"> Oldest </a></li>
                                    <li :class="userActive"><a @click="makeActiveSection('User')" class="mouse-pointer lg:px-2"> Mine </a></li>
                                </ul>
                            </nav>
                        </div>
                        <a href="/dashboard/create-forum" class="flex items-center justify-center h-10 w-10 z-10 rounded-full bg-blue-600 text-white hover:text-white absolute right-0" data-tippy-placement="left" title="Create New Forum Post">
                            <svg class="w-6 h-6" fill="currentColor" viewbox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" style="padding:2px">
                                <path fill-rule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clip-rule="evenodd"></path>
                            </svg>
                        </a>
                    </div>

                    <ul class="card divide-y divide-gray-100 sm:m-0 sm:-mx-5">
                        <li v-for="topic in topics">
                            <div class="flex items-start space-x-5 p-7">
                                <img v-if="topic.image" :src="topic.image" alt="" class="w-12 h-12 rounded-full" />
                                <img v-else :src="defaultPostUrl()" alt="" class="w-12 h-12 rounded-full" />
                                <div class="flex-1">
                                    <router-link :to="'/dashboard/forum/' + topic.name_key" class="text-lg font-semibold line-clamp-1 mb-1 capitalize">
                                        {{ topic.title }}
                                    </router-link>
                                    <p class="text-sm text-gray-400 mb-2 capitalize">
                                        <template v-if="topic.user">
                                            Posted By: <router-link :to="'/dashboard/timeline/'+topic.user.id">{{ topic.user.username }}</router-link> 
                                        </template>
                                        <template>
                                            <span> Posted:</span>
                                        </template>
                                        {{ feedTimeManager(topic.created_at) }}
                                    </p>
                                    <p class="leading-6 line-clamp-2 mt-3">
                                        {{ blogSubtitle(topic.content,150) }}
                                    </p>
                                </div>
                                <div class="sm:flex items-center space-x-4 hidden">
                                    <svg class="w-7 h-7" fill="currentColor" viewbox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M2 5a2 2 0 012-2h7a2 2 0 012 2v4a2 2 0 01-2 2H9l-3 3v-3H4a2 2 0 01-2-2V5z"></path>
                                        <path d="M15 7v2a4 4 0 01-4 4H9.828l-1.766 1.767c.28.149.599.233.938.233h2l3 3v-3h2a2 2 0 002-2V9a2 2 0 00-2-2h-1z"></path>
                                    </svg>
                                    <span class="text-xl"> {{ topic.comments.length }} </span>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <div v-if="pendingRequest == false && topics.length == 0" class="bg-white p-2">
                        <h4>No Forum Topic Found</h4>
                    </div>

                    <h2 class="text-xl font-semibold mb-6 lg:mt-10 mt-4">
                        Top Contributions
                    </h2>
                    <ul class="card divide-y divide-gray-100">
                        <li v-for="tc in topContributions">
                            <div class="flex items-center space-x-5 p-7">
                                <img v-if="tc.image" :src="tc.image" alt="" class="w-12 h-12 rounded-full" />
                                <img v-else :src="defaultPostUrl()" alt="" class="w-12 h-12 rounded-full" />
                                <div class="flex-1">
                                    <router-link :to="'/dashboard/forum/' + tc.name_key" class="text-lg font-semibold line-clamp-1 capitalize">
                                        {{ tc.title }}
                                    </router-link>
                                    <div class="flex space-x-3 text-sm pb-2 mt-1 flex-wrap font-medium">
                                        <a :href="'/dashboard/timeline/'+tc.user.id" class="font-semibold capitalize">
                                            {{ tc.user.username }}
                                        </a>
                                        <div class="text-gray-500">{{ formatDateForum(tc.created_at) }}</div>
                                        <div class="text-gray-500">{{ tc.comments.length }} comments</div>
                                    </div>
                                </div>
                                <div class="sm:flex items-center -space-x-4 hidden">
                                    <!-- <template v-html="lastThreeUserCommentForum(tc.comments)"></template> -->
                                    <div v-if="getOddEven(tc.comments.length) == 'odd'" class="w-10 h-10 rounded-full flex justify-center items-center bg-red-100 text-red-500 font-semibold">
                                        <span v-if="tc.comments.length > 1">{{ tc.comments.length-1 }}+</span>
                                        <span v-else>0</span>
                                    </div>
                                    <div v-else class="w-10 h-10 rounded-full flex justify-center items-center bg-green-100 text-green-500 font-semibold">
                                        <span v-if="tc.comments.length > 1">{{ tc.comments.length-1 }}+</span>
                                        <span v-else>0</span>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>

                <div class="lg:w-1/3 pt-5 text-gray-900">
                    <div uk-sticky="offset:100" class="">
                        <h2 class="text-xl font-semibold mb-2">Top Contributors</h2>
                        <p>People who started the most discussions on Talks.</p>
                        <br />
                        <ul class="space-y-3 card-mobile">
                            <li v-for="tc in topContributors">
                                <div class="flex items-center space-x-3">
                                    <img v-if="tc.profile_photo" :src="tc.profile_photo" alt="" class="w-8 h-8 rounded-full" />
                                    <img v-else :src="defaultPostUrl()" alt="" class="w-8 h-8 rounded-full" />
                                    <router-link :to="'/dashboard/timeline/'+tc.id" class="font-semibold capitalize"> 
                                        {{ tc.name }} <verified-badge v-if="tc.verified == 'true'" size="small"></verified-badge>
                                    </router-link>
                                    <div class="flex items-center space-x-2">
                                        <i name="fa fa-comment" class="text-lg"></i>
                                        <span> {{ tc.forum_posts || 0 }} </span>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div id="progressLoader">
            <progress-loader></progress-loader>
        </div>
    </div>

    <dashboard-widgets page="forums"></dashboard-widgets>
</div>
</template>

<script>
import {
    ApiService
} from "@/services/api.service";
import Header from "@/include/Dashboard/Header.vue";
import Sidebar from "@/include/Dashboard/Sidebar.vue";
import DashboardWidgets from '@/components/DashboardWidgets'
import OpenChatBox from "@/include/Modals/OpenChatBox.vue";
import Feeds from "@/include/Dashboard/Feeds.vue";
import ProgressLoader from '@/components/ProgressLoader'
import VerifiedBadge from '@/components/VerifiedBadge'

export default {
    name: "Forums",
    components: {
        Header,
        Sidebar,
        DashboardWidgets,
        OpenChatBox,
        Feeds,
        ProgressLoader,
        VerifiedBadge,
    },

    data() {
        return {
            topics: [],
            recentTopics: [],
            page: 0,
            pendingRequest: false,
            recentActive: 'active',
            trendingActive: '',
            oldestActive: '',
            userActive: '',
            topContributions: '',
            topContributors: '',
            pagination: {
                limit: 20,
                offset: 0,
                total: 60,
            },
        };
    },
    mounted: function () {
        this.makeActiveSection('recent');
        this.getForumPostTop()
    },
    methods: {
        async getForumPosts(section) {
            this.pendingRequest = true;
            this.toggleProgress('show')
            let apiResponse = await ApiService.GetForumPosts(this.page, section);
            if (apiResponse["status"] != "success") {} else {
                this.page++;
                this.topics = apiResponse["data"];
                this.pendingRequest = false;
            }
            this.toggleProgress('hide')
        },
        async getForumPostTop(section) {
            this.pendingRequest = true;
            this.toggleProgress('show')
            let apiResponse = await ApiService.GetForumPostTop();
            if (apiResponse["status"] != "success") {} else {
                this.topContributions = apiResponse["data"]['top_contributions'];
                this.topContributors = apiResponse["data"]['top_contributors'];
                this.pendingRequest = false;
            }
            this.toggleProgress('hide')
        },
        async getRecentForumPosts() {
            this.pendingRequest = true;
            this.toggleProgress('show')
            let apiResponse = await ApiService.GetForumPosts(0, 'recent');
            if (apiResponse["status"] != "success") {} else {
                let recentTopics = apiResponse["data"];
                this.recentTopics = recentTopics.slice(0,5);
                this.pendingRequest = false;
            }
            this.toggleProgress('hide')
        },
        async makeActiveSection(a) {
            this.recentActive = ''
            this.oldestActive = ''
            this.trendingActive = ''
            this.userActive = ''

            this.page = 0;
            this.topics = []

            if(a.toLowerCase() == 'recent'){
                this.recentActive = 'active'
                this.getForumPosts('recent')
            }else if(a.toLowerCase() == 'oldest'){
                this.oldestActive = 'active'
                this.getForumPosts('oldest')
            }else if(a.toLowerCase() == 'trending'){
                this.trendingActive = 'active'
                this.getForumPosts('trending')
            }else if(a.toLowerCase() == 'user'){
                this.userActive = 'active'
                this.getForumPosts('user')
            }
        }
    },
};
</script>

<style>
    .forum-image img {
        width: 3rem !important;
        height: 3rem !important;
        border-radius: 9999px;
        position: relative;
    }
</style>

<style lang="scss" scoped>
    .some-modal-content {
        min-width: 400px;
        padding: 25px;

        .buttons button {
            padding: 10px;
            margin: 10px;
        }
    }
</style>
