<template>
<div uk-lightbox="" v-if="thisFeed.images && thisFeed.images.length > 0" class="mb-4">
    <template v-if="noOfImages == 1">
        <div class="uk-grid-xs" uk-grid>
            <div class="uk-width-1-1" v-for="(feedImage, index) in thisFeed.images">
                <a href="javascript:;" :href="feedImage.url" :style="'background-image: url('+feedImage.url+');'">
                    <img v-bind:src="feedImage.url" alt="" class="object-cover mb-0 m-auto" style="width: 100%;height:auto;object-position: top;">
                </a>
            </div>
        </div>
    </template>
    <template v-else-if="noOfImages == 2">
        <template v-if="imageLayout == 'long'">
            <div class="uk-grid-xs" uk-grid>
                <div class="uk-width-1-2" v-for="(feedImage, index) in thisFeed.images">
                    <a href="javascript:;" :href="feedImage.url" :style="'background-image: url('+feedImage.url+');'">
                        <img v-bind:src="feedImage.url" alt="" class="object-cover mb-0 m-auto height_one" style="object-fit: cover;height_old: 500px;width: 100%;object-position: top;">
                    </a>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="uk-grid-xs" uk-grid>
                <div class="uk-width-1-1" v-for="(feedImage, index) in thisFeed.images">
                    <a href="javascript:;" :href="feedImage.url" :style="'background-image: url('+feedImage.url+');'">
                        <img v-bind:src="feedImage.url" alt="" class="object-cover mb-0 m-auto height_two" style="object-fit: cover;height_old: 250px;width: 100%;object-position: top;">
                    </a>
                </div>
            </div>
        </template>
    </template>
    <template v-else-if="noOfImages == 4">
        <div class="uk-grid-xs" uk-grid>
            <div class="uk-width-1-2" v-for="(feedImage, index) in thisFeed.images">
                <a href="javascript:;" :href="feedImage.url" :style="'background-image: url('+feedImage.url+');'">
                    <img v-bind:src="feedImage.url" alt="" class="object-cover mb-0 m-auto height_two" style="object-fit: cover;height_old: 250px;width: 100%;object-position: center center;">
                </a>
            </div>
        </div>
    </template>
    <template v-else-if="noOfImages == 3">
        <template v-if="imageLayout == 'long'">
            <div class="uk-grid-xs" uk-grid>
                <div class="uk-width-1-2">
                    <a href="javascript:;" :href="thisFeed.images[0].url" :style="'background-image: url('+thisFeed.images[0].url+');'">
                        <img v-bind:src="thisFeed.images[0].url" alt="" class="object-cover mb-0 m-auto height_one" style="object-fit: cover;height_old: 500px;width: 100%;object-position: top;">
                    </a>
                </div>
                <div class="uk-width-1-2">
                    <a href="javascript:;" :href="thisFeed.images[1].url" :style="'background-image: url('+thisFeed.images[1].url+');'">
                        <img v-bind:src="thisFeed.images[1].url" alt="" class="object-cover mb-0 m-auto height_two" style="object-fit: cover;height_old: 250px;width: 100%;object-position: top;">
                    </a>
                    <a href="javascript:;" :href="thisFeed.images[2].url" :style="'background-image: url('+thisFeed.images[2].url+');'">
                        <img v-bind:src="thisFeed.images[2].url" alt="" class="object-cover mb-0 m-auto height_two" style="object-fit: cover;height_old: 250px;width: 100%;object-position: top;">
                    </a>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="uk-grid-xs" uk-grid>
                <div class="uk-width-1-2">
                    <a href="javascript:;" :href="thisFeed.images[0].url" :style="'background-image: url('+thisFeed.images[0].url+');'">
                        <img v-bind:src="thisFeed.images[0].url" alt="" class="object-cover mb-0 m-auto height_two" style="object-fit: cover;height_old: 250px;width: 100%;object-position: top;">
                    </a>
                </div>
                <div class="uk-width-1-2">
                    <a href="javascript:;" :href="thisFeed.images[1].url" :style="'background-image: url('+thisFeed.images[1].url+');'">
                        <img v-bind:src="thisFeed.images[1].url" alt="" class="object-cover mb-0 m-auto height_two" style="object-fit: cover;height_old: 250px;width: 100%;object-position: top;">
                    </a>
                </div>
                <div class="uk-width-1-1">
                    <a href="javascript:;" :href="thisFeed.images[2].url" :style="'background-image: url('+thisFeed.images[2].url+');'">
                        <img v-bind:src="thisFeed.images[2].url" alt="" class="object-cover mb-0 m-auto height_two" style="object-fit: cover;height_old: 250px;width: 100%;object-position: top;">
                    </a>
                </div>
            </div>
        </template>
    </template>
    <template v-else-if="noOfImages == 5">
        <template v-if="imageLayout == 'long'">
            <div class="uk-grid-xs" uk-grid>
                <div class="uk-width-1-2">
                    <a href="javascript:;" :href="thisFeed.images[0].url" :style="'background-image: url('+thisFeed.images[0].url+');'">
                        <img v-bind:src="thisFeed.images[0].url" alt="" class="object-cover mb-0 m-auto height_two" style="object-fit: cover;height_old: 250px;width: 100%;object-position: center center;">
                    </a>
                    <a href="javascript:;" :href="thisFeed.images[1].url" :style="'background-image: url('+thisFeed.images[1].url+');'">
                        <img v-bind:src="thisFeed.images[1].url" alt="" class="object-cover mb-0 m-auto height_two" style="object-fit: cover;height_old: 250px;width: 100%;object-position: center center;">
                    </a>
                </div>
                <div class="uk-width-1-2">
                    <a href="javascript:;" :href="thisFeed.images[2].url" :style="'background-image: url('+thisFeed.images[2].url+');'">
                        <img v-bind:src="thisFeed.images[2].url" alt="" class="object-cover mb-0 m-auto height_three" style="object-fit: cover;height_old: 166.667px;width: 100%;object-position: center center;">
                    </a>
                    <a href="javascript:;" :href="thisFeed.images[3].url" :style="'background-image: url('+thisFeed.images[3].url+');'">
                        <img v-bind:src="thisFeed.images[3].url" alt="" class="object-cover mb-0 m-auto height_three" style="object-fit: cover;height_old: 166.667px;width: 100%;object-position: center center;">
                    </a>
                    <a href="javascript:;" :href="thisFeed.images[4].url" :style="'background-image: url('+thisFeed.images[4].url+');'">
                        <img v-bind:src="thisFeed.images[4].url" alt="" class="object-cover mb-0 m-auto height_three" style="object-fit: cover;height_old: 166.667px;width: 100%;object-position: center center;">
                    </a>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="uk-grid-xs" uk-grid>
                <div class="uk-width-1-2">
                    <a href="javascript:;" :href="thisFeed.images[0].url" :style="'background-image: url('+thisFeed.images[0].url+');'">
                        <img v-bind:src="thisFeed.images[0].url" alt="" class="object-cover mb-0 m-auto height_two" style="object-fit: cover;height_old: 250px;width: 100%;object-position: top;">
                    </a>
                </div>
                <div class="uk-width-1-2">
                    <a href="javascript:;" :href="thisFeed.images[1].url" :style="'background-image: url('+thisFeed.images[1].url+');'">
                        <img v-bind:src="thisFeed.images[1].url" alt="" class="object-cover mb-0 m-auto height_two" style="object-fit: cover;height_old: 250px;width: 100%;object-position: top;">
                    </a>
                </div>
                <div class="uk-width-1-1">
                </div>
                <div class="uk-width-1-3">
                    <a href="javascript:;" :href="thisFeed.images[2].url" :style="'background-image: url('+thisFeed.images[2].url+');'">
                        <img v-bind:src="thisFeed.images[2].url" alt="" class="object-cover mb-0 m-auto height_two" style="object-fit: cover;height_old: 250px;width: 100%;object-position: top;">
                    </a>
                </div>
                <div class="uk-width-1-3">
                    <a href="javascript:;" :href="thisFeed.images[3].url" :style="'background-image: url('+thisFeed.images[3].url+');'">
                        <img v-bind:src="thisFeed.images[3].url" alt="" class="object-cover mb-0 m-auto height_two" style="object-fit: cover;height_old: 250px;width: 100%;object-position: top;">
                    </a>
                </div>
                <div class="uk-width-1-3">
                    <a href="javascript:;" :href="thisFeed.images[4].url" :style="'background-image: url('+thisFeed.images[4].url+');'">
                        <img v-bind:src="thisFeed.images[4].url" alt="" class="object-cover mb-0 m-auto height_two" style="object-fit: cover;height_old: 250px;width: 100%;object-position: top;">
                    </a>
                </div>
            </div>
        </template>
    </template>
    <template v-else>
        <photo-grid :box-height="photoGridBoxHeight(thisFeed.images.length)" :box-width="'100%'" :excess-text="'+ {{count}}'" v-on:clickExcess="triggerClick">
            <a href="javascript:;" v-for="(feedImage, index) in thisFeed.images" :href="feedImage.url" :style="'background-image: url('+feedImage.url+');'">
                <img v-bind:src="feedImage.url" alt="" class="max-h-96-old w-full object-cover mb-0" style="max-height: 600px;object-position: center center;height: 100%;">
            </a>
        </photo-grid>
    </template>
</div>
</template>

<script>
import {
    ApiService
} from '@/services/api.service'
import GoogleBannerAd from '@/include/Dashboard/Feed/GoogleBannerAd'
import VerifiedBadge from '@/components/VerifiedBadge'

export default {
    name: "ImagesComponent",
    props: {
        feed: Object,
    },
    data() {
        return {
            currentUser: '',
            thisFeed: '',
            imageLayout: 'short',
            noOfImages: 0,
        };
    },
    components: {
        GoogleBannerAd,
        VerifiedBadge,
    },
    mounted: function () {
        this.currentUser = this.$store.getters["user/currentUser"];
        this.thisFeed = this.feed
        if (this.thisFeed.images) {
            this.noOfImages = this.thisFeed.images.length
            if (this.noOfImages == 2) {
                this.imageLayout = 'long'
            } else if (this.noOfImages == 3) {
                this.imageLayout = 'long'
            } else if (this.noOfImages == 5) {
                this.imageLayout = 'short'
            }
        }
        // console.log('noOfImages', this.noOfImages)
    },
    methods: {
        moreLessImageManager() {
            if (this.is_less_image == 'true') {
                this.image_length = this.thisFeed.images.length
                this.is_less_image = 'false'
            } else {
                this.image_length = 5
                this.is_less_image = 'true'
            }
        },
        triggerClick() {
            // console.log('TCLIECK')
        },
    }
};
</script>

<style scoped>
.height_one {
    height: 500px;
}

.height_two {
    height: 250px;
}

.height_three {
    height: 166.667px;
}

@media (max-width: 540px) {
    .height_one {
        height: 250px;
    }

    .height_two {
        height: 125px;
    }

    .height_three {
        height: 83.333px;
    }
}
</style>
