<template>
<div id="wrapper">

    <Header />

    <sidebar></sidebar>

    <!-- Main Contents -->
    <div class="main_content">

        <span uk-toggle="target: .message-content;" class="fixed left-0 top-33 bg-blue-600 z-10 py-1 px-2 rounded-r-3xl text-white">
            <i class="fa fa-comment"></i>
        </span>

        <div class="messages-container" v-if="chats.length > 0">
            <div class="messages-container-inner" style="display: -webkit-box;">

                <div class="messages-inbox">
                    <div class="messages-headline">
                        <div class="input-with-icon" hidden="">
                            <input id="autocomplete-input" type="text" placeholder="Search">
                            <i class="icon-material-outline-search"></i>
                        </div>
                        <h2 class="text-2xl font-semibold">Chats</h2>
                        <a class="absolute icon-feather-home mr-4 text-xl uk-position-center-right cursor-pointer sm:right-0 right-10" href="/dashboard"></a>
                    </div>
                    <div class="messages-inbox-inner" data-simplebar="init">
                        <div class="simplebar-wrapper" style="margin: 0px;">
                            <div class="simplebar-height-auto-observer-wrapper">
                                <div class="simplebar-height-auto-observer"></div>
                            </div>
                            <div class="simplebar-mask">
                                <div class="simplebar-offset" style="right: -20px; bottom: 0px;">
                                    <div class="simplebar-content" style="padding: 0px calc(20px) 0px 0px; height: 100%; overflow: hidden scroll;">
                                        <ul>

                                            <li v-for="c in chats" :id="'chat_'+c.id" class="chats_class" uk-toggle="target: .message-content;">
                                                <a href="javascript:;" @click="setChatId(c.id)" class="bg-gray-600:hover">
                                                    <div class="message-avatar">
                                                        <i class="status-icon status-online"></i>
                                                        <img v-if="otherUserProfilePhoto(c, currentShop, 'user')" :src="otherUserProfilePhoto(c, currentShop, 'user')" alt="">
                                                        <img v-else :src="defaultImageUrl()" alt="">
                                                        <span v-if="otherUserOnline(c, currentShop, 'user') == 'true'" :class="'user_status status_online chat_user_'+otherUserID(c, currentShop, 'user')"></span>
                                                        <span v-else :class="'user_status status_online chat_user_'+otherUserID(c, currentShop, 'user')" style="display:none"></span>
                                                    </div>

                                                    <div class="message-by">
                                                        <div class="message-by-headline">
                                                            <h5>{{ otherUserName(c, currentShop, 'user') }}</h5>
                                                            <span>{{ timeAgo(c.updated_at) }} ago</span>
                                                        </div>
                                                        <p v-if="c.last_message_type == 'text'">
                                                            <template v-if="c.last_message == 'image' || c.last_message == 'images' || c.last_message == 'image(s)'">
                                                                <span class="text-italic">{{ c.last_message }}</span>
                                                            </template>
                                                            <template v-else>
                                                                {{ c.last_message }}
                                                            </template>
                                                        </p>
                                                        <p v-else-if="c.last_message_type" >-{{ c.last_message_type }}-</p>
                                                        <p v-else class="text-italic"></p>
                                                    </div>
                                                </a>
                                            </li>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="simplebar-placeholder" style="width: 339px; height: 584px;"></div>
                        </div>
                        <div class="simplebar-track simplebar-horizontal" style="visibility: hidden;">
                            <div class="simplebar-scrollbar simplebar-visible" style="transform: translate3d(0px, 0px, 0px); visibility: hidden;"></div>
                        </div>
                        <div class="simplebar-track simplebar-vertical" style="visibility: visible;">
                            <div class="simplebar-scrollbar simplebar-visible" style="transform: translate3d(0px, 0px, 0px); visibility: visible; height: 265px;"></div>
                        </div>
                    </div>
                </div>
                <div class="message-content" v-if="chat">

                    <div class="messages-headline">
                        <h4>
                            <a :href="'/dashboard/timeline/'+otherUserID(chat, currentShop, 'user')">
                                {{ otherUserName(chat, currentShop, 'user') }}
                            </a>
                        </h4>
                        <div class="message-action">
                            <template v-if="chat">
                                <a v-if="chat.is_blocked != 'true'" href="javascript:;" class="text-red-500 mr-3" @click="blockChat">
                                    <i class="fa fa-ban"></i> 
                                    <span class="md:inline hidden"> Block</span> 
                                </a>
                                <template v-else>
                                    <a v-if="chat.blocked_by == currentUser.id" href="javascript:;" class="text-red-500 mr-3" @click="unblockChat">
                                        <i class="fa fa-ban"></i> 
                                        <span class="md:inline hidden"> Unblock</span> 
                                    </a>
                                </template>
                            </template>
                            <a v-if="chat && chat.is_blocked != 'true'" href="javascript:;" class="text-red-500">
                                <i class="icon-feather-trash-2"></i> 
                                <span class="md:inline hidden"> Delete Conversation</span> 
                            </a>
                        </div>
                    </div>

                    <div class="message-content-scrolbar" data-simplebar="init">
                        <div class="simplebar-wrapper" style="margin: 0px;">
                            <div class="simplebar-height-auto-observer-wrapper">
                                <div class="simplebar-height-auto-observer"></div>
                            </div>
                            <div class="simplebar-mask">
                                <div class="simplebar-offset" style="right: -20px; bottom: 0px;">
                                    <div class="simplebar-content" ref="messageDisplay" style="padding: 0px calc(20px) 0px 0px; height: 100%; overflow: hidden scroll;">

                                        <!-- Message Content Inner -->
                                        <div class="message-content-inner">

                                            <template v-for="m in chat.messages">
                                                <template v-if="message_from == m.message_from">
                                                    <!-- Time Sign -->
                                                    <div class="message-time-sign d-none">
                                                        <span>{{ formatDate(m.created_at) }}</span>
                                                    </div>

                                                    <!-- <div class="message-bubble me">
                                                        <div class="message-bubble-inner">
                                                            <div class="message-avatar mouse-pointer" @click='openLinkInNewTab("/dashboard/timeline/"+currentUser["id"])'>
                                                                <img :src="userProfilePhoto(chat, 'user')" alt="">
                                                                <span class="user_status status_online d-none"></span>
                                                            </div>
                                                            <div class="message-text mouse-pointer" @click="setMessageToReply(m)" uk-toggle-old="target: #reply-chat-modal-old">
                                                                <div v-if="m.original_message_id && m.main_message" class="bg-blue-500 px-3 mb-2 rounded-md">
                                                                    <p>
                                                                        <small>Reply to</small>
                                                                    </p>
                                                                    <p v-if="m.main_message.message_type == 'text'" v-html="customTextRender(m.main_message.message, 'color-black')"></p>
                                                                    <a v-else-if="m.main_message.message_type == 'image'" :href="m.main_message.message" target="_blank" class="">
                                                                        <img :src="m.main_message.message" style="height:50px" class="mr-5" />
                                                                    </a>
                                                                    <p v-else-if="m.main_message.message_type == 'link'">
                                                                        <a :href="m.main_message.message" target="_blank" class="text-white text-white:hover">{{ m.main_message.message }}</a>
                                                                    </p>
                                                                    </hr>
                                                                </div>
                                                                <p class="text-white" v-if="m.message_type == 'text'" v-html="customTextRender(m.message, 'text-white')"></p>
                                                                <a v-else-if="m.message_type == 'image'" :href="m.message" target="_blank" class="">
                                                                    <img :src="m.message" style="height:50px" class="mr-5" />
                                                                </a>
                                                                <p v-else-if="m.message_type == 'link'">
                                                                    <a :href="m.message" target="_blank" class="text-white text-white:hover"><i class="fa fa-link"></i> {{ m.message }}</a>
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div class="clearfix"></div>
                                                        <p class="text-right">
                                                            <small style="font-size: 10px;">
                                                                {{ formatDateTimeNiceShort(m.created_at) }}
                                                                <template v-if="chat_user_is == 'receiver'">
                                                                    <span class="ml-2" v-if="m.emiter_seen == 'true'">seen</span>
                                                                </template>
                                                                <template v-if="chat_user_is == 'sender'">
                                                                    <span class="ml-2" v-if="m.receiver_seen == 'true'">seen</span>
                                                                </template>
                                                            </small>
                                                        </p>
                                                    </div> -->
                                                    <my-message :chat="chat" :m="m" :chat_user_is="chat_user_is" type="user"></my-message>
                                                </template>
                                                <template v-else>
                                                    <!-- Time Sign -->
                                                    <div class="message-time-sign d-none">
                                                        <span>{{ formatDate(m.created_at) }}</span>
                                                    </div>

                                                    <!-- <div class="message-bubble">
                                                        <div class="message-bubble-inner">
                                                            <div class="message-avatar mouse-pointer" @click='openLinkInNewTab("/dashboard/timeline/"+otherUserID(chat, currentShop, "user"))'>
                                                                <img :src="otherUserProfilePhoto(chat, currentShop, 'user')" alt="">
                                                                <span v-if="otherUserOnline(chat, currentShop, 'user') == 'true'" :class="'user_status status_online chat_user_'+otherUserID(chat, currentShop, 'user')"></span>
                                                                <span v-else :class="'user_status status_online chat_user_'+otherUserID(chat, currentShop, 'user')" style="display:none"></span>
                                                            </div>
                                                            <div class="message-text mouse-pointer" @click="setMessageToReply(m)" uk-toggle-old="target: #reply-chat-modal-old">
                                                                <div v-if="m.original_message_id && m.main_message" class="bg-blue-500 px-3 mb-2 rounded-md text-white">
                                                                    <p>
                                                                        <small>Reply to</small>
                                                                    </p>
                                                                    <p v-if="m.main_message.message_type == 'text'" v-html="customTextRender(m.main_message.message)"></p>
                                                                    <a v-else-if="m.main_message.message_type == 'image'" :href="m.main_message.message" target="_blank" class="">
                                                                        <img :src="m.main_message.message" style="height:50px" class="mr-5" />
                                                                    </a>
                                                                    <p v-else-if="m.main_message.message_type == 'link'">
                                                                        <a :href="m.main_message.message" target="_blank" class="text-blue text-blue:hover"><i class="fa fa-link"></i> {{ m.main_message.message }}</a>
                                                                    </p>
                                                                    <hr/>
                                                                </div>
                                                                <p v-if="m.message_type == 'text'" v-html="customTextRender(m.message)"></p>
                                                                <a v-else-if="m.message_type == 'image'" :href="m.message" target="_blank" class="">
                                                                    <img :src="m.message" style="height:50px" class="mr-5" />
                                                                </a>
                                                                <p v-else-if="m.message_type == 'link'">
                                                                    <a :href="m.message" target="_blank" class="text-blue text-blue:hover"><i class="fa fa-link"></i> {{ m.message }}</a>
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div class="clearfix"></div>
                                                        <p class="text-left">
                                                            <small style="font-size: 10px;">
                                                                {{ formatDateTimeNiceShort(m.created_at) }}
                                                            </small>
                                                        </p>
                                                    </div> -->
                                                    <other-user-message :chat="chat" :m="m"></other-user-message>
                                                </template>
                                            </template>
                                            <!-- <div class="message-bubble me">
                                                <div class="message-bubble-inner">
                                                    <div class="message-avatar">
                                                        <img v-if="currentUser.progile_photo" :src="currentUser.progile_photo" alt="">
                                                        <img v-else :src="defaultImageUrl()" alt="">
                                                        <span class="user_status status_online d-none"></span>
                                                    </div>
                                                    <div class="message-text">
                                                        <p class="text-white" v-if="messageTempType == 'text'" v-html="customTextRender(messageTemp, 'text-white')"></p>
                                                        <img v-else-if="messageTempType == 'image'" :src="messageTemp" style="height:50px" class="mr-5" />
                                                        <p v-else-if="messageTempType == 'link'">
                                                            <a :href="messageTemp" target="_blank" class="text-white text-white:hover">{{ messageTemp }}</a>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="clearfix"></div>
                                                <p class="text-right">
                                                    <small>sending...</small>
                                                </p>
                                            </div> -->
                                            <temp-message v-if="messageTemp" :messageTemp="messageTemp" :messageTempType="messageTempType"></temp-message>
                                        </div>
                                        <!-- Message Content Inner / End -->

                                        <!-- Reply Area -->
                                        <!-- <div>
                                            <small v-if="typing == 'true'" class="pl-5">{{ otherUserName(chat, currentShop, 'user') }} is typing</small>
                                        </div> -->
                                        <div>
                                            <div oldclass="message-reply bg-gray-50">
                                                <!-- <button v-if="messageType == 'text' || messageType == 'image'" @click="setMessageType('attachment')" class="button ripple-effect mr-2" style="padding: 0 10px;">
                                                    <i class="fa fa-paperclip"></i>
                                                </button>
                                                <button v-if="messageType == 'attachment'" @click="setMessageType('image')" class="button ripple-effect mr-2" style="padding: 0 10px;">
                                                    <i class="fa fa-file-image"></i>
                                                </button> -->
                                                <!-- <button v-if="messageType == 'attachment'" @click="setMessageType('link')" class="button ripple-effect mr-2" style="padding: 0 10px;">
                                                    <i class="fa fa-globe"></i>
                                                </button> -->
                                                <!-- <button v-if="messageType == 'attachment'" @click="setMessageType('text')" class="button ripple-effect mr-2" style="padding: 0 10px;">
                                                    <i class="fa fa-text-width"></i>
                                                </button> -->
                                                <!-- <template>
                                                    <div click="setMessageType('image')">
                                                        <single-file-upload type="image" @uploadSuccess="onImageUploaded">
                                                            <button class="button ripple-effect mr-2" style="padding: 0 10px;">
                                                                <i class="fa fa-paperclip"></i>
                                                            </button>
                                                        </single-file-upload>
                                                    </div>
                                                    <textarea :disabled="disableMessageButton" v-if="messageType == 'text'" id="chat_message" @keydown="userIsTyping('#chat_message')" @keyup="userIsTyping('#chat_message', 'true')" @mouseout="stopTypingSocketConnection" v-model="message" cols="1" class="pl-2" rows="1" placeholder="Your Message" />
                                                    <textarea :disabled="disableMessageButton" v-else @click="setMessageType('text')"  cols="1" class="pl-2" rows="1" placeholder="Image" readonly />
                                                    <button @click="sendChatMessage" class="button ripple-effect" :disabled="disableMessageButton">Send</button>
                                                </template> -->
                                                <!-- <template v-if="messageType == 'link'">
                                                    <input @keydown="typingSocketConnection" @keyup="typingSocketConnection" @mouseout="stopTypingSocketConnection" v-model="message" class="pl-2" placeholder="https://example.co" type="url" />
                                                    <button v-if="messageType == 'link'" @click="sendChatMessage" class="button ripple-effect">Send</button>
                                                </template> -->
                                                <!-- <template v-if="messageType == 'image'">
                                                    <img v-if="image" :src="image" style="height:50px"  class="mr-5"/>
                                                    <single-file-upload type="image" @uploadSuccess="onImageUploaded">
                                                        <svg class="bg-blue-100 h-9 p-1.5 rounded-full text-blue-600 w-9 cursor-pointer" fill="none" stroke="currentColor" viewbox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"></path>
                                                        </svg>
                                                    </single-file-upload>
                                                    <button v-if="messageType == 'image'" @click="sendChatMessage" class="ml-4 button ripple-effect">Send</button>
                                                </template> -->
                                            </div>
                                            <!-- <div id="chat_message_tags">
                                            </div>
                                            <div class="px-3 py-2 d-flex">
                                                <div v-for="(im,i) in images">
                                                    <p class="text-right" style="margin-bottom: -10px; z-index: 99999; position: relative;">
                                                        <a @click="deleteImagesItem(i)" href="Javascript:;">
                                                            <i class="fa fa-times-circle"></i>
                                                        </a>
                                                    </p>
                                                    <a :href="im" target="_blank">
                                                        <img :src="im" style="height:50px"  class="mr-2"/>
                                                    </a>
                                                </div>
                                            </div> -->
                                        </div>
                                        <send-message-input :chat="chat" @sendChatMessage="sendChatMessage"></send-message-input>

                                    </div>
                                </div>
                            </div>
                            <div class="simplebar-placeholder" style="width: 18232px; height: 753px;"></div>
                        </div>
                        <div class="simplebar-track simplebar-horizontal" style="visibility: hidden;">
                            <div class="simplebar-scrollbar" style="transform: translate3d(0px, 0px, 0px); visibility: hidden;"></div>
                        </div>
                        <div class="simplebar-track simplebar-vertical" style="visibility: visible;">
                            <div class="simplebar-scrollbar" style="height: 204px; transform: translate3d(0px, 0px, 0px); visibility: visible;"></div>
                        </div>
                    </div>

                </div>

                <div class="message-content" v-else>

                    <div class="messages-headline">
                        <h4 v-if="requestProgress == 'true'"> loading </h4>
                        <a v-if="requestProgress == 'false' && !chatId" class="message-action text-red-500">kindly select a chat from the sidebar</a>
                    </div>

                    <div class="message-content-scrolbar-old" data-simplebar-old="init">
                        <div class="simplebar-wrapper-old" style="margin: 0px;">
                            <progress-loader v-if="requestProgress == 'true'"></progress-loader>
                        </div>
                    </div>

                </div>

            </div>
        </div>
        <div v-else class="messages-container">
            <chat-loading-screen></chat-loading-screen>
        </div>

    </div>

    <progress-loader v-if="requestProgress == 'true'"></progress-loader>
    <dashboard-widgets page="chats"></dashboard-widgets>
    <reply-chat-message v-if="messageToReply" :chat_message="messageToReply" :current_shop="currentShop" :message_from="message_from"></reply-chat-message>

</div>
</template>

<script>
import {
    VueEditor
} from "vue2-editor";
import {
    ApiService
} from '@/services/api.service'
import SocketioService from '@/services/socket.service.js';
import Header from '@/include/Dashboard/Header.vue'
import Sidebar from '@/include/Dashboard/Sidebar.vue'
import DashboardWidgets from '@/components/DashboardWidgets'
import OpenChatBox from '@/include/Modals/OpenChatBox.vue'
import Feeds from '@/include/Dashboard/Feeds.vue'
import ProfileIcon from '@/include/Dashboard/Feed/ProfileIcon'
import ProgressLoader from '@/components/ProgressLoader'
import SingleFileUpload from '@/components/SingleFileUpload'
import ReplyChatMessage from '@/include/Modals/ReplyChatMessage'
import SendMessageInput from '@/components/Chats/SendMessageInput'
import MyMessage from '@/components/Chats/MyMessage'
import OtherUserMessage from '@/components/Chats/OtherUserMessage'
import TempMessage from '@/components/Chats/TempMessage'
import ChatLoadingScreen from '@/components/Chats/ChatLoadingScreen'

export default {
    name: 'Chats',
    components: {
        Header,
        Sidebar,
        DashboardWidgets,
        OpenChatBox,
        Feeds,
        ProgressLoader,
        SingleFileUpload,
        ReplyChatMessage,
        VueEditor,
        SendMessageInput,
        MyMessage,
        OtherUserMessage,
        TempMessage,
        ChatLoadingScreen,
    },

    data() {
        return {
            id: '',
            type: 'user',
            chats: '',
            chat: '',
            chat_sender: '',
            chat_receiver: '',
            messageTemp: '',
            messageType: 'text',
            messageTempType: 'text',
            message: '',
            message_type: 'text',
            message_from: '',
            product_link: '',
            chatId: '',
            chatType: 'user',
            requestProgress: 'false',
            currentUser: '',
            currentShop: '',
            chat_user_is: '',
            countryName: true,
            regionName: true,
            isConnected: false,
            socketMessage: '',
            socket: '',
            image: '',
            images: [],
            typing: 'false',
            chatSilent: 'false',
            messageToReply: '',
            clicks: 0,
            disableMessageButton: false,
        }
    },
    mounted () {
        this.currentUser = this.$store.getters['user/currentUser'] || {}
        this.id = this.currentUser['id']
        this.other_id = this.$route.query['id']
        this.other_type = this.$route.query['type']
        this.chatId = this.$route.query['chat_id']
        this.product_link = this.$route.query['product_link']
        if (!this.chatId) {
            if (this.other_id && this.other_type) {
                this.findChat()
            } else {
                this.getChats()
            }
        } else {
            this.getChat()
            this.getChats()
        }

        this.$root.$on("refresh-socket-id", (socket) => {
            console.log('Refresh socket', socket)
            this.socket = socket
        })
        this.$root.$on("refresh-typing-true", (data) => {
            if (this.currentUser['id'] == data['user_id'] && this.chatId == data['chat_id']) {
                this.typing = 'true'
            }
        })
        this.$root.$on("refresh-typing-false", (data) => {
            if (this.currentUser['id'] == data['user_id'] && this.chatId == data['chat_id']) {
                this.typing = 'false'
            }
        })
        this.$root.$on("refresh-user-online", (data) => {
            $(".chat_user_" + data['user_id']).show()
        })
        this.$root.$on("refresh-user-offline", (data) => {
            $(".chat_user_" + data['user_id']).hide()
        })
        this.$root.$on("refresh-chats", (data) => {
            console.log('Refresh chat', data, this.chatId)
            if (data['event'] == 'newMessageRead') {
                if (this.currentUser['id'] == data['message_sender_id']) {
                    this.getChat()
                }
            } else {
                this.getChats()
                if (data['chat_id'] == this.chatId) {
                    this.chatSilent = 'true'
                    this.getChat()
                }
            }
        })
    },
    methods: {
        async getChats() {
            this.requestProgress = 'true'
            this.toggleProgress('show')
            let apiResponse = await ApiService.GetChats(this.chatType);
            if (apiResponse['status'] == 'success') {
                this.currentShop = apiResponse['data']['shop']
                this.chats = apiResponse['data']['chats']
                if (!this.chatId) {
                    if (this.chats.length > 0) {
                        // set last message as open chat
                        this.chatId = this.chats[0]['id']
                        this.getChat()
                    }
                }
            } else {
                this.$store.commit("toast/Notify", {
                    type: apiResponse['status'],
                    message: apiResponse['message'],
                });
            }
            this.requestProgress = 'false'
            this.toggleProgress('hide')
        },
        async getChat() {
            // console.log("this.chatSilent", this.chatSilent)
            if (this.chatSilent == 'false') {
                this.requestProgress = 'true'
                this.toggleProgress('show')
            }
            let apiResponse = await ApiService.GetChat(this.chatId);
            if (apiResponse['status'] == 'success') {
                this.chat = apiResponse['data']['chat']
                this.chat_sender = apiResponse['data']['sender']
                this.chat_receiver = apiResponse['data']['receiver']
                this.chat_user_is = apiResponse['data']['chat_user_is']
                this.message_from = apiResponse['data']['chat_user_id']
                $(".chats_class").removeClass('active-message')
                $("#chat_" + this.chat.id).addClass('active-message')

                let vueThis = this
                setTimeout(() => {
                    vueThis.scrollToLastMessage()
                }, 1000);
                this.markChatMessagesAsSeen()
            } else {
                this.$store.commit("toast/Notify", {
                    type: apiResponse['status'],
                    message: apiResponse['message'],
                });
            }
            if (this.chatSilent == 'false') {
                this.requestProgress = 'false'
                this.toggleProgress('hide')
            }
            this.chatSilent = 'false'
            let messageShow = $(".message-content").attr('hidden')
            if (messageShow == 'hidden') {
                $(".message-content").removeAttr('hidden')
            }
        },
        async blockChat() {
            // console.log("this.chatSilent", this.chatSilent)
            if (this.chatSilent == 'false') {
                this.requestProgress = 'true'
                this.toggleProgress('show')
            }
            let apiResponse = await ApiService.BlockChat(this.chatId);
            if (apiResponse['status'] == 'success') {
                this.getChat()
            } else {
                this.$store.commit("toast/Notify", {
                    type: apiResponse['status'],
                    message: apiResponse['message'],
                });
            }
            if (this.chatSilent == 'false') {
                this.requestProgress = 'false'
                this.toggleProgress('hide')
            }
            this.chatSilent = 'false'
        },
        async unblockChat() {
            // console.log("this.chatSilent", this.chatSilent)
            if (this.chatSilent == 'false') {
                this.requestProgress = 'true'
                this.toggleProgress('show')
            }
            let apiResponse = await ApiService.UnblockChat(this.chatId);
            if (apiResponse['status'] == 'success') {
                this.getChat()
            } else {
                this.$store.commit("toast/Notify", {
                    type: apiResponse['status'],
                    message: apiResponse['message'],
                });
            }
            if (this.chatSilent == 'false') {
                this.requestProgress = 'false'
                this.toggleProgress('hide')
            }
            this.chatSilent = 'false'
        },
        async findChat() {
            this.requestProgress = 'true'
            this.toggleProgress('show')
            let apiResponse = await ApiService.FindChat(this.id, this.type, this.other_id, this.other_type);
            this.getChats()
            if (apiResponse['status'] == 'success') {
                this.chatId = apiResponse['data']['chat']['id']
                this.getChats()
                if (this.chatId) {
                    this.getChat(this.chatId)
                    location.href = '?chat_id=' + this.chatId
                }
            } else {
                this.$store.commit("toast/Notify", {
                    type: apiResponse['status'],
                    message: apiResponse['message'],
                });
            }
            this.requestProgress = 'false'
            this.toggleProgress('hide')
        },
        async sendChatMessage(a) {
            if(a){
                this.message = a.message
                this.message_type = a.message_type
                this.image = a.image
                this.images = a.images
            }
            this.message = $("#chat_message").val()
            // this.requestProgress = 'true'
            this.messageTemp = this.message
            let apiResponse = await ApiService.SendChatMessage(this.message, this.message_type, this.message_from, this.chatId, this.product_link, '', this.images);

            if (apiResponse["status"] != "success") {
                this.$root.$emit("chat-message-sent", 'error')
                this.$store.commit("toast/Notify", {
                    type: apiResponse["status"],
                    message: apiResponse["message"],
                });
            } else {
                this.$root.$emit("chat-message-sent", 'success')
                this.messageTemp = ''
                this.chatSilent = 'true'
                this.getChat(this.chatId)
                
            }
            // this.requestProgress = 'false'
        },
        async setChatId(id) {
            this.$router.push('/dashboard/chats?chat_id='+id)
            this.chatId = id
            this.getChat()
        },
        async markChatMessagesAsSeen() {
            let apiResponse = await ApiService.MarkChatMessagesAsSeen(this.chatId);
        },
        userIsTyping(a, b = 'false') {
            this.typingSocketConnection()
            if (b == 'true') {
                this.manageTagging(a)
            }
        },
        setupSocketConnection() {},
        typingSocketConnection() {
            if (this.socket) {
                this.socket.emit('broadcast', {
                    event: 'user_typing',
                    user_id: this.otherUserID(this.chat, this.currentShop, 'user'),
                    chat_id: this.chatId
                });
            }
        },
        stopTypingSocketConnection() {
            if (this.socket) {
                this.socket.emit('broadcast', {
                    event: 'user_typing_stopped',
                    user_id: this.otherUserID(this.chat, this.currentShop, 'user'),
                    chat_id: this.chatId
                });
            }
        },
        setMessageType(a) {
            this.messageType = a
            this.message_type = a
            this.messageTempType = a
        },
        setMessageToReply(a) {
            this.messageToReply = a
            this.clicks++;
            // console.log('::::', this.clicks)
            if (this.clicks === 2) {
                UIkit.modal('#reply-chat-modal').toggle();
                this.clicks = 0
            } else {}
            let vueThis = this
            setTimeout(() => {
                vueThis.clicks = 0
            }, 300);
            // $('#reply-chat-modal').addClass('uk-open').show();
        },
        disconnect() {
            if (this.socket) {
                this.socket.disconnect();
            }
        },
        scrollToLastMessage() {
            var messageDisplay = this.$refs.messageDisplay;
            if (messageDisplay && messageDisplay.scrollHeight) {
                messageDisplay.scrollTop = messageDisplay.scrollHeight;
            }
            // console.log('Scrolled')
        },
    },
}
</script>

<style scoped>
.some-modal-content {
    min-width: 400px;
    padding: 25px;

    .buttons button {
        padding: 10px;
        margin: 10px;
    }
}

.postImgheight {
    height: 100px
}

.editor {
    height: 350px;
}

.me a,
.me a.custom_link,
.me .color-black {
    color: #fff !important;
}

.message-bubble .message-text p {
    word-break: break-word;
}

@media (max-width: 520px) {
    header {
        display: none;
    }

    .main_content {
        padding-top: 0px !important;
    }
}
</style>
