<template>
<section>
    <div class="bg-gradient-to-tr flex from-blue-400-old bg-blue-500 h-52 items-center justify-center lg:h-80 pb-10 relative to-blue-300 via-blue-400 w-full">

        <div class="text-center max-w-xl mx-auto z-10 relative px-5">
            <div class="lg:text-4xl text-2xl text-white font-semibold mb-3"> What We Do </div>
            <div class="text-white text-lg font-medium text-opacity-90"> </div>
        </div>

    </div>
    <div class="max-w-7xl mx-auto p-7 mb-10">

        <div class="-mt-16 bg-white p-10 relative rounded-md">

            <!-- Pricing cards -->
            <div class="grid md:grid-cols-3 gap-7 mt-12">

                <!-- Card 1 -->
                <div class="bg-white border border-gray-100 hover:shadow-md p-6 rounded-xl">

                    <h2 class="font-bold mb-4 text-2xl text-black"> Creators </h2>
                    <div class="font-medium mb-6 text-gray-400 text-base">We empower and pay our content creators for their time and content" </div>

                    <div class="border-b-2 border-blue-50 flex items-baseline mb-10 pb-8 space-x-2">
                        <div class="font-semibold text-4xl text-black"></div>
                        <div class="font-medium text-gray-400"></div>
                    </div>

                    <div class="space-y-4 text- font-medium text-gray-400">
                        <div class="flex items-center space-x-5 d-none">
                            <i class="icon-feather-check font-bold text-blue-600"></i>
                            <div> Post Feeds </div>
                        </div>
                        <div class="flex items-center space-x-5">
                            <i class="icon-feather-check font-bold text-blue-600"></i>
                            <div> Gather Followers </div>
                        </div>
                        <div class="flex items-center space-x-5">
                            <i class="icon-feather-check font-bold text-blue-600"></i>
                            <div> Get verified </div>
                        </div>
                        <div class="flex items-center space-x-5">
                            <i class="icon-feather-check font-bold text-blue-600"></i>
                            <div> Monetize content </div>
                        </div>
                        <div class="flex items-center space-x-5">
                            <i class="icon-feather-check font-bold text-blue-600"></i>
                            <div> And get paid </div>
                        </div>

                    </div>

                </div>

                <!-- Card 2 -->
                <div class="bg-white border border-gray-100 hover:shadow-md p-6 rounded-xl relative">

                    <!-- <span class="absolute bg-blue-100 font-semibold px-2.5 py-1 right-4 rounded-full text-blue-600 text-xs"> Popular </span> -->

                    <h2 class="font-bold mb-4 text-2xl text-black"> Promoters </h2>
                    <div class="font-medium mb-6 text-gray-400 text-base"> Get paid to do simple social media tasks from multiple social media platforms. </div>

                    <div class="border-b-2 border-blue-50 flex items-baseline mb-10 pb-8 space-x-2">
                        <div class="font-semibold text-4xl text-black"></div>
                        <div class="font-medium text-gray-400"></div>
                    </div>

                    <div class="space-y-4 text- font-medium text-gray-400">
                        <div class="flex items-center space-x-5">
                            <i class="icon-feather-check font-bold text-blue-600"></i>
                            <div> Add Social Media Account </div>
                        </div>
                        <div class="flex items-center space-x-5">
                            <i class="icon-feather-check font-bold text-blue-600"></i>
                            <div> Perform Tasks </div>
                        </div>
                        <div class="flex items-center space-x-5">
                            <i class="icon-feather-check font-bold text-blue-600"></i>
                            <div> Get Paid For Task </div>
                        </div>
                        <div class="flex items-center space-x-5">
                            <i class="icon-feather-check font-bold text-blue-600"></i>
                            <div> Withdraw Funds From Wallet </div>
                        </div>

                    </div>

                </div>

                <!-- Card 4 -->
                <div class="bg-white border border-gray-100 hover:shadow-md p-6 rounded-xl relative">

                    <h2 class="font-bold mb-4 text-2xl text-black"> Advertisers</h2>
                    <div class="font-medium mb-6 text-gray-400 text-base"> Create a campaign and reach out to millions of targeted users for less. </div>

                    <div class="border-b-2 border-blue-50 flex items-baseline mb-10 pb-8 space-x-2">
                        <div class="font-semibold text-4xl text-black"></div>
                        <div class="font-medium text-gray-400"></div>
                    </div>

                    <div class="space-y-4 text- font-medium text-gray-400">
                        <div class="flex items-center space-x-5">
                            <i class="icon-feather-check font-bold text-blue-600"></i>
                            <div> Select Advert Platform </div>
                        </div>
                        <div class="flex items-center space-x-5">
                            <i class="icon-feather-check font-bold text-blue-600"></i>
                            <div> Select Advert Type </div>
                        </div>
                        <div class="flex items-center space-x-5">
                            <i class="icon-feather-check font-bold text-blue-600"></i>
                            <div> Create Advert(Campaign) </div>
                        </div>
                        <div class="flex items-center space-x-5">
                            <i class="icon-feather-check font-bold text-blue-600"></i>
                            <div> Pay For Advert </div>
                        </div>

                    </div>

                </div>

            </div>

        </div>

    </div>
</section>
</template>

<script>
export default {
    name: 'WhatWeDoSection',
    components: {},
    data() {
        return {}
    },

    methods: {}
}
</script>

<style>
</style>
<style lang="scss" scoped>
</style>
