<template>
<div v-if="thisFeed" @click="openFeedById(thisFeed.id)" class="mouse-pointer card lg:mx-0 uk-animation-slide-bottom-small" style="overflow: scroll;box-shadow: none;background-color: #ddd;margin: 10px;">

    <h5 v-if="boosted == 'true'" class="lg:px-4 px-2.5 lg:pt-4 pt-2.5 text-italic">Boosted</h5>
    <h5 v-if="thisFeed.user_pin == 'true' || thisFeed.admin_pin == 'true'" class="lg:px-4 px-2.5 lg:pt-4 pt-2.5 text-italic">Pinned</h5>
    <!-- post header-->
    <div class="flex justify-between items-center lg:p-4 p-2.5">
        <div uk-lightbox="" class="flex flex-1 items-center space-x-4">
            <profile-icon v-if="feedOwner" :url="feedOwner.profile_photo"></profile-icon>
            <div v-if="feedOwner" class="flex-1 font-semibold capitalize">
                <a :href="'/dashboard/timeline/'+feedOwner.id" class="text-gray-700 dark:text-gray-100">
                    {{ feedOwner.name }}
                    <verified-badge v-if="feedOwner.verified == 'true'" size="small"></verified-badge>
                </a>
                <div class="text-gray-400 flex items-center space-x-2 mt-1" style="font-size: 13px;"> {{ feedTimeManager(thisFeed.created_at) }}
                    <span> </span>
                </div>
            </div>
        </div>
        <div>
        </div>
    </div>

    <div class="p-4 leading-6" v-html="feedContent"></div>
    <p v-if="show_more_less_button == 'true'" class="ml-3">
        <a class="custom_link" v-if="is_less == 'false'" href="javascript:;" @click="lessChange('true')">show less</a>
        <a class="custom_link" v-else href="javascript:;" @click="lessChange('false')">show more</a>
    </p>

    <images-component :feed="thisFeed" />

    <audios-component :feed="thisFeed" />

    <videos-component :feed="thisFeed" />

</div>
</template>

<script>
import {
    ApiService
} from '@/services/api.service'
import ProfileIcon from '@/include/Dashboard/Feed/ProfileIcon'
import SharePostModal from '@/include/Modals/SharePostModal'
import FeedInsightModal from '@/include/Modals/FeedInsightModal'
import GoogleBannerAd from '@/include/Dashboard/Feed/GoogleBannerAd'
import VerifiedBadge from '@/components/VerifiedBadge'
import FeedLikersModal from '@/include/Modals/FeedLikersModal'
import ImagesComponent from '@/components/Feed/ImagesComponent'
import AudiosComponent from '@/components/Feed/AudiosComponent'
import VideosComponent from '@/components/Feed/VideosComponent'

export default {
    name: "SharedFeed",
    props: {
        feed: Object,
        feedOwner: Object
    },
    data() {
        return {
            feedContent: '',
            reply: '',
            replyReply: '',
            comment: '',
            comments: '',
            noOfPreviewComments: 2,
            unviewedComments: 0,
            currentUser: '',
            thisFeed: '',
            shareLink: '',
            feed_id_insight: '',
            shareDescription: '',
            show_more_less_button: 'false',
            is_less: 'true',
            is_less_image: 'true',
            is_less_audio: 'true',
            is_less_video: 'true',
            image_length: 5,
            audio_length: 1,
            video_length: 1,
            likers: '',
            boosted: '',
        };
    },
    components: {
        ProfileIcon,
        SharePostModal,
        FeedInsightModal,
        GoogleBannerAd,
        VerifiedBadge,
        FeedLikersModal,
        ImagesComponent,
        AudiosComponent,
        VideosComponent,
    },
    mounted: function () {
        this.currentUser = this.$store.getters["user/currentUser"];
        this.thisFeed = this.feed
        this.moreLessManager()
        // this.commentChecker()
        // this.getFeedLikes()
    },
    methods: {
        async getFeedLikes() {
            this.loading = "true";
            let apiResponse = await ApiService.FeedLikes(this.feed.id);
            if (apiResponse["status"] != "success") {
                this.$store.commit("toast/Notify", {
                    type: apiResponse["status"],
                    message: apiResponse["message"],
                });
            } else {
                if (apiResponse["data"] && apiResponse["data"].length > 0) {
                    this.likers = apiResponse["data"];
                }
            }
            this.loading = "false";
        },
        async addComment(id) {
            this.toggleProgress('show')
            let apiResponse = await ApiService.AddComment(id, this.comment)
            if (apiResponse['status'] != 'success') {
                this.$store.commit("toast/Notify", {
                    type: apiResponse["status"],
                    message: apiResponse["message"],
                });
            } else {
                this.comment = ''
                this.thisFeed = apiResponse['data']
                this.commentChecker()
            }
            this.toggleProgress('hide')
        },
        async likeFeed(type) {
            this.toggleProgress('show')
            let apiResponse = await ApiService.LikeFeed(this.thisFeed.id, type)
            if (apiResponse['status'] != 'success') {
                this.$store.commit("toast/Notify", {
                    type: apiResponse["status"],
                    message: apiResponse["message"],
                });
            } else {
                this.thisFeed = apiResponse['data']
                this.commentChecker()
            }
            this.toggleProgress('hide')
        },
        async unlikeFeed() {
            this.toggleProgress('show')
            let apiResponse = await ApiService.UnlikeFeed(this.thisFeed.id)
            if (apiResponse['status'] != 'success') {
                this.$store.commit("toast/Notify", {
                    type: apiResponse["status"],
                    message: apiResponse["message"],
                });
            } else {
                this.thisFeed = apiResponse['data']
                this.commentChecker()
            }
            this.toggleProgress('hide')
        },
        async likeComment(comment_id, type) {
            this.toggleProgress('show')
            let apiResponse = await ApiService.LikeComment(this.thisFeed.id, comment_id, type)
            if (apiResponse['status'] != 'success') {
                this.$store.commit("toast/Notify", {
                    type: apiResponse["status"],
                    message: apiResponse["message"],
                });
            } else {
                this.thisFeed = apiResponse['data']
                this.commentChecker()
            }
            this.toggleProgress('hide')
        },
        async unlikeComment(comment_id) {
            this.toggleProgress('show')
            let apiResponse = await ApiService.UnlikeComment(this.thisFeed.id, comment_id)
            if (apiResponse['status'] != 'success') {
                this.$store.commit("toast/Notify", {
                    type: apiResponse["status"],
                    message: apiResponse["message"],
                });
            } else {
                this.thisFeed = apiResponse['data']
                this.commentChecker()
            }
            this.toggleProgress('hide')
        },
        async shareAction(id) {
            this.toggleProgress('show')
            let apiResponse = await ApiService.ShareFeed(this.thisFeed.id)
            if (apiResponse['status'] != 'success') {} else {}
            this.$store.commit("toast/Notify", {
                type: apiResponse["status"],
                message: apiResponse["message"],
            });
            this.toggleProgress('hide')
        },
        async replyComment(comment_id) {
            this.toggleProgress('show')
            let apiResponse = await ApiService.ReplyComment(this.thisFeed.id, comment_id, this.reply)
            if (apiResponse['status'] != 'success') {
                this.$store.commit("toast/Notify", {
                    type: apiResponse["status"],
                    message: apiResponse["message"],
                });
            } else {
                this.reply = ''
                this.thisFeed = apiResponse['data']
                this.commentChecker()
            }
            this.toggleProgress('hide')
        },
        async replyCommentReply(feed_id, comment_id, reply_id) {
            this.toggleProgress('show')
            let apiResponse = await ApiService.ReplyCommentReply(feed_id, comment_id, reply_id, this.replyReply)
            if (apiResponse['status'] != 'success') {
                this.$store.commit("toast/Notify", {
                    type: apiResponse["status"],
                    message: apiResponse["message"],
                });
            } else {
                this.replyReply = ''
                this.thisFeed = apiResponse['data']
                this.commentChecker()
            }
            this.toggleProgress('hide')
        },
        async likeCommentReply(reply_id, type) {
            this.toggleProgress('show')
            let apiResponse = await ApiService.LikeCommentReply(this.thisFeed.id, reply_id, type)
            if (apiResponse['status'] != 'success') {
                this.$store.commit("toast/Notify", {
                    type: apiResponse["status"],
                    message: apiResponse["message"],
                });
            } else {
                this.thisFeed = apiResponse['data']
                this.commentChecker()
            }
            this.toggleProgress('hide')
        },
        async unlikeCommentReply(reply_id) {
            this.toggleProgress('show')
            let apiResponse = await ApiService.UnlikeCommentReply(this.thisFeed.id, reply_id)
            if (apiResponse['status'] != 'success') {
                this.$store.commit("toast/Notify", {
                    type: apiResponse["status"],
                    message: apiResponse["message"],
                });
            } else {
                this.thisFeed = apiResponse['data']
                this.commentChecker()
            }
            this.toggleProgress('hide')
        },
        async likeCommentReplyReply(reply_id, type) {
            this.toggleProgress('show')
            let apiResponse = await ApiService.LikeCommentReplyReply(this.thisFeed.id, reply_id, type)
            if (apiResponse['status'] != 'success') {
                this.$store.commit("toast/Notify", {
                    type: apiResponse["status"],
                    message: apiResponse["message"],
                });
            } else {
                this.thisFeed = apiResponse['data']
                this.commentChecker()
            }
            this.toggleProgress('hide')
        },
        async unlikeCommentReplyReply(reply_id) {
            this.toggleProgress('show')
            let apiResponse = await ApiService.UnlikeCommentReplyReply(this.thisFeed.id, reply_id)
            if (apiResponse['status'] != 'success') {
                this.$store.commit("toast/Notify", {
                    type: apiResponse["status"],
                    message: apiResponse["message"],
                });
            } else {
                this.thisFeed = apiResponse['data']
                this.commentChecker()
            }
            this.toggleProgress('hide')
        },
        async enableFeedComment() {
            this.toggleProgress('show')
            let apiResponse = await ApiService.EnableFeedComment(this.thisFeed.id)
            if (apiResponse['status'] != 'success') {
                this.$store.commit("toast/Notify", {
                    type: apiResponse["status"],
                    message: apiResponse["message"],
                });
            } else {
                this.thisFeed = apiResponse['data']
                this.commentChecker()
            }
            this.toggleProgress('hide')
        },
        async disableFeedComment() {
            this.toggleProgress('show')
            let apiResponse = await ApiService.DisableFeedComment(this.thisFeed.id)
            if (apiResponse['status'] != 'success') {
                this.$store.commit("toast/Notify", {
                    type: apiResponse["status"],
                    message: apiResponse["message"],
                });
            } else {
                this.thisFeed = apiResponse['data']
                this.commentChecker()
            }
            this.toggleProgress('hide')
        },
        async boostFeed(id) {
            this.toggleProgress('show')
            let apiResponse = await ApiService.BoostFeed(this.thisFeed.id)
            if (apiResponse['status'] != 'success') {
                this.$store.commit("toast/Notify", {
                    type: apiResponse["status"],
                    message: apiResponse["message"],
                });
            } else {
                this.thisFeed = apiResponse['data']
                this.commentChecker()
            }
            this.toggleProgress('hide')
        },
        async unboostFeed(id) {
            this.toggleProgress('show')
            let apiResponse = await ApiService.UnboostFeed(this.thisFeed.id)
            if (apiResponse['status'] != 'success') {
                this.$store.commit("toast/Notify", {
                    type: apiResponse["status"],
                    message: apiResponse["message"],
                });
            } else {
                this.thisFeed = apiResponse['data']
                this.commentChecker()
            }
            this.toggleProgress('hide')
        },
        async deleteFeedAudio(audio_id) {
            this.toggleProgress('show')
            let apiResponse = await ApiService.DeleteFeedAudio(this.thisFeed.id, audio_id)
            if (apiResponse['status'] != 'success') {
                this.$store.commit("toast/Notify", {
                    type: apiResponse["status"],
                    message: apiResponse["message"],
                });
            } else {
                this.thisFeed = apiResponse['data']
                this.commentChecker()
            }
            this.toggleProgress('hide')
        },
        async deleteFeedImage(image_id) {
            this.toggleProgress('show')
            let apiResponse = await ApiService.DeleteFeedImage(this.thisFeed.id, image_id)
            if (apiResponse['status'] != 'success') {
                this.$store.commit("toast/Notify", {
                    type: apiResponse["status"],
                    message: apiResponse["message"],
                });
            } else {
                this.thisFeed = apiResponse['data']
                this.commentChecker()
            }
            this.toggleProgress('hide')
        },
        async deleteFeedVideo(video_id) {
            this.toggleProgress('show')
            let apiResponse = await ApiService.DeleteFeedVideo(this.thisFeed.id, video_id)
            if (apiResponse['status'] != 'success') {
                this.$store.commit("toast/Notify", {
                    type: apiResponse["status"],
                    message: apiResponse["message"],
                });
            } else {
                this.thisFeed = apiResponse['data']
                this.commentChecker()
            }
            this.toggleProgress('hide')
        },
        async deleteFeedComment(comment_id) {
            this.toggleProgress('show')
            let apiResponse = await ApiService.DeleteFeedComment(this.thisFeed.id, comment_id)
            if (apiResponse['status'] != 'success') {
                this.$store.commit("toast/Notify", {
                    type: apiResponse["status"],
                    message: apiResponse["message"],
                });
            } else {
                this.thisFeed = apiResponse['data']
                this.commentChecker()
            }
            this.toggleProgress('hide')
        },
        async deleteFeedCommentReply(reply_id) {
            this.toggleProgress('show')
            let apiResponse = await ApiService.DeleteFeedCommentReply(this.thisFeed.id, reply_id)
            if (apiResponse['status'] != 'success') {
                this.$store.commit("toast/Notify", {
                    type: apiResponse["status"],
                    message: apiResponse["message"],
                });
            } else {
                this.thisFeed = apiResponse['data']
                this.commentChecker()
            }
            this.toggleProgress('hide')
        },
        async deleteFeedCommentReplyReply(reply_id) {
            this.toggleProgress('show')
            let apiResponse = await ApiService.DeleteFeedCommentReplyReply(this.thisFeed.id, reply_id)
            if (apiResponse['status'] != 'success') {
                this.$store.commit("toast/Notify", {
                    type: apiResponse["status"],
                    message: apiResponse["message"],
                });
            } else {
                this.thisFeed = apiResponse['data']
                this.commentChecker()
            }
            this.toggleProgress('hide')
        },
        async deleteFeed() {
            this.toggleProgress('show')
            let apiResponse = await ApiService.DeleteFeed(this.thisFeed.id)
            if (apiResponse['status'] != 'success') {} else {
                location.href = ''
            }
            this.$store.commit("toast/Notify", {
                type: apiResponse["status"],
                message: apiResponse["message"],
            });
            this.toggleProgress('hide')
        },
        async viewFeedOnce(e) {
            let apiResponse = await ApiService.ViewFeed(this.thisFeed.id)
            // console.log("viewFeedOnce", apiResponse)
        },
        async userPinFeed() {
            this.toggleProgress('show')
            let apiResponse = await ApiService.UserPinFeed(this.thisFeed.id)
            if (apiResponse['status'] != 'success') {
                this.$store.commit("toast/Notify", {
                    type: apiResponse["status"],
                    message: apiResponse["message"],
                });
            } else {
                this.thisFeed = apiResponse['data']
                this.commentChecker()
            }
            this.toggleProgress('hide')
        },
        async userUnpinFeed() {
            this.toggleProgress('show')
            let apiResponse = await ApiService.UserUnpinFeed(this.thisFeed.id)
            if (apiResponse['status'] != 'success') {
                this.$store.commit("toast/Notify", {
                    type: apiResponse["status"],
                    message: apiResponse["message"],
                });
            } else {
                this.thisFeed = apiResponse['data']
                this.commentChecker()
            }
            this.toggleProgress('hide')
        },
        async adminPinFeed() {
            this.toggleProgress('show')
            let apiResponse = await ApiService.AdminPinFeed(this.thisFeed.id)
            if (apiResponse['status'] != 'success') {
                this.$store.commit("toast/Notify", {
                    type: apiResponse["status"],
                    message: apiResponse["message"],
                });
            } else {
                this.thisFeed = apiResponse['data']
                this.commentChecker()
            }
            this.toggleProgress('hide')
        },
        async adminUnpinFeed() {
            this.toggleProgress('show')
            let apiResponse = await ApiService.AdminUnpinFeed(this.thisFeed.id)
            if (apiResponse['status'] != 'success') {
                this.$store.commit("toast/Notify", {
                    type: apiResponse["status"],
                    message: apiResponse["message"],
                });
            } else {
                this.thisFeed = apiResponse['data']
                this.commentChecker()
            }
            this.toggleProgress('hide')
        },
        viewFeedInsight(feed_id_insight) {
            this.feed_id_insight = feed_id_insight
        },
        confirmEnableFeedComment(audio_id) {
            let proceed = confirm("Are you sure you want to Enable Feed Comments?");
            if (proceed) {
                this.enableFeedComment(audio_id)
            } else {
                //don't proceed
            }
        },
        confirmDisableFeedComment(audio_id) {
            let proceed = confirm("Are you sure you want to Disable Feed Comments?");
            if (proceed) {
                this.disableFeedComment(audio_id)
            } else {
                //don't proceed
            }
        },
        confirmDeleteFeedAudio(audio_id) {
            let proceed = confirm("Are you sure you want to delete this Feed Audio?");
            if (proceed) {
                this.deleteFeedAudio(audio_id)
            } else {
                //don't proceed
            }
        },
        confirmDeleteFeedImage(image_id) {
            let proceed = confirm("Are you sure you want to delete this Feed Image?");
            if (proceed) {
                this.deleteFeedImage(image_id)
            } else {
                //don't proceed
            }
        },
        confirmDeleteFeedVideo(audio_id) {
            let proceed = confirm("Are you sure you want to delete this Feed Video?");
            if (proceed) {
                this.deleteFeedVideo(audio_id)
            } else {
                //don't proceed
            }
        },
        confirmDeleteFeedComment(comment_id) {
            let proceed = confirm("Are you sure you want to delete this Feed Comment?");
            if (proceed) {
                this.deleteFeedComment(comment_id)
            } else {
                //don't proceed
            }
        },
        confirmDeleteFeedCommentReply(reply_id) {
            let proceed = confirm("Are you sure you want to delete this Feed Comment Reply?");
            if (proceed) {
                this.deleteFeedCommentReply(reply_id)
            } else {
                //don't proceed
            }
        },
        confirmDeleteFeedCommentReplyReply(reply_id) {
            let proceed = confirm("Are you sure you want to delete this Feed Comment Reply Reply?");
            if (proceed) {
                this.deleteFeedCommentReplyReply(reply_id)
            } else {
                //don't proceed
            }
        },
        confirmDeleteFeed() {
            let proceed = confirm("Are you sure you want to delete this feed and loose all its likes and comments?");
            if (proceed) {
                this.deleteFeed()
            } else {
                //don't proceed
            }
        },
        setCurrentLink(id, description) {
            let thisLink = this.$store.getters["host/seoUrl"] + '/feed/' + id
            this.shareLink = thisLink
        },
        showCommentBox() {
            this.$forceUpdate();
        },
        triggerFeedEdit(feed) {
            this.$root.$emit("open-edit-feed-post-modal", feed)
        },
        commentChecker() {
            let comments = this.thisFeed.comments.length
            if (comments > this.noOfPreviewComments) {
                this.unviewedComments = comments - this.noOfPreviewComments
            }
            if (this.preview == 'true') {
                this.comments = this.thisFeed.comments.slice(0, this.noOfPreviewComments)
            } else {
                this.comments = this.thisFeed.comments
            }
        },
        showAllComments() {
            this.unviewedComments = 0
            this.comments = this.thisFeed.comments
        },
        closeFeedModal(a) {
            // $('.feedModal').click();
            UIkit.drop(a).hide(100);
            // console.log('closeFeedModal', UIkit.drop(a))
        },
        moreLessManager() {
            this.show_more_less_button = 'false'
            let a = this.customTextRender(this.thisFeed['content'])
            let max_length = 50
            let textSplit = a.split(" ")
            if (textSplit.length > max_length) {
                this.show_more_less_button = 'true'
                if (this.is_less == 'true') {
                    textSplit = textSplit.slice(0, max_length)
                } else {}
            }
            a = textSplit.join(' ')
            this.feedContent = a
        },
        lessChange(a) {
            this.is_less = a
            this.moreLessManager()
        },
        moreLessImageManager() {
            if (this.is_less_image == 'true') {
                this.image_length = this.thisFeed.images.length
                this.is_less_image = 'false'
            } else {
                this.image_length = 5
                this.is_less_image = 'true'
            }
        },
        moreLessAudioManager() {
            if (this.is_less_audio == 'true') {
                this.audio_length = this.thisFeed.audios.length
                this.is_less_audio = 'false'
            } else {
                this.audio_length = 1
                this.is_less_audio = 'true'
            }
        },
        moreLessVideoManager() {
            if (this.is_less_video == 'true') {
                this.video_length = this.thisFeed.videos.length
                this.is_less_video = 'false'
            } else {
                this.video_length = 1
                this.is_less_video = 'true'
            }
        },
        triggerClick() {
            // console.log('TCLIECK')
        },
        openFeedById(id) {
            // $('#' + id).get(0).scrollIntoView();
            location.href = '/dashboard/feed/'+id
        }
    }
};
</script>

<style>
.feed_audios audio {
    margin: auto;
}

.feed_audio>div:first-child {
    margin-bottom: 20px;
}

.feed_audio>div:last-child {
    display: none;
}

.feed_video {
    margin-bottom: 20px;
}

.feed_video>div:first-child {
    width: 100%;
}

.feed_video .vjs-big-play-button {
    display: none !important;
}

.comment_extra_holder {
    position: absolute;
    bottom: -8px;
    right: -20px;
    background: white;
    border-radius: 15px;
    border: #ccc 1px solid;
    padding-right: 5px;
}

.icon_gif {
    height: 30px;
}

.icon_image {
    height: 20px;
}

.icon_image_min {
    height: 20px;
}

.comment_content_reaction_holder {
    position: absolute;
    bottom: -18px;
    right: -20px;
    background: white;
    border-radius: 15px;
    border: #ccc 1px solid;
    padding: 0px 7px;
    display: flex;
}

.comment_box_reaction_png>img {
    margin-top: -4px;
}

.comment_content_reaction_holder img {
    height: 15px;
    margin: 5px 0;
}

.comment_button {
    position: absolute;
    right: 0;
    z-index: 99999;
    top: 0;
    padding: 12px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}
</style>
