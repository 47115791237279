import { render, staticRenderFns } from "./BoostedFeed.vue?vue&type=template&id=35f8a035&scoped=true"
import script from "./BoostedFeed.vue?vue&type=script&lang=js"
export * from "./BoostedFeed.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35f8a035",
  null
  
)

export default component.exports