<template>
    <div id="report-content-modal" class="create-post is-story" uk-modal="">
        <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical rounded-lg p-0 lg:w-8/12 relative shadow-2xl uk-animation-slide-bottom-small">
            <div class="text-center py-3 border-b">
                <h3 class="text-lg font-semibold">Report {{ replaceAll(type, '_', ' ') }}</h3>
                <button class="uk-modal-close-default bg-gray-100 rounded-full p-2.5 right-2" type="button" uk-close="" uk-tooltip="title: Close ; pos: bottom ;offset:7"></button>
            </div>
            <div class="bsolute bottom-0 p-4 space-x-4 w-full">
                <div class="flex-old bg-gray-50 p-2 shadow-sm items-center mt-3">
                    <div>
                        <h5 class="mb-4">Reason</h5>
                        <div>
                            <textarea class="form-control p-1 px-3" v-model="reason"></textarea>
                        </div>
                        <div>
                            <button class="button secondary bg-orange mt-4" @click="reportFeedContent()">Report</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </template>
    
    <script>
    import { ApiService } from "@/services/api.service";
    export default {
        name: "ReportContent",
        props: {
        },
        components: {
        },
        data() {
            return {
                currentYear: new Date().getFullYear(),
                appName: "-",
                currentUser: "",
                type: "",
                id: "",
                reason: "",
            };
        },
        mounted: function () {
            this.appName = this.$APP_NAME;
            this.currentUser = this.$store.getters["user/currentUser"];

            let vueThis = this
            this.$root.$on("open-report-content-modal",(content) => {
                // console.log('content', content)
                vueThis.id = content['id']
                vueThis.type = content['type']
            })
        },
        methods: {
            async reportFeedContent(){
                this.toggleProgress('show')
                let apiResponse = await ApiService.ReportFeedContent(this.type, this.id, this.reason)
                this.$store.commit("toast/Notify", {
                    type: apiResponse["status"],
                    message: apiResponse["message"],
                });
                $('#report-content-modal').removeClass('uk-open').hide();
                this.toggleProgress('hide')
                if(apiResponse['status'] == 'success'){
                    this.reason = ''
                    location.href = ''
                }
            }
        }
    };
    </script>
    
    <style lang="scss" scoped>
    </style>
    