<template>
  <!-- Share post modal -->
  <div id="order-product-modal" class="create-post is-story" uk-modal="">
    <div
      class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical rounded-lg p-0 lg:w-5/12 relative shadow-2xl uk-animation-slide-bottom-small"
    >
      <div class="text-center py-3 border-b">
        <h3 class="text-lg font-semibold">Ordered Product</h3>
        <button
          class="uk-modal-close-default bg-gray-100 rounded-full p-2.5 right-2"
          type="button"
          uk-close=""
          uk-tooltip="title: Close ; pos: bottom ;offset:7"
        ></button>
      </div>
      <div class="bsolute bottom-0 p-4 space-x-4 w-full">
        <div
          class="flex-old bg-gray-50 p-5 shadow-sm items-center"
        >
            <div>
                <p v-if="!product.date_accepted && !product.date_rejected" >Order has to be accepted or rejcted before {{ formatDateTimeToFuture(product.created_at) }}</p>
                <p v-if="product.date_accepted" class="mb-2">Product order accepted on {{ unixToUtc(product.date_accepted) }}</p>
                <p v-if="product.date_rejected" class="mb-2">Product order rejected on {{ unixToUtc(product.date_rejected) }}</p>
                <p v-if="product.date_shipped" class="mb-2">Product order shipped on {{ unixToUtc(product.date_shipped) }}</p>
                <p v-if="product.date_delivered" class="mb-2">Product order delivered to customer on {{ unixToUtc(product.date_delivered) }}</p>
                <p v-if="product.date_received" class="mb-2">Product order received by customer on {{ unixToUtc(product.date_received) }}</p>
                <p v-if="product.date_confirmed" class="mb-2">Product order confirmed on {{ unixToUtc(product.date_confirmed) }}</p>
                <p v-if="product.date_disputed" class="mb-2">Product order disputed on {{ unixToUtc(product.date_disputed) }}</p>
            </div>
            <div
                class="flex-old flex-1 items-center lg:justify-center justify-center space-x-2"
            >
                <button @click="acceptOrder" class="mb-3 h-8 lg:px-3 px-2 rounded-md bg-blue-600 text-white space-x-1" v-if="!product.date_accepted">Accept Order</button>
                <button @click="rejectOrder" class="mb-3 h-8 lg:px-3 px-2 rounded-md bg-red-600 text-white space-x-1" v-if="!product.date_rejected && !product.date_shipped">Reject Order</button>
                <button @click="shipOrder" class="mb-3 h-8 lg:px-3 px-2 rounded-md bg-yellow-600 text-white space-x-1" v-if="product.date_accepted && !product.date_rejected && !product.date_shipped">Mark as Shipped</button>
                <button @click="deliverOrder" class="mb-3 h-8 lg:px-3 px-2 rounded-md bg-green-600 text-white space-x-1" v-if="product.date_shipped && !product.date_delivered">Mark as Delivered</button>
            </div>
            <br/>
            <div>
                <a class="mt-4 mb-3 h-8 lg:px-3 px-5 py-3 rounded-md bg-blue-600 text-white space00-x-1" :href="'/dashboard/products/shop-order/'+product.id" >view product order</a>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {ApiService} from '@/services/api.service'
export default {
  name: "OrderProductModal",
  props: {
      product: Object,
      description: String
  },
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
  mounted: function () {
  },
  components: {
  },
  methods: {
    async acceptOrder() {
        this.toggleProgress('show')
        let apiResponse = await ApiService.MarkShopProductOrder('accepted',this.product.id);
        if (apiResponse["status"] != "success") {} else {
            location.href = ''
        }
        this.toggleProgress('hide')
    },
    async rejectOrder() {
        this.toggleProgress('show')
        let apiResponse = await ApiService.MarkShopProductOrder('rejected',this.product.id);
        if (apiResponse["status"] != "success") {} else {
            location.href = ''
        }
        this.toggleProgress('hide')
    },
    async shipOrder() {
        this.toggleProgress('show')
        let apiResponse = await ApiService.MarkShopProductOrder('shiped',this.product.id);
        if (apiResponse["status"] != "success") {} else {
            location.href = ''
        }
        this.toggleProgress('hide')
    },
    async deliverOrder() {
        this.toggleProgress('show')
        let apiResponse = await ApiService.MarkShopProductOrder('delivered',this.product.id);
        if (apiResponse["status"] != "success") {} else {
            location.href = ''
        }
        this.toggleProgress('hide')
    },
    async open() {
    },
    closeModal(){
      $('#order-product-modal').removeClass('uk-open').hide();
    }
  },
};
</script>

<style lang="scss" scoped></style>
