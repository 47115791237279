<template>
<div class="message-bubble me">
    <div class="message-bubble-inner">
        <div class="message-avatar">
            <img v-if="currentUser.profile_photo" :src="currentUser.profile_photo" alt="">
            <img v-else :src="defaultImageUrl()" alt="">
            <span class="user_status status_online d-none"></span>
        </div>
        <div class="message-text">
            <p class="text-white" v-if="messageTempType == 'text'" v-html="customTextRender(messageTemp, 'text-white')"></p>
            <img v-else-if="messageTempType == 'image'" :src="messageTemp" style="height:50px" class="mr-5" />
            <p v-else-if="messageTempType == 'link'">
                <a :href="messageTemp" target="_blank" class="text-white text-white:hover">{{ messageTemp }}</a>
            </p>
        </div>
    </div>
    <div class="clearfix"></div>
    <p class="text-right">
        <small>sending...</small>
    </p>
</div>
</template>

<script>
export default {
    props: {
        messageTempType: String,
        messageTemp: String,
    },
    name: 'MyMessage',
    data() {
        return {
            currentUser: '',
            currentShop: '',
        }
    },
    mounted: function () {
        this.currentUser = this.$store.getters['user/currentUser'] || {}
    },
    methods: {
        setMessageToReply(a) {
        },
    },
}
</script>
