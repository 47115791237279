/**
 * anything that relates to HOST URL
 */
export default {
    namespaced: true,
    state: {
        env: 'local',
        apiLocal: 'http://localhost:6020/api/v2',
        apiStaging: 'https://apidev.vitisocial.com/api/v2',
        apiLive: 'https://api.vitisocial.com/api/v2',
        promoterLocal: 'http://localhost:8081',
        promoterStaging: 'https://promotersdev.vitisocial.com',
        promoterLive: 'https://promoters.vitisocial.com',
        advertiserLocal: 'http://localhost:8082',
        advertiserStaging: 'https://advertisersdev.vitisocial.com',
        advertiserLive: 'https://advertisers.vitisocial.com',
        appLocal: 'http://localhost:8080',
        appStaging: 'https://test.vitisocial.com',
        appLive: 'https://vitisocial.com',
        seoLocal: 'http://localhost:8000',
        seoStaging: 'https://l.vitisocial.com',
        seoLive: 'https://l.vitisocial.com',
    },

    mutations: {
        SET_ENV(state, env) {
            state.env = env
        }
    },

    getters: {
        apiUrl: state => {
            console.log('location.href', location.href)
            let env = 'staging'
            if (location.href.search('localhost') > -1) {
                env = 'local'
            } else {
                if (location.href.search('viraltrend.org') > -1 || location.href.search('vitisocial.com') > -1) {
                    env = 'live'
                }
                if (location.href.search('v2.viraltrend.org') > -1 || location.href.search('test.vitisocial.com') > -1) {
                    env = 'staging'
                }
            }
            let url = ''
            if (env == 'local') {
                url = state.apiLocal
            } else if (env == 'staging') {
                url = state.apiStaging
            } else if (env == 'live') {
                url = state.apiLive
            }
            return url;
        },
        socketUrl: state => {
            let env = 'staging'
            if (location.href.search('localhost') > -1) {
                env = 'local'
            } else {
                if (location.href.search('viraltrend.org') > -1 || location.href.search('vitisocial.com') > -1) {
                    env = 'live'
                }
                if (location.href.search('v2.viraltrend.org') > -1 || location.href.search('test.vitisocial.com') > -1) {
                    env = 'staging'
                }
            }
            let url = ''
            if (env == 'local') {
                url = state.apiLocal.replace("/api/v2", "").replace('https://', 'wss://').replace('http://', 'ws://')
            } else if (env == 'staging') {
                url = state.apiStaging.replace("/api/v2", "").replace('https://', 'wss://').replace('http://', 'ws://')
            } else if (env == 'live') {
                url = state.apiLive.replace("/api/v2", "").replace('https://', 'wss://').replace('http://', 'ws://')
            }
            return url;
        },
        promoterUrl: state => {
            let env = 'staging'
            if (location.href.search('localhost') > -1) {
                env = 'local'
            } else {
                if (location.href.search('viraltrend.org') > -1 || location.href.search('vitisocial.com') > -1) {
                    env = 'live'
                }
                if (location.href.search('v2.viraltrend.org') > -1 || location.href.search('test.vitisocial.com') > -1) {
                    env = 'staging'
                }
            }
            let url = ''
            if (env == 'local') {
                url = state.promoterLocal
            } else if (env == 'staging') {
                url = state.promoterStaging
            } else if (env == 'live') {
                url = state.promoterLive
            }
            return url;
        },
        advertiserUrl: state => {
            let env = 'staging'
            if (location.href.search('localhost') > -1) {
                env = 'local'
            } else {
                if (location.href.search('viraltrend.org') > -1 || location.href.search('vitisocial.com') > -1) {
                    env = 'live'
                }
                if (location.href.search('v2.viraltrend.org') > -1 || location.href.search('test.vitisocial.com') > -1) {
                    env = 'staging'
                }
            }
            let url = ''
            if (env == 'local') {
                url = state.advertiserLocal
            } else if (env == 'staging') {
                url = state.advertiserStaging
            } else if (env == 'live') {
                url = state.advertiserLive
            }
            return url;
        },
        appUrl: state => {
            let env = 'staging'
            if (location.href.search('localhost') > -1) {
                env = 'local'
            } else {
                if (location.href.search('viraltrend.org') > -1 || location.href.search('vitisocial.com') > -1) {
                    env = 'live'
                }
                if (location.href.search('v2.viraltrend.org') > -1 || location.href.search('test.vitisocial.com') > -1) {
                    env = 'staging'
                }
            }
            let url = ''
            if (env == 'local') {
                url = state.appLocal
            } else if (env == 'staging') {
                url = state.appStaging
            } else if (env == 'live') {
                url = state.appLive
            }
            return url;
        },
        seoUrl: state => {
            let env = 'staging'
            if (location.href.search('localhost') > -1) {
                env = 'local'
            } else {
                if (location.href.search('viraltrend.org') > -1 || location.href.search('vitisocial.com') > -1) {
                    env = 'live'
                }
                if (location.href.search('v2.viraltrend.org') > -1 || location.href.search('test.vitisocial.com') > -1) {
                    env = 'staging'
                }
            }
            let url = ''
            if (env == 'local') {
                url = state.seoLocal
            } else if (env == 'staging') {
                url = state.seoStaging
            } else if (env == 'live') {
                url = state.seoLive
            }
            return url;
        },
    }
}