<template>
<div id="feed-likers-modal" class="create-post is-story" uk-modal="">
    <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical rounded-lg p-0 lg:w-8/12 relative shadow-2xl uk-animation-slide-bottom-small">
        <div class="text-center py-3 border-b">
            <h3 class="text-lg font-semibold"></h3>
            <button class="uk-modal-close-default bg-gray-100 rounded-full p-2.5 right-2" type="button" uk-close="" uk-tooltip="title: Close ; pos: bottom ;offset:7"></button>
        </div>
        <div class="bsolute bottom-0 p-4 space-x-4 w-full">
            <div class="flex-old bg-gray-50 p-3 shadow-sm items-center mt-3">
                <div>
                    <h5 class="mb-4"></h5>
                    <ul>
                        <template v-for="liker in likers">
                            <li v-if="liker['user']" class="mb-2">
                                <a :href="'/dashboard/timeline/'+liker['user']['id']" target="_blank" class="text-blue d-flex">
                                    <profile-icon :url="liker['user']['profile_photo']"></profile-icon>
                                    <span style="padding-top: 10px;margin-left: 10px;">{{ liker['user']['name'] }}</span>
                                    <verified-badge v-if="liker['user'] && liker['user']['verified'] == 'true'" mt="one"></verified-badge>
                                    <span style="padding-top: 0px;margin-left: 10px;" v-if="!liker['type']">
                                        <img class="icon_gif" src="/assets/img/reaction/gif/like.gif" />
                                    </span>
                                    <span style="padding-top: 0px;margin-left: 10px;" v-else>
                                        <img v-if="liker['type'] == 'like'" class="icon_gif" src="/assets/img/reaction/gif/like.gif" />
                                        <img v-else-if="liker['type'] == 'love'" class="icon_gif" src="/assets/img/reaction/gif/love.gif" />
                                        <img v-else-if="liker['type'] == 'care'" class="icon_gif" src="/assets/img/reaction/gif/care.svg" />
                                        <img v-else-if="liker['type'] == 'laugh'" class="icon_gif" src="/assets/img/reaction/gif/haha.gif" />
                                        <img v-else-if="liker['type'] == 'surprise'" class="icon_gif" src="/assets/img/reaction/gif/wow.gif" />
                                        <img v-else-if="liker['type'] == 'sad'" class="icon_gif" src="/assets/img/reaction/gif/sad.gif" />
                                        <img v-else-if="liker['type'] == 'angry'" class="icon_gif" src="/assets/img/reaction/gif/angry.gif" />
                                        <img v-else class="icon_gif" src="/assets/img/reaction/gif/like.gif" />
                                    </span>
                                </a>
                            </li>
                        </template>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import ProfileIcon from '@/include/Dashboard/Feed/ProfileIcon'
import VerifiedBadge from '../../components/VerifiedBadge.vue';
export default {
    name: "FeedLikersModal",
    props: {
        likers: Array
    },
    components: {
        ProfileIcon,
        VerifiedBadge,
    },
    data() {
        return {
            currentYear: new Date().getFullYear(),
            appName: "-",
            currentUser: "",
        };
    },
    mounted: function () {
        this.appName = this.$APP_NAME;
        this.currentUser = this.$store.getters["user/currentUser"];
        // console.log('likers', this.likers)
    },
    methods: {
    }
};
</script>

<style lang="scss" scoped>
</style>
