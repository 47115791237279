import { render, staticRenderFns } from "./CourseLessonMaterials.vue?vue&type=template&id=5b0e1652&scoped=true"
import script from "./CourseLessonMaterials.vue?vue&type=script&lang=js"
export * from "./CourseLessonMaterials.vue?vue&type=script&lang=js"
import style0 from "./CourseLessonMaterials.vue?vue&type=style&index=0&id=5b0e1652&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b0e1652",
  null
  
)

export default component.exports