<template>
<div id="wrapper">

    <Header />

    <sidebar></sidebar>

    <!-- Main Contents -->
    <div class="main_content">
        <div class="mcontainer">

            <div class="flex justify-between relative md:mb-4 mb-3">
                <div class="flex-1">
                    <h2 class="text-2xl font-semibold"> Videos </h2>
                    <nav class="responsive-nav border-b md:m-0 -mx-4">
                        <ul>
                            <li :class="randomActive"><a @click="makeActiveSection('Random')" class="lg:px-2 mouse-pointer"> Suggestions </a></li>
                            <li :class="recentActive"><a @click="makeActiveSection('Recent')" class="lg:px-2 mouse-pointer"> Newest </a></li>
                            <li :class="popularActive"><a @click="makeActiveSection('Trending')" class="lg:px-2 mouse-pointer"> Popular </a></li>
                        </ul>
                    </nav>
                    <progress-loader v-if="videoPreloader == 'true'" style="position: relative"></progress-loader>
                </div>
            </div>

            <!-- Vide slider -->

            <div class="relative" uk-slider="finite: true">

                <div class="uk-slider-container px-1 py-3">
                    <ul class="uk-slider-items uk-child-width-1-4@m uk-child-width-1-3@s uk-child-width-1-2 uk-grid-small uk-grid">
                        <li v-for="v in videos">
                            <a :href="'/dashboard/video/'+v.id" class="w-full md:h-36 h-28 overflow-hidden rounded-lg relative block">
                                <img v-if="v.thumbnail_image" :src="v.thumbnail_image" alt="" class="w-full h-full absolute inset-0 object-cover">
                                <img v-else :src="defaultPostUrl()" alt="" class="w-full h-full absolute inset-0 object-cover">
                                <span class="absolute bg-black bg-opacity-60 bottom-1 font-semibold px-1.5 py-0.5 right-1 rounded text-white text-xs"> {{ videoDuration(v.duration) }}</span>
                                <img src="/assets/images/icon-play.svg" class="w-12 h-12 uk-position-center" alt="">
                            </a>
                            <div class="pt-3">
                                <a :href="'/dashboard/video/'+v.id" class="font-semibold line-clamp-2"> {{ v.title }} </a>
                                <div class="pt-2">
                                    <a :href="'/dashboard/timeline/'+v.user.id" class="text-sm" v-if="v.user"> 
                                        {{ v.user.name }} 
                                        <verified-badge v-if="v.user.verified == 'true'" size="small"></verified-badge>
                                    </a>
                                    <div class="flex space-x-2 items-center text-sm mt-1">
                                        <div> {{ timeAgo(v.created_at) }} Ago</div>
                                        <div class="md:block hidden">·</div>
                                        <div v-if="!v.comments_list"> {{ v.comments }} comments</div>
                                        <div v-else> {{ v.comments_list.length }} comments</div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>

                    <a class="absolute bg-white top-16 flex items-center justify-center p-2 -left-4 rounded-full shadow-md text-xl w-9 z-10 dark:bg-gray-800 dark:text-white" href="#" uk-slider-item="previous"> <i class="fa fa-feather-chevron-left"></i></a>
                    <a class="absolute bg-white top-16 flex items-center justify-center p-2 -right-4 rounded-full shadow-md text-xl w-9 z-10 dark:bg-gray-800 dark:text-white" href="#" uk-slider-item="next"> <i class="fa fa-feather-chevron-right"></i></a>

                </div>
            </div>

            <br>

            <div class="md:mb-4 mb-3">
                <h2 class="text-2xl font-semibold"> Your videos </h2>
                <nav class="responsive-nav border-b md:m-0 -mx-4">
                    <ul>
                        <li :class="randomActiveUser"><a @click="makeUserActiveSection('Random')" class="mouse-pointer lg:px-2"> Suggestions </a></li>
                        <li :class="recentActiveUser"><a @click="makeUserActiveSection('Recent')" class="mouse-pointer lg:px-2"> Newest </a></li>
                        <li :class="popularActiveUser"><a @click="makeUserActiveSection('Trending')" class="mouse-pointer lg:px-2"> My videos </a></li>
                    </ul>
                </nav>
                <progress-loader v-if="userVideoPreloader == 'true'" style="position: relative"></progress-loader>
            </div>

            <!--  videos  list -->

            <div class="divide-y">

                <div v-for="uv in user_videos" class="flex md:space-x-6 space-x-4 md:py-5 py-3 relative">
                    <a :href="'/dashboard/video/'+uv.id" class="md:w-64 md:h-40 w-36 h-24 overflow-hidden rounded-lg relative shadow-sm">
                        <img v-if="uv.thumbnail_image" :src="uv.thumbnail_image" alt="" class="w-full h-full absolute inset-0 object-cover">
                        <img v-else :src="defaultPostUrl()" alt="" class="w-full h-full absolute inset-0 object-cover">
                        <span class="absolute bg-black bg-opacity-60 bottom-1 font-semibold px-1.5 py-0.5 right-1 rounded text-white text-xs"> {{ videoDuration(uv.duration) }}</span>
                        <img src="/assets/images/icon-play.svg" class="w-12 h-12 uk-position-center" alt="">
                    </a>
                    <div class="flex-1 space-y-2">

                        <a href="video-watch.html" class="md:text-xl font-semibold line-clamp-2"> {{ uv.title }} </a>
                        <p class="leading-6 pr-4 line-clamp-2 md:block hidden"> {{ uv.description }} </p>
                        <a v-if="uv.user" :href="'/dashboard/timeline/'+uv.user.id" class="font-semibold block text-sm"> 
                            {{ uv.user.name }}
                            <verified-badge v-if="uv.user.verified == 'true'" size="small"></verified-badge>
                        </a>

                        <div class="flex items-center justify-between">
                            <div class="flex space-x-3 items-center text-sm md:pt-3">
                                <div> {{ timeAgo(uv.created_at) }} Ago</div>
                                <div class="md:block hidden">·</div>
                                <div v-if="!uv.comments_list"> {{ uv.comments }} comments</div>
                                <div v-else> {{ uv.comments_list.length }} comments</div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    </div>

    <create-video-post @saveVideo="saveUserVideo"></create-video-post>
    <progress-loader v-if="videoPreloader == 'true'"></progress-loader>
    <dashboard-widgets></dashboard-widgets>

</div>
</template>

<script>
import {
    VueEditor
} from "vue2-editor";
import {
    ApiService
} from '@/services/api.service'
import Header from '@/include/Dashboard/Header.vue'
import Sidebar from '@/include/Dashboard/Sidebar.vue'
import DashboardWidgets from '@/components/DashboardWidgets'
import OpenChatBox from '@/include/Modals/OpenChatBox.vue'
import ProfileIcon from '@/include/Dashboard/Feed/ProfileIcon'
import ProgressLoader from '@/components/ProgressLoader'
import TimelineScreen from '@/include/Dashboard/Timeline/TimelineScreen'
import FriendScreen from '@/include/Dashboard/Timeline/FriendScreen'
import PhotoScreen from '@/include/Dashboard/Timeline/PhotoScreen'
import PageScreen from '@/include/Dashboard/Timeline/PageScreen'
import GroupScreen from '@/include/Dashboard/Timeline/GroupScreen'
import VideoScreen from '@/include/Dashboard/Timeline/VideoScreen'
import SingleFileUpload from '@/components/SingleFileUpload'
import IonIcon from '@/components/IonIcon'
import CreateVideoPost from '@/components/CreateVideoPost'
import axios from 'axios'
import VerifiedBadge from '@/components/VerifiedBadge'

export default {
    name: 'Videos',
    components: {
        Header,
        Sidebar,
        DashboardWidgets,
        ProgressLoader,
        VueEditor,
        TimelineScreen,
        FriendScreen,
        PhotoScreen,
        PageScreen,
        GroupScreen,
        VideoScreen,
        SingleFileUpload,
        CreateVideoPost,
        IonIcon,
        VerifiedBadge,
    },
    data() {
        return {
            thisUser: {},
            videos: '',
            category: 'random',
            section: '',
            user_videos: '',
            user_category: 'random',
            popularActive: '',
            recentActive: '',
            randomActive: 'active',
            popularActiveUser: '',
            recentActiveUser: '',
            randomActiveUser: 'active',
            videoPreloader: 'false',
            userVideoPreloader: 'false',
            page: 0,
            pageUser: 0
        }
    },
    mounted: function () {
        this.thisUser = this.$store.getters["user/currentUser"];
        this.getVideos()
        this.getUserVideos(this.thisUser['id'])
    },
    methods: {
        async getVideos() {
            this.videoPreloader = 'true'
            let apiResponse = await ApiService.GetFeedVideos(this.page, this.category);
            // console.log('GetVideos', apiResponse)
            if (apiResponse['status'] == 'success') {
                let videos = apiResponse['data']['videos']
                if (videos.length > 0) {
                    this.page++
                }
                // console.log('this.page', this.page)
                if (this.videos.length == 0) {
                    this.videos = videos
                } else {
                    if (videos.length > 0) {
                        this.videos = this.videos.concat(videos)
                    }
                }
            }
            this.videoPreloader = 'false'
        },
        async getUserVideos(id) {
            this.useVideoPreloader = 'true'
            let apiResponse = await ApiService.GetUserFeedVideos(this.pageUser, id, this.user_category);
            // console.log('GetUserVideos', apiResponse)
            if (apiResponse['status'] == 'success') {
                let user_videos = apiResponse['data']['videos']
                if (user_videos.length > 0) {
                    this.pageUser++
                }
                // console.log('this.pageUser', this.pageUser)
                if (this.user_videos.length == 0) {
                    this.user_videos = user_videos
                } else {
                    if (user_videos.length > 0) {
                        this.user_videos = this.user_videos.concat(user_videos)
                    }
                }
            }
            this.useVideoPreloader = 'false'
        },
        async addFavourites(id) {

        },
        async likeUserVideo(id) {
            if (this.pendingRequest == true) {
                // console.log('Pending Req')
                return
            }
            this.pendingRequest = true
            let apiResponse = await ApiService.LikePhoto(id)
            if (apiResponse['status'] != 'success') {} else {}
            this.$store.commit("toast/Notify", {
                type: apiResponse["status"],
                message: apiResponse["message"],
            });
            this.pendingRequest = false
        },
        async saveUserVideo(title, description, reach, url, age_restrict, keywords) {
            if (this.pendingRequest == true) {
                // console.log('Pending Req')
                return
            }
            this.pendingRequest = true
            let apiResponse = await ApiService.SaveVideo(title, description, reach, url, age_restrict, keywords)
            this.$store.commit("toast/Notify", {
                type: apiResponse["status"],
                message: apiResponse["message"],
            });
            if (apiResponse['status'] != 'success') {} else {

                this.page = 0
                this.getUserVideos(this.id)
            }
            this.pendingRequest = false
        },
        async makeActiveSection(a) {
            this.recentActive = ''
            this.oldestActive = ''
            this.popularActive = ''
            this.randomActive = ''

            this.page = 0;
            this.section = a

            this.videos = []

            if (a == 'Recent') {
                this.recentActive = 'active'
                this.category = 'recent'
                this.getVideos()
            } else if (a == 'Oldest') {
                this.oldestActive = 'active'
                this.category = 'oldest'
                this.getVideos()
            } else if (a == 'Trending') {
                this.popularActive = 'active'
                this.category = 'popular'
                this.getVideos()
            } else if (a == 'Random') {
                this.randomActive = 'active'
                this.category = 'random'
                this.getVideos()
            }
        },
        async makeUserActiveSection(a) {
            this.recentActiveUser = ''
            this.oldestActiveUser = ''
            this.popularActiveUser = ''
            this.randomActiveUser = ''

            this.pageUser = 0;
            this.sectionUser = a
            this.user_videos = []

            if (a == 'Recent') {
                this.recentActiveUser = 'active'
                this.user_category = 'recent'
                this.getUserVideos()
            } else if (a == 'Oldest') {
                this.oldestActiveUser = 'active'
                this.user_category = 'oldest'
                this.getUserVideos()
            } else if (a == 'Trending') {
                this.popularActiveUser = 'active'
                this.user_category = 'popular'
                this.getUserVideos()
            } else if (a == 'Random') {
                this.randomActiveUser = 'active'
                this.user_category = 'random'
                this.getUserVideos()
            }
        },
        onImageUploaded(response) {
            // Handle files like:
            // console.log('::::',response)
            if (response['status'] == 'success') {
                this.image = response['data']['file_name']
            }
            this.$store.commit("toast/Notify", {
                type: response["status"],
                message: response["message"],
            });
        },
        async loadMore() {
            this.getUserVideos(this.id)
        },
    },
}
</script>

<style>
.profilePage123 input {
    width: 157px !important;
    height: 32px !important;
}
</style>
