<template>
<div id="wrapper">
    <Header />

    <sidebar></sidebar>

    <!-- Main Contents -->
    <div class="main_content">
        <div class="mcontainer">
            <div class="lg:flex lg:space-x-12">
                <div class="lg:w-2/3 flex-shirink-0">
                    <div class="flex justify-between relative md:mb-4 mb-3">
                        <div class="flex-1 text-gray-900">
                            <h2 class="text-2xl font-semibold">Forums by Hash Tag #{{ hash_tag }}</h2>
                        </div>
                    </div>

                    <ul class="card divide-y divide-gray-100 sm:m-0 sm:-mx-5">
                        <li v-for="topic in topics">
                            <div class="flex items-start space-x-5 p-7">
                                <img v-if="topic.image" :src="topic.image" alt="" class="w-12 h-12 rounded-full" />
                                <img v-else :src="defaultPostUrl()" alt="" class="w-12 h-12 rounded-full" />
                                <div class="flex-1">
                                    <router-link :to="'/dashboard/forum/' + topic.name_key" class="text-lg font-semibold line-clamp-1 mb-1 capitalize">
                                        {{ topic.title }}
                                    </router-link>
                                    <p class="text-sm text-gray-400 mb-2 capitalize">
                                        <template v-if="topic.user">
                                            Posted By: <router-link :to="'/dashboard/timeline/'+topic.user.id">{{ topic.user.username }}</router-link> 
                                        </template>
                                        <template>
                                            <span> Posted:</span>
                                        </template>
                                        {{ timeAgo(topic.created_at) }} ago
                                    </p>
                                    <p class="leading-6 line-clamp-2 mt-3">
                                        {{ blogSubtitle(topic.content,150) }}
                                    </p>
                                </div>
                                <div class="sm:flex items-center space-x-4 hidden">
                                    <svg class="w-7 h-7" fill="currentColor" viewbox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M2 5a2 2 0 012-2h7a2 2 0 012 2v4a2 2 0 01-2 2H9l-3 3v-3H4a2 2 0 01-2-2V5z"></path>
                                        <path d="M15 7v2a4 4 0 01-4 4H9.828l-1.766 1.767c.28.149.599.233.938.233h2l3 3v-3h2a2 2 0 002-2V9a2 2 0 00-2-2h-1z"></path>
                                    </svg>
                                    <span class="text-xl"> {{ topic.comments.length }} </span>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <div v-if="pendingRequest == false && topics.length == 0" class="bg-white p-2">
                        <h4>No Forum Topic Found with this Hash Tag</h4>
                    </div>
                </div>

                <div class="lg:w-1/3 pt-5 text-gray-900">
                    <div uk-sticky="offset:100" class="">
                        <h2 class="text-xl font-semibold mb-2">Top Contributors</h2>
                        <p>People who started the most discussions on Talks.</p>
                        <br />
                        <ul class="space-y-3 card-mobile">
                            <li v-for="tc in topContributors">
                                <div class="flex items-center space-x-3">
                                    <img v-if="tc.profile_photo" :src="tc.profile_photo" alt="" class="w-8 h-8 rounded-full" />
                                    <img v-else :src="defaultPostUrl()" alt="" class="w-8 h-8 rounded-full" />
                                    <router-link :to="'/dashboard/timeline/'+tc.id" class="font-semibold capitalize"> 
                                        {{ tc.name }} <verified-badge v-if="tc.verified == 'true'" size="small"></verified-badge>
                                    </router-link>
                                    <div class="flex items-center space-x-2">
                                        <i name="fa fa-comment" class="text-lg"></i>
                                        <span> {{ tc.forum_posts || 0 }} </span>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div id="progressLoader">
            <progress-loader></progress-loader>
        </div>
    </div>

    <dashboard-widgets page="forums"></dashboard-widgets>
</div>
</template>

<script>
import {
    ApiService
} from "@/services/api.service";
import Header from "@/include/Dashboard/Header.vue";
import Sidebar from "@/include/Dashboard/Sidebar.vue";
import DashboardWidgets from '@/components/DashboardWidgets'
import OpenChatBox from "@/include/Modals/OpenChatBox.vue";
import Feeds from "@/include/Dashboard/Feeds.vue";
import ProgressLoader from '@/components/ProgressLoader'
import VerifiedBadge from '@/components/VerifiedBadge'

export default {
    name: "ForumsByHashTag",
    components: {
        Header,
        Sidebar,
        DashboardWidgets,
        OpenChatBox,
        Feeds,
        ProgressLoader,
        VerifiedBadge,
    },

    data() {
        return {
            topics: [],
            recentTopics: [],
            page: 0,
            pendingRequest: false,
            section: 'hash_tag',
            hash_tag: '',
            pagination: {
                limit: 20,
                offset: 0,
                total: 60,
            },
        };
    },
    mounted: function () {
        let hash_tag = this.$route.params['hash_tag']
        this.hash_tag = hash_tag
        this.getForumPostTop()
        this.getForumPosts()
    },
    methods: {
        async getForumPosts() {
            this.pendingRequest = true;
            this.toggleProgress('show')
            let apiResponse = await ApiService.GetForumPosts(this.page, this.section, this.hash_tag);
            if (apiResponse["status"] != "success") {} else {
                this.page++;
                this.topics = apiResponse["data"];
                this.pendingRequest = false;
            }
            this.toggleProgress('hide')
        },
        async getForumPostTop() {
            this.pendingRequest = true;
            this.toggleProgress('show')
            let apiResponse = await ApiService.GetForumPostTop();
            if (apiResponse["status"] != "success") {} else {
                this.topContributions = apiResponse["data"]['top_contributions'];
                this.topContributors = apiResponse["data"]['top_contributors'];
                this.pendingRequest = false;
            }
            this.toggleProgress('hide')
        },
    },
};
</script>

<style>
    .forum-image img {
        width: 3rem !important;
        height: 3rem !important;
        border-radius: 9999px;
        position: relative;
    }
</style>

<style lang="scss" scoped>
    .some-modal-content {
        min-width: 400px;
        padding: 25px;

        .buttons button {
            padding: 10px;
            margin: 10px;
        }
    }
</style>
