<template>
<div id="wrapper">
    <!-- Header -->
    <home-header></home-header>
    <auth-styles pageNameMain="Contact Us"></auth-styles>

    <div class="home-content">

        <section class="bg-white">
            <div class="bg-gradient-to-tr flex from-blue-400 h-52 items-center justify-center lg:h-80 pb-10 relative to-blue-300 via-blue-400 w-full">

                <div class="text-center max-w-xl mx-auto z-10 relative px-5">
                    <div class="lg:text-4xl text-2xl text-white font-semibold mb-3"> Have Questions? </div>
                    <div class="text-white text-lg font-medium text-opacity-90"> </div>
                </div>

            </div>
            <div class="max-w-7xl mx-auto p-7 old-mb-10">

                <div class="-mt-16 bg-white p-10 relative rounded-md">

                </div>

            </div>
        </section>
        <section data-section-id="1" data-category="contacts" data-component-id="ea2020b0_04_awz" data-path="0" class="bg-white">
            <div class="pb-12 old-pt-24 2xl:pt-40 bg-blueGray-100 rounded-b-10xl" data-path="0.0">
                <div class="container px-4 mx-auto" data-path="0.0.0">
                    <div class="text-center" data-path="0.0.0.0">
                        <div class="flex items-center flex-wrap" data-path="0.0.0.0.2">
                            <div class="w-full lg:w-1/3 mb-8 lg:mb-0 pb-4 lg:pb-0 md:px-8 xl:px-16 border-b lg:border-b-0 lg:border-r border-gray-200 border-opacity-50" data-path="0.0.0.0.2.0">
                                <div class="flex items-center" data-path="0.0.0.0.2.0.0">
                                    <img class="mr-6 h-10" src="/assets/icons/icon1.svg" alt="" data-config-id="auto-img-1-4" data-path="0.0.0.0.2.0.0.0">
                                    <a class="text-xl lg:text-xl font-medium" data-config-id="auto-txt-3-4" data-path="0.0.0.0.2.0.0.1">+16144055997</a>
                                </div>
                            </div>
                            <div class="w-full lg:w-1/3 mb-8 lg:mb-0 pb-4 lg:pb-0 md:px-8 xl:px-16 border-b lg:border-b-0 lg:border-r border-gray-200 border-opacity-50" data-path="0.0.0.0.2.1">
                                <div class="flex items-center" data-path="0.0.0.0.2.1.0">
                                    <img class="mr-6 h-10" src="/assets/icons/icon3.svg" alt="" data-config-id="auto-img-2-4" data-path="0.0.0.0.2.1.0.0">
                                    <a class="text-xl lg:text-xl font-medium" data-config-id="auto-txt-4-4" data-path="0.0.0.0.2.1.0.1">admin@vitisocial.com</a>
                                </div>
                            </div>
                            <div class="w-full lg:w-1/3 pb-8 lg:pb-0 md:px-8 xl:px-16" data-path="0.0.0.0.2.2">
                                <div class="flex items-center" data-path="0.0.0.0.2.2.0">
                                    <img class="mr-6 h-10" src="/assets/icons/icon3.svg" alt="" data-config-id="auto-img-2-4" data-path="0.0.0.0.2.1.0.0">
                                    <a class="text-xl lg:text-xl font-medium" data-config-id="auto-txt-4-4" data-path="0.0.0.0.2.1.0.1">support@vitisocial.com</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container mx-auto px-6 pt-5 pb-14" data-path="0.1">
                <div class="flex flex-wrap items-center justify-center" data-path="0.1.0">
                    <a href="https://api.whatsapp.com/send?phone=+16144055997" target="_blank" class="inline-block py-5 px-10 mb-6 md:mb-0 md:mr-12 w-full md:w-auto text-xl leading-6 text-white hover:text-white font-medium tracking-tighter text-center font-heading bg-blue-500 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 rounded-xl" type="button" data-config-id="auto-txt-6-4" data-path="0.1.0.0">Chat on Whatsapp</a>
                </div>
            </div>
        </section>
    </div>

    <auth-scripts></auth-scripts>

</div>
</template>

<script>
import AuthStyles from '@/include/Auth/AuthStyles'
import AuthScripts from '@/include/Auth/AuthScripts'
import FaqSection from '@/include/Home/FaqSection'
import HomeHeader from '@/include/Home/HomeHeader'
export default {
    name: 'ContactPage',
    components: {
        AuthStyles,
        AuthScripts,
        FaqSection,
        HomeHeader,
    },
    data() {
        return {
            currentYear: new Date().getFullYear(),
            faqData: [{
                'question': 'How do I register either as an advertiser or a promoter?',
                'answer': ""
            }]
        }
    },

    methods: {}
}
</script>

<style>
</style>
<style lang="scss" scoped>
</style>
