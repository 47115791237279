<template>
<div class="card" for="group in groups_followed" v-if="group">
    <div class="card-media h-28">
        <div class="card-media-overly"></div>
        <img v-if="group.image" :src="group.image" alt="" class="">
        <img v-else :src="defaultPostUrl()" alt="" class="">
    </div>
    <div class="card-body">
        <a :href="'/dashboard/group/'+group.id" class="font-semibold text-lg truncate mb-1"> 
            {{ group.name }} 
            <verified-badge v-if="page.verified == 'true'" size="small" mt="one"></verified-badge>
        </a>
        <div class="flex items-center space-x-1 text-sm text-gray-500 capitalize">
            <a href="javascript:;"> <span> {{ group.followers_count }} members </span> </a>
            <a href="javascript:;"> <span> </span> </a>
        </div>

        <div class="flex mt-3 space-x-2 text-sm">
            <a v-if="group.user_follow" href="javascript:;" @click="followGroup" class="bg-orange flex flex-1 h-8 items-center justify-center rounded-md text-white capitalize">
                Follow
            </a>
        </div>

    </div>
</div>
</template>

<script>
import {
    ApiService
} from "@/services/api.service";
import SharePostModal from '@/include/Modals/SharePostModal'
import VerifiedBadge from '@/components/VerifiedBadge'

export default {
    name: 'GroupSuggestionTwoComponent',
    props: {
        group: Object
    },
    data() {
        return {
            currentUser: '',
            shareLink: '',
            shareDescription: ''
        }
    },
    components: {
        SharePostModal,
        VerifiedBadge,
    },
    mounted() {
        this.currentUser = this.$store.getters["user/currentUser"];
        this.shareDescription = 'Group on Vitisocial'
    },
    methods: {
        async followGroup() {
            this.toggleProgress('show')
            let apiResponse = await ApiService.FollowGroup(this.group['id']);
            this.toggleProgress('hide')
            this.$store.commit("toast/Notify", {
                type: apiResponse["status"],
                message: apiResponse["message"],
            });
            if (apiResponse["status"] != "success") {} else {
                location.href = ''
            }
        },
        isHappening() {},
        setCurrentLink(id, description) {
            let thisLink = this.$store.getters["host/seoUrl"] + '/group/' + id
            this.shareLink = thisLink
        },
        openGroup(id) {
            location.href = '/dashboard/group/' + id
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
