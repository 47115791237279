<template>
<div class="card md:p-6 p-2 max-w-3xl mx-auto">

    <h2 class="text-xl font-bold"> Pages</h2>
    <nav class="responsive-nav border-b md:m-0 -mx-4">
        <ul>
            <li class="active"><a href="javascript:;" class="lg:px-2"> {{currentUser['name']}} Pages </a></li>
        </ul>
    </nav>

    <div class="mt-5 pt-5">
        <div v-if="pages.length == 0 && pendingRequest == false" class="bg-white p-3">
            <h5 v-if="page == 0">{{ currentUser.name }} has no page yet</h5>
            <h5 v-else>No page here</h5>
        </div>
        <div v-if="pendingRequest == true" class="text-center my-3">
            <i class="fa fa-spin fa-spinner fa-2x"></i>
        </div>
    </div>
    <div class="grid md:grid-cols-4 sm:grid-cols-3 grid-cols-2 gap-4 mt-5">

        <div class="card" v-for="page in pages">
            <a :href="'/dashboard/page/'+page['id']">
                <img v-if="!page.image" :src="defaultImageUrl" class="h-36 object-cover rounded-t-md shadow-sm w-full" style="height: 150px">
                <img v-else :src="page.image" class="h-36 object-cover rounded-t-md shadow-sm w-full" style="height: 150px">
            </a>
            <div class="p-3">
                <a :href="'/dashboard/page/'+page['id']" class="text-base font-semibold mb-0.5"> {{page.name}} </a>
                <p class="font-medium text-sm">{{page.followers_count || 0}} Following </p>
                <button @click="followPage(page.id)" v-if="!page.user_following" class="bg-orange text-white w-full flex font-semibold h-8 items-center justify-center mt-3 px-3 rounded-md  text-sm">
                    Follow
                </button>
                <button v-else class="bg-gray-100 w-full flex font-semibold h-8 items-center justify-center mt-3 px-3 rounded-md  text-sm">
                    Following
                </button>
            </div>
        </div>

    </div>

    <div class="flex justify-center mt-6" v-if="pages.length > 0 && pendingRequest == false">
        <a href="javascript:;" @click="loadMorePages" class="bg-white dark:bg-gray-900 font-semibold my-3 px-6 py-2 rounded-full shadow-md dark:bg-gray-800 dark:text-white">
            Load more ..</a>
    </div>

    <div class="mt-5 pt-5">
        <nav class="responsive-nav border-b md:m-0 -mx-4">
            <ul>
                <li class="active"><a href="javascript:;" class="lg:px-2"> Suggested Pages </a></li>
            </ul>
        </nav>
        <div v-if="random_pages.length == 0 && randomPagesRequest == false" class="bg-white p-3">
            <h5>No random page</h5>
        </div>
        <div v-if="randomPagesRequest == true" class="text-center my-3">
            <i class="fa fa-spin fa-spinner fa-2x"></i>
        </div>
    </div>

    <div class="grid md:grid-cols-4 sm:grid-cols-3 grid-cols-2 gap-4 mt-5">

        <div class="card" v-for="page in random_pages">
            <a :href="'/dashboard/page/'+page['id']">
                <img v-if="!page.image" :src="defaultImageUrl" class="h-36 object-cover rounded-t-md shadow-sm w-full" style="height: 150px">
                <img v-else :src="page.image" class="h-36 object-cover rounded-t-md shadow-sm w-full" style="height: 150px">
            </a>
            <div class="p-3">
                <a :href="'/dashboard/page/'+page['id']" class="text-base font-semibold mb-0.5"> {{page.name}} </a>
                <p class="font-medium text-sm">{{page.followers_count || 0}} Following </p>
                <button @click="followPage(page.id)" v-if="!page.user_following" class="bg-orange text-white w-full flex font-semibold h-8 items-center justify-center mt-3 px-3 rounded-md  text-sm">
                    Follow
                </button>
                <button v-else class="bg-gray-100 w-full flex font-semibold h-8 items-center justify-center mt-3 px-3 rounded-md  text-sm">
                    Following
                </button>
            </div>
        </div>

    </div>

</div>
</template>

<script>
import {
    ApiService
} from '@/services/api.service'
export default {
    name: "PageScreen",
    data() {
        return {
            pages: [],
            random_pages: [],
            page: 0,
            pendingRequest: false,
            randomPagesRequest: false,
        };
    },
    props: {
        currentUser: Object,
    },
    components: {},
    mounted: function () {
        this.getPages()
        this.getRandomPages()
    },
    methods: {
        async getPages() {
            if (this.pendingRequest == true) {
                // console.log('Pending Req')
                return
            }
            this.pendingRequest = true
            let apiResponse = await ApiService.GetOtherUserPages('other_user', this.page)
            if (apiResponse['status'] != 'success') {} else {
                this.pendingRequest = false
                this.pages = apiResponse['data']['pages']
                this.total_pages = apiResponse['data']['total_pages']
            }
        },
        async loadMorePages() {
            this.page++
            this.getPages()
        },
        async followPage(id) {
            this.toggleProgress('show')
            let apiResponse = await ApiService.FollowPage(id);
            this.toggleProgress('hide')
            this.$store.commit("toast/Notify", {
                type: apiResponse["status"],
                message: apiResponse["message"],
            });
            if (apiResponse["status"] != "success") {} else {
                setTimeout(() => {
                    location.href = ''
                }, 1500);
            }
        },
        async unfollowPage(id) {
            let proceed = confirm("Are you sure you want to Unfollow this Page?");
            if (proceed) {
                this.toggleProgress('show')
                let apiResponse = await ApiService.UnfollowPage(id);
                this.toggleProgress('hide')
                this.$store.commit("toast/Notify", {
                    type: apiResponse["status"],
                    message: apiResponse["message"],
                });
                if (apiResponse["status"] != "success") {} else {
                    setTimeout(() => {
                        location.href = ''
                    }, 1500);
                }
            } else {
                //don't proceed
            }
        },
        async getRandomPages() {
            this.randomPagesRequest = true
            let apiResponse = await ApiService.GetOtherUserPages('random', 0)
            if (apiResponse['status'] != 'success') {} else {
                this.randomPagesRequest = false
                this.random_pages = apiResponse['data']['pages']
            }
        },
    }
};
</script>

<style lang="scss" scoped></style>
