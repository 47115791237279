<template>

    <div class="space-y-7">
        <template v-if="boostedFeed">
            <boosted-feed :feed="getBoostedFeed(1,boostedFeed)" preview="true"></boosted-feed>
        </template>
        <template v-for="(thisFeed, index) in admin_pinned_feeds">
            <user-feed :feed="thisFeed" :is_admin="is_admin" preview="true"></user-feed>
            <!-- <google-banner-ad v-if="canShowBoostedFeed(index) == 'true'"></google-banner-ad> -->
        </template>
        <template v-for="(thisFeed, index) in user_pinned_feeds">
            <user-feed :feed="thisFeed" :is_admin="is_admin" preview="true"></user-feed>
            <!-- <google-banner-ad v-if="canShowBoostedFeed(index) == 'true'"></google-banner-ad> -->
        </template>
        <template v-for="(thisFeed, index) in user_new_feeds">
            <user-feed :feed="thisFeed" :is_admin="is_admin" preview="true"></user-feed>
            <google-banner-ad v-if="canShowBoostedFeed(index) == 'true'"></google-banner-ad>
        </template>
        <template v-for="(thisFeed, index) in feeds">
            <div v-view.once="loadMoreFeeds">
                <user-feed :feed="thisFeed" :is_admin="is_admin" preview="true" :can_show_ad="canShowBoostedFeed(index+1)"></user-feed>
            </div>
            <template v-if="canShowBoostedFeed(index+1) == 'true'">
                <boosted-feed :feed="getBoostedFeed(index+1,boostedFeed)" preview="true"></boosted-feed>
            </template>
            <!-- <google-banner-ad v-if="canShowBoostedFeed(index) == 'true'"></google-banner-ad> -->
        </template>

        <template v-if="user['country']">
            <div v-if="feeds.length == 0 && pendingRequest == false" class="bg-white p-2">
                <h5>No Feeds</h5>
            </div>
        </template>
        <template v-else>
            <div class="bg-white px-2 py-3" v-if="user && !user['country']">
                <h5>Profile Update Needed</h5>
                <a id="feedUpdateprofile" href="javascript:;" uk-toggle="target: #update-user-data" class="button gray mt-3 w-full"> Update Profile </a>
            </div>
        </template>

        <div v-if="pendingRequest == true" class="text-center my-3">
            <i class="fa fa-spin fa-spinner fa-2x"></i>
        </div>
        <template v-if="killInfiniteScroll == 'false'">
            <div v-if="pendingRequest == false" class="flex justify-center mt-6" v-view="callInfiniteScroll" style="visibility: hidden;">
                <a v-if="feeds.length > 0" @click="loadMoreFeeds" class="bg-white dark:bg-gray-900 font-semibold my-3 px-6 py-2 rounded-full shadow-md dark:bg-gray-800 dark:text-white mouse-pointer">
                    Load more ..</a>
            </div>
        </template>
        <edit-feed-post></edit-feed-post>
        <share-post-modal></share-post-modal>
        <report-content></report-content>
        <edit-feed-comment></edit-feed-comment>
        <likers-modal></likers-modal>
        <update-user-data v-if="user && !user['country']"></update-user-data>
    </div>
</template>

<script>
import { ApiService } from '@/services/api.service'
import SharePostModal from '@/include/Modals/SharePostModal'
import UserFeed from '@/include/Dashboard/Feed/UserFeed'
import BoostedFeed from '@/include/Dashboard/Feed/BoostedFeed'
import ProgressLoader from '@/components/ProgressLoader'
import GoogleBannerAd from '@/include/Dashboard/Feed/GoogleBannerAd'
import ShareFeedPost from '@/include/Modals/ShareFeedPost'
import EditFeedPost from '@/include/Modals/EditFeedPost'
import ReportContent from '@/include/Modals/ReportContent'
import EditFeedComment from '@/include/Modals/EditFeedComment'
import LikersModal from '@/include/Modals/LikersModal'
import UpdateUserData from '@/include/Modals/UpdateUserData'
export default {
    name: "Feeds",
    props: {
        section: String
    },
    data() {
        return {
            feeds: [],
            user_new_feeds: [],
            user_pinned_feeds: [],
            admin_pinned_feeds: [],
            boostedFeed: [],
            googleAds: [],
            googleAdsAdminPin: [],
            googleAdsUserPin: [],
            page: 0,
            pendingRequest: false,
            group_id: "",
            page_id: "",
            hash_tag: "",
            loading_feeds: "true",
            is_admin: '',
            killInfiniteScroll: 'false',
            last_page: '',
            user: '',
        };
    },
    components: {
        UserFeed,
        SharePostModal,
        BoostedFeed,
        ProgressLoader,
        GoogleBannerAd,
        ShareFeedPost,
        EditFeedPost,
        ReportContent,
        EditFeedComment,
        LikersModal,
        UpdateUserData,
    },
    mounted () {
        let id = this.$route.params['id']
        if (location.href.search("/page/") > -1) {
            this.page_id = id
        }
        if (location.href.search("/group/") > -1) {
            this.group_id = id
        }
        if (location.href.search("/feed-hash-tags/") > -1) {
            this.hash_tag = id
        }
        this.getMe()
        this.$root.$on("refresh-feeds",() => {
            // console.log("refresh-feeds")
            location.href = ''
        })
        setTimeout(() => {
            this.getBoostedFeeds()
        }, 5000);
        setTimeout(() => {
            this.getBoostedFeeds()
        }, 10000);
    },
    methods: {
        async getFeeds(){
            if(this.pendingRequest == true){
                // console.log('Pending Req')
                return
            }
            this.pendingRequest = true
            console.log('====',location.href.search('/feed-hash-tags/'),this.hash_tag, this.page_id, this.group_id)
            let apiResponse = ''
            if(location.href.search('/feed-hash-tags/') > -1){
                apiResponse = await ApiService.GetFeedsWithHashTags(this.page, this.hash_tag)
            }else{
                apiResponse = await ApiService.GetFeeds(this.page, this.section, this.group_id, this.page_id)
            }
            if(apiResponse['status'] != 'success'){
            }else{
                this.feeds = []
                this.feeds = apiResponse['data']
                if(this.page == 0){
                    this.feeds = apiResponse['data']
                    this.user_pinned_feeds = apiResponse['user_pinned_feeds']
                    this.user_new_feeds = apiResponse['user_new_feeds']
                    this.admin_pinned_feeds = apiResponse['admin_pinned_feeds']
                }
                this.page++

                // console.log(this.feeds)
            }
            this.pendingRequest = false
        },
        async loadMoreFeeds(){
            if(!this.user['country']){
                // console.log('Pending Req')
                return
            }
            if(this.pendingRequest == true){
                // console.log('Pending Req')
                return
            }
            if(this.last_page && this.page > this.last_page){
                console.log('Last page')
                return;
            }
            this.pendingRequest = true
            this.getBoostedFeeds()
            let apiResponse = ''
            if(location.href.search('/feed-hash-tags/') > -1){
                apiResponse = await ApiService.GetFeedsWithHashTags(this.page, this.hash_tag)
            }else{
                apiResponse = await ApiService.GetFeeds(this.page, this.section, this.group_id, this.page_id)
            }
            if(apiResponse['status'] != 'success'){
            }else{
                if(apiResponse['data'].length == 0){
                    this.killInfiniteScroll = 'true'
                }else{
                    this.page++
                    this.feeds = this.feeds.concat(apiResponse['data'])
                    this.last_page = apiResponse['pagination']['last_page']
                    // console.log(this.feeds)
                }
            }
            this.pendingRequest = false
        },
        async getBoostedFeeds(){
            let apiResponse = await ApiService.BoostedFeed()
            if(apiResponse['status'] != 'success'){
            }else{
                this.boostedFeed = this.boostedFeed.concat(apiResponse['data'])
            }
        },
        async getMe(){
            let apiResponse = await ApiService.GetMe()
            if(apiResponse['status'] != 'success'){
            }else{
                let user = apiResponse['data']['user']
                this.user = user
                this.is_admin = apiResponse['data']['is_admin']
                if(user['country']){
                    this.getFeeds()
                }else{
                    // profile update
                    setTimeout(() => {
                        UIkit.modal('#update-user-data').toggle()
                    }, 1200);
                }
            }
        },
        callInfiniteScroll(){
            window.onscroll = () => {
                if(this.killInfiniteScroll == 'false'){
                    let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight > document.documentElement.offsetHeight-100;
                    if (bottomOfWindow) {
                        this.loadMoreFeeds()
                    }
                }
            }
        },
    }
};
</script>

<style lang="scss" scoped></style>
