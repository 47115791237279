<template>
<!-- birthdays modal -->
<div id="birthdays" uk-modal="">
    <div class="uk-modal-dialog uk-modal-body rounded-xl shadow-lg">
        <!-- close button -->
        <button class="uk-modal-close-default p-2.5 bg-gray-100 rounded-full m-3" type="button" uk-close=""></button>

        <div class="flex items-center space-x-3 mb-10">
            <i name="gift" class="text-yellow-500 text-xl bg-yellow-50 p-1 rounded-md"></i>
            <div class="text-xl font-semibold">Today's birthdays</div>
        </div>

        <div class="space-y-6">
            <div class="sm:space-y-8 space-y-6 pb-2">
                <div v-for="bd in todays_birthdays" class="flex items-center sm:space-x-6 space-x-3">
                    <img v-if="bd['profile_photo']" :src="bd['profile_photo']" alt="" class="sm:w-16 sm:h-16 w-14 h-14 rounded-full" />
                    <img v-else :src="defaultPostUrl()" alt="" class="sm:w-16 sm:h-16 w-14 h-14 rounded-full" />
                    <div class="flex-1">
                        <div class="flex items-center justify-between mb-3">
                            <div class="text-base font-semibold">
                                <a :href="'/dashboard/timeline/'+bd.id"> {{ bd['name'] }} </a>
                            </div>
                            <div class="font-medium text-sm text-gray-400">
                                {{ age(bd['dob']) }} years old
                            </div>
                        </div>
                        <div class="relative">
                            <!-- <input type="text" name="" id="" class="with-border" placeholder="Write her on Timeline" />
                            <i name="happy" class="absolute right-3 text-2xl top-1/4"></i> -->
                        </div>
                    </div>
                </div>
                <!-- <div class="flex items-center sm:space-x-6 space-x-3">
                    <img src="/assets/images/avatars/avatar-2.jpg" alt="" class="sm:w-16 sm:h-16 w-14 h-14 rounded-full" />
                    <div class="flex-1">
                        <div class="flex items-center justify-between mb-3">
                            <div class="text-base font-semibold">
                                <a href="#"> Stella Johnson </a>
                            </div>
                            <div class="font-medium text-sm text-gray-400">
                                19 years old
                            </div>
                        </div>
                        <div class="relative">
                            <input type="text" name="" id="" class="with-border" placeholder="Write her on Timeline" />
                            <i name="happy" class="absolute right-3 text-2xl top-1/4"></i>
                        </div>
                    </div>
                </div> -->
            </div>
            <div class="relative cursor-pointer" uk-toggle="target: #upcoming; animation: uk-animation-fade">
                <div class="bg-gray-50 rounded-lg px-5 py-4 font-semibold text-base">
                    Upcoming birthdays
                </div>
                <i class="-translate-y-1/2 absolute icon-feather-chevron-up right-4 text-xl top-1/2 transform text-gray-400" id="upcoming" hidden=""></i>
                <i class="-translate-y-1/2 absolute icon-feather-chevron-down right-4 text-xl top-1/2 transform text-gray-400" id="upcoming"></i>
            </div>
            <div class="mt-5 sm:space-y-8 space-y-6" id="upcoming" hidden="">
                <div v-for="bd in rest_of_month_birthdays" class="flex items-center sm:space-x-6 space-x-3">
                    <img v-if="bd['profile_photo']" :src="bd['profile_photo']" alt="" class="sm:w-16 sm:h-16 w-14 h-14 rounded-full" />
                    <img v-else :src="defaultPostUrl()" alt="" class="sm:w-16 sm:h-16 w-14 h-14 rounded-full" />
                    <div class="flex-1">
                        <div class="flex items-center justify-between mb-3">
                            <div class="text-base font-semibold">
                                <a :href="'/dashboard/timeline/'+bd.id"> {{ bd['name'] }} </a>
                            </div>
                            <div class="font-medium text-sm text-gray-400">
                                {{ age(bd['dob']) }} years old
                            </div>
                        </div>
                        <div class="relative">
                            <!-- <input type="text" name="" id="" class="with-border" placeholder="Write her on Timeline" />
                            <i name="happy" class="absolute right-3 text-2xl top-1/4"></i> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    ApiService
} from '@/services/api.service'
export default {
    name: "BirthdayModal",
    data() {
        return {
            currentYear: new Date().getFullYear(),
            appName: "-",
            todays_birthdays: '',
            rest_of_month_birthdays: '',
        };
    },
    mounted: function () {
        this.appName = this.$APP_NAME;
    },
    mounted() {
        this.currentUser = this.$store.getters["user/currentUser"] || '';
        this.getBirthdays()
    },
    methods: {
        async getBirthdays() {
            this.birthdayLoading = true
            let apiResponse = await ApiService.GetBirthdays();
            if (apiResponse['status'] == 'success') {
                this.todays_birthdays = apiResponse['data']['todays_birthdays']
                this.rest_of_month_birthdays = apiResponse['data']['rest_of_month_birthdays']
            }else{
                this.$store.commit("toast/Notify", {
                    type: apiResponse['status'],
                    message: apiResponse['message'],
                });
            }
            this.birthdayLoading = false
        },
    }
};
</script>

<style lang="scss" scoped></style>
