<template>
  <div id="wrapper" class="flex flex-col justify-between h-screen">
      <auth-styles pageNameMain="Login"></auth-styles>
      <auth-header></auth-header>

      <!-- Content-->
      <div>
          <div class="lg:p-12 max-w-xl lg:my-0 my-12 mx-auto p-6 space-y-">
              
              <form class="lg:p-10 p-6 space-y-3 relative bg-white shadow-xl rounded-md text-center">
                  <h1 class="lg:text-2xl text-xl font-semibold mb-6"> Registration successful </h1>
                  <div>
                    <p>
                        Your registration was successful, An account activation email has been sent to you
                    </p>
                  </div>
              </form>

          </div>
      </div>

      <auth-scripts></auth-scripts>

  </div>
</template>

<script>
import { ApiService } from '@/services/api.service'
import AuthStyles from '../../include/Auth/AuthStyles'
import AuthScripts from '../../include/Auth/AuthScripts'
import AuthHeader from '@/include/Auth/AuthHeader'

export default {
  name: 'RegisterSuccess',
  data () {
    return {
      error: ''
    }
  },
  components: {
    AuthStyles,
    AuthScripts,
    AuthHeader
  },
  methods: {
    async makeLogin () {
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
