import moment from "moment";
import { ApiService } from "../services/api.service";

export default {
    data() {
        return {
            delay: 700,
            clicks: 0,
            timer: null
        };
    },
    methods: {
        defaultImageUrl: function() {
            return '/assets/img/def-img.png'
        },
        defaultPostUrl: function() {
            return '/assets/img/def-img.png'
        },
        logoUrl: function() {
            return '/assets/img/vt_social_logo.png'
        },
        vitiLogoIcon: function() {
            return '/assets/img/vt_social_icon.png'
        },
        timeAgo: function(d) {

            if (!d) {
                return ''
            }
            let date = Date.parse(d)

            var seconds = Math.floor((new Date() - date) / 1000);

            var interval = seconds / 31536000;
            let floorVal = ''

            if (interval > 1) {
                return Math.floor(interval) + " y";
            }
            interval = seconds / 2592000;
            if (interval > 1) {
                return Math.floor(interval) + " m";
            }
            interval = seconds / 86400;
            if (interval > 1) {
                return Math.floor(interval) + " d";
            }
            interval = seconds / 3600;
            if (interval > 1) {
                floorVal = Math.floor(interval)
                if (floorVal == 1) {
                    floorVal = floorVal + ' Hr'
                } else {
                    floorVal = floorVal + " Hrs"
                }
                return floorVal;
            }
            interval = seconds / 60;
            if (interval > 1) {
                floorVal = Math.floor(interval)
                if (floorVal == 1) {
                    floorVal = floorVal + ' Min'
                } else {
                    floorVal = floorVal + " Mins"
                }
                return floorVal;
            }
            floorVal = Math.floor(seconds)
            if (floorVal == 1) {
                floorVal = floorVal + ' Sec'
            } else {
                floorVal = floorVal + " Secs"
            }
            return floorVal;
        },
        feedTimeManager(a) {
            let b = this.timeAgo(a)
            if (b.search('d') > -1 || b.search('m') > -1 || b.search('y') > -1) {
                b = this.formatDateTimeNice(a)
            } else {
                b = b + ' ago'
            }
            return b
        },
        unixToUtc: function(a) {
            let b = a
            if (b) {
                b = moment.unix(b / 1000).format("DD-MM-YYYY HH:mm:ss");
            }
            return b
        },
        daysLater: function(d) {

            if (!d) {
                return ''
            }
            let todaysDate = new Date()
            let date = new Date(d)

            let todaysDateDay = todaysDate.getDate()
            let dobDateDay = date.getDate()

            let daysLeft = dobDateDay - todaysDateDay

            return daysLeft
        },
        ageCalculator: function(d) {

            if (!d) {
                return ''
            }
            let date = Date.parse(d)

            var seconds = Math.floor((new Date() - date) / 1000);

            var interval = seconds / 31536000;

            return Math.floor(interval);
        },
        stripTags: function(a) {
            let b = a.replace(/(<([^>]+)>)/gi, "");
            return b
        },
        blogMinify: function(a, length) {
            let b = a
            if (a.length > length) {
                b = a.substr(0, length)
            }
            return b
        },
        textMinify: function(a, length) {
            let b = a
            if (a && a.length > length) {
                b = a.substr(0, length) + '...'
            }
            return b
        },
        blogPullImage: function(a) {
            let img = '<img src="/assets/img/vt-logo.jpg" alt="" class="w-full h-full absolute inset-0 object-cover" />'
            let b = a.split("<p>")
            let shouldbreak = 'false'
            for (let i = 0; i < b.length; i++) {
                const c = b[i];
                if (!c) {
                    continue
                }
                let d = c.split("</p>")
                for (let j = 0; j < d.length; j++) {
                    const element = d[j];
                    if (!element) {
                        continue
                    }
                    if (element.search("<") > -1) {
                        if (element.search("<img") > -1) {
                            img = element
                            shouldbreak = 'true'
                        }
                    }
                    if (shouldbreak == 'true') {
                        break
                    }
                }
                if (shouldbreak == 'true') {
                    break
                }
            }
            return img
        },
        blogSubtitle: function(a, length) {
            let subTitle = ''
            let b = a.split("<p>")
            let shouldbreak = 'false'
            for (let i = 0; i < b.length; i++) {
                const c = b[i];
                if (!c) {
                    continue
                }
                let d = c.split("</p>")
                for (let j = 0; j < d.length; j++) {
                    const element = d[j];
                    if (!element) {
                        continue
                    }
                    if (element.search("<") > -1) {
                        continue
                    } else {
                        subTitle = this.blogMinify(element, length)
                        shouldbreak = 'true'
                    }
                    if (shouldbreak == 'true') {
                        break
                    }
                }
                if (shouldbreak == 'true') {
                    break
                }
            }
            return subTitle
        },
        lastThreeUserComment: function(a) {
            let divElement = ''
            let userId = ''
            let uniqueUsers = 0
            if (a.length > 0) {
                for (let i = 0; i < a.length; i++) {
                    const element = a[i];
                    if (uniqueUsers > 2) {
                        break
                    }
                    if (element['user']) {
                        if (userId.search(element['user']['id']) > -1) {
                            continue
                        } else {
                            let pp = element['user']['profile_photo']
                            if (pp) {
                                divElement = divElement + '<img alt="Image placeholder" src="' + pp + '" class="border-2 border-white rounded-full w-7 h-7" />'
                            } else {
                                divElement = divElement + '<img alt="Image placeholder" src="/assets/img/avatar.png" class="border-2 border-white rounded-full w-7 h-7" />'
                            }
                            userId = userId + ',' + element['user']['id']
                            uniqueUsers++
                        }
                    }
                }
            }
            return divElement
        },
        lastThreeUserCommentForum: function(a) {
            let divElement = ''
            let userId = ''
            let uniqueUsers = 0
            if (a.length > 0) {
                for (let i = 0; i < a.length; i++) {
                    const element = a[i];
                    if (uniqueUsers > 2) {
                        break
                    }
                    if (element['user']) {
                        if (userId.search(element['user']['id']) > -1) {
                            continue
                        } else {
                            let pp = element['user']['profile_photo']
                            let username = element['user']['username']
                            if (pp) {
                                divElement = divElement + '<img src="' + pp + '" alt="" class="w-10 h-10 rounded-full border-2 border-white" data-tippy-placement="top" title="' + username + '" />'
                            } else {
                                divElement = divElement + '<img src="/assets/img/avatar.png" alt="" class="w-10 h-10 rounded-full border-2 border-white" data-tippy-placement="top" title="' + username + '" />'
                            }
                            userId = userId + ',' + element['user']['id']
                            uniqueUsers++
                        }
                    }
                }
            }
            return divElement
        },
        userReactionsIcon: function(a, b, c = 'normal') {
            let divElement = ''
            let imgSize = 'icon_image'
            if (c == 'small') {
                imgSize = 'icon_image_min'
            } else if (c == 'big') {
                imgSize = 'icon_image_max'
            }
            if (b == 'feed') {
                if (a['has_like']) {
                    divElement = divElement + ' <img class="icon_image" src="/assets/img/reaction/png/like.png" />'
                }
                if (a['has_love']) {
                    divElement = divElement + ' <img class="icon_image" src="/assets/img/reaction/png/love.png" />'
                }
                if (a['has_care']) {
                    divElement = divElement + ' <img class="icon_image" src="/assets/img/reaction/png/care.png" />'
                }
                if (a['has_laugh']) {
                    divElement = divElement + ' <img class="icon_image" src="/assets/img/reaction/png/haha.png" />'
                }
                if (a['has_surprise']) {
                    divElement = divElement + ' <img class="icon_image" src="/assets/img/reaction/png/wow.png" />'
                }
                if (a['has_sad']) {
                    divElement = divElement + ' <img class="icon_image" src="/assets/img/reaction/png/sad.png" />'
                }
                if (a['has_angry']) {
                    divElement = divElement + ' <img class="icon_image" src="/assets/img/reaction/png/angry.png" />'
                }
            } else if (b == 'feed_reaction') {
                if (a['user_has_like'] > 0) {
                    divElement = '<img class="' + imgSize + '" src="/assets/img/reaction/png/like.png" /> <b class="py-1 mt-0 ml-2">Like</b>'
                }
                if (a['user_has_love'] > 0) {
                    divElement = '<img class="' + imgSize + '" src="/assets/img/reaction/png/love.png" /> <b class="py-1 mt-0 ml-2">Love</b>'
                }
                if (a['user_has_care'] > 0) {
                    divElement = '<img class="' + imgSize + '" src="/assets/img/reaction/png/care.png" /> <b class="py-1 mt-0 ml-2">Care</b>'
                }
                if (a['user_has_laugh'] > 0) {
                    divElement = '<img class="' + imgSize + '" src="/assets/img/reaction/png/haha.png" /> <b class="py-1 mt-0 ml-2">Haha</b>'
                }
                if (a['user_has_surprise'] > 0) {
                    divElement = '<img class="' + imgSize + '" src="/assets/img/reaction/png/wow.png" /> <b class="py-1 mt-0 ml-2">Wow</b>'
                }
                if (a['user_has_sad'] > 0) {
                    divElement = '<img class="' + imgSize + '" src="/assets/img/reaction/png/sad.png" /> <b class="py-1 mt-0 ml-2">Sad</b>'
                }
                if (a['user_has_angry'] > 0) {
                    divElement = '<img class="' + imgSize + '" src="/assets/img/reaction/png/angry.png" /> <b class="py-1 mt-0 ml-2">Angry</b>'
                }
            }
            // return divElement + ' <span class="ml-1 mr-2">' + a.likes + '</span>'
            return divElement + ' <span class="ml-1 mr-0"></span>'
        },
        getOddEven: function(a) {
            let b = 'odd'
            if (a) {
                let c = parseInt(a / 10)
                let d = c % 2
                if (d == 0) {
                    b = 'even'
                }
            }
            return b
        },
        currencySymbol: function(a) {
            let b = ''
            switch (a.toUpperCase()) {
                case 'USD':
                    b = '$'
                    break;

                case 'NGN':
                    b = '₦'
                    break;

                case 'EUR':
                    b = '€'
                    break;

                case 'POUNDS':
                    b = '£'
                    break;

                case 'CEDI':
                    b = 'GH₵'
                    break;

                case 'SHILLING':
                    b = 'Ksh'
                    break;

                default:
                    break;
            }

            return b
        },
        getCartSubTotal: function(a, format = true) {
            let b = 0
            for (let i = 0; i < a.length; i++) {
                const thisItem = a[i];

                if (thisItem.amount) {
                    b = b + parseFloat(thisItem['amount'] * thisItem['item_number'])
                }
            }

            if (format == false) {
                return b
            }

            return this.formatAmount(b)
        },
        getCartTotal: function(a, format = true) {
            let b = this.getCartSubTotal(a, false)
            if (b > 0) {
                b = parseFloat(b) + 10
            }

            if (format == false) {
                return b
            }

            return this.formatAmount(b)
        },
        formatDate: function(a) {
            if (a) {
                return moment(String(a)).format('Do, MMMM YYYY')
            }
        },
        formatDateTimeNice: function(a) {
            if (a) {
                return moment(String(a)).format('Do, MMMM YYYY HH:mm A')
            }
        },
        formatDateTimeNiceShort: function(a) {
            if (a) {
                return moment(String(a)).format('D-M-YYYY HH:mm')
            }
        },
        formatDateTime: function(a) {
            if (a) {
                return moment(String(a)).format('dddd, d MMMM YYYY')
            }
        },
        formatAmount: function(a) {
            if (a) {
                a = Number(a).toLocaleString('en-US')
            }
            return a
        },
        formatDateForum: function(a) {
            if (a) {
                return moment(String(a)).format('MMM Do, YYYY')
            }
        },
        formatDateTimeToFuture: function(date, toAdd, numberToAdd) {
            if (date) {
                return moment(date).add(numberToAdd, toAdd).format('dddd, Do MMMM YYYY hh:mm:ss');
            }
        },
        shortenNumber(a) {
            let b = a
            if (a) {
                if (Math.abs(a) > 999) {
                    b = Math.sign(a) * ((Math.abs(a) / 1000).toFixed(1)) + 'k'
                } else if (Math.abs(a) > 999999) {
                    b = Math.sign(a) * ((Math.abs(a) / 1000).toFixed(1)) + 'm'
                } else if (Math.abs(a) > 999999999) {
                    b = Math.sign(a) * ((Math.abs(a) / 1000).toFixed(1)) + 'b'
                }
            }
            return b
        },
        pullDobData: function(a) {
            let day = '',
                month = '',
                year = '',
                d
            if (a) {
                console.log('a', a, String(a))
                d = moment(String(a), ['YYYY-MM-DD', 'YYYY/MM/DD', 'YYYY-DD-MM', 'YYYY/DD/MM', 'MM-DD-YYYY', 'MM/DD/YYYY', 'DD-MM-YYYY', 'DD/MM/YYYY'])
                day = d.format('DD').toString()
                month = d.format('MM').toString()
                year = d.format('YYYY').toString()
            }
            console.log('::::', day, month, year, d)

            return {
                day,
                month,
                year
            }
        },
        scrollToCommentBox(id) {
            $("#" + id).show()
            this.focusTextarea("#" + id)
        },
        toggleReplyForm(id) {
            // $("#" + id).toggle()
            let a = $("#" + id).css('display')
            if (a == 'none') {
                // hide all other sections
                this.hideAllSections()
                $("#" + id).show()
                if ($("#" + id + ' input').length > 0) {
                    this.focusInput("#" + id)
                }
                if ($("#" + id + ' textarea').length > 0) {
                    this.focusTextarea("#" + id)
                }
                // document.getElementById(id).scrollIntoView();
            } else {
                $("#" + id).hide()
            }
        },
        toggleProgress(a) {
            if (a == 'show') {
                $("#progressLoader").show()
                $("button").attr('disabled', true)
            } else if (a == 'hide') {
                $("#progressLoader").hide()
                $("button").attr('disabled', false)
            } else {
                $("#progressLoader").toggle()
            }
        },
        toggleCommentSection() {
            let a = $(".commentSection").css('display')
            console.log('---', a)
            if (a == 'none') {
                // hide all other sections
                this.hideAllSections()
                $(".commentSection").show()
            } else {
                $(".commentSection").hide()
            }
        },
        hideAllSections() {
            console.log(';:::::::::', 'hideAllSections')
            $(".commentSection").hide()
            $(".replySection").hide()
            $(".replyReplySection").hide()
        },
        countdownData(a) {},
        videoDuration(a) {
            let b = '0:00'
            if (!a || a == 0) {
                return b
            }
            a = parseInt(a)
            if (a > 59) {
                let c = parseInt(a / 60)
                let secs = a % 60
                let mins = 0
                let hrs = 0
                if (c > 59) {
                    hrs = c / 60
                    mins = c % 60
                    b = hrs + ':' + mins + ':' + secs
                } else {
                    mins = c
                    b = mins + ':' + secs
                }
            } else {
                b = '0:' + a.toString()
            }
            console.log('b', b)
            return b
        },
        pullJobtags(t) {
            let a = ''
            if (t) {
                let b = JSON.parse(t)
                for (let i = 0; i < b.length; i++) {
                    const c = b[i];
                    if (c) {
                        a = a + '<a class="border px-3 py-1.5 rounded-md text-sm inline-block"> ' + c + ' </a>'
                    }
                }
            }
            return a;
        },
        otherUserID(c, currentShop, type) {
            let a = '';
            let currentUser = this.$store.getters["user/currentUser"];
            if (type == 'shop') {
                if (c && c.sender_type == 'shop' && currentShop && c.sender_id == currentShop['id']) {
                    a = c.receiver_id
                } else if (c && c.receiver_type == 'shop' && currentShop && c.receiver_id == currentShop['id']) {
                    a = c.sender_id
                }

            }
            if (type == 'user') {
                if (c && c.sender_type == 'user' && c.sender_id == currentUser['id']) {
                    a = c.receiver_id
                } else if (c && c.receiver_type == 'user' && c.receiver_id == currentUser['id']) {
                    a = c.sender_id
                }
            }

            if (!a) {
                if (c && currentShop && c.sender_id == currentShop['id']) {
                    a = c.receiver_id
                } else if (c && currentShop && c.receiver_id == currentShop['id']) {
                    a = c.sender_id
                } else if (c && c.sender_id == currentUser['id']) {
                    a = c.receiver_id
                } else if (c && c.receiver_id == currentUser['id']) {
                    a = c.sender_id
                } else if (c && currentShop && c.sender_id == currentShop['id']) {
                    a = c.receiver_id
                } else if (c && currentShop && c.receiver_id == currentShop['id']) {
                    a = c.sender_id
                }
            }

            return a
        },
        otherUserProfilePhoto(c, currentShop, type) {
            let a = '';
            let currentUser = this.$store.getters["user/currentUser"];
            if (type == 'shop') {
                if (c.sender_type == 'shop' && currentShop && c.sender_id == currentShop['id'] && c.receiver_shop) {
                    a = c.receiver_shop.profile_photo
                    if (c.shop_id && c.shop_owner == 'receiver') {
                        if (c.shop) {
                            a = c.shop.image
                        }
                    }
                } else if (c.receiver_type == 'shop' && currentShop && c.receiver_id == currentShop['id'] && c.sender_shop) {
                    a = c.sender_shop.profile_photo
                    if (c.shop_id && c.shop_owner == 'sender') {
                        if (c.shop) {
                            a = c.shop.image
                        }
                    }
                }

            }
            if (type == 'user') {
                if (c.sender_type == 'user' && c.sender_id == currentUser['id'] && c.receiver) {
                    a = c.receiver.profile_photo
                    if (c.shop_id && c.shop_owner == 'receiver') {
                        if (c.shop) {
                            a = c.shop.image
                        }
                    }
                } else if (c.receiver_type == 'user' && c.receiver_id == currentUser['id'] && c.sender) {
                    a = c.sender.profile_photo
                    if (c.shop_id && c.shop_owner == 'sender') {
                        if (c.shop) {
                            a = c.shop.image
                        }
                    }
                }
            }
            if (!a) {
                if (c && currentShop && c.sender_id == currentShop['id'] && c.receiver_shop) {
                    a = c.receiver_shop.profile_photo
                } else if (c && currentShop && c.receiver_id == currentShop['id'] && c.sender_shop) {
                    a = c.sender_shop.profile_photo
                } else if (c && c.sender_id == currentUser['id'] && c.receiver) {
                    a = c.receiver.profile_photo
                } else if (c && c.receiver_id == currentUser['id'] && c.sender) {
                    a = c.sender.profile_photo
                } else if (c && currentShop && c.sender_id == currentShop['id'] && c.receiver_shop) {
                    a = c.receiver_shop.profile_photo
                } else if (c && currentShop && c.receiver_id == currentShop['id'] && c.sender_shop) {
                    a = c.sender_shop.profile_photo
                }
            }
            if (!a) {
                a = this.defaultImageUrl()
            }

            return a
        },
        otherUserOnline(c, currentShop, type) {
            let a = '';
            let currentUser = this.$store.getters["user/currentUser"];
            if (type == 'shop') {
                if (c.sender_type == 'shop' && currentShop && c.sender_id == currentShop['id']) {
                    if (c.receiver) {
                        a = c.receiver.online
                    }
                } else if (c.receiver_type == 'shop' && currentShop && c.receiver_id == currentShop['id']) {
                    if (c.receiver) {
                        a = c.sender.online
                    }
                }
                type = 'user'

            }
            if (type == 'user') {
                if (c.sender_type == 'user' && c.sender_id == currentUser['id']) {
                    if (c.receiver) {
                        a = c.receiver.online
                    }
                } else if (c.receiver_type == 'user' && c.receiver_id == currentUser['id']) {
                    if (c.sender) {
                        a = c.sender.online
                    }
                }
            }

            if (!a) {
                if (c && c.receiver && currentShop && c.sender_id == currentShop['id']) {
                    a = c.receiver.online
                } else if (c && c.sender && currentShop && c.receiver_id == currentShop['id']) {
                    a = c.sender.online
                } else if (c && c.receiver && c.sender_id == currentUser['id']) {
                    a = c.receiver.online
                } else if (c && c.sender && c.receiver_id == currentUser['id']) {
                    a = c.sender.online
                } else if (c && c.receiver_shop && c.sender_id == currentUser['id']) {
                    a = c.receiver_shop.online
                } else if (c && c.sender_shop && c.receiver_id == currentUser['id']) {
                    a = c.sender_shop.online
                } else if (c && currentShop && c.receiver_shop && c.sender_id == currentShop['id']) {
                    a = c.receiver_shop.online
                } else if (c && currentShop && c.sender_shop && c.receiver_id == currentShop['id']) {
                    a = c.sender_shop.online
                }
                // console.log(a, c, currentShop, type, 'otherUserOnline', currentUser)
            }

            return a
        },
        userProfilePhoto(c, type) {
            let a = '';
            let currentUser = this.$store.getters["user/currentUser"];
            if (type == 'shop') {
                if (c.sender_type == 'shop' && c.sender_id == currentShop['id'] && c.sender_shop) {
                    a = c.sender_shop.profile_photo
                    if (c.shop_id && c.shop_owner == 'sender') {
                        if (c.shop) {
                            a = c.shop.image
                        }
                    }
                } else if (c.receiver_type == 'shop' && c.receiver_id == currentShop['id'] && c.receiver_shop) {
                    a = c.receiver_shop.profile_photo
                    if (c.shop_id && c.shop_owner == 'receiver') {
                        if (c.shop) {
                            a = c.shop.image
                        }
                    }
                }
                type = 'user'
            }
            if (type == 'user') {
                if (c.sender_type == 'user' && c.sender_id == currentUser['id'] && c.sender) {
                    a = c.sender.profile_photo
                    if (c.shop_id && c.shop_owner == 'sender') {
                        if (c.shop) {
                            a = c.shop.image
                        }
                    }
                } else if (c.receiver_type == 'user' && c.receiver_id == currentUser['id'] && c.receiver) {
                    a = c.receiver.profile_photo
                    if (c.shop_id && c.shop_owner == 'receiver') {
                        if (c.shop) {
                            a = c.shop.image
                        }
                    }
                }
            }
            if (!a) {
                a = this.defaultImageUrl()
            }

            return a
        },
        otherUserName(c, currentShop, type) {
            let a = '';
            let currentUser = this.$store.getters["user/currentUser"];
            if (type == 'shop') {
                if (c.sender_type == 'shop' && currentShop && c.sender_id == currentShop['id']) {
                    a = c.receiver_name
                } else if (c.receiver_type == 'shop' && currentShop && c.receiver_id == currentShop['id']) {
                    a = c.sender_name
                }
                type = 'user'
            }
            if (type == 'user') {
                if (c.sender_type == 'user' && c.sender_id == currentUser['id']) {
                    a = c.receiver_name
                    if (c.shop_owner == 'receiver') {
                        if (c.shop) {
                            a = c.shop.name
                        }
                    }
                } else if (c.receiver_type == 'user' && c.receiver_id == currentUser['id']) {
                    a = c.sender_name
                    if (c.shop_owner == 'sender') {
                        if (c.shop) {
                            a = c.shop.name
                        }
                    }
                }
            }

            return a
        },
        otherChatUserID(f) {
            let a = '';
            let currentUser = this.$store.getters["user/currentUser"];
            if (f.user) {
                if (f.user.id != currentUser.id) {
                    a = f.user.id
                }
            }
            if (f.follower) {
                if (f.follower.id != currentUser.id) {
                    a = f.follower.id
                }
            }

            return a
        },
        otherChatUserPhoto(f) {
            let a = '';
            let currentUser = this.$store.getters["user/currentUser"];
            if (f.user) {
                if (f.user.id != currentUser.id) {
                    a = f.user.profile_photo
                }
            }
            if (f.follower) {
                if (f.follower.id != currentUser.id) {
                    a = f.follower.profile_photo
                }
            }
            if (!a) {
                a = this.defaultImageUrl()
            }

            return a
        },
        otherChatUserName(f) {
            let a = '';
            let currentUser = this.$store.getters["user/currentUser"];
            if (f.user) {
                if (f.user.id != currentUser.id) {
                    a = f.user.name
                }
            }
            if (f.follower) {
                if (f.follower.id != currentUser.id) {
                    a = f.follower.name
                }
            }

            return a
        },
        otherChatUserOnline(f) {
            let a = '';
            let currentUser = this.$store.getters["user/currentUser"];
            if (f.user) {
                if (f.user.id != currentUser.id) {
                    a = f.user.online
                }
            }
            if (f.follower) {
                if (f.follower.id != currentUser.id) {
                    a = f.follower.online
                }
            }

            return a
        },
        otherChatUserVerified(f) {
            let a = '';
            let currentUser = this.$store.getters["user/currentUser"];
            if (f.user) {
                if (f.user.id != currentUser.id) {
                    a = f.user.verified
                }
            }
            if (f.follower) {
                if (f.follower.id != currentUser.id) {
                    a = f.follower.verified
                }
            }

            return a
        },
        chatMessageUnseen(c, currentShop, type, section) {
            let a = '',
                b = '';
            let currentUser = this.$store.getters["user/currentUser"];
            if (type == 'shop') {
                if (c.sender_type == 'shop' && currentShop && c.sender_id == currentShop['id']) {
                    a = 'sender_unseen'
                } else if (c.receiver_type == 'shop' && currentShop && c.receiver_id == currentShop['id']) {
                    a = 'receiver_unseen'
                }
            }
            if (type == 'user') {
                if (c.sender_type == 'user' && c.sender_id == currentUser['id']) {
                    a = 'sender_unseen'
                } else if (c.receiver_type == 'user' && c.receiver_id == currentUser['id']) {
                    a = 'receiver_unseen'
                }
            }

            if (c[a] == 'true') {
                if (section == 'header') {
                    b = 'un-read'
                }
            }
            // console.log('chatMessageUnseen', a, c[a], b, section, type, c)

            return b
        },
        openReplyChat() {
            let clicks = this.clicks + 1;
            console.log('::::', clicks)
            if (clicks === 2) {
                this.timer = setTimeout(() => {
                    UIkit.modal('#reply-chat-modal').toggle();
                    this.clicks = 0
                }, this.delay);
            } else {
                // clearTimeout(this.timer);
                // this.clicks = 0;
            }
        },
        unseenStoryItems(userID, items) {
            let unseen = 0
            for (let i = 0; i < items.length; i++) {
                const thisItem = items[i];

                // console.log('unseenStoryItems', thisItem['seen_by'].search('' + userID + ''), userID, thisItem['seen_by'])
                if (thisItem['seen_by'].search('' + userID + '') > -1) {
                    // seen
                } else {
                    unseen++
                }
            }
            return unseen
        },
        feedImageSize(totalImages, index) {
            let thisClass = 'col-sm-12 col-xs-12'
            if (index > 0) {
                let divider = index % 5
                if (divider == 1) {
                    thisClass = 'col-sm-12 col-xs-12'
                }
                if (divider == 2) {
                    thisClass = 'col-sm-6 col-xs-6'
                }
                if (divider == 3) {
                    thisClass = 'col-sm-12 col-xs-12'
                }
                if (divider == 4) {
                    thisClass = 'col-sm-12 col-xs-12'
                }
            }
            // if (totalImages == 2) {
            //     thisClass = 'col-sm-6 col-xs-6'
            // }
            // if (totalImages == 3) {
            //     thisClass = 'col-sm-4 col-xs-4'
            // }
            // if (totalImages == 4) {
            //     thisClass = 'col-sm-6 col-xs-6'
            // }
            // if (totalImages >= 5) {
            //     thisClass = 'd-none'
            //     if (index == 1 || index == 2 || index == 3) {
            //         thisClass = 'col-sm-4 col-xs-4'
            //     }
            //     if (index == 4 || index == 5) {
            //         thisClass = 'col-sm-6 col-xs-6'
            //     }
            // }
            return thisClass
        },
        canShowBoostedFeed(index) {
            let canShow = 'false'
            index++
            if (index > 0 && index % 3 == 0) {
                canShow = 'true'
            }
            return canShow
        },
        getBoostedFeed(index, boostedFeeds) {
            let boostedFeed = {}
            let boostedFeedIndex = 0
            if (boostedFeeds && index >= 0 && boostedFeeds.length > 0) {
                boostedFeedIndex = parseInt(index / 3)

                if (boostedFeeds[boostedFeedIndex]) {
                    boostedFeed = boostedFeeds[boostedFeedIndex]
                }
            }
            return boostedFeed
        },
        focusInput(a) {
            console.log('aaa', a)
            $('html, body').animate({
                    scrollTop: $(a + ' input').offset().top - 300
                },
                1000,
                function() {
                    $(a + ' input').focus();
                });
        },
        focusTextarea(a) {
            console.log('aaa', a)
            $('html, body').animate({
                    scrollTop: $(a + ' textarea').offset().top - 300
                },
                1000,
                function() {
                    $(a + ' textarea').focus();
                });
        },
        scrollToContent(a) {
            try {
                $('html, body').animate({
                        scrollTop: $(a).offset().top - 300
                    },
                    1000,
                    function() {
                        $(a).focus();
                    });

            } catch (error) {
                console.log('E scrollToContent: ', error, a)
            }
        },
        openLink(link) {
            location.href = link
        },
        openLinkInNewTab(link) {
            var win = window.open(link, '_blank');
            if (win) {
                //Browser has allowed it to be opened
                win.focus();
            } else {
                //Browser has blocked it
                this.$store.commit("toast/Notify", {
                    type: 'warning',
                    message: 'Please allow popups for this website',
                });
            }
        },
        customTextRenderOld(text) {
            let b = ''
            if (text) {
                var exp = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
                b = text.replace(exp, "<a href='$1' target='_blank'>$1</a>");
                exp = /((?:(http|https|Http|Https|rtsp|Rtsp):\/\/(?:(?:[a-zA-Z0-9\$\-\_\.\+\!\*\'\(\)\,\;\?\&\=]|(?:\%[a-fA-F0-9]{2})){1,64}(?:\:(?:[a-zA-Z0-9\$\-\_\.\+\!\*\'\(\)\,\;\?\&\=]|(?:\%[a-fA-F0-9]{2})){1,25})?\@)?)?((?:(?:[a-zA-Z0-9][a-zA-Z0-9\-]{0,64}\.)+(?:(?:aero|arpa|asia|a[cdefgilmnoqrstuwxz])|(?:biz|b[abdefghijmnorstvwyz])|(?:cat|com|coop|c[acdfghiklmnoruvxyz])|d[ejkmoz]|(?:edu|e[cegrstu])|f[ijkmor]|(?:gov|g[abdefghilmnpqrstuwy])|h[kmnrtu]|(?:info|int|i[delmnoqrst])|(?:jobs|j[emop])|k[eghimnrwyz]|l[abcikrstuvy]|(?:mil|mobi|museum|m[acdghklmnopqrstuvwxyz])|(?:name|net|n[acefgilopruz])|(?:org|om)|(?:pro|p[aefghklmnrstwy])|qa|r[eouw]|s[abcdeghijklmnortuvyz]|(?:tel|travel|t[cdfghjklmnoprtvwz])|u[agkmsyz]|v[aceginu]|w[fs]|y[etu]|z[amw]))|(?:(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9])\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[0-9])))(?:\:\d{1,5})?)(\/(?:(?:[a-zA-Z0-9\;\/\?\:\@\&\=\#\~\-\.\+\!\*\'\(\)\,\_])|(?:\%[a-fA-F0-9]{2}))*)?(?:\b|$)/gi;
                b = text.replace(exp, "<a class='custom_link' href='$1' target='_blank'>$1</a>");
                //Change email addresses to mailto:: links
                var replacePattern3 = /(\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,6})/gim;
                var replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1">$1</a>');
            }
            return b
        },
        customTextRender(text, custom_class = '', custom_title = '') {
            let b = text
            if (text) {
                let vueThis = this
                let bold_text = (/_b_(.*?)_b_/g)
                let italic_text = (/_i_(.*?)_i_/g)
                let strike_text = (/_s_(.*?)_s_/g)
                let hash_tag = (/#[a-z0-9_]+/gi)
                let user_tag = (/@[a-z0-9_]+/gi)
                let exp = /((?:(http|https|Http|Https|rtsp|Rtsp):\/\/(?:(?:[a-zA-Z0-9\$\-\_\.\+\!\*\'\(\)\,\;\?\&\=]|(?:\%[a-fA-F0-9]{2})){1,64}(?:\:(?:[a-zA-Z0-9\$\-\_\.\+\!\*\'\(\)\,\;\?\&\=]|(?:\%[a-fA-F0-9]{2})){1,25})?\@)?)?((?:(?:[a-zA-Z0-9][a-zA-Z0-9\-]{0,64}\.)+(?:(?:aero|arpa|asia|a[cdefgilmnoqrstuwxz])|(?:biz|b[abdefghijmnorstvwyz])|(?:cat|com|coop|c[acdfghiklmnoruvxyz])|d[ejkmoz]|(?:edu|e[cegrstu])|f[ijkmor]|(?:gov|g[abdefghilmnpqrstuwy])|h[kmnrtu]|(?:info|int|i[delmnoqrst])|(?:jobs|j[emop])|k[eghimnrwyz]|l[abcikrstuvy]|(?:mil|mobi|museum|m[acdghklmnopqrstuvwxyz])|(?:name|net|n[acefgilopruz])|(?:org|om)|(?:pro|p[aefghklmnrstwy])|qa|r[eouw]|s[abcdeghijklmnortuvyz]|(?:tel|travel|t[cdfghjklmnoprtvwz])|u[agkmsyz]|v[aceginu]|w[fs]|y[etu]|z[amw]))|(?:(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9])\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[0-9])))(?:\:\d{1,5})?)(\/(?:(?:[a-zA-Z0-9\;\/\?\:\@\&\=\#\~\-\.\+\!\*\'\(\)\,\_])|(?:\%[a-fA-F0-9]{2}))*)?(?:\b|$)/gi;
                b = b.replace(hash_tag, function(s) {
                    let hashTagUrl = "Javascript:;"
                    if (location.href.search('dashboard/feeds') > -1 || location.href.search('dashboard/feed-hash-tags') > -1 || location.href.search('dashboard/timeline') > -1 || location.href.search('dashboard/page') > -1 || location.href.search('dashboard/product') > -1 || location.href.search('dashboard/group') > -1 || location.href.search('dashboard/event') > -1) {
                        hashTagUrl = "/dashboard/feed-hash-tags/" + s.replace('#', '')
                    }
                    if (location.href.search('dashboard/blogs') > -1 || location.href.search('dashboard/blog') > -1) {
                        hashTagUrl = "/dashboard/blogs/hash-tag/" + s.replace('#', '')
                    }
                    if (location.href.search('dashboard/forums') > -1 || location.href.search('dashboard/forum') > -1) {
                        hashTagUrl = "/dashboard/forums/hash-tag/" + s.replace('#', '')
                    }
                    if (custom_class) {
                        return "<a class='" + custom_class + "' href='" + hashTagUrl + "' >" + s + "</a>"
                    }
                    return "<a class='custom_link' href='" + hashTagUrl + "' >" + s + "</a>"
                });
                b = b.replace(user_tag, function(s) {
                    let userProfileUrl = "Javascript:;"
                    userProfileUrl = "/dashboard/timeline/" + s.replace('@', '')
                    let target = '_self'
                    if (location.href.search('dashboard/chats') > -1 || location.href.search('dashboard/chat') > -1) {
                        target = '_blank'
                    }
                    if (custom_class) {
                        return "<a class='" + custom_class + "' href='" + userProfileUrl + "' target=" + target + " >" + s + "</a>"
                    }
                    return "<a class='custom_link' href='" + userProfileUrl + "' >" + s + "</a>"
                });
                b = b.replace(exp, function(s) {
                    let newLink = s
                    if (s.search('://') > -1) {
                        // continue
                    } else {
                        newLink = 'https://' + s
                    }
                    if ((s.indexOf(".jpg") > 0) || (s.indexOf(".png") > 0) || (s.indexOf(".gif") > 0)) {
                        return '<img src="' + s + '">' + '<br/>'
                    }
                    let targetName = '_blank'
                    if(newLink.search('vitisocial.com') > -1 || newLink.search('viraltrend.org') > -1){
                        targetName = '_self'
                    }
                    if (custom_title) {
                        s = custom_title
                    }
                    if (custom_class) {
                        return "<a class='" + custom_class + "' href='" + newLink + "' target='"+targetName+"'>" + s + "</a>"
                    }
                    return "<a class='custom_link' href='" + newLink + "' target='"+targetName+"'>" + s + "</a>"
                });
                b = b.replace(bold_text, function(s) {
                    return "<b>" + vueThis.replaceAll(s, '_b_', '') + "</b>"
                });
                b = b.replace(italic_text, function(s) {
                    return "<i>" + vueThis.replaceAll(s, '_i_', '') + "</i>"
                });
                b = b.replace(strike_text, function(s) {
                    return "<strike>" + vueThis.replaceAll(s, '_s_', '') + "</strike>"
                });

            }
            return b
        },
        randomizeItems(a) {
            let b = a
            for (let i = b.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                const temp = b[i];
                b[i] = b[j];
                b[j] = temp;
            }

            return b
        },
        openProfileChange() {
            $("#user_profile_change .upload-input").click()
        },
        openBannerChange() {
            $("#banner_profile_change .upload-input").click()
        },
        copyTextToClipboard(element) {
            var $temp = $("<input hidden>");
            $("body").append($temp);
            $temp.val($(element).text()).select();
            document.execCommand("copy");
            $temp.remove();

            this.$store.commit("toast/Notify", {
                type: 'success',
                message: 'Text copied',
            });
        },
        copyToClipboard(element) {
            var $temp = $("<input>");
            $("body").append($temp);
            $temp.val($(element).val()).select();
            document.execCommand("copy");
            $temp.remove();

            this.$store.commit("toast/Notify", {
                type: 'success',
                message: 'Text copied',
            });
        },
        noty(status, message) {
            return this.$store.commit("toast/Notify", {
                type: status,
                message: message,
            });
        },
        togglePopover(id) {
            $("#" + id).toggle()
        },
        toggleElements(a, b) {
            $(a).toggle()
        },
        toggleItem(id, c) {
            if (c) {
                if ($("#" + id).css('display') == 'block') {
                    $("." + c).hide()
                } else {
                    $("#" + id).toggle()
                }
            } else {
                $("#" + id).toggle()
            }
        },
        toggleTooltip(id) {
            // console.log('ev', id)
            UIkit.tooltip(id).show();
        },
        replaceAll(a, b, c) {
            return a.split(b).join(c);
        },
        photoGridBoxHeight(a) {
            let b = '300px'
            if (a > 2) {
                b = '600px'
            }
            return b
        },
        manageTaggingOld: function(element) {
            let text = $(element).val()
            let b = text
            console.log('===', element, b)
            $('.tag_dropdown').remove()
            if (b.search('@') > -1) {
                let hash_tag = (/@[a-z0-9_]+/gi)
                let thisListFullHtml = ''
                let thisListUlHtml = ''
                b = b.replace(hash_tag, async function(s) {
                    console.log('---', s)
                    let c = s.replace('@', '')
                    if (c) {
                        let result = await ApiService.Search(c)
                        console.log('............', result)
                        if (result && result['data'] && result['data']['users'] && result['data']['users'].length > 0) {
                            let thisListHtml = ''
                            console.log('......=======......', result['data']['users'])
                            for (let i = 0; i < result['data']['users'].length; i++) {
                                const thisUser = result['data']['users'][i];
                                let thisListContentHtml = '<p>' + thisUser.name + '</p>' + '<p>@' + thisUser.username + '</p>'
                                console.log('------------------', thisListContentHtml)
                                thisListHtml = '<li @click="updateTag(s,@' + thisUser.username + ')"><a class="mouse-pointer" >' + thisListContentHtml + '</a></li>'
                            }
                            thisListUlHtml = '<ul>' + thisListHtml + '</ul>'
                        } else {
                            // thisListUlHtml = '<ul><li>No result</li></ul>'
                        }
                        thisListFullHtml = '<div class="tag_dropdown">' + thisListUlHtml + '</div>'
                        console.log('=-=-=-=-=', thisListFullHtml)
                        $(element).each(function() {
                            $(this).parent().append(thisListFullHtml);
                        });
                    }
                });
            }
        },
        manageTagging: async function(element) {
            if (!$(element).val()) {
                $('.tag_dropdown').remove()
                $('.usertag_dropdown').remove()
            }
            this.manageUserTagging(element)
            this.manageHashTagging(element)
        },
        manageUserTagging: async function(element) {
            let selection = this.getCurrentSelection(element)
            if (selection && selection.search('@') > -1) {
                console.log('sele====', selection, selection.search('@'))
                let s = selection
                let c = s.replace('@', '')
                $('.tag_dropdown').remove()
                $('.usertag_dropdown').remove()
                if (c) {
                    let thisListFullHtml = ''
                    let thisListUlHtml = ''
                    let result = await ApiService.Search(c, 'user_tags')
                    if (result && result['data'] && result['data']['users'] && result['data']['users'].length > 0) {
                        let thisListHtml = ''
                        for (let i = 0; i < result['data']['users'].length; i++) {
                            const thisUser = result['data']['users'][i];
                            if (thisUser.username) {
                                let thisListContentHtml = '<p>' + thisUser.name + '</p>' + '<p>@' + thisUser.username + '</p>'
                                thisListHtml = thisListHtml + '<li class="tag_dropdown_item" data-username="@' + thisUser.username + '" data-search="' + s + '" data-element="' + element + '" onclick="updateTag(this)"><a class="mouse-pointer text-black" href="Javascript:;">' + thisListContentHtml + '</a></li>'
                            }
                        }
                        thisListUlHtml = '<ul>' + thisListHtml + '</ul>'
                    } else {
                        // thisListUlHtml = '<ul><li class="mouse-pointer text-black">No result</li></ul>'
                    }
                    if (thisListUlHtml) {
                        thisListFullHtml = '<div class="tag_dropdown">' + thisListUlHtml + '</div>'
                        $(element + '_tags').each(function() {
                            $(this).append(thisListFullHtml);
                        });
                    }
                }
            } else {
                $('.usertag_dropdown').remove()
            }
        },
        manageHashTagging: async function(element) {
            let selection = this.getCurrentSelection(element)
            if (selection && selection.search('#') > -1) {
                console.log('sele', selection, selection.search('#'))
                let s = selection
                let c = s.replace('#', '')
                $('.tag_dropdown').remove()
                $('.usertag_dropdown').remove()
                if (c) {
                    let thisListFullHtml = ''
                    let thisListUlHtml = ''
                    let result = await ApiService.Search(c, 'hash_tags')
                    if (result && result['data'] && result['data']['hash_tags'] && result['data']['hash_tags'].length > 0) {
                        let thisListHtml = ''
                        for (let i = 0; i < result['data']['hash_tags'].length; i++) {
                            const thisHashTag = result['data']['hash_tags'][i];
                            if (thisHashTag.hash_tag) {
                                let thisListContentHtml = '<p>' + thisHashTag.hash_tag + '</p>'
                                thisListHtml = thisListHtml + '<li class="usertag_dropdown_item" data-username="' + thisHashTag.hash_tag + '" data-search="' + s + '" data-element="' + element + '" onclick="updateTag(this)"><a class="mouse-pointer text-black" href="Javascript:;">' + thisListContentHtml + '</a></li>'
                            }
                        }
                        thisListUlHtml = '<ul>' + thisListHtml + '</ul>'
                    } else {
                        // thisListUlHtml = '<ul><li class="mouse-pointer text-black">No result</li></ul>'
                    }
                    if (thisListUlHtml) {
                        thisListFullHtml = '<div class="usertag_dropdown">' + thisListUlHtml + '</div>'
                        $(element + '_tags').each(function() {
                            $(this).append(thisListFullHtml);
                        });
                    }
                }
            } else {
                $('.tag_dropdown').remove()
            }
        },
        updateTag(search, replace) {
            console.log('====', search, replace)
        },
        getCurrentSelection(element) {
            try {
                var stopCharacters = [' ', '\n', '\r', '\t']
                let thisElement = $(element)
                var text = $(element).val();
                var start = $(element)[0].selectionStart;
                var end = $(element)[0].selectionEnd;
                if (text.search('@') > -1 || text.search('#') > -1) {} else {
                    return ''
                }
                while (start >= 0) {
                    if (stopCharacters.indexOf(text[start]) == -1) {
                        --start;
                    } else {
                        break;
                    }
                };
                ++start;
                while (end < text.length) {
                    if (stopCharacters.indexOf(text[end]) == -1) {
                        ++end;
                    } else {
                        break;
                    }
                }
                var currentWord = text.substr(start, end - start);

            } catch (error) {
                console.log('getCurrentSelection', error, element)
                currentWord = ''
            }
            return currentWord
        },
        checkTokenValidity(payload) {
            if (payload.message.search("invalid token") > -1) {
                localStorage.removeItem('access_token')
                localStorage.removeItem('currentUser')
            }
        },
        userDataToCommitToStore(user) {
            let a = user
            if (user) {
                a = {
                    id: user['id'],
                    role: '',
                    name: user['name'],
                    email: user['email'],
                    profile_photo: user['profile_photo'],
                    banner_photo: user['banner_photo'],
                    verified: user['verified'] || 'false',
                    username: user['username'],
                    phone: user['phone'],
                    type: user['type']
                }
            }
            return a
        },
        age(a) {
            let b = ''
            if (a) {
                try {
                    let years = moment().diff(a, 'years', false);
                    b = years
                } catch (error) {
                    console.log('E', error)
                }
            }
            return b
        },
        userBrowser() {
            let browser = ''
            if ((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1) {
                browser = ('Opera');
            } else if (navigator.userAgent.indexOf("Edg") != -1) {
                browser = ('Edge');
            } else if (navigator.userAgent.indexOf("Chrome") != -1) {
                browser = ('Chrome');
            } else if (navigator.userAgent.indexOf("Safari") != -1) {
                browser = ('Safari');
            } else if (navigator.userAgent.indexOf("Firefox") != -1) {
                browser = ('Firefox');
            } else if ((navigator.userAgent.indexOf("MSIE") != -1) || (!!document.documentMode == true)) //IF IE > 10
            {
                browser = ('IE');
            } else {
                browser = ('unknown');
            }
            return browser
        },
        resizeTextArea(a){
            let textLength = $(a).val().length
            let tlPerLine = 50
            let tll = textLength/tlPerLine
            let cHeight = $(a).css('height')
            let newHeight = tll*40
            $(a).css('height',newHeight+'px')
        },
        randomNumber(){
            return Math.floor(Math.random() *999999999999);
        },
        manageLikeTextSize(id){
            setTimeout(() => {
                let text = $(id).text()
                let textlength = text.length 
                if(textlength > 90){
                    $(id).css('font-size','10px')
                }else if(textlength > 70){
                    $(id).css('font-size','11px')
                }else if(textlength > 50){
                    $(id).css('font-size','12px')
                }else if(textlength > 30){
                    $(id).css('font-size','13px')
                }
            }, 2000);
        },
        isInViewport(identifier) {
            // const element = document.querySelector(identifier)
            const element = document.getElementById(identifier)
            if(element){
                const rect = element.getBoundingClientRect();
                return (
                    rect.top >= 0 &&
                    rect.left >= 0 &&
                    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
                );
            }else{
                return false 
            }
        },
        convertToVTSocialLink(link){
            let a = link 
            if(link){
                a = link.replace('viraltrend.org', 'api.vitisocial.com/files')
            }
            return a;
        }
    },
}