<template>
    <section></section>
</template>

<script>
import { apiService } from '@/services/api.service'
export default {
    name: 'AuthStyles',
    props: {
        pageNameMain: String,
        pageDescriptionMain: String,
        pageImageMain: String
    },
    data: function() {
        return {
            currentYear: new Date().getFullYear(),
            appName: '',
            hostUrl: '',
            pageName: '',
            pageDescription: 'Make Money Socializing... Go Viral',
            pageImage: '/assets/img/logo-full-small.png'
        }
    },
    head: {
        title: function() {
            let pageName = ''
            if(this.pageNameMain){
                pageName = this.pageNameMain
            }
            return {inner: pageName+' '}
        },
        meta: function() {
            let appName = this.$APP_NAME
            let pageDescription = this.pageDescription
            let pageImage = this.pageImage
            let pageName = this.pageName
            if(this.pageDescriptionMain){
                pageDescription = this.pageDescriptionMain
            }
            if(this.pageNameMain){
                pageName = this.pageNameMain
            }
            if(this.pageImageMain){
                pageImage = this.pageImageMain
            }
            // console.log('this.pageName',this.pageName)
            // console.log('pageDescription',pageDescription)
            // console.log('pageImage',pageImage)
            return [
                { name: 'application-name', content: appName },
                { name: 'description', content: pageDescription, id: 'desc' },
                { name: 'twitter:title', content: this.pageName+' '+appName },
                { n: 'twitter:description', c: pageDescription},
                { itemprop: 'name', content: this.pageName+' '+appName },
                { itemprop: 'description', content: pageDescription },
                { property: 'fb:app_id', content: '123456789' },
                { property: 'og:title', content: this.pageName+' '+appName },
                { p: 'og:image', c: pageImage },
            ]
        },
    },
    methods: {
    },
    beforeCreate: function() {
        this.appName = this.$APP_NAME
    },
    mounted: function () {
        if(this.pageNameMain){
            this.pageName = this.pageNameMain + ' - '
        }
        if(this.pageDescriptionMain){
            this.pageDescription = this.pageDescriptionMain
        }
        if(this.pageImageMain){
            this.pageImage = this.pageImageMain
        }
    }
}
</script>

<!-- <style lang="css" scoped>
    @import '/public/assets/css/icons.css';
    @import '/public/assets/css/uikit.css';
    @import '/public/assets/css/style.css';
    @import '/public/tailwindcss/dist/tailwind.min.css';
</style> -->

<style>
    input , .bootstrap-select.btn-group button, select, .special-input{
        background-color: #f3f4f6  !important;
        height: 44px  !important;
        box-shadow: none  !important; 
        padding: 0 20px;
    }
    .logo_img {
        height: 50px;
        width: auto;
    }
    .mouse-pointer {
        cursor: pointer;
    }
</style>
