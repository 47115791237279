<template>
<div class="lg:w-72 w-full">

    <a href="#birthdays" uk-toggle="">
        <div class="bg-white mb-5 px-4 py-3 rounded-md shadow">
            <h3 class="text-line-through font-semibold mb-1"> Birthdays </h3>
            <div v-if="todays_birthdays.length > 0" class="-mx-2 duration-300 flex hover:bg-gray-50 px-2 py-2 rounded-md">
                <img src="/assets/images/icons/gift-icon.png" class="w-9 h-9 mr-3" alt="">
                <p v-if="todays_birthdays.length > 0" class="line-clamp-2 leading-6"> <strong> {{ todays_birthdays[0]['name'] }} </strong> 
                <template v-if="todays_birthdays.length > 2">
                    and <strong> {{ todays_birthdays.length-1 }} others </strong>
                    have birthdays today.
                </template>
                <template v-if="todays_birthdays.length == 2">
                    and <strong> 1 other </strong>
                    has a birthday today.
                </template>
                <template v-if="todays_birthdays.length == 1">
                    has a birthday today.
                </template>
                </p>
            </div>
            <div v-if="todays_birthdays.length == 0 && birthdayLoading == false" >
                <h5>No birthday today</h5>
            </div>
            <div v-if="birthdayLoading == true" class="text-center my-3">
                <i class="fa fa-spin fa-spinner fa-2x"></i>
            </div>
        </div>
    </a>

    <!-- <h3 class="text-xl font-semibold bg-white px-2 pt-2 pb-3 rounded-md mb-3"> Contacts </h3> -->

    <div class="uk-sticky card rounded-md shadow" uk-sticky="offset:80" style="">

        <nav class="responsive-nav border-b extanded mb-2 -mt-2">
            <ul uk-switcher="connect: #group-details; animation: uk-animation-fade">
                <li class="uk-active" @click="setActiveSection('friend')">
                    <a class="active px-2" href="javascript:;"> 
                        Followers <span> {{ followers.length }} </span> 
                    </a>
                </li>
                <li @click="setActiveSection('group')">
                    <a href="javascript:;">Groups</a>
                </li>
            </ul>
        </nav>

        <div class="contact-list p-2">
            <template v-if="activeSection == 'friend'">
                <div v-if="followers.length == 0 && followersLoading == false" >
                    <h5 class="p-3">You dont have any followers yet</h5>
                </div>
                <div v-if="followersLoading == true" class="text-center my-3">
                    <i class="fa fa-spin fa-spinner fa-2x"></i>
                </div>
                <template v-for="f in followers" >
                    <a :href="'/dashboard/timeline/'+otherChatUserID(f)" aria-expanded="false">
                        <div class="contact-avatar">
                            <img :src="otherChatUserPhoto(f)" alt="">
                            <span class="user_status status_online d-none"></span>
                        </div>
                        <div class="contact-username"> 
                            {{ otherChatUserName(f) }}
                            <verified-badge v-if="otherChatUserVerified(f) == 'true'" size="small"></verified-badge>
                        </div>
                    </a>
                    <div uk-drop="pos: left-center ;animation: uk-animation-slide-left-small" class="uk-drop">
                        <div class="contact-list-box">
                            <div class="contact-avatar">
                                <img :src="otherChatUserPhoto(f)" alt="">
                                <span class="user_status status_online d-none"></span>
                            </div>
                            <div class="contact-username"> 
                                {{ otherChatUserName(f) }} <verified-badge v-if="otherChatUserVerified(f) == 'true'" size="small"></verified-badge>
                            </div>
                            <p class="d-none">
                                Become friends with
                                <strong> Stella Johnson </strong> and <strong> 14 Others</strong>
                            </p>
                            <div class="contact-list-box-btns">
                                <button v-on:click="openLink('/dashboard/chats?type=user&id='+otherChatUserID(f))" class="button primary flex-1 block mr-2">
                                    <i class="uil-envelope mr-1"></i> Chat</button>
                                <button v-on:click="openLink('/dashboard/timeline/'+otherChatUserID(f))" class="button secondary button-icon mr-2 bg-orange">
                                    <i class="uil-list-ul mr-1"></i>view profile</button>
                            </div>
                        </div>
                    </div>
                </template>
            </template>
            <template v-if="activeSection == 'group'">
                <div v-if="groups_followed.length == 0 && groupsLoading == false" >
                    <h5>No Group</h5>
                </div>
                <div v-if="groupsLoading == true" class="text-center my-3">
                    <i class="fa fa-spin fa-spinner fa-2x"></i>
                </div>
                <div class="" v-for="gf in groups_followed">
                    <a :href="'/dashboard/group/'+gf.group.id" aria-expanded="false" class="">
                        <div class="contact-avatar">
                            <img v-if="gf.group.image" :src="gf.group.image" alt="">
                            <img v-else :src="defaultPostUrl()" alt="">
                            <span class="user_status"></span>
                        </div>
                        <div class="contact-username"> {{ gf.group.name }}</div>
                    </a>
                    <div uk-drop="pos: left-center ;animation: uk-animation-slide-left-small" class="uk-drop uk-drop-left-center" style="left: -300px; top: 23.25px;">
                        <div class="contact-list-box">
                            <div class="contact-avatar">
                                <img v-if="gf.group.image" :src="gf.group.image" alt="">
                                <img v-else :src="defaultPostUrl()" alt="">
                                <span class="user_status"></span>
                            </div>
                            <div class="contact-username"> {{ gf.group.name }} </div>
                        </div>
                    </div>
                </div>
            </template>

        </div>

    </div>
    <div class="uk-sticky-placeholder" style="height: 484px; margin: 0px;" hidden=""></div>

</div>
</template>

<script>
import {
    ApiService
} from "@/services/api.service";
import SingleFileUpload from '@/components/SingleFileUpload'
import VerifiedBadge from '@/components/VerifiedBadge'
export default {
    name: 'BirthdayContactsCardComponent',
    props: {},
    data() {
        return {
            followers: '',
            followings: '',
            groups_followed: '',
            pendingFollowersRequest: '',
            pendingBirthdayRequest: '',
            pendingGroupsRequest: '',
            todays_birthdays:'',
            birthdayLoading: false,
            followersLoading: false,
            groupsLoading: false,
            activeSection: 'friend',
        }
    },
    components: {
        SingleFileUpload,
        VerifiedBadge,
    },
    mounted() {
        this.currentUser = this.$store.getters["user/currentUser"] || '';
        this.getFollowers()
        this.getFollowedGroups()
        this.getBirthdays()
    },
    methods: {
        async getFollowers() {
            this.followersLoading = true
            let apiResponse = await ApiService.GetFollowers();
            if (apiResponse['status'] == 'success') {
                this.followers = apiResponse['data']['followers']
                this.followings = apiResponse['data']['followings']
            }else{
                this.$store.commit("toast/Notify", {
                    type: apiResponse['status'],
                    message: apiResponse['message'],
                });
            }
            this.followersLoading = false
        },
        async getBirthdays() {
            this.birthdayLoading = true
            let apiResponse = await ApiService.GetBirthdays();
            if (apiResponse['status'] == 'success') {
                this.todays_birthdays = apiResponse['data']['todays_birthdays']
            }else{
                this.$store.commit("toast/Notify", {
                    type: apiResponse['status'],
                    message: apiResponse['message'],
                });
            }
            this.birthdayLoading = false
        },
        async getFollowedGroups(section) {
            this.pendingGroupsRequest = true;
            this.toggleProgress('show')
            let apiResponse = await ApiService.GetFollowedGroups(this.page, section);
            if (apiResponse["status"] != "success") {} else {
                this.groups_followed = apiResponse["data"]['groups'];
                this.pendingRequest = false;
            }
            this.pendingGroupsRequest = false;
        },
        setActiveSection(a){
            this.activeSection = a
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
