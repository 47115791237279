<template>
<div id="wrapper">
    <progress-loader v-if="requestProgress == 'true'"></progress-loader>

    <Header />

    <sidebar></sidebar>

    <!-- Main Contents -->
    <div class="main_content">
        <div class="mcontainer">

            <!--  breadcrumb -->
            <div class="breadcrumb-area py-0">
                <div class="breadcrumb">
                    <ul class="m-0">
                        <li>
                            <a href="/dashboard/products">Products</a>
                        </li>
                        <li class="active">
                            <a href="">Edit Product </a>
                        </li>
                    </ul>
                </div>
            </div>

            <!-- create page-->
            <div class="max-w-2xl m-auto shadow-md rounded-md bg-white lg:mt-20">

                <!-- form header -->
                <div class="text-center border-b border-gray-100 py-6">
                    <h3 class="font-bold text-xl"> {{ product.name }} </h3>
                </div>

                <!-- form body -->
                <div class="p-10 space-y-7">

                    <div>
                        <label>Name</label>
                    </div>
                    <div class="line" style="margin-top: 5px">
                        <input v-model="name" class="line__input" id="name" name="name" type="text" onkeyup="this.setAttribute('value', this.value);" value="" autocomplete="off">
                        <span for="name" class="line__placeholder">  </span>
                    </div>
                    <div class="flex items-center">
                        <div class="mr-2">
                            <p>Product Image</p>
                            <img v-if="image" :src="image" class="postImgheight" />
                            <img v-else :src="defaultPostUrl()" class="postImgheight" />
                        </div>
                        <single-file-upload type="image" @uploadSuccess="onImageUploaded">
                            <p>Select<br /> Image</p>
                        </single-file-upload>
                    </div>
                    <div>
                        <!-- <label for=""> Category </label><br/><br/> -->
                        <select v-model="brand_id" id="" name="" class="shadow-none with-border ">
                            <option value="">Select Brand</option>
                            <option v-for="c in brands" :value="c.id">{{ c.name }}</option>
                        </select>
                    </div>
                    <div>
                        <!-- <label for=""> Category </label><br/><br/> -->
                        <select v-model="categorySubcategory" id="" name="" class="shadow-none with-border " @change="setCategorySubcategory">
                            <option value="">Select Category</option>
                            <template v-for="c in categories">
                                <option v-for="s in c.sub_categories" :value="c.id+'-'+s.id">{{ c.name }} - {{ s.name }}</option>
                            </template>
                        </select>
                    </div>
                    <div>
                        <!-- <label for=""> Category </label><br/><br/> -->
                        <select v-model="currency" id="" name="" class="shadow-none with-border ">
                            <option value="">Select Currency</option>
                            <option v-for="c in currencies" :value="c">{{ c }}</option>
                        </select>
                    </div>

                    <div>
                        <label>Amount</label>
                    </div>
                    <div class="line" style="margin-top: 5px">
                        <input v-model="amount" class="line__input" id="amount" name="amount" type="phone" onkeyup="this.setAttribute('value', this.value);" value="" autocomplete="off">
                        <span for="amount" class="line__placeholder">  </span>
                    </div>

                    <div>
                        <label>Items In Stock</label>
                    </div>
                    <div class="line" style="margin-top: 5px">
                        <input v-model="in_stock" class="line__input" id="in_stock" name="in_stock" type="phone" onkeyup="this.setAttribute('value', this.value);" value="" autocomplete="off">
                        <span for="in_stock" class="line__placeholder">  </span>
                    </div>
                    <div class="">
                        <label for="" class="mb-2"> Available Colors </label>
                        <div>
                            <label v-for="s in colors" class="mr-3">
                                <input type="checkbox" v-model="product_colors" :value="s['name']" class="custom-checkbox"/>
                                {{ s['name'] }}
                            </label>
                        </div>
                    </div>
                    <div class="">
                        <label for="" class="mb-2"> Available Sizes </label>
                        <div>
                            <label v-for="s in sizes" class="mr-3">
                                <input type="checkbox" v-model="product_sizes" :value="s['name']" class="custom-checkbox"/>
                                {{ s['name'] }}
                            </label>
                        </div>
                    </div>
                    <div class="">
                        <label for=""> Description </label>
                        <!-- <vue-editor id="editor" v-model="description" useCustomImageHandler @image-added="handleImageAdded"></vue-editor> -->
                        <textarea v-model="description" class="bg-smoke h-32 p-2" id="" name="" type="text" onkeyup="this.setAttribute('value', this.value);" value="" autocomplete="off" style="background-color: #f3f4f6 !important;"></textarea>
                        <!-- <span for="username" class="line__placeholder"> Description </span> -->
                    </div>

                </div>

                <!-- form footer -->
                <div class="border-t flex justify-between lg:space-x-10 p-7 bg-gray-50 rounded-b-md">
                    <button type="button" class="button lg:w-1/2" @click="updateProduct">
                        Update Product
                    </button>
                </div>

            </div>

        </div>
    </div>

    <dashboard-widgets page="edit-product"></dashboard-widgets>

</div>
</template>

<script>
import {
    VueEditor
} from "vue2-editor";
import {
    ApiService
} from '@/services/api.service'
import Header from '@/include/Dashboard/Header.vue'
import Sidebar from '@/include/Dashboard/Sidebar.vue'
import DashboardWidgets from '@/components/DashboardWidgets'
import OpenChatBox from '@/include/Modals/OpenChatBox.vue'
import Feeds from '@/include/Dashboard/Feeds.vue'
import ProfileIcon from '@/include/Dashboard/Feed/ProfileIcon'
import ProgressLoader from '@/components/ProgressLoader'
import SingleFileUpload from '@/components/SingleFileUpload'
import axios from 'axios'

export default {
    name: 'EditProduct',
    components: {
        Header,
        Sidebar,
        DashboardWidgets,
        OpenChatBox,
        Feeds,
        ProgressLoader,
        SingleFileUpload,
        VueEditor
    },

    data() {
        return {
            product: '',
            image: '',
            category: '',
            brand_id: '',
            name: '',
            currency: 'USD',
            amount: '',
            description: '',
            in_stock: '',
            brands: '',
            categories: '',
            currencies: '',
            categorySubcategory: '',
            category_id: '',
            sub_category_id: '',
            sizes: '',
            colors: '',
            product_sizes: [],
            product_colors: [],
            requestProgress: 'false'
        }
    },
    mounted: function () {
        let id = this.$route.params['id']
        this.getProduct(id)
        this.getExtraInfo()
    },
    methods: {
        async getProduct(id) {
            this.toggleProgress('show')
            let apiResponse = await ApiService.GetProduct(id);
            this.$store.commit("toast/Notify", {
                type: apiResponse["status"],
                message: apiResponse["message"],
            });
            if (apiResponse["status"] != "success") {} else {
                this.product = apiResponse['data']['product']

                this.name = this.product['name']
                this.description = this.product['description']
                this.image = this.product['image']
                this.amount = this.product['amount']
                this.currency = this.product['currency']
                this.in_stock = this.product['in_stock']
                this.brand_id = this.product['brand_id']
                this.category_id = this.product['category_id']
                this.sub_category_id = this.product['sub_category_id']

                let sizes = this.product['sizes']
                let colors = this.product['colors']

                for (let i = 0; i < sizes.length; i++) {
                    const thisSize = sizes[i];
                    this.product_sizes.push(thisSize['name'])
                }
                for (let i = 0; i < colors.length; i++) {
                    const thisColor = colors[i];
                    this.product_colors.push(thisColor['name'])
                }
                this.toggleProgress('hide')
            }
        },
        async setCategorySubcategory(){
            let catSubcat = this.categorySubcategory
            let catSubcatObj = catSubcat.split("-")
            this.category_id = catSubcatObj[0]
            this.sub_category_id = catSubcatObj[1]
        },
        async getExtraInfo() {
            this.toggleProgress('show')
            this.requestProgress = 'true'
            let apiResponse = await ApiService.ExtraInfo();
            if (apiResponse['status'] == 'success') {
                this.brands = apiResponse['data']['product_brands']
                this.categories = apiResponse['data']['product_categories']
                this.currencies = apiResponse['data']['currencies']
                this.sizes = apiResponse['data']['product_sizes']
                this.colors = apiResponse['data']['product_colors']
            }
            this.toggleProgress('hide')
            this.requestProgress = 'false'
        },
        async updateProduct() {
            this.requestProgress = 'true'
            this.toggleProgress('show')
            let apiResponse = await ApiService.UpdateProduct(this.product.id, this.name, this.amount, this.currency, this.in_stock, this.image, this.description, this.brand_id, this.category_id, this.sub_category_id, this.product_sizes, this.product_colors);
            if (apiResponse["status"] != "success") {
                this.$store.commit("toast/Notify", {
                    type: apiResponse["status"],
                    message: apiResponse["message"],
                });
            } else {

                location.href = '/dashboard/product/'+this.product.id
            }
            this.toggleProgress('hide')
            this.requestProgress = 'false'
        },
        onImageUploaded(response) {
            // Handle files like:
            // console.log('::::',response)
            if (response['status'] == 'success') {
                this.image = response['data']['file_name']
            }
            this.$store.commit("toast/Notify", {
                type: response["status"],
                message: response["message"],
            });
        },
        handleImageAdded: function (file, Editor, cursorLocation, resetUploader) {
            // An example of using FormData
            // NOTE: Your key could be different such as:
            // formData.append('file', file)

            var formData = new FormData();
            formData.append("file", file);

            axios({
                    url: this.$store.getters['host/apiUrl'].replace('/api/v2', '/files/upload'),
                    method: "POST",
                    data: formData
                })
                .then(result => {
                    // console.log('result', result)
                    const url = result.data.data['file_name']; // Get url from response
                    Editor.insertEmbed(cursorLocation, "image", url);
                    resetUploader();
                })
                .catch(err => {
                    console.log(err);
                });
        }
    },
}
</script>

<style lang="scss" scoped>
.some-modal-content {
    min-width: 400px;
    padding: 25px;

    .buttons button {
        padding: 10px;
        margin: 10px;
    }
}

.postImgheight {
    height: 100px
}

.editor {
    height: 350px;
}
</style>
