// layouts
import DashboardLayout from '@/layout/DashboardLayout'

// single pages
import homePage from '../pages/Home.vue'
import notFoundPage from '../pages/NotFound.vue'
import Welcome from '@/pages/Welcome'
import Faq from '@/pages/home/Faq'
import Contact from '@/pages/home/Contact'
import About from '@/pages/home/About'
import Terms from '@/pages/Terms'

import loginPage from '../pages/auth/LoginPage.vue'
import registerPage from '../pages/auth/RegisterPage.vue'
import registerSuccess from '../pages/auth/RegisterSuccess.vue'
import RequestAccountActivation from '@/pages/auth/RequestAccountActivation'
import AccountActivation from '../pages/auth/AccountActivation.vue'
import ResetPassword from '../pages/auth/ResetPassword.vue'
import AutoLogin from '../pages/auth/AutoLogin.vue'

// Dashboard
import CreateBlog from '../pages/dashboard/blog/CreateBlog.vue'
import Blog from '../pages/dashboard/blog/Blog.vue'
import Blogs from '../pages/dashboard/blog/Blogs.vue'
import EditBlog from '@/pages/dashboard/blog/EditBlog'
import BlogsByHashTag from '@/pages/dashboard/blog/BlogsByHashTag'
import CreateForum from '../pages/dashboard/forum/CreateForum.vue'
import Forum from '../pages/dashboard/forum/Forum.vue'
import Forums from '../pages/dashboard/forum/Forums.vue'
import EditForum from '@/pages/dashboard/forum/EditForum'
import ForumsByHashTag from '@/pages/dashboard/forum/ForumsByHashTag'

import Timeline from '@/pages/dashboard/Timeline'
import UserTimeline from '@/pages/dashboard/UserTimeline'
import Wallet from '@/pages/dashboard/Wallet'

import Photos from '@/pages/dashboard/Photos'

import Videos from '@/pages/dashboard/Videos'

import Birthdays from '@/pages/dashboard/Birthdays'

import Events from '@/pages/dashboard/events/Events'
import Event from '@/pages/dashboard/events/Event'
import CreateEvent from '@/pages/dashboard/events/CreateEvent'
import EditEvent from '@/pages/dashboard/events/EditEvent'

import Jobs from '@/pages/dashboard/jobs/Jobs'
import Job from '@/pages/dashboard/jobs/Job'
import CreateJob from '@/pages/dashboard/jobs/CreateJob'
import EditJob from '@/pages/dashboard/jobs/EditJob'
import UpcomingEvents from '@/pages/dashboard/events/UpcomingEvents'

import Products from '@/pages/dashboard/products/Products'
import Product from '@/pages/dashboard/products/Product'
import CreateProduct from '@/pages/dashboard/products/CreateProduct'
import EditProduct from '@/pages/dashboard/products/EditProduct'
import NewProducts from '@/pages/dashboard/products/NewProducts'
import ProductSections from '@/pages/dashboard/products/ProductSections'
import Checkout from '@/pages/dashboard/products/Checkout'
import Orders from '@/pages/dashboard/products/Orders'
import CreateShop from '@/pages/dashboard/shop/CreateShop'
import UpdateShop from '@/pages/dashboard/shop/UpdateShop'
import ProductOrderCustomer from '@/pages/dashboard/products/ProductOrderCustomer'
import ProductOrderShop from '@/pages/dashboard/products/ProductOrderShop'
import ShopPage from '@/pages/dashboard/shop/ShopPage'

import Chats from '@/pages/dashboard/chats/Chats'
import ShopChats from '@/pages/dashboard/chats/ShopChats'

import Courses from '@/pages/dashboard/courses/Courses'
import Course from '@/pages/dashboard/courses/Course'
import CreateCourse from '@/pages/dashboard/courses/CreateCourse'
import UpdateCourse from '@/pages/dashboard/courses/UpdateCourse'
import AddCourseLesson from '@/pages/dashboard/courses/add/AddCourseLesson'
import AddCourseFaq from '@/pages/dashboard/courses/add/AddCourseFaq'
import AddCourseAnnouncement from '@/pages/dashboard/courses/add/AddCourseAnnouncement'

import Groups from '@/pages/dashboard/groups/Groups'
import Group from '@/pages/dashboard/groups/Group'
import CreateGroup from '@/pages/dashboard/groups/CreateGroup'
import EditGroup from '@/pages/dashboard/groups/EditGroup'

import Pages from '@/pages/dashboard/pages/Pages'
import Page from '@/pages/dashboard/pages/Page'
import CreatePage from '@/pages/dashboard/pages/CreatePage'
import EditPage from '@/pages/dashboard/pages/EditPage'

import PagePublic from '@/pages/dashboard/pages/PagePublic'

import SingleFeedPage from '@/pages/SingleFeedPage'

import FeedHashTags from '@/pages/dashboard/hash_tags/FeedHashTags'

import AdvertiseAll from '@/pages/dashboard/advertise/AdvertiseAll'
import AdvertiseSingle from '@/pages/dashboard/advertise/AdvertiseSingle'
import AdvertiseNew from '@/pages/dashboard/advertise/AdvertiseNew'

import Maintainance from '@/pages/dashboard/utils/Maintainance'
import ComingSoon from '@/pages/dashboard/utils/ComingSoon'

import Referrals from '@/pages/dashboard/referrals/Referrals'
import ReferralCommisions from '@/pages/dashboard/referrals/ReferralCommisions'

// import { routePropResolver } from './util'
import { DOMAIN_TITLE } from '../.env'

export const routes = [{
  path: '/',
  name: 'index',
  component: Welcome
},
{
  path: '/home',
  name: 'home',
  component: Welcome
},
{
  path: '/faq',
  name: 'faq',
  component: Faq
},
{
  path: '/about',
  name: 'about',
  component: About
},
{
  path: '/contact',
  name: 'contact',
  component: Contact
},
{
  path: '/terms',
  name: 'terms',
  component: Terms
},
{
  path: '/terms-and-conditions',
  name: 'terms-and-conditions',
  component: Terms
},
{
  path: '/dashboard',
  component: DashboardLayout,
  children: [{
    path: '',
    name: 'dashboard',
    component: homePage
  },
  {
    path: 'feeds',
    name: 'feeds',
    component: homePage
  },
  {
    path: 'feed/:id',
    name: 'single-feed',
    component: SingleFeedPage
  },
  {
    path: 'feed-hash-tags/:id',
    name: 'feed-hash-tags',
    component: FeedHashTags
  },
  {
    path: 'create-blog',
    name: 'create-blog',
    component: CreateBlog
  },
  {
    path: 'blogs',
    name: 'blogs',
    component: Blogs
  },
  {
    path: 'blog',
    name: 'blog-topics',
    component: Blogs
  },
  {
    path: 'blog/:id',
    name: 'blog',
    component: Blog
  },
  {
    path: 'blogs/hash-tag/:hash_tag',
    name: 'blogs-by-hash-tag',
    component: BlogsByHashTag
  },
  {
    path: 'edit-blog/:id',
    name: 'edit-blog',
    component: EditBlog
  },
  {
    path: 'create-forum',
    name: 'create-forum',
    component: CreateForum
  },
  {
    path: 'forums',
    name: 'forums',
    component: Forums
  },
  {
    path: 'forums/hash-tag/:hash_tag',
    name: 'forums-by-hash-tag',
    component: ForumsByHashTag
  },
  {
    path: 'forum',
    name: 'forum-topics',
    component: Forums
  },
  {
    path: 'forum/:id',
    name: 'forum',
    component: Forum
  },
  {
    path: 'edit-forum/:id',
    name: 'edit-forum',
    component: EditForum
  },
  {
    path: 'timeline',
    name: 'timeline',
    component: Timeline
  },
  {
    path: 'wallet',
    name: 'wallet',
    component: Wallet
  },
  {
    path: 'profile',
    name: 'profile',
    component: Timeline
  },
  {
    path: 'timeline/:id',
    name: 'user-timeline',
    component: UserTimeline
  },
  {
    path: 'referrals/all',
    name: 'referrals-all',
    component: Referrals
  },
  {
    path: 'referrals/advertiser-commisions',
    name: 'referrals-advertiser-commisions',
    component: ReferralCommisions
  },
  {
    path: 'videos',
    name: 'videos',
    component: Videos
  },
  {
    path: 'photos',
    name: 'photos',
    component: Photos
  },
  {
    path: 'jobs',
    name: 'jobs',
    component: Jobs
  },
  {
    path: 'job/:id',
    name: 'job',
    component: Job
  },
  {
    path: 'create-job',
    name: 'create-job',
    component: CreateJob
  },
  {
    path: 'edit-job/:id',
    name: 'edit-job',
    component: EditJob
  },
  {
    path: 'birthdays',
    name: 'birthdays',
    component: Birthdays
  },
  {
    path: 'events',
    name: 'events',
    component: Events
  },
  {
    path: 'event/:id',
    name: 'event',
    component: Event
  },
  {
    path: 'create-event',
    name: 'create-event',
    component: CreateEvent
  },
  {
    path: 'edit-event/:id',
    name: 'edit-event',
    component: EditEvent
  },
  {
    path: 'events/upcoming',
    name: 'upcoming-events',
    component: UpcomingEvents
  },
  {
    path: 'products',
    name: 'products',
    component: Products
  },
  {
    path: 'products/new',
    name: 'new-products',
    component: NewProducts
  },
  {
    path: 'product/:id',
    name: 'product',
    component: Product
  },
  {
    path: 'create-product',
    name: 'create-product',
    component: CreateProduct
  },
  {
    path: 'edit-product/:id',
    name: 'edit-product',
    component: EditProduct
  },
  {
    path: 'products/checkout',
    name: 'products/checkout',
    component: Checkout
  },
  {
    path: 'products/orders',
    name: 'products/orders',
    component: Orders
  },
  {
    path: 'products/customer-order/:id',
    name: 'products/customer-order',
    component: ProductOrderCustomer
  },
  {
    path: 'products/shop-order/:id',
    name: 'products/shop-order',
    component: ProductOrderShop
  },
  {
    path: 'products/:section/:section_id',
    name: 'product-sections',
    component: ProductSections
  },
  {
    path: 'shops/create',
    name: 'shops/create',
    component: CreateShop
  },
  {
    path: 'shops/update',
    name: 'shops/update',
    component: UpdateShop
  },
  {
    path: 'shop/:id',
    name: 'shop/:id',
    component: ShopPage
  },
  {
    path: 'chats',
    name: 'chats',
    component: Chats
  },
  {
    path: 'shop-chats',
    name: 'shop-chats',
    component: ShopChats
  },
  {
    path: 'courses',
    name: 'courses',
    component: Courses
  },
  {
    path: 'course/:id',
    name: 'course/:id',
    component: Course
  },
  {
    path: 'courses/create',
    name: 'courses/create',
    component: CreateCourse
  },
  {
    path: 'update-course/:id',
    name: 'update-course/:id',
    component: UpdateCourse
  },
  {
    path: 'course/add-faq/:id',
    name: 'add-course-faq',
    component: AddCourseFaq
  },
  {
    path: 'course/add-lesson/:id',
    name: 'add-course-lesson',
    component: AddCourseLesson
  },
  {
    path: 'course/add-announcement/:id',
    name: 'add-course-announcement',
    component: AddCourseAnnouncement
  },
  {
    path: 'groups',
    name: 'groups',
    component: Groups
  },
  {
    path: 'group/:id',
    name: 'group/:id',
    component: Group
  },
  {
    path: 'groups/create',
    name: 'groups/create',
    component: CreateGroup
  },
  {
    path: 'update-group/:id',
    name: 'update-group/:id',
    component: EditGroup
  },
  {
    path: 'pages',
    name: 'pages',
    component: Pages
  },
  {
    path: 'page/:id',
    name: 'page',
    component: Page
  },
  {
    path: 'pages/create',
    name: 'pages/create',
    component: CreatePage
  },
  {
    path: 'update-page/:id',
    name: 'update-page/:id',
    component: EditPage
  },
  {
    path: 'advertise/all',
    name: 'advertise/all',
    component: AdvertiseAll
  },
  {
    path: 'advertise/create',
    name: 'advertise/create',
    component: AdvertiseNew
  },
  {
    path: 'advertise/:id',
    name: 'advertise/:id',
    component: AdvertiseSingle
  },
  {
    path: 'coming-soon',
    name: 'coming-soon',
    component: ComingSoon
  },
  {
    path: 'under-maintainance',
    name: 'under-maintainance',
    component: Maintainance
  }
  ]
},
{
  path: '/login',
  name: 'login',
  component: loginPage
},
{
  path: '/register',
  name: 'register',
  component: registerPage
},
{
  path: '/register/:id',
  name: 'register-from-link',
  component: registerPage
},
{
  path: '/register-success',
  name: 'register-success',
  component: registerSuccess
},
{
  path: '/request-activate-account',
  name: 'request-activate-account',
  component: RequestAccountActivation
},
{
  path: '/activate-account/:code/:expire_at',
  name: 'activate-account',
  component: AccountActivation
},
{
  path: '/auth/activate-account/:code/:expire_at',
  name: 'auth-activate-account',
  component: AccountActivation
},
{
  path: '/auth/activate_account/:code/:expire_at',
  name: 'auth-activate-account2',
  component: AccountActivation
},
{
  path: '/reset-password',
  name: 'reset-password',
  component: ResetPassword
},
{
  path: '/auto/:token',
  name: 'auto',
  component: AutoLogin
},
{
  path: '/auto-login/:token',
  name: 'auto-login',
  component: AutoLogin
},
{
  path: '/page/:id',
  name: 'publicPage',
  component: PagePublic
},
{
  path: '*',
  component: notFoundPage,
  meta: { title: `${DOMAIN_TITLE} | not found` }
}
]
