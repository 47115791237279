export default {
    SET_CURRENT_USER(state) {
        let currentUser = localStorage.getItem('currentUser')
        if (currentUser) {
            currentUser = JSON.parse(currentUser)
            state.currentUser = currentUser
        }
    },
    SAVE_CURRENT_USER(state, currentUser) {
        state.currentUser = currentUser
            // console.log(currentUser, 'SAVE_CURRENT_USER')
        localStorage.setItem('currentUser', JSON.stringify(currentUser))
    },
    CLEAR_CURRENT_USER(state) {
        localStorage.clear('currentUser')
    }
}