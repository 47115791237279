import { render, staticRenderFns } from "./UpcomingEvents.vue?vue&type=template&id=20508a08"
import script from "./UpcomingEvents.vue?vue&type=script&lang=js"
export * from "./UpcomingEvents.vue?vue&type=script&lang=js"
import style0 from "./UpcomingEvents.vue?vue&type=style&index=0&id=20508a08&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports