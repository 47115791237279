<template>
<!-- Share post modal -->
<div id="feed-donations-modal" class="create-post-old is-story-old uk-modal-container-old" uk-modal="">
    <div class="uk-modal-body uk-margin-auto-vertical rounded-lg p-0  relative shadow-2xl-old uk-animation-slide-bottom-small bg-white">
        <div class="text-center py-3 border-b">
            <h3 class="text-lg font-semibold">Feed Donations</h3>
            <button class="uk-modal-close-default bg-gray-100 rounded-full p-2.5 right-2" type="button" uk-close="" uk-tooltip="title: Close ; pos: bottom ;offset:7"></button>
        </div>
        <template>
            <div class="bsolute bottom-0 p-4 w-full my-3">
                <div style="justify-content: space-between;" class="flex">
                    <div>
                        <p>
                            Total Amount: {{currency}}{{ formatAmount(total_amount) }}
                        </p>
                    </div>
                    <div>
                        <p>
                            Total: {{ formatAmount(total_number) }}
                        </p>
                    </div>
                </div>
                <div class="flex-old bg-gray-50 border border-purple-100 rounded-2xl-old p-2 shadow-sm items-center mt-3 mb-5">
                    <div class="table-responsive">
                        <table class="styled-table">
                            <thead>
                                <tr>
                                    <th>Amount</th>
                                    <th>Reference</th>
                                    <th>Date</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="donation in donations">
                                    <td>{{ donation['currency'] }} {{ donation['amount'] }}</td>
                                    <td>{{ donation['reference'] }}</td>
                                    <td>{{ donation['created_at'] }}</td>
                                    <td>{{ donation['status'] }}</td>
                                    <td>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div v-if="feed_boosts.length == 0" class="mt-3 text-center">
                        <h5>
                            This feed has no donations
                        </h5>
                    </div>
                </div>
                <div class="" v-if="loading == 'true'">
                    <i class="fa fa-spin fa-spinner fa-2x"></i>
                </div>
            </div>
        </template>

    </div>
</div>
</template>

<script>
import {
    ApiService
} from '@/services/api.service'
export default {
    name: "FeedDonationsModal",
    props: {
        feed_id: String,
    },
    data() {
        return {
            loading: "true",
            donations: "",
            feed_boosts: "",
            ip: '',
            browser: '',
            device: '',
            currentUser: '',
            total_amount: '',
            total_number: '',
            currency: '',
            main_feed_id: '',
            currentYear: new Date().getFullYear(),
        };
    },
    mounted: function () {
        this.currentUser = this.$store.getters['user/currentUser'] || {}
        let vueThis = this
        this.$root.$on("open-feed-donations-modal",(main_feed_id) => {
            // console.log('main_feed_id', main_feed_id)
            vueThis.main_feed_id = main_feed_id
            if (vueThis.main_feed_id) {
                vueThis.getFeedDonationsReceived();
            }

        })
        if (this.feed_id) {
          this.main_feed_id = this.feed_id
            this.getFeedDonationsReceived();
        }
    },
    methods: {
        async getFeedDonationsReceived() {
            this.loading = "true";
            let apiResponse = await ApiService.FeedDonationsReceived(this.main_feed_id);
            if (apiResponse["status"] != "success") {
                this.$store.commit("toast/Notify", {
                    type: apiResponse["status"],
                    message: apiResponse["message"],
                });
            } else {
                this.donations = apiResponse["data"]
                this.total_number = this.donations.length
                this.total_amount = this.donations.length
                let donations = this.donations
                let total_amount = 0
                for (let i = 0; i < donations.length; i++) {
                    const donation = donations[i];
                    if(donation['amount']){
                        this.currency = donation['currency']
                        total_amount = total_amount+parseFloat(donation['amount'])
                    }
                }
                this.total_amount = total_amount
            }
            this.loading = "false";
        },
        async open() {},
        closeModal() {
            $("#feed-donations-modal").removeClass("uk-open").hide();
        },
    },
};
</script>

<style lang="scss" scoped>
.styled-table {
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  font-family: sans-serif;
  min-width: 400px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  width: 100%;
}
.styled-table thead tr {
  background-color: #2563eb;
  color: #ffffff;
  text-align: left;
}
.styled-table th,
.styled-table td {
    padding: 12px 15px;
}
.styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.styled-table tbody tr:last-of-type {
  border-bottom: 2px solid #2563eb;
}
</style>
