<template>
<div id="wrapper">
    <!-- Header -->
    <auth-styles pageNameMain="About"></auth-styles>
    <home-header></home-header>

    <div class="home-content">
        <what-we-do-section></what-we-do-section>
        <advert-platforms></advert-platforms>
    </div>

    <auth-scripts></auth-scripts>

</div>
</template>

<script>
import AuthStyles from '@/include/Auth/AuthStyles'
import AuthScripts from '@/include/Auth/AuthScripts'
import HomeHeader from '@/include/Home/HomeHeader'
import WhatWeDoSection from '@/include/Home/WhatWeDoSection'
import SocialWidget from '@/include/Home/SocialWidget'
import AdvertPlatforms from '@/include/Home/AdvertPlatforms'
export default {
    name: 'AboutPage',
    components: {
        AuthStyles,
        AuthScripts,
        SocialWidget,
        HomeHeader,
        WhatWeDoSection,
        AdvertPlatforms,
    },
    data() {
        return {
            currentYear: new Date().getFullYear(),
            faqData: [{
                'question': 'How do I register either as an advertiser or a promoter?',
                'answer': ""
            }]
        }
    },

    methods: {}
}
</script>

<style>
</style>
<style lang="scss" scoped>
</style>
