<template>

    <div id="allow-push-notification-bar" class="card bg-white p-3 text-center" style="display:none">
        <div>
            <div class="mb-3">
                <h4>Stay connected to vitisocial, we will notify you as soon as they happen</h4>
            </div>
            <div class="row">
                <div class="col-sm-6">
                    <button class="btn btn-success px-5" id="allowFCMPermission">Allow</button>
                </div>
                <div class="col-sm-6">
                    <button class="btn btn-default" id="denyFCMPermission">No</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    ApiService
} from "@/services/api.service";
import SharePostModal from '@/include/Modals/SharePostModal'
import VerifiedBadge from '@/components/VerifiedBadge'

export default {
    name: 'FCMPermission',
    props: {
    },
    data() {
        return {
            currentUser: '',
        }
    },
    components: {
    },
    mounted() {
    },
    methods: {
        async allowFCMPermission() {
        },
        async denyFCMPermission() {
        },
    }
}
</script>

<style lang="scss" scoped>
</style>
