<template>
<div id="wrapper" class="flex flex-col justify-between h-screen">
    <auth-styles pageNameMain="Login"></auth-styles>
    <auth-header></auth-header>

    <!-- Content-->
    <div>
        <div class="lg:p-12 max-w-xl lg:my-0 my-12 mx-auto p-6 space-y-">

            <form class="lg:p-10 p-6 space-y-3 relative bg-white shadow-xl rounded-md">

                <div v-if="stage == '1'">
                    <h1 class="lg:text-2xl text-xl font-semibold mb-6"> Reset Password </h1>
                    <div>
                        <label class="mb-0"> Email Address </label>
                        <input v-model="email" type="email" placeholder="Info@example.com" class="bg-gray-100 h-12 mt-2 px-3 rounded-md w-full">
                    </div>

                    <div>
                        <button v-on:click="resetPassword" type="button" class="bg-blue-600 font-semibold p-4 mt-5 rounded-md text-center text-white w-full">
                            Get Started</button>
                    </div>
                    <div>
                        <p>
                            Remember your password? <router-link to="/login">Click here to login</router-link>
                        </p>
                    </div>
                </div>
                <div v-if="stage == '2'">
                    <h1 class="lg:text-2xl text-xl font-semibold mb-6"> Change Password </h1>
                    <div>
                        <label class="mb-0"> Code </label>
                        <input v-model="code" type="text" placeholder="Code sent to your email address" class="bg-gray-100 h-12 mt-2 px-3 rounded-md w-full">
                    </div>
                    <div>
                        <label class="mb-0"> Password </label>
                        <input v-model="password" type="password" placeholder="******" class="bg-gray-100 h-12 mt-2 px-3 rounded-md w-full">
                    </div>
                    <div>
                        <label class="mb-0"> Repeat Password </label>
                        <input v-model="repeat_password" type="password" placeholder="******" class="bg-gray-100 h-12 mt-2 px-3 rounded-md w-full">
                    </div>

                    <div>
                        <button v-on:click="changePassword" type="button" class="bg-blue-600 font-semibold p-4 mt-5 rounded-md text-center text-white w-full">
                            Get Started</button>
                    </div>
                </div>
                <div v-if="stage == '3'">
                    <h1 class="lg:text-2xl text-xl font-semibold mb-6"> Password Changed </h1>
                    <div class="text-center">
                        <p>
                            Your password has been changed successfully, <br />proceed to <a href="/login">login</a>
                        </p>
                    </div>
                </div>
            </form>

        </div>
    </div>

    <auth-scripts></auth-scripts>

</div>
</template>

<script>
import {
    ApiService
} from '@/services/api.service'
import AuthStyles from '../../include/Auth/AuthStyles'
import AuthScripts from '../../include/Auth/AuthScripts'
import AuthHeader from '@/include/Auth/AuthHeader'

export default {
    name: 'ResetPassword',
    data() {
        return {
            email: '',
            code: '',
            password: '',
            repeat_password: '',
            stage: '1',
            error: ''
        }
    },
    components: {
        AuthStyles,
        AuthScripts,
        AuthHeader
    },
    mounted() {
        let accessToken = this.$store.getters['auth/accessToken']
        if (accessToken) {
            location.href = '/dashboard/feeds'
        }
    },
    methods: {
        async resetPassword() {
            if (!this.email) {
                return this.$store.commit('toast/Notify', {
                    type: 'warning',
                    message: 'email is required'
                })
            }
            $('button').attr('disabled', true)
            let apiResponse = await ApiService.ResetPassword(this.email)
            // console.log(apiResponse)
            this.$store.commit('toast/Notify', {
                type: apiResponse['status'],
                message: apiResponse['message']
            })
            if (apiResponse['status'] == 'success') {
                this.stage = '2'
            }
            $('button').attr('disabled', false)
        },
        async changePassword() {
            if (!this.email) {
                return this.$store.commit('toast/Notify', {
                    type: 'warning',
                    message: 'email is required'
                })
            }
            if (!this.code) {
                return this.$store.commit('toast/Notify', {
                    type: 'warning',
                    message: 'code is required'
                })
            }
            if (!this.password) {
                return this.$store.commit('toast/Notify', {
                    type: 'warning',
                    message: 'password is required'
                })
            }
            if (!this.repeat_password) {
                return this.$store.commit('toast/Notify', {
                    type: 'warning',
                    message: 'repeat_password is required'
                })
            }
            $('button').attr('disabled', true)
            let apiResponse = await ApiService.ChangePassword(this.email, this.code, this.password, this.repeat_password)
            // console.log(apiResponse)
            this.$store.commit('toast/Notify', {
                type: apiResponse['status'],
                message: apiResponse['message']
            })
            if (apiResponse['status'] == 'success') {
                this.stage = '3'
            }
            $('button').attr('disabled', false)
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
