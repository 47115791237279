<template>
<div id="wrapper">
    <auth-styles v-if="pageNameMain" :pageNameMain="pageNameMain" :pageDescriptionMain="pageDescriptionMain" :pageImageMain="pageImageMain"></auth-styles>

    <Header />

    <sidebar></sidebar>

    <!-- Main Contents -->
    <div class="main_content">
        <div class="mcontainer">

            <div class="my-4 flex items-center justify-between">
                <div>
                    <h2 class="text-xl font-semibold">Upcomming Events </h2>
                </div>
            </div>

            <div class="relative uk-slider" uk-slider="finite: true">

                <div class="uk-slider-container px-1 py-3">
                    <ul class="uk-slider-items uk-child-width-1-4@m uk-child-width-1-3@s uk-grid-small uk-grid" style="transform: translate3d(0px, 0px, 0px);">
                        <event-component v-for="event in events" :event="event" :key="event.id" ></event-component>
                    </ul>
                    <div v-if="eventsPreloader == 'false' && comingEvents.length == 0" class="bg-white p-2">
                        <h5>No Up coming event</h5>
                    </div>

                    <a class="absolute bg-white top-16 flex items-center justify-center p-2 -left-4 rounded-full shadow-md text-xl w-9 z-10 dark:bg-gray-800 dark:text-white uk-invisible" href="#" uk-slider-item="previous"> <i class="icon-feather-chevron-left"></i></a>
                    <a class="absolute bg-white top-16 flex items-center justify-center p-2 -right-4 rounded-full shadow-md text-xl w-9 z-10 dark:bg-gray-800 dark:text-white" href="#" uk-slider-item="next"> <i class="icon-feather-chevron-right"></i></a>

                </div>
            </div>

            <br>

        </div>
    </div>

    <progress-loader v-if="eventsPreloader == 'true'"></progress-loader>
    <dashboard-widgets page="upcoming-event"></dashboard-widgets>

</div>
</template>

<script>
import {
    VueEditor
} from "vue2-editor";
import {
    ApiService
} from '@/services/api.service'
import Header from '@/include/Dashboard/Header.vue'
import Sidebar from '@/include/Dashboard/Sidebar.vue'
import DashboardWidgets from '@/components/DashboardWidgets'
import OpenChatBox from '@/include/Modals/OpenChatBox.vue'
import ProfileIcon from '@/include/Dashboard/Feed/ProfileIcon'
import ProgressLoader from '@/components/ProgressLoader'
import TimelineScreen from '@/include/Dashboard/Timeline/TimelineScreen'
import FriendScreen from '@/include/Dashboard/Timeline/FriendScreen'
import PhotoScreen from '@/include/Dashboard/Timeline/PhotoScreen'
import PageScreen from '@/include/Dashboard/Timeline/PageScreen'
import GroupScreen from '@/include/Dashboard/Timeline/GroupScreen'
import VideoScreen from '@/include/Dashboard/Timeline/VideoScreen'
import SingleFileUpload from '@/components/SingleFileUpload'
import CreatePhotoPost from '@/components/CreatePhotoPost'
import EventComponent from '@/components/EventComponent'
import axios from 'axios'

export default {
    name: 'UpcomingEvents',
    components: {
        Header,
        Sidebar,
        DashboardWidgets,
        OpenChatBox,
        ProgressLoader,
        VueEditor,
        TimelineScreen,
        FriendScreen,
        PhotoScreen,
        PageScreen,
        GroupScreen,
        VideoScreen,
        SingleFileUpload,
        CreatePhotoPost,
        EventComponent
    },
    data() {
        return {
            events: [],
            tags: [],
            categories: [],
            category: '',
            total_events: 0,
            pendingRequest: false,
            eventsPreloader: 'false',
            pageNameMain: '',
            pageDescriptionMain: '',
            pageImageMain: '',
            section: '',
            randomActive: '',
            recentActive: '',
            userActive: '',
            upcomingActive: '',
            pastActive: '',
            comingEventsPreloader: 'false',
            comingEvents: '',
            tags: [],
        }
    },
    mounted: function () {
        this.thisUser = this.$store.getters["user/currentUser"];
        let id = this.thisUser['id']
        this.id = id
        this.getEvents()
    },
    methods: {
        async getEvents() {
            if (this.pendingRequest == true) {
                // console.log('Pending Req')
                return
            }
            this.pendingRequest = true
            this.eventsPreloader = 'true'
            let apiResponse = await ApiService.GetEvents(this.page, 'upcoming')
            if (apiResponse['status'] != 'success') {} else {
                this.total_events = apiResponse['data']['total_events']
                let events = apiResponse['data']['event']
                if (events.length > 0) {
                    this.page++
                }
                // console.log('this.page', this.page)
                if (this.events.length == 0) {
                    this.events = events
                } else {
                    if (photos.length > 0) {
                        this.events = this.events.concat(events)
                    }
                }
            }
            this.pendingRequest = false
            this.eventsPreloader = 'false'
        },
        async makeActiveSection(a) {
            this.recentActive = ''
            this.randomActive = ''
            this.userActive = ''
            this.pastActive = ''
            this.trendingActive = ''

            this.section = a;
            this.page = 0;
            this.events = []

            if (a.toLowerCase() == 'recent') {
                this.recentActive = 'active'
                this.getEvents('recent')
            } else if (a.toLowerCase() == 'random') {
                this.randomActive = 'active'
                this.getEvents('oldest')
            } else if (a.toLowerCase() == 'trending') {
                this.trendingActive = 'active'
                this.getEvents('trending')
            } else if (a.toLowerCase() == 'user') {
                this.userActive = 'active'
                this.getEvents('user')
            } else if (a.toLowerCase() == 'past') {
                this.pastActive = 'active'
                this.getEvents('past')
            }
        }
    },
}
</script>

<style>
.profilePage123 input {
    width: 157px !important;
    height: 32px !important;
}
</style>
