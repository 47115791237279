import Vue from 'vue'
// import socketio from 'socket.io-client';
// import VueSocketIO from 'vue-socket.io';

import AppLayout from './layout/index.vue'
import AuthLayout from './layout/AuthLayout.vue'
import router from './router'
import store from './store'
import VueHead from 'vue-head'
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import vueCountryRegionSelect from 'vue-country-region-select'
import FileUpload from 'v-file-upload'
import firebaseMessaging from './services/firebase.service.js'

Vue.prototype.$messaging = firebaseMessaging

import './mixins'
import utilMixin from './mixins/utils.js'
import './plugins'
import './thirdParty'

import './scss/style.scss'
import './assets/fonts/bebasneue.css'
import axios from 'axios'
import VueVideoPlayer from 'vue-video-player'
import AudioVisual from 'vue-audio-visual'
import checkView from 'vue-check-view'
import PhotoGrid from 'vue-photo-grid';
import Popover from 'vue-js-popover'
import Ads from 'vue-google-adsense'
import ImageComponent from '@/components/ImageComponent'


// require videojs style
import 'video.js/dist/video-js.css'
// import 'vue-video-player/src/custom-theme.css'

Vue.config.productionTip = false

let host = window.location.hostname;
Vue.prototype.$APP_NAME = 'Vitisocial'
Vue.prototype.$axios = axios


const commitWindowWidth = () => store.commit('dom/SET_WINDOW_WIDTH', window.innerWidth)

Vue.use(VueHead)
const options = {
    timeout: 5000
};


Vue.use(Toast, options);
Vue.use(vueCountryRegionSelect)
Vue.use(FileUpload)
Vue.use(VueVideoPlayer,
    /* {
  options: global default options,
  events: global videojs events
} */
)
Vue.use(AudioVisual)
Vue.use(checkView)
Vue.use(PhotoGrid);
Vue.use(Popover)

Vue.use(require('vue-script2'))

Vue.use(Ads.Adsense)
Vue.use(Ads.InArticleAdsense)
Vue.use(Ads.InFeedAdsense)

Vue.use(ImageComponent)



Vue.mixin(utilMixin)

new Vue({
    name: 'RootTemplate',
    router,
    store,
    mounted() {
        commitWindowWidth()
        window.addEventListener('resize', commitWindowWidth)
    },

    beforeDestroy() {
        window.removeEventListener('resize', commitWindowWidth)
    },
    render: h => h(AppLayout)
}).$mount('#app')