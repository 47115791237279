<template>
<div id="wrapper">

    <Header />

    <sidebar></sidebar>

    <!-- Main Contents -->
    <div class="main_content">
        <div class="mcontainer">
            <div class="max-w-xl mx-auto">

                <div class="card sm:p-7 p-4">

                    <div class="flex items-center space-x-3">
                        <ion-icon name="gift" class="text-yellow-500 text-xl bg-yellow-50 p-1 rounded-md"></ion-icon>
                        <div class="text-xl font-semibold"> Today's birthdays </div>
                    </div>

                    <div class="space-y-8 mt-10">
                        <div class="sm:space-y-8 space-y-6">

                            <div v-for="b in todays_birthdays" class="flex items-center sm:space-x-6 space-x-3">
                                <img v-if="b.profile_photo" :src="b.profile_photo" alt="" class="sm:w-16 sm:h-16 w-14 h-14 rounded-full">
                                <img v-else :src="defaultImageUrl()" alt="" class="sm:w-16 sm:h-16 w-14 h-14 rounded-full">
                                <div class="flex-1">
                                    <div class="flex items-center justify-between mb-3">
                                        <div class="text-base font-semibold"> <a :href="'/dashboard/timeline/'+b.id"> {{ b.name }} </a> </div>
                                        <div class="font-medium text-sm text-gray-400"> {{ ageCalculator(b.dob) }} years old</div>
                                    </div>
                                    <div class="relative">
                                        <input type="text" name="" id="" class="with-border" placeholder="Write her on Timeline">
                                        <ion-icon name="happy" class="absolute right-3 text-2xl top-1/4"></ion-icon>
                                    </div>
                                </div>
                            </div>
                            <div v-if="todays_birthdays.length == 0" class="bg-white p-2">
                                <h5>No birthdays today</h5>
                            </div>

                        </div>
                        <div class="relative cursor-pointer" @click="toggleShowUpcoming()">
                            <div class="bg-gray-50 rounded-lg px-5 py-4 font-semibold text-base"> Upcoming birthdays </div>
                            <i v-if="showUpcoming == 'true'" class="-translate-y-1/2 absolute icon-feather-chevron-up right-4 text-xl top-1/2 transform text-gray-400" id="upcoming" hidden-old=""></i>
                            <i v-else class="-translate-y-1/2 absolute icon-feather-chevron-down right-4 text-xl top-1/2 transform text-gray-400" id="upcoming"></i>
                        </div>
                        <div v-if="rest_of_month_birthdays.length == 0" class="bg-white p-2">
                            <h5>No birthdays this week</h5>
                        </div>
                        <div v-if="showUpcoming == 'true'" class="mt-5 sm:space-y-8 space-y-6" id="upcoming" hidden-old="">

                            <div v-for="rb in rest_of_month_birthdays" class="flex items-center sm:space-x-6 space-x-3">
                                <img v-if="rb.profile_photo" :src="rb.profile_photo" alt="" class="sm:w-16 sm:h-16 w-14 h-14 rounded-full">
                                <img v-else :src="defaultImageUrl()" alt="" class="sm:w-16 sm:h-16 w-14 h-14 rounded-full">
                                <div class="flex-1">
                                    <div class="flex items-center justify-between mb-3">
                                        <div class="text-base font-semibold"> <a :href="'/dashboard/timeline/'+rb.id"> {{ rb.name }} </a> </div>
                                        <div class="font-medium text-sm text-gray-400"> {{ ageCalculator(rb.dob) }} years old in {{ daysLater(rb.dob) }} days</div>
                                    </div>
                                    <div class="relative">
                                        <input type="text" name="" id="" class="with-border" placeholder="Write her on Timeline">
                                        <ion-icon name="happy" class="absolute right-3 text-2xl top-1/4"></ion-icon>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>

            </div>
        </div>
    </div>

    <dashboard-widgets page="birthdays"></dashboard-widgets>
    <progress-loader v-if="birthdayPreloader == 'true'"></progress-loader>

</div>
</template>

<script>
import {
    VueEditor
} from "vue2-editor";
import {
    ApiService
} from '@/services/api.service'
import Header from '@/include/Dashboard/Header.vue'
import Sidebar from '@/include/Dashboard/Sidebar.vue'
import DashboardWidgets from '@/components/DashboardWidgets'
import OpenChatBox from '@/include/Modals/OpenChatBox.vue'
import ProfileIcon from '@/include/Dashboard/Feed/ProfileIcon'
import ProgressLoader from '@/components/ProgressLoader'
import TimelineScreen from '@/include/Dashboard/Timeline/TimelineScreen'
import FriendScreen from '@/include/Dashboard/Timeline/FriendScreen'
import PhotoScreen from '@/include/Dashboard/Timeline/PhotoScreen'
import PageScreen from '@/include/Dashboard/Timeline/PageScreen'
import GroupScreen from '@/include/Dashboard/Timeline/GroupScreen'
import VideoScreen from '@/include/Dashboard/Timeline/VideoScreen'
import SingleFileUpload from '@/components/SingleFileUpload'
import CreatePhotoPost from '@/components/CreatePhotoPost'
import IonIcon from '@/components/IonIcon'
import axios from 'axios'

export default {
    name: 'Photos',
    components: {
        Header,
        Sidebar,
        DashboardWidgets,
        ProgressLoader,
        VueEditor,
        TimelineScreen,
        FriendScreen,
        PhotoScreen,
        PageScreen,
        GroupScreen,
        VideoScreen,
        SingleFileUpload,
        CreatePhotoPost,
        IonIcon
    },
    data() {
        return {
            birthdays: [],
            rest_of_month_birthdays: [],
            todays_birthdays: [],
            pendingRequest: false,
            birthdayPreloader: 'false',
            showUpcoming: 'false',
        }
    },
    mounted: function () {
        this.thisUser = this.$store.getters["user/currentUser"];
        let id = this.thisUser['id']
        this.id = id
        this.getBirthdays()
    },
    methods: {
        async getBirthdays() {
            if (this.pendingRequest == true) {
                // console.log('Pending Req')
                return
            }
            this.pendingRequest = true
            this.birthdayPreloader = 'true'
            let apiResponse = await ApiService.GetBirthdays()
            if (apiResponse['status'] != 'success') {} else {
                this.birthdays = apiResponse['data']['birthdays']
                this.rest_of_month_birthdays = apiResponse['data']['rest_of_month_birthdays']
                this.todays_birthdays = apiResponse['data']['todays_birthdays']
            }
            this.pendingRequest = false
            this.birthdayPreloader = 'false'
        },
        async toggleShowUpcoming(){
            if(this.showUpcoming == 'true'){
                this.showUpcoming = 'false'
            }else{
                this.showUpcoming = 'true'
            }
        }
    },
}
</script>

<style>
.profilePage123 input {
    width: 157px !important;
    height: 32px !important;
}
</style>
