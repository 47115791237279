<template>
<div id="modal-sections" uk-modal>
    <div class="uk-modal-dialog">
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <div class="uk-modal-header">
            <h2 class="uk-modal-title">{{ title }}</h2>
        </div>
        <div class="uk-modal-body">
            <p>{{ subTitle }}</p>
        </div>
        <div class="flex p-4 text-center">
            <div class="mr-4">
                <button @click="closeModal" class="px-4 py-3 rounded-md text-sm text-white hover:text-white w-full bg-blue-700" type="button">No</button>
            </div>
            <div>
                <button class="px-4 py-3 rounded-md text-sm text-white hover:text-white w-full bg-red-700 mouse-pointer" type="button" @click="confirmButton">Yes</button>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    ApiService
} from "@/services/api.service";
import {
    FileUploadService
} from 'v-file-upload'
import FileUpload from 'v-file-upload'
export default {
    name: 'ConfirmModal',
    props: {
        title: String,
        subTitle: String,
    },
    data() {
        return {}
    },
    components: {
        FileUpload
    },
    mounted() {},
    methods: {
        confirmButton(file) {
            this.$emit('confirm', e)
        },
        closeModal(){
            let element = $("#modal-sections")
            UIkit.modal(element).hide();
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
