<template>
<!-- birthdays modal -->
<div id="view-status-modal" uk-modal="">
    <div v-if="currentUser" class="uk-modal-dialog uk-modal-body rounded-xl shadow-lg">
        <div>
            <div>
                <div class="flex flex-1 items-center space-x-4">
                    <div uk-lightbox="" v-if="this_story.user && this_story.user.profile_photo">
                        <profile-icon :url="this_story.user.profile_photo"></profile-icon>
                    </div>
                    <div class="flex-1 font-semibold capitalize">
                        <a v-if="this_story.user && this_story.user.id" :href="'/dashboard/timeline/'+this_story.user.id" class="text-gray-700 dark:text-gray-100">
                            {{ this_story.user.name }}
                            <verified-badge v-if="this_story.user && this_story.user.verified == 'true'" size="small"></verified-badge>
                        </a>
                    </div>
                </div>
            </div>
            <!-- close button -->
            <button @click="resetStory" class="uk-modal-close-default-old p-2.5 bg-gray-100 rounded-full m-3" type="button" uk-close=""></button>
        </div>
        <div class="m-auto">
            <br />
            <div class="my-3">
                <div v-if="this_story && this_story.items.length > 0" class="row">
                    <div v-for="(items, index) in this_story.items" class="col px-1 mouse-pointer" @click="changeStoryItem(index)">
                        <div :id="'sic_'+items.id" class="mx-1 story-ind-custom">
                            <div></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="story-content">
                <VueSlickCarousel v-bind="settings" v-if="this_story && this_story.items.length > 0" @beforeChange="manageBeforeStoryChange" ref="carousel">
                    <div v-for="item in this_story.items" :key="item.id">
                        <div class="text-right" v-if="show_viewers == 'true'">
                            <div class="">
                                <div>
                                    <h5 class="mb-4">Viewed by</h5>
                                    <ul>
                                        <li v-for="viewer in item.viewers" v-if="viewer['user']" class="mb-2">
                                            <a :href="'/dashboard/timeline/'+viewer['user']['id']" target="_blank" class="text-blue">
                                                {{ viewer['user']['name'] }}
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <template v-else>
                            <div class="story_image" v-if="item.type == 'image'">
                                <img :src="item.content" alt="story item" style="" />
                                <div class="text-center" v-html="customTextRender(item.caption,'','')">
                                </div>
                            </div>
                            <div class="story_text" v-else-if="item.type == 'text'">
                                <div class="status_text_holder text-center text-middle" :style="'background-color:'+item['color']+';color:#fff;'">
                                    <div>
                                        <div v-html="customTextRender(item.content)"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="story_link" v-else-if="item.type == 'link'">
                                <div class="status_text_holder text-center text-middle" :style="'background-color:'+item['color']+';color:#fff;'">
                                    <div>
                                        <a :href="item.content" target="_blank" data-alt="Image" v-html="customTextRender(item.content,'text-white')">
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>
                </VueSlickCarousel>
                <p class="">
                    <br/>
                </p>
                <hr/>
                <p v-if="this_story.user_id == currentUser.id" class="text-center mt-3">
                    <a class="text-orange text-underline" v-if="show_viewers == 'false'" href="javascript:;" @click="toggleViewers">show viewers</a>
                    <a class="text-orange text-underline" v-else href="javascript:;" @click="toggleViewers">hide viewers</a>
                </p>
                <p v-if="this_story.user_id == currentUser.id" class="mt-3">
                    <a uk-toggle="target: #save-status-modal" class="flex space-x-4 items-center hover:bg-gray-100 md:my-2 py-2 rounded-lg hover:text-gray-700 mouse-pointer">
                        <svg class="w-12 h-12 p-3 bg-gray-200 rounded-full text-blue-500 ml-1" fill="none" stroke="currentColor" viewbox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path>
                        </svg>
                        <div class="flex-1">
                            <div class="text-lg font-semibold">Create a story</div>
                            <div class="text-sm -mt-0.5">Share a photo or write something.</div>
                        </div>
                    </a>
                </p>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    ApiService
} from "@/services/api.service";
// import { Carousel, Slide } from 'vue-carousel';
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import ProfileIcon from '@/include/Dashboard/Feed/ProfileIcon'
import VerifiedBadge from '@/components/VerifiedBadge'
export default {
    name: "ViewStatusModal",
    props: {
        story: Object
    },
    components: {
        VueSlickCarousel,
        ProfileIcon,
        VerifiedBadge,
    },
    data() {
        return {
            currentYear: new Date().getFullYear(),
            appName: "-",
            currentUser: "",
            storyItem: "",
            this_story: "",
            is_last: 'false',
            currentIntIndex: 0,
            maxIndex: 4,
            storyTime: 6000,
            show_viewers: 'false',
            currentStoryId: '',
            allTimeout: [],
            settings: {
                arrows: false,
                dots: false,
                autoplay: true,
                autoplaySpeed: 5000,
            },
        };
    },
    mounted: function () {
        this.appName = this.$APP_NAME;
        this.currentUser = this.$store.getters["user/currentUser"];
        let vueThis = this
        this.$root.$on("open-view-status-modal", (story) => {
            // console.log('opened modal')
            // console.log('mounted', story)
            vueThis.this_story = story
            if (story.items.length > 1) {} else {
                this.manageBeforeStoryChange(-1, 0)
            }
        })
    },
    methods: {
        async viewStoryItem(id) {
            this.storyLoading = true
            let apiResponse = await ApiService.ViewStoryItem(id)
            if (apiResponse['status'] != 'success') {} else {}
        },
        changeStoryItem(index) {
            if (index < 0 || index > this.this_story.items.length - 1) {
                // console.log('We cant open this index')
                return
            }
            // console.log('index', index)
            this.$refs.carousel.goTo(index)
        },
        manageBeforeStoryChange(i, j) {
            // console.log('manageBeforeStoryChange', i, j)
            if (i == this.this_story.items.length - 1 && j == 0) {
                this.resetStory()
            } else {
                this.currentStoryId = j
                this.setStoryIndColor(this.this_story.items[j])
                this.viewStoryItem(this.this_story.items[j]['id'])
            }
        },
        resetStory() {
            this.this_story = ''
            this.is_last = 'false'
            this.$root.$emit("close-view-status-modal")
        },
        setStoryIndColor(item) {
            $(".story-ind-custom").css('background', '#ddd')
            $(".story-ind-custom > div").css('width', '100%')

            $("#sic_" + item.id).css('background', '#aaa')
            $("#sic_" + item.id).css('width', '0%')
            let interval = 1000
            let noOfIntervals = 5000 / interval
            let itemID = item.id
            let thisElement = "#sic_" + item.id
            for (let i = 0; i < noOfIntervals; i++) {
                let newI = i + 1
                let currentPercent = newI * (100 / noOfIntervals)
                let vueThis = this
                setTimeout(() => {
                    if (vueThis.currentStoryId == itemID) {
                        $(thisElement).css('background', '#aaa')
                        $(thisElement).css('width', currentPercent + '%')
                    } else {
                        $(thisElement).css('background', '#aaa')
                        $(thisElement).css('width', '100%')
                    }
                }, 1000 * newI);
            }
        },
        toggleViewers() {
            if (this.show_viewers == 'true') {
                this.show_viewers = 'false'
            } else {
                this.show_viewers = 'true'
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.story-ind-custom {
    padding: 2px;
    height: 2px;
    width: 100%;
    background: #ddd;
    border-radius: 5px;
    margin-bottom: 10px;
}

.story-ind-custom>div {
    background: #ddd;
}

.text-middle {
    height: 400px;
    background: #eee;
    vertical-align: middle;
    display: flex;
    align-items: center;
}

.text-middle>div {
    width: 100%;
}

.story-slider-image {
    height: 400px;
    max-width: 100%;
}

.story_image>img {
    width: 100%;
    max-height: 500px;
    max-width: 100%;
    object-fit: contain;
}
</style>
