<template>
<div id="wrapper">

    <Header />

    <sidebar></sidebar>

    <!-- Main Contents -->
    <div class="main_content">
        <div class="mcontainer" v-if="currentUser && job && currentUser['id'] == job['user_id']">

            <!--  breadcrumb -->
            <div class="breadcrumb-area py-0">
                <div class="breadcrumb">
                    <ul class="m-0">
                        <li>
                            <a href="/dashboard/jobs">Job Posts</a>
                        </li>
                        <li class="active">
                            <a href="">Edit Job Post Post </a>
                        </li>
                    </ul>
                </div>
            </div>

            <!-- create page-->
            <div class="max-w-2xl m-auto shadow-md rounded-md bg-white lg:mt-20">

                <!-- form header -->
                <div class="text-center border-b border-gray-100 py-6">
                    <h3 class="font-bold text-xl"> Create New Job Post </h3>
                </div>

                <!-- form body -->
                <div class="p-10 space-y-7">

                    <div>
                        <!-- <label for=""> Category </label><br/><br/> -->
                        <select v-model="job_role_id" id="" name="" class="shadow-none with-border ">
                            <option value="">Select Job Role</option>
                            <option v-for="c in jobRoles" :value="c.id">{{ c.name }}</option>
                        </select>
                        <div class="mt-2">
                            <a class="mouse-pointer" uk-toggle="target: #create-new-role">
                                Cant find Job Role in the Add? Add yours
                            </a>
                        </div>
                    </div>
                    <div>
                        <!-- <label for=""> Category </label><br/><br/> -->
                        <select v-model="company_id" id="" name="" class="shadow-none with-border ">
                            <option value="">Select Company</option>
                            <option v-for="c in companies" :value="c.id">{{ c.name }}</option>
                        </select>
                        <div class="mt-2">
                            <a class="mouse-pointer" uk-toggle="target: #create-new-company">
                                Add Company
                            </a>
                        </div>
                    </div>
                    <div class="line">
                        <input v-model="min_salary" class="line__input" id="min_salary" name="min_salary" type="phone" onkeyup="this.setAttribute('value', this.value);" value="" autocomplete="off">
                        <span for="min_salary" class="line__placeholder"> Min Salary($) </span>
                    </div>
                    <div class="line">
                        <input v-model="max_salary" class="line__input" id="max_salary" name="max_salary" type="phone" onkeyup="this.setAttribute('value', this.value);" value="" autocomplete="off">
                        <span for="max_salary" class="line__placeholder"> Max Salary($) </span>
                    </div>
                    <div class="flex items-center">
                        <div class="mr-2">
                            <p>Job Image</p>
                            <img v-if="image" :src="image" class="postImgheight" />
                            <img v-else :src="defaultPostUrl()" class="postImgheight" />
                        </div>
                        <single-file-upload type="image" @uploadSuccess="onImageUploaded">
                            <p>Select<br /> Image</p>
                        </single-file-upload>
                    </div>
                    <div>
                        <!-- <label for=""> Category </label><br/><br/> -->
                        <select v-model="job_type_id" id="" name="" class="shadow-none with-border ">
                            <option value="">Job Type</option>
                            <option v-for="c in jobTypes" :value="c.id">{{ c.name }}</option>
                        </select>
                    </div>
                    <div class="line">
                        <input v-model="application_link" class="line__input" id="application_link" name="application_link" type="url" onkeyup="this.setAttribute('value', this.value);" value="" autocomplete="off">
                        <span for="application_link" class="line__placeholder"> Application Link </span>
                    </div>
                    <div class="">
                        <label for=""> Description </label><br/>
                        <vue-editor id="editor" v-model="description" useCustomImageHandler @image-added="handleImageAdded"></vue-editor>
                    </div>

                </div>

                <!-- form footer -->
                <div class="border-t flex justify-between lg:space-x-10 p-7 bg-gray-50 rounded-b-md hover:text-white">
                    <button type="button" class="button lg:w-1/2" @click="updateJob">
                        Update Job
                    </button>
                </div>

            </div>

        </div>
        <div id="progressLoader">
            <progress-loader></progress-loader>
        </div>
    </div>

    <dashboard-widgets page="edit-job"></dashboard-widgets>

</div>
</template>

<script>
import { VueEditor } from "vue2-editor";
import { ApiService } from '@/services/api.service'
import Header from '@/include/Dashboard/Header.vue'
import Sidebar from '@/include/Dashboard/Sidebar.vue'
import DashboardWidgets from '@/components/DashboardWidgets'
import OpenChatBox from '@/include/Modals/OpenChatBox.vue'
import Feeds from '@/include/Dashboard/Feeds.vue'
import ProfileIcon from '@/include/Dashboard/Feed/ProfileIcon'
import ProgressLoader from '@/components/ProgressLoader'
import SingleFileUpload from '@/components/SingleFileUpload'
import axios from 'axios'

export default {
    name: 'EditJob',
    components: {
        Header,
        Sidebar,
        DashboardWidgets,
        OpenChatBox,
        Feeds,
        ProgressLoader,
        SingleFileUpload,
        VueEditor
    },

    data() {
        return {
            image: '',
            company_id: '',
            companies: [],
            job_role_id: '',
            jobRoles: [],
            min_salary: '',
            max_salary: '',
            image: '',
            job_type_id: '',
            jobTypes: [],
            description: '',
            job_role_name: '',
            company_name: '',
            company_location: '',
            company_website: '',
            tags: [],
            selectedTags: [],
            job_sector:'',
            jobSectors:'',
            application_link:'',
            job: '',
            currentUser: '',
        }
    },
    mounted: function () {
        this.getJobExtraInfo()
        this.currentUser = this.$store.getters['user/currentUser']
        this.getExtraInfo()
        let id = this.$route.params['id']
        this.getJob(id)
    },
    methods: {
        async getJobExtraInfo() {
            let apiResponse = await ApiService.GetJobExtraData();
            // console.log('getJobExtraInfo', apiResponse)
            if (apiResponse['status'] == 'success') {
                this.jobRoles = apiResponse['data']['job_roles']
                this.jobTypes = apiResponse['data']['job_types']
                this.companies = apiResponse['data']['job_companies']
                this.tags = apiResponse['data']['job_tags']
                this.jobSectors = apiResponse['data']['job_sectors']
            }
        },
        async getJob(id) {
            this.toggleProgress('show')
            let apiResponse = await ApiService.GetJobPost(id);
            if (apiResponse["status"] != "success") {} else {
                this.job = apiResponse["data"]['job_post'];
                if(this.job){
                    this.job_role_id = this.job['job_role_id']
                    this.company_id = this.job['job_company_id']
                    this.max_salary = this.job['max_salary']
                    this.min_salary = this.job['min_salary']
                    this.image = this.job['image']
                    this.description = this.job['description']
                    this.job_type_id = this.job['job_type_id']
                    this.application_link = this.job['application_link']
                }
                this.toggleProgress('hide')
            }
        },
        async getExtraInfo(){
            let apiResponse = await ApiService.ExtraInfo();
            // console.log('getExtraInfo',apiResponse)
            if(apiResponse['status'] == 'success'){
                this.postCategories = apiResponse['data']['post_categories']
            }
        },
        async updateJob() {
            this.toggleProgress('show')
            let apiResponse = await ApiService.UpdateJobPost(this.job['id'], this.job_role_id, this.company_id, this.max_salary, this.min_salary, this.image, this.description, this.job_type_id, this.application_link);
            this.$store.commit("toast/Notify", {
                type: apiResponse["status"],
                message: apiResponse["message"],
            });
            if (apiResponse["status"] != "success") {
            } else {
                this.job_role_id = ''
                this.company_id = ''
                this.max_salary = ''
                this.min_salary = ''
                this.image = ''
                this.description = ''
                this.job_type_id = ''
                this.selectedTags = ''
                this.application_link = ''

                location.href = '/dashboard/job/'+this.job['id']
            }
            this.toggleProgress('hide')
        },
        async deleteJob() {
            this.toggleProgress('show')
            let apiResponse = await ApiService.DeleteJob(this.job['id']);
            this.$store.commit("toast/Notify", {
                type: apiResponse["status"],
                message: apiResponse["message"],
            });
            if (apiResponse["status"] != "success") {
            } else {

                location.href = '/dashboard/jobs'
            }
            this.toggleProgress('hide')
        },
        onImageUploaded (response) {
            // Handle files like:
            // console.log('::::',response)
            if(response['status'] == 'success'){
                this.image = response['data']['file_name']
            }
            this.$store.commit("toast/Notify", {
                type: response["status"],
                message: response["message"],
            });
        },
        handleImageAdded: function(file, Editor, cursorLocation, resetUploader) {
            // An example of using FormData
            // NOTE: Your key could be different such as:
            // formData.append('file', file)

            var formData = new FormData();
            formData.append("file", file);

            axios({
                url: this.$store.getters['host/apiUrl'].replace('/api/v2', '/files/upload'),
                method: "POST",
                data: formData
            })
            .then(result => {
                // console.log('result',result)
                const url = result.data.data['file_name']; // Get url from response
                Editor.insertEmbed(cursorLocation, "image", url);
                resetUploader();
            })
            .catch(err => {
                console.log(err);
            });
        }
    },
}
</script>

<style lang="scss" scoped>
.some-modal-content {
    min-width: 400px;
    padding: 25px;

    .buttons button {
        padding: 10px;
        margin: 10px;
    }
}
.postImgheight {
    height:100px
}
.editor {
    height: 350px;
}
</style>
