<template>
<div id="wrapper">
    <auth-styles v-if="pageNameMain" :pageNameMain="pageNameMain" :pageDescriptionMain="pageDescriptionMain" :pageImageMain="pageImageMain"></auth-styles>

    <Header />

    <sidebar></sidebar>

    <!-- Main Contents -->
    <div class="main_content">
        <div class="mcontainer">
            <div class="bg-white mb-3 p-5 flex" v-if="job && currentUser['id'] == job['user_id']">
                <div class="mr-5 text-white">
                    <router-link :to="'/dashboard/edit-job/'+job.id" class="h-8 px-4 py-3 rounded-md text-sm text-white hover:text-white w-full bg-blue-700">Edit Job Post</router-link>
                </div>
                <div class="text-white">
                    <a @click="confirmDelete" class="h-8 px-4 py-3 rounded-md text-sm text-white hover:text-white w-full bg-red-700 mouse-pointer">Delete Job Post</a>
                </div>
            </div>

            <div class="lg:flex lg:space-x-12">

                <div class="lg:w-3/4 card mb-10" v-if="job">

                    <div class="h-44 md:h-60 overflow-hidden relative rounded-t-lg w-full">
                        <img v-if="job.image" :src="job.image" alt="" class="w-full h-full absolute inset-0 object-cover">
                        <img v-else :src="defaultPostUrl" alt="" class="w-full h-full absolute inset-0 object-cover">
                    </div>

                    <div class="p-10 pt-4 divide-y divide-gray-100">

                        <div class="md:py-8 py-3">
                            <h2 class="text-2xl font-bold"> {{ job.job_role.name }} </h2>
                            <p class="font-medium mt-1.5 text-base"> {{ job.company.name }} </p>
                        </div>

                        <div class="md:py-8 py-3">
                            <h1 class="block text-xl font-bold mb-9"> Job details </h1>
                            <div class="md:flex">
                                <div class="md:w-2/3 space-y-4 -mt-2">

                                    <div class="flex items-center space-x-3">
                                        <ion-icon name="unlink" class="bg-gray-100 p-1.5 rounded-full text-xl md hydrated" role="img" aria-label="unlink"></ion-icon>
                                        <div class="flex-1">
                                            <div> <a href="#" class="text-blue-500"> {{ job.company.location }} </a> </div>
                                        </div>
                                    </div>
                                    <div class="flex items-center space-x-3">
                                        <ion-icon name="mail-open" class="bg-gray-100 p-1.5 rounded-full text-xl md hydrated" role="img" aria-label="mail open"></ion-icon>
                                        <div class="flex-1">
                                            <div> {{ job.job_type.name }} </div>
                                        </div>
                                    </div>
                                    <div class="flex items-center space-x-3">
                                        <ion-icon name="mail-open" class="bg-gray-100 p-1.5 rounded-full text-xl md hydrated" role="img" aria-label="mail open"></ion-icon>
                                        <div class="flex-1">
                                            <div> Posted {{ timeAgo(job.created_at) }} ago </div>
                                        </div>
                                    </div>
                                    <div class="flex items-center space-x-3">
                                        <ion-icon name="unlink" class="bg-gray-100 p-1.5 rounded-full text-xl md hydrated" role="img" aria-label="unlink"></ion-icon>
                                        <div class="flex-1">
                                            <div> <a :href="job.company.website" class="text-blue-500" target="_blank"> {{ job.company.website }} </a> </div>
                                        </div>
                                    </div>
                                    <div class="flex items-center space-x-3">
                                        <ion-icon name="albums" class="bg-gray-100 p-1.5 rounded-full text-xl md hydrated" role="img" aria-label="albums"></ion-icon>
                                        <div class="flex-1">
                                            <div> <a href="#" class="text-blue-500">{{ job.company.sector }}</a> </div>
                                        </div>
                                    </div>

                                </div>

                                <div class="flex-1 space-y-4 md:mt-0 mt-6 text-sm font-medium">
                                    <div>
                                        <div class="font-semibold mb-2"> Minimum </div>
                                        <div class="text-gray-400"> <span class="text-2xl font-semibold text-black"> ${{ formatAmount(job.min_salary) }} </span> Per Month</div>
                                    </div>
                                    <div>
                                        <div class="font-semibold mb-2"> Maximum </div>
                                        <div class="text-gray-400"> <span class="text-2xl font-semibold text-black"> ${{ formatAmount(job.max_salary) }} </span> Per Month</div>
                                    </div>
                                    <a v-if="job.application_link" target="_blank" :href="job.application_link" class="bg-blue-600 text-white py-2.5 text-center font-semibold w-full mt-4 block rounded hover:text-white"> Apply now </a>
                                    <h4 v-else  class="bg-white p-2">No Application Link</h4>
                                </div>
                            </div>
                        </div>

                        <div class="md:py-8 py-3">
                            <h1 class="block text-2xl font-bold mb-6"> Job Description </h1>
                            <div class="space-y-3" v-html="job.description">
                            </div>
                        </div>

                    </div>

                </div>
                <div class="lg:w-1/4 w-full flex-shrink-0">

                    <div uk-sticky="media @m ; offset:80 ; bottom : true" class="uk-sticky">

                        <h2 class="text-xl font-semibold mb-2"> Recently Posted Jobs </h2>
                        <ul class="-space-y-2 card-mobile">
                            <li v-for="rj in recent_jobs">
                                <a :href="'/dashboard/job/'+rj.id" class="hover:bg-gray-100 rounded-md p-3 -mx-3 block">
                                    <h3 class="font-medium line-clamp-2">{{ rj.job_role.name }}</h3>
                                    <div class="flex font-medium items-center mt-1 space-x-1.5 text-xs">
                                        <div class="">{{ job.job_type.name }}</div>
                                        <div class="pb-1"> . </div>
                                        <div class="text-blue-500">${{ formatAmount(rj.min_salary) }} - ${{ formatAmount(rj.max_salary) }} / month</div>
                                    </div>
                                </a>
                            </li>
                        </ul>
                        <br>

                    </div>
                    <div class="uk-sticky-placeholder" style="height: 481px; margin: 0px;"></div>
                </div>

            </div>

        </div>
    </div>

    <progress-loader v-if="jobsPreloader == 'true'"></progress-loader>
    <dashboard-widgets page="job"></dashboard-widgets>

</div>
</template>

<script>
import {
    VueEditor
} from "vue2-editor";
import {
    ApiService
} from '@/services/api.service'
import Header from '@/include/Dashboard/Header.vue'
import Sidebar from '@/include/Dashboard/Sidebar.vue'
import DashboardWidgets from '@/components/DashboardWidgets'
import OpenChatBox from '@/include/Modals/OpenChatBox.vue'
import ProfileIcon from '@/include/Dashboard/Feed/ProfileIcon'
import ProgressLoader from '@/components/ProgressLoader'
import TimelineScreen from '@/include/Dashboard/Timeline/TimelineScreen'
import FriendScreen from '@/include/Dashboard/Timeline/FriendScreen'
import PhotoScreen from '@/include/Dashboard/Timeline/PhotoScreen'
import PageScreen from '@/include/Dashboard/Timeline/PageScreen'
import GroupScreen from '@/include/Dashboard/Timeline/GroupScreen'
import VideoScreen from '@/include/Dashboard/Timeline/VideoScreen'
import SingleFileUpload from '@/components/SingleFileUpload'
import CreatePhotoPost from '@/components/CreatePhotoPost'
import IonIcon from '@/components/IonIcon'
import axios from 'axios'

export default {
    name: 'Job',
    components: {
        Header,
        Sidebar,
        DashboardWidgets,
        OpenChatBox,
        ProgressLoader,
        VueEditor,
        TimelineScreen,
        FriendScreen,
        PhotoScreen,
        PageScreen,
        GroupScreen,
        VideoScreen,
        SingleFileUpload,
        CreatePhotoPost,
        IonIcon
    },
    data() {
        return {
            job: '',
            recent_jobs: [],
            tags: 0,
            pendingRequest: false,
            jobsPreloader: 'false',
            pageNameMain: '',
            pageDescriptionMain: '',
            pageImageMain: '',
            currentUser: '',
        }
    },
    mounted: function () {
        this.thisUser = this.$store.getters["user/currentUser"];
        this.currentUser = this.$store.getters['user/currentUser'] || {}
        let id = this.$route.params['id']
        this.id = id
        this.getJob(this.id)
    },
    methods: {
        async getJob(id) {
            if (this.pendingRequest == true) {
                // console.log('Pending Req')
                return
            }
            this.pendingRequest = true
            this.jobsPreloader = 'true'
            let apiResponse = await ApiService.GetJobPost(id)
            if (apiResponse['status'] != 'success') {} else {
                this.job = apiResponse['data']['job_post']
                this.recent_jobs = apiResponse['data']['recent_jobs']
            }
            this.pendingRequest = false
            this.jobsPreloader = 'false'
        },
        async deleteJobPost() {
            this.toggleProgress('show')
            let apiResponse = await ApiService.DeleteJobPost(this.job['id']);
            this.$store.commit("toast/Notify", {
                type: apiResponse["status"],
                message: apiResponse["message"],
            });
            if (apiResponse["status"] != "success") {} else {
                location.href = '/dashboard/jobs'
            }
        },
        confirmDelete() {
            let proceed = confirm("Are you sure you want to proceed?");
            if (proceed) {
                this.deleteJobPost()
            } else {
                //don't proceed
            }
        },
    },
}
</script>

<style>
.profilePage123 input {
    width: 157px !important;
    height: 32px !important;
}
</style>
