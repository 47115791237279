<template>
    <div id="wrapper">

        <HeaderNoAuth v-if="!currentUser" />
        <Header v-else />
    
        <sidebar></sidebar>
    
        <!-- Main Contents -->
        <div class="main_content">
            <div class="mcontainer">
    
                <!--  Feeds  -->
                <div class="lg:flex lg:space-x-10">
                    <div class="lg:w-3/4 lg:px-20 space-y-7">
                        <feed preview="false"></feed>
                    </div>
                </div>
    
            </div>
        </div>
    
    </div>
    </template>
    
    <script>
    import {
        ApiService
    } from "@/services/api.service";
    import Feed from '@/include/Dashboard/Feed'
    import Header from '@/include/Dashboard/Header.vue'
    import HeaderNoAuth from '@/include/Dashboard/HeaderNoAuth'
    import Sidebar from '@/include/Dashboard/Sidebar.vue'
    
    export default {
        name: 'SingleFeedPage',
        components: {
            Feed,
            Header,
            Sidebar,
            HeaderNoAuth,
        },
    
        data() {
            return {
                currentUser: '',
            }
        },
        mounted() {
            let currentUser = this.$store.getters["user/currentUser"] || "";
            this.currentUser = currentUser;
        },
    
        methods: {
        }
    }
    </script>
    
    <style lang="scss" scoped>
    .some-modal-content {
        min-width: 400px;
        padding: 25px;
    
        .buttons button {
            padding: 10px;
            margin: 10px;
        }
    }
    
    .single_story_div {
        width: 100%;
        height: 100%;
        position: absolute;
        padding: 10px;
        color: #fff;
        background-color: #333;
    }
    input[type=text]{
        -webkit-transition: all .2s ease-out;
        -moz-transition: all .2s ease-out;
        -ms-transition: all .2s ease-out;
        -o-transition: all .2s ease-out;
        transition: all .2s ease-out
    }
    input[type=text]:focus {
        -webkit-transition: all .2s ease-out;
        -moz-transition: all .2s ease-out;
        -ms-transition: all .2s ease-out;
        -o-transition: all .2s ease-out;
        transition: all .2s ease-out
    }
    </style>
    