<template>
<div v-if="thisFeed" :id="thisFeed.id" v-view.once="viewFeedOnce" class="card lg:mx-0 uk-animation-slide-bottom-small no-scroll" oldstyle="overflow: scroll">

    <h5 v-if="boosted == 'true'" class="lg:px-4 px-2.5 lg:pt-4 pt-2.5 text-italic">Boosted</h5>
    <h5 v-if="thisFeed.admin_pin == 'true'" class="lg:px-4 px-2.5 lg:pt-4 pt-2.5 text-italic">Featured</h5>
    <h5 v-else-if="thisFeed.user_pin == 'true'" class="lg:px-4 px-2.5 lg:pt-4 pt-2.5 text-italic">Pinned</h5>
    <!-- post header-->
    <div class="flex justify-between items-center lg:p-4 p-2.5">
        <div class="flex flex-1 items-center space-x-4">
            <div uk-lightbox="">
                <img v-if="!thisFeed" src="/assets/img/avatar.png" class="bg-gray-200 border border-white rounded-full w-10 h-10" alt="VT">
                <template v-else>
                    <template v-if="thisFeed.post_as == 'page'">
                        <profile-icon :url="thisFeed.page.image"></profile-icon>
                    </template>
                    <template v-else-if="thisFeed.post_as == 'group'">
                        <profile-icon :url="thisFeed.group.image"></profile-icon>
                    </template>
                    <template v-else>
                        <profile-icon v-if="thisFeed.user" :url="thisFeed.user.profile_photo"></profile-icon>
                        <img v-else src="/assets/img/avatar.png" class="bg-gray-200 border border-white rounded-full w-10 h-10" alt="VT">
                    </template>
                </template>
            </div>
            <div class="flex-1 font-semibold capitalize">
                <template v-if="thisFeed.post_as == 'page'">
                    <a v-if="thisFeed.page" :href="'/dashboard/page/'+thisFeed.page.id" class="text-gray-700 dark:text-gray-100">
                        {{ thisFeed.page.name }}
                        <verified-badge v-if="thisFeed.page.verified == 'true'" size="small" mt="minusZeroPointFive"></verified-badge>
                    </a>
                </template>
                <template v-else-if="thisFeed.post_as == 'group'">
                    <a v-if="thisFeed.group" :href="'/dashboard/group/'+thisFeed.group.id" class="text-gray-700 dark:text-gray-100">
                        {{ thisFeed.group.name }}
                        <verified-badge v-if="thisFeed.group.verified == 'true'" size="small" mt="minusZeroPointFive"></verified-badge>
                    </a>
                </template>
                <template v-else>
                    <a v-if="thisFeed.user" :href="'/dashboard/timeline/'+thisFeed.user.id" class="text-gray-700 dark:text-gray-100">
                        {{ thisFeed.user.name }}
                        <verified-badge v-if="thisFeed.user.verified == 'true'" size="small" mt="minusZeroPointFive"></verified-badge>
                    </a>
                </template>
                <div v-if="thisFeed.content_updated" class="text-gray-400 flex items-center space-x-2 mt-1" style="font-size: 13px;">
                    <span>
                        <i class="fa fa-pen"></i>
                        {{ feedTimeManager(thisFeed.content_updated) }}
                    </span>
                </div>
                <div v-else class="text-gray-400 flex items-center space-x-2 mt-1" style="font-size: 13px;"> 
                    {{ feedTimeManager(thisFeed.created_at) }}
                </div>
            </div>
        </div>
        <p v-if="currentUser.id != thisFeed.user_id && thisFeed.post_as != 'group' && thisFeed.post_as != 'page'">
            <button v-if="!thisFeed.user_follow" @click="followUser(thisFeed.user_id)" :class="'button text-black border-black flex-1 block mr-2 '+thisFeed.user_id" style="padding: 0 15px;background-color: #f3f4f6">
                <i class="fa fa-user-plus"></i>
            </button>
        </p>
        <div>
            <template v-if="currentUser.id == thisFeed.user_id || is_admin == 'true'">
                <a @click="togglePopover('popover_holder_'+thisFeed.id)" class="feedModal" href="javascript:;">
                    <i class="icon-feather-more-vertical text-2xl hover:bg-gray-200 rounded-full p-2 transition -mr-1 dark:hover:bg-gray-700"></i>
                </a>
                <div class="popover_holder" :id="'popover_holder_'+thisFeed.id" @click="togglePopover('popover_holder_'+thisFeed.id)">
                    <div meclick="closeFeedModal('#feedModalContent'+thisFeed.id)" :id="'feedModalContent'+thisFeed.id" class="feedModalContent bg-white w-56 shadow-md mx-auto p-2 mt-12 rounded-md text-gray-500 hidden-old text-base border border-gray-100 dark:bg-gray-900 dark:text-gray-100 dark:border-gray-700 mb-3" uk-drop-old="mode: click;shift: false; flip: false;pos: bottom-right;animation: uk-animation-slide-bottom-small;boundary: !.main_content">

                        <ul class="space-y-1">
                            <!-- <li>
                                    <a uk-toggle="target: #share-post-modal" @click="setCurrentLink(thisFeed.id, '')" href="javascript:;" class="flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800">
                                        <i class="uil-share-alt mr-1"></i> Share
                                    </a>
                                </li> -->
                            <template v-if="currentUser.id != thisFeed.user_id">
                                <li>
                                    <a @click="triggerReportContent('feed', thisFeed.id)" uk-toggle="target: #report-content-modal" href="javascript:;" class="flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800">
                                        <i class="icon-feather-alert-octagon mr-1"></i> Report
                                    </a>
                                </li>
                                <li>
                                    <a @click="userBlockFeed()" href="javascript:;" class="flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800">
                                        <i class="icon-feather-alert-octagon mr-1"></i> Remove
                                    </a>
                                </li>
                            </template>
                            <template v-if="currentUser.id == thisFeed.user_id">
                                <li>
                                    <a v-if="currentUser.id == thisFeed.user_id" href="javascript:;" @click="triggerFeedEdit(thisFeed)" uk-toggle="target: #edit-feed-post-modal" class="flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800">
                                        <i class="uil-edit-alt mr-1"></i> Edit Post
                                    </a>
                                </li>
                                <li v-if="thisFeed.comment_disabled == 'true'">
                                    <a @click="confirmEnableFeedComment" href="javascript:;" class="flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800">
                                        <i class="uil-comment-slash mr-1"></i> Enable comments
                                    </a>
                                </li>
                                <li v-else>
                                    <a @click="confirmDisableFeedComment" v-if="currentUser.id == thisFeed.user_id" href="javascript:;" class="flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800">
                                        <i class="uil-comment-slash mr-1"></i> Disable comments
                                    </a>
                                </li>
                                <template v-if="thisFeed.boost == 'true'">
                                    <li class="d-none">
                                        <a @click="unboostFeed(thisFeed.id)" v-if="currentUser.id == thisFeed.user_id" uk-toggle="target: #feed-insight-modal" href="javascript:;" class="flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800">
                                            <i class="fa fa-rocket mr-1"></i> Unboost Feed
                                        </a>
                                    </li>
                                </template>
                                <li class="d-none" v-else>
                                    <a @click="boostFeed(thisFeed.id)" v-if="currentUser.id == thisFeed.user_id" href="javascript:;" class="flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800">
                                        <i class="fa fa-rocket mr-1"></i> Boost Feed
                                    </a>
                                </li>
                                <template v-if="thisFeed.allow_donation == 'true'">
                                    <li class="">
                                        <a @click="viewFeedDonations(thisFeed.id)" v-if="currentUser.id == thisFeed.user_id" uk-toggle="target: #feed-donations-modal" href="javascript:;" class="flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800">
                                            <i class="fa fa-bar-chart mr-1"></i> View Donations
                                        </a>
                                    </li>
                                </template>
                                <li>
                                    <a @click="viewFeedInsight(thisFeed.id)" v-if="currentUser.id == thisFeed.user_id" uk-toggle="target: #feed-insight-modal" href="javascript:;" class="flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800">
                                        <i class="fa fa-bar-chart mr-1"></i> View Boost Insights
                                    </a>
                                </li>
                                <li v-if="thisFeed.user_pin == 'true'">
                                    <a @click="userUnpinFeed" href="javascript:;" class="flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800">
                                        <i class="uil-unlock mr-1"></i> Unpin Feed
                                    </a>
                                </li>
                                <li v-else>
                                    <a @click="userPinFeed" href="javascript:;" class="flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800">
                                        <i class="uil-lock mr-1"></i> Pin Feed
                                    </a>
                                </li>
                                <li>
                                    <hr class="-mx-2 my-2 dark:border-gray-800">
                                </li>
                                <li>
                                    <a v-if="currentUser.id == thisFeed.user_id" @click="deleteFeed" href="javascript:;" class="flex items-center px-3 py-2 text-red-500 hover:bg-red-100 hover:text-red-500 rounded-md dark:hover:bg-red-600">
                                        <i class="uil-trash-alt mr-1"></i> Delete
                                    </a>
                                </li>
                            </template>
                            <template v-if="is_admin == 'true'">
                                <li v-if="thisFeed.delisted != 'true'">
                                    <a @click="adminDelistFeed" href="javascript:;" class="flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800">
                                        <i class="uil-unlock mr-1"></i> Admin Delist Feed
                                    </a>
                                </li>
                                <li v-if="thisFeed.admin_pin == 'true'">
                                    <a @click="adminUnpinFeed" href="javascript:;" class="flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800">
                                        <i class="uil-unlock mr-1"></i> Admin Unpin Feed
                                    </a>
                                </li>
                                <li v-else>
                                    <a @click="adminPinFeed" href="javascript:;" class="flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800">
                                        <i class="uil-lock mr-1"></i> Admin Pin Feed
                                    </a>
                                </li>
                                <li v-if="thisFeed.allow_donation == 'true'">
                                    <a @click="adminDisableFeedDonation" href="javascript:;" class="flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800">
                                        <i class="uil-unlock mr-1"></i> Disabled Donation
                                    </a>
                                </li>
                                <li v-else>
                                    <a @click="adminEnableFeedDonation" href="javascript:;" class="flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800">
                                        <i class="uil-lock mr-1"></i> Enable Donation
                                    </a>
                                </li>
                                <li>
                                    <a @click="adminDeleteFeed" href="javascript:;" class="flex items-center px-3 py-2 text-red-500 hover:bg-red-100 hover:text-red-500 rounded-md dark:hover:bg-red-600">
                                        <i class="uil-lock mr-1"></i> Admin Delete Feed
                                    </a>
                                </li>
                            </template>
                            <!-- <li>
                                            <a href="#" class="flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800">
                                                <i class="uil-favorite mr-1"></i> Add favorites
                                            </a>
                                        </li> -->
                        </ul>

                    </div>
                </div>
            </template>
            <template v-else>
                <a @click="togglePopover('popover_holder_'+thisFeed.id)" class="feedModal" href="javascript:;">
                    <i class="icon-feather-more-vertical text-2xl hover:bg-gray-200 rounded-full p-2 transition -mr-1 dark:hover:bg-gray-700"></i>
                </a>
                <div class="popover_holder" :id="'popover_holder_'+thisFeed.id" @click="togglePopover('popover_holder_'+thisFeed.id)">
                    <div meclick="closeFeedModal('#feedModalContent'+thisFeed.id)" :id="'feedModalContent'+thisFeed.id" class="feedModalContent bg-white w-56 shadow-md mx-auto p-2 mt-12 rounded-md text-gray-500 hidden-old text-base border border-gray-100 dark:bg-gray-900 dark:text-gray-100 dark:border-gray-700 mb-3" uk-drop-old="mode: click;shift: false; flip: false;pos: bottom-right;animation: uk-animation-slide-bottom-small;boundary: !.main_content">

                        <ul class="space-y-1">
                            <li>
                                <a @click="triggerReportContent('feed', thisFeed.id)" uk-toggle="target: #report-content-modal" href="javascript:;" class="flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800">
                                    <i class="icon-feather-alert-octagon mr-1"></i> Report
                                </a>
                            </li>
                            <li>
                                <a @click="userBlockFeed()" href="javascript:;" class="flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800">
                                    <i class="icon-feather-alert-octagon mr-1"></i> Remove
                                </a>
                            </li>
                        </ul>

                    </div>
                </div>
            </template>
        </div>
    </div>

    <div class="py-2 px-4 leading-6" :style="feedTextBackground" thisFeed.template>
        <div class="d-inline" style="white-space: pre-wrap;" v-html="feedContent"></div>
        <p v-if="show_more_less_button == 'true'" class="d-inline">
            <a class="custom_link" v-if="is_less == 'false'" href="javascript:;" @click="lessChange('true')"> show less</a>
            <a class="custom_link" v-else href="javascript:;" @click="lessChange('false')"> ... show more</a>
        </p>
    </div>

    <template v-if="thisFeed.feed_shared_from">
        <template v-if="loadingOF == false">
            <shared-feed v-if="originalFeed" :feed="originalFeed" :feedOwner="originalFeed.user"></shared-feed>
        </template>
        <template v-else></template>
    </template>

    <images-component :feed="thisFeed" />

    <audios-component :feed="thisFeed" />

    <videos-component :feed="thisFeed" />
    
    <div class="mx-4" v-if="thisFeed.allow_donation == 'true'">
        <div class="percent_holder" v-if="thisFeed.donation_target">
            <p class="percent_marker" :style="'width:'+raised_percent+'%'"></p>
        </div>
        <p>
            {{ currencySymbol('USD') }}
            <span v-if="total_donation_amount && total_donation_amount > 0">{{ total_donation_amount.toFixed(2).toLocaleString() }}</span>
            <span v-else>{{ total_donation_amount }}</span>
            <span v-if="thisFeed.donation_target && thisFeed.donation_target != ''">/{{ currencySymbol('USD') }}{{ donation_target.toFixed(2).toLocaleString() }}</span>
            
            <span v-if="thisFeed.donation_target && thisFeed.donation_target != ''"> Raised</span>
            <span v-else> Raised so far</span>
        </p>
    </div>

    <comments-manager v-if="thisFeed && thisFeed.comments" :feed="thisFeed" :preview="preview" :currency="currency" :can_show_ad="can_show_ad"></comments-manager>

    <share-post-modal v-if="shareLink" :link="shareLink" :description="shareDescription" :feed="thisFeed"></share-post-modal>
    <feed-insight-modal v-if="feed_id_insight" :feed_id="feed_id_insight"></feed-insight-modal>
    <feed-donations-modal v-if="feed_id_donation" :feed_id="feed_id_donation"></feed-donations-modal>
    <feed-likers-modal v-if="thisFeed && likers" :likers="likers"></feed-likers-modal>

</div>
</template>

<script>
import {
    ApiService
} from '@/services/api.service'
import ProfileIcon from '@/include/Dashboard/Feed/ProfileIcon'
import SharePostModal from '@/include/Modals/SharePostModal'
import FeedInsightModal from '@/include/Modals/FeedInsightModal'
import AudioPlayerComponent from '@/components/AudioPlayerComponent'
import VideoPlayerComponent from '@/components/VideoPlayerComponent'
import GoogleBannerAd from '@/include/Dashboard/Feed/GoogleBannerAd'
import VerifiedBadge from '@/components/VerifiedBadge'
import FeedLikersModal from '@/include/Modals/FeedLikersModal'
import SharedFeed from '@/include/Dashboard/Feed/SharedFeed'
import ImagesComponent from '@/components/Feed/ImagesComponent'
import AudiosComponent from '@/components/Feed/AudiosComponent'
import VideosComponent from '@/components/Feed/VideosComponent'
import CommentsManager from '@/components/Feed/CommentsManager'
import FeedDonationsModal from '@/include/Modals/FeedDonationsModal'

export default {
    name: "UserFeed",
    props: {
        feed: Object,
        is_admin: String,
        preview: String,
        boosted: String,
        user_currency: String,
        can_show_ad: String
    },
    data() {
        return {
            feedContent: '',
            reply: '',
            replyReply: '',
            comment: '',
            comments: '',
            noOfPreviewComments: 0,
            unviewedComments: 0,
            currentUser: '',
            thisFeed: '',
            shareLink: '',
            feed_id_insight: '',
            shareDescription: '',
            show_more_less_button: 'false',
            is_less: 'true',
            is_less_image: 'true',
            is_less_audio: 'true',
            is_less_video: 'true',
            image_length: 5,
            audio_length: 1,
            video_length: 1,
            likers: '',
            feedTextBackground: '',
            feed_id_donation: '',
            userFeedKey: '',
            currency: '',
            total_donation_amount: 0,
            donation_target: 0,
            raised_percent: 0,
            originalFeed: '',
            loadingOF: false,
        };
    },
    components: {
        ProfileIcon,
        SharePostModal,
        FeedInsightModal,
        AudioPlayerComponent,
        VideoPlayerComponent,
        GoogleBannerAd,
        VerifiedBadge,
        FeedLikersModal,
        SharedFeed,
        ImagesComponent,
        AudiosComponent,
        VideosComponent,
        CommentsManager,
        FeedDonationsModal,
    },
    mounted () {
        this.resetDropdownKey()
        let vueThis = this
        this.currentUser = this.$store.getters["user/currentUser"] || '';
        if(this.feed['user']){
            this.manageFeedTemplate(this.feed)
            this.thisFeed = this.feed
            this.currency = 'usd'
            if(this.user_currency){
                this.currency = this.user_currency
            }

            this.moreLessManager()
            this.otherFeedprocess()
        }else{
            this.getThisFeed(this.feed['id'])
        }
        this.$root.$on("refresh-feed",(data) => {
            // console.log("refresh-feed", data)
            if(data['feed_id'] && data['feed_id'] == vueThis.thisFeed.id){
                this.getFeed()
                // console.log("refreshed feed", data)
            }
        })
        this.$root.$on("page-scrolled",(data) => {
            let ddState = ''
            if($('#popover_holder_'+this.thisFeed.id)){
                ddState = $('#popover_holder_'+this.thisFeed.id).css('display')
                if(ddState == 'block'){
                    this.togglePopover('popover_holder_'+this.thisFeed.id)
                }
            }
        })
        // console.log('=========Preview', this.preview, this.noOfPreviewComments)
    },
    methods: {
        async getThisFeed(id) {
            this.loading = "true";
            let apiResponse = await ApiService.GetFeed(id);
            if (apiResponse["status"] != "success") {
            } else {
                this.thisFeed = apiResponse['data']
                this.currency = apiResponse['currency']

                this.manageFeedTemplate(this.thisFeed)
                this.otherFeedprocess()
            }
            this.loading = "false";
        },
        async getOriginalFeed(id) {
            this.loadingOF = true;
            let apiResponse = await ApiService.GetFeed(id);
                // console.log('============GetFeed',apiResponse)
            if (apiResponse["status"] != "success") {
            } else {
                // console.log('============this.thisFeed.feed_shared_from',this.originalFeed)
                this.originalFeed = apiResponse['data']
            }
            this.loadingOF = false;
        },
        async getFeedLikes() {
            this.loading = "true";
            let apiResponse = await ApiService.FeedLikes(this.feed.id);
            if (apiResponse["status"] != "success") {
                // this.$store.commit("toast/Notify", {
                // type: apiResponse["status"],
                // message: apiResponse["message"],
                // });
            } else {
                if (apiResponse["data"] && apiResponse["data"].length > 0) {
                    this.likers = apiResponse["data"];
                }
            }
            this.loading = "false";
        },
        async addComment(id) {
            this.toggleProgress('show')
            let apiResponse = await ApiService.AddComment(id, this.comment)
            if (apiResponse['status'] != 'success') {
                this.$store.commit("toast/Notify", {
                    type: apiResponse["status"],
                    message: apiResponse["message"],
                });
            } else {
                this.comment = ''
                this.thisFeed = apiResponse['data']
                this.commentChecker()
            }
            this.toggleProgress('hide')
        },
        async likeFeed(type) {
            this.toggleProgress('show')
            let apiResponse = await ApiService.LikeFeed(this.thisFeed.id, type)
            if (apiResponse['status'] != 'success') {
                this.$store.commit("toast/Notify", {
                    type: apiResponse["status"],
                    message: apiResponse["message"],
                });
            } else {
                this.thisFeed = apiResponse['data']
                this.commentChecker()
            }
            this.toggleProgress('hide')
        },
        async unlikeFeed() {
            this.toggleProgress('show')
            let apiResponse = await ApiService.UnlikeFeed(this.thisFeed.id)
            if (apiResponse['status'] != 'success') {
                this.$store.commit("toast/Notify", {
                    type: apiResponse["status"],
                    message: apiResponse["message"],
                });
            } else {
                this.thisFeed = apiResponse['data']
                this.commentChecker()
            }
            this.toggleProgress('hide')
        },
        async likeComment(comment_id, type) {
            this.toggleProgress('show')
            let apiResponse = await ApiService.LikeComment(this.thisFeed.id, comment_id, type)
            if (apiResponse['status'] != 'success') {
                this.$store.commit("toast/Notify", {
                    type: apiResponse["status"],
                    message: apiResponse["message"],
                });
            } else {
                this.thisFeed = apiResponse['data']
                this.commentChecker()
            }
            this.toggleProgress('hide')
        },
        async unlikeComment(comment_id) {
            this.toggleProgress('show')
            let apiResponse = await ApiService.UnlikeComment(this.thisFeed.id, comment_id)
            if (apiResponse['status'] != 'success') {
                this.$store.commit("toast/Notify", {
                    type: apiResponse["status"],
                    message: apiResponse["message"],
                });
            } else {
                this.thisFeed = apiResponse['data']
                this.commentChecker()
            }
            this.toggleProgress('hide')
        },
        async shareAction(id) {
            this.toggleProgress('show')
            let apiResponse = await ApiService.ShareFeed(this.thisFeed.id)
            if (apiResponse['status'] != 'success') {} else {}
            this.$store.commit("toast/Notify", {
                type: apiResponse["status"],
                message: apiResponse["message"],
            });
            this.toggleProgress('hide')
        },
        async replyComment(comment_id) {
            this.toggleProgress('show')
            let apiResponse = await ApiService.ReplyComment(this.thisFeed.id, comment_id, this.reply)
            if (apiResponse['status'] != 'success') {
                this.$store.commit("toast/Notify", {
                    type: apiResponse["status"],
                    message: apiResponse["message"],
                });
            } else {
                this.reply = ''
                this.thisFeed = apiResponse['data']
                this.commentChecker()
            }
            this.toggleProgress('hide')
        },
        async replyCommentReply(feed_id, comment_id, reply_id) {
            this.toggleProgress('show')
            let apiResponse = await ApiService.ReplyCommentReply(feed_id, comment_id, reply_id, this.replyReply)
            if (apiResponse['status'] != 'success') {
                this.$store.commit("toast/Notify", {
                    type: apiResponse["status"],
                    message: apiResponse["message"],
                });
            } else {
                this.replyReply = ''
                this.thisFeed = apiResponse['data']
                this.commentChecker()
            }
            this.toggleProgress('hide')
        },
        async likeCommentReply(reply_id, type) {
            this.toggleProgress('show')
            let apiResponse = await ApiService.LikeCommentReply(this.thisFeed.id, reply_id, type)
            if (apiResponse['status'] != 'success') {
                this.$store.commit("toast/Notify", {
                    type: apiResponse["status"],
                    message: apiResponse["message"],
                });
            } else {
                this.thisFeed = apiResponse['data']
                this.commentChecker()
            }
            this.toggleProgress('hide')
        },
        async unlikeCommentReply(reply_id) {
            this.toggleProgress('show')
            let apiResponse = await ApiService.UnlikeCommentReply(this.thisFeed.id, reply_id)
            if (apiResponse['status'] != 'success') {
                this.$store.commit("toast/Notify", {
                    type: apiResponse["status"],
                    message: apiResponse["message"],
                });
            } else {
                this.thisFeed = apiResponse['data']
                this.commentChecker()
            }
            this.toggleProgress('hide')
        },
        async likeCommentReplyReply(reply_id, type) {
            this.toggleProgress('show')
            let apiResponse = await ApiService.LikeCommentReplyReply(this.thisFeed.id, reply_id, type)
            if (apiResponse['status'] != 'success') {
                this.$store.commit("toast/Notify", {
                    type: apiResponse["status"],
                    message: apiResponse["message"],
                });
            } else {
                this.thisFeed = apiResponse['data']
                this.commentChecker()
            }
            this.toggleProgress('hide')
        },
        async unlikeCommentReplyReply(reply_id) {
            this.toggleProgress('show')
            let apiResponse = await ApiService.UnlikeCommentReplyReply(this.thisFeed.id, reply_id)
            if (apiResponse['status'] != 'success') {
                this.$store.commit("toast/Notify", {
                    type: apiResponse["status"],
                    message: apiResponse["message"],
                });
            } else {
                this.thisFeed = apiResponse['data']
                this.commentChecker()
            }
            this.toggleProgress('hide')
        },
        async enableFeedComment() {
            this.toggleProgress('show')
            let apiResponse = await ApiService.EnableFeedComment(this.thisFeed.id)
            if (apiResponse['status'] != 'success') {
                this.$store.commit("toast/Notify", {
                    type: apiResponse["status"],
                    message: apiResponse["message"],
                });
            } else {
                this.thisFeed = apiResponse['data']
                this.commentChecker()
            }
            this.toggleProgress('hide')
        },
        async disableFeedComment() {
            this.toggleProgress('show')
            let apiResponse = await ApiService.DisableFeedComment(this.thisFeed.id)
            if (apiResponse['status'] != 'success') {
                this.$store.commit("toast/Notify", {
                    type: apiResponse["status"],
                    message: apiResponse["message"],
                });
            } else {
                this.thisFeed = apiResponse['data']
                this.commentChecker()
            }
            this.toggleProgress('hide')
        },
        async boostFeed(id) {
            this.toggleProgress('show')
            let apiResponse = await ApiService.BoostFeed(this.thisFeed.id)
            if (apiResponse['status'] != 'success') {
                this.$store.commit("toast/Notify", {
                    type: apiResponse["status"],
                    message: apiResponse["message"],
                });
            } else {
                this.thisFeed = apiResponse['data']
                this.commentChecker()
            }
            this.toggleProgress('hide')
        },
        async unboostFeed(id) {
            this.toggleProgress('show')
            let apiResponse = await ApiService.UnboostFeed(this.thisFeed.id)
            if (apiResponse['status'] != 'success') {
                this.$store.commit("toast/Notify", {
                    type: apiResponse["status"],
                    message: apiResponse["message"],
                });
            } else {
                this.thisFeed = apiResponse['data']
                this.commentChecker()
            }
            this.toggleProgress('hide')
        },
        async deleteFeedAudio(audio_id) {
            this.toggleProgress('show')
            let apiResponse = await ApiService.DeleteFeedAudio(this.thisFeed.id, audio_id)
            if (apiResponse['status'] != 'success') {
                this.$store.commit("toast/Notify", {
                    type: apiResponse["status"],
                    message: apiResponse["message"],
                });
            } else {
                this.thisFeed = apiResponse['data']
                this.commentChecker()
            }
            this.toggleProgress('hide')
        },
        async deleteFeedImage(image_id) {
            this.toggleProgress('show')
            let apiResponse = await ApiService.DeleteFeedImage(this.thisFeed.id, image_id)
            if (apiResponse['status'] != 'success') {
                this.$store.commit("toast/Notify", {
                    type: apiResponse["status"],
                    message: apiResponse["message"],
                });
            } else {
                this.thisFeed = apiResponse['data']
                this.commentChecker()
            }
            this.toggleProgress('hide')
        },
        async deleteFeedVideo(video_id) {
            this.toggleProgress('show')
            let apiResponse = await ApiService.DeleteFeedVideo(this.thisFeed.id, video_id)
            if (apiResponse['status'] != 'success') {
                this.$store.commit("toast/Notify", {
                    type: apiResponse["status"],
                    message: apiResponse["message"],
                });
            } else {
                this.thisFeed = apiResponse['data']
                this.commentChecker()
            }
            this.toggleProgress('hide')
        },
        async deleteFeedComment(comment_id) {
            this.toggleProgress('show')
            let apiResponse = await ApiService.DeleteFeedComment(this.thisFeed.id, comment_id)
            if (apiResponse['status'] != 'success') {
                this.$store.commit("toast/Notify", {
                    type: apiResponse["status"],
                    message: apiResponse["message"],
                });
            } else {
                this.thisFeed = apiResponse['data']
                this.commentChecker()
            }
            this.toggleProgress('hide')
        },
        async deleteFeedCommentReply(reply_id) {
            this.toggleProgress('show')
            let apiResponse = await ApiService.DeleteFeedCommentReply(this.thisFeed.id, reply_id)
            if (apiResponse['status'] != 'success') {
                this.$store.commit("toast/Notify", {
                    type: apiResponse["status"],
                    message: apiResponse["message"],
                });
            } else {
                this.thisFeed = apiResponse['data']
                this.commentChecker()
            }
            this.toggleProgress('hide')
        },
        async deleteFeedCommentReplyReply(reply_id) {
            this.toggleProgress('show')
            let apiResponse = await ApiService.DeleteFeedCommentReplyReply(this.thisFeed.id, reply_id)
            if (apiResponse['status'] != 'success') {
                this.$store.commit("toast/Notify", {
                    type: apiResponse["status"],
                    message: apiResponse["message"],
                });
            } else {
                this.thisFeed = apiResponse['data']
                this.commentChecker()
            }
            this.toggleProgress('hide')
        },
        async deleteFeed() {
            this.toggleProgress('show')
            let apiResponse = await ApiService.DeleteFeed(this.thisFeed.id)
            if (apiResponse['status'] != 'success') {} else {
                location.href = ''
            }
            this.$store.commit("toast/Notify", {
                type: apiResponse["status"],
                message: apiResponse["message"],
            });
            this.toggleProgress('hide')
        },
        async viewFeedOnce(e) {
            if (this.currentUser) {
                let apiResponse = await ApiService.ViewFeed(this.thisFeed.id)
            } else {
                await ApiService.ViewFeedPublic(this.thisFeed.id)
            }
            // console.log("viewFeedOnce", apiResponse)
        },
        async userBlockFeed() {
            this.toggleProgress('show')
            let apiResponse = await ApiService.UserBlockFeed(this.thisFeed.id)
            if (apiResponse['status'] != 'success') {
                this.$store.commit("toast/Notify", {
                    type: apiResponse["status"],
                    message: apiResponse["message"],
                });
            } else {
                this.thisFeed = ''
                this.commentChecker()
            }
            this.toggleProgress('hide')
        },
        async userPinFeed() {
            this.toggleProgress('show')
            let apiResponse = await ApiService.UserPinFeed(this.thisFeed.id)
            if (apiResponse['status'] != 'success') {
                this.$store.commit("toast/Notify", {
                    type: apiResponse["status"],
                    message: apiResponse["message"],
                });
            } else {
                this.thisFeed = apiResponse['data']
                this.commentChecker()
            }
            this.toggleProgress('hide')
        },
        async userUnpinFeed() {
            this.toggleProgress('show')
            let apiResponse = await ApiService.UserUnpinFeed(this.thisFeed.id)
            if (apiResponse['status'] != 'success') {
                this.$store.commit("toast/Notify", {
                    type: apiResponse["status"],
                    message: apiResponse["message"],
                });
            } else {
                this.thisFeed = apiResponse['data']
                this.commentChecker()
            }
            this.toggleProgress('hide')
        },
        async adminDelistFeed() {
            this.toggleProgress('show')
            let apiResponse = await ApiService.AdminDelistFeed(this.thisFeed.id)
            if (apiResponse['status'] != 'success') {
                this.$store.commit("toast/Notify", {
                    type: apiResponse["status"],
                    message: apiResponse["message"],
                });
            } else {
                this.thisFeed = ''
                this.commentChecker()
            }
            this.toggleProgress('hide')
        },
        async adminPinFeed() {
            this.toggleProgress('show')
            let apiResponse = await ApiService.AdminPinFeed(this.thisFeed.id)
            if (apiResponse['status'] != 'success') {
                this.$store.commit("toast/Notify", {
                    type: apiResponse["status"],
                    message: apiResponse["message"],
                });
            } else {
                this.thisFeed = apiResponse['data']
                this.commentChecker()
            }
            this.toggleProgress('hide')
        },
        async adminUnpinFeed() {
            this.toggleProgress('show')
            let apiResponse = await ApiService.AdminUnpinFeed(this.thisFeed.id)
            if (apiResponse['status'] != 'success') {
                this.$store.commit("toast/Notify", {
                    type: apiResponse["status"],
                    message: apiResponse["message"],
                });
            } else {
                this.thisFeed = apiResponse['data']
                this.commentChecker()
            }
            this.toggleProgress('hide')
        },
        async adminEnableFeedDonation() {
            this.toggleProgress('show')
            let apiResponse = await ApiService.AdminEnableFeedDonation(this.thisFeed.id)
            if (apiResponse['status'] != 'success') {
                this.$store.commit("toast/Notify", {
                    type: apiResponse["status"],
                    message: apiResponse["message"],
                });
            } else {
                this.thisFeed = apiResponse['data']
                this.commentChecker()
            }
            this.toggleProgress('hide')
        },
        async adminDisableFeedDonation() {
            this.toggleProgress('show')
            let apiResponse = await ApiService.AdminDisableFeedDonation(this.thisFeed.id)
            if (apiResponse['status'] != 'success') {
                this.$store.commit("toast/Notify", {
                    type: apiResponse["status"],
                    message: apiResponse["message"],
                });
            } else {
                this.thisFeed = apiResponse['data']
                this.commentChecker()
            }
            this.toggleProgress('hide')
        },
        async adminDeleteFeed() {
            this.toggleProgress('show')
            let apiResponse = await ApiService.AdminDeleteFeed(this.thisFeed.id)
            if (apiResponse['status'] != 'success') {
                this.$store.commit("toast/Notify", {
                    type: apiResponse["status"],
                    message: apiResponse["message"],
                });
            } else {
                this.thisFeed = apiResponse['data']
                this.commentChecker()
            }
            this.toggleProgress('hide')
        },
        async followUser(id) {
            this.toggleProgress('show')
            let apiResponse = await ApiService.FollowUser(id);
            // console.log('FollowUser', apiResponse)
            this.$store.commit("toast/Notify", {
                type: apiResponse["status"],
                message: apiResponse["message"],
            });
            if (apiResponse['status'] == 'success') {
                this.thisFeed = apiResponse['data']
                // hide other buttons about folowing this user
                $("." + id).hide()
            }
            this.toggleProgress('hide')
        },
        async getFeed(){
            let apiResponse = await ApiService.GetFeed(this.thisFeed.id)
            if(apiResponse['status'] != 'success'){
                this.thisFeed = ''
            }else{
                this.thisFeed = apiResponse['data']
                this.currency = apiResponse['currency']
                // this.resetDropdownKey()
            }
        },
        viewFeedInsight(feed_id_insight) {
            this.feed_id_insight = feed_id_insight
            this.$root.$emit("open-feed-insight-modal", feed_id_insight)
        },
        viewFeedDonations(feed_id_donation) {
            this.feed_id_donation = feed_id_donation
            this.$root.$emit("open-feed-donations-modal", feed_id_donation)
        },
        confirmEnableFeedComment(audio_id) {
            let proceed = confirm("Are you sure you want to Enable Feed Comments?");
            if (proceed) {
                this.enableFeedComment(audio_id)
            } else {
                //don't proceed
            }
        },
        confirmDisableFeedComment(audio_id) {
            let proceed = confirm("Are you sure you want to Disable Feed Comments?");
            if (proceed) {
                this.disableFeedComment(audio_id)
            } else {
                //don't proceed
            }
        },
        confirmDeleteFeedAudio(audio_id) {
            let proceed = confirm("Are you sure you want to delete this Feed Audio?");
            if (proceed) {
                this.deleteFeedAudio(audio_id)
            } else {
                //don't proceed
            }
        },
        confirmDeleteFeedImage(image_id) {
            let proceed = confirm("Are you sure you want to delete this Feed Image?");
            if (proceed) {
                this.deleteFeedImage(image_id)
            } else {
                //don't proceed
            }
        },
        confirmDeleteFeedVideo(audio_id) {
            let proceed = confirm("Are you sure you want to delete this Feed Video?");
            if (proceed) {
                this.deleteFeedVideo(audio_id)
            } else {
                //don't proceed
            }
        },
        confirmDeleteFeedComment(comment_id) {
            let proceed = confirm("Are you sure you want to delete this Feed Comment?");
            if (proceed) {
                this.deleteFeedComment(comment_id)
            } else {
                //don't proceed
            }
        },
        confirmDeleteFeedCommentReply(reply_id) {
            let proceed = confirm("Are you sure you want to delete this Feed Comment Reply?");
            if (proceed) {
                this.deleteFeedCommentReply(reply_id)
            } else {
                //don't proceed
            }
        },
        confirmDeleteFeedCommentReplyReply(reply_id) {
            let proceed = confirm("Are you sure you want to delete this Feed Comment Reply Reply?");
            if (proceed) {
                this.deleteFeedCommentReplyReply(reply_id)
            } else {
                //don't proceed
            }
        },
        confirmDeleteFeed() {
            let proceed = confirm("Are you sure you want to delete this feed and loose all its likes and comments?");
            if (proceed) {
                this.deleteFeed()
            } else {
                //don't proceed
            }
        },
        setCurrentLink(id, description) {
            let thisLink = this.$store.getters["host/seoUrl"] + '/feed/' + id
            this.shareLink = thisLink
        },
        showCommentBox() {
            this.$forceUpdate();
        },
        triggerFeedEdit(feed) {
            this.$root.$emit("open-edit-feed-post-modal", feed)
        },
        triggerReportContent(type, id) {
            let d = {
                type,
                id
            }
            this.$root.$emit("open-report-content-modal", d)
        },
        triggerEditFeedContent(type, id, comment) {
            let d = {
                type,
                id,
                comment
            }
            this.$root.$emit("open-edit-feed-comment-content-modal", d)
        },
        triggerLikersModal(likers) {
            this.$root.$emit("open-likers-modal", likers)
            UIkit.modal('#likers-modal').toggle();
        },
        commentChecker() {
            if (this.thisFeed && this.thisFeed.comments) {
                let comments = this.thisFeed.comments.length
                if (comments > this.noOfPreviewComments) {
                    this.unviewedComments = comments - this.noOfPreviewComments
                }
                if (this.preview == 'true') {
                    this.comments = this.thisFeed.comments.slice(0, this.noOfPreviewComments)
                } else {
                    this.comments = this.thisFeed.comments
                }

            }
        },
        showAllComments() {
            this.unviewedComments = 0
            this.comments = this.thisFeed.comments
        },
        closeFeedModal(a) {
            // $('.feedModal').click();
            UIkit.drop(a).hide(100);
            // console.log('closeFeedModal',UIkit.drop(a))
        },
        moreLessManager() {
            this.show_more_less_button = 'false'
            let a = this.customTextRender(this.thisFeed['content'])
            let max_length = 50
            if (a) {
                let textSplit = a.split(" ")
                if (textSplit.length > max_length) {
                    this.show_more_less_button = 'true'
                    if (this.is_less == 'true') {
                        textSplit = textSplit.slice(0, max_length)
                    } else {}
                }
                a = textSplit.join(' ')

            }
            this.feedContent = a
        },
        lessChange(a) {
            this.is_less = a
            this.moreLessManager()
        },
        moreLessImageManager() {
            if (this.is_less_image == 'true') {
                this.image_length = this.thisFeed.images.length
                this.is_less_image = 'false'
            } else {
                this.image_length = 5
                this.is_less_image = 'true'
            }
        },
        moreLessAudioManager() {
            if (this.is_less_audio == 'true') {
                this.audio_length = this.thisFeed.audios.length
                this.is_less_audio = 'false'
            } else {
                this.audio_length = 1
                this.is_less_audio = 'true'
            }
        },
        moreLessVideoManager() {
            if (this.is_less_video == 'true') {
                this.video_length = this.thisFeed.videos.length
                this.is_less_video = 'false'
            } else {
                this.video_length = 1
                this.is_less_video = 'true'
            }
        },
        triggerClick() {
            // console.log('TCLIECK')
        },
        openLikersModal() {
            if (this.thisFeed.user_id == this.currentUser.id) {
                UIkit.modal('#feed-likers-modal').toggle();
            }
        },
        resetDropdownKey(){
            this.userFeedKey = this.randomNumber()
        },
        totalDoantionAmount(donations){
            let total_donation_amount = 0
            for (let i = 0; i < donations.length; i++) {
                const donation = donations[i];
                let amount_usd = parseFloat(donation['amount_usd'])
                if(amount_usd > 0){
                    total_donation_amount = total_donation_amount+amount_usd
                }
            }
            let target = parseFloat(this.thisFeed.donation_target)
            let raised_percent = 0
            if(target > 0 && total_donation_amount > 0){
                raised_percent = parseInt((total_donation_amount/target)*100)
            }
            if(raised_percent > 100){
                raised_percent = 100
            }
            if(total_donation_amount == 0){
                total_donation_amount = 0.50
            }
            this.total_donation_amount = total_donation_amount
            this.raised_percent = raised_percent
        },
        manageFeedTemplate(a){
            if (a && a.template) {
                if(a.template.search('template') > -1){
                    if(a.images.length == 0 && a.audios.length == 0 && a.videos.length == 0){
                        if(a.template.search('-six') > -1){
                            this.feedTextBackground = 'color: #000; background:url("' + a.template + '");min-height: 40vh; display: grid; width: 100%; align-items: center; text-align: center;'
                        }else{
                            this.feedTextBackground = 'color: #ffffff; background:url("' + a.template + '");min-height: 40vh; display: grid; width: 100%; align-items: center; text-align: center;'
                        }
                    }
                }
            }
        },
        otherFeedprocess(){
            this.moreLessManager()
            this.commentChecker()
            if(this.thisFeed.allow_donation == 'true' || this.thisFeed.donations.length > 0){
                this.totalDoantionAmount(this.thisFeed['donations'])
                this.donation_target = parseFloat(this.thisFeed['donation_target'])
            }
            if (this.currentUser) {
                // this.getFeedLikes()
                this.likers = this.thisFeed["likes_all"];
            }
            if(this.thisFeed.feed_shared_from){
                this.getOriginalFeed(this.thisFeed.feed_shared_from)
            }
        }
    }
};
</script>

<style>
.feed_audios audio {
    margin: auto;
}

.feed_audio>div:first-child {
    margin-bottom: 20px;
}

.feed_audio>div:last-child {
    display: none;
}

.feed_video {
    margin-bottom: 20px;
}

.feed_video>div:first-child {
    width: 100%;
}

.feed_video .vjs-big-play-button {
    display: none !important;
}

.comment_extra_holder {
    position: absolute;
    bottom: -8px;
    right: -20px;
    background: white;
    border-radius: 15px;
    border: #ccc 1px solid;
    padding-right: 5px;
}

.icon_gif {
    height: 30px;
}

.icon_image {
    height: 20px;
}

.icon_image_min {
    height: 15px;
}

.icon_image_max {
    height: 20px;
}

.comment_content_reaction_holder {
    position: absolute;
    bottom: -10px;
    right: -20px;
    background: white;
    border-radius: 15px;
    border: #ccc 1px solid;
    padding: 0px 7px;
    display: flex;
}

.comment_box_reaction_png>img {
    margin-top: -4px;
}

.comment_content_reaction_holder img {
    height: 15px;
    margin: 5px 0;
}

.comment_button {
    position: absolute;
    right: 0;
    z-index: 99999;
    top: 0;
    padding: 12px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}

.moreOptions {
    position: absolute;
    width: 120px;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding-left: 10px;
    margin-top: 10px;
    margin-left: 20px;
    display: none;
    right: 20px;
}

.obvious {
    background-color: #f5f5f5;
    padding: 10px;
}

.f_r_a img.icon_image {
    margin-top: 4px;
}
.percent_holder {
    width: 100%;
    height: 10px;
    background: #fff;
    border: 1px solid #DF8301;
    border-radius: 5px;
    margin-bottom: 10px;
}
.percent_marker {
    background: #df8301;
    height: 8px;
    border-radius: 5px;
}
</style>
