<template>
<div id="wrapper">

    <Header />

    <sidebar></sidebar>

    <!-- Main Contents -->
    <div class="main_content">

        <span uk-toggle="target: .message-content;" class="fixed left-0 top-36 bg-blue-600 z-10 py-1 px-4 rounded-r-3xl text-white"> My Chats</span>

        <div class="messages-container" v-if="chats.length > 0">
            <div class="messages-container-inner" style="display: -webkit-box;">

                <div class="messages-inbox">
                    <div class="messages-headline">
                        <div class="input-with-icon" hidden="">
                            <input id="autocomplete-input" type="text" placeholder="Search">
                            <i class="icon-material-outline-search"></i>
                        </div>
                        <h2 class="text-2xl font-semibold">Shop Chats</h2>
                        <a class="absolute icon-feather-home mr-4 text-xl uk-position-center-right cursor-pointer sm:right-0 right-10" href="/dashboard"></a>
                    </div>
                    <div class="messages-inbox-inner" data-simplebar="init">
                        <div class="simplebar-wrapper" style="margin: 0px;">
                            <div class="simplebar-height-auto-observer-wrapper">
                                <div class="simplebar-height-auto-observer"></div>
                            </div>
                            <div class="simplebar-mask">
                                <div class="simplebar-offset" style="right: -20px; bottom: 0px;">
                                    <div class="simplebar-content" style="padding: 0px calc(20px) 0px 0px; height: 100%; overflow: hidden scroll;">
                                        <ul>

                                            <li v-for="c in chats" :id="'chat_'+c.id" class="chats_class" uk-toggle="target: .message-content;">
                                                <a href="javascript:;" @click="setChatId(c.id)">
                                                    <div class="message-avatar">
                                                        <i class="status-icon status-online"></i>
                                                        <img v-if="otherUserProfilePhoto(c)" :src="otherUserProfilePhoto(c, currentShop, 'shop')" alt="">
                                                        <img v-else :src="defaultImageUrl()" alt="">
                                                        <span v-if="otherUserOnline(c, currentShop, 'shop') == 'true'" :class="'user_status status_online chat_user_'+otherUserID(c, currentShop, 'user')"></span>
                                                        <span v-else :class="'user_status status_online chat_user_'+otherUserID(c, currentShop, 'shop')" style="display:none"></span>
                                                    </div>

                                                    <div class="message-by">
                                                        <div class="message-by-headline">
                                                            <h5>{{ otherUserName(c, currentShop, 'shop') }}</h5>
                                                            <span>{{ timeAgo(c.updated_at) }} ago</span>
                                                        </div>
                                                        <p v-if="c.last_message_type == 'text'">{{ c.last_message }}</p>
                                                        <p v-else class="text-italic">-{{ c.last_message_type }}-</p>
                                                    </div>
                                                </a>
                                            </li>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="simplebar-placeholder" style="width: 339px; height: 584px;"></div>
                        </div>
                        <div class="simplebar-track simplebar-horizontal" style="visibility: hidden;">
                            <div class="simplebar-scrollbar simplebar-visible" style="transform: translate3d(0px, 0px, 0px); visibility: hidden;"></div>
                        </div>
                        <div class="simplebar-track simplebar-vertical" style="visibility: visible;">
                            <div class="simplebar-scrollbar simplebar-visible" style="transform: translate3d(0px, 0px, 0px); visibility: visible; height: 265px;"></div>
                        </div>
                    </div>
                </div>

                <div class="message-content" v-if="chat">

                    <div class="messages-headline">
                        <h4>
                            <a :href="'/dashboard/timeline/'+otherUserID(chat, currentShop, 'user')">
                                {{ otherUserName(chat, currentShop, 'user') }}
                            </a>
                        </h4>
                        <div class="message-action">
                            <template v-if="chat">
                                <a v-if="chat.is_blocked != 'true'" href="javascript:;" class="text-red-500 mr-3" @click="blockChat">
                                    <i class="fa fa-ban"></i> 
                                    <span class="md:inline hidden"> Block</span> 
                                </a>
                                <template v-else>
                                    <a v-if="chat.blocked_by == currentUser.id" href="javascript:;" class="text-red-500 mr-3" @click="unblockChat">
                                        <i class="fa fa-ban"></i> 
                                        <span class="md:inline hidden"> Unblock</span> 
                                    </a>
                                </template>
                            </template>
                            <a v-if="chat && chat.is_blocked != 'true'" href="javascript:;" class="text-red-500">
                                <i class="icon-feather-trash-2"></i> 
                                <span class="md:inline hidden"> Delete Conversation</span> 
                            </a>
                        </div>
                    </div>

                    <div class="message-content-scrolbar" data-simplebar="init">
                        <div class="simplebar-wrapper" style="margin: 0px;">
                            <div class="simplebar-height-auto-observer-wrapper">
                                <div class="simplebar-height-auto-observer"></div>
                            </div>
                            <div class="simplebar-mask">
                                <div class="simplebar-offset" style="right: -20px; bottom: 0px;">
                                    <div class="simplebar-content" ref="messageDisplay" style="padding: 0px calc(20px) 0px 0px; height: 100%; overflow: hidden scroll;">

                                        <!-- Message Content Inner -->
                                        <div class="message-content-inner">

                                            <template v-for="m in chat.messages">
                                                <template v-if="message_from == m.message_from">
                                                    <!-- Time Sign -->
                                                    <div class="message-time-sign d-none">
                                                        <span>{{ formatDate(m.created_at) }}</span>
                                                    </div>

                                                    <my-message :chat="chat" :m="m" :chat_user_is="chat_user_is" type="shop"></my-message>
                                                </template>
                                                <template v-else>
                                                    <!-- Time Sign -->
                                                    <div class="message-time-sign d-none">
                                                        <span>{{ formatDate(m.created_at) }}</span>
                                                    </div>

                                                    <other-user-message :chat="chat" :m="m"></other-user-message>
                                                </template>
                                            </template>
                                            <temp-message v-if="messageTemp" :messageTemp="messageTemp" :messageTempType="messageTempType"></temp-message>
                                        </div>
                                        <!-- Message Content Inner / End -->

                                        <!-- Reply Area -->
                                        <send-message-input :chat="chat" @sendChatMessage="sendChatMessage"></send-message-input>

                                    </div>
                                </div>
                            </div>
                            <div class="simplebar-placeholder" style="width: 18232px; height: 753px;"></div>
                        </div>
                        <div class="simplebar-track simplebar-horizontal" style="visibility: hidden;">
                            <div class="simplebar-scrollbar" style="transform: translate3d(0px, 0px, 0px); visibility: hidden;"></div>
                        </div>
                        <div class="simplebar-track simplebar-vertical" style="visibility: visible;">
                            <div class="simplebar-scrollbar" style="height: 204px; transform: translate3d(0px, 0px, 0px); visibility: visible;"></div>
                        </div>
                    </div>

                </div>

                <div class="message-content" v-else>

                    <div class="messages-headline">
                        <h4 v-if="requestProgress == 'true'"> loading </h4>
                        <a v-if="requestProgress == 'false' && !chatId" class="message-action text-red-500">kindly select a chat from the sidebar</a>
                    </div>

                    <div class="message-content-scrolbarold" >
                        <div class="simplebar-wrapperold" style="margin: 0px;">
                            <progress-loader v-if="requestProgress == 'true'"></progress-loader>
                        </div>
                    </div>

                </div>

            </div>
        </div>
        <div v-else class="messages-container">
            <chat-loading-screen></chat-loading-screen>
        </div>

    </div>

    <progress-loader v-if="requestProgress == 'true'"></progress-loader>
    <dashboard-widgets page="shop-chats"></dashboard-widgets>
    <reply-chat-message v-if="messageToReply" :chat_message="messageToReply" :current_shop="currentShop" :message_from="message_from"></reply-chat-message>

</div>
</template>

<script>
import {
    VueEditor
} from "vue2-editor";
import {
    ApiService
} from '@/services/api.service'
import Header from '@/include/Dashboard/Header.vue'
import Sidebar from '@/include/Dashboard/Sidebar.vue'
import DashboardWidgets from '@/components/DashboardWidgets'
import OpenChatBox from '@/include/Modals/OpenChatBox.vue'
import Feeds from '@/include/Dashboard/Feeds.vue'
import ProfileIcon from '@/include/Dashboard/Feed/ProfileIcon'
import ProgressLoader from '@/components/ProgressLoader'
import SingleFileUpload from '@/components/SingleFileUpload'
import ReplyChatMessage from '@/include/Modals/ReplyChatMessage'
import axios from 'axios'
import SendMessageInput from '@/components/Chats/SendMessageInput'
import MyMessage from '@/components/Chats/MyMessage'
import OtherUserMessage from '@/components/Chats/OtherUserMessage'
import TempMessage from '@/components/Chats/TempMessage'
import ChatLoadingScreen from '@/components/Chats/ChatLoadingScreen'

export default {
    name: 'ShopChats',
    components: {
        Header,
        Sidebar,
        DashboardWidgets,
        OpenChatBox,
        Feeds,
        ProgressLoader,
        SingleFileUpload,
        ReplyChatMessage,
        VueEditor,
        SendMessageInput,
        MyMessage,
        OtherUserMessage,
        TempMessage,
        ChatLoadingScreen,
    },
    data() {
        return {
            id: '',
            type: 'shop',
            chats: '',
            chat: '',
            chat_sender: '',
            chat_receiver: '',
            messageTemp: '',
            messageType: 'text',
            messageTempType: 'text',
            message: '',
            message_type: 'text',
            message_from: '',
            product_link: '',
            chatId: '',
            chatType: 'shop',
            requestProgress: 'false',
            currentUser:'',
            currentShop:'',
            chat_user_is: '',
            countryName: true,
            regionName: true,
            messageToReply: '',
            clicks: 0,
            images: [],
            typing: 'false',
            chatSilent: 'false',
            disableMessageButton: false,
        }
    },
    // created() {
    //     this.setupSocketConnection()
    // },
    // beforeUnmount() {
    //     this.dicconnect()
    // },
    mounted: function () {
        this.currentUser = this.$store.getters['user/currentUser'] || {}
        this.other_id = this.$route.query['id']
        this.other_type = this.$route.query['type']
        this.chatId = this.$route.query['chat_id']
        this.product_link = this.$route.query['product_link']
        if(!this.chatId){
            if(this.other_id && this.other_type){
                this.findChat()
            }else{
                this.getChats()
            }
        }else{
            this.getChat()
            this.getChats()
        }
        this.$root.$on("refresh-socket-id", (socket) => {
            this.socket = socket
        })
        this.$root.$on("refresh-typing-true", (data) => {
            if (this.currentShop['id'] == data['shop_id'] && this.chatId == data['chat_id']) {
                this.typing = 'true'
            }
        })
        this.$root.$on("refresh-typing-false", (data) => {
            if (this.currentShop['id'] == data['shop_id'] && this.chatId == data['chat_id']) {
                this.typing = 'false'
            }
        })
        this.$root.$on("refresh-user-online", (data) => {
            $(".chat_user_"+data['shop_id']).show()
        })
        this.$root.$on("refresh-user-offline", (data) => {
            $(".chat_user_"+data['shop_id']).hide()
        })
        this.$root.$on("refresh-chats",(data) => {
            console.log('Refresh chat', data)
            if(data['event'] == 'newMessageRead'){
                if(this.currentUser['id'] == data['message_sender_id']){
                    this.getChat()
                }
            }else{
                this.getChats()
                if (data['chat_id'] == this.chatId) {
                    this.chatSilent = 'true'
                    this.getChat()
                }
            }
        })
    },
    methods: {
        async getChats() {
            this.requestProgress = 'true'
            this.toggleProgress('show')
            let apiResponse = await ApiService.GetChats(this.chatType, 'shop');
            if (apiResponse['status'] == 'success') {
                this.currentShop = apiResponse['data']['shop']
                this.chats = apiResponse['data']['chats']
                if(!this.chatId){
                    if(this.chats.length > 0){
                        // set last message as open chat
                        this.chatId = this.chats[0]['id']
                        this.getChat()
                    }
                }
            }else{
                this.$store.commit("toast/Notify", {
                    type: apiResponse['status'],
                    message: apiResponse['message'],
                });
            }
            this.requestProgress = 'false'
            this.toggleProgress('hide')
        },
        async getChat() {
            // console.log("this.chatSilent",this.chatSilent)
            if(this.chatSilent == 'false'){
                this.requestProgress = 'true'
                this.toggleProgress('show')
            }
            let apiResponse = await ApiService.GetChat(this.chatId, 'shop');
            if (apiResponse['status'] == 'success') {
                this.chat = apiResponse['data']['chat']
                this.chat_sender = apiResponse['data']['sender']
                this.chat_receiver = apiResponse['data']['receiver']
                this.shop = this.chat['shop']
                if(this.chat['shop_id'] == this.currentShop['id']){
                    this.chat_user_is = this.chat['shop_owner']
                    if(this.chat_user_is == 'sender'){
                        this.message_from = this.chat_sender['id']
                    }else if(this.chat_user_is == 'receiver'){
                        this.message_from = this.chat_receiver['id']
                    }
                }else{
                    this.$store.commit("toast/Notify", {
                        type: 'warning',
                        message: 'How did you get here, parameter error',
                    });
                }
                $(".chats_class").removeClass('active-message')
                $("#chat_"+this.chat.id).addClass('active-message')
                let vueThis = this
                setTimeout(() => {
                    vueThis.scrollToLastMessage()
                }, 1000);
                this.markChatMessagesAsSeen()
            }else{
                this.$store.commit("toast/Notify", {
                    type: apiResponse['status'],
                    message: apiResponse['message'],
                });
            }
            // console.log("this.chatSilent",this.chatSilent)
            if(this.chatSilent == 'false'){
                this.requestProgress = 'false'
                this.toggleProgress('hide')
            }
        },
        async blockChat() {
            // console.log("this.chatSilent", this.chatSilent)
            if (this.chatSilent == 'false') {
                this.requestProgress = 'true'
                this.toggleProgress('show')
            }
            let apiResponse = await ApiService.BlockChat(this.chatId);
            if (apiResponse['status'] == 'success') {
                this.getChat()
            } else {
                this.$store.commit("toast/Notify", {
                    type: apiResponse['status'],
                    message: apiResponse['message'],
                });
            }
            if (this.chatSilent == 'false') {
                this.requestProgress = 'false'
                this.toggleProgress('hide')
            }
            this.chatSilent = 'false'
        },
        async unblockChat() {
            // console.log("this.chatSilent", this.chatSilent)
            if (this.chatSilent == 'false') {
                this.requestProgress = 'true'
                this.toggleProgress('show')
            }
            let apiResponse = await ApiService.UnblockChat(this.chatId);
            if (apiResponse['status'] == 'success') {
                this.getChat()
            } else {
                this.$store.commit("toast/Notify", {
                    type: apiResponse['status'],
                    message: apiResponse['message'],
                });
            }
            if (this.chatSilent == 'false') {
                this.requestProgress = 'false'
                this.toggleProgress('hide')
            }
            this.chatSilent = 'false'
        },
        async findChat() {
            this.requestProgress = 'true'
            this.toggleProgress('show')
            let apiResponse = await ApiService.FindChat(this.id,this.type,this.other_id, this.other_type);
            if (apiResponse['status'] == 'success') {
                this.chatId = apiResponse['data']['chat']['id']
                this.getChats()
                if(this.chatId){
                    this.getChat(this.chatId)
                }
            }else{
                this.$store.commit("toast/Notify", {
                    type: apiResponse['status'],
                    message: apiResponse['message'],
                });
            }
            this.requestProgress = 'false'
            this.toggleProgress('hide')
        },
        async sendChatMessage(a) {
            if(a){
                this.message = a.message
                this.message_type = a.message_type
                this.image = a.image
                this.images = a.images
            }
            this.message = $("#chat_message").val()
            // this.requestProgress = 'true'
            let apiResponse = await ApiService.SendChatMessage(this.message, this.message_type, this.message_from, this.chatId, this.product_link, '', this.images);

            if (apiResponse["status"] != "success") {
                this.$root.$emit("chat-message-sent", 'error')
                this.$store.commit("toast/Notify", {
                    type: apiResponse["status"],
                    message: apiResponse["message"],
                });
            } else {
                this.$root.$emit("chat-message-sent", 'success')
                this.messageTemp = ''
                this.chatSilent = 'true'
                this.getChat(this.chatId)
                
            }
            // this.requestProgress = 'false'
        },
        async setChatId(id){
            this.$router.push('/dashboard/chats?chat_id='+id)
            this.chatId = id 
            this.getChat()
        },
        async markChatMessagesAsSeen() {
            let apiResponse = await ApiService.MarkChatMessagesAsSeen(this.chatId);
        },
        setupSocketConnection() {
        },
        typingSocketConnection() {
            if (this.socket) {
                this.socket.emit('broadcast', {
                    event: 'shop_typing',
                    user_id: this.otherUserID(this.chat, this.currentShop, 'shop'),
                    chat_id: this.chatId
                });
            }
        },
        stopTypingSocketConnection() {
            if (this.socket) {
                this.socket.emit('broadcast', {
                    event: 'shop_typing_stopped',
                    user_id: this.otherUserID(this.chat, this.currentShop, 'shop'),
                    chat_id: this.chatId
                });
            }
        },
        setMessageType(a) {
            this.messageType = a
            this.message_type = a
            this.messageTempType = a
        },
        setMessageToReply(a){
            this.messageToReply = a
            this.clicks++;
            // console.log('::::', this.clicks)
            if (this.clicks === 2) {
                UIkit.modal('#reply-chat-modal').toggle();
                this.clicks = 0
            } else {
            }
            let vueThis = this
            setTimeout(() => {
                vueThis.clicks = 0
            }, 300);
            // $('#reply-chat-modal').addClass('uk-open').show();
        },
        disconnect() {
            if (this.socket) {
                this.socket.disconnect();
            }
        },
        scrollToLastMessage(){
            var messageDisplay = this.$refs.messageDisplay;
            if(messageDisplay && messageDisplay.scrollHeight){
                messageDisplay.scrollTop = messageDisplay.scrollHeight;
            }
            // console.log('Scrolled')
        },
    },
}
</script>

<style lang="scss" scoped>
.some-modal-content {
    min-width: 400px;
    padding: 25px;

    .buttons button {
        padding: 10px;
        margin: 10px;
    }
}

.postImgheight {
    height: 100px
}

.editor {
    height: 350px;
}
.message-reply {
    padding: 10px;
}
.message-reply textarea {
    padding-left: 5px;
}
.message-bubble .message-text p {
    word-break: break-word;
}
@media (max-width: 520px) {
    header {
        display: none;
    }
    .main_content {
        padding-top: 0px !important;
    }
}
</style>
