<template>
    <a :href="icon" target="_blank">
        <img :src="icon" class="bg-gray-200 border border-white rounded-full w-10 h-10 object-cover" alt="VT">
    </a>
</template>

<script>
export default {
    name: "ProfileIcon",
    props: {
        url: String
    },
    data() {
        return {
            icon: '',
        };
    },
    mounted: function () {
        if(!this.url){
            this.icon = '/assets/img/avatar.png'
        }else{
            this.icon = this.url
        }
    },
    methods: {
    }
};
</script>

<style lang="scss" scoped></style>
