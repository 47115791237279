<template>
<div id="wrapper">
    <auth-styles v-if="pageNameMain" :pageNameMain="pageNameMain" :pageDescriptionMain="pageDescriptionMain" :pageImageMain="pageImageMain"></auth-styles>

    <Header />

    <sidebar></sidebar>

    <!-- Main Contents -->
    <div class="main_content">
        <div class="mcontainer" v-if="event">

            <!-- Profile event cover -->
            <div class="profile">

                <div class="profiles_banner">
                    <img v-if="event.image" :src="event.image" alt="">
                    <img v-else :src="defaultPostUrl" alt="">
                </div>
                <div class="profiles_content">
                    <div class="profile_info">
                        <h1> {{ event.title }} </h1>
                        <div class="flex space-x-2 items-center md:pt-3 text-base -mt-2 md:justify-start justify-center">
                            <div> {{ event.payment_type }}</div>
                            <div class="md:block hidden">·</div>
                            <div> {{ event.type }} event</div>
                        </div>
                    </div>
                    <div class="items-center space-x-2 text-center grid grid-cols-4 uk-countdown" :uk-countdown="'date: '+event.start_date+' '+event.start_time">
                        <div class="bg-gray-100 rounded-md p-2 border shadow-inner">
                            <div class="uk-countdown-days text-lg font-bold"><span>0</span><span>0</span></div>
                            <div class="text-xs">DAYS </div>
                        </div>
                        <div class="bg-gray-100 rounded-md p-2 border shadow-inner">
                            <div class="uk-countdown-hours text-lg font-bold"><span>0</span><span>0</span></div>
                            <div class="text-xs">HOURS </div>
                        </div>
                        <div class="bg-gray-100 rounded-md p-2 border shadow-inner">
                            <div class="uk-countdown-minutes text-lg font-bold"><span>0</span><span>0</span></div>
                            <div class="text-xs">MIN  </div>
                        </div>
                        <div class="bg-gray-100 rounded-md p-2 border shadow-inner">
                            <div class=" uk-countdown-seconds text-lg font-bold"><span>0</span><span>0</span></div>
                            <div class="text-xs">SEC </div>
                        </div>
                    </div>
                </div>

                <div class="flex items-center space-x-4 lg:absolute bottom-0 right-0 p-3 z-10 justify-center">
                    <a v-if="!event.user_interest" @click="showEventInterest(event.id)"  href="javascript:;" class="mouse-pointer flex items-center justify-center h-9 px-5 rounded-md bg-blue-600 text-white space-x-1.5">
                        <i name="thumbs-up" role="img" class="md hydrated" aria-label="thumbs up"></i>
                        <span> Show Interest </span>
                    </a>
                    <a v-else  href="javascript:;" class="mouse-pointer flex items-center justify-center h-9 px-5 rounded-md bg-green-600 text-white space-x-1.5">
                        <i name="thumbs-up" role="img" class="md hydrated" aria-label="thumbs up"></i>
                        <span> Interest Shown </span>
                    </a>
                    <template v-if="event.user && thisUser.id == event.user.id">
                        <a v-if="event.status == 'pending'" :href="'/dashboard/edit-event/'+event.id" class="flex items-center justify-center h-9 px-5 rounded-md bg-blue-600 text-white font-semibold space-x-1.5">
                            <span> Edit </span>
                        </a>
                    </template>
                    <a href="#" class="flex-old d-none items-center justify-center h-9 px-5 rounded-md bg-blue-600 text-white font-semibold space-x-1.5">
                        <span> Go Now </span>
                    </a>
                </div>
                <nav class="responsive-nav border-t -mb-0.5 lg:pl-2">
                    <ul>
                        <li :class="homeActive" @click="sectionSwitch('home')"><a class="mouse-pointer"> Home</a></li>
                        <li :class="aboutActive" @click="sectionSwitch('about')"><a class="mouse-pointer">About</a></li> 
                        <li :class="discussionActive" @click="sectionSwitch('discussion')"><a class="mouse-pointer">Discussion</a></li> 
                    </ul>
                </nav>
                
            </div>
                
            <div class="md:flex  md:space-x-8 lg:mx-14">
                <div class="space-y-5 flex-shrink-0 md:w-7/12">
            

                    <div v-if="homeActive == 'active' || aboutActive == 'active'" class="card p-5 mb-10">

                        <h1 class="block text-lg font-bold"> Details  </h1>

                        <div class="space-y-4 mt-3">
                                
                            <div class="flex-old d-none items-center space-x-3">
                                <i name="alert-circle" class="bg-gray-100 p-1.5 rounded-full text-xl md hydrated fa fa-circle" role="img" aria-label="alert circle"></i>
                                <div class="flex-1">
                                    <div > {{ event.attendance }} attending</div>
                                </div>
                            </div> 
                            <div class="flex items-center space-x-3">
                                <i name="people" class="bg-gray-100 p-1.5 rounded-full text-xl md hydrated fa fa-user" role="img" aria-label="people"></i>
                                <div class="flex-1">
                                    <div class="font-semibold"> {{ event.response_count }} people responded </div>
                                </div>
                            </div> 
                            <div class="flex items-center space-x-3">
                                <i name="globe" class="bg-gray-100 p-1.5 rounded-full text-xl md hydrated fa fa-globe" role="img" aria-label="globe"></i>
                                <div class="flex-1">
                                    <div> {{ event.type }}: <a :href="event.website" class="text-blue-500"> {{ event.website }} </a> </div> 
                                </div>
                            </div>
                            <div class="flex items-center space-x-3">
                                <i name="globe" class="bg-gray-100 p-1.5 rounded-full text-xl md hydrated fa fa-globe" role="img" aria-label="globe"></i>
                                <div class="flex-1">
                                    <div> {{ formatDateTime(event.start_date) }} at {{ event.start_time }} {{ event.timezone }} </div> 
                                </div>
                            </div>
                            <div class="flex items-center space-x-3">
                                <i name="albums" class="bg-gray-100 p-1.5 rounded-full text-xl md hydrated fa fa-tag" role="img" aria-label="albums"></i>
                                <div class="flex-1">
                                    <div>  <a class="text-blue-500 text-capitalize">{{ event.sector }}</a> </div> 
                                </div>
                            </div>

                            <div class="line-clamp-3" id="more-text" v-html="event.description"></div>
                            <!-- <a href="#" id="more-text" uk-toggle="target: #more-text ; cls: line-clamp-3"> See more </a> -->
                                
                        </div>
                        

                    </div>
            
                    <div v-if="homeActive == 'active' || discussionActive == 'active'" class="card-old lg:mx-0 uk-animation-slide-bottom-small mb-4">
                        <user-feed :feed="feed" ></user-feed>
                    </div> 
                
                </div>

                <!-- Sidebar -->
                <div class="w-full flex-shirink-0">

                    <div class="card p-4"> 
                        <div class="">
                            <div class="py-2 flex items-center space-x-4">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="text-yellow-500 w-9">
                                    <path fill-rule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clip-rule="evenodd"></path>
                                </svg>
                                <div>
                                    <div class="text-lg font-semibold"> Start Date</div>
                                    <div> {{ event.start_date }} - {{ event.start_time }}</div>
                                </div>
                            </div>
                            <div class="py-2 flex items-center space-x-4">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="text-red-500 w-9">
                                    <path fill-rule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clip-rule="evenodd"></path>
                                </svg>
                                <div>
                                    <div class="text-lg font-semibold"> End Date</div>
                                    <div> {{ event.end_date }} - {{ event.end_time }}</div>
                                </div>
                            </div>
                        </div>
                        <hr class="-mx-4 my-4">
                            
                        <!--  widget  -->
                        <div>
                            <div class="flex items-center justify-between mb-2">
                                <div>
                                    <h4 class="text-xl -mb-0.5 font-semibold"> Go with friends  </h4>
                                </div>
                                <a href="#" class="text-blue-600 d-none">See all</a>
                            </div>
                            <div>
                                <div v-for="invite in invites" v-if="invite.follower && currentUser.id != invite.follower_id" class="flex items-center space-x-4 hover:bg-gray-100 rounded-md -mx-2 p-2">
                                    <div class="w-10 h-10 flex-shrink-0 rounded-md relative"> 
                                        <profile-icon :url="invite.follower.profile_photo"></profile-icon>
                                        <!-- <img src="/assets/images/avatars/avatar-3.jpg" class="absolute w-full h-full inset-0 rounded-full" alt=""> -->
                                    </div>
                                    <div class="flex-1">
                                        <h3 class="text-base font-semibold capitalize"> 
                                            {{ invite.follower.name }}  
                                            <verified-badge v-if="invite.follower.verified == 'true'" mt="zero"></verified-badge>
                                        </h3>
                                    </div>
                                    <a href="Javascript:;" @click="inviteFriendToEvent(event.id, invite.follower.id)" class="flex items-center justify-center h-9 px-4 rounded-md border font-semibold">  Invite </a>
                                </div> 
                            </div>
                        </div>

                        <hr class="-mx-4 my-4">

                        <!--  widget  -->
                        <div>
                            <div class="flex items-center justify-between mb-2">
                                <div>
                                    <h4 class="text-xl -mb-0.5 font-semibold"> Related Pages  </h4>
                                </div>
                                <a href="#" class="text-blue-600 d-none">See all</a>
                            </div>
                            <div>
                                <div v-for="related in all_related" class="flex items-center  hover:bg-gray-100 rounded-md -mx-2 p-2 pr-0">
                                    <div class="flex-shrink-0 h-16 mr-3 relative rounded-md w-16">
                                        <img :src="related.image" class="absolute w-full h-full inset-0 rounded-md object-cover" alt="">
                                    </div>
                                    <div class="flex-1">
                                        <a href="#" class="capitalize font-semibold line-clamp-1 mb-1 text-base">{{ related.title }}</a>
                                        <div class="text-xs uppercase text-blue-500 font-semibold">{{  related.sector }}</div>
                                    </div>
                                    <a :href="'/dashboard/event/'+related.id" class="bg-gray-100 flex flex-col font-semibold items-center justify-center py-1.5 px-4 rounded-md"> {{ related.attendance }}
                                        <div class="text-sm"> Going </div> 
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>
        <div v-else class="mcontainer">
            <div v-if="pendingRequest == false" class="bg-white p-3 text-center font-bold">
                <h4>Feed could not be retrieved. <br/> you may have been blocked from seeing this or feed may have been deleted</h4>
            </div>
        </div>
    </div>

    <progress-loader v-if="eventPreloader == 'true'"></progress-loader>
    <dashboard-widgets page="event"></dashboard-widgets>

</div>
</template>

<script>
import {
    VueEditor
} from "vue2-editor";
import {
    ApiService
} from '@/services/api.service'
import Header from '@/include/Dashboard/Header.vue'
import Sidebar from '@/include/Dashboard/Sidebar.vue'
import DashboardWidgets from '@/components/DashboardWidgets'
import OpenChatBox from '@/include/Modals/OpenChatBox.vue'
import ProfileIcon from '@/include/Dashboard/Feed/ProfileIcon'
import ProgressLoader from '@/components/ProgressLoader'
import TimelineScreen from '@/include/Dashboard/Timeline/TimelineScreen'
import FriendScreen from '@/include/Dashboard/Timeline/FriendScreen'
import PhotoScreen from '@/include/Dashboard/Timeline/PhotoScreen'
import PageScreen from '@/include/Dashboard/Timeline/PageScreen'
import GroupScreen from '@/include/Dashboard/Timeline/GroupScreen'
import VideoScreen from '@/include/Dashboard/Timeline/VideoScreen'
import SingleFileUpload from '@/components/SingleFileUpload'
import CreatePhotoPost from '@/components/CreatePhotoPost'
import IonIcon from '@/components/IonIcon'
import UserFeed from '@/include/Dashboard/Feed/UserFeed'
import VerifiedBadge from '@/components/VerifiedBadge'
import axios from 'axios'

export default {
    name: 'Event',
    components: {
        Header,
        Sidebar,
        DashboardWidgets,
        OpenChatBox,
        ProgressLoader,
        VueEditor,
        TimelineScreen,
        FriendScreen,
        PhotoScreen,
        PageScreen,
        GroupScreen,
        VideoScreen,
        SingleFileUpload,
        CreatePhotoPost,
        IonIcon,
        UserFeed,
        VerifiedBadge,
        ProfileIcon,
    },
    data() {
        return {
            event: '',
            recent_events: [],
            tags: 0,
            pendingRequest: false,
            eventPreloader: 'false',
            pageNameMain: '',
            pageDescriptionMain: '',
            pageImageMain: '',
            currentUser: '',
            feed: '',
            homeActive: '',
            aboutActive: '',
            discussionActive: '',
            invites: '',
            all_related: '',
            countDown: {}
        }
    },
    mounted: function () {
        this.thisUser = this.$store.getters["user/currentUser"];
        this.currentUser = this.$store.getters['user/currentUser'] || {}
        let id = this.$route.params['id']
        this.id = id
        this.getEvent(this.id)
        this.sectionSwitch('home')
    },
    methods: {
        async getEvent(id) {
            if (this.pendingRequest == true) {
                // console.log('Pending Req')
                return
            }
            this.pendingRequest = true
            this.eventPreloader = 'true'
            let apiResponse = await ApiService.GetEvent(id)
            if (apiResponse['status'] != 'success') {} else {
                this.event = apiResponse['data']['event']
                this.feed = apiResponse['data']['feed']
                this.invites = apiResponse['data']['invite']
                this.all_related = apiResponse['data']['related']

                this.countDown = this.countdownData(this.event['start_date']+' '+this.event['start_time'])
            }
            this.pendingRequest = false
            this.eventPreloader = 'false'
        },
        async inviteFriendToEvent(id, user_id) {
            if (this.pendingRequest == true) {
                // console.log('Pending Req')
                return
            }
            this.eventPreloader = 'true'
            let apiResponse = await ApiService.InviteFriendToEvent(id, user_id)
            this.eventPreloader = 'false'
            if (apiResponse['status'] != 'success') {
            } else {
                this.getEvent(id)
            }
        },
        async deleteEventPost() {
            this.toggleProgress('show')
            let apiResponse = await ApiService.DeleteEvent(this.event['id']);
            this.toggleProgress('hide')
            this.$store.commit("toast/Notify", {
                type: apiResponse["status"],
                message: apiResponse["message"],
            });
            if (apiResponse["status"] != "success") {} else {
                location.href = '/dashboard/events'
            }
        },
        async showEventInterest() {
            this.toggleProgress('show')
            let apiResponse = await ApiService.ShowEventInterest(this.event['id']);
            this.toggleProgress('hide')
            this.$store.commit("toast/Notify", {
                type: apiResponse["status"],
                message: apiResponse["message"],
            });
            if (apiResponse["status"] != "success") {} else {
                location.href = ''
            }
        },
        confirmDelete() {
            let proceed = confirm("Are you sure you want to proceed?");
            if (proceed) {
                this.deleteEventPost()
            } else {
                //don't proceed
            }
        },
        sectionSwitch(a) {
            this.homeActive = ''
            this.aboutActive = ''
            this.discussionActive = ''
            switch (a) {
                case 'home':
                    this.homeActive = 'active'
                    break;
            
                case 'about':
                    this.aboutActive = 'active'
                    break;
            
                case 'discussion':
                    this.discussionActive = 'active'
                    break;
            
                default:
                    break;
            }
        }
    },
}
</script>

<style>
.profilePage123 input {
    width: 157px !important;
    height: 32px !important;
}
.text-capitalize {
    text-transform: capitalize;
}
</style>
