<template>
<div id="wrapper">
    <auth-styles :pageNameMain="pageNameMain" :pageDescriptionMain="pageDescriptionMain" :pageImageMain="pageImageMain"></auth-styles>
    <Header />

    <sidebar></sidebar>

    <!-- Main Contents -->
    <div class="main_content">
        <div class="mcontainer">

            <div class="flex justify-between items-center relative md:mb-4 mb-3">
                <div class="flex-1">
                    <h2 class="text-2xl font-semibold"> Products </h2>
                    <nav class="responsive-nav border-b md:m-0 -mx-4">
                        <ul>
                            <li :class="randomActive"><a @click="makeActiveSection('random')" class="mouse-pointer lg:px-2"> Suggestions </a></li>
                            <li :class="recentActive"><a @click="makeActiveSection('recent')" class="mouse-pointer lg:px-2"> Newest </a></li>
                            <li :class="userActive"><a @click="makeActiveSection('user')" class="mouse-pointer lg:px-2"> My Products </a></li>
                            <li v-if="hasShop == 'true'" :class="orderActive"><a @click="makeActiveSection('shop-product-orders')" class="mouse-pointer lg:px-2"> My Product Orders </a></li>
                        </ul>
                    </nav>
                </div>
                <template v-if="hasShop == 'true'">
                    <a v-if="shop.is_approved == 'true'" href="/dashboard/create-product" uk-toggle="" class="flex items-center justify-center h-8 lg:px-3 px-2 rounded-md bg-blue-600 text-white space-x-1 absolute right-0 z-10">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-4">
                            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clip-rule="evenodd"></path>
                        </svg>
                        <span class="md:block hidden"> Create Product </span>
                    </a>
                    <a v-else href="/dashboard/shops/update" uk-toggle="" class="flex items-center justify-center h-8 lg:px-3 px-2 rounded-md bg-blue-600 text-white space-x-1 absolute right-0 z-10">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-4">
                            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clip-rule="evenodd"></path>
                        </svg>
                        <span class="md:block hidden"> Update Shop </span>
                    </a>
                </template>
                <a v-else href="/dashboard/shops/create" uk-toggle="" class="flex items-center justify-center h-8 lg:px-3 px-2 rounded-md bg-blue-600 text-white space-x-1 absolute right-0 z-10">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-4">
                        <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clip-rule="evenodd"></path>
                    </svg>
                    <span class="md:block hidden"> Create Shop </span>
                </a>

            </div>

            <div class="relative uk-slider" uk-slider="finite: true">

                <div class="uk-slider-container px-1 py-3">
                    <ul class="uk-slider-items uk-child-width-1-5@m uk-child-width-1-3@s uk-child-width-1 uk-grid-small uk-grid" style="transform: translate3d(0px, 0px, 0px);">
                        <li tabindex="-1" class="uk-active mb-3" v-for="p in products">
                            <a :href="'/dashboard/product/'+p.id">
                                <div class="card">
                                    <div class="card-media h-40">
                                        <div class="card-media-overly"></div>
                                        <img v-if="p.image" :src="p.image" alt="">
                                        <img v-else :src="defaultPostUrl()" alt="">
                                        <span class="absolute bg-white px-2 py-1 text-sm rounded-md m-2"> Label</span>
                                        <button v-if="p.user_favourite" @onclick="unfavouriteProduct" class="bg-green-100 absolute right-2 top-2 p-0.5 px-1.5 rounded-full text-red-500">
                                            <i class="icon-feather-heart"> </i>
                                        </button>
                                        <button v-else @onclick="favouriteProduct" class="bg-blue-100 absolute right-2 top-2 p-0.5 px-1.5 rounded-full text-red-500">
                                            <i class="icon-feather-heart"> </i>
                                        </button>
                                    </div>
                                    <div class="card-body">
                                        <div class="-top-3 absolute bg-blue-100 font-medium px-2 py-1 right-2 rounded-full text text-blue-500 text-sm">
                                            {{ currencySymbol(p.currency) }}{{ formatAmount(p.amount) }}
                                        </div>
                                        <div class="text-xs font-semibold uppercase text-yellow-500">
                                            <span v-if="p.shop">{{ p.shop.name }} - </span><span v-if="p.category">{{ p.category.name }}</span>
                                        </div>
                                        <div class="ext-lg font-medium mt-1 t truncate"> {{ p.name }} </div>
                                    </div>
                                </div>
                            </a>
                        </li>
                        <li tabindex="-1" class="uk-active mb-3" v-for="p in shopProductorders" uk-toggle="target: #order-product-modal">
                            <a @click="setOrderProduct(p)">
                                <div class="card">
                                    <div class="card-media h-40">
                                        <div class="card-media-overly"></div>
                                        <img v-if="p.image" :src="p.image" alt="">
                                        <img v-else :src="defaultPostUrl()" alt="">
                                    </div>
                                    <div class="card-body">
                                        <div class="-top-3 absolute bg-blue-100 font-medium px-2 py-1 right-2 rounded-full text text-blue-500 text-sm">
                                            {{ currencySymbol(p.currency) }}{{ formatAmount(p.amount) }}
                                        </div>
                                        <div class="text-xs font-semibold uppercase text-yellow-500">
                                            <span v-if="p.category">{{ p.category.name }}</span>
                                        </div>
                                        <div class="ext-lg font-medium mt-1 t truncate"> {{ p.name }} </div>
                                    </div>
                                </div>
                            </a>
                        </li>
                    </ul>

                    <a class="absolute bg-white bottom-1/2 flex items-center justify-center p-2 -left-4 rounded-full shadow-md text-xl w-9 z-10 dark:bg-gray-800 dark:text-white uk-invisible" href="#" uk-slider-item="previous"> <i class="icon-feather-chevron-left"></i></a>
                    <a class="absolute bg-white bottom-1/2 flex items-center justify-center p-2 -right-4 rounded-full shadow-md text-xl w-9 z-10 dark:bg-gray-800 dark:text-white" href="#" uk-slider-item="next"> <i class="icon-feather-chevron-right"></i></a>

                </div>
                <div v-if="loadingProducts == true" class="text-center bg-white">
                    <i class="fa fa-spin fa-spinner fa-2x"></i>
                </div>
                <div v-if="products.length == 0 && orderActive != 'active'" class="bg-white p-2">
                    <h5>You have no Product in this Section</h5>
                </div>
                <div v-if="shopProductorders.length == 0 && orderActive == 'active'" class="bg-white p-2">
                    <h5>You have no Orders in this Section</h5>
                </div>
            </div>

            <div class="sm:my-6 my-3 flex items-center justify-between">
                <div>
                    <h2 class="text-xl font-semibold"> Categories </h2>
                    <p class="font-medium text-gray-500 leading-6"> Find a group by browsing top categories. </p>
                </div>
                <a href="/dashboard/products/categories" class="text-blue-500 sm:block hidden"> See all </a>
            </div>

            <div class="relative -mt-3 uk-slider" uk-slider="finite: true">

                <div class="uk-slider-container px-1 py-3">
                    <ul class="uk-slider-items uk-child-width-1-5@m uk-child-width-1-3@s uk-child-width-1-2 uk-grid-small uk-grid" style="transform: translate3d(0px, 0px, 0px);">

                        <li v-for="c in categories" tabindex="-1" class="uk-active mouse-pointer" @click="openSectionPage('category',c.id)">
                            <div class="rounded-md overflow-hidden relative w-full h-36">
                                <div class="absolute w-full h-3/4 -bottom-12 bg-gradient-to-b from-transparent to-gray-800 z-10">
                                </div>
                                <img v-if="c.image" :src="c.image" class="absolute w-full h-full object-cover" alt="">
                                <img v-else :src="defaultPostUrl()" class="absolute w-full h-full object-cover" alt="">
                                <div class="absolute bottom-0 w-full p-3 text-white z-20 font-semibold text-lg"> {{ c.name }} </div>
                            </div>
                        </li>
                    </ul>
                </div>

                <a class="absolute bg-white top-16 flex items-center justify-center p-2 -left-4 rounded-full shadow-md text-xl w-9 z-10 dark:bg-gray-800 dark:text-white uk-invisible" href="#" uk-slider-item="previous"> <i class="icon-feather-chevron-left"></i></a>
                <a class="absolute bg-white top-16 flex items-center justify-center p-2 -right-4 rounded-full shadow-md text-xl w-9 z-10 dark:bg-gray-800 dark:text-white" href="#" uk-slider-item="next"> <i class="icon-feather-chevron-right"></i></a>

            </div>
            <div v-if="loadingCategories == true" class="text-center bg-white">
                <i class="fa fa-spin fa-spinner fa-2x"></i>
            </div>

            <div class="my-6 flex items-center justify-between">
                <div>
                    <h2 class="text-xl font-semibold"> New Collection
                    </h2>
                </div>
                <a href="/dashboard/products/new" class="text-blue-500"> See all </a>
            </div>

            <div class="relative -mt-3 uk-slider" uk-slider="finite: true">

                <div class="uk-slider-container px-1 py-3">
                    <ul class="uk-slider-items uk-child-width-1-3@m uk-child-width-1-2@s uk-grid-small uk-grid" style="transform: translate3d(0px, 0px, 0px);">

                        <li v-for="p in newProducts" tabindex="-1" class="uk-active mouse-pointer" @click="openProductPage(p.id)">
                            <div class="card p-2 flex space-x-4 border border-gray-100">
                                <div class="w-20 h-24 overflow-hidden rounded-lg">
                                    <div class="card-media h-24">
                                        <img v-if="p.image" :src="p.image" alt="">
                                        <img v-else :src="defaultPostUrl()" alt="">
                                    </div>
                                </div>
                                <div class="flex-1 pt-2.5 relative">

                                    <div class="text-xs font-semibold uppercase text-yellow-500">{{ p.category.name }} </div>
                                    <div class="text-lg mt-3 2.5 text-gray-700">{{ p.name }}</div>
                                    <div class="flex items-center space-x-2 text-sm text-gray-400 capitalize">
                                        <div> {{ p.favourite_count }} favourites</div>
                                        <div>·</div>
                                        <div> {{ p.unique_views_count }} views </div>
                                    </div>
                                    <div class="top-1.5 absolute bg-gray-100 font-semibold px-2.5 py-1 right-1 rounded-full text text-blude-500 text-sm">
                                        {{ currencySymbol(p.currency) }}{{ formatAmount(p.amount) }}
                                    </div>

                                </div>
                            </div>
                        </li>

                    </ul>
                </div>

                <a class="absolute bg-white top-11 flex items-center justify-center p-2 -left-4 rounded-full shadow-md text-xl w-9 z-10 dark:bg-gray-800 dark:text-white uk-invisible" href="#" uk-slider-item="previous"> <i class="icon-feather-chevron-left"></i></a>
                <a class="absolute bg-white top-11 flex items-center justify-center p-2 -right-4 rounded-full shadow-md text-xl w-9 z-10 dark:bg-gray-800 dark:text-white" href="#" uk-slider-item="next"> <i class="icon-feather-chevron-right"></i></a>

            </div>
            <div v-if="loadingProductsNew == true" class="text-center bg-white">
                <i class="fa fa-spin fa-spinner fa-2x"></i>
            </div>

            <br>

            <div v-if="orders && orders.length > 0" class="my-6 flex items-center justify-between">
                <div>
                    <h2 class="text-xl font-semibold"> Customer Product Orders
                    </h2>
                </div>
                <a href="/dashboard/products/orders" class="text-blue-500"> See all </a>
            </div>

            <div v-if="orders && orders.length > 0" class="relative -mt-3 uk-slider" uk-slider="finite: true">

                <div class="uk-slider-container px-1 py-3">
                    <ul class="uk-slider-items uk-child-width-1-3@m uk-child-width-1-2@s uk-grid-small uk-grid" style="transform: translate3d(0px, 0px, 0px);">

                        <li v-for="order in orders" tabindex="-1" class="uk-active mouse-pointer" uk-toggle="target: #customer-order-product-modal">
                            <div @click="setCustomerOrderProduct(order)" class="card p-2 flex space-x-4 border border-gray-100">
                                <div class="w-20 h-24 overflow-hidden rounded-lg">
                                    <div class="card-media h-24">
                                        <img v-if="order.image" :src="order.image" alt="">
                                        <img v-else :src="defaultPostUrl()" alt="">
                                    </div>
                                </div>
                                <div class="flex-1 pt-2.5 relative">

                                    <div class="text-xs font-semibold uppercase text-yellow-500">{{ order.category }} </div>
                                    <div class="text-lg mt-3 2.5 text-gray-700">{{ order.name }}</div>
                                    <div class="flex items-center space-x-2 text-sm text-gray-400 capitalize">
                                        <div> Order ID: {{ order.id }}</div>
                                    </div>
                                    <div class="flex items-center space-x-2 text-sm text-gray-400 capitalize">
                                        <div> Paid: {{ unixToUtc(order.date_paid) }}</div>
                                    </div>
                                    <div class="flex items-center space-x-2 text-sm text-gray-400 capitalize">
                                        <div> Status: 
                                            <span v-if="order.date_paid">paid</span>
                                            <span v-else-if="order.date_accepted">vendor accepted</span>
                                            <span v-else-if="order.date_rejected">vendor rejected</span>
                                            <span v-else-if="order.date_refunded">vendor refunded</span>
                                            <span v-else-if="order.date_shipped">vendor shipped</span>
                                            <span v-else-if="order.date_delivered">vendor delivered</span>
                                            <span v-else-if="order.date_confirmed">customer confirmed</span>
                                        </div>
                                    </div>
                                    <div class="top-1.5 absolute bg-gray-100 font-semibold px-2.5 py-1 right-1 rounded-full text text-blude-500 text-sm">
                                        {{ currencySymbol(order.currency) }}{{ formatAmount(order.total_amount) }}
                                    </div>

                                </div>
                            </div>
                        </li>

                    </ul>
                </div>

                <a class="absolute bg-white top-11 flex items-center justify-center p-2 -left-4 rounded-full shadow-md text-xl w-9 z-10 dark:bg-gray-800 dark:text-white uk-invisible" href="#" uk-slider-item="previous"> <i class="icon-feather-chevron-left"></i></a>
                <a class="absolute bg-white top-11 flex items-center justify-center p-2 -right-4 rounded-full shadow-md text-xl w-9 z-10 dark:bg-gray-800 dark:text-white" href="#" uk-slider-item="next"> <i class="icon-feather-chevron-right"></i></a>

            </div>
            <div v-if="loadingOrders == true" class="text-center bg-white">
                <i class="fa fa-spin fa-spinner fa-2x"></i>
            </div>

            <br>

            <div class="d-none my-4 flex-one items-center justify-between border-b pb-3">
                <div>
                    <h2 class="text-xl font-semibold"> Brand Collection
                    </h2>
                </div>
                <a href="#" class="text-blue-500"> See all </a>
            </div>

            <div class="d-none relative uk-slider" uk-slider="finite: true">

                <div class="uk-slider-container px-1 py-3">
                    <ul class="uk-slider-items uk-child-width-1-5@m uk-child-width-1-3@s uk-child-width-1-2 uk-grid-small uk-grid" style="transform: translate3d(0px, 0px, 0px);">
                        <li tabindex="-1" class="uk-active">
                            <a href="product-single.html">
                                <div class="card">
                                    <div class="card-media h-40">
                                        <div class="card-media-overly"></div>
                                        <img src="/assets/images/product/12.jpg" alt="">
                                        <span class="absolute bg-white px-2 py-1 text-sm rounded-md m-2"> Label</span>
                                        <button class="bg-red-100 absolute right-2 top-2 p-0.5 px-1.5 rounded-full text-red-500">
                                            <i class="icon-feather-heart"> </i>
                                        </button>
                                    </div>
                                    <div class="card-body">
                                        <div class="-top-3 absolute bg-blue-100 font-medium px-2 py-1 right-2 rounded-full text text-blue-500 text-sm">
                                            $22.99
                                        </div>
                                        <div class="text-xs font-semibold uppercase text-yellow-500">Shoes</div>
                                        <div class="ext-lg font-medium mt-1 t truncate"> Hermes Rivera </div>
                                    </div>
                                </div>
                            </a>
                        </li>
                        <li tabindex="-1" class="uk-active">
                            <a href="product-single.html">
                                <div class="card">
                                    <div class="card-media h-40">
                                        <div class="card-media-overly"></div>
                                        <img src="/assets/images/product/15.jpg" alt="">
                                        <button class="bg-red-100 absolute right-2 top-2 p-0.5 px-1.5 rounded-full text-red-500">
                                            <i class="icon-feather-heart"> </i>
                                        </button>
                                    </div>
                                    <div class="card-body">
                                        <div class="-top-3 absolute bg-blue-100 font-medium px-2 py-1 right-2 rounded-full text text-blue-500 text-sm">
                                            $12.99
                                        </div>
                                        <div class="text-xs font-semibold uppercase text-yellow-500">Herbel</div>
                                        <div class="ext-lg font-medium mt-1 t truncate"> Herbal Shampoo </div>
                                    </div>
                                </div>
                            </a>
                        </li>
                        <li tabindex="-1" class="uk-active">
                            <a href="product-single.html">
                                <div class="card">
                                    <div class="card-media h-40">
                                        <div class="card-media-overly"></div>
                                        <img src="/assets/images/product/4.jpg" alt="">
                                        <button class="bg-red-100 absolute right-2 top-2 p-0.5 px-1.5 rounded-full text-red-500">
                                            <i class="icon-feather-heart"> </i>
                                        </button>
                                    </div>
                                    <div class="card-body">
                                        <div class="-top-3 absolute bg-blue-100 font-medium px-2 py-1 right-2 rounded-full text text-blue-500 text-sm">
                                            $122.99
                                        </div>
                                        <div class="text-xs font-semibold uppercase text-yellow-500">Mobiles</div>
                                        <div class="ext-lg font-medium mt-1 t truncate"> iPhone 7 unboxing </div>
                                    </div>
                                </div>
                            </a>
                        </li>
                        <li tabindex="-1" class="uk-active">
                            <a href="product-single.html">
                                <div class="card">
                                    <div class="card-media h-40">
                                        <div class="card-media-overly"></div>
                                        <img src="/assets/images/product/14.jpg" alt="">
                                        <button class="bg-red-100 absolute right-2 top-2 p-0.5 px-1.5 rounded-full text-red-500">
                                            <i class="icon-feather-heart"> </i>
                                        </button>
                                    </div>
                                    <div class="card-body">
                                        <div class="-top-3 absolute bg-blue-100 font-medium px-2 py-1 right-2 rounded-full text text-blue-500 text-sm">
                                            $32.99
                                        </div>
                                        <div class="text-xs font-semibold uppercase text-yellow-500">Furniture</div>
                                        <div class="ext-lg font-medium mt-1 t truncate"> Wood Chair </div>
                                    </div>
                                </div>
                            </a>
                        </li>
                        <li tabindex="-1" class="uk-active">
                            <a href="product-single.html">
                                <div class="card">
                                    <div class="card-media h-40">
                                        <div class="card-media-overly"></div>
                                        <img src="/assets/images/product/9.jpg" alt="">
                                        <button class="bg-red-100 absolute right-2 top-2 p-0.5 px-1.5 rounded-full text-red-500">
                                            <i class="icon-feather-heart"> </i>
                                        </button>
                                    </div>
                                    <div class="card-body">
                                        <div class="-top-3 absolute bg-blue-100 font-medium px-2 py-1 right-2 rounded-full text text-blue-500 text-sm">
                                            $32.99
                                        </div>
                                        <div class="text-xs font-semibold uppercase text-yellow-500">Fruit</div>
                                        <div class="ext-lg font-medium mt-1 t truncate"> Strawberries FreshRipe </div>
                                    </div>
                                </div>
                            </a>
                        </li>
                        <li tabindex="-1" class="">
                            <a href="product-single.html">
                                <div class="card">
                                    <div class="card-media h-40">
                                        <div class="card-media-overly"></div>
                                        <img src="/assets/images/product/2.jpg" alt="">
                                        <button class="bg-red-100 absolute right-2 top-2 p-0.5 px-1.5 rounded-full text-red-500">
                                            <i class="icon-feather-heart"> </i>
                                        </button>
                                    </div>
                                    <div class="card-body">
                                        <div class="-top-3 absolute bg-blue-100 font-medium px-2 py-1 right-2 rounded-full text text-blue-500 text-sm">
                                            $32.99
                                        </div>
                                        <div class="text-xs font-semibold uppercase text-yellow-500">Accessories</div>
                                        <div class="ext-lg font-medium mt-1 t truncate">Wireless headphones</div>
                                    </div>
                                </div>
                            </a>
                        </li>
                    </ul>

                    <a class="absolute bg-white bottom-1/2 flex items-center justify-center p-2 -left-4 rounded-full shadow-md text-xl w-9 z-10 dark:bg-gray-800 dark:text-white uk-invisible" href="#" uk-slider-item="previous"> <i class="icon-feather-chevron-left"></i></a>
                    <a class="absolute bg-white bottom-1/2 flex items-center justify-center p-2 -right-4 rounded-full shadow-md text-xl w-9 z-10 dark:bg-gray-800 dark:text-white" href="#" uk-slider-item="next"> <i class="icon-feather-chevron-right"></i></a>

                </div>
            </div>

        </div>
    </div>
    <div id="progressLoader">
        <progress-loader></progress-loader>
    </div>

    <dashboard-widgets page="products"></dashboard-widgets>
    <order-product-modal v-if="orderProduct" :product="orderProduct"></order-product-modal>
    <customer-order-product-modal v-if="customerOrderProduct" :order="customerOrderProduct"></customer-order-product-modal>
</div>
</template>

<script>
import {
    ApiService
} from '@/services/api.service'
import AuthStyles from '@/include/Auth/AuthStyles'
import Header from "@/include/Dashboard/Header.vue";
import Sidebar from "@/include/Dashboard/Sidebar.vue";
import DashboardWidgets from '@/components/DashboardWidgets'
import OpenChatBox from "@/include/Modals/OpenChatBox.vue";
import Feeds from "@/include/Dashboard/Feeds.vue";
import CustomIcon from '@/components/CustomIcon'
import ProgressLoader from '@/components/ProgressLoader'
import OrderProductModal from '@/include/Modals/OrderProductModal'
import CustomerOrderProductModal from '@/include/Modals/CustomerOrderProductModal'

export default {
    name: "Products",
    components: {
        Header,
        Sidebar,
        DashboardWidgets,
        OpenChatBox,
        Feeds,
        CustomIcon,
        ProgressLoader,
        AuthStyles,
        OrderProductModal,
        CustomerOrderProductModal
    },

    data() {
        return {
            products: [],
            shopProductorders: [],
            orders: [],
            recentTopics: [],
            page: 0,
            pendingRequest: false,
            loadingOrders: false,
            loadingProducts: false,
            loadingProductsNew: false,
            loadingProductsShopOrders: false,
            loadingCategories: false,
            recentActive: 'active',
            trendingActive: '',
            randomActive: '',
            orderActive: '',
            userActive: '',
            brands: '',
            categories: '',
            section: '',
            category: '',
            newProducts: '',
            pagination: {
                limit: 20,
                offset: 0,
                total: 60,
            },
            pageNameMain: 'Blogs',
            pageDescriptionMain: '',
            pageImageMain: '',
            myshop: '',
            hasShop: 'false',
            orderProduct: '',
            customerOrderProduct: '',
        };
    },
    mounted: function () {
        this.makeActiveSection('random')
        this.getUserShop()
        this.getNewProducts()
        this.getProductOrders()
        this.getExtraInfo()
    },
    methods: {
        async getExtraInfo() {
            this.toggleProgress('show')
            let apiResponse = await ApiService.ExtraInfo();
            if (apiResponse['status'] == 'success') {
                this.brands = apiResponse['data']['product_brands']
                this.categories = apiResponse['data']['product_categories']
            }
            this.toggleProgress('hide')
        },
        async getUserShop() {
            this.toggleProgress('show')
            let apiResponse = await ApiService.GetUserShop();
            if (apiResponse['status'] == 'success') {
                this.shop = apiResponse['data']
                if(this.shop){
                    this.hasShop = 'true'
                }
            }
            this.toggleProgress('hide')
        },
        async getProducts(section) {
            if (this.pendingRequest == true) {
                // console.log("Pending Req");
                return;
            }
            this.toggleProgress('show')
            this.pendingRequest = true;
            this.loadingProducts = true;
            let apiResponse = await ApiService.GetProducts(this.page, section);
            if (apiResponse["status"] != "success") {} else {
                this.pendingRequest = false;
                this.page++;
                this.products = apiResponse["data"]['products'];
            }
            this.loadingProducts = false;
            this.toggleProgress('hide')
        },
        async getProductOrders() {
            this.toggleProgress('show')
            this.loadingOrders = true;
            let apiResponse = await ApiService.GetProductOrders('customer', 0, 5);
            if (apiResponse["status"] != "success") {} else {
                this.orders = apiResponse["data"];
            }
            this.loadingOrders = false;
            this.toggleProgress('hide')
        },
        async getShopProductOrders() {
            this.toggleProgress('show')
            this.loadingProductsShopOrders = true;
            let apiResponse = await ApiService.GetProductOrders('shop', 0, 5);
            if (apiResponse["status"] != "success") {} else {
                this.shopProductorders = apiResponse["data"];
            }
            this.loadingProductsShopOrders = false;
            this.toggleProgress('hide')
        },
        async getNewProducts() {
            this.toggleProgress('show')
            this.loadingProductsNew = true;
            let apiResponse = await ApiService.GetProducts(0, 'recent');
            if (apiResponse["status"] != "success") {} else {
                this.newProducts = apiResponse["data"]['products'];
            }
            this.loadingProductsNew = false;
            this.toggleProgress('hide')
        },
        async favouriteProduct(id) {
            this.toggleProgress('show')
            this.pendingRequest = true;
            let apiResponse = await ApiService.FavouriteProduct(id);
            this.$store.commit("toast/Notify", {
                type: apiResponse["status"],
                message: apiResponse["message"],
            });
            if (apiResponse["status"] != "success") {} else {
            }
            this.toggleProgress('hide')
        },
        async unfavouriteProduct(id) {
            this.toggleProgress('show')
            this.pendingRequest = true;
            let apiResponse = await ApiService.UnfavouriteProduct(id);
            this.$store.commit("toast/Notify", {
                type: apiResponse["status"],
                message: apiResponse["message"],
            });
            if (apiResponse["status"] != "success") {} else {
            }
            this.toggleProgress('hide')
        },
        async makeActiveSection(a) {
            this.recentActive = ''
            this.randomActive = ''
            this.trendingActive = ''
            this.userActive = ''
            this.orderActive = ''

            this.page = 0;
            this.section = a
            this.products = []
            this.shopProductorders = []

            if (a == 'recent') {
                this.recentActive = 'active'
                this.getProducts('recent')
            } else if (a == 'random') {
                this.randomActive = 'active'
                this.getProducts('oldest')
            } else if (a == 'trending') {
                this.trendingActive = 'active'
                this.getProducts('trending')
            } else if (a == 'user') {
                this.userActive = 'active'
                this.getProducts('user')
            } else if (a == 'shop-product-orders') {
                this.orderActive = 'active'
                this.getShopProductOrders()
            }
        },
        async openSectionPage(section, section_id) {
            location.href = '/dashboard/products/'+section+'/'+section_id
        },
        async openProductPage(id) {
            location.href = '/dashboard/product/'+id
        },
        async setOrderProduct(p){
            this.orderProduct = p
        },
        async setCustomerOrderProduct(p) {
            this.customerOrderProduct = p
        },
    },
};
</script>

<style lang="scss" scoped>
.some-modal-content {
    min-width: 400px;
    padding: 25px;

    .buttons button {
        padding: 10px;
        margin: 10px;
    }
}
</style>
