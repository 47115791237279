<template>
    <!-- Header -->
    <header>
        <div class="header_wrap">
            <div class="header_inner mcontainer">
                <div class="left_side">
                    <span class="slide_menu" uk-toggle="target: #wrapper ; cls: is-collapse is-active">
                        <svg xmlns="http://www.w3.org/2000/svg" viewbox="0 0 24 24" width="24" height="24">
                            <path d="M3 4h18v2H3V4zm0 7h12v2H3v-2zm0 7h18v2H3v-2z" fill="currentColor"></path>
                        </svg>
                    </span>
    
                    <div id="logo">
                        <a href="/dashboard/feeds">
                            <img src="/assets/img/vt_social_logo.png" alt="" class="logo_img" />
                            <img src="/assets/img/vt_social_icon.png" class="logo_mobile logo_img" alt="" />
                        </a>
                    </div>
                </div>
    
                <!-- search icon for mobile -->
                <div class="header-search-icon" uk-toggle="target: #wrapper ; cls: show-searchbox"></div>
                <div class="header_search">
                    <i class="uil-search-alt"></i>
                    <input v-model="search" @keyup="searchApi" value="" type="text" class="form-control" placeholder="Search for Friends , Videos and more.." autocomplete="off" />
                    <div uk-drop="mode: click" class="header_search_dropdown">
                        <h4 class="search_title">Recently</h4>
                        <ul>
                            <template v-if="result.shops && result.shops.length > 0">
                                <li>
                                    <p class="mouse-pointer search_head" @click="toggleElements('.shop_results', '.results')">Shops</p>
                                </li>
                                <li style="display: none" class="results shop_results" v-for="data in result.shops">
                                    <a :href="'/dashboard/shop/'+data.id">
                                        <img v-if="data.image" :src="data.image" alt="" class="list-avatar" />
                                        <img v-else src="/assets/images/avatars/avatar-1.jpg" alt="" class="list-avatar" />
                                        <div class="list-name">{{ data.name }}</div>
                                    </a>
                                </li>
                            </template>
                            <template v-if="result.products && result.products.length > 0">
                                <li>
                                    <p class="mouse-pointer search_head" @click="toggleElements('.product_results', '.results')">Products</p>
                                </li>
                                <li style="display: none" class="results product_results" v-for="data in result.products">
                                    <a :href="'/dashboard/product/'+data.id">
                                        <img v-if="data.image" :src="data.image" alt="" class="list-avatar" />
                                        <img v-else src="/assets/images/avatars/avatar-1.jpg" alt="" class="list-avatar" />
                                        <div class="list-name">{{ data.name }}</div>
                                    </a>
                                </li>
                            </template>
                            <template v-if="result.users && result.users.length > 0">
                                <li>
                                    <p class="mouse-pointer search_head" @click="toggleElements('.user_results', '.results')">Users</p>
                                </li>
                                <li style="display: none" class="results user_results" v-for="user in result.users">
                                    <a :href="'/dashboard/timeline/'+user.id">
                                        <img v-if="user.profile_photo" :src="user.profile_photo" alt="" class="list-avatar" />
                                        <img v-else src="/assets/images/avatars/avatar-1.jpg" alt="" class="list-avatar" />
                                        <div class="list-name">{{ user.name }}</div>
                                    </a>
                                </li>
                            </template>
                            <template v-if="result.blogs && result.blogs.length > 0">
                                <li>
                                    <p class="mouse-pointer search_head" @click="toggleElements('.blog_results', '.results')">Blogs</p>
                                </li>
                                <li style="display: none" class="results blog_results" v-for="blog in result.blogs">
                                    <a :href="'/dashboard/blog/'+blog.name_key">
                                        <img v-if="blog.image" :src="blog.image" alt="" class="list-avatar" />
                                        <img v-else src="/assets/images/avatars/avatar-1.jpg" alt="" class="list-avatar" />
                                        <div class="list-name">{{ blog.title }}</div>
                                    </a>
                                </li>
                            </template>
                            <template v-if="result.courses && result.courses.length > 0">
                                <li>
                                    <p class="mouse-pointer search_head" @click="toggleElements('.course_results', '.results')">Courses</p>
                                </li>
                                <li style="display: none" class="results course_results" v-for="data in result.courses">
                                    <a :href="'/dashboard/course/'+data.id">
                                        <img v-if="data.image" :src="data.image" alt="" class="list-avatar" />
                                        <img v-else src="/assets/images/avatars/avatar-1.jpg" alt="" class="list-avatar" />
                                        <div class="list-name">{{ data.title }}</div>
                                    </a>
                                </li>
                            </template>
                            <template v-if="result.events && result.events.length > 0">
                                <li>
                                    <p class="mouse-pointer search_head" @click="toggleElements('.event_results', '.results')">Events</p>
                                </li>
                                <li style="display: none" class="results event_results" v-for="data in result.events">
                                    <a :href="'/dashboard/event/'+data.id">
                                        <img v-if="data.image" :src="data.image" alt="" class="list-avatar" />
                                        <img v-else src="/assets/images/avatars/avatar-1.jpg" alt="" class="list-avatar" />
                                        <div class="list-name">{{ data.title }}</div>
                                    </a>
                                </li>
                            </template>
                            <template v-if="result.forums && result.forums.length > 0">
                                <li>
                                    <p class="mouse-pointer search_head" @click="toggleElements('.forum_results', '.results')">Forums</p>
                                </li>
                                <li style="display: none" class="results forum_results" v-for="data in result.forums">
                                    <a :href="'/dashboard/forum/'+data.id">
                                        <img v-if="data.image" :src="data.image" alt="" class="list-avatar" />
                                        <img v-else src="/assets/images/avatars/avatar-1.jpg" alt="" class="list-avatar" />
                                        <div class="list-name">{{ data.title }}</div>
                                    </a>
                                </li>
                            </template>
                            <template v-if="result.groups && result.groups.length > 0">
                                <li>
                                    <p class="mouse-pointer search_head" @click="toggleElements('.group_results', '.results')">Groups</p>
                                </li>
                                <li style="display: none" class="results group_results" v-for="data in result.groups">
                                    <a :href="'/dashboard/group/'+data.id">
                                        <img v-if="data.image" :src="data.image" alt="" class="list-avatar" />
                                        <img v-else src="/assets/images/avatars/avatar-1.jpg" alt="" class="list-avatar" />
                                        <div class="list-name">{{ data.name }}</div>
                                    </a>
                                </li>
                            </template>
                            <template v-if="result.pages && result.pages.length > 0">
                                <li>
                                    <p class="mouse-pointer search_head" @click="toggleElements('.page_results', '.results')">Pages</p>
                                </li>
                                <li style="display: none" class="results page_results" v-for="data in result.pages">
                                    <a :href="'/dashboard/page/'+data.id">
                                        <img v-if="data.image" :src="data.image" alt="" class="list-avatar" />
                                        <img v-else src="/assets/images/avatars/avatar-1.jpg" alt="" class="list-avatar" />
                                        <div class="list-name">{{ data.name }}</div>
                                    </a>
                                </li>
                            </template>
                        </ul>
                    </div>
                </div>
    
                <div class="right_side">
                    <div class="header_widgets">
                        <a v-if="currentUser" :href="autoLoginPromoter" class="is_link mouse-pointer" target="_blank">
                            Upgrade
                        </a>
                        <a v-if="currentUser" class="is_icon mouse-pointer" uk-tooltip="title: Cart">
                            <svg fill="currentColor" viewbox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3 1a1 1 0 000 2h1.22l.305 1.222a.997.997 0 00.01.042l1.358 5.43-.893.892C3.74 11.846 4.632 14 6.414 14H15a1 1 0 000-2H6.414l1-1H14a1 1 0 00.894-.553l3-6A1 1 0 0017 3H6.28l-.31-1.243A1 1 0 005 1H3zM16 16.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM6.5 18a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"></path>
                            </svg>
                            <span v-if="cart">{{ cart.length }}</span>
                        </a>
                        <div v-if="currentUser" uk-drop="mode: click" class="header_dropdown dropdown_cart">
                            <div class="drop_headline">
                                <h4>My Cart</h4>
                                <a href="/dashboard/products/checkout" class="btn_action hover:bg-gray-100 mr-2 px-2 py-1 rounded-md underline">
                                    Checkout
                                </a>
                            </div>
    
                            <ul class="dropdown_cart_scrollbar" data-simplebar="">
                                <li v-for="c in cart">
                                    <div class="cart_avatar">
                                        <img v-if="c.image" :src="c.image" alt="" />
                                        <img v-else :src="defaultPostUrl()" alt="" />
                                    </div>
                                    <div class="cart_text">
                                        <div class="font-semibold leading-4 mb-1.5 text-base line-clamp-1">
                                            {{ c.name }}
                                        </div>
                                        <p class="text-sm">Type {{ c.category }}</p>
                                    </div>
                                    <div class="cart_price">
                                        <span> {{ currencySymbol(c.currency) }}{{ formatAmount(c.amount*c.item_number) }} </span>
                                        <button @click="removeCartItem(c.id)" class="type">Remove</button>
                                    </div>
                                </li>
                            </ul>
    
                            <div class="cart_footer">
                                <p>Subtotal : NGN {{ getCartSubTotal(cart) }}</p>
                                <h1>Total : <strong> NGN {{ getCartTotal(cart) }}</strong></h1>
                            </div>
                        </div>
    
                        <a v-if="currentUser" class="is_icon mouse-pointer" uk-tooltip="title: Notifications">
                            <svg fill="currentColor" viewbox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10 2a6 6 0 00-6 6v3.586l-.707.707A1 1 0 004 14h12a1 1 0 00.707-1.707L16 11.586V8a6 6 0 00-6-6zM10 18a3 3 0 01-3-3h6a3 3 0 01-3 3z"></path>
                            </svg>
                            <span>{{ notifications.length || 0 }}</span>
                        </a>
                        <div v-if="currentUser" uk-drop="mode: click" class="header_dropdown mouse-pointer">
                            <div class="dropdown_scrollbar" data-simplebar="">
                                <div @click="viewNotificationsOnce" class="drop_headline">
                                    <h4>Notifications</h4>
                                </div>
                                <p v-if="notifications.length == 0">No Notification</p>
                                <ul>
                                    <li v-for="noty in notifications">
                                        <a :href="notificationLink(noty)" class="">
                                            <div class="drop_avatar">
                                                <profile-icon v-if="noty.user && noty.user.profile_photo" :url="noty.user.profile_photo"></profile-icon>
                                                <img v-else :src="defaultImageUrl()" alt="" />
                                            </div>
                                            <!-- <span class="drop_icon bg-gradient-primary">
                                                <i class="icon-feather-thumbs-up"></i>
                                            </span> -->
                                            <div class="drop_text">
                                                <p>
                                                    <strong v-if="noty.user">{{ noty.user.name }}</strong>
                                                    {{ noty.title }}
                                                    <span class="text-link"></span>
                                                </p>
                                                <time> {{ timeAgo(noty.created_at) }} ago </time>
                                            </div>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
    
                        <!-- Message -->
                        <a v-if="currentUser" class="is_icon mouse-pointer" uk-tooltip="title: Message">
                            <svg fill="currentColor" viewbox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" d="M18 5v8a2 2 0 01-2 2h-5l-5 4v-4H4a2 2 0 01-2-2V5a2 2 0 012-2h12a2 2 0 012 2zM7 8H5v2h2V8zm2 0h2v2H9V8zm6 0h-2v2h2V8z" clip-rule="evenodd"></path>
                            </svg>
                            <span>{{ unreadCount }}</span>
                        </a>
                        <div v-if="currentUser" uk-drop="mode: click" class="header_dropdown is_message">
                            <div class="dropdown_scrollbar" data-simplebar="">
                                <div class="drop_headline">
                                    <h4>Messages</h4>
                                </div>
                                <input type="text" class="uk-input" placeholder="Search in Messages" />
                                <ul>
                                    <li :class="chatMessageUnseen(c, currentShop, 'user', 'header')" v-for="c in chats.slice(0, 8)">
                                        <a :href="'/dashboard/chats?chat_id='+c.id">
                                            <div class="drop_avatar">
                                                <img :src="otherUserProfilePhoto(c, currentShop, 'user')" alt="" />
                                                <span v-if="otherUserOnline(c, currentShop, 'user') == 'true'" class="user_status status_online"></span>
                                            </div>
                                            <div class="drop_text">
                                                <strong> {{ otherUserName(c, currentShop, 'user') }} </strong> <time>{{ formatDateTimeNiceShort(c.updated_at) }}</time>
                                                <p v-if="c.last_message_type == 'text'">{{ c.last_message }}</p>
                                                <p v-else class="text-italic">-{{ c.last_message_type }}-</p>
                                            </div>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <a href="/dashboard/chats" class="see-all"> See all in Messages</a>
                        </div>
    
                        <a v-if="!currentUser || !currentUser.profile_photo" class="mouse-pointer">
                            <img src="/assets/img/avatar.png" class="is_avatar" alt="" />
                        </a>
                        <a v-else class="mouse-pointer">
                            <img :src="currentUser.profile_photo" class="is_avatar" alt="" />
                        </a>
                        <div v-if="!currentUser" uk-drop="mode: click;offset:5" class="header_dropdown profile_dropdown">
                            <router-link :to="'/login'" class="is-link">
                                <svg fill="currentColor" viewbox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" d="M12.395 2.553a1 1 0 00-1.45-.385c-.345.23-.614.558-.822.88-.214.33-.403.713-.57 1.116-.334.804-.614 1.768-.84 2.734a31.365 31.365 0 00-.613 3.58 2.64 2.64 0 01-.945-1.067c-.328-.68-.398-1.534-.398-2.654A1 1 0 005.05 6.05 6.981 6.981 0 003 11a7 7 0 1011.95-4.95c-.592-.591-.98-.985-1.348-1.467-.363-.476-.724-1.063-1.207-2.03zM12.12 15.12A3 3 0 017 13s.879.5 2.5.5c0-1 .5-4 1.25-4.5.5 1 .786 1.293 1.371 1.879A2.99 2.99 0 0113 13a2.99 2.99 0 01-.879 2.121z" clip-rule="evenodd"></path>
                                </svg>
                                Login
                            </router-link>
                            <hr />
                            <router-link :to="'/register'">
                                <svg fill="currentColor" viewbox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z" clip-rule="evenodd"></path>
                                </svg>
                                Register
                            </router-link>
                            <a v-if="accessToken" class="mouse-pointer" @click="logOut">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewbox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"></path>
                                </svg>
                                Log Out
                            </a>
                        </div>
                        <div v-else uk-drop="mode: click;offset:5" class="header_dropdown profile_dropdown">
                            <a href="/dashboard/timeline" class="user">
                                <div class="user_avatar">
                                    <img v-if="!currentUser.profile_photo" src="/assets/img/avatar.png" alt="" />
                                    <img v-else :src="currentUser.profile_photo" style="width: 45px;height: 45px;" alt="" />
                                </div>
                                <div class="user_name">
                                    <div class="d-flex">
                                        {{ currentUser.name }}
                                        <verified-badge v-if="currentUser.verified == 'true'" size="small"></verified-badge>
                                    </div>
                                    <span> @{{ currentUser.username }}</span>
                                </div>
                            </a>
                            <hr />
                            <template v-if="!currentUser.type">
                                <a :href="autoLoginPromoter" class="is-link">
                                    <svg fill="currentColor" viewbox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" d="M12.395 2.553a1 1 0 00-1.45-.385c-.345.23-.614.558-.822.88-.214.33-.403.713-.57 1.116-.334.804-.614 1.768-.84 2.734a31.365 31.365 0 00-.613 3.58 2.64 2.64 0 01-.945-1.067c-.328-.68-.398-1.534-.398-2.654A1 1 0 005.05 6.05 6.981 6.981 0 003 11a7 7 0 1011.95-4.95c-.592-.591-.98-.985-1.348-1.467-.363-.476-.724-1.063-1.207-2.03zM12.12 15.12A3 3 0 017 13s.879.5 2.5.5c0-1 .5-4 1.25-4.5.5 1 .786 1.293 1.371 1.879A2.99 2.99 0 0113 13a2.99 2.99 0 01-.879 2.121z" clip-rule="evenodd"></path>
                                    </svg>
                                    Upgrade To Premium
                                </a>
                                <hr />
                                <a :href="autoLoginAdvertiser" class="is-link">
                                    <svg fill="currentColor" viewbox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" d="M12.395 2.553a1 1 0 00-1.45-.385c-.345.23-.614.558-.822.88-.214.33-.403.713-.57 1.116-.334.804-.614 1.768-.84 2.734a31.365 31.365 0 00-.613 3.58 2.64 2.64 0 01-.945-1.067c-.328-.68-.398-1.534-.398-2.654A1 1 0 005.05 6.05 6.981 6.981 0 003 11a7 7 0 1011.95-4.95c-.592-.591-.98-.985-1.348-1.467-.363-.476-.724-1.063-1.207-2.03zM12.12 15.12A3 3 0 017 13s.879.5 2.5.5c0-1 .5-4 1.25-4.5.5 1 .786 1.293 1.371 1.879A2.99 2.99 0 0113 13a2.99 2.99 0 01-.879 2.121z" clip-rule="evenodd"></path>
                                    </svg>
                                    Advertise
                                </a>
                                <hr />
                            </template>
                            <template v-else-if="currentUser.type == 'promoter'">
                                <a :href="autoLoginPromoter" class="is-link">
                                    <svg fill="currentColor" viewbox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" d="M12.395 2.553a1 1 0 00-1.45-.385c-.345.23-.614.558-.822.88-.214.33-.403.713-.57 1.116-.334.804-.614 1.768-.84 2.734a31.365 31.365 0 00-.613 3.58 2.64 2.64 0 01-.945-1.067c-.328-.68-.398-1.534-.398-2.654A1 1 0 005.05 6.05 6.981 6.981 0 003 11a7 7 0 1011.95-4.95c-.592-.591-.98-.985-1.348-1.467-.363-.476-.724-1.063-1.207-2.03zM12.12 15.12A3 3 0 017 13s.879.5 2.5.5c0-1 .5-4 1.25-4.5.5 1 .786 1.293 1.371 1.879A2.99 2.99 0 0113 13a2.99 2.99 0 01-.879 2.121z" clip-rule="evenodd"></path>
                                    </svg>
                                    Promoters Dashboard
                                </a>
                                <hr />
                            </template>
                            <template v-else-if="currentUser.type == 'advertiser'">
                                <a :href="autoLoginAdvertiser" class="is-link">
                                    <svg fill="currentColor" viewbox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" d="M12.395 2.553a1 1 0 00-1.45-.385c-.345.23-.614.558-.822.88-.214.33-.403.713-.57 1.116-.334.804-.614 1.768-.84 2.734a31.365 31.365 0 00-.613 3.58 2.64 2.64 0 01-.945-1.067c-.328-.68-.398-1.534-.398-2.654A1 1 0 005.05 6.05 6.981 6.981 0 003 11a7 7 0 1011.95-4.95c-.592-.591-.98-.985-1.348-1.467-.363-.476-.724-1.063-1.207-2.03zM12.12 15.12A3 3 0 017 13s.879.5 2.5.5c0-1 .5-4 1.25-4.5.5 1 .786 1.293 1.371 1.879A2.99 2.99 0 0113 13a2.99 2.99 0 01-.879 2.121z" clip-rule="evenodd"></path>
                                    </svg>
                                    Advertisers Dashboard
                                </a>
                                <hr />
                            </template>
                            <router-link to="/dashboard/timeline">
                                <svg fill="currentColor" viewbox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z" clip-rule="evenodd"></path>
                                </svg>
                                My Account
                            </router-link>
                            <router-link v-if="currentShop" :to="'/dashboard/shop/'+currentShop.shop_unique_name || currentShop.id">
                                <svg fill="currentColor" viewbox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z" clip-rule="evenodd"></path>
                                </svg>
                                My Shop
                            </router-link>
                            <router-link v-else :to="'/dashboard/shops/create'">
                                <svg fill="currentColor" viewbox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z" clip-rule="evenodd"></path>
                                </svg>
                                Create Shop
                            </router-link>
                            <router-link to="/pages">
                                <svg xmlns="http://www.w3.org/2000/svg" viewbox="0 0 20 20" fill="currentColor">
                                    <path fill-rule="evenodd" d="M3 6a3 3 0 013-3h10a1 1 0 01.8 1.6L14.25 8l2.55 3.4A1 1 0 0116 13H6a1 1 0 00-1 1v3a1 1 0 11-2 0V6z" clip-rule="evenodd"></path>
                                </svg>
                                Manage Pages
                            </router-link>
                            <router-link to="/user/settings">
                                <svg fill="currentColor" viewbox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4 4a2 2 0 00-2 2v1h16V6a2 2 0 00-2-2H4z"></path>
                                    <path fill-rule="evenodd" d="M18 9H2v5a2 2 0 002 2h12a2 2 0 002-2V9zM4 13a1 1 0 011-1h1a1 1 0 110 2H5a1 1 0 01-1-1zm5-1a1 1 0 100 2h1a1 1 0 100-2H9z" clip-rule="evenodd"></path>
                                </svg>
                                Settings
                            </router-link>
                            <a href="#" id="night-mode" class="btn-night-mode">
                                <svg xmlns="http://www.w3.org/2000/svg" viewbox="0 0 20 20" fill="currentColor">
                                    <path d="M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z"></path>
                                </svg>
                                Night mode
                                <span class="btn-night-mode-switch">
                                    <span class="uk-switch-button"></span>
                                </span>
                            </a>
                            <a class="mouse-pointer" @click="logOut">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewbox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"></path>
                                </svg>
                                Log Out
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
    </template>
    
    <script>
    import {
        ApiService
    } from '@/services/api.service'
    import VerifiedBadge from '@/components/VerifiedBadge'
    import ProfileIcon from '@/include/Dashboard/Feed/ProfileIcon'
    
    export default {
        name: "HeaderNoAuth",
        components: {
            VerifiedBadge,
            ProfileIcon,
        },
        data() {
            return {
                currentYear: new Date().getFullYear(),
                appName: "-",
                currentUser: "",
                autoLoginPromoter: "",
                autoLoginAdvertiser: "",
                chats: "",
                currentShop: "",
                unreadCount: '0',
                cart: [],
                notifications: '',
                search: '',
                result: '',
                accessToken: '',
            };
        },
        mounted: function () {
            this.appName = this.$APP_NAME;
    
            let currentUser = "";
            this.accessToken = "";
            this.currentUser = currentUser;
    
        },
        methods: {
            searchApi(){},
            logOut() {
                // console.log("Logged Out");
                this.$store.commit("auth/CLEAR_ACCESS_TOKEN");
                location.href = "/login";
            },
        },
    };
    </script>
    
    <style lang="scss" scoped></style>
    
    <style scoped>
    .header_dropdown ul li a .drop_avatar img {
        width: 50px;
        height: 50px;
    }
    
    .drop_avatar a {
        padding: 5px;
    }
    
    .drop_avatar img {
        width: 45px;
        height: 45px;
        object-fit: contain;
    }
    .search_head {
        padding: 8px 4px;
    }
    </style>
    <style>
    
    
    .uk-grid-xs,
    .uk-grid-column-xs {
        margin-left: -6px;
    }
    
    .uk-grid-xs>*,
    .uk-grid-column-xs>* {
        padding-left: 6px;
    }
    
    /* Vertical */
    .uk-grid+.uk-grid-xs,
    .uk-grid+.uk-grid-row-xs,
    .uk-grid-xs>.uk-grid-margin,
    .uk-grid-row-xs>.uk-grid-margin,
    *+.uk-grid-margin-xs {
        margin-top: 6px;
    }
    
    /* Desktop and bigger */
    @media (max-width: 1200px) {
    
        .uk-grid-xs>*,
        .uk-grid-column-xs>* {
            padding-left: 5px !important;
        }
    }
    
    .uk-grid-divider.uk-grid-xs,
    .uk-grid-divider.uk-grid-column-xs {
        margin-left: -15px;
    }
    
    .uk-grid-divider.uk-grid-xs>*,
    .uk-grid-divider.uk-grid-column-xs>* {
        padding-left: 15px;
    }
    
    .uk-grid-divider.uk-grid-xs> :not(.uk-first-column)::before,
    .uk-grid-divider.uk-grid-column-xs> :not(.uk-first-column)::before {
        left: 8px;
    }
    
    /* Vertical */
    .uk-grid-divider.uk-grid-xs.uk-grid-stack>.uk-grid-margin,
    .uk-grid-divider.uk-grid-row-xs.uk-grid-stack>.uk-grid-margin {
        margin-top: 15px;
    }
    
    .uk-grid-divider.uk-grid-xs.uk-grid-stack>.uk-grid-margin::before {
        top: -8px;
        left: 15px;
    }
    .Vue-Toastification__container {
        z-index: 99999 !important;
    }
    </style>