<template>
    <div class="message-bubble me">
        <div class="message-bubble-inner">
            <div class="message-avatar mouse-pointer" @click='openLinkInNewTab("/dashboard/timeline/"+currentUser["id"])'>
                <img :src="userProfilePhoto(chat, type)" alt="">
                <span class="user_status status_online d-none"></span>
            </div>
            <div class="message-text mouse-pointer" @click="setMessageToReply(m)" uk-toggle-old="target: #reply-chat-modal-old">
                <chat-message-images v-if="m.images" :images="m.images"></chat-message-images>
                <p v-if="m.message && m.images.length > 0" class="py-1"></p>
                <div v-if="m.original_message_id && m.main_message" class="bg-blue-500 px-3 mt-2 rounded-md">
                    <p>
                        <small>Reply to</small>
                    </p>
                    <p v-if="m.main_message.message_type == 'text'" v-html="customTextRender(m.main_message.message, 'color-black')"></p>
                    <a v-else-if="m.main_message.message_type == 'image'" :href="m.main_message.message" target="_blank" class="">
                        <img :src="m.main_message.message" style="height:50px" class="mr-5" />
                    </a>
                    <p v-else-if="m.main_message.message_type == 'link'">
                        <a :href="m.main_message.message" target="_blank" class="text-white text-white:hover">{{ m.main_message.message }}</a>
                    </p>
                    <hr/>
                </div>
                <p class="text-white" v-if="m.message_type == 'text'" v-html="customTextRender(m.message, 'text-white')" style="word-break: break-all;"></p>
            </div>
        </div>
        <div class="clearfix"></div>
        <p class="text-right mt-3">
            <small style="font-size: 10px;">
                {{ formatDateTimeNiceShort(m.created_at) }}
                <template v-if="chat_user_is == 'receiver'">
                    <span class="ml-2" v-if="m.sender_seen == 'true'">seen</span>
                </template>
                <template v-if="chat_user_is == 'sender'">
                    <span class="ml-2" v-if="m.receiver_seen == 'true'">seen</span>
                </template>
            </small>
        </p>
    </div>
</template>

<script>
import ChatMessageImages from '@/components/Chats/ChatMessageImages'
export default {
    props: {
        type: String,
        chat_user_is: String,
        chat: Object,
        m: Object,
    },
    components: {
        ChatMessageImages
    },
    name: 'MyMessage',
    data() {
        return {
            currentUser: '',
            currentShop: '',
        }
    },
    mounted: function () {
        this.currentUser = this.$store.getters['user/currentUser'] || {}
    },
    methods: {
        setMessageToReply(a) {
            this.$emit('setMessageToReply', a)
        },
    },
}
</script>
