<template>
<div class="card md:p-6 p-2 max-w-3xl mx-auto">

    <div class="flex justify-between items-start relative md:mb-4 mb-3">
        <div class="flex-1">
            <h2 class="text-xl font-bold"> Photos </h2>
            <nav class="responsive-nav style-2 md:m-0 -mx-4">
                <ul>
                    <li class="active">
                        <a href="Javascript:;"> Photos of you <span> {{ total_photos }}</span> </a>
                    </li>
                </ul>
            </nav>
        </div>
        <a v-if="thisUser['id'] == id" href="#offcanvas-create" uk-toggle="" class="flex items-center justify-center z-10 h-10 w-10 rounded-full bg-blue-600 text-white hover:text-white absolute right-0" data-tippy-placement="left" title="Save Photo" style="padding-left: 4px; padding-top: 4px;">
            <svg class="w-6 h-6" fill="currentColor" viewbox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clip-rule="evenodd"></path>
            </svg>
        </a>
    </div>

    <div class="grid md:grid-cols-4 sm:grid-cols-3 grid-cols-2 gap-3 mt-5">
        <div v-for="photo in photos">
            <div class="bg-green-400 max-w-full lg:h-44 h-36 rounded-lg relative overflow-hidden shadow uk-transition-toggle">
                <img v-if="photo.url" :src="photo.url" class="w-full h-full absolute object-cover inset-0">
                <img v-else :src="defaultImageUrl()" class="w-full h-full absolute object-cover inset-0">
                <!-- overly-->
                <div class="-bottom-12 absolute bg-gradient-to-b from-transparent h-1/2 to-gray-800 uk-transition-slide-bottom-small w-full"></div>
                <div class="absolute bottom-0 w-full p-3 text-white uk-transition-slide-bottom-small">
                    <div class="text-base"> {{ photo.description }} </div>
                    <div class="flex justify-between text-xs">
                        <a href="Javascript:;" @click="likePhoto(photo.id)"> Like</a>
                        <a href="Javascript:;"> </a>
                        <a href="Javascript:;"> </a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="flex justify-center mt-6" v-if="photos.length > 0">
        <a @click="loadMore" href="Javascript:;" class="bg-white dark:bg-gray-900 font-semibold my-3 px-6 py-2 rounded-full shadow-md dark:bg-gray-800 dark:text-white">
            Load more ..</a>
    </div>

    <create-photo-post v-if="thisUser['id'] == id" @savePhoto="savePhoto"></create-photo-post>

</div>
</template>

<script>
import {
    ApiService
} from '@/services/api.service'
import SingleFileUpload from '@/components/SingleFileUpload'
import CreatePhotoPost from '@/components/CreatePhotoPost'
export default {
    name: "PhotoScreen",
    data() {
        return {
            photos: [],
            page: 0,
            photo_reach: 'Public',
            photo_description: '',
            image: '',
            id: '',
            thisUser: {},
            total_photos: '',
            pendingRequest: false,
        };
    },
    props: {
        currentUser: Object,
    },
    components: {
        SingleFileUpload,
        CreatePhotoPost
    },
    mounted: function () {
        this.thisUser = this.$store.getters["user/currentUser"];
        // console.log("currentUser",this.currentUser,this.thisUser)
        let id = this.$route.params['id'] || this.thisUser['id']
        this.id = id
        this.getPhotos(this.id)
    },
    methods: {
        async getPhotos(id) {
            if (this.pendingRequest == true) {
                // console.log('Pending Req')
                return
            }
            this.pendingRequest = true
            let apiResponse = await ApiService.GetFeedPhotos(this.page, id)
            if (apiResponse['status'] != 'success') {} else {
                this.total_photos = apiResponse['data']['total_photos']
                let photos = apiResponse['data']['photos']
                if(photos.length > 0){
                    this.page++
                }
                // console.log('this.page',this.page)
                if(this.photos.length == 0){
                    this.photos = photos
                }else{
                    if(photos.length > 0){
                        this.photos = this.photos.concat(photos)
                    }
                }
            }
            this.pendingRequest = false
        },
        async likePhoto(id) {
            if (this.pendingRequest == true) {
                // console.log('Pending Req')
                return
            }
            this.pendingRequest = true
            let apiResponse = await ApiService.LikePhoto(id)
            this.$store.commit("toast/Notify", {
                type: apiResponse["status"],
                message: apiResponse["message"],
            });
            if (apiResponse['status'] != 'success') {} else {
                this.restart()
            }
            this.pendingRequest = false
        },
        async savePhoto(photo_reach, photo_description, image) {
            if (this.pendingRequest == true) {
                // console.log('Pending Req')
                return
            }
            this.pendingRequest = true
            let apiResponse = await ApiService.SavePhoto(photo_reach, photo_description, image)
            this.$store.commit("toast/Notify", {
                type: apiResponse["status"],
                message: apiResponse["message"],
            });
            if (apiResponse['status'] != 'success') {} else {
                this.photo_reach = ''
                this.photo_description = ''
                this.image = ''

                this.restart()
            }
            this.pendingRequest = false
        },
        async restart() {
            this.page = 0
            this.photos = []
            this.getPhotos(this.id)
        },
        async loadMore() {
            this.getPhotos(this.id)
        },
    }
};
</script>

<style>
    .photoUpload123 .upload-input {
        height: 138px !important;
    }
</style>
