<template>
<div v-if="images_raw && images_raw.length > 0" class="mb-4">
    <template v-if="noOfImages == 1">
        <div class="uk-grid-xs" uk-grid>
            <div class="uk-width-1-1" v-for="(feedImage, index) in images_raw">
                <a href="javascript:;" :href="feedImage" :style="'background-image: url('+feedImage+');'">
                    <img v-bind:src="feedImage" alt="" class="object-cover mb-0 m-auto" style="max-width: 100%;object-position: top;">
                </a>
                <a href="javascript:;" v-on:click="removeImage(index)">remove image</a>
            </div>
        </div>
    </template>
    <template v-else-if="noOfImages == 2">
        <template v-if="imageLayout == 'long'">
            <div class="uk-grid-xs" uk-grid>
                <div class="uk-width-1-2" v-for="(feedImage, index) in images_raw">
                    <a href="javascript:;" :href="feedImage" :style="'background-image: url('+feedImage+');'">
                        <img v-bind:src="feedImage" alt="" class="object-cover mb-0 m-auto height_one" style="height_old: 500px;width: 100%;object-fit: cover;object-position: top;">
                    </a>
                    <a href="javascript:;" v-on:click="removeImage(index)">remove image</a>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="uk-grid-xs" uk-grid>
                <div class="uk-width-1-1" v-for="(feedImage, index) in images_raw">
                    <a href="javascript:;" :href="feedImage" :style="'background-image: url('+feedImage+');'">
                        <img v-bind:src="feedImage" alt="" class="object-cover mb-0 m-auto height_two" style="height_old: 250px;object-fit: cover;width: 100%;object-position: top;">
                    </a>
                    <a href="javascript:;" v-on:click="removeImage(index)">remove image</a>
                </div>
            </div>
        </template>
    </template>
    <template v-else-if="noOfImages == 4">
        <div class="uk-grid-xs" uk-grid>
            <div class="uk-width-1-2" v-for="(feedImage, index) in images_raw">
                <a href="javascript:;" :href="feedImage" :style="'background-image: url('+feedImage+');'">
                    <img v-bind:src="feedImage" alt="" class="object-cover mb-0 m-auto height_two" style="object-fit: cover;height_old: 250px;width: 100%;object-position: center center;">
                </a>
                <a href="javascript:;" v-on:click="removeImage(index)">remove image</a>
            </div>
        </div>
    </template>
    <template v-else-if="noOfImages == 3">
        <template v-if="imageLayout == 'long'">
            <div class="uk-grid-xs" uk-grid>
                <div class="uk-width-1-2">
                    <a href="javascript:;" :href="images_raw[0]" :style="'background-image: url('+images_raw[0]+');'">
                        <img v-bind:src="images_raw[0]" alt="" class="object-cover mb-0 m-auto height_one" style="height_old: 500px;width: 100%;object-fit: cover;object-position: top;">
                    </a>
                    <a href="javascript:;" v-on:click="removeImage(0)">remove image</a>
                </div>
                <div class="uk-width-1-2">
                    <a href="javascript:;" :href="images_raw[1]" :style="'background-image: url('+images_raw[1]+');'">
                        <img v-bind:src="images_raw[1]" alt="" class="object-cover mb-0 m-auto height_two_small" style="height_old: 242px;object-fit: cover;width: 100%;object-position: top;">
                    </a>
                    <a href="javascript:;" v-on:click="removeImage(1)">remove image</a>
                    <a href="javascript:;" :href="images_raw[2]" :style="'background-image: url('+images_raw[2]+');'">
                        <img v-bind:src="images_raw[2]" alt="" class="object-cover mb-0 m-auto height_two_small" style="height_old: 242px;object-fit: cover;width: 100%;object-position: top;">
                    </a>
                    <a href="javascript:;" v-on:click="removeImage(2)">remove image</a>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="uk-grid-xs" uk-grid>
                <div class="uk-width-1-2">
                    <a href="javascript:;" :href="images_raw[0]" :style="'background-image: url('+images_raw[0]+');'">
                        <img v-bind:src="images_raw[0]" alt="" class="object-cover mb-0 m-auto height_two" style="object-fit: cover;height_old:250px;width: 100%;object-position: top;">
                    </a>
                    <a href="javascript:;" v-on:click="removeImage(0)">remove image</a>
                </div>
                <div class="uk-width-1-2">
                    <a href="javascript:;" :href="images_raw[1]" :style="'background-image: url('+images_raw[1]+');'">
                        <img v-bind:src="images_raw[1]" alt="" class="object-cover mb-0 m-auto height_two" style="object-fit: cover;height_old:250px;width: 100%;object-position: top;">
                    </a>
                    <a href="javascript:;" v-on:click="removeImage(1)">remove image</a>
                </div>
                <div class="uk-width-1-1">
                    <a href="javascript:;" :href="images_raw[2]" :style="'background-image: url('+images_raw[2]+');'">
                        <img v-bind:src="images_raw[2]" alt="" class="object-cover mb-0 m-auto height_two" style="object-fit: cover;height_old:250px;width: 100%;object-position: top;">
                    </a>
                    <a href="javascript:;" v-on:click="removeImage(2)">remove image</a>
                </div>
            </div>
        </template>
    </template>
    <template v-else-if="noOfImages == 5">
        <template v-if="imageLayout == 'long'">
            <div class="uk-grid-xs" uk-grid>
                <div class="uk-width-1-2">
                    <a href="javascript:;" :href="images_raw[0]" :style="'background-image: url('+images_raw[0]+');'">
                        <img v-bind:src="images_raw[0]" alt="" class="object-cover mb-0 m-auto height_two_smaller" style="object-fit: cover;height_old: 225px;width: 100%;object-position: center center;">
                    </a>
                    <a href="javascript:;" :href="images_raw[1]" :style="'background-image: url('+images_raw[1]+');'">
                        <img v-bind:src="images_raw[1]" alt="" class="object-cover mb-0 m-auto height_two_smaller" style="object-fit: cover;height_old: 225px;width: 100%;object-position: center center;">
                    </a>
                </div>
                <div class="uk-width-1-2">
                    <a href="javascript:;" :href="images_raw[2]" :style="'background-image: url('+images_raw[2]+');'">
                        <img v-bind:src="images_raw[2]" alt="" class="object-cover mb-0 m-auto height_three" style="object-fit: cover;height_old: 150px;width: 100%;object-position: center center;">
                    </a>
                    <a href="javascript:;" :href="images_raw[3]" :style="'background-image: url('+images_raw[3]+');'">
                        <img v-bind:src="images_raw[3]" alt="" class="object-cover mb-0 m-auto height_three" style="object-fit: cover;height_old: 150px;width: 100%;object-position: center center;">
                    </a>
                    <a href="javascript:;" :href="images_raw[4]" :style="'background-image: url('+images_raw[4]+');'">
                        <img v-bind:src="images_raw[4]" alt="" class="object-cover mb-0 m-auto height_three" style="object-fit: cover;height_old: 150px;width: 100%;object-position: center center;">
                    </a>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="uk-grid-xs" uk-grid>
                <div class="uk-width-1-2">
                    <a href="javascript:;" :href="images_raw[0]" :style="'background-image: url('+images_raw[0]+');'">
                        <img v-bind:src="images_raw[0]" alt="" class="object-cover mb-0 m-auto height_two" style="object-fit: cover;height_old: 250px;width: 100%;object-position: top;">
                    </a>
                    <a href="javascript:;" v-on:click="removeImage(0)">remove image</a>
                </div>
                <div class="uk-width-1-2">
                    <a href="javascript:;" :href="images_raw[1]" :style="'background-image: url('+images_raw[1]+');'">
                        <img v-bind:src="images_raw[1]" alt="" class="object-cover mb-0 m-auto height_two" style="object-fit: cover;height_old: 250px;width: 100%;object-position: top;">
                    </a>
                    <a href="javascript:;" v-on:click="removeImage(1)">remove image</a>
                </div>
                <div class="uk-width-1-1">
                </div>
                <div class="uk-width-1-3">
                    <a href="javascript:;" :href="images_raw[2]" :style="'background-image: url('+images_raw[2]+');'">
                        <img v-bind:src="images_raw[2]" alt="" class="object-cover mb-0 m-auto height_two" style="object-fit: cover;height_old: 250px;width: 100%;object-position: top;">
                    </a>
                    <a href="javascript:;" v-on:click="removeImage(2)">remove image</a>
                </div>
                <div class="uk-width-1-3">
                    <a href="javascript:;" :href="images_raw[3]" :style="'background-image: url('+images_raw[3]+');'">
                        <img v-bind:src="images_raw[3]" alt="" class="object-cover mb-0 m-auto height_two" style="object-fit: cover;height_old: 250px;width: 100%;object-position: top;">
                    </a>
                    <a href="javascript:;" v-on:click="removeImage(3)">remove image</a>
                </div>
                <div class="uk-width-1-3">
                    <a href="javascript:;" :href="images_raw[4]" :style="'background-image: url('+images_raw[4]+');'">
                        <img v-bind:src="images_raw[4]" alt="" class="object-cover mb-0 m-auto height_two" style="object-fit: cover;height_old: 250px;width: 100%;object-position: top;">
                    </a>
                    <a href="javascript:;" v-on:click="removeImage(4)">remove image</a>
                </div>
            </div>
        </template>
    </template>
    <template v-else>
        <div class="uk-grid-xs" uk-grid>
            <div class="uk-width-1-2" v-for="(feedImage, index) in images_raw">
                <a href="javascript:;" :href="feedImage" :style="'background-image: url('+feedImage+');'">
                    <img v-bind:src="feedImage" alt="" class="object-cover mb-0 m-auto" style="object-fit: cover;max-height: 150px;width: 100%;object-position: center center;">
                </a>
                <a href="javascript:;" v-on:click="removeImage(index)">remove image</a>
            </div>
        </div>
    </template>
</div>
</template>

<script>
import {
    ApiService
} from '@/services/api.service'
import GoogleBannerAd from '@/include/Dashboard/Feed/GoogleBannerAd'
import VerifiedBadge from '@/components/VerifiedBadge'

export default {
    name: "NewImagesComponent",
    props: {
        images: Array,
    },
    data() {
        return {
            currentUser: '',
            images_raw: '',
            imageLayout: 'short',
            noOfImages: 0,
        };
    },
    components: {
        GoogleBannerAd,
        VerifiedBadge,
    },
    mounted: function () {
        this.currentUser = this.$store.getters["user/currentUser"];
        this.images_raw = this.images
        if(this.images_raw){
            this.syncNoOfImages()
        }
        // console.log('noOfImages', this.noOfImages)
    },
    methods: {
        triggerClick() {
            // console.log('TCLIECK')
        },
        syncNoOfImages(){
            this.noOfImages = this.images_raw.length
            if(this.noOfImages == 2){
                this.imageLayout = 'long'
            }else if(this.noOfImages == 3){
                this.imageLayout = 'long'
            }else if(this.noOfImages == 5){
                this.imageLayout = 'short'
            }
        },
        removeImage(index){
            this.$emit('removeImage', index)
            this.syncNoOfImages()
        }
    }
};
</script>

<style scoped>
.height_one {
    height: 500px;
}
.height_two {
    height: 250px;
}
.height_two_small {
    height: 242px;
}
.height_two_smaller {
    height: 225px;
}
.height_three {
    height: 150px;
}

.uk-grid-xs, .uk-grid-column-xs {
    margin-left: -6px;
}

@media (max-width: 540px) {
    .height_one {
        height: 250px;
    }
    .height_two {
        height: 125px;
    }
    .height_two_small {
        height: 117px;
    }
    .height_two_smaller {
        height: 112.5px;
    }
    .height_three {
        height: 75px;
    }
  }
</style>

