<template>
<div id="wrapper">

    <Header />

    <sidebar></sidebar>

    <!-- Main Contents -->
    <div class="main_content">
        <div class="mcontainer">

            <!-- Profile cover -->
            <div class="profile user-profile">

                <div class="profiles_banner" uk-lightbox>
                    <template v-if="bannerPhoto">
                        <a v-if="bannerPhoto" target="_blank" :href="bannerPhoto">
                            <img :src="bannerPhoto" alt="">
                        </a>
                    </template>
                    <template v-else>
                        <a v-if="currentUser['banner_photo']" target="_blank" :href="currentUser['banner_photo']">
                            <img :src="currentUser['banner_photo']" alt="">
                        </a>
                        <img v-else src="/assets/images/avatars/profile-cover.jpg" alt="">
                    </template>
                    <div class="bannerCamera" @click="openBannerChange"> 
                        <i class="fa fa-camera"></i>
                    </div>
                    <div class="profile_action absolute bottom-0 right-0 space-x-1.5 p-3 text-sm z-50 hidden lg:flex profilePage123">
                        <template v-if="bannerPhoto">
                            <a @click="saveBannerPhoto" href="JavaScript:Void(0);" class="mouse-pointer flex items-center justify-center h-8 px-3 rounded-md bg-gray-700 bg-opacity-70 text-white hover:text-white space-x-1.5"> 
                                <span> Save Banner Photo  </span>
                            </a>
                            <a @click="removeBannerPhoto" href="JavaScript:Void(0);" class="mouse-pointer flex items-center justify-center h-8 px-3 rounded-md bg-gray-700 bg-opacity-70 text-white hover:text-white space-x-1.5"> 
                                <span> Remove Banner Photo  </span>
                            </a>
                        </template>
                        <single-file-upload id="banner_profile_change" v-else type="image" @uploadSuccess="onBannerUploaded">
                            <a href="JavaScript:Void(0);" class="mouse-pointer flex items-center justify-center h-8 px-3 rounded-md bg-gray-700 bg-opacity-70 text-white hover:text-white space-x-1.5"> 
                                <span> Change Banner Photo  </span>
                            </a>
                        </single-file-upload>
                        <template v-if="profilePhoto">
                            <a @click="saveProfilePhoto" href="JavaScript:Void(0);" class="mouse-pointer flex items-center justify-center h-8 px-3 rounded-md bg-gray-700 bg-opacity-70 text-white hover:text-white space-x-1.5"> 
                                <span> Save Profile Photo  </span>
                            </a>
                            <a @click="removeProfilePhoto" href="JavaScript:Void(0);" class="mouse-pointer flex items-center justify-center h-8 px-3 rounded-md bg-gray-700 bg-opacity-70 text-white hover:text-white space-x-1.5"> 
                                <span> Remove Profile Photo  </span>
                            </a>
                        </template>
                        <single-file-upload id="user_profile_change" v-else type="image" @uploadSuccess="onImageUploaded">
                            <a href="JavaScript:Void(0);" class="mouse-pointer flex items-center justify-center h-8 px-3 rounded-md bg-gray-700 bg-opacity-70 text-white hover:text-white space-x-1.5"> 
                                <span> Change Profile Photo </span>
                            </a>
                        </single-file-upload>
                    </div>
                </div>
                <div class="profiles_content">

                    <div class="profile_avatar">
                        <div class="profile_avatar_holder" uk-lightbox> 
                            <template v-if="profilePhoto">
                                <a target="_blank" :href="profilePhoto">
                                    <img :src="profilePhoto" alt="">
                                </a>
                            </template>
                            <template v-else>
                                <a v-if="currentUser['profile_photo']" target="_blank" :href="currentUser['profile_photo']">
                                    <img :src="currentUser['profile_photo']" alt="">
                                </a>
                                <img v-else :src="defaultImageUrl()" alt="">
                            </template>
                        </div>
                        <div v-if="thisUser.online == 'true'" class="user_status status_online"></div>
                        <div class="icon_change_photo" @click="openProfileChange"> 
                            <i class="fa fa-camera"></i>
                        </div>
                    </div>

                    <div class="profile_info col-sm-8 col-xs-12">
                        <h1 class="text-center"> 
                            {{ currentUser['name'] }} 
                            <verified-badge v-if="currentUser['verified'] == 'true'" mt="one"></verified-badge>
                            <template v-else>
                                <a class="d-inline mouse-pointer" style="font-size: 12px;margin-top: -10px;position: absolute;" href="javascript:;" uk-toggle="target: #verification-application">
                                    <small>get verified</small>
                                </a>
                            </template>
                        </h1>
                        <div class="">
                            <div class="line mb-4">
                                <input id="user_profile_url" v-model="user_profile_url" readonly="readonly" disabled="disabled">
                                <p class="text-right">
                                    <a href="javascript:;" @click="copyToClipboard('#user_profile_url')">copy</a>
                                </p>
                            </div>
                            <div class="line mt-4">
                                <input id="user_reg_url" v-model="user_reg_url" readonly="readonly" disabled="disabled">
                                <p class="text-right">
                                    <a href="javascript:;" @click="copyToClipboard('#user_reg_url')">copy</a>
                                </p>
                            </div>
                        </div>
                        <!-- <p> {{ currentUser['email'] }} </p>
                        <p> {{ currentUser['bio'] }} <a href="Javascript:Void()" uk-toggle="target: #update-user-data">Edit </a></p> -->
                    </div>

                </div>

                <div class="flex justify-between lg:border-t border-gray-100 flex-col-reverse lg:flex-row pt-2">
                    <nav class="responsive-nav pl-3">
                        <ul uk-switcher="connect: #timeline-tab; animation: uk-animation-fade">
                            <li><a href="javascript:;">Timeline</a></li>
                            <li><a href="javascript:;">Friend <span>{{ followers }}</span> </a></li>
                            <li><a href="javascript:;">Photos </a></li>
                            <li><a href="javascript:;">Pages</a></li> 
                            <li><a href="javascript:;">Groups</a></li> 
                            <!-- <li><a href="#">Videos</a></li>  -->
                        </ul>
                    </nav>

                    <!-- button actions -->
                    <div class="flex items-center space-x-1.5 flex-shrink-0 pr-4 mb-2 justify-center order-1 relative">
                        
                        <!-- add story -->
                        <a v-if="follow == 'no'" @click="followerUser" href="Javascript:;" class="flex items-center justify-center h-10 px-5 rounded-md bg-blue-600 text-white space-x-1.5 text-white mouse-pointer hover:text-white" > 
                            <i class="fa fa-plus-circle"></i>
                            <span> Follow </span>
                        </a>
                        <a v-if="follow == 'yes'" @click="unfollowerUser" href="Javascript:;" class="flex items-center justify-center h-10 px-5 rounded-md bg-blue-600 text-white space-x-1.5 text-white mouse-pointer hover:text-white" > 
                            <i class="fa fa-minus-circle"></i>
                            <span> Following </span>
                        </a>
                        
                        <!-- search icon -->
                        <!-- <a href="#" class="flex items-center justify-center h-10 w-10 rounded-md bg-gray-100" uk-toggle="target: #profile-search;animation: uk-animation-slide-top-small"> 
                            <i name="" class="fa fa-search text-xl"></i>
                        </a> -->
                        <!-- search dropdown -->
                        <div class="absolute right-3 bg-white z-10 w-full flex items-center border rounded-md" id="profile-search" hidden="">
                            <!-- <input type="text" placeholder="Search.." class="flex-1">
                            <ion-icon name="close-outline" class="text-2xl hover:bg-gray-100 p-1 rounded-full mr-2 cursor-pointer" uk-toggle="target: #profile-search;animation: uk-animation-slide-top-small"></ion-icon> -->
                        </div>
                        
                        <!-- more icon -->
                        <!-- <a href="#" class="flex items-center justify-center h-10 w-10 rounded-md bg-gray-100"> 
                            <i name="ellipsis-horizontal" class="fa fa-ellipsis-h text-xl"></i>
                        </a> -->
                        <!-- more drowpdown -->
                        <!-- <div class="bg-white w-56 shadow-md mx-auto p-2 mt-12 rounded-md text-gray-500 hidden border border-gray-100 dark:bg-gray-900 dark:text-gray-100 dark:border-gray-700" uk-drop="mode: click;pos: bottom-right;animation: uk-animation-slide-bottom-small; offset:5">
                                <ul class="space-y-1">
                                <li> 
                                    <a href="#" class="flex items-center px-3 py-2 hover:bg-gray-100 hover:text-gray-800 rounded-md dark:hover:bg-gray-800">
                                        <ion-icon name="arrow-redo-outline" class="pr-2 text-xl"></ion-icon> Share Profile
                                    </a> 
                                </li>
                                <li> 
                                    <a href="#" class="flex items-center px-3 py-2 hover:bg-gray-100 hover:text-gray-800 rounded-md dark:hover:bg-gray-800">
                                        <ion-icon name="create-outline" class="pr-2 text-xl"></ion-icon>  Account setting 
                                    </a> 
                                </li>
                                <li> 
                                    <a href="#" class="flex items-center px-3 py-2 hover:bg-gray-100 hover:text-gray-800 rounded-md dark:hover:bg-gray-800">
                                        <ion-icon name="notifications-off-outline" class="pr-2 text-lg"></ion-icon>   Disable notifications
                                    </a> 
                                </li> 
                                <li> 
                                    <a href="#" class="flex items-center px-3 py-2 hover:bg-gray-100 hover:text-gray-800 rounded-md dark:hover:bg-gray-800">
                                        <ion-icon name="star-outline" class="pr-2 text-xl"></ion-icon>  Add favorites 
                                    </a> 
                                </li>
                                <li>
                                    <hr class="-mx-2 my-2 dark:border-gray-800">
                                </li>
                                <li> 
                                    <a href="#" class="flex items-center px-3 py-2 text-red-500 hover:bg-red-50 hover:text-red-500 rounded-md dark:hover:bg-red-600">
                                        <ion-icon name="stop-circle-outline" class="pr-2 text-xl"></ion-icon>  Unfriend
                                    </a> 
                                </li>
                                </ul>
                        </div> -->
                    </div>

                </div>

            </div>
            
            <div class="uk-switcher lg:mt-8 mt-4" id="timeline-tab">

                <!-- Timeline -->
                <timeline-screen :currentUser="currentUser" :followers_count="followers" :followings_count="followings" :followers_list="followers_list_6" :followings_list="followings_list"></timeline-screen>
                        
                <!-- Friends  -->
                <friend-screen :currentUser="currentUser"></friend-screen>

                <!-- Photos  -->
                <photo-screen :currentUser="currentUser"></photo-screen>

                <!-- Pages  -->
                <page-screen :currentUser="currentUser"></page-screen>

                <!-- Groups  -->
                <group-screen :currentUser="currentUser"></group-screen>

                    <!-- Videos -->
                <video-screen :currentUser="currentUser"></video-screen>


            </div>

        </div>
    </div>

    <update-user-data></update-user-data>

    <dashboard-widgets page="timeline"></dashboard-widgets>
    <verification-application></verification-application>
    <div id="progressLoader">
        <progress-loader></progress-loader>
    </div>

</div>
</template>

<script>
import { VueEditor } from "vue2-editor";
import { ApiService } from '@/services/api.service'
import Header from '@/include/Dashboard/Header.vue'
import Sidebar from '@/include/Dashboard/Sidebar.vue'
import DashboardWidgets from '@/components/DashboardWidgets'
import OpenChatBox from '@/include/Modals/OpenChatBox.vue'
import ProfileIcon from '@/include/Dashboard/Feed/ProfileIcon'
import ProgressLoader from '@/components/ProgressLoader'
import TimelineScreen from '@/include/Dashboard/Timeline/TimelineScreen'
import FriendScreen from '@/include/Dashboard/Timeline/FriendScreen'
import PhotoScreen from '@/include/Dashboard/Timeline/PhotoScreen'
import PageScreen from '@/include/Dashboard/Timeline/PageScreen'
import GroupScreen from '@/include/Dashboard/Timeline/GroupScreen'
import VideoScreen from '@/include/Dashboard/Timeline/VideoScreen'
import SingleFileUpload from '@/components/SingleFileUpload'
import IonIcon from '@/components/IonIcon'
import axios from 'axios'
import DobSelectorComponent from '@/components/DobSelectorComponent'
import VerifiedBadge from '@/components/VerifiedBadge'
import VerificationApplication from '@/include/Modals/VerificationApplication'
import UpdateUserData from '@/include/Modals/UpdateUserData'

export default {
    name: 'Timeline',
    components: {
        Header,
        Sidebar,
        DashboardWidgets,
        ProgressLoader,
        VueEditor,
        TimelineScreen,
        FriendScreen,
        PhotoScreen,
        PageScreen,
        GroupScreen,
        VideoScreen,
        SingleFileUpload,
        DobSelectorComponent,
        IonIcon,
        VerifiedBadge,
        VerificationApplication,
        UpdateUserData,
    },
    data() {
        return {
            user: '',
            feeds: '',
            followers_list: [],
            followers_list_6: [],
            followings_list: [],
            currentUser: {},
            thisUser: {},
            profilePhoto: '',
            bannerPhoto: '',
            followings: '',
            followers: '',
            followers_int: 0,
            follow: '',
            id: '',
            dob: '',
            new_dob: '',
            new_bio: '',
            user_profile_url: '',
            user_reg_url: '',
            dob_day: '',
            dob_month: '',
            dob_year: '',
            country: '',
            state: '',
            city: '',
            gender: '',
            phone: '',
            countryName: true,
            regionName: true,
        }
    },
    mounted () {
        this.thisUser = this.$store.getters["user/currentUser"];
        this.currentUser = this.currentUser
        this.id = this.thisUser['id']
        this.getUserInfo()
        this.userFollowership()
    },
    methods: {
        async getUserInfo(){
            this.toggleProgress('show')
            let apiResponse = await ApiService.GetMe();
            // console.log('GetMe',apiResponse)
            if(apiResponse['status'] == 'success'){
                this.currentUser = apiResponse['data']['user']
                this.followings = apiResponse['data']['followings_count'].toString()
                this.followers = apiResponse['data']['followers_count'].toString()
                this.follow = apiResponse['data']['follow']

                // console.log(this.currentUser,'current user')
                if(this.currentUser){
                    this.new_bio = this.currentUser['bio']
                    this.phone = this.currentUser['phone']
                    this.country = this.currentUser['country']
                    this.city = this.currentUser['city']
                    this.state = this.currentUser['state']
                    this.gender = this.currentUser['gender']
                    if(this.currentUser['dob']){
                        let {day, month, year} = this.pullDobData(this.currentUser['dob'])
                        this.dob_day = day
                        this.dob_month = month
                        this.dob_year = year

                        this.setDob(this.dob_year+'-'+this.dob_month+'-'+this.dob_day)
                        this.dob = 'true'
                    }else{
                        this.dob = 'false'
                    }
                }
                this.setUserProfileUrl()
                this.setUserRegUrl()
            }
            this.toggleProgress('hide')
        },
        async userFollowership(){
            this.toggleProgress('show')
            let apiResponse = await ApiService.UserFollowership(this.id,0);
            // console.log('UserFollowership',apiResponse)
            if(apiResponse['status'] == 'success'){
                this.followings = apiResponse['data']['followings_count'].toString()
                this.followers = apiResponse['data']['followers_count'].toString()
                this.follow = apiResponse['data']['follow']
                this.followers_list = apiResponse['data']['followers']
                this.followers_list_6 = this.followers_list.slice(0,6)
                this.followers_int = parseInt(this.followers)
            }
            this.toggleProgress('hide')
        },
        async saveBannerPhoto(){
            this.toggleProgress('show')
            let apiResponse = await ApiService.SaveBannerPhoto(this.bannerPhoto);
            // console.log('getUserInfo',apiResponse)
            if(apiResponse['status'] == 'success'){
                let currentUser = this.userDataToCommitToStore(apiResponse['user'])
                this.$store.commit('user/SAVE_CURRENT_USER', currentUser)
                location.href = ''
            }
            this.toggleProgress('hide')
        },
        async saveProfilePhoto(){
            this.toggleProgress('show')
            let apiResponse = await ApiService.SaveProfilePhoto(this.profilePhoto);
            // console.log('getUserInfo',apiResponse)
            if(apiResponse['status'] == 'success'){
                let currentUser = this.userDataToCommitToStore(apiResponse['user'])
                this.$store.commit('user/SAVE_CURRENT_USER', currentUser)
                location.href = ''
            }
            this.toggleProgress('hide')
        },
        async saveUserData(){
            this.toggleProgress('show')
            let apiResponse = await ApiService.SaveUserData(this.new_dob, this.new_bio, this.country, this.state, this.city, this.phone, this.gender);
            // console.log('saveUserData',apiResponse)
            this.$store.commit("toast/Notify", {
                type: apiResponse["status"],
                message: apiResponse["message"],
            });
            if(apiResponse['status'] == 'success'){
                // location.href = ''
            }
            this.toggleProgress('hide')
        },
        setUserProfileUrl(){
            let user_profile_url = ''
            let url = this.$store.getters["host/seoUrl"]+'/user/'+this.currentUser['id']
            user_profile_url = url 
            this.user_profile_url = user_profile_url
        },
        setUserRegUrl(){
            let user_reg_url = ''
            let url = location.href 
            if(url.search('dashboard/') > -1){
                user_reg_url = url.split('dashboard/')[0]+'register/'+this.currentUser['id']
            }else{
                this.$store.commit("toast/Notify", {
                    type: 'warning',
                    message: 'we could not find this page',
                });
                return
            }
            this.user_reg_url = user_reg_url
        },
        onImageUploaded(response) {
            // Handle files like:
            // console.log('::::',response)
            if (response['status'] == 'success') {
                this.profilePhoto = response['data']['file_name']
            }
            this.$store.commit("toast/Notify", {
                type: response["status"],
                message: response["message"],
            });
            this.saveProfilePhoto()
        },
        onBannerUploaded(response) {
            // Handle files like:
            // console.log('::::',response)
            if (response['status'] == 'success') {
                this.bannerPhoto = response['data']['file_name']
            }
            this.$store.commit("toast/Notify", {
                type: response["status"],
                message: response["message"],
            });
            this.saveBannerPhoto()
        },
        removeBannerPhoto(){
            this.bannerPhoto = ''
        },
        removeProfilePhoto(){
            this.profilePhoto = ''
        },
        async setDob(a){
            // console.log(":::::::::",a)
            this.new_dob = a
        },
    },
}
</script>

<style>
    .profilePage123 input {
        width: 157px !important;
        height: 32px !important;
    }

    .bannerCamera {
        position: absolute;
        width: 40px;
        height: 40px;
        border-radius: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        display: flex;
        border: 2px solid #FFF;
        font-size: 16px;
        cursor: pointer;
        top: 0;
        right: 0;
        background-color: #c5ccd4;
        z-index: 10;
    }
        
    @media (max-width: 640px) {
        .line {
            height: 55px !important;
        }
        .flex-xs > div {
            display: flex;
        }
    }
</style>
