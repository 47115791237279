<template>
<div v-if="thisFeed.audios" class="feed_audios mb-2">
    <!-- <av-media v-for="feedAudio in thisFeed.audios" :key="feedAudio.id" :mediaStream="feedAudio.url"></av-media> -->
    <template v-for="feedAudio in thisFeed.audios.slice(0, audio_length)">
        <audio-player-component class="feed_audio" :src="feedAudio.url"></audio-player-component>
    </template>
    <p v-if="thisFeed.audios.length > 1" class="ml-3">
        <a class="custom_link" v-if="is_less_audio == 'false'" href="javascript:;" @click="moreLessAudioManager()">show less audio(s)</a>
        <a class="custom_link" v-else href="javascript:;" @click="moreLessAudioManager()">show more audio(s)</a>
    </p>
</div>
</template>

<script>
import {
    ApiService
} from '@/services/api.service'
import GoogleBannerAd from '@/include/Dashboard/Feed/GoogleBannerAd'
import VerifiedBadge from '@/components/VerifiedBadge'
import AudioPlayerComponent from '@/components/AudioPlayerComponent'

export default {
    name: "AudiosComponent",
    props: {
        feed: Object,
    },
    data() {
        return {
            currentUser: '',
            thisFeed: '',
            is_less_audio: 'true',
            audio_length: 1,
        };
    },
    components: {
        GoogleBannerAd,
        VerifiedBadge,
        AudioPlayerComponent,
    },
    mounted: function () {
        this.currentUser = this.$store.getters["user/currentUser"];
        this.thisFeed = this.feed
    },
    methods: {
        moreLessAudioManager() {
            if (this.is_less_audio == 'true') {
                this.audio_length = this.thisFeed.audios.length
                this.is_less_audio = 'false'
            } else {
                this.audio_length = 1
                this.is_less_audio = 'true'
            }
        },
        triggerClick() {
            // console.log('TCLIECK')
        },
    }
};
</script>

<style >
</style>
