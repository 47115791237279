<template>
<div id="wrapper">

    <Header />

    <sidebar></sidebar>

    <!-- Main Contents -->
    <div class="main_content">
        <div class="mcontainer">

            <!--  breadcrumb -->
            <div class="breadcrumb-area py-0">
                <div class="breadcrumb">
                    <ul class="m-0">
                        <li>
                            <a href="/dashboard/products">Products</a>
                        </li>
                        <li class="active">
                            <a href="">Update Shop </a>
                        </li>
                    </ul>
                </div>
            </div>

            <!-- create page-->
            <div class="max-w-2xl m-auto shadow-md rounded-md bg-white lg:mt-20">

                <!-- form header -->
                <div class="text-center border-b border-gray-100 py-6">
                    <h3 class="font-bold text-xl"> Update Shop - {{ shop.name }} </h3>
                </div>

                <!-- form body -->
                <div class="p-10 space-y-7">

                    <div>
                        <label>Name</label>
                    </div>
                    <div class="line" style="margin-top: 5px">
                        <input v-model="name" class="line__input" id="name" name="name" type="text" onkeyup="this.setAttribute('value', this.value);" value="" autocomplete="off">
                        <span for="name" class="line__placeholder">  </span>
                    </div>

                    <div>
                        <label> Shop URL NAME(https://app.vitisocial.com/shop/{SHOP_URL_NAME})</label>
                    </div>
                    <div class="line" style="margin-top: 5px">
                        <input v-model="shop_unique_name" class="line__input" id="shop_unique_name" name="shop_unique_name" type="text" onkeyup="this.setAttribute('value', this.value);" value="" autocomplete="off">
                        <span for="shop_unique_name" class="line__placeholder"> </span>
                    </div>
                    <div class="flex items-center">
                        <div class="mr-2">
                            <p>Shop Image</p>
                            <img v-if="image" :src="image" class="postImgheight" />
                            <img v-else :src="defaultPostUrl()" class="postImgheight" />
                        </div>
                        <single-file-upload type="image" @uploadSuccess="onImageUploaded">
                            <p>Select<br /> Image</p>
                        </single-file-upload>
                    </div>
                    <div class="grid lg:grid-cols-2 gap-3">
                        <div>
                            <label class="mb-0"> Country </label>
                            <country-select
                                :countryName="countryName"
                                v-model="country"
                                :country="country"
                                topCountry="Nigeria"
                            />
                        </div>
                        <div>
                            <label class="mb-0"> States: </label>
                            <region-select
                                :countryName="countryName"
                                :regionName="regionName"
                                v-model="state"
                                :country="country"
                                :region="state"
                            />
                        </div>
                    </div>

                    <div>
                        <label>City</label>
                    </div>
                    <div class="line" style="margin-top: 5px">
                        <input v-model="city" class="line__input" id="city" name="city" type="text" onkeyup="this.setAttribute('value', this.value);" value="" autocomplete="off">
                        <span for="city" class="line__placeholder">  </span>
                    </div>

                    <div>
                        <label>Address</label>
                    </div>
                    <div class="line" style="margin-top: 5px">
                        <input v-model="address" class="line__input" id="address" name="address" type="phone" onkeyup="this.setAttribute('value', this.value);" value="" autocomplete="off">
                        <span for="address" class="line__placeholder">  </span>
                    </div>
                    
                    <div class="">
                        <label for=""> Description </label>
                        <!-- <vue-editor id="editor" v-model="description" useCustomImageHandler @image-added="handleImageAdded"></vue-editor> -->
                        <textarea v-model="description" class="bg-smoke h-32 p-2" id="" name="" type="text" onkeyup="this.setAttribute('value', this.value);" value="" autocomplete="off" style="background-color: #f3f4f6 !important;"></textarea>
                        <!-- <span for="username" class="line__placeholder"> Description </span> -->
                    </div>

                </div>

                <!-- form footer -->
                <div class="border-t flex justify-between lg:space-x-10 p-7 bg-gray-50 rounded-b-md">
                    <button type="button" class="button lg:w-1/2" @click="updateShop">
                        Update Shop
                    </button>
                </div>

            </div>

        </div>
    </div>

    <progress-loader v-if="requestProgress == 'true'"></progress-loader>
    <dashboard-widgets page="update-shop"></dashboard-widgets>

</div>
</template>

<script>
import {
    VueEditor
} from "vue2-editor";
import {
    ApiService
} from '@/services/api.service'
import Header from '@/include/Dashboard/Header.vue'
import Sidebar from '@/include/Dashboard/Sidebar.vue'
import DashboardWidgets from '@/components/DashboardWidgets'
import OpenChatBox from '@/include/Modals/OpenChatBox.vue'
import Feeds from '@/include/Dashboard/Feeds.vue'
import ProfileIcon from '@/include/Dashboard/Feed/ProfileIcon'
import ProgressLoader from '@/components/ProgressLoader'
import SingleFileUpload from '@/components/SingleFileUpload'
import axios from 'axios'

export default {
    name: 'UpdateShop',
    components: {
        Header,
        Sidebar,
        DashboardWidgets,
        OpenChatBox,
        Feeds,
        ProgressLoader,
        SingleFileUpload,
        VueEditor
    },

    data() {
        return {
            shop: '',
            image: '',
            category: '',
            brand_id: '',
            name: '',
            currency: 'USD',
            amount: '',
            description: '',
            address: '',
            city: '',
            state: '',
            country: '',
            shop_unique_name: '',
            requestProgress: 'false',
            countryName: true,
            regionName: true,
        }
    },
    mounted: function () {
        this.getUserShop()
    },
    methods: {
        async getUserShop() {
            this.toggleProgress('show')
            let apiResponse = await ApiService.GetUserShop();
            if (apiResponse['status'] == 'success') {
                this.shop = apiResponse['data']

                this.name = this.shop['name']
                this.description = this.shop['description']
                this.image = this.shop['image']
                this.address = this.shop['address']
                this.city = this.shop['city']
                this.state = this.shop['state']
                this.country = this.shop['country']
                this.shop_unique_name = this.shop['shop_unique_name']
                if(this.shop){
                    this.hasShop = 'true'
                }
            }
            this.toggleProgress('hide')
        },
        async updateShop() {
            this.requestProgress = 'true'
            let apiResponse = await ApiService.UpdateShop(this.shop['id'], this.name, this.image, this.description, this.address, this.city, this.state, this.country, this.shop_unique_name);

            this.$store.commit("toast/Notify", {
                type: apiResponse["status"],
                message: apiResponse["message"],
            });
            if (apiResponse["status"] != "success") {
            } else {
                setTimeout(() => {
                    location.href = '/dashboard/products'
                }, 2000);
            }
            this.requestProgress = 'false'
        },
        onImageUploaded(response) {
            // Handle files like:
            // console.log('::::',response)
            if (response['status'] == 'success') {
                this.image = response['data']['file_name']
            }
            this.$store.commit("toast/Notify", {
                type: response["status"],
                message: response["message"],
            });
        },
        handleImageAdded: function (file, Editor, cursorLocation, resetUploader) {
            // An example of using FormData
            // NOTE: Your key could be different such as:
            // formData.append('file', file)

            var formData = new FormData();
            formData.append("file", file);

            axios({
                    url: this.$store.getters['host/apiUrl'].replace('/api/v2', '/files/upload'),
                    method: "POST",
                    data: formData
                })
                .then(result => {
                    // console.log('result', result)
                    const url = result.data.data['file_name']; // Get url from response
                    Editor.insertEmbed(cursorLocation, "image", url);
                    resetUploader();
                })
                .catch(err => {
                    console.log(err);
                });
        }
    },
}
</script>

<style lang="scss" scoped>
.some-modal-content {
    min-width: 400px;
    padding: 25px;

    .buttons button {
        padding: 10px;
        margin: 10px;
    }
}

.postImgheight {
    height: 100px
}

.editor {
    height: 350px;
}
</style>
