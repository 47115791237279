<template>
<div id="wrapper">
    <auth-styles :pageNameMain="pageNameMain" :pageDescriptionMain="pageDescriptionMain" :pageImageMain="pageImageMain"></auth-styles>
    <Header />

    <sidebar></sidebar>

    <!-- Main Contents -->
    <div class="main_content">
        <div class="mcontainer">
            <div class="lg:flex lg:space-x-12">
                <div class="lg:w-3/4">
                    <div class="flex justify-between items-center relative md:mb-4 mb-3">
                        <div class="flex-1">
                            <h2 class="text-2xl font-semibold">Blog Posts with Hash Tag #{{ hash_tag }}</h2>
                        </div>
                    </div>

                    <div class="card divide-y divide-gray-100 px-4">
                        <div class="lg:flex lg:space-x-6 py-5" v-for="topic in topics">
                            <router-link :to="'/dashboard/blog/'+topic.name_key">
                                <div class="lg:w-60 w-full h-40 overflow-hidden rounded-lg relative shadow-sm">
                                    <img v-if="topic.image" :src="topic.image" alt="" class="w-full h-full absolute inset-0 object-cover" />
                                    <img v-else :src="defaultPostUrl()" alt="" class="w-12 h-12 rounded-full" />
                                    <div class="absolute bg-blue-100 font-semibold px-2.5 py-1 rounded-full text-blue-500 text-xs top-2.5 left-2.5">
                                        {{ topic.category }}
                                    </div>
                                </div>
                            </router-link>
                            <div class="flex-1 lg:pt-0 pt-4">
                                <router-link :to="'/dashboard/blog/'+topic.name_key" class="text-xl font-semibold line-clamp-2 mouse-pointer" v-html="topic.title">
                                </router-link>
                                <p class="line-clamp-2 pt-3">
                                    {{ blogSubtitle(topic.content,150) }}
                                </p>

                                <div class="flex items-center pt-3">
                                    <div class="flex items-center">
                                        <i class="fa fa-tags mr-2"></i>
                                        {{ topic.unique_page_views }}
                                    </div>
                                    <div class="flex items-center mx-4">
                                        <i class="fa fa-comment mr-2"></i>
                                        {{ topic.comments.length }}
                                        <div class="-space-x-2 flex items-center ml-1" v-html="lastThreeUserComment(topic.comments)">
                                        </div>
                                    </div>
                                    <!-- <div class="flex items-center">
                                        <ion-icon name="bookmark-outline" class="text-xl mr-2"></ion-icon>
                                        12
                                    </div> -->
                                </div>
                            </div>
                        </div>
                        <div v-if="blogLoading == 'false' && topics.length == 0" class="bg-white text-black p-5">
                            <h4>No Blog Post using this hash tag Yet</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <progress-loader v-if="blogLoading == 'true'"></progress-loader>
    <div id="progressLoader">
        <progress-loader></progress-loader>
    </div>

    <dashboard-widgets page="blogs"></dashboard-widgets>
</div>
</template>

<script>
import { ApiService } from '@/services/api.service'
import AuthStyles from '@/include/Auth/AuthStyles'
import Header from "@/include/Dashboard/Header.vue";
import Sidebar from "@/include/Dashboard/Sidebar.vue";
import DashboardWidgets from '@/components/DashboardWidgets'
import OpenChatBox from "@/include/Modals/OpenChatBox.vue";
import Feeds from "@/include/Dashboard/Feeds.vue";
import CustomIcon from '@/components/CustomIcon'
import ProgressLoader from '@/components/ProgressLoader'

export default {
    name: "BlogsByHashTag",
    components: {
        Header,
        Sidebar,
        DashboardWidgets,
        OpenChatBox,
        Feeds,
        CustomIcon,
        ProgressLoader,
        AuthStyles
    },

    data() {
        return {
            topics: [],
            recentTopics: [],
            page: 0,
            pendingRequest: false,
            recentActive: 'active',
            trendingActive: '',
            oldestActive: '',
            userActive: '',
            postCategories: '',
            section: 'hash_tag',
            hash_tag: '',
            pagination: {
                limit: 20,
                offset: 0,
                total: 60,
            },
            pageNameMain: 'Blogs',
            pageDescriptionMain: '',
            pageImageMain: '',
            blogLoading: 'false',
        };
    },
    mounted: function () {
        let hash_tag = this.$route.params['hash_tag']
        this.hash_tag = hash_tag
        this.getBlogPosts()
        this.getExtraInfo()
    },
    methods: {
        async getExtraInfo(){
            this.blogLoading = 'true'
            this.toggleProgress('show')
            let apiResponse = await ApiService.ExtraInfo();
            // console.log('getExtraInfo',apiResponse)
            if(apiResponse['status'] == 'success'){
                this.postCategories = apiResponse['data']['post_categories']
            }
            this.blogLoading = 'false'
            this.toggleProgress('hide')
        },
        async getBlogPosts() {
            this.blogLoading = 'true'
            if (this.pendingRequest == true) {
                // console.log("Pending Req");
                return;
            }
            this.toggleProgress('show')
            this.pendingRequest = true;
            let apiResponse = await ApiService.GetBlogPosts(this.page, this.section, '', this.hash_tag);
            if (apiResponse["status"] != "success") {} else {
                this.pendingRequest = false;
                this.page++;
                this.topics = apiResponse["data"];
            }
            this.blogLoading = 'false'
            this.toggleProgress('hide')
        },
    },
};
</script>

<style lang="scss" scoped>
.some-modal-content {
    min-width: 400px;
    padding: 25px;

    .buttons button {
        padding: 10px;
        margin: 10px;
    }
}
</style>
