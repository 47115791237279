<template>
<div class="message-bubble">
    <div class="message-bubble-inner">
        <div class="message-avatar mouse-pointer" @click='openLinkInNewTab("/dashboard/timeline/"+otherUserID(chat, currentShop, "user"))'>
            <img :src="otherUserProfilePhoto(chat, currentShop, 'user')" alt="">
            <span v-if="otherUserOnline(chat, currentShop, 'user') == 'true'" :class="'user_status status_online chat_user_'+otherUserID(chat, currentShop, 'user')"></span>
            <span v-else :class="'user_status status_online chat_user_'+otherUserID(chat, currentShop, 'user')" style="display:none"></span>
        </div>
        <div class="message-text mouse-pointer" @click="setMessageToReply(m)" uk-toggle-old="target: #reply-chat-modal-old">
            <chat-message-images v-if="m.images" :images="m.images"></chat-message-images>
            <p v-if="m.message && m.images.length > 0" class="py-1"></p>
            <div v-if="m.original_message_id && m.main_message" class="bg-blue-500 px-3 rounded-md text-white">
                <p>
                    <small>Reply to</small>
                </p>
                <p v-if="m.main_message.message_type == 'text'" v-html="customTextRender(m.main_message.message)"></p>
                <a v-else-if="m.main_message.message_type == 'image'" :href="m.main_message.message" target="_blank" class="">
                    <img :src="m.main_message.message" style="height:50px" class="mr-5" />
                </a>
                <p v-else-if="m.main_message.message_type == 'link'">
                    <a :href="m.main_message.message" target="_blank" class="text-blue text-blue:hover"><i class="fa fa-link"></i> {{ m.main_message.message }}</a>
                </p>
                <hr />
            </div>
            <p v-if="m.message_type == 'text'" v-html="customTextRender(m.message)" class="" style="word-break: break-all;"></p>
        </div>
    </div>
    <div class="clearfix"></div>
    <p class="text-left mt-3">
        <small style="font-size: 10px;">
            {{ formatDateTimeNiceShort(m.created_at) }}
        </small>
    </p>
</div>
</template>

<script>
import ChatMessageImages from '@/components/Chats/ChatMessageImages'
export default {
    props: {
        chat_user_is: String,
        chat: Object,
        m: Object,
    },
    name: 'OtherUserMessage',
    components: {
        ChatMessageImages
    },
    data() {
        return {
            currentUser: '',
            currentShop: '',
        }
    },
    mounted: function () {
        this.currentUser = this.$store.getters['user/currentUser'] || {}
    },
    methods: {
        setMessageToReply(a) {
            this.$emit('setMessageToReply', a)
        },
    },
}
</script>
