<template>
<!-- Craete post modal -->
<div id="edit-feed-post-modal" class="create-post is-story" uk-modal="">
    <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical rounded-lg p-0 lg:w-5/12 relative shadow-2xl uk-animation-slide-bottom-small" style="height: 90vh;overflow-y: scroll;">
        <div class="text-center py-3 border-b">
            <h3 class="text-lg font-semibold">Edit Post</h3>
            <button class="uk-modal-close-default bg-gray-100 rounded-full p-2.5 right-2" type="button" uk-close="" uk-tooltip="title: Close ; pos: bottom ;offset:7"></button>
        </div>
        <div class="flex flex-1 items-start space-x-4 p-3">
            <img v-if="profilePhoto" :src="profilePhoto" class="bg-gray-200 border border-white rounded-full w-11 h-11">
            <img v-else :src="defaultImageUrl()" class="bg-gray-200 border border-white rounded-full w-11 h-11">
            <div class="flex-1 pt-2">
                <textarea v-model="content" autofocus @keyup="manageTagging('.edit_post_input')" class="edit_post_input uk-textare text-black shadow-none focus:shadow-none text-xl font-medium resize-none" rows="5" :placeholder="'What\'s up? ' + nameOfPoster + '!'"></textarea>
            </div>
        </div>
        <div :class="'edit_post_input_tags'"></div>
        <div class="mx-3">
            <div class="row m-0" v-if="images_raw.length > 0 || old_images.length > 0">
                <h5 class="col-sm-12 p-0 mt-1 pt-2 mb-3 pb-3">Selected Images</h5>
                <div v-for="(image, index) in old_images" :class="'p-0 text-center col-sm-12 col-xs-12'">
                    <img :src="image['url']" style="padding: 10px; max-height: 200px" class="m-auto" />
                    <a href="javascript:;" v-on:click="removeImage(index,'main')">remove image</a>
                </div>
                <div v-for="(image, index) in images_raw" :class="'p-0 text-center col-sm-12 col-xs-12'">
                    <img :src="image" style="padding: 10px; max-height: 200px" class="m-auto" />
                    <a href="javascript:;" v-on:click="removeImage(index)">remove image</a>
                </div>
            </div>
            <div class="row m-0" v-if="audios_raw.length > 0 || old_audios.length > 0">
                <h5 class="col-sm-12 p-0 mt-1 pt-2 mb-3 pb-3">Selected Audios</h5>
                <div v-for="(audio, index) in old_audios" class="p-0 text-center col-sm-12 mb-3 pb-3">
                    <audio-player-component :src="audio['url']"></audio-player-component>
                    <a href="javascript:;" v-on:click="removeAudio(index, 'main')">remove audio</a>
                </div>
                <div v-for="(audio, index) in audios_raw" class="p-0 text-center col-sm-12 mb-3 pb-3">
                    <audio-player-component :src="audio"></audio-player-component>
                    <a href="javascript:;" v-on:click="removeAudio(index)">remove audio</a>
                </div>
            </div>
            <div class="row m-0" v-if="videos_raw.length > 0 || old_videos.length > 0">
                <h5 class="col-sm-12 p-0 mt-1 pt-2 mb-3 pb-3">Selected Videos</h5>
                <div v-for="(video, index) in old_videos" class="p-0 text-center videoPreview col-sm-12 mb-3 pb-3">
                    <video-player-component :src="video['url']"></video-player-component>
                    <a href="javascript:;" v-on:click="removeVideo(index, 'main')">remove video</a>
                </div>
                <div v-for="(video, index) in videos_raw" class="p-0 text-center videoPreview col-sm-12 mb-3 pb-3">
                    <video-player-component :type="videos_type[index]" :src="video"></video-player-component>
                    <a href="javascript:;" v-on:click="removeVideo(index)">remove video</a>
                </div>
            </div>
        </div>
        <div class="bsolute bottom-0 p-4 space-x-4 w-full">
            <div class="flex bg-gray-50 border border-purple-100 rounded-2xl p-2 shadow-sm items-center">
                <div class="lg:block hidden ml-1">Add to your post</div>
                <div class="flex flex-1 items-center lg:justify-end justify-center space-x-2">
                    <single-file-preload type="image" @uploadSuccess="onImageUploaded">
                        <svg class="bg-blue-100 h-9 p-1.5 rounded-full text-blue-600 w-9 cursor-pointer" fill="none" stroke="currentColor" viewbox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"></path>
                        </svg>
                    </single-file-preload>
                    <single-file-preload type="audio" @uploadSuccess="onAudioUploaded">
                        <svg class="text-purple-600 h-9 p-1.5 rounded-full bg-purple-100 w-9 cursor-pointer" old-id="veiw-more" fill="none" stroke="currentColor" viewbox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 19V6l12-3v13M9 19c0 1.105-1.343 2-3 2s-3-.895-3-2 1.343-2 3-2 3 .895 3 2zm12-3c0 1.105-1.343 2-3 2s-3-.895-3-2 1.343-2 3-2 3 .895 3 2zM9 10l12-3"></path>
                        </svg>
                    </single-file-preload>
                    <single-file-preload type="video" @uploadSuccess="onVideoUploaded">
                        <svg class="text-pink-600 h-9 p-1.5 rounded-full bg-pink-100 w-9 cursor-pointer" old-id="veiw-more" fill="none" stroke="currentColor" viewbox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z"></path>
                        </svg>
                    </single-file-preload>
                </div>
            </div>
        </div>
        <div class="flex items-center w-full justify-between border-t px-3 py-2 p-sm-3 pb-3">
            <div class="mt-2old mr-2">
                <select class="story" v-model="reach">
                    <option value="only-you">Only me</option>
                    <option value="every-one">Every one</option>
                    <option value="people-i-follow">People I Follow</option>
                    <option value="people-you-follow">People Follow Me</option>
                </select>
            </div>

            <div class="flex space-x-2 mb-5old">
                <button @click="editFeedFlow()" class="bg-blue-600 flex h-9 items-center justify-center rounded-md text-white px-5 font-medium">
                    Update
                </button>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    ApiService
} from "@/services/api.service";
import {
    FileUploadService
} from 'v-file-upload'
import SingleFilePreload from "../../components/SingleFilePreload"
import AudioPlayerComponent from '@/components/AudioPlayerComponent'
import VideoPlayerComponent from '@/components/VideoPlayerComponent'
export default {
    name: "EditFeedPost",
    props: {
        name: String
    },
    data() {
        return {
            currentYear: new Date().getFullYear(),
            reach: "every-one",
            appName: "",
            content: "",
            profilePhoto: "",
            userFullName: "",
            group_id: "",
            page_id: "",
            nameOfPoster: '',
            headers: {},
            images_raw: [],
            videos_raw: [],
            videos_type: [],
            audios_raw: [],
            images_raw_data: [],
            videos_raw_data: [],
            audios_raw_data: [],
            images: [],
            videos: [],
            audios: [],
            old_images: [],
            old_videos: [],
            old_audios: [],
            loading: false,
            url: '',
            main_feed: '',
        };
    },
    components: {
        SingleFilePreload,
        AudioPlayerComponent,
        VideoPlayerComponent,
    },
    mounted: function () {
        this.appName = this.$APP_NAME;
        this.uploadUrl = this.$store.getters["host/apiUrl"] + "/upload_file";
        this.url = this.$store.getters['host/apiUrl'].replace('/api/v2', '/files/upload')
        this.currentUser = this.$store.getters["user/currentUser"];
        if (this.currentUser) {
            this.profilePhoto = this.currentUser['profile_photo']
            this.userFullName = this.currentUser['name']
        }
        let id = this.$route.params['id']
        if (location.href.search("/page/") > -1) {
            this.page_id = id
        }
        if (location.href.search("/group/") > -1) {
            this.group_id = id
        }

        if(this.name){
            this.nameOfPoster = this.name
        }else{
            this.nameOfPoster = this.userFullName
        }
        let vueThis = this
        this.$root.$on("open-edit-feed-post-modal",(main_feed) => {
            // console.log('main_feed', main_feed)
            vueThis.main_feed = main_feed

            vueThis.old_images = []
            vueThis.old_audios = []
            vueThis.old_videos = []

            vueThis.content = main_feed['content']
            vueThis.reach = main_feed['reach']
            if(main_feed['images'] && main_feed['images'].length > 0){
                for (let i = 0; i < main_feed['images'].length; i++) {
                    const thisImage = main_feed['images'][i];
                    vueThis.old_images.push(thisImage)
                }
            }
            if(main_feed['audios'] && main_feed['audios'].length > 0){
                for (let i = 0; i < main_feed['audios'].length; i++) {
                    const thisAudio = main_feed['audios'][i];
                    vueThis.old_audios.push(thisAudio)
                }
            }
            if(main_feed['videos'] && main_feed['videos'].length > 0){
                for (let i = 0; i < main_feed['videos'].length; i++) {
                    const thisVideo = main_feed['videos'][i];
                    vueThis.old_videos.push(thisVideo)
                }
            }
        })
    },
    methods: {
        async editFeed() {
            if (!this.content) {
                return this.$store.commit("toast/Notify", {
                    type: "warning",
                    message: "content is required",
                });
            }
            if(this.old_images && this.old_images.length > 0){
                for (let i = 0; i < this.old_images.length; i++) {
                    const thisImage = this.old_images[i]['url'];
                    this.images.push(thisImage)
                }
            }
            if(this.old_audios && this.old_audios.length > 0){
                for (let i = 0; i < this.old_audios.length; i++) {
                    const thisAudio = this.old_audios[i]['url'];
                    this.audios.push(thisAudio)
                }
            }
            if(this.old_videos && this.old_videos.length > 0){
                for (let i = 0; i < this.old_videos.length; i++) {
                    const thisVideo = this.old_videos[i]['url'];
                    this.videos.push(thisVideo)
                }
            }
            let apiResponse = await ApiService.UpdateFeed(
                this.main_feed['id'],
                this.content,
                this.images,
                this.audios,
                this.videos,
                this.reach
            );
            this.$store.commit("toast/Notify", {
                type: apiResponse["status"],
                message: apiResponse["message"],
            });
            if (apiResponse["status"] != "success") {} else {
                this.$root.$emit("refresh-feeds")
                this.content = ''
                this.images = []
                this.audios = []
                this.videos = []
                this.closeModal()
            }
        },
        async editFeedFlow(){
            this.noty('success','Processing')
            this.content = $(".edit_post_input").val()
            if(this.images_raw_data.length > 0){
                this.noty('success','Saving photos')
                let images = this.images_raw_data
                for (let im = 0; im < images.length; im++) {
                    const element = images[im];
                    
                    if(element){
                        let apiResponse = await this.saveFile(element)
                        // console.log('apiResponse', apiResponse)
                        if(apiResponse && apiResponse['status'] == 'success'){
                            this.images.push(apiResponse['data']['file_name'])
                        }

                    }
                }
                this.images_raw = []
            }
            if(this.audios_raw_data.length > 0){
                this.noty('success','Saving photos')
                let audios = this.audios_raw_data
                for (let im = 0; im < audios.length; im++) {
                    const element = audios[im];
                    
                    if(element){
                        let apiResponse = await this.saveFile(element)
                        // console.log('apiResponse', apiResponse)
                        if(apiResponse && apiResponse['status'] == 'success'){
                            this.audios.push(apiResponse['data']['file_name'])
                        }
                    }
                }
                this.audios_raw = []
            }
            if(this.videos_raw_data.length > 0){
                this.noty('success','Saving videos')
                let videos = this.videos_raw_data
                for (let im = 0; im < videos.length; im++) {
                    const element = videos[im];
                    
                    if(element){
                        let apiResponse = await this.saveFile(element)
                        // console.log('apiResponse', apiResponse)
                        if(apiResponse && apiResponse['status'] == 'success'){
                            this.videos.push(apiResponse['data']['file_name'])
                        }
                    }
                }
                this.videos_raw = []
            }
            this.editFeed()
        },
        onImageUploaded(response) {
            this.images_raw_data.push(response)
            this.images_raw.push(URL.createObjectURL(response))
        },
        onAudioUploaded(response) {
            this.audios_raw_data.push(response)
            this.audios_raw.push(URL.createObjectURL(response))
        },
        onVideoUploaded(response) {
            this.videos_type.push(response['type'])
            this.videos_raw_data.push(response)
            this.videos_raw.push(URL.createObjectURL(response))
        },
        closeModal() {
            $('#create-post-modal').removeClass('uk-open').hide();
        },
        removeImage(i, a = 'raw'){
            if(a == 'raw'){
                this.images_raw_data.splice(i,1)
                this.images_raw.splice(i,1)
            }else{
                this.old_images.splice(i,1)
            }
        },
        removeAudio(i, a = 'raw'){
            if(a == 'raw'){
                this.audios_raw_data.splice(i,1)
                this.audios_raw.splice(i,1)
            }else{
                this.old_audios.splice(i,1)
            }
        },
        removeVideo(i, a = 'raw'){
            if(a == 'raw'){
                this.videos_type.splice(i,1)
                this.videos_raw_data.splice(i,1)
                this.videos_raw.splice(i,1)
            }else{
                this.old_videos.splice(i,1)
            }
        },
        async saveFile(file) {
            // this.noty('success','uploading '+file['name'])
            this.loading = true
            let fileUpload = new FileUploadService(this.url, this.headers, this.onProgress)
            let e = await fileUpload.upload(file, {})
            e = e.target.response
            this.loading = false
            // this.noty('success','uploaded '+file['name'])
            // console.log('EEE', e)
            return e
        },
        onProgress(event) {
            // Handdle the progress
            console.log(event)
        },
    },
};
</script>

<style >
    .videoPreview>div>div {
        width: 100% !important;
    }
    .videoPreview .vjs-big-play-button {
        display: none !important;
    }
    #create-post-modal > div {
        max-height: 90vh;
        overflow-x: scroll;
    }
</style>
