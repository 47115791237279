<template>
<div id="wrapper">
    <auth-styles :pageNameMain="pageNameMain" :pageDescriptionMain="pageDescriptionMain" :pageImageMain="pageImageMain"></auth-styles>
    <Header />

    <sidebar></sidebar>

    <!-- Main Contents -->
    <div class="main_content">
        <div class="mcontainer">

            <div class="flex justify-between items-center relative md:mb-4 mb-3">
                <div class="flex-1">
                    <h2 class="text-2xl font-semibold"> Customer Orders </h2>
                </div>

            </div>

            <div class="relative -mt-3 uk-slider-old" uk-slider-old="finite: true">

                <div class="uk-slider-container-old px-1 py-3">
                    <ul class="uk-slider-items-old uk-child-width-1-3@m uk-child-width-1-2@s uk-grid-small uk-grid" style="transform: translate3d(0px, 0px, 0px);">

                        <li v-for="order in orders" tabindex="-1" class="uk-active mouse-pointer mb-5" uk-toggle="target: #customer-order-product-modal" >
                            <div @click="setOrderProduct(order)" class="card p-2 flex space-x-4 border border-gray-100">
                                <div class="w-20 h-24 overflow-hidden rounded-lg">
                                    <div class="card-media h-24">
                                        <img v-if="order.image" :src="order.image" alt="">
                                        <img v-else :src="defaultPostUrl()" alt="">
                                    </div>
                                </div>
                                <div class="flex-1 pt-2.5 relative">

                                    <div class="text-xs font-semibold uppercase text-yellow-500">{{ order.category }} </div>
                                    <div class="text-lg mt-3 2.5 text-gray-700">{{ order.name }}</div>
                                    <div class="flex items-center space-x-2 text-sm text-gray-400 capitalize">
                                        <div> Paid: {{ unixToUtc(order.date_paid) }}</div>
                                        <div>·</div>
                                        <div> 
                                            <span v-if="order.date_paid">paid</span>
                                            <span v-else-if="order.date_accepted">vendor accepted</span>
                                            <span v-else-if="order.date_rejected">vendor rejected</span>
                                            <span v-else-if="order.date_refunded">vendor refunded</span>
                                            <span v-else-if="order.date_shipped">vendor shipped</span>
                                            <span v-else-if="order.date_delivered">vendor delivered</span>
                                            <span v-else-if="order.date_confirmed">customer confirmed</span>
                                        </div>
                                    </div>
                                    <div class="top-1.5 absolute bg-gray-100 font-semibold px-2.5 py-1 right-1 rounded-full text text-blude-500 text-sm">
                                        {{ currencySymbol(order.currency) }}{{ formatAmount(order.total_amount) }}
                                    </div>

                                </div>
                            </div>
                        </li>

                    </ul>
                </div>

            </div>

            <br>

        </div>
    </div>
    <div id="progressLoader">
        <progress-loader></progress-loader>
    </div>

    <dashboard-widgets page="orders"></dashboard-widgets>
    <customer-order-product-modal v-if="orderProduct" :order="orderProduct"></customer-order-product-modal>
</div>
</template>

<script>
import {
    ApiService
} from '@/services/api.service'
import AuthStyles from '@/include/Auth/AuthStyles'
import Header from "@/include/Dashboard/Header.vue";
import Sidebar from "@/include/Dashboard/Sidebar.vue";
import DashboardWidgets from '@/components/DashboardWidgets'
import OpenChatBox from "@/include/Modals/OpenChatBox.vue";
import Feeds from "@/include/Dashboard/Feeds.vue";
import CustomIcon from '@/components/CustomIcon'
import ProgressLoader from '@/components/ProgressLoader'
import CustomerOrderProductModal from '@/include/Modals/CustomerOrderProductModal'

export default {
    name: "ProductOrders",
    components: {
        Header,
        Sidebar,
        DashboardWidgets,
        OpenChatBox,
        Feeds,
        CustomIcon,
        ProgressLoader,
        AuthStyles,
        CustomerOrderProductModal
    },

    data() {
        return {
            orders: [],
            page: 0,
            pendingRequest: false,
            recentActive: 'active',
            trendingActive: '',
            randomActive: '',
            userActive: '',
            brands: '',
            categories: '',
            section: '',
            category: '',
            newProducts: '',
            pagination: {
                limit: 20,
                offset: 0,
                total: 60,
            },
            pageNameMain: 'Blogs',
            pageDescriptionMain: '',
            pageImageMain: '',
            orderProduct: '',
        };
    },
    mounted: function () {
        this.getProductOrders()
    },
    methods: {
        async getProductOrders() {
            let apiResponse = await ApiService.GetProductOrders('customer', 0, 5);
            if (apiResponse["status"] != "success") {} else {
                this.orders = apiResponse["data"];
            }
        },
        async setOrderProduct(p) {
            this.orderProduct = p
        },
    },
};
</script>

<style lang="scss" scoped>
.some-modal-content {
    min-width: 400px;
    padding: 25px;

    .buttons button {
        padding: 10px;
        margin: 10px;
    }
}
</style>
