<template>

    <!-- Create Company modal -->
    <div id="update-user-data" class="create-post is-story" uk-modal="">
        <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical rounded-lg p-0 lg:w-5/12 relative shadow-2xl uk-animation-slide-bottom-small">
            <div class="text-center py-3 border-b">
                <h3 class="text-lg font-semibold">Update User Profile</h3>
                <button class="uk-modal-close-default bg-gray-100 rounded-full p-2.5 right-2" type="button" uk-close="" uk-tooltip="title: Close ; pos: bottom ;offset:7"></button>
            </div>
            <div class="items-start space-x-4 p-5">
                <div class="line">
                    <label>Email: </label>
                    <input readonly :value="currentUser['email']" disabled />
                </div>
                <div v-if="dob" class="line flex-xs" style="margin-left:0 !important; margin-top: 30px;margin-bottom: 30px">
                    <label>Date of Birth</label>
                    <dob-selector-component v-if="!dob_day || !dob_month || !dob_year" @setDate="setDob"></dob-selector-component>
                    <dob-selector-component v-else :set_day.sync="dob_day" :set_month.sync="dob_month" :set_year.sync="dob_year" @setDate="setDob"></dob-selector-component>
                </div>
                <div class="ml-0 mt-3">
                    <label class="mb-0"> Phone: </label>
                    <input
                    v-model="phone"
                    required
                    type="phone"
                    placeholder="+543 5445 0543"
                    class="bg-gray-100 h-12 mt-2 px-3 rounded-md w-full"
                    />
                </div>
                <div class="old-grid lg:grid-cols-2 old-gap-3 ml-0 mt-3">
                    <div class="mb-3">
                        <label class="mb-0"> Gender </label>
                        <select required class="mt-2" v-model="gender">
                            <option value="">Select gender</option>
                            <option>Male</option>
                            <option>Female</option>
                        </select>
                    </div>
                </div>
                <div class="grid lg:grid-cols-2 gap-3 ml-0 mt-3">
                    <div>
                    <label class="mb-0"> Country </label>
                    <country-select
                        :countryName="countryName"
                        v-model="country"
                        :country="country"
                        topCountryOLD="Nigeria"
                    />
                    </div>
                    <div>
                    <label class="mb-0"> States: </label>
                    <region-select
                        :countryName="countryName"
                        :regionName="regionName"
                        v-model="state"
                        :country="country"
                        :region="state"
                    />
                    </div>
                </div>
                <div class="ml-0 mt-3">
                    <label>Bio</label>
                    <textarea v-model="new_bio" class="bg-gray-100 mt-2 p-2"></textarea>
                </div>
            </div>
            <div class="flex-d items-center w-full justify-between border-t p-3">

                <div class="text-center mt-3">
                    <button @click="saveUserData()" class="bg-blue-600 flex h-9 items-center justify-center rounded-md text-white sm:px-5 px-3 font-medium m-auto">
                        Update User Profile
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    ApiService
} from "@/services/api.service";
import DobSelectorComponent from '@/components/DobSelectorComponent'
export default {
    name: "UpdateUserData",
    props: {
    },
    components: {
        DobSelectorComponent,
    },
    data() {
        return {
            user: '',
            currentUser: {},
            thisUser: {},
            id: '',
            dob: '',
            new_dob: '',
            new_bio: '',
            user_profile_url: '',
            user_reg_url: '',
            dob_day: '',
            dob_month: '',
            dob_year: '',
            country: '',
            state: '',
            city: '',
            gender: '',
            phone: '',
            countryName: true,
            regionName: true,
        };
    },
    mounted () {
        this.thisUser = this.$store.getters["user/currentUser"];
        this.currentUser = this.currentUser
        this.id = this.thisUser['id']
        this.getUserInfo()
    },
    methods: {
        async getUserInfo(){
            this.toggleProgress('show')
            let apiResponse = await ApiService.GetMe();
            // console.log('GetMe',apiResponse)
            if(apiResponse['status'] == 'success'){
                this.currentUser = apiResponse['data']['user']
                this.followings = apiResponse['data']['followings_count'].toString()
                this.followers = apiResponse['data']['followers_count'].toString()
                this.follow = apiResponse['data']['follow']

                // console.log(this.currentUser,'current user')
                if(this.currentUser){
                    this.new_bio = this.currentUser['bio']
                    this.phone = this.currentUser['phone']
                    this.country = this.currentUser['country']
                    this.city = this.currentUser['city']
                    this.state = this.currentUser['state']
                    this.gender = this.currentUser['gender']
                    if(this.currentUser['dob']){
                        let {day, month, year} = this.pullDobData(this.currentUser['dob'])
                        this.dob_day = day
                        this.dob_month = month
                        this.dob_year = year

                        this.setDob(this.dob_year+'-'+this.dob_month+'-'+this.dob_day)
                        this.dob = 'true'
                    }else{
                        this.dob = 'false'
                    }
                }
            }
            this.toggleProgress('hide')
        },
        async saveUserData(){
            this.toggleProgress('show')
            let apiResponse = await ApiService.SaveUserData(this.new_dob, this.new_bio, this.country, this.state, this.city, this.phone, this.gender);
            // console.log('saveUserData',apiResponse)
            this.$store.commit("toast/Notify", {
                type: apiResponse["status"],
                message: apiResponse["message"],
            });
            if(apiResponse['status'] == 'success'){
                location.href = ''
            }
            this.toggleProgress('hide')
        },
        async setDob(a){
            // console.log(":::::::::",a)
            this.new_dob = a
        },
    }
};
</script>

<style lang="scss" scoped>
.story-ind-custom {
    padding: 2px;
    height: 2px;
    width: 100%;
    background: #ddd;
    border-radius: 5px;
    margin-bottom: 10px;
}

.story-ind-custom>div {
    background: #ddd;
}

.text-middle {
    height: 400px;
    background: #eee;
    vertical-align: middle;
    display: flex;
    align-items: center;
}

.text-middle>div {
    width: 100%;
}

.story-slider-image {
    height: 400px;
    max-width: 100%;
}
@media (max-width: 520px) {
    #update-user-data > div {
        overflow: scroll !important;
    }
}
</style>
