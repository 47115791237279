<template>
<div id="wrapper">
    <!-- Header -->
    <home-header></home-header>
    <auth-styles pageNameMain="Frequently Asked Questions"></auth-styles>

    <div class="home-content">
        <faq-section></faq-section>
    </div>

    <auth-scripts></auth-scripts>

</div>
</template>

<script>
import AuthStyles from '@/include/Auth/AuthStyles'
import AuthScripts from '@/include/Auth/AuthScripts'
import FaqSection from '@/include/Home/FaqSection'
import HomeHeader from '@/include/Home/HomeHeader'
export default {
    name: 'WelcomePage',
    components: {
        AuthStyles,
        AuthScripts,
        FaqSection,
        HomeHeader,
    },
    data() {
        return {
            currentYear: new Date().getFullYear(),
            faqData: [{
                'question': 'How do I register either as an advertiser or a promoter?',
                'answer': ""
            }]
        }
    },

    methods: {}
}
</script>

<style>
</style>
<style lang="scss" scoped>
</style>
