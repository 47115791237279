<template>
<div id="wrapper">
    <auth-styles v-if="pageNameMain" :pageNameMain="pageNameMain" :pageDescriptionMain="pageDescriptionMain" :pageImageMain="pageImageMain"></auth-styles>

    <Header />

    <sidebar></sidebar>

    <!-- Main Contents -->
    <div class="main_content">
        <div class="mcontainer" v-if="product">

            <div class="lg:flex lg:space-x-10">
                <div class="lg:w-3/4 lg:px-20 space-y-7">

                    <div class="card lg:mx-0 uk-animation-slide-bottom-small">

                        <!-- post header-->
                        <div class="flex justify-between items-center lg:p-4 p-2.5">
                            <div class="flex flex-1 items-center space-x-4">
                                <a v-if="!product.user || !product.user.profile_photo" target="_blank" :href="defaultImageUrl()">
                                    <img :src="defaultImageUrl()" class="bg-gray-200 border border-white rounded-full w-10 h-10">
                                </a>
                                <a v-else target="_blank" :href="product.user.profile_photo">
                                    <img :src="product.user.profile_photo" class="bg-gray-200 border border-white rounded-full w-10 h-10">
                                </a>
                                <div class="flex-1 font-semibold capitalize">
                                    <a target="_blank" v-if="product.user" :href="'/dashboard/timeline/'+product.user.id" class="text-black"> 
                                        {{ product.user.name }} 
                                        <verified-badge v-if="product.user.verified == 'true'" size="small"></verified-badge>
                                    </a>
                                    <div class="text-gray-700 flex items-center space-x-2"> 
                                        {{ feedTimeManager(product.created_at) }}
                                    </div>
                                </div>
                            </div>
                            <div>
                                <a href="#" aria-expanded="false"> <i class="icon-feather-more-horizontal text-2xl hover:bg-gray-200 rounded-full p-2 transition -mr-1 dark:hover:bg-gray-700"></i> </a>
                                <div class="bg-white w-56 shadow-md mx-auto p-2 mt-12 rounded-md text-gray-500 hidden text-base border border-gray-100 dark:bg-gray-900 dark:text-gray-100 dark:border-gray-700 uk-drop" uk-drop="mode: click;pos: bottom-right;animation: uk-animation-slide-bottom-small">

                                    <ul class="space-y-1">
                                        <li>
                                            <a uk-toggle="target: #share-post-modal" @click="setCurrentLink(product.id, product.name)" href="javascript:;" class="mouse-pointer flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800">
                                                <i class="uil-share-alt mr-1"></i> Share
                                            </a>
                                        </li>
                                        <li>
                                            <a v-if="!product.user_favourite" @click="confirmFavouriteProduct()" href="javascript:;" class="flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800">
                                                <i class="uil-favorite mr-1"></i> Add favorites
                                            </a>
                                            <a v-else @click="confirmUnfavouriteProduct()" href="javascript:;" class="flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800">
                                                <i class="uil-favorite mr-1"></i> Remove favorites
                                            </a>
                                        </li>
                                        <li>
                                            <hr class="-mx-2 my-2 dark:border-gray-800">
                                        </li>
                                        <template v-if="currentUser.id == product.user_id">
                                            <li v-if="product.status == 'pending'">
                                                <a :href="'/dashboard/edit-product/'+product.id" class="flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800">
                                                    <i class="uil-edit-alt mr-1"></i> Edit Post
                                                </a>
                                            </li>
                                            <li class="d-none">
                                                <a href="#" class="flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800">
                                                    <i class="uil-comment-slash mr-1"></i> Disable comments
                                                </a>
                                            </li>
                                            <li>
                                                <a @click="confirmDelete()" class="mouse-pointer flex items-center px-3 py-2 text-red-500 hover:bg-red-100 hover:text-red-500 rounded-md dark:hover:bg-red-600">
                                                    <i class="uil-trash-alt mr-1"></i> Delete
                                                </a>
                                            </li>
                                        </template>
                                    </ul>

                                </div>
                            </div>
                        </div>

                        <div uk-lightbox="">
                            <a v-if="product.image" :href="product.image">
                                <img :src="product.image" alt="" class="max-h-96 w-full object-cover">
                            </a>
                            <a v-else :href="defaultPostUrl()">
                                <img :src="defaultPostUrl()" alt="" class="max-h-96 w-full object-cover">
                            </a>
                        </div>

                        <div class="p-4 space-y-3 relative">
                            <div class="text-2xl font-semibold pt-2"> {{ product.name }}</div>
                            <p> {{ product.description }}</p>

                            <div class="top-3 absolute bg-gray-100 font-semibold px-3 py-1 right-3 rounded-full text text-sm">
                                {{ currencySymbol(product.currency) }}{{ formatAmount(product.amount) }}
                            </div>

                            <div class="flex space-x-3 items-center text-sm md:pt-3">
                                <div> <span v-if="product.category">{{ product.category.name }}</span>: <span v-if="product.sub_category">{{ product.sub_category.name }}</span></div>
                                <div class="md:block hidden">·</div>
                                <div class="font-semibold text-yellow-500" v-if="product.in_stock > 0"> Instock</div>
                                <div class="flex"> Brand: <span v-if="product.brand" class="font-semibold text-yellow-500 mr-2">{{ product.brand.name }} </span> </div>
                            </div>

                            <hr>

                            <template v-if="product.colors && product.colors.length > 0">
                                <div class="flex space-x-3 items-center text-sm md:pt-3">
                                    <label for="" class="mb-2"> Available Colors </label>
                                    <div>
                                        <label v-for="s in product.colors" class="mr-3">
                                            <input type="radio" v-model="color" :value="s['name']" class="custom-checkbox"/>
                                            {{ s['name'] }}
                                        </label>
                                    </div>
                                </div>

                                <hr>
                            </template>

                            <template v-if="product.sizes && product.sizes.length > 0">
                                <div class="flex space-x-3 items-center text-sm md:pt-3">
                                    <label for="" class="mb-2"> Available Sizes </label>
                                    <div>
                                        <label v-for="s in product.sizes" class="mr-3">
                                            <input type="radio" v-model="size" :value="s['name']" class="custom-checkbox"/>
                                            {{ s['name'] }}
                                        </label>
                                    </div>
                                </div>

                                <hr>
                            </template>

                            <div class="grid md:grid-cols-3 gap-4 mb-5">

                                <a v-if="product.shop" :href="'/dashboard/chats?type=shop&id='+product.shop.id+'&product_link='+thisPage" class="bg-yellow-200 flex flex-1 font-semibold h-10 items-center justify-center px-2 rounded-md">
                                    Contact seller
                                </a>

                                <a v-if="product.shop" :href="'/dashboard/shop/'+product.shop.shop_unique_name || product.shop.id" class="bg-gray-200 flex flex-1 font-semibold h-10 items-center justify-center px-4 rounded-md">
                                    View shop
                                </a>
                                <a v-if="product.shop" @click="addItemToCart(product.id)" href="javascript:;" class="mouse-pointer bg-blue-600 flex flex-1 font-semibold h-10 items-center justify-center px-4 rounded-md text-white">
                                    Add to Cart
                                </a>

                            </div>

                            <comments-manager v-if="feed && feed.comments" :feed="feed" preview="false"></comments-manager>

                        </div>

                    </div>

                </div>
                <birthday-contacts-card-component></birthday-contacts-card-component>
            </div>

        </div>
        <div id="progressLoader">
            <progress-loader></progress-loader>
        </div>
    </div>

    <share-post-modal v-if="shareLink" :link="shareLink" :description="shareDescription"></share-post-modal>
    <dashboard-widgets page="product"></dashboard-widgets>

</div>
</template>

<script>
import {
    ApiService
} from '@/services/api.service'
import Header from '@/include/Dashboard/Header.vue'
import Sidebar from '@/include/Dashboard/Sidebar.vue'
import DashboardWidgets from '@/components/DashboardWidgets'
import OpenChatBox from '@/include/Modals/OpenChatBox.vue'
import Feeds from '@/include/Dashboard/Feeds.vue'
import ProfileIcon from '@/include/Dashboard/Feed/ProfileIcon'
import ProgressLoader from '@/components/ProgressLoader'
import AuthStyles from '@/include/Auth/AuthStyles'
import ConfirmModal from '@/components/ConfirmModal'
import ShareIcons from '@/components/ShareIcons'
import SharePostModal from '@/include/Modals/SharePostModal'
import MessengerComponent from '@/components/MessengerComponent'
import BirthdayContactsCardComponent from '@/components/BirthdayContactsCardComponent'
import VerifiedBadge from '@/components/VerifiedBadge'
import CommentsManager from '@/components/Feed/CommentsManager'

export default {
    name: 'Product',
    components: {
        Header,
        Sidebar,
        DashboardWidgets,
        OpenChatBox,
        Feeds,
        ProgressLoader,
        AuthStyles,
        ConfirmModal,
        ShareIcons,
        SharePostModal,
        ProfileIcon,
        MessengerComponent,
        BirthdayContactsCardComponent,
        VerifiedBadge,
        CommentsManager,
    },

    data() {
        return {
            product: {},
            feed: {},
            comment: '',
            reply: '',
            replyReply: '',
            pagination: {
                limit: 20,
                offset: 0,
                total: 60
            },
            pageNameMain: '',
            pageDescriptionMain: '',
            pageImageMain: '',
            currentUser: {},
            thisPage: '',
            unviewedComments: '',
            preview: 'true',
            shareLink: '',
            shareLink: '',
            comment: '',
            color: '',
            size: '',
            followers: [],
            followings: [],
            noOfPreviewComments: 2,
        }
    },
    mounted: function () {
        this.currentUser = this.$store.getters['user/currentUser'] || {}
        let id = this.$route.params['id']
        this.getProduct(id)
        this.thisPage = location.href
        // this.commentChecker()
        // this.getFollowers()
    },
    methods: {
        async getFollowers() {
            this.toggleProgress('show')
            let apiResponse = await ApiService.GetFollowers();
            if (apiResponse['status'] == 'success') {
                this.followers = apiResponse['data']['followers']
                this.followings = apiResponse['data']['followings']
            }else{
                this.$store.commit("toast/Notify", {
                    type: apiResponse['status'],
                    message: apiResponse['message'],
                });
            }
            this.toggleProgress('hide')
        },
        async addComment(id){
            let apiResponse = await ApiService.AddComment(id,this.comment)
            if(apiResponse['status'] != 'success'){
            }else{
                this.comment = ''
                this.feed = apiResponse['data']
                this.commentChecker()
            }
            this.$store.commit("toast/Notify", {
                type: apiResponse["status"],
                message: apiResponse["message"],
            });
        },
        async likeFeed(){
            let apiResponse = await ApiService.LikeFeed(this.feed.id)
            if(apiResponse['status'] != 'success'){
            }else{
                this.feed = apiResponse['data']
                this.commentChecker()
            }
            this.$store.commit("toast/Notify", {
                type: apiResponse["status"],
                message: apiResponse["message"],
            });
        },
        async unlikeFeed(){
            let apiResponse = await ApiService.UnlikeFeed(this.feed.id)
            if(apiResponse['status'] != 'success'){
            }else{
                this.feed = apiResponse['data']
                this.commentChecker()
            }
            this.$store.commit("toast/Notify", {
                type: apiResponse["status"],
                message: apiResponse["message"],
            });
        },
        async likeComment(comment_id){
            let apiResponse = await ApiService.LikeComment(this.feed.id, comment_id)
            if(apiResponse['status'] != 'success'){
            }else{
                this.feed = apiResponse['data']
                this.commentChecker()
            }
            this.$store.commit("toast/Notify", {
                type: apiResponse["status"],
                message: apiResponse["message"],
            });
        },
        async unlikeComment(comment_id){
            let apiResponse = await ApiService.UnlikeComment(this.feed.id, comment_id)
            if(apiResponse['status'] != 'success'){
            }else{
                this.feed = apiResponse['data']
                this.commentChecker()
            }
            this.$store.commit("toast/Notify", {
                type: apiResponse["status"],
                message: apiResponse["message"],
            });
        },
        async shareAction(id){
            let apiResponse = await ApiService.ShareFeed(this.feed.id)
            if(apiResponse['status'] != 'success'){
            }else{
            }
            this.$store.commit("toast/Notify", {
                type: apiResponse["status"],
                message: apiResponse["message"],
            });
        },
        async replyComment(comment_id){
            let apiResponse = await ApiService.ReplyComment(this.feed.id, comment_id, this.reply)
            if(apiResponse['status'] != 'success'){
            }else{
                this.reply = ''
                this.feed = apiResponse['data']
                this.commentChecker()
            }
            this.$store.commit("toast/Notify", {
                type: apiResponse["status"],
                message: apiResponse["message"],
            });
        },
        async replyCommentReply(feed_id, comment_id, reply_id){
            let apiResponse = await ApiService.ReplyCommentReply(feed_id, comment_id, reply_id, this.replyReply)
            if(apiResponse['status'] != 'success'){
            }else{
                this.replyReply = ''
                this.feed = apiResponse['data']
                this.commentChecker()
            }
            this.$store.commit("toast/Notify", {
                type: apiResponse["status"],
                message: apiResponse["message"],
            });
        },
        async likeCommentReply(reply_id){
            let apiResponse = await ApiService.LikeCommentReply(this.feed.id, reply_id)
            if(apiResponse['status'] != 'success'){
            }else{
                this.feed = apiResponse['data']
                this.commentChecker()
            }
            this.$store.commit("toast/Notify", {
                type: apiResponse["status"],
                message: apiResponse["message"],
            });
        },
        async unlikeCommentReply(reply_id){
            let apiResponse = await ApiService.UnlikeCommentReply(this.feed.id, reply_id)
            if(apiResponse['status'] != 'success'){
            }else{
                this.feed = apiResponse['data']
                this.commentChecker()
            }
            this.$store.commit("toast/Notify", {
                type: apiResponse["status"],
                message: apiResponse["message"],
            });
        },
        async likeCommentReplyReply(reply_id){
            let apiResponse = await ApiService.LikeCommentReplyReply(this.feed.id, reply_id)
            if(apiResponse['status'] != 'success'){
            }else{
                this.feed = apiResponse['data']
                this.commentChecker()
            }
            this.$store.commit("toast/Notify", {
                type: apiResponse["status"],
                message: apiResponse["message"],
            });
        },
        async unlikeCommentReplyReply(reply_id){
            let apiResponse = await ApiService.UnlikeCommentReplyReply(this.feed.id, reply_id)
            if(apiResponse['status'] != 'success'){
            }else{
                this.feed = apiResponse['data']
                this.commentChecker()
            }
            this.$store.commit("toast/Notify", {
                type: apiResponse["status"],
                message: apiResponse["message"],
            });
        },
        commentChecker(){
            if(this.feed && this.feed.comments){
                let comments = this.feed.comments.length
                if(comments > this.noOfPreviewComments){
                    this.unviewedComments = comments-this.noOfPreviewComments
                }
                if(this.preview == 'true'){
                    this.comments = this.feed.comments.slice(0,this.noOfPreviewComments)
                }else{
                    this.comments = this.feed.comments
                }
                
            }
        },
        async getProduct(id) {
            this.toggleProgress('show')
            let apiResponse = await ApiService.GetProduct(id);
            if (apiResponse["status"] != "success") {} else {
                this.product = apiResponse['data']['product']
                this.feed = apiResponse['data']['feed']
                this.toggleProgress('hide')
                let vueThis = this
                // setTimeout(() => {
                //     vueThis.locateComment()
                // }, 2000);
            }
        },
        async addItemToCart(id) {
            this.toggleProgress('show')
            let apiResponse = await ApiService.AddProductToCart(id, this.color, this.size);
            this.$store.commit("toast/Notify", {
                type: apiResponse["status"],
                message: apiResponse["message"],
            });
            if (apiResponse["status"] != "success") {} else {
                location.href = ''
            }
            this.toggleProgress('hide')
        },
        async deleteProduct() {
            this.toggleProgress('show')
            let apiResponse = await ApiService.DeleteProduct(this.product['id']);
            this.$store.commit("toast/Notify", {
                type: apiResponse["status"],
                message: apiResponse["message"],
            });
            if (apiResponse["status"] != "success") {} else {
                location.href = '/dashboard/products'
            }
        },
        confirmDelete() {
            let proceed = confirm("Are you sure you want to proceed?");
            if (proceed) {
                this.deleteProduct()
            } else {
                //don't proceed
            }
        },
        confirmFavouriteProduct() {
            let proceed = confirm("Are you sure you want to proceed?");
            if (proceed) {
                this.favouriteProduct(this.product.id)
            } else {
                //don't proceed
            }
        },
        confirmUnfavouriteProduct() {
            let proceed = confirm("Are you sure you want to proceed?");
            if (proceed) {
                this.unfavouriteProduct(this.product.id)
            } else {
                //don't proceed
            }
        },
        async favouriteProduct(id) {
            this.toggleProgress('show')
            this.pendingRequest = true;
            let apiResponse = await ApiService.FavouriteProduct(id);
            this.$store.commit("toast/Notify", {
                type: apiResponse["status"],
                message: apiResponse["message"],
            });
            if (apiResponse["status"] != "success") {} else {
                location.href = ''
            }
            this.toggleProgress('hide')
        },
        async unfavouriteProduct(id) {
            this.toggleProgress('show')
            this.pendingRequest = true;
            let apiResponse = await ApiService.UnfavouriteProduct(id);
            this.$store.commit("toast/Notify", {
                type: apiResponse["status"],
                message: apiResponse["message"],
            });
            if (apiResponse["status"] != "success") {} else {
                location.href = ''
            }
            this.toggleProgress('hide')
        },
        setCurrentLink(id, description) {
            let thisLink = this.$store.getters["host/seoUrl"]+'/product/'+id
            this.shareLink = thisLink
        },
        locateComment(){
            // let url = location.href
            // let contentLocator = ''
            // if(url.search('comment_id=') > -1){
            //     let urlSplit = url.split('comment_id=')
            //     let commentId = urlSplit[1]
            //     contentLocator = '#comment_'+commentId
            // }else if(url.search('comment_reply_id=') > -1){
            //     let urlSplit = url.split('comment_reply_id=')
            //     let commentReplyId = urlSplit[1]
            //     contentLocator = '#comment_reply_'+commentReplyId
            // }else if(url.search('comment_reply_reply_id=') > -1){
            //     let urlSplit = url.split('comment_reply_reply_id=')
            //     let commentReplyReplyId = urlSplit[1]
            //     contentLocator = '#comment_reply_reply_'+commentReplyReplyId
            // }
            // if(contentLocator){
            //     $(contentLocator).addClass('obvious')
            //     this.scrollToContent(contentLocator)
            //     setTimeout(() => {
            //         $(contentLocator).removeClass('obvious', {duration:2000,effect:'blind'})
            //     }, 4000);
            // }
        }
    },
}
</script>

<style lang="scss" scoped>
.some-modal-content {
    min-width: 400px;
    padding: 25px;

    .buttons button {
        padding: 10px;
        margin: 10px;
    }
}
</style>
