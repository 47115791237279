<template>
<div class="w-full md:w-1/2 lg:w-1/4 px-6 lg:px-8 mb-20 lg:mb-0">
    <span class="flex justify-center items-center w-20 h-20 rounded-lg">
        <img :src="imgLink" style="" class="social" />
    </span>
    <div class="pb-px mb-12 bg-gray-50"></div>
    <h3 class="mt-12 mb-8 text-lg font-bold font-heading" data-config-id="header1">
        {{ title }}
    </h3>
    <p class="text-lg" data-config-id="desc1">
        {{ subtitle }}
    </p>
</div>
</template>

<script>
export default {
    name: 'SocialWidget',
    props: {
        imgLink: String,
        title: String,
        subtitle: String
    },
    components: {},
    data() {
        return {

        }
    },
    mounted() {},
    methods: {}
}
</script>
