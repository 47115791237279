<template>
<div id="reply-chat-modal" class="create-post is-story" uk-modal="">
    <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical rounded-lg p-0 lg:w-5/12 relative shadow-2xl uk-animation-slide-bottom-small">
        <div class="text-center py-3 border-b">
            <h3 class="text-lg font-semibold">Reply Message</h3>
            <button class="uk-modal-close-default bg-gray-100 rounded-full p-2.5 right-2" type="button" uk-close="" uk-tooltip="title: Close ; pos: bottom ;offset:7"></button>
        </div>
        <div class="message-reply bg-gray-50 p-3">
            <template v-if="messageType == 'text'">
                <textarea style="padding-left: 5px;padding-right: 5px;" @keydown="typingSocketConnection" @keyup="typingSocketConnection" @mouseout="stopTypingSocketConnection" v-model="message" cols="1" class="pl-2" rows="1" placeholder="Reply"></textarea>
            </template>
            <button v-if="messageType == 'text' || messageType == 'image'" @click="setMessageType('attachment')" class="button ripple-effect mr-2" style="padding: 0 10px;">
                <i class="fa fa-paperclip"></i>
            </button>
            <button v-if="messageType == 'attachment'" @click="setMessageType('image')" class="button ripple-effect mr-2" style="padding: 0 10px;">
                <i class="fa fa-file-image"></i>
            </button>
            <!-- <button v-if="messageType == 'attachment'" @click="setMessageType('link')" class="button ripple-effect mr-2" style="padding: 0 10px;">
                <i class="fa fa-globe"></i>
            </button> -->
            <button v-if="messageType == 'attachment'" @click="setMessageType('text')" class="button ripple-effect mr-2" style="padding: 0 10px;">
                <i class="fa fa-text-width"></i>
            </button>
            <template v-if="messageType == 'text'">
                <button v-if="messageType == 'text'" @click="sendChatMessage" class="button ripple-effect">Reply</button>
                <button v-if="messageType == 'text'" @click="deleteChatMessage(chat_message.id)" class="button ripple-effect ml-3">Delete</button>
            </template>
            <template v-if="messageType == 'link'">
                <input style="padding-left: 5px;padding-right: 5px;" @keydown="typingSocketConnection" @keyup="typingSocketConnection" @mouseout="stopTypingSocketConnection" v-model="message" class="pl-2" placeholder="https://example.co" type="url" />
                <button v-if="messageType == 'link'" @click="sendChatMessage" class="button ripple-effect ml-3">Reply</button>
            </template>
            <template v-if="messageType == 'image'">
                <img v-if="image" :src="image" style="height:50px" class="mr-5" />
                <single-file-upload type="image" @uploadSuccess="onImageUploaded">
                    <svg class="bg-blue-100 h-9 p-1.5 rounded-full text-blue-600 w-9 cursor-pointer" fill="none" stroke="currentColor" viewbox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"></path>
                    </svg>
                </single-file-upload>
                <button v-if="messageType == 'image'" @click="sendChatMessage" class="ml-4 button ripple-effect">Reply</button>
            </template>
        </div>
    </div>
    <div id="progressLoader">
        <progress-loader></progress-loader>
    </div>
</div>
</template>

<script>

import {
    ApiService
} from '@/services/api.service'
import axios from 'axios'
import SingleFileUpload from '@/components/SingleFileUpload'
import ProgressLoader from '@/components/ProgressLoader'
export default {
    name: 'ReplyChatMessage',
    props: {
        chat_message: Object,
        current_shop: Object,
        message_from: String,
    },
    components: {
        ProgressLoader,
        SingleFileUpload,
    },
    data() {
        return {
            id: '',
            type: 'user',
            messageTemp: '',
            messageType: 'text',
            messageTempType: 'text',
            message: '',
            message_type: 'text',
            product_link: '',
            chatId: '',
            chatType: 'user',
            requestProgress: 'false',
            currentUser: '',
            chat_user_is: '',
            isConnected: false,
            socket: '',
            image: '',
            typing: 'false',
            chatSilent: 'false',
            messageToReply: ''
        }
    },
    // created() {
    //     this.setupSocketConnection()
    // },
    // beforeUnmount() {
    //     this.disconnect()
    // },
    mounted: function () {
        // console.log('-----', this.current_shop, this.chat_message)

        this.currentUser = this.$store.getters['user/currentUser'] || {}
        this.id = this.currentUser['id']
        this.messageToReply = this.chat_message
        this.chatId = this.messageToReply['chat_id']
        this.toggleProgress('hide')

        this.$root.$on("refresh-socket-id", (socket) => {
            // console.log("refresh-chats", data)
            this.socket = socket
        })

    },
    methods: {
        async sendChatMessage() {
            this.toggleProgress('show')
            this.messageTemp = this.message
            let apiResponse = await ApiService.SendChatMessage(this.message, this.message_type, this.message_from, this.chatId, this.product_link, this.messageToReply['id']);

            if (apiResponse["status"] != "success") {
                this.$store.commit("toast/Notify", {
                    type: apiResponse["status"],
                    message: apiResponse["message"],
                });
            } else {
                this.message = ''
                this.messageTemp = ''
                this.closeModal()
            }
            this.toggleProgress('hide')
        },
        async deleteChatMessage(id) {
            let proceed = confirm("Are you sure you want to delete message? ");
            if (proceed) {
                this.toggleProgress('show')
                let apiResponse = await ApiService.DeleteChatMessage(id);
                this.$store.commit("toast/Notify", {
                    type: apiResponse["status"],
                    message: apiResponse["message"],
                });
                if (apiResponse["status"] != "success") {} else {
                    this.closeModal()
                }
                this.toggleProgress('hide')
            } else {
                //don't proceed
                return
            }
        },
        setupSocketConnection() {
        },
        typingSocketConnection() {
            if (this.socket) {
                this.socket.emit('broadcast', {
                    event: 'user_typing',
                    user_id: this.otherUserID(this.chat, this.current_shop, 'user'),
                    chat_id: this.chatId
                });
            }
        },
        stopTypingSocketConnection() {
            if (this.socket) {
                this.socket.emit('broadcast', {
                    event: 'user_typing_stopped',
                    user_id: this.otherUserID(this.chat, this.current_shop, 'user'),
                    chat_id: this.chatId
                });
            }
        },
        setMessageType(a) {
            this.messageType = a
            this.message_type = a
            this.messageTempType = a
        },
        setMessageToReply(a) {
            this.messageToReply = a
        },
        disconnect() {
            if (this.socket) {
                this.socket.disconnect();
            }
        },
        onImageUploaded(response) {
            // Handle files like:
            // console.log('::::',response)
            if (response['status'] == 'success') {
                this.image = response['data']['file_name']
                this.message = this.image
            }
            this.$store.commit("toast/Notify", {
                type: response["status"],
                message: response["message"],
            });
        },
        handleImageAdded: function (file, Editor, cursorLocation, resetUploader) {
            // An example of using FormData
            // NOTE: Your key could be different such as:
            // formData.append('file', file)

            var formData = new FormData();
            formData.append("file", file);

            axios({
                    url: this.$store.getters['host/apiUrl'].replace('/api/v2', '/files/upload'),
                    method: "POST",
                    data: formData
                })
                .then(result => {
                    // console.log('result', result)
                    const url = result.data.data['file_name']; // Get url from response
                    Editor.insertEmbed(cursorLocation, "image", url);
                    resetUploader();
                })
                .catch(err => {
                    // console.log(err);
                });
        },
        closeModal() {
            $('#reply-chat-modal').removeClass('uk-open').hide();
        }
    },
}
</script>

<style lang="scss" scoped>

@media (max-width: 540px) {
    .message-reply {
        display: block;
    }
    .message-reply textarea {
        margin-bottom: 10px;
    }
}
</style>
