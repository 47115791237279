<template>
  <div id="wrapper" class="flex flex-col justify-between h-screen">
      <auth-styles pageNameMain="Login"></auth-styles>
      <auth-header></auth-header>

      <!-- Content-->
      <div>
          <div class="lg:p-12 max-w-xl lg:my-0 my-12 mx-auto p-6 space-y-">
              
              <form class="lg:p-10 p-6 space-y-3 relative bg-white shadow-xl rounded-md" v-on:submit.prevent="makeLogin">
                  <h1 class="lg:text-2xl text-xl font-semibold mb-6"> Login </h1>
                  <div>
                      <label class="mb-0"> Email Address </label>
                      <input v-model="email" type="email" placeholder="Info@example.com" class="bg-gray-100 h-12 mt-2 px-3 rounded-md w-full">
                  </div>
                  <div>
                      <label class="mb-0"> Password </label>
                      <input v-model="password" type="password" placeholder="******" class="bg-gray-100 h-12 mt-2 px-3 rounded-md w-full">
                  </div>

                  <div>
                      <button v-on:click="makeLogin" type="button" class="bg-blue-600 font-semibold p-4 mt-5 rounded-md text-center text-white w-full">
                          Login</button>
                  </div>
                  <div class="text-center">
                    <p>
                      <router-link to="/reset-password">Reset password</router-link>
                    </p>
                    <p>
                      Unactivated account? <router-link to="/request-activate-account">Click here</router-link>
                    </p>
                    <p>
                      Don't have an account? <a href="/register">Register</a>
                    </p>
                  </div>
              </form>

          </div>
      </div>

      <auth-scripts></auth-scripts>

  </div>
</template>

<script>
import { ApiService } from '@/services/api.service'
import AuthStyles from '../../include/Auth/AuthStyles'
import AuthScripts from '../../include/Auth/AuthScripts'
import AuthHeader from '@/include/Auth/AuthHeader'

export default {
  name: 'Login',
  data () {
    return {
      email: '',
      password: '',
      error: ''
    }
  },
  components: {
    AuthStyles,
    AuthScripts,
    AuthHeader,
  },
  mounted(){
    let accessToken = this.$store.getters['auth/accessToken']
    if(accessToken){
      location.href = '/dashboard/feeds'
    }
  },
  methods: {
    async makeLogin () {
      if(!this.email){
        return this.$store.commit('toast/Notify', { type: 'warning', message: 'email is required' })
      }
      if(!this.password){
        return this.$store.commit('toast/Notify', { type: 'warning', message: 'password is required' })
      }
      $('button').attr('disabled', true)
      let loginResponse = await ApiService.LoginUser(this.email,this.password)
      // console.log(loginResponse)
      this.$store.commit('toast/Notify', { type: loginResponse['status'], message: loginResponse['message'] })
      if(loginResponse['status'] != 'success'){
      }else{
        this.$store.commit('auth/SAVE_ACCESS_TOKEN', loginResponse['user']['remember_token'])
        let currentUser = this.userDataToCommitToStore(loginResponse['user'])
        this.$store.commit('user/SAVE_CURRENT_USER', currentUser)
        // this.$router.push("/dashboard/blogs");
        location.href = '/dashboard/feeds'
      }
      $('button').attr('disabled', false)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
