<template>
<div id="wrapper">
    <Header />

    <sidebar></sidebar>

    <!-- Main Contents -->
    <div class="main_content">
        <div class="mcontainer">

            <div class="flex justify-between relative md:mb-4 mb-3">
                <div class="flex-1">
                    <h2 class="text-2xl font-semibold"> Groups </h2>
                    <nav class="responsive-nav border-b md:m-0 -mx-4">
                        <ul>
                            <li :class="randomActive"><a @click="makeActiveSection('random')" class="mouse-pointer lg:px-2">   Suggestions </a></li>
                            <li :class="recentActive"><a @click="makeActiveSection('recent')" class="mouse-pointer lg:px-2"> Newest </a></li>
                            <li :class="userActive"><a @click="makeActiveSection('user')" class="mouse-pointer lg:px-2"> My group </a></li>
                        </ul>
                    </nav>
                </div>
                <a href="/dashboard/groups/create" class="flex items-center justify-center h-9 lg:px-5 px-2 rounded-md bg-orange text-white space-x-1.5 absolute right-0">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5">
                        <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clip-rule="evenodd"></path>
                    </svg>
                    <span class="md:block hidden"> Create </span>
                </a>
            </div>

            <div class="relative uk-slider" uk-slider="finite: true">

                <div class="uk-slider-container px-1 py-3">
                    <div v-if="groups.length == 0 && groupsLoading == false" class="bg-white p-2">
                        <h5>No groups in this section</h5>
                    </div>
                    <div v-if="groupsLoading == true" class="text-center my-3">
                        <i class="fa fa-spin fa-spinner fa-2x"></i>
                    </div>
                    <ul class="uk-slider-items uk-child-width-1-4@m uk-child-width-1-3@s uk-grid-small uk-grid" style="transform: translate3d(0px, 0px, 0px);">
                        <group-component v-for="group in groups" :group="group" :key="group.id"></group-component>
                        
                    </ul>

                    <a class="absolute bg-white bottom-1/2 flex items-center justify-center p-2 -left-4 rounded-full shadow-md text-xl w-9 z-10 dark:bg-gray-800 dark:text-white uk-invisible" href="javascript:;" uk-slider-item="previous"> <i class="icon-feather-chevron-left"></i></a>
                    <a class="absolute bg-white bottom-1/2 flex items-center justify-center p-2 -right-4 rounded-full shadow-md text-xl w-9 z-10 dark:bg-gray-800 dark:text-white" href="javascript:;" uk-slider-item="next"> <i class="icon-feather-chevron-right"></i></a>

                </div>
            </div>

            <div class="sm:my-6 my-3 flex-old d-none items-center justify-between border-b pb-3">
                <div>
                    <h2 class="text-xl font-semibold"> Categories </h2>
                    <p class="font-medium text-gray-500 leading-6"> Find a group by browsing top categories. </p>
                </div>
                <a href="javascript:;" class="text-blue-500 sm:block hidden"> See all </a>
            </div>

            <div class="relative -mt-3 uk-slider-old d-none" uk-slider-old="finite: true">

                <div class="uk-slider-container px-1 py-3">
                    <ul class="uk-slider-items uk-child-width-1-5@m uk-child-width-1-3@s uk-child-width-1-2 uk-grid-small uk-grid" style="transform: translate3d(0px, 0px, 0px);">

                        <li tabindex="-1" class="uk-active">
                            <div class="rounded-md overflow-hidden relative w-full h-36">
                                <div class="absolute w-full h-3/4 -bottom-12 bg-gradient-to-b from-transparent to-gray-800 z-10">
                                </div>
                                <img src="/assets/images/category/travel.jpg" class="absolute w-full h-full object-cover" alt="">
                                <div class="absolute bottom-0 w-full p-3 text-white z-20 font-semibold text-lg"> Travel </div>
                            </div>
                        </li>
                        <li tabindex="-1" class="uk-active">
                            <div class="rounded-md overflow-hidden relative w-full h-36">
                                <div class="absolute w-full h-3/4 -bottom-12 bg-gradient-to-b from-transparent to-gray-800 z-10">
                                </div>
                                <img src="/assets/images/category/business.jpg" class="absolute w-full h-full object-cover" alt="">
                                <div class="absolute bottom-0 w-full p-3 text-white z-20 font-semibold text-lg"> Business </div>
                            </div>
                        </li>
                        <li tabindex="-1" class="uk-active">
                            <div class="rounded-md overflow-hidden relative w-full h-36">
                                <div class="absolute w-full h-3/4 -bottom-12 bg-gradient-to-b from-transparent to-gray-800 z-10">
                                </div>
                                <img src="/assets/images/category/health.jpg" class="absolute w-full h-full object-cover" alt="">
                                <div class="absolute bottom-0 w-full p-3 text-white z-20 font-semibold text-lg"> Health </div>
                            </div>
                        </li>
                        <li tabindex="-1" class="uk-active">
                            <div class="rounded-md overflow-hidden relative w-full h-36">
                                <div class="absolute w-full h-3/4 -bottom-12 bg-gradient-to-b from-transparent to-gray-800 z-10">
                                </div>
                                <img src="/assets/images/category/science-and-tech.jpg" class="absolute w-full h-full object-cover" alt="">
                                <div class="absolute bottom-0 w-full p-3 text-white z-20 font-semibold text-lg"> Science </div>
                            </div>
                        </li>
                        <li tabindex="-1" class="uk-active">
                            <div class="rounded-md overflow-hidden relative w-full h-36">
                                <div class="absolute w-full h-3/4 -bottom-12 bg-gradient-to-b from-transparent to-gray-800 z-10">
                                </div>
                                <img src="/assets/images/category/Buy-and-sell.jpg" class="absolute w-full h-full object-cover" alt="">
                                <div class="absolute bottom-0 w-full p-3 text-white z-20 font-semibold text-lg"> Buy &amp; sell</div>
                            </div>
                        </li>
                        <li tabindex="-1" class="">
                            <div class="rounded-md overflow-hidden relative w-full h-36">
                                <div class="absolute w-full h-3/4 -bottom-12 bg-gradient-to-b from-transparent to-gray-800 z-10">
                                </div>
                                <img src="/assets/images/category/travel.jpg" class="absolute w-full h-full object-cover" alt="">
                                <div class="absolute bottom-0 w-full p-3 text-white z-20 font-semibold text-lg"> Travel </div>
                            </div>
                        </li>

                    </ul>
                </div>

                <a class="absolute bg-white top-16 flex items-center justify-center p-2 -left-4 rounded-full shadow-md text-xl w-9 z-10 dark:bg-gray-800 dark:text-white uk-invisible" href="javascript:;" uk-slider-item="previous"> <i class="icon-feather-chevron-left"></i></a>
                <a class="absolute bg-white top-16 flex items-center justify-center p-2 -right-4 rounded-full shadow-md text-xl w-9 z-10 dark:bg-gray-800 dark:text-white" href="javascript:;" uk-slider-item="next"> <i class="icon-feather-chevron-right"></i></a>

            </div>

            <br>

            <div class="lg:flex lg:space-x-12">

                <div class="lg:w-3/4">

                    <h3 class="text-xl font-semibold"> Your Groups </h3>
                    <nav class="responsive-nav border-b">
                        <ul>
                            <li :class="allActiveFollowed"><a @click="makeActiveSectionFollowed('')" href="javascript:;" class="lg:px-2"> All Groups</a></li>
                            <li :class="recentActiveFollowed"><a @click="makeActiveSectionFollowed('recent')" href="javascript:;" class="lg:px-2"> Recently added </a></li>
                        </ul>
                    </nav>

                    <div class="grid md:grid-cols-2 divide divide-gray-200 gap-x-4 mt-4 card px-2">

                        <div v-if="groups_followed.length == 0 && groupsFollowedLoading == false" class="bg-white p-2">
                            <h5>No followed groups under this section </h5>
                        </div>
                        <div v-if="groupsFollowedLoading == true" class="text-center my-3">
                            <i class="fa fa-spin fa-spinner fa-2x"></i>
                        </div>
                        <groups-followed-component v-for="group in groups_followed" v-if="group" :group="group['group']" :key="'s_'+group.id"></groups-followed-component>

                    </div>
                </div>
                <div class="lg:w-1/4 flex-shrink-0 space-y-5">

                    <h2 class="text-xl font-semibold mt-7"> Suggested For You </h2>
                    <div v-if="suggestedGroups.length == 0 && groupsSuggestedLoading == false" class="bg-white p-2">
                        <h5>No Suggested groups under this section </h5>
                    </div>
                    <div v-if="groupsSuggestedLoading == true" class="text-center my-3">
                        <i class="fa fa-spin fa-spinner fa-2x"></i>
                    </div>
                    <group-suggestion-two-component v-for="group in suggestedGroups" v-if="group" :group="group" :key="'s_2_'+group.id"></group-suggestion-two-component>

                </div>

            </div>

            <div class="my-6 flex items-center justify-between border-b pb-3">
                <div>
                    <h2 class="text-xl font-semibold"> Suggestions </h2>
                    <p class="font-medium text-gray-500 leading-6"> Find a groups You Might Be Interested In. </p>
                </div>
                <a href="javascript:" class="text-blue-500 sm:block hidden"> See all </a>
            </div>

            <div class="grid md:grid-cols-2 divide divide-gray-200 gap-x-6 gap-y-4 card p-2">

                <div v-if="suggestedGroups.length == 0 && groupsSuggestedLoading == false" class="bg-white p-2">
                    <h5>No Suggested groups under this section </h5>
                </div>
                <div v-if="groupsSuggestedLoading == true" class="text-center my-3">
                    <i class="fa fa-spin fa-spinner fa-2x"></i>
                </div>
                <group-suggestion-component v-for="group in suggestedGroups" :group="group" :key="'random_'+group.id"></group-suggestion-component>

                <div class="flex-old d-none items-center space-x-4">
                    <div class="w-20 h-20 flex-shrink-0 rounded-md relative mb-3">
                        <img src="/assets/images/group/group-4.jpg" class="absolute w-full h-full inset-0 rounded-md object-cover shadow-sm" alt="">
                    </div>
                    <div class="flex-1 border-b pb-3">
                        <a href="timeline-group.html" class="text-lg font-semibold capitalize"> Mountain Riders</a>
                        <div class="flex space-x-2 items-center text-sm">
                            <div> 16K Members</div>
                            <div>·</div>
                            <div> 12 posts a week</div>
                        </div>
                        <div class="flex items-center mt-2">
                            <img src="/assets/images/avatars/avatar-2.jpg" class="w-6 h-6 rounded-full border-2 border-gray-200 -mr-2" alt="">
                            <img src="/assets/images/avatars/avatar-4.jpg" class="w-6 h-6 rounded-full border-2 border-gray-200" alt="">
                            <div class="text-sm text-gray-500 ml-2"> 2 friends are members</div>
                        </div>

                    </div>
                    <a href="javascript:;" class="flex items-center justify-center h-9 px-3 rounded-md bg-blue-100 text-blue-500">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 mr-2">
                            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clip-rule="evenodd"></path>
                        </svg>Follow
                    </a>
                </div>
            </div>

        </div>
        <div id="progressLoader">
            <progress-loader></progress-loader>
        </div>
    </div>

    <dashboard-widgets page="groups"></dashboard-widgets>
</div>
</template>

<script>
import {
    ApiService
} from "@/services/api.service";
import Header from "@/include/Dashboard/Header.vue";
import Sidebar from "@/include/Dashboard/Sidebar.vue";
import DashboardWidgets from '@/components/DashboardWidgets'
import OpenChatBox from "@/include/Modals/OpenChatBox.vue";
import Feeds from "@/include/Dashboard/Feeds.vue";
import ProgressLoader from '@/components/ProgressLoader'
import GroupComponent from '@/components/GroupComponent'
import GroupSuggestionComponent from '@/components/GroupSuggestionComponent'
import GroupsFollowedComponent from '@/components/GroupsFollowedComponent'
import GroupSuggestionTwoComponent from '@/components/GroupSuggestionTwoComponent'
import VerifiedBadge from '@/components/VerifiedBadge'

export default {
    name: "Groups",
    components: {
        Header,
        Sidebar,
        DashboardWidgets,
        OpenChatBox,
        Feeds,
        ProgressLoader,
        GroupComponent,
        GroupSuggestionComponent,
        GroupsFollowedComponent,
        GroupSuggestionTwoComponent,
        VerifiedBadge,
    },

    data() {
        return {
            groups: [],
            groups_followed: [],
            page: 0,
            pendingRequest: false,
            recentActive: '',
            trendingActive: '',
            oldestActive: '',
            randomActive:'active',
            userActive: '',
            allActiveFollowed: '',
            recentActiveFollowed: '',
            trendingActiveFollowed: '',
            oldestActiveFollowed: '',
            randomActiveFollowed:'active',
            userActive: '',
            topContributions: '',
            topContributors: '',
            suggestedGroups: '',
            groupsLoading: false,
            groupsFollowedLoading: false,
            groupsSuggestedLoading: false,
            pagination: {
                limit: 20,
                offset: 0,
                total: 60,
            },
        };
    },
    mounted: function () {
        this.makeActiveSection('random');
        this.makeActiveSectionFollowed('all');
        this.getSuggestedGroups()
    },
    methods: {
        async getGroups(section) {
            this.groupsLoading = true;
            this.toggleProgress('show')
            let apiResponse = await ApiService.GetGroups(this.page, section);
            if (apiResponse["status"] != "success") {} else {
                this.page++;
                this.groups = apiResponse["data"]['groups'];
                this.groupsLoading = false;
            }
            this.toggleProgress('hide')
        },
        async getFollowedGroups(section) {
            this.groupsFollowedLoading = true;
            this.toggleProgress('show')
            let apiResponse = await ApiService.GetFollowedGroups(this.page_followed, section);
            if (apiResponse["status"] != "success") {} else {
                // this.page_followed++;
                this.groups_followed = apiResponse["data"]['groups'];
                this.groupsFollowedLoading = false;
            }
            this.toggleProgress('hide') 
        },
        async getSuggestedGroups() {
            this.pendingRequest = true;
            this.groupsSuggestedLoading = true;
            this.toggleProgress('show')
            let apiResponse = await ApiService.GetGroups(0, 'random');
            if (apiResponse["status"] != "success") {} else {
                this.suggestedGroups = apiResponse["data"]['groups'];
                this.pendingRequest = false;
                this.groupsSuggestedLoading = false;
            }
            this.toggleProgress('hide')
        },
        async makeActiveSection(a) {
            this.recentActive = ''
            this.oldestActive = ''
            this.trendingActive = ''
            this.userActive = ''
            this.randomActive = ''

            this.page = 0;
            this.groups = []

            if (a.toLowerCase() == 'recent') {
                this.recentActive = 'active'
                this.getGroups('recent')
            } else if (a.toLowerCase() == 'oldest') {
                this.oldestActive = 'active'
                this.getGroups('oldest')
            } else if (a.toLowerCase() == 'trending') {
                this.trendingActive = 'active'
                this.getGroups('trending')
            } else if (a.toLowerCase() == 'random') {
                this.randomActive = 'active'
                this.getGroups('random')
            } else if (a.toLowerCase() == 'user') {
                this.userActive = 'active'
                this.getGroups('user')
            }
        },
        async makeActiveSectionFollowed(a) {
            this.allActiveFollowed = ''
            this.recentActiveFollowed = ''
            this.oldestActiveFollowed = ''
            this.trendingActiveFollowed = ''
            this.userActiveFollowed = ''
            this.randomActiveFollowed = ''

            this.page_followed = 0;
            this.groups_followed = []

            if (a.toLowerCase() == 'recent') {
                this.recentActiveFollowed = 'active'
                this.getFollowedGroups('recent')
            } else if (a.toLowerCase() == 'oldest') {
                this.oldestActiveFollowed = 'active'
                this.getFollowedGroups('oldest')
            } else if (a.toLowerCase() == 'trending') {
                this.trendingActiveFollowed = 'active'
                this.getFollowedGroups('trending')
            } else if (a.toLowerCase() == 'random') {
                this.randomActiveFollowed = 'active'
                this.getFollowedGroups('random')
            } else{
                this.allActiveFollowed = 'active'
                this.getFollowedGroups('')
            } 
        }
    },
};
</script>

<style>
.group-image img {
    width: 3rem !important;
    height: 3rem !important;
    border-radius: 9999px;
    position: relative;
}
</style>

<style lang="scss" scoped>
.some-modal-content {
    min-width: 400px;
    padding: 25px;

    .buttons button {
        padding: 10px;
        margin: 10px;
    }
}
</style>
