<template>
<div>
    <template v-if="chat.is_blocked != 'true'">
        <div>
            <small v-if="typing == 'true'" class="pl-5">{{ otherUserName(chat, currentShop, 'user') }} is typing</small>
        </div>
        <div class="message-reply bg-gray-50">
            <!-- <button v-if="message_type == 'text' || message_type == 'image'" @click="setMessageType('attachment')" class="button ripple-effect mr-2" style="padding: 0 10px;">
                    <i class="fa fa-paperclip"></i>
                </button>
                <button v-if="message_type == 'attachment'" @click="setMessageType('image')" class="button ripple-effect mr-2" style="padding: 0 10px;">
                    <i class="fa fa-file-image"></i>
                </button> -->
            <!-- <button v-if="message_type == 'attachment'" @click="setMessageType('link')" class="button ripple-effect mr-2" style="padding: 0 10px;">
                    <i class="fa fa-globe"></i>
                </button> -->
            <!-- <button v-if="message_type == 'attachment'" @click="setMessageType('text')" class="button ripple-effect mr-2" style="padding: 0 10px;">
                    <i class="fa fa-text-width"></i>
                </button> -->
            <template>
                <div click="setMessageType('image')">
                    <single-file-upload type="image" @uploadSuccess="onImageUploaded">
                        <button class="button ripple-effect mr-2" style="padding: 0 10px;">
                            <i class="fa fa-paperclip"></i>
                        </button>
                    </single-file-upload>
                </div>
                <textarea :disabled="disableMessageButton" v-if="message_type == 'text'" id="chat_message" @keydown="userIsTyping('#chat_message')" @keyup="userIsTyping('#chat_message', 'true')" @mouseout="stopTypingSocketConnection" v-model="message" cols="1" class="pl-2" rows="1" placeholder="Your Message" />
                <textarea :disabled="disableMessageButton" v-else @click="setMessageType('text')"  cols="1" class="pl-2" rows="1" placeholder="Image" readonly />
                <button @click="sendChatMessage" class="button ripple-effect" :disabled="disableMessageButton">Send</button>
            </template>
                <!-- <template v-if="message_type == 'link'">
                    <input @keydown="typingSocketConnection" @keyup="typingSocketConnection" @mouseout="stopTypingSocketConnection" v-model="message" class="pl-2" placeholder="https://example.co" type="url" />
                    <button v-if="message_type == 'link'" @click="sendChatMessage" class="button ripple-effect">Send</button>
                </template> -->
                <!-- <template v-if="message_type == 'image'">
                    <img v-if="image" :src="image" style="height:50px"  class="mr-5"/>
                    <single-file-upload type="image" @uploadSuccess="onImageUploaded">
                        <svg class="bg-blue-100 h-9 p-1.5 rounded-full text-blue-600 w-9 cursor-pointer" fill="none" stroke="currentColor" viewbox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"></path>
                        </svg>
                    </single-file-upload>
                    <button v-if="message_type == 'image'" @click="sendChatMessage" class="ml-4 button ripple-effect">Send</button>
                </template> -->
        </div>
        <div id="chat_message_tags">
        </div>
        <div class="px-3 py-2 d-flex">
            <div v-for="(im,i) in images" class="mr-3">
                <p class="text-right" style="margin-bottom: -10px; z-index: 99999; position: relative;">
                    <a @click="deleteImagesItem(i)" href="Javascript:;">
                        <i class="fa fa-times-circle"></i>
                    </a>
                </p>
                <a :href="im" target="_blank">
                    <img :src="im" style="height:50px"  class=""/>
                </a>
            </div>
        </div>
    </template>
    <template else>
        <div>
            <block-chat :chat="chat"></block-chat>
        </div>
    </template>
</div>
    
</template>

<script>
import SingleFileUpload from '@/components/SingleFileUpload'
import BlockChat from '@/components/Chats/BlockChat'
export default {
    props: {
        chat: Object,
    },
    name: 'Chats',
    components: {
        SingleFileUpload,
        BlockChat,
    },

    data() {
        return {
            id: '',
            type: 'user',
            message: '',
            message_type: 'text',
            currentUser: '',
            currentShop: '',
            chat_user_is: '',
            isConnected: false,
            socketMessage: '',
            socket: '',
            image: '',
            images: [],
            typing: 'false',
            chatSilent: 'false',
            messageToReply: '',
            clicks: 0,
            disableMessageButton: false,
        }
    },
    mounted () {
        this.currentUser = this.$store.getters['user/currentUser'] || {}

        this.$root.$on("refresh-socket-id", (socket) => {
            console.log('Refresh socket', socket)
            this.socket = socket
        })
        this.$root.$on("refresh-typing-true", (data) => {
            if (this.currentUser['id'] == data['user_id'] && this.chatId == data['chat_id']) {
                this.typing = 'true'
            }
        })
        this.$root.$on("refresh-typing-false", (data) => {
            if (this.currentUser['id'] == data['user_id'] && this.chatId == data['chat_id']) {
                this.typing = 'false'
            }
        })
        this.$root.$on("refresh-user-online", (data) => {
            $(".chat_user_" + data['user_id']).show()
        })
        this.$root.$on("refresh-user-offline", (data) => {
            $(".chat_user_" + data['user_id']).hide()
        })
        this.$root.$on("chat-message-sent", (status) => {
            this.disableMessageButton = false
            if(status == 'error'){
            }else{
                this.message = ''
                this.image = ''
                this.images = []
            }
        })
    },
    methods: {
        async sendChatMessage() {
            let data = {
                'message': this.message,
                'message_type': this.message_type,
                'image': this.image,
                'images': this.images,
            }
            this.$emit('sendChatMessage', data)
        },
        userIsTyping(a, b = 'false') {
            this.typingSocketConnection()
            if (b == 'true') {
                this.manageTagging(a)
            }
        },
        setupSocketConnection() {},
        typingSocketConnection() {
            if (this.socket) {
                this.socket.emit('broadcast', {
                    event: 'user_typing',
                    user_id: this.otherUserID(this.chat, this.currentShop, 'user'),
                    chat_id: this.chatId
                });
            }
        },
        stopTypingSocketConnection() {
            if (this.socket) {
                this.socket.emit('broadcast', {
                    event: 'user_typing_stopped',
                    user_id: this.otherUserID(this.chat, this.currentShop, 'user'),
                    chat_id: this.chatId
                });
            }
        },
        setMessageType(a) {
            this.message_type = a
            this.messageTempType = a
        },
        setMessageToReply(a) {
            this.messageToReply = a
            this.clicks++;
            // console.log('::::', this.clicks)
            if (this.clicks === 2) {
                UIkit.modal('#reply-chat-modal').toggle();
                this.clicks = 0
            } else {}
            let vueThis = this
            setTimeout(() => {
                vueThis.clicks = 0
            }, 300);
            // $('#reply-chat-modal').addClass('uk-open').show();
        },
        disconnect() {
            if (this.socket) {
                this.socket.disconnect();
            }
        },
        onImageUploaded(response) {
            // Handle files like:
            // console.log('::::',response)
            if (this.images.length >= 10) {
                this.$store.commit("toast/Notify", {
                    type: 'warning',
                    message: 'max of 10 images per message',
                });
                return
            }
            if (response['status'] == 'success') {
                this.image = response['data']['file_name']
                this.images.push(this.image)
            }
            this.$store.commit("toast/Notify", {
                type: response["status"],
                message: response["message"],
            });
        },
        deleteImagesItem(i) {
            this.images = this.images.splice(0, i)
        }
    },
}
</script>
<style scoped>
.message-reply {
    padding: 10px;
}
.message-reply textarea {
    padding-left: 5px;
}
</style>