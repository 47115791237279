<template>
<div id="wrapper">

    <Header />

    <sidebar></sidebar>

    <!-- Main Contents -->
    <div class="main_content">
        <div class="mcontainer">

            <!--  breadcrumb -->
            <div class="breadcrumb-area py-0">
                <div class="breadcrumb">
                    <ul class="m-0">
                        <li>
                            <a href="/dashboard/forums">Forums</a>
                        </li>
                        <li class="active">
                            <a href="">Create New Forum Post </a>
                        </li>
                    </ul>
                </div>
            </div>

            <!-- create page-->
            <div class="max-w-2xl m-auto shadow-md rounded-md bg-white lg:mt-20">

                <!-- form header -->
                <div class="text-center border-b border-gray-100 py-6">
                    <h3 class="font-bold text-xl"> Create New Forum Post </h3>
                </div>

                <!-- form body -->
                <div class="sm:p-10 p-3 space-y-7">

                    <div class="line">
                        <input v-model="title" class="line__input" id="title" name="title" type="text" onkeyup="this.setAttribute('value', this.value);" value="" autocomplete="off">
                        <span for="title" class="line__placeholder"> Title </span>
                    </div>
                    <div class="flex items-center">
                        <div class="mr-2">
                            <p>Forum Image</p>
                            <img v-if="image" :src="image" class="postImgheight" />
                            <img v-else :src="defaultPostUrl()" class="postImgheight" />
                        </div>
                        <single-file-upload type="image" @uploadSuccess="onImageUploaded">
                            <p>Select<br /> Image</p>
                        </single-file-upload>
                    </div>
                    <div>
                        <!-- <label for=""> Category </label><br/><br/> -->
                        <select v-model="category" id="" name="" class="shadow-none with-border ">
                            <option value="">Select Category</option>
                            <option v-for="c in postCategories">{{ c }}</option>
                        </select>
                    </div>
                    <div class="">
                        <label for="" class="mb-2"> Content </label>
                        <vue-editor id="editor" v-model="content" useCustomImageHandler @image-added="handleImageAdded"></vue-editor>
                    </div>

                </div>

                <!-- form footer -->
                <div class="border-t flex justify-between lg:space-x-10 p-7 bg-gray-50 rounded-b-md">
                    <button type="button" class="button lg:w-1/2" @click="addForumPost">
                        Create Now
                    </button>
                </div>

            </div>

        </div>
    </div>

    <progress-loader v-if="requestProgress == 'true'"></progress-loader>
    <dashboard-widgets page="forum"></dashboard-widgets>

</div>
</template>

<script>
import {
    VueEditor
} from "vue2-editor";
import {
    ApiService
} from '@/services/api.service'
import Header from '@/include/Dashboard/Header.vue'
import Sidebar from '@/include/Dashboard/Sidebar.vue'
import DashboardWidgets from '@/components/DashboardWidgets'
import OpenChatBox from '@/include/Modals/OpenChatBox.vue'
import Feeds from '@/include/Dashboard/Feeds.vue'
import ProfileIcon from '@/include/Dashboard/Feed/ProfileIcon'
import ProgressLoader from '@/components/ProgressLoader'
import SingleFileUpload from '@/components/SingleFileUpload'
import axios from 'axios'

export default {
    name: 'CreateForum',
    components: {
        Header,
        Sidebar,
        DashboardWidgets,
        OpenChatBox,
        Feeds,
        ProgressLoader,
        SingleFileUpload,
        VueEditor
    },

    data() {
        return {
            image: '',
            category: '',
            title: '',
            content: '',
            postCategories: '',
            requestProgress: 'false'
        }
    },
    mounted: function () {
        this.getExtraInfo()
    },
    methods: {
        async getExtraInfo() {
            this.requestProgress = 'true'
            let apiResponse = await ApiService.ExtraInfo();
            // console.log('getExtraInfo', apiResponse)
            if (apiResponse['status'] == 'success') {
                this.postCategories = apiResponse['data']['post_categories']
            }
            this.requestProgress = 'false'
        },
        async addForumPost() {
            this.requestProgress = 'true'
            let apiResponse = await ApiService.AddForumPost(this.image, this.title, this.content, this.category);
            if (apiResponse["status"] != "success") {
                this.$store.commit("toast/Notify", {
                    type: apiResponse["status"],
                    message: apiResponse["message"],
                });
            } else {
                this.image = ''
                this.category = ''
                this.title = ''
                this.content = ''

                location.href = '/dashboard/forums'
            }
            this.requestProgress = 'false'
        },
        onImageUploaded(response) {
            // Handle files like:
            // console.log('::::',response)
            if (response['status'] == 'success') {
                this.image = response['data']['file_name']
            }
            this.$store.commit("toast/Notify", {
                type: response["status"],
                message: response["message"],
            });
        },
        handleImageAdded: function (file, Editor, cursorLocation, resetUploader) {
            // An example of using FormData
            // NOTE: Your key could be different such as:
            // formData.append('file', file)

            var formData = new FormData();
            formData.append("file", file);

            axios({
                    url: this.$store.getters['host/apiUrl'].replace('/api/v2', '/files/upload'),
                    method: "POST",
                    data: formData
                })
                .then(result => {
                    // console.log('result', result)
                    const url = result.data.data['file_name']; // Get url from response
                    Editor.insertEmbed(cursorLocation, "image", url);
                    resetUploader();
                })
                .catch(err => {
                    console.log(err);
                });
        }
    },
}
</script>

<style lang="scss" scoped>
.some-modal-content {
    min-width: 400px;
    padding: 25px;

    .buttons button {
        padding: 10px;
        margin: 10px;
    }
}

.postImgheight {
    height: 100px
}

.editor {
    height: 350px;
}
</style>
