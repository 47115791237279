<template>
<Rave v-if="requestProgress == 'false' && raveKey" style-class="bg-green-600 custom_button mr-1 text-white col-xs-12" :email="email" :amount="amount" :reference="reference" :rave-key="raveKey" :callback="callback" :close="close" :metadata="meta" :redirectUrl="redirect" :paymentPlan="plan" :customerFirstname="name" paymentOptions="card,account,ussd" hostedPayemt="1" :customTitle="description" :currency="currency_flw"><i></i>{{ this.title }}</Rave>
</template>

<script>
import Rave from 'vue-ravepayment';
import {
    ApiService
} from '@/services/api.service'
export default {
    name: 'PayWithFlutterwave',
    components: {
        Rave
    },
    props: {
        title: String,
        email: String,
        phone: String,
        name: String,
        amount: Number,
        currency: String,
        description: String,
        productId: String,
    },
    data: function () {
        return {
            widget: '',
            requestProgress: 'true',
            country: 'NG',
            raveKey: "",
            plan: 0,
            redirect: "",
            currency_flw: '',
            meta: [{
                metaname: 'school',
                metavalue: 'high school'
            }],
            sub: [{
                    id: "1324"
                },
                {
                    id: "1221"
                }
            ]
        }
    },
    mounted() {
        this.getExtraInfo()
        // console.log('title',this.title,'email',this.email,'phone',this.phone,'name',this.name,'amount',this.amount,'currency',this.currency,'description',this.description,'productId',this.productId)

        this.currency_flw = this.currency
        if (this.currency.toLowerCase() == 'ngn') {
            this.country = 'NG'
        } else if (this.currency.toLowerCase() == 'cedi') {
            this.country = 'GH'
            this.currency_flw = 'GHS'
        } else if (this.currency.toLowerCase() == 'shilling') {
            this.country = 'KE'
            this.currency_flw = 'KES'
        } else {
            this.country = 'US'
        }
        console.log('Pay with', this.country, this.currency_flw, this.currency)
        let vueThis = this
        setTimeout(() => {
            vueThis.requestProgress = 'false'
        }, 2000);
    },
    computed: {
        reference() {
            let text = "";
            let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

            for (let i = 0; i < 10; i++)
                text += possible.charAt(Math.floor(Math.random() * possible.length));

            return text;
        }
    },
    methods: {
        callback: function (response) {
            // console.log(response)
            this.$emit('paymentCallback', response)
            let vueThis = this
            setTimeout(() => {
                vueThis.requestProgress = 'true'
            }, 2000);
        },
        close: function () {
            // console.log("Payment closed")
        },
        async getExtraInfo() {
            this.requestProgress = 'true'
            let apiResponse = await ApiService.ExtraInfo();
            if (apiResponse['status'] == 'success') {
                this.raveKey = apiResponse['data']['ravePublicKey']
            }
            // console.log('getExtraInfo', apiResponse, this.raveKey)
            this.requestProgress = 'false'
        },
    }
}
</script>

<style lang="scss" scoped>
</style>
<style>
 .paymentbtn {
     font-weight: 800;
 }
</style>
