<template>
<section class="py-10 2xl:py-20 blue-bg-light-custom">
    <div class="container px-4 mx-auto">
        <div class="mb-20 text-center">
            <span class="text-lg font-bold text-blue-500">Hey! Have any questions?</span>
            <div class="mt-8 text-7xl font-bold font-heading text-black">FAQ&apos;s</div>
        </div>
        <div class="max-w-4xl mx-auto">
            <ul>
                <faq class="mb-4 px-4 lg:px-12 py-8 bg-white rounded-2xl all mini max" id="1" question="How do I register either as an advertiser or a promoter?">
                    <div class="mt-6 border-l-2 border-gray-50pl-0">
                        <p>Hey! So you wish to go viral or earn some cool cash performing some social tasks with vitisocial, but don't know how to go about registration?</p>
                        <p>Don't beat yourself too much, registration is quite simple and the website is user-friendly.</p>
                        <p>To register all you need to do is visit our website <a class="text-blue-500" href="/">https://www.vitisocial.com</a></p>
                        <p>Please ensure that you choose 'promote and earn' while registering to start performing tasks or you can choose 'Advertiser' to publish adverts.</p>
                        <p>Once you have registered, the website would automatically send to you a verification link to the email address that you just registered with. </p>
                        <p>Once you receive the verification link, login into your email and click on 'verify' email. Choose a package, basic or premium account type, pay and you are now good to go!</p>
                        <p>Simple right?</p>
                        <h4>NOTE:</h4>
                        <p>You can also successfully register with viral trends, using the direct links below:</p>
                        <p>
                            <a class="text-blue-500" href="/register">/register</a>
                        </p>
                        <p class="d-none">
                            <a href="/register">/register</a>
                        </p>
                    </div>
                </faq>
                <faq class="mb-4 px-4 lg:px-12 py-8 bg-white rounded-2xl all mini max" id="2" question="How do I link my social media account to my viral trend account?">
                    <div class="mt-6 border-l-2 border-gray-50pl-0">
                        <p>So you have successfully registered with the Viral trend? Congratulations!</p>
                        <p>
                            But you are now confused on how you can link your social media accounts to your viral trend account?
                            <br />
                            Well, it is not as hard as you may think!
                        </p>
                        <p>
                            To link your social media accounts you would need to first log in your social media accounts to the site which includes <b>Facebook, Twitter, YouTube,</b> and <b>Instagram</b> accounts into different tabs in your browser (Firefox/chrome).
                            <br />
                            Then go to your viral trend dashboard, click on profile and connect your social media usernames correctly to the site.
                        </p>
                        <p>
                            However, you should note that you can always leave any social media network which you don't want to link. Be informed that such action would make you not to be seeing tasks from that particular social media from Vitisocial dashboard.
                            <br />
                            You can also skip the linking and continue from your profile when you are done with your registration.
                        </p>
                        <p>Please note that you must link only your active social media accounts with our platform as nonactive or dormant accounts are not accepted on our platform and when used on our platform can lead to our team banning your account.</p>

                    </div>
                </faq>
                <faq class="mb-4 px-4 lg:px-12 py-8 bg-white rounded-2xl all mini max" id="3" question="How to perform social media tasks such as Facebook, Twitter, Instagram, and YouTube.">
                    <div class="mt-6 border-l-2 border-gray-50pl-0">
                        <p>
                            Performing social media tasks Is quite simple on viral trend.<br />
                            To effectively do that, you need to ensure that your Instagram account is active in your browser/ app.
                        </p>
                        <p>After that go to your dashboard, and click 'perform' once you see Instagram tasks, then input your email and username to perform the task.</p>
                        <p>Simple right?</p>
                    </div>
                </faq>
                <faq class="mb-4 px-4 lg:px-12 py-8 bg-white rounded-2xl all mini max" id="4" question="I was restricted from commenting or liking on Instagram (action blocked)">
                    <div class="mt-6 border-l-2 border-gray-50pl-0">
                        <p>
                            oops! We are sorry for that.
                        </p>
                        <p>There are quite a few things that could cause that and fixing the problem isn't such a big deal.</p>
                        <p>Firstly try logging out and logging in from your Instagram account or you can try changing your Instagram password or just switch to another Instagram account.</p>
                        <p>If the problem persists, you can contact an Admin to change your Instagram account. Usually, the action block lasts for about 3-7 days. </p>
                    </div>
                </faq>
                <faq class="mb-4 px-4 lg:px-12 py-8 bg-white rounded-2xl all max" id="5" question="Site not opening or loading">
                    <div class="mt-6 border-l-2 border-gray-50pl-0">
                        <p>
                            We understand how frustrating it could be when you have a task to perform and the site is not opening or loading!
                        </p>
                        <p>You can fix this by kindly checking if your network is connected to the internet and refreshing. You can also clear your browser history and cache then try again.</p>
                        <p></p>
                    </div>
                </faq>
                <faq class="mb-4 px-4 lg:px-12 py-8 bg-white rounded-2xl all max" id="6" question="Issue of tasks being deactivated, can't be retrieved or paused.">
                    <div class="mt-6 border-l-2 border-gray-50pl-0">
                        <p>
                            This happens when the task has gotten the required number just before you finished performing it. However, if this happens too frequently please contact any of the admins.
                        </p>
                        <p></p>
                        <p></p>
                    </div>
                </faq>
                <faq class="mb-4 px-4 lg:px-12 py-8 bg-white rounded-2xl all max" id="7" question="how much one can make a day or how many tasks can one perform a day.">
                    <div class="mt-6 border-l-2 border-gray-50pl-0">
                        <p>
                            With Vitisocial there is no limit to how much you can make on the site daily. All you have to do is ensure all your social media accounts are connected correctly to the site. You also earn more money when you engage in referrals which gives you 25% or 40% depending on your registered package. And also stay active on the site to perform more tasks.
                        </p>
                        <p></p>
                        <p></p>
                    </div>
                </faq>
                <faq class="mb-4 px-4 lg:px-12 py-8 bg-white rounded-2xl all max" id="8" question="Why is my account Banned?">
                    <div class="mt-6 border-l-2 border-gray-50pl-0">
                        <p>
                            User account can get banned when a user get involved in some fraudulent activities like submitting success for a task or tasks he or she didn’t or couldn’t do. Or owning multiple accounts as to defraud the system and cheat our advertisers.
                        </p>
                        <p>If the case is for multiple accounts, all accounts will be banned except for one. If ban is due to other irregularities, we will review such account and then decide the next step.</p>
                        <p></p>
                    </div>
                </faq>
                <faq class="mb-4 px-4 lg:px-12 py-8 bg-white rounded-2xl all max" id="9" question="Can I lose Money on Vitisocial?">
                    <div class="mt-6 border-l-2 border-gray-50pl-0">
                        <p>
                            Vitisocial.com is 100% safe and we are not an investment company therefore you can not lose what you never invested with us. We render services to advertisers and we pay you the promoter to promote our services. We only charge you a one time registration fee to become a member. That simple.
                        </p>
                        <p></p>
                        <p></p>
                    </div>
                </faq>
                <faq class="mb-4 px-4 lg:px-12 py-8 bg-white rounded-2xl all max" id="10" question="How to play *fun play and win*">
                    <div class="mt-6 border-l-2 border-gray-50pl-0">
                        <p>
                            Do you know you can win up to 200k by playing 'fun play and win'?
                        </p>
                        <p>How?</p>
                        <p>Click on the link below to watch the video on how to win up to N200k or more from participating in play and win on <a class="text-blue-500" href="https://www.vitisocial.com">www.vitisocial.com</a></p>
                        <p><a class="text-blue-500" href="https://www.instagram.com/tv/B-bnDFtF8Il/?igshid=n366ycva4zqy">https://www.instagram.com/tv/B-bnDFtF8Il/?igshid=n366ycva4zqy</a></p>
                    </div>
                </faq>
                <faq class="mb-4 px-4 lg:px-12 py-8 bg-white rounded-2xl all" id="11" question="Differences between basic and premium plans">
                    <div class="mt-6 border-l-2 border-gray-50pl-0">
                        <p>
                            As you already know we have two basic plans on viral trend; the basic and premium plans.<br />
                            The major difference between both plans is Referral Commission, basic members earn 25% while premium earns 40%.
                        </p>
                        <p>Please note that the task's earnings are the same.</p>
                        <p></p>
                    </div>
                </faq>
                <faq class="mb-4 px-4 lg:px-12 py-8 bg-white rounded-2xl all" id="12" question="Issue of No new tasks for you">
                    <div class="mt-6 border-l-2 border-gray-50pl-0">
                        <p>
                            To fix this, Kindly ensure all your social media accounts are connected correctly to your profile and also opened in a different tab in your browser, Also stay active on the site to get more tasks.
                        </p>
                        <p></p>
                        <p></p>
                    </div>
                </faq>
                <faq class="mb-4 px-4 lg:px-12 py-8 bg-white rounded-2xl all" id="13" question="How To Perform Instagram Tasks illustrated">
                    <div class="mt-6 border-l-2 border-gray-50pl-0">
                        <p>
                            Click view more 👉 Accept Task 👉 Click on the link to perform the task 👉Then come back to your dashboard 👉 Click done👉 input your Instagram username and email address 👉Then click Submit*
                        </p>
                        <p></p>
                        <p></p>
                    </div>
                </faq>
                <faq class="mb-4 px-4 lg:px-12 py-8 bg-white rounded-2xl all" id="14" question="How To Perform Other Social Media Tasks illustrated">
                    <div class="mt-6 border-l-2 border-gray-50pl-0">
                        <p>
                            *Click Accept Task 👉 Click Perform Task 👉 Perform The Task 👉Then come back to your dashboard 👉 Wait For About 50 Seconds To Be Credited*
                        </p>
                        <p></p>
                        <p></p>
                    </div>
                </faq>
                <faq class="mb-4 px-4 lg:px-12 py-8 bg-white rounded-2xl all" id="15" question="Not receiving verification mail">
                    <div class="mt-6 border-l-2 border-gray-50pl-0">
                        <p>
                            If you requested verification mail and didn’t get it in your mailbox, please kindly check through your spam inbox or contact any of the Admin for further assistance. Meanwhile you can click on this link and input the email address again; <a class="text-blue-500" href="/email-verify">verify email</a>
                        </p>
                        <p></p>
                        <p></p>
                    </div>
                </faq>
                <faq class="mb-4 px-4 lg:px-12 py-8 bg-white rounded-2xl all" id="16" question="I have requested withdrawals but have yet to receive it">
                    <div class="mt-6 border-l-2 border-gray-50pl-0">
                        <p>
                            Withdrawals are processed within 24hrs and sometimes might exceed if we have more requests to attend to at that time. Please note that you can only withdraw 45 days after the first withdrawal. This helps you to earn and save more money for withdrawals. This also avail us the time to minimize user abuse and fraudulent activities.
                        </p>
                        <p></p>
                        <p></p>
                    </div>
                </faq>
            </ul>
            <div v-if="show == 'mini'" class="support-button text-center d-flex align-items-center justify-content-center mt-4 wow fadeInUp" data-wow-delay="0.5s" style="visibility: visible; animation-delay: 0.5s; animation-name: fadeInUp;">
                <router-link to="/faq">Show All</router-link>
            </div>
            <div v-else class="support-button text-center d-flex align-items-center justify-content-center mt-4 wow fadeInUp" data-wow-delay="0.5s" style="visibility: visible; animation-delay: 0.5s; animation-name: fadeInUp;">
                <i class="lni-emoji-sad"></i>
                <p class="mb-0 px-2">Can't find your answers?</p>
                <a href="mailto:support@vitisocial.com"> Contact us</a>
            </div>
        </div>
    </div>
</section>
</template>

<script>
import Faq from '@/components/Faq'
export default {
    name: 'FaqSection',
    props: {
        show: String
    },
    components: {
        Faq
    },
    data() {
        return {
            
        }
    },
    mounted() {
        $(".all").hide()
        if(this.show == 'mini'){
            $(".mini").show()
        }else{
            $(".all").show()
        }
    },
    methods: {}
}
</script>
