<template>
<transition>
    <router-view></router-view>
</transition>
</template>

<script>
import {
    ApiService
} from '@/services/api.service'
import * as PusherPushNotifications from "@pusher/push-notifications-web";
import {
    getMessaging,
    getToken
} from "firebase/messaging";
export default {
    name: "DashboardLayout",
    data() {
        return {
            msg: "",
            socket: "",
            currentUser: "",
        };
    },
    created() {
        this.managePageLock()
        this.currentUser = this.$store.getters["user/currentUser"] || {};
        this.setupSocketConnection();
        window.addEventListener("scroll", this.handleScroll);
    },
    destroyed() {
        window.removeEventListener("scroll", this.handleScroll);
    },
    beforeUnmount() {},
    mounted: function () {
        // disable dark mode 
        console.log('====-----------', $("html").hasClass('dark'))
        if ($("html").hasClass('dark')) {
            $("html").removeClass('dark')
        }
        this.detectFocusOut();
        this.initFCM()
        let vueThis = this
        setTimeout(() => {
            $('#allowFCMPermission').click(function () {
                vueThis.fcmRequestPermission()
            });
            $('#denyFCMPermission').click(function () {
                $('#allow-push-notification-bar').hide();
            });

        }, 2000);
    },
    watch: {
        $route(to, from) {
            this.managePageLock()
        },
    },
    methods: {
        setupSocketConnection() {
            let vueThis = this
        },
        disconnect() {},
        detectFocusOut() {
            let inView = true;

            const onWindowFocusChange = (e) => {
                if ({
                        focus: 1,
                        pageshow: 1
                    } [e.type]) {
                    if (inView) return;
                    this.tabFocus = true;
                    inView = true;
                } else if (inView) {
                    this.tabFocus = !this.tabFocus;
                    inView = false;
                }
                if (inView == false) {
                    // console.log("OUT")
                    this.disconnect();
                } else {
                    // console.log("IN")
                    this.setupSocketConnection();
                }
            };

            window.addEventListener("focus", onWindowFocusChange);
            window.addEventListener("blur", onWindowFocusChange);
            window.addEventListener("pageshow", onWindowFocusChange);
            window.addEventListener("pagehide", onWindowFocusChange);
        },
        managePageLock() {
            let url = location.href
            if (url.search('/dashboard/product') > -1 || url.search('/dashboard/job') > -1 || url.search('/dashboard/course') > -1 || url.search('/dashboard/shop') > -1) {
                location.href = '/dashboard/coming-soon'
            }
        },
        async initFCM() {
            try {
                // if (this.userBrowser() != 'Safari') {
                let fcmPermission = Notification.permission
                if (fcmPermission != 'granted') {
                    // console.log('FCM: Requesting permission...', fcmPermission);
                    // console.log('this.userBrowser()',this.userBrowser())
                    if (this.userBrowser() == 'Safari' || this.userBrowser() == 'Firefox') {
                        if (Notification.permission === 'default') {
                            $('#allow-push-notification-bar').show();
                        }
                    } else {
                        this.fcmRequestPermission()
                    }
                    return
                }

                // console.log('FCM: Has permission...', fcmPermission);

                let apiResponse = await ApiService.ExtraInfo()
                if (apiResponse['status'] != 'success') {} else {
                    const messaging = getMessaging();
                    getToken(messaging, {
                        vapidKey: apiResponse['data']['fcm_id']
                    }).then((currentToken) => {
                        if (currentToken) {
                            console.log('FCM: client token', currentToken)
                            ApiService.SavePushToken(currentToken)
                        } else {
                            console.log('FCM: No registration token available. Request permission to generate one.');
                        }
                    }).catch((err) => {
                        console.log('FCM: An error occurred while retrieving token. ', err);
                    });
                }

                // }
            } catch (error) {
                console.log('Pusher Error', error)
            }
        },
        async fcmRequestPermission() {
            Notification.requestPermission().then((permission) => {
                if (permission === 'granted') {
                    console.log('Notification permission granted.');
                } else if (permission === 'denied') {
                    console.log('Notification permission denied.');
                }
            });
        },
        async initPusher() {
            try {
                if (this.userBrowser() != 'Safari') {
                    let apiResponse = await ApiService.ExtraInfo()
                    if (apiResponse['status'] != 'success') {} else {
                        const beamsClient = new PusherPushNotifications.Client({
                            instanceId: apiResponse['data']['i_id'],
                        });
                        let accessToken = this.$store.getters['auth/accessToken']
                        let beamUrl = this.$store.getters['host/apiUrl'] + '' + '/dashboard/user/beams-auth';
                        const tokenProvider = new PusherPushNotifications.TokenProvider({
                            url: beamUrl,
                            queryParams: {
                                access_token: accessToken
                            },

                        })

                        beamsClient
                            .start()
                            .then((beamsClient) => beamsClient.setUserId(this.currentUser.id, tokenProvider))
                            .then(() => console.log('Successfully authenticated with PusherBeams ' + this.currentUser.id))
                            .catch(console.error);

                        beamsClient
                            .getUserId()
                            .then((userId) => {
                                console.log('=-=-=', userId, this.currentUser.id)
                                // Check if the Beams user matches the user that is currently logged in
                                if (userId != this.currentUser.id) {
                                    // Unregister for notifications
                                    return beamsClient.stop();
                                }
                            })
                            .catch(console.error);
                    }
                }
            } catch (error) {
                console.log('Pusher Error', error)
            }
        },
        async initPN() {
            let apiResponse = await ApiService.ExtraInfo()
            if (apiResponse['status'] != 'success') {} else {
                window.OneSignal = window.OneSignal || [];
                OneSignal.push(function () {
                    OneSignal.init({
                        appId: apiResponse['data']['os_app_id'],
                    });
                    let osUser = localStorage.getItem('os-user')
                    if (!osUser) {
                        OneSignal.getUserId(function (userId) {
                            ApiService.SavePushToken(userId)
                            localStorage.setItem('os-user', userId);
                        });
                    }
                });
            }
        },
        handleScroll(event) {
            this.$root.$emit("page-scrolled", {})
        },
    },
};
</script>
