import { io } from 'socket.io-client';
import $store from '../store'
import Vue from 'vue';

class SocketioService {
    socket;
    constructor() {}
    disconnect() {
        if (this.socket) {
            this.socket.disconnect();
        }
    }

    setupSocketConnection() {
        this.socket = io($store.getters['host/socketUrl']);
        this.socket.on('broadcast', (data) => {
            console.log(data);
            if (data['event']) {
                if (data['event'] == 'newMessage') {
                    let chatID = data['chat_id']
                    this.$root.$emit("refresh-chats", { chat_id: chatID })
                }
            }
        });
    }
}

export default new SocketioService();