<template>
<div id="wrapper">

    <Header />

    <sidebar></sidebar>

    <!-- Main Contents -->
    <div class="main_content">
        <div class="mcontainer">

            <!--  breadcrumb -->
            <div class="breadcrumb-area py-0">
                <div class="breadcrumb">
                    <ul class="m-0">
                        <li>
                            <a href="/dashboard/pages">Pages</a>
                        </li>
                        <li class="active">
                            <a href="">Create New Page </a>
                        </li>
                    </ul>
                </div>
            </div>

            <!-- create page-->
            <div class="max-w-2xl m-auto shadow-md rounded-md bg-white lg:mt-20">

                <!-- form header -->
                <div class="text-center border-b border-gray-100 py-6">
                    <h3 class="font-bold text-xl"> Create New Page </h3>
                </div>

                <!-- form body -->
                <div class="sm:p-10 p-3 space-y-7">

                    <div class="line">
                        <input v-model="name" class="line__input" id="name" name="name" type="text" onkeyup="this.setAttribute('value', this.value);" value="" autocomplete="off">
                        <span for="name" class="line__placeholder"> Name </span>
                    </div>
                    <div style="margin-top: 5px;font-size:12px; font-weight:bold">
                        <small>
                            <b>A page url will be generated using this name</b>
                        </small>
                    </div>
                    <div class="flex items-center">
                        <div class="mr-2">
                            <p>Page Image</p>
                            <img v-if="image" :src="image" class="postImgheight" />
                            <img v-else :src="defaultPostUrl()" class="postImgheight" />
                        </div>
                        <single-file-upload type="image" @uploadSuccess="onImageUploaded">
                            <p>Select<br /> Image</p>
                        </single-file-upload>
                    </div>
                    <div class="flex items-center">
                        <div class="mr-2">
                            <p>Page Banner</p>
                            <img v-if="banner" :src="banner" class="postImgheight" />
                            <img v-else :src="defaultPostUrl()" class="postImgheight" />
                        </div>
                        <single-file-upload type="image" @uploadSuccess="onBannerUploaded">
                            <p>Select<br /> Image</p>
                        </single-file-upload>
                    </div>
                    <div>
                        <!-- <label for=""> Category </label><br/><br/> -->
                        <select v-model="category" id="" name="" class="shadow-none with-border ">
                            <option value="">Select Category</option>
                            <option v-for="c in postCategories">{{ c }}</option>
                        </select>
                    </div>

                    <div class="line">
                        <input required v-model="email" class="line__input" id="email" name="email" type="text" onkeyup="this.setAttribute('value', this.value);" value="" autocomplete="off">
                        <span for="email" class="line__placeholder"> Email </span>
                    </div>
                    <div>
                        <div class="mb-2">
                            <label>Dont Show Email Address In Public Page</label>
                        </div>
                        <!-- <label for=""> Category </label><br/><br/> -->
                        <select required v-model="hide_email" id="" name="" class="shadow-none with-border ">
                            <option value="false">False</option>
                            <option value="true">True</option>
                        </select>
                    </div>

                    <div class="line">
                        <input required v-model="website" class="line__input" id="website" name="website" type="url" onkeyup="this.setAttribute('value', this.value);" value="" autocomplete="off">
                        <span for="website" class="line__placeholder"> Website </span>
                    </div>

                    <div class="">
                        <label for="" class="mb-3"> Description </label>
                        <textarea v-model="description" class="bg-smoke h-32 p-2" id="" name="" type="text" onkeyup="this.setAttribute('value', this.value);" value="" autocomplete="off" style="background-color: #f3f4f6 !important;"></textarea>
                    </div>

                    <div class="row">

                        <div class="col-sm-6">
                            <div class="mx-2 mb-3">
                                <label for="" class="mb-2"> Facebook </label>
                                <input required v-model="facebook" class="form-control" id="facebook" name="facebook" type="url" onkeyup="this.setAttribute('value', this.value);" value="" autocomplete="off" placeholder="https://facebook.com/********">
                            </div>
                            <div class="mx-2 mb-3">
                                <label for="" class="mb-2"> Instagram </label>
                                <input required v-model="instagram" class="form-control" id="instagram" name="instagram" type="url" onkeyup="this.setAttribute('value', this.value);" value="" autocomplete="off" placeholder="https://instagram.com/********">
                            </div>
                        </div>

                        <div class="col-sm-6">
                            <div class="mx-2 mb-3">
                                <label for="" class="mb-2"> Twitter </label>
                                <input required v-model="twitter" class="form-control" id="twitter" name="twitter" type="url" onkeyup="this.setAttribute('value', this.value);" value="" autocomplete="off" placeholder="https://twitter.com/********">
                            </div>
                            <div class="mx-2 mb-3">
                                <label for="" class="mb-2"> Youtube </label>
                                <input required v-model="youtube" class="form-control" id="youtube" name="youtube" type="url" onkeyup="this.setAttribute('value', this.value);" value="" autocomplete="off" placeholder="https://youtube.com/********">
                            </div>
                        </div>
                    </div>

                </div>

                <!-- form footer -->
                <div class="border-t flex justify-between lg:space-x-10-old p-7 bg-gray-50 rounded-b-md">
                    <p class="text-sm leading-6 d-none"> You can add images, contact info and other details after you create the Page. </p>
                    <button type="button" class="button lg:w-1/2" @click="createPage">
                        Create Now
                    </button>
                </div>

            </div>

        </div>
    </div>

    <progress-loader v-if="requestProgress == 'true'"></progress-loader>
    <dashboard-widgets page="page"></dashboard-widgets>

</div>
</template>

<script>
import {
    VueEditor
} from "vue2-editor";
import {
    ApiService
} from '@/services/api.service'
import Header from '@/include/Dashboard/Header.vue'
import Sidebar from '@/include/Dashboard/Sidebar.vue'
import DashboardWidgets from '@/components/DashboardWidgets'
import OpenChatBox from '@/include/Modals/OpenChatBox.vue'
import Feeds from '@/include/Dashboard/Feeds.vue'
import ProfileIcon from '@/include/Dashboard/Feed/ProfileIcon'
import ProgressLoader from '@/components/ProgressLoader'
import SingleFileUpload from '@/components/SingleFileUpload'
import axios from 'axios'

export default {
    name: 'CreatePage',
    components: {
        Header,
        Sidebar,
        DashboardWidgets,
        OpenChatBox,
        Feeds,
        ProgressLoader,
        SingleFileUpload,
        VueEditor
    },

    data() {
        return {
            image: '',
            banner: '',
            category: '',
            name: '',
            description: '',
            website: '',
            email: '',
            facebook: '',
            twitter: '',
            instagram: '',
            youtube: '',
            hide_email: 'false',
            postCategories: '',
            requestProgress: 'false'
        }
    },
    mounted: function () {
        this.getExtraInfo()
    },
    methods: {
        async getExtraInfo() {
            this.requestProgress = 'true'
            let apiResponse = await ApiService.ExtraInfo();
            // console.log('getExtraInfo', apiResponse)
            if (apiResponse['status'] == 'success') {
                this.postCategories = apiResponse['data']['post_categories']
            }
            this.requestProgress = 'false'
        },
        async createPage() {
            this.requestProgress = 'true'
            let apiResponse = await ApiService.CreatePage(this.image, this.name, this.description, this.category, this.website, this.email, this.hide_email, this.banner, this.facebook, this.twitter, this.instagram, this.youtube);
                this.$store.commit("toast/Notify", {
                    type: apiResponse["status"],
                    message: apiResponse["message"],
                });
            if (apiResponse["status"] != "success") {
            } else {
                this.image = ''
                this.category = ''
                this.name = ''
                this.description = ''
                setTimeout(() => {
                    location.href = '/dashboard/pages'
                }, 1200);
            }
            this.requestProgress = 'false'
        },
        onImageUploaded(response) {
            // Handle files like:
            // console.log('::::',response)
            if (response['status'] == 'success') {
                this.image = response['data']['file_name']
            }
            this.$store.commit("toast/Notify", {
                type: response["status"],
                message: response["message"],
            });
        },
        handleImageAdded: function (file, Editor, cursorLocation, resetUploader) {
            // An example of using FormData
            // NOTE: Your key could be different such as:
            // formData.append('file', file)

            var formData = new FormData();
            formData.append("file", file);

            axios({
                    url: this.$store.getters['host/apiUrl'].replace('/api/v2', '/files/upload'),
                    method: "POST",
                    data: formData
                })
                .then(result => {
                    // console.log('result', result)
                    const url = result.data.data['file_name']; // Get url from response
                    Editor.insertEmbed(cursorLocation, "image", url);
                    resetUploader();
                })
                .catch(err => {
                    console.log(err);
                });
        },
        onBannerUploaded(response) {
            // Handle files like:
            // console.log('::::',response)
            if (response['status'] == 'success') {
                this.banner = response['data']['file_name']
            }
            this.$store.commit("toast/Notify", {
                type: response["status"],
                message: response["message"],
            });
        },
    },
}
</script>

<style lang="scss" scoped>
.some-modal-content {
    min-width: 400px;
    padding: 25px;

    .buttons button {
        padding: 10px;
        margin: 10px;
    }
}

.postImgheight {
    height: 100px
}

.editor {
    height: 350px;
}
</style>
