<template>
<div>
    <h3 v-if="showText == 'true'">Google banner ad</h3>
    <div v-else>
        <!-- <div v-if="googleAd && googleAd.google_ad_code" v-html="googleAd.google_ad_code.replace('ins', 'InArticleAdsense')">
        </div> -->
        <!-- <InFeedAdsense
            data-ad-layout-key="-gj-10-1s-7v+vn"
            data-ad-client="ca-pub-1298783804434025"
            data-ad-slot="4046809806">
        </InFeedAdsense> -->
        <InArticleAdsense v-if="adClient && adSlot"
            :data-ad-client="adClient"
            :data-ad-slot="adSlot">
        </InArticleAdsense>
        <!-- 
        <ins class="adsbygoogle" style="display:block" data-ad-format="fluid" data-ad-layout-key="-gj-10-1s-7v+vn" data-ad-client="ca-pub-1298783804434025" data-ad-slot="6398107378"></ins> -->
        <!-- <ins class="adsbygoogle" style="display:block" data-ad-client="ca-pub-1298783804434025" data-ad-slot="4046809806" data-ad-format="auto" data-full-width-responsive="true"></ins> -->
    </div>
</div>
</template>

<script>
import {
    ApiService
} from '@/services/api.service'
export default {
    name: "GoogleBannerAd",
    props: {
        ad: Object,
        preview: String
    },
    data() {
        return {
            showText: 'true',
            googleAd: '',
            adClient: '',
            adSlot: ''
        };
    },
    components: {},
    mounted() {
        if (location.href.search('localhost') > -1 || location.href.search('127.0.0.1') > -1) {
            this.showText = 'true'
        } else {
            this.showText = 'false'
        }
        if(!this.ad){
            this.getPostAds()
        }else{
            this.googleAd = this.ad

            if(googleAd.google_ad_code){
                this.loadSlotAndClient(googleAd.google_ad_code)
            }
        }
        this.loadAd()
    },
    methods: {
        async viewGoogleAd() {
            let apiResponse = await ApiService.PostAdAction(this.ad.id, 'view')
        },
        async clickGoogleAd() {
            let apiResponse = await ApiService.PostAdAction(this.ad.id, 'click')
        },
        async getPostAds(){
            let apiResponse = await ApiService.GetPostAds('google', 1)
            // console.log('=============apiResponse', apiResponse, apiResponse['data'], apiResponse['data'][0])
            if(apiResponse['status'] != 'success'){
            }else{
                if(apiResponse['data'].length > 0){
                    let googleAd = apiResponse['data'][0]
                    if(googleAd.google_ad_code){
                        this.loadSlotAndClient(googleAd.google_ad_code)
                    }
                    // this.googleAd = apiResponse['data'][0]
                    // console.log('=============google ad', googleAdArray, googleAd)
                }
            }
        },
        loadSlotAndClient(googleAdCode){
            if(googleAdCode){
                let googleAdCodeArray = googleAdCode.split(' ')
                // console.log('=============google ad', googleAdCodeArray)
                for (let i = 0; i < googleAdCodeArray.length; i++) {
                    const thisSeg = googleAdCodeArray[i];
                    if(thisSeg == ''){
                        continue
                    }
                    if(thisSeg.search('data-ad-client') > -1){
                        this.adClient = thisSeg.replace('data-ad-client="','').replace('"','')
                    }
                    if(thisSeg.search('data-ad-slot') > -1){
                        this.adSlot = thisSeg.replace('data-ad-slot="','').replace('"','')
                    }
                }
                // console.log('---------------client and slot', this.adClient, this.adSlot)
            }
        },
        loadAd() {
            // setTimeout(function(){
            //     (adsbygoogle = window.adsbygoogle || []).push({});
            // },1000)
        }
    }
};
</script>

<style lang="scss" scoped></style>
