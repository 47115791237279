<template>
<div id="create-post-modal" class="create-post" uk-modal="">
    <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical shadow-2xl uk-animation-slide-bottom-small">

        <div class="py-4 border-b flex  justify-between px-6">
            <h3 class="text-lg font-semibold"> Create Video </h3>
            <div>
                <!-- <div class="font-semibold px-2.5 py-1 bg-gray-100  rounded-md text text-gray-500 text-sm mr-8">
                    Saved as draft
                </div> -->
                <button class="uk-modal-close-default hover:bg-gray-100 rounded-full p-2.5 block uk-icon uk-close mt-0.5" type="button" uk-close="">
                    <i class="icon-feather-close"></i>
                    <svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg" data-svg="close-icon">
                        <line fill="none" stroke="#000" stroke-width="1.1" x1="1" y1="1" x2="13" y2="13"></line>
                        <line fill="none" stroke="#000" stroke-width="1.1" x1="13" y1="1" x2="1" y2="13"></line>
                    </svg></button>
            </div>
        </div>

        <div class="create-post-modal-body space-x-6 p-5 overflow-x-hidden mr-1" data-simplebar="init">
            <div class="simplebar-wrapper" style="margin: -20px;">
                <div class="simplebar-height-auto-observer-wrapper">
                    <div class="simplebar-height-auto-observer"></div>
                </div>
                <div class="simplebar-mask">
                    <div class="simplebar-offset" style="right: -20px; bottom: 0px;">
                        <div class="simplebar-content" style="padding: 20px calc(40px) 20px 20px; height: 100%; overflow: hidden scroll;">

                            <form v-on:submit.prevent="saveVideo" class="space-y-4 g:w-8/12 videoUpload123">
                                <single-file-upload type="video" @uploadSuccess="onVideoUploaded">
                                    <div class="relative uk-transition-toggle overflow-hidden">
                                        <div class="bg-gray-100 border-2 border-dashed flex flex-col h-40 items-center justify-center relative w-full rounded-lg dark:bg-gray-800 dark:border-gray-600">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-12">
                                                <path d="M5.5 13a3.5 3.5 0 01-.369-6.98 4 4 0 117.753-1.977A4.5 4.5 0 1113.5 13H11V9.413l1.293 1.293a1 1 0 001.414-1.414l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L9 9.414V13H5.5z"></path>
                                                <path d="M9 13h2v5a1 1 0 11-2 0v-5z"></path>
                                            </svg>
                                        </div>
                                        <div class="w-full uk-form-custom" uk-form-custom="">
                                            <a href="#" class="bg-gray-200 flex font-medium h-9 items-center justify-center px-5 rounded-b-xl text-blue-600 text-white uk-position-bottom uk-transition-bottom-small">
                                                Choose file</a>
                                        </div>
                                    </div>
                                </single-file-upload>

                                <div class="font-semibold text-lg"> Details </div>
                                <div class="border focus-within:bg-white focus-within:border-blue-300 p-3 pb-0 rounded-md">
                                    <label for="" class="mb-0 ml-2 text-gray-600 text-sm"> Title (required)</label>
                                    <input v-model="title" type="text" placeholder="Name" class="-mt-2 -mx-3 bg-transparent focus:bg-white focus:ring-gray-300 h-8 outline-none px-0 text-base w-full">
                                </div>
                                <div class="border focus-within:bg-white focus-within:border-blue-300 p-3 rounded-md">
                                    <label for="" class="mb-0 ml-2 text-gray-600 text-sm"> Description (required)</label>
                                    <textarea v-model="description" type="text" rows="5" placeholder="What's Your Mind ?" class="bg-transparent focus:bg-white focus:ring-gray-300 focus:shadow-none outline-none p-2 resize-none shadow-none text-base w-full"></textarea>
                                </div>
                                <div class="keywords-container">
                                    <div class="keyword-input-container shadow-none">
                                        <input type="text" class="keyword-input with-border" placeholder="Add Keywords">
                                        <button type="button" class="keyword-input-button ripple-effect">
                                            <i class="icon-feather-plus"></i></button>
                                    </div>
                                    <div class="keywords-list">
                                        <span class="keyword">
                                            <span class="keyword-remove"></span> <span class="keyword-text">Video</span>
                                        </span>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>

                                <div class="px-2 space-y-2">
                                    <div class="font-semibold text-base"> Reach </div>
                                    <div> Manage your videos or your contents who want to see your videos and share with theme across
                                        the website. </div>
                                    <select v-model="reach" class="selectpicker">
                                        <option data-icon="uil-bullseye"> Private </option>
                                        <option data-icon="uil-chat-bubble-user">My Following</option>
                                        <option data-icon="uil-layer-group-slash">Unlisted</option>
                                        <option data-icon="uil-globe" selected="">Public</option>
                                    </select>
                                </div>

                                <div class="px-2 space-y-2">
                                    <div class="font-semibold text-base"> Age Restrict </div>
                                    <div> Do you want to restrict your video to an adult audience? </div>
                                    <div> </div>
                                    <div class="flex flex-col">
                                        <div class="radio">
                                            <input v-model="age_restrict" id="radio-1" name="radio" type="radio" checked="" value="yes">
                                            <label for="radio-1"><span class="radio-label"></span> Yes, it's made for kids </label>
                                        </div>
                                        <div class="radio">
                                            <input v-model="age_restrict" id="radio-2" name="radio" type="radio" value="no">
                                            <label for="radio-2"><span class="radio-label"></span>No, it's not made for kids </label>
                                        </div>
                                    </div>
                                </div>

                            </form>

                        </div>
                    </div>
                </div>
                <div class="simplebar-placeholder" style="width: 656px; height: 1014px;"></div>
            </div>
            <div class="simplebar-track simplebar-horizontal" style="visibility: hidden;">
                <div class="simplebar-scrollbar" style="transform: translate3d(0px, 0px, 0px); visibility: hidden;"></div>
            </div>
            <div class="simplebar-track simplebar-vertical" style="visibility: visible;">
                <div class="simplebar-scrollbar" style="transform: translate3d(0px, 249px, 0px); visibility: visible; height: 251px;"></div>
            </div>
        </div>

        <div class="flex items-center w-full justify-between p-3 border-t">

            <div class="flex items-center space-x-2">

                <div> </div>

            </div>

            <div class="flex space-x-2">
                <button @click="saveVideo" class="bg-blue-600 flex h-9 items-center justify-center rounded-md text-white px-5 font-medium">
                    Share
                </button>
            </div>
        </div>

    </div>
</div>
</template>

<script>
import {
    ApiService
} from "@/services/api.service";
import SingleFileUpload from '@/components/SingleFileUpload'
export default {
    name: 'CreateVideoPost',
    props: {},
    data() {
        return {
            profilePhoto: '',
            userFullName: '',
            currentUser: '',
            reach: 'Public',
            description: '',
            title: '',
            age_restrict: 'yes',
            video: '',
        }
    },
    components: {
        SingleFileUpload
    },
    mounted() {
        this.currentUser = this.$store.getters["user/currentUser"];
        this.profilePhoto = this.currentUser['profile_photo']
        this.userFullName = this.currentUser['name']
    },
    methods: {
        saveVideo() {
            let keywords = []
            let k = $(".keyword-text").each(function(index, element) {
                keywords[keywords.length] = element.outerText
                return this.text
            });
            // console.log(k, keywords)
            this.$emit('saveVideo', this.title, this.description, this.reach, this.video, this.age_restrict,keywords)
        },
        onVideoUploaded(response) {
            if (response['status'] == 'success') {
                this.video = response['data']['file_name']
            }
            this.$store.commit("toast/Notify", {
                type: response["status"],
                message: response["message"],
            });
        },
    }
}
</script>

<style>
.videoUpload123 .upload-input {
    height: 176px !important;
    width: 616px !important;
    z-index: 99999;
}
</style>
