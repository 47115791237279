<template>
  <div id="wrapper" class="flex flex-col justify-between h-screen">
      <auth-styles pageNameMain="Login"></auth-styles>

      <!-- Content-->
      <div>
          <div class="lg:p-12 max-w-xl lg:my-0 my-12 mx-auto p-6 space-y-">
              
              <form class="lg:p-10 p-6 space-y-3 relative bg-white shadow-xl rounded-md text-center">
                  <h1 class="lg:text-2xl text-xl font-semibold mb-6"> Page not found </h1>
                  <div>
                    <p>
                        <router-link to="/login">Login</router-link>
                    </p>
                    <p>
                        <router-link to="/register">Register</router-link>
                    </p>
                    <p>
                        <router-link to="/">Home</router-link>
                    </p>
                  </div>
              </form>

          </div>
      </div>

      <auth-scripts></auth-scripts>

  </div>
</template>

<script>
  import AuthStyles from '../include/Auth/AuthStyles'
  import AuthScripts from '../include/Auth/AuthScripts'
  export default {
      name: 'NotFound',
      components: {
        AuthStyles,
        AuthScripts
      },
      data(){
        return {}
      },
      mounted () {
        console.log('Route',this.$route)
      }
  }
</script>
