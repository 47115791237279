<template>
<div id="wrapper">
    <auth-styles v-if="pageNameMain" :pageNameMain="pageNameMain" :pageDescriptionMain="pageDescriptionMain" :pageImageMain="pageImageMain"></auth-styles>

    <Header />

    <sidebar></sidebar>

    <!-- Main Contents -->
    <div class="main_content">
        <div class="mcontainer" v-if="order">

            <div class="lg:flex lg:space-x-10">
                <div class="lg:w-3/4 lg:px-20 space-y-7">

                    <div class="card lg:mx-0 uk-animation-slide-bottom-small">

                        <!-- post header-->
                        <div class="flex justify-between items-center lg:p-4 p-2.5">
                            <div class="flex flex-1 items-center space-x-4">
                                <a v-if="!order.user || !order.user.profile_photo" target="_blank" :href="defaultImageUrl()">
                                    <img :src="defaultImageUrl()" class="bg-gray-200 border border-white rounded-full w-10 h-10">
                                </a>
                                <a v-else target="_blank" :href="order.user.profile_photo">
                                    <img :src="order.user.profile_photo" class="bg-gray-200 border border-white rounded-full w-10 h-10">
                                </a>
                                <div class="flex-1 font-semibold capitalize">
                                    <a target="_blank" v-if="order.user" :href="'/dashboard/timeline/'+order.user.id" class="text-black"> 
                                        {{ order.user.name }} 
                                        <verified-badge v-if="order.user.verified == 'true'" size="small"></verified-badge>
                                    </a>
                                    <div class="text-gray-700 flex items-center space-x-2"> {{ timeAgo(order.created_at) }} ago - {{ formatDateTimeNice(order.created_at) }}
                                        <ion-icon name="people" role="img" class="md hydrated" aria-label="people"></ion-icon>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div uk-lightbox="">
                            <a v-if="order.image" :href="order.image">
                                <img :src="order.image" alt="" class="max-h-96 w-full object-cover">
                            </a>
                            <a v-else :href="defaultPostUrl()">
                                <img :src="defaultPostUrl()" alt="" class="max-h-96 w-full object-cover">
                            </a>
                        </div>

                        <div class="p-4 space-y-3 relative">
                            <div class="text-2xl font-semibold pt-2"> {{ order.name }}</div>
                            <p> {{ order.description }}</p>

                            <div class="top-3 absolute bg-gray-100 font-semibold px-3 py-1 right-3 rounded-full text text-sm">
                                {{ currencySymbol(order.currency) }}{{ formatAmount(order.amount) }}
                            </div>

                            <div class="flex space-x-3 items-center text-sm md:pt-3">
                                <div> 
                                    <span v-if="order.category">{{ order.category }}</span>: 
                                    <span v-if="order.product.sub_category">{{ order.product.sub_category.name }}</span>
                                </div>
                                <div class="md:block hidden">·</div>
                                <div class="font-semibold text-yellow-500" v-if="order.product.in_stock > 0"> Instock</div>
                                <div class="flex"> Brand: <span v-if="order.product.brand" class="font-semibold text-yellow-500 mr-2">{{ order.product.brand.name }} </span> </div>
                            </div>

                            <hr>

                            <div class="flex space-x-3 items-center text-sm md:pt-3">
                                <div> 
                                    <span v-if="order.shop">Shop: {{ order.shop.name }}</span>:
                                </div>
                                <div class="md:block hidden">·</div>
                                <div class="font-semibold text-yellow-500" v-if="order.shop">  {{ order.shop.country }}</div>
                                <div class="flex"> <span v-if="order.shop" class="font-semibold text-yellow-500 mr-2">{{ order.shop.state }} </span> </div>
                            </div>

                            <hr>

                            <div v-if="order.size" class="flex space-x-3 items-center text-sm md:pt-3">
                                <div> 
                                    <span>Size: {{ order.size }}</span>:
                                </div>
                            </div>

                            <div v-if="order.color" class="flex space-x-3 items-center text-sm md:pt-3">
                                <div> 
                                    <span>Color: {{ order.color }}</span>:
                                </div>
                            </div>

                            <hr v-if="order.color || order.size">

                            <div class="grid grid-cols-3 gap-4 mb-5">

                                <a v-if="order.shop" :href="'/dashboard/chats?type=shop&id='+order.shop.id+'&product_link='+thisPage" class="bg-yellow-200 flex flex-1 font-semibold h-10 items-center justify-center px-4 rounded-md">
                                    Contact seller
                                </a>

                                <a v-if="order.shop" :href="'/dashboard/shop/'+order.shop.shop_unique_name || order.shop.id" class="bg-gray-200 flex flex-1 font-semibold h-10 items-center justify-center px-4 rounded-md">
                                    View shop
                                </a>
                                <a :href="'/dashboard/product/'+order.product_id" class="mouse-pointer bg-blue-600 flex flex-1 font-semibold h-10 items-center justify-center px-4 rounded-md text-white">
                                    View Product
                                </a>

                            </div>

                            <div class="p-4 space-y-3">
                                <div>
                                    <p v-if="order.date_accepted" class="mb-2">Product order accepted on {{ unixToUtc(order.date_accepted) }}</p>
                                    <p v-if="order.date_rejected" class="mb-2">Product order rejected on {{ unixToUtc(order.date_rejected) }}</p>
                                    <p v-if="order.date_shipped" class="mb-2">Product order shipped on {{ unixToUtc(order.date_shipped) }}</p>
                                    <p v-if="order.date_delivered" class="mb-2">Product order delivered on {{ unixToUtc(order.date_delivered) }}</p>
                                    <p v-if="order.date_received" class="mb-2">Product order received on {{ unixToUtc(order.date_received) }}</p>
                                    <p v-if="order.date_confirmed" class="mb-2">Product order confirmed on {{ unixToUtc(order.date_confirmed) }}</p>
                                    <p v-if="order.date_disputed" class="mb-2">Product order disputed on {{ unixToUtc(order.date_disputed) }}</p>
                                </div>
                                <div class="flex-old flex-1 items-center lg:justify-center justify-center space-x-2">
                                    <button @click="receivedOrder(order.id)" class="mb-3 h-8 lg:px-3 px-2 rounded-md bg-blue-600 text-white space-x-1" v-if="order.date_delivered && !order.date_received">Received Order</button>
                                    <template v-if="order.date_accepted || order.date_rejected">
                                        <button @click="disputeOrder(order.id)" class="mb-3 h-8 lg:px-3 px-2 rounded-md bg-red-600 text-white space-x-1" v-if="!order.date_disputed">Dispute Order</button>
                                    </template>
                                    <p v-else>Order has to be accepted or rejcted by vendor before {{ formatDateTimeToFuture(order.created_at) }}</p>
                                </div>
                                <br />
                            </div>

                        </div>

                    </div>

                </div>
                <div class="lg:w-72 w-full bg-white">

                    <h3 class="text-xl font-semibold ml-2"> Dispute Messages </h3>
                    <div class="simplebar-wrapper" style="margin: 0px;" v-if="order.date_disputed">
                        <div class="simplebar-height-auto-observer-wrapper">
                            <div class="simplebar-height-auto-observer"></div>
                        </div>
                        <div class="simplebar-mask p-relative">
                            <div class="simplebar-offset p-relative" style="right: -20px; bottom: 0px;">
                                <div class="simplebar-content" style="padding: 0px calc(0px) 0px 0px; height: 100%; overflow: hidden scroll;">

                                    <!-- Message Content Inner -->
                                    <div class="message-content-inner">

                                        <template v-for="m in messages">
                                            <div class="message-bubble me" v-if="m.sender_name == 'customer'">
                                                <div class="message-bubble-inner">
                                                    <p class="ml-3">
                                                        <span class="text-capitalize">{{ m.sender_name }}</span> - {{ formatDateTimeNice(m.created_at) }}
                                                    </p>
                                                    <div class="message-text">
                                                        <p>{{ m.message }}</p>
                                                    </div>
                                                </div>
                                                <div class="clearfix"></div>
                                            </div>

                                            <div class="message-bubble" v-else>
                                                <div class="message-bubble-inner">
                                                    <p class="ml-3">
                                                        <span class="text-capitalize">{{ m.sender_name }}</span> - {{ formatDateTimeNice(m.created_at) }}
                                                    </p>
                                                    <div class="message-text">
                                                        <p>{{ m.message }}</p>
                                                    </div>
                                                </div>
                                                <div class="clearfix"></div>
                                            </div>
                                        </template>
                                    </div>
                                    <!-- Message Content Inner / End -->

                                    <!-- Reply Area -->
                                    <div class="message-reply px-2 bg-gray-50">
                                        <textarea v-model="comment" cols="1" rows="1" placeholder="Your Message"></textarea>
                                        <button @click="addComment" class="button ripple-effect">Send</button>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="simplebar-placeholder d-none" style="width: 977px; height: 753px;"></div>
                    </div>
                    <p v-else class="p-3">
                        Messages will appear here when this order has been disputed
                    </p>

                </div>
            </div>

        </div>
        <div id="progressLoader">
            <progress-loader></progress-loader>
        </div>
    </div>

    <share-post-modal v-if="shareLink" :link="shareLink" :description="shareDescription"></share-post-modal>
    <dashboard-widgets page="product-order-customer"></dashboard-widgets>

</div>
</template>

<script>
import {
    ApiService
} from '@/services/api.service'
import Header from '@/include/Dashboard/Header.vue'
import Sidebar from '@/include/Dashboard/Sidebar.vue'
import DashboardWidgets from '@/components/DashboardWidgets'
import OpenChatBox from '@/include/Modals/OpenChatBox.vue'
import Feeds from '@/include/Dashboard/Feeds.vue'
import ProfileIcon from '@/include/Dashboard/Feed/ProfileIcon'
import ProgressLoader from '@/components/ProgressLoader'
import AuthStyles from '@/include/Auth/AuthStyles'
import ConfirmModal from '@/components/ConfirmModal'
import ShareIcons from '@/components/ShareIcons'
import SharePostModal from '@/include/Modals/SharePostModal'
import VerifiedBadge from '@/components/VerifiedBadge'

export default {
    name: 'Order',
    components: {
        Header,
        Sidebar,
        DashboardWidgets,
        OpenChatBox,
        Feeds,
        ProgressLoader,
        AuthStyles,
        ConfirmModal,
        ShareIcons,
        SharePostModal,
        ProfileIcon,
        VerifiedBadge
    },

    data() {
        return {
            order: {},
            feed: {},
            comment: '',
            reply: '',
            replyReply: '',
            pagination: {
                limit: 20,
                offset: 0,
                total: 60
            },
            pageNameMain: '',
            pageDescriptionMain: '',
            pageImageMain: '',
            currentUser: {},
            thisPage: '',
            unviewedComments: '',
            preview: 'true',
            shareLink: '',
            shareLink: '',
            comment: '',
            messages: '',
            noOfPreviewComments: 2,
        }
    },
    mounted: function () {
        this.currentUser = this.$store.getters['user/currentUser'] || {}
        let id = this.$route.params['id']
        this.getProductOrder(id)
        this.thisPage = location.href
    },
    methods: {
        async addComment() {
            let apiResponse = await ApiService.SendCustomerMessageToProductOrder(this.order.id, this.comment)
            this.$store.commit("toast/Notify", {
                type: apiResponse["status"],
                message: apiResponse["message"],
            });
            if (apiResponse['status'] != 'success') {} else {
                location.href = ''
            }
        },
        async getProductOrder(id) {
            this.toggleProgress('show')
            let apiResponse = await ApiService.GetProductOrder(id);
            if (apiResponse["status"] != "success") {} else {
                this.order = apiResponse['data']['order']
                this.feed = apiResponse['data']['feed']
                if(this.order){
                    this.messages = this.order['messages']
                }
                this.toggleProgress('hide')
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.message-bubble .message-text {
    margin-left: 10px;
    margin-right: 10px;
}
.message-reply {
    padding: 10px;
}
.message-reply textarea {
    padding-left: 5px;
}
.message-bubble.me .message-text {
    margin-right: 10px;
}
.message-bubble .message-text {
    padding: 12px 10px;
}
</style>
