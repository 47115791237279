<template>
<div class="md:flex md:space-x-6 lg:mx-16 switch_sections">
    <div class="space-y-5 flex-shrink-0 md:w-7/12">

        <!-- create post  -->
        <create-post-component v-if="showPostBox == 'true'"></create-post-component>

        <template v-for="thisFeed in user_pinned_feeds">
            <div v-view.once="loadMoreFeeds">
                <user-feed :feed="thisFeed" preview="true" :is_admin="is_admin"></user-feed>
            </div>
        </template>
        <template v-for="thisFeed in feeds">
            <div v-view.once="loadMoreFeeds">
                <user-feed :feed="thisFeed" preview="true" :is_admin="is_admin"></user-feed>
            </div>
        </template>

        <div v-if="feeds.length == 0 && pendingRequest == false" class="bg-white p-5">
            <h5>No Feeds</h5>
        </div>

        <div v-if="pendingRequest == true" class="text-center my-3">
            <i class="fa fa-spin fa-spinner fa-2x"></i>
        </div>

        <div class="flex justify-center mt-6" style="visibility: hidden;">
            <a v-if="feeds.length > 0" @click="loadMoreFeeds" href="Javascript:;" class="bg-white font-semibold my-3 px-6 py-2 rounded-full shadow-md dark:bg-gray-800 dark:text-white">
                Load more ..</a>
        </div>
        <edit-feed-post></edit-feed-post>
        <edit-feed-comment></edit-feed-comment>
        <likers-modal></likers-modal>

    </div>

    <!-- Sidebar -->
    <div class="w-full space-y-6">

        <div class="widget card sm:p-5 p-3">
            <h4 class="text-lg font-semibold"> About </h4>
            <ul class="text-gray-600 space-y-3 mt-3">
                <li class="flex items-center space-x-2" v-if="currentUser['username']">
                    <i name="username-sharp" class="rounded-full text-xl p-1 mr-2">
                        <img :src="vitiLogoIcon()" style="height: 20px;width: 20px; object-fit: cover;" />
                    </i>
                    <strong class="font-bold">@{{ currentUser['username'] }}</strong>
                </li>
                <li class="flex items-center space-x-2" v-if="currentUser['state'] && currentUser['country']">
                    <i name="home-sharp" class="rounded-full bg-gray-200 text-xl p-1 mr-3 fa fa-home"></i>
                    Lives In <strong class="font-bold"> {{ currentUser['state'] }} , {{ currentUser['country'] }} </strong>
                </li>
                <li v-if="currentUser.id == thisUser.id" class="flex-old items-center space-x-2">
                    <div>
                        <i name="globe" class="rounded-full bg-gray-200 text-xl p-1 mr-3 fa fa-globe"></i>
                        Email <strong class="font-bold"> {{ currentUser['email'] }} </strong>
                    </div>
                    <div class="ml-5">
                        <small>
                            (only visible to me)
                        </small>
                    </div>
                </li>
                <template v-if="currentUser.id  == thisUser.id">
                    <li class="flex items-center space-x-2" v-if="currentUser['gender']">
                        <i name="heart-sharp" class="rounded-full bg-gray-200 text-xl p-1 mr-3 fa fa-heart"></i>
                        Gender <strong class="font-bold"> {{ currentUser['gender'] }} </strong>
                    </li>
                </template>
                <li class="flex items-center space-x-2">
                    <i name="logo-rss" class="rounded-full bg-gray-200 text-xl p-1 mr-3 fa fa-rss"></i>
                    Following <strong class="font-bold"> {{ followings_count || 0 }} <span v-if="followings_count > 1">People</span><span v-if="followings_count == 1">Person</span> </strong>
                </li>
                <li class="flex items-center space-x-2">
                    <i name="logo-rss" class="rounded-full bg-gray-200 text-xl p-1 mr-3 fa fa-rss"></i>
                    Followed By <strong class="font-bold"> {{ followers_count || 0 }} <span v-if="followers_count > 1">People</span><span v-if="followers_count == 1">Person</span> </strong>
                </li>
                <li class="flex-old items-center-old space-x-2" v-if="currentUser['bio']">
                    <i name="logo-rss" class="rounded-full bg-gray-200 text-xl p-1 mr-2 fa fa-rss"></i>
                    <strong class="font-bold">Bio: </strong> 
                    <p class="ml-0" style="margin:0" v-if="currentUser['bio']" v-html="customTextRender(currentUser['bio'])"></p>
                    <p class="ml-0" style="margin:0" v-else>--</p>
                </li>
            </ul>
            <!-- <h5 class="mt-5">Followers</h5>
            <div class="gap-3 grid grid-cols-3 mt-4">
                <template v-for="fl in followers_list.slice(0,1)">
                    <img v-if="fl.user && fl.user.profile_photo" :src="fl.user.profile_photo" style="max-height: 250px;" alt="" class="object-cover rounded-lg col-span-full">
                    <img v-else :src="defaultImageUrl()" alt="" style="max-height: 250px;" class="object-cover rounded-lg col-span-full">
                </template>
                <template v-for="fl in followers_list.slice(1,4)">
                    <img v-if="fl.user && fl.user.profile_photo" :src="fl.user.profile_photo" style="height: 150px" alt="" class="rounded-lg">
                    <img v-else :src="defaultImageUrl()" style="height: 150px" alt="" class="rounded-lg">
                </template>
                <p v-if="followers_list.length == 0">No followers</p>
            </div> -->
            <a v-if="currentUser.id == thisUser.id" href="javascript:;" uk-toggle="target: #update-user-data" class="button gray mt-3 w-full"> Edit </a>
            <button @click="deleteAccount" v-if="currentUser.id == thisUser.id" class="btn btn-danger mt-3 w-full"> Close Account </button>
        </div>

        <div class="widget card sm:p-5 p-3 border-t">
            <div class="flex items-center justify-between mb-4">
                <div>
                    <h4 class="text-lg font-semibold"> Friends </h4>
                    <p class="text-sm"> {{ followers_count }} Friend(s)</p>
                </div>
                <a v-if="followers_list.length > 0" href="javascript:;" uk-switcher-item="1" class="text-blue-600 ">See all</a>
            </div>
            <div class="grid grid-cols-3 gap-3 text-gray-600 font-semibold">
                <a :href="'/dashboard/timeline/'+fl.follower.id" v-for="fl in followers_list">
                    <div class="avatar relative rounded-md overflow-hidden w-full h-24 mb-2">
                        <img v-if="fl.follower.profile_photo" :src="fl.follower.profile_photo" alt="" class="w-full h-full object-cover absolute">
                        <img v-else :src="defaultImageUrl()" alt="" class="w-full h-full object-cover absolute">
                    </div>
                    <div class="text-sm truncate"> 
                        {{ fl.follower.name }} 
                        <verified-badge v-if="fl.follower.verified == 'true'" size="small" mt="zero"></verified-badge>
                    </div>
                </a>
            </div>
            <div v-if="followers_list.length == 0" class="bg-white p-2">
                <h4>No friend yet</h4>
            </div>
            <a v-if="followers_list.length > 0" href="javascript:;" uk-switcher-item="1" class="button gray mt-3 w-full"> See all </a>
        </div>

        <div class="widget card sm:p-5 p-3 border-t mb-4">
            <div class="flex items-center justify-between mb-2">
                <div>
                    <h4 class="text-lg font-semibold"> Groups({{ total_groups }}) </h4>
                </div>
                <a href="javascript:;" uk-switcher-item="4" class="text-blue-600 "> See all</a>
            </div>
            <div>

                <div v-for="group in groups.slice(0,5)" class="flex items-center space-x-4 rounded-md -mx-2 p-2 hover:bg-gray-50">
                    <a :href="'/dashboard/group/'+group.id" class="w-12 h-12 flex-shrink-0 overflow-hidden rounded-full relative">
                        <img v-if="!group.image" :src="defaultImageUrl" class="absolute w-full h-full inset-0 " alt="">
                        <img v-else :src="group.image" class="absolute w-full h-full inset-0 " alt="">
                    </a>
                    <div class="flex-1">
                        <a :href="'/dashboard/group/'+group.id" class="text-base font-semibold capitalize"> {{ group.name }} </a>
                        <div class="text-sm text-gray-500 mt-0.5"> {{ group.followers_count }} Following</div>
                    </div>
                    <a v-if="!group.user_follow" :href="'/dashboard/group/'+group.id" class="flex items-center justify-center h-8 px-3 rounded-md text-sm border font-semibold bg-blue-500 text-white">
                        Join
                    </a>
                    <a v-else href="javascript:;" class="flex items-center justify-center h-8 px-3 rounded-md text-sm border font-semibold bg-gray-500 text-white">
                        Following
                    </a>
                </div>
            </div>
        </div>

    </div>
    <share-post-modal></share-post-modal>
</div>
</template>

<script>
import {
    ApiService
} from '@/services/api.service'
import UserFeed from '@/include/Dashboard/Feed/UserFeed'
import CreatePostComponent from '@/components/CreatePostComponent'
import VerifiedBadge from '@/components/VerifiedBadge'
import EditFeedPost from '@/include/Modals/EditFeedPost'
import EditFeedComment from '@/include/Modals/EditFeedComment'
import LikersModal from '@/include/Modals/LikersModal'
import SharePostModal from '@/include/Modals/SharePostModal'

export default {
    name: "TimelineScreen",
    data() {
        return {
            feeds: [],
            user_pinned_feeds: [],
            thisUser: '',
            id: '',
            groups: '',
            total_groups: '',
            page: 0,
            pendingRequest: false,
            groupPendingRequest: false,
            last_page: '',
            is_admin: '',
            showPostBox: 'false',
        };
    },
    props: {
        currentUser: Object,
        followers_count: String,
        followings_count: String,
        followers_list: Array,
        followings_list: Array,
    },
    components: {
        UserFeed,
        CreatePostComponent,
        VerifiedBadge,
        EditFeedPost,
        EditFeedComment,
        LikersModal,
        SharePostModal,
    },
    mounted () {
        this.thisUser = this.$store.getters["user/currentUser"];
        let id = this.$route.params['id']
        if(this.thisUser){
            this.getMe()
            if(id){
                if(this.thisUser.id == id){
                    this.showPostBox = 'true'
                }
            }else{
                this.showPostBox = 'true'
            }
        }
        this.id = id
        this.$root.$on("refresh-feeds",() => {
            // console.log("refresh-feeds")
            location.href = ''
        })
    },
    methods: {
        async getMyFeeds() {
            if (this.pendingRequest == true) {
                // console.log('Pending Req')
                return
            }
            if(this.last_page && this.page > this.last_page){
                console.log('Last page')
                return;
            }
            this.pendingRequest = true
            let apiResponse = await ApiService.GetMyFeeds(this.page)
            if (apiResponse['status'] != 'success') {} else {
                if(apiResponse['data'].length > 0){
                    this.feeds = this.feeds.concat(apiResponse['data'])
                    this.user_pinned_feeds = apiResponse['user_pinned_feeds']
                    this.last_page = apiResponse['pagination']['last_page']
                }
                // console.log(this.feeds)
            }
            this.pendingRequest = false
        },
        async getUserFeedsPublic(id) {
            if (this.pendingRequest == true) {
                // console.log('Pending Req')
                return
            }
            if(this.last_page && this.page > this.last_page){
                console.log('Last page',this.last_page, this.page)
                return;
            }
            this.pendingRequest = true
            let apiResponse = await ApiService.GetUserFeedsPublic(id, this.page)
            if (apiResponse['status'] != 'success') {} else {
                this.pendingRequest = false
                if(apiResponse['data'].length > 0){
                    // this.page++
                    // this.feeds = apiResponse['data']
                    this.feeds = this.feeds.concat(apiResponse['data'])
                    this.user_pinned_feeds = apiResponse['user_pinned_feeds']
                    this.last_page = apiResponse['pagination']['last_page']
                }
                // console.log(this.feeds)
            }
        },
        async getMe(){
            let apiResponse = await ApiService.GetMe()
            if(apiResponse['status'] != 'success'){
            }else{
                let user = apiResponse['data']['user']
                this.user = user
                this.is_admin = apiResponse['data']['is_admin']
                if(user['country']){
                    if(!this.id){
                        this.getMyFeeds()
                        this.getGroups(this.thisUser['id'])
                    }else{
                        this.getUserFeedsPublic(this.id)
                        this.getGroups(this.id)
                    }
                }else{
                }
            }
        },
        async deleteAccount(){
            let proceed = confirm("Are you sure you want to Delete your Account? This is permanent and can not be reversed ");
            if (proceed) {
                let apiResponse = await ApiService.DeleteAccount(this.thisUser.id)
                if(apiResponse['status'] != 'success'){
                }else{
                    this.$store.commit("auth/CLEAR_ACCESS_TOKEN");
                    localStorage.clear()
                    location.href = '/login'
                }
            }
        },
        async getGroups(id) {
            this.groupPendingRequest = true
            let apiResponse = await ApiService.GetOtherUserGroups('other_user', id)
            if (apiResponse['status'] != 'success') {} else {
                this.groups = apiResponse['data']['groups']
                this.total_groups = apiResponse['data']['total_groups']
                this.groupPendingRequest = false
            }
        },
        async loadMoreFeeds() {
            if(!this.id){
                this.page++
                this.getMyFeeds()
            }else{
                this.page++
                this.getUserFeedsPublic(this.id)
            }
        },
    }
};
</script>

<style>

@media (max-width: 640px) {
    .switch_sections {
        display:flex;
        flex-direction: column-reverse;
    }
}
</style>
