<template>
<li tabindex="-1" class="uk-active" v-if="group">
    <div class="card">
        <div class="card-media h-28">
            <div class="card-media-overly"></div>
            <img v-if="group.image" :src="group.image" alt="" class="">
            <img v-else :src="defaultPostUrl()" alt="" class="">

            <div v-if="group.followers_count > 1000" class="absolute bg-red-100 font-semibold px-2.5 py-1 rounded-lg text-red-500 text-xs top-2.5 left-2.5"> Trend </div>
        </div>
        <div class="card-body">
            <a :href="'/dashboard/group/'+group.id" class="font-semibold text-lg truncate"> 
                {{ group.name }} 
                <verified-badge v-if="group.verified == 'true'" size="small" mt="one"></verified-badge>
            </a>
            <div class="flex items-center flex-wrap space-x-1 mt-1 text-sm text-gray-500 capitalize">
                <a href="#"> <span> {{ group.followers_count }} members </span> </a>
                <a href="#"> <span></span> </a>
            </div>
            <div class="flex mt-3.5 space-x-2">
                <div v-if="group.last_five_followers && group.last_five_followers.length > 0" class="flex items-center -space-x-2 -mt-1">
                    <template v-for="follower in group.last_five_followers" v-if="follower.user">
                        <img v-if="follower.user.profile_photo" alt="Image placeholder" :src="follower.user.profile_photo" class="border-2 border-white rounded-full w-7 h-7">
                        <img v-else alt="Image placeholder" :src="defaultPostUrl()" class="border-2 border-white rounded-full w-7 h-7">
                    </template>
                </div>
                <div v-else class="flex items-center -space-x-2 -mt-1">
                    <img alt="Image placeholder" :src="defaultPostUrl()" class="border-2 border-white rounded-full w-7 h-7">
                </div>
                <div class="flex-1 leading-5 text-sm">
                    <div v-if="group.last_five_followers && group.last_five_followers.length > 0 && group.last_five_followers[0]['user'] && group.last_five_followers[0]['user']['name']"> <strong>{{ group.last_five_followers[0]['user']['name'] }}</strong> and others are members </div>
                    <div v-else> No one follows this group yet </div>
                </div>
            </div>

            <div class="flex mt-3.5 space-x-2 text-sm font-medium">
                <a v-if="!group.user_follow" href="javascript:;" @click="followGroup" class="bg-orange flex flex-1 h-8 items-center justify-center rounded-md text-white capitalize">
                    Follow
                </a>
                <a v-else href="javascript:;" class="bg-green-600 flex flex-1 h-8 items-center justify-center rounded-md text-white capitalize">
                    Following
                </a>
                <a :href="'/dashboard/group/'+group.id" class="bg-gray-200 flex flex-1 h-8 items-center justify-center rounded-md capitalize">
                    View
                </a>
            </div>

        </div>
    </div>
</li>
</template>

<script>
import {
    ApiService
} from "@/services/api.service";
import SharePostModal from '@/include/Modals/SharePostModal'
import VerifiedBadge from '@/components/VerifiedBadge'

export default {
    name: 'GroupComponent',
    props: {
        group: Object
    },
    data() {
        return {
            currentUser: '',
            shareLink: '',
            shareDescription: ''
        }
    },
    components: {
        SharePostModal,
        VerifiedBadge,
    },
    mounted() {
        this.currentUser = this.$store.getters["user/currentUser"];
        this.shareDescription = 'Group on Vitisocial'
    },
    methods: {
        async followGroup() {
            this.toggleProgress('show')
            let apiResponse = await ApiService.FollowGroup(this.group['id']);
            this.toggleProgress('hide')
            this.$store.commit("toast/Notify", {
                type: apiResponse["status"],
                message: apiResponse["message"],
            });
            if (apiResponse["status"] != "success") {} else {
                location.href = ''
            }
        },
        isHappening() {},
        setCurrentLink(id, description) {
            let thisLink = this.$store.getters["host/seoUrl"] + '/group/' + id
            this.shareLink = thisLink
        },
        openGroup(id) {
            location.href = '/dashboard/group/' + id
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
