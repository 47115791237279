<template>
<div id="wrapper">
    <Header />

    <sidebar></sidebar>

    <!-- Main Contents -->
    <div class="main_content">
        <div class="mcontainer">
            <div class="lg:flex-old lg:space-x-12">
                <div class="lg:w-1/1 flex-shirink-0">
                    <div class="flex justify-between relative md:mb-4 mb-3">
                        <div class="flex-1 text-gray-900">
                            <h2 class="text-2xl font-semibold">Advertise</h2>
                            <nav class="responsive-nav md:m-0 -mx-4 style-2">
                                <ul>
                                    <li :class="runningAdverts">
                                        <a @click="makeActiveSection('Running', '0')" class="mouse-pointer lg:px-2"> Running </a>
                                    </li>
                                    <li :class="unapprovedAdverts"><a @click="makeActiveSection('Unapproved', '0')" class="mouse-pointer lg:px-2"> Unapproved </a></li>
                                    <li :class="unpaidAdverts"><a @click="makeActiveSection('Unpaid', '0')" class="mouse-pointer lg:px-2"> Unpaid </a></li>
                                    <li :class="pausedAdverts"><a @click="makeActiveSection('Paused', '0')" class="mouse-pointer lg:px-2"> Paused </a></li>
                                    <li :class="completedAdverts"><a @click="makeActiveSection('Completed', '0')" class="mouse-pointer lg:px-2"> Completed </a></li>
                                </ul>
                            </nav>
                        </div>
                        <a href="/dashboard/advertise/create" class="flex items-center justify-center h-10 w-10 z-10 rounded-full bg-blue-600 text-white hover:text-white absolute right-0" data-tippy-placement="left" title="Advertise with Vitisocial">
                            <svg class="w-6 h-6" fill="currentColor" viewbox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" style="padding:2px">
                                <path fill-rule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clip-rule="evenodd"></path>
                            </svg>
                        </a>
                    </div>
                    <div>
                        <div class="card grid grid-cols-4" class-old="card divide-y divide-gray-100 sm:m-0 sm:-mx-5">
                            <a :href="'/dashboard/advertise/' + advert.id" v-for="advert in adverts">
                                <div class="flex items-start space-x-5 p-7">
                                    <img v-if="advert.image" :src="advert.image" alt="" class="w-12 h-12 rounded-full" />
                                    <img v-else :src="defaultPostUrl()" alt="" class="w-12 h-12 rounded-full" />
                                    <div class="flex-1">
                                        <router-link :to="'/dashboard/advertise/' + advert.id" class="text-lg font-semibold line-clamp-1 mb-1 capitalize">
                                            {{ advert.title }}
                                        </router-link>
                                        <p class="text-gray-600  mb-1">
                                            <span class="text-sm">Amount paid: {{ advert.currency }}{{ advert.amount_paid || 0 }} </span>
                                            <span class="text-sm">Amount left: {{ advert.currency }}{{ advert.amount_left || 0 }} </span>
                                        </p>
                                        <p class="text-xs text-gray-400 mb-2 capitalize">
                                            <template>
                                                <span></span>
                                            </template>
                                            {{ feedTimeManager(advert.created_at) }}
                                        </p>
                                    </div>
                                    <div class="sm:flex items-center space-x-4 hidden">
                                        <p>
                                            <span class="text-sm">views: {{ advert.views || 0 }} </span>
                                            <span class="text-sm">clicks: {{ advert.clicks || 0 }} </span>
                                        </p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div v-if="pendingRequest == false && adverts.length == 0" class="bg-white p-2">
                        <h4>No Advert</h4>
                    </div>
                </div>
            </div>
        </div>
        <div id="progressLoader">
            <progress-loader></progress-loader>
        </div>
    </div>

    <dashboard-widgets page="forums"></dashboard-widgets>
</div>
</template>

<script>
import {
    ApiService
} from "@/services/api.service";
import Header from "@/include/Dashboard/Header.vue";
import Sidebar from "@/include/Dashboard/Sidebar.vue";
import DashboardWidgets from '@/components/DashboardWidgets'
import OpenChatBox from "@/include/Modals/OpenChatBox.vue";
import Feeds from "@/include/Dashboard/Feeds.vue";
import ProgressLoader from '@/components/ProgressLoader'
import VerifiedBadge from '@/components/VerifiedBadge'

export default {
    name: "Forums",
    components: {
        Header,
        Sidebar,
        DashboardWidgets,
        OpenChatBox,
        Feeds,
        ProgressLoader,
        VerifiedBadge,
    },

    data() {
        return {
            adverts: [],
            recentTopics: [],
            page: 0,
            pendingRequest: false,
            runningAdverts: 'active',
            unapprovedAdverts: '',
            unpaidAdverts: '',
            pausedAdverts: '',
            completedAdverts: '',
            topContributions: '',
            topContributors: '',
            pagination: {
                limit: 20,
                offset: 0,
                total: 60,
            },
        };
    },
    mounted: function () {
        this.makeActiveSection('running', 0);
    },
    methods: {
        async getAdverts(section, page) {
            this.pendingRequest = true;
            this.toggleProgress('show')
            let apiResponse = await ApiService.GetAdverts(section, page);
            if (apiResponse["status"] != "success") {} else {
                this.adverts = apiResponse["data"]['adverts'];
                this.pendingRequest = false;
            }
            this.toggleProgress('hide')
        },
        async makeActiveSection(a, page) {
            this.runningAdverts = ''
            this.pausedAdverts = ''
            this.unapprovedAdverts = ''
            this.unpaidAdverts = ''
            this.completedAdverts = ''

            this.page = 0;
            this.adverts = []

            if (a.toLowerCase() == 'running') {
                this.runningAdverts = 'active'
                this.getAdverts('running', page)
            } else if (a.toLowerCase() == 'unapproved') {
                this.unapprovedAdverts = 'active'
                this.getAdverts('unapproved', page)
            } else if (a.toLowerCase() == 'paused') {
                this.pausedAdverts = 'active'
                this.getAdverts('paused', page)
            } else if (a.toLowerCase() == 'unpaid') {
                this.unpaidAdverts = 'active'
                this.getAdverts('unpaid', page)
            } else if (a.toLowerCase() == 'completed') {
                this.completedAdverts = 'active'
                this.getAdverts('completed', page)
            }
        }
    },
};
</script>

<style>
.forum-image img {
    width: 3rem !important;
    height: 3rem !important;
    border-radius: 9999px;
    position: relative;
}
</style>
<style lang="scss" scoped>
.some-modal-content {
    min-width: 400px;
    padding: 25px;

    .buttons button {
        padding: 10px;
        margin: 10px;
    }
}
</style>
