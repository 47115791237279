<template>
<div id="wrapper">
    <auth-styles v-if="pageNameMain" :pageNameMain="pageNameMain" :pageDescriptionMain="pageDescriptionMain" :pageImageMain="pageImageMain"></auth-styles>
    <Header />

    <sidebar></sidebar>

    <!-- Main Contents -->
    <div class="main_content">
        <div class="mcontainer" v-if="page != ''">

            <div class="profile is_page">

                <div class="profiles_banner" uk-lightbox="">
                    <img v-if="page.banner" :src="page.banner" alt="Vitisocial">
                    <img v-else :src="defaultPostUrl()" alt="Vitisocial">
                </div>
                <div class="profiles_content">
                    <div class="profile_info">
                        <div class="flex">
                            <div uk-lightbox="">
                                <img class="rounded mr-2" style="height: 40px" v-if="page.image" :src="page.image" alt="Vitisocial">
                                <img class="rounded mr-2" style="height: 40px" v-else :src="defaultPostUrl()" alt="Vitisocial">
                            </div>
                            <div>
                                <h1> 
                                    {{ page.name }} 
                                    <verified-badge v-if="page.verified == 'true'" size="small" mt="one"></verified-badge>
                                </h1>
                                <p>
                                    Page · {{ page.followers_count }} 
                                    <span v-if="page['followers_count'] != 1">members</span> 
                                    <span v-else>member</span> 
                                </p>
                            </div>
                        </div>
                        <div>
                            <div class="mt-4 mb-2 mx-0" v-if="page.user_id == currentUser.id">
                                <h5 class="">View as</h5>
                                <select @change="setPageViewer()" class="story col-sm-4" v-model="post_as">
                                    <option v-for="s in share_as" :value="s">
                                        <span v-if="s.toLowerCase() == 'user'">
                                            {{ currentUser['name'] }}({{ s }})
                                        </span>
                                        <span v-else>
                                            {{ page['name'] }}({{ s }})
                                        </span>
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="sm:flex items-center text-center space-x-4">
                        <div class="flex items-center -space-x-4 mb-3">
                            <template v-if="page.last_five_followers && page.last_five_followers.length > 0">
                                <template v-for="follower in page.last_five_followers" v-if="follower.user">
                                    <img v-if="follower.user.profile_photo" alt="Image placeholder" :src="follower.user.profile_photo" class="w-10 h-10 rounded-full border-2 border-white object-cover">
                                    <img v-else alt="Image placeholder" :src="defaultPostUrl()" class="w-10 h-10 rounded-full border-2 border-white object-cover">
                                </template>
                            </template>
                            <div v-if="page.followers_count > page.last_five_followers.length" class="w-10 h-10 rounded-full flex justify-center items-center bg-red-100 text-red-500 font-semibold"> {{ page.followers_count-page.last_five_followers.length }}+
                            </div>
                        </div>

                        <template v-if="page.user_id != currentUser.id">
                            <a v-if="!page.user_follow" href="javascript:;" @click="followPage" class="flex items-center justify-center h-9 px-5 rounded-md bg-blue-600 text-white  space-x-1.5 mb-2">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5">
                                    <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clip-rule="evenodd"></path>
                                </svg>
                                <span> Follow </span>
                            </a>
                            <a v-else href="javascript:;" @click="unfollowPage" class="flex items-center justify-center h-9 px-3 rounded-md bg-green-100 text-blue-500">
                                Following
                            </a>
                        </template>
                        <a v-if="page.user_id == currentUser.id" :href="'/dashboard/update-page/'+page.id" class="flex items-center justify-center h-9 px-5 rounded-md bg-yellow-600 text-white  space-x-1.5">
                            <span> Edit Page Info </span>
                        </a>
                    </div>

                </div>

                <nav class="responsive-nav border-t -mb-0.5 lg:pl-3.5">
                    <ul uk-switcher="connect: #timeline-tab; animation: uk-animation-fade">
                        <li class=""><a href="javascript:;"> Home</a></li>
                        <li class=""><a href="javascript:;"> About</a></li>
                        <li v-if="page && page['user_follow']" class=""><a href="javascript:;"> Followers</a></li>
                    </ul>
                </nav>

            </div>

            <div class="uk-switcher lg:mt-8 mt-4" id="timeline-tab">
                <div class="md:flex md:space-x-6 lg:mx-16 order-last">
                    <div class="space-y-5 flex-shrink-0 md:w-7/12">

                        <create-post-component v-if="currentUser && currentUser.id == page.user_id" :name="page.name+' Page'"></create-post-component>

                        <feeds section="page"></feeds>

                    </div>
                    <div class="w-full flex-shirink-0">

                        <div class="card p-4 mb-5">

                            <h1 class="block text-lg font-bold"> About </h1>

                            <about v-if="page" :page="page"></about>

                        </div>

                        <div class="widget card p-5 border-t">
                            <div class="flex items-center justify-between mb-2">
                                <div>
                                    <h4 class="text-lg font-semibold"> Related pages </h4>
                                </div>
                                <a href="javascript:;" class="text-blue-600 d-none"> See all</a>
                            </div>
                            <div>
                                <div v-for="rg in related_pages" class="flex items-center space-x-4 rounded-md -mx-2 p-2 hover:bg-gray-50">
                                    <a :href="'/dashboard/page/'+rg.id" class="w-12 h-12 flex-shrink-0 overflow-hidden rounded-full relative">
                                        <img v-if="rg.image" :src="rg.image" class="absolute w-full h-full inset-0 " alt="">
                                        <img v-else :src="defaultPostUrl()" class="absolute w-full h-full inset-0 " alt="">
                                    </a>
                                    <div class="flex-1">
                                        <a :href="'/dashboard/page/'+rg.id" class="text-base font-semibold capitalize"> {{ rg.name }} </a>
                                        <div class="text-sm text-gray-500 mt-0.5"> {{ rg.followers_count }} Following</div>
                                    </div>
                                    <a v-if="!rg.user_follow" href="javascript:;" class="flex items-center justify-center h-8 px-3 rounded-md text-sm border font-semibold bg-blue-500 text-white">
                                        Follow
                                    </a>
                                    <a v-else href="javascript:;" class="flex items-center justify-center h-8 px-3 rounded-md text-sm border font-semibold bg-green-500 text-white">
                                        Following
                                    </a>
                                </div>
                                <div class="bg-white p-2">
                                    <h5 v-if="related_pages.length == 0">No related page yet</h5>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>

                <div class="md:flex md:space-x-6 lg:mx-16">
                    <div class="space-y-5 flex-shrink-0 md:w-11/12 bg-white">

                        <div class="p-4 -mt-1.5">
                            
                            <about v-if="page" :page="page"></about>

                        </div>
                    </div>
                </div>

                <div class="md:flex md:space-x-6 lg:mx-16">
                    <div class="space-y-5 flex-shrink-0 md:w-11/12 bg-white">

                        <div class="p-4 -mt-1.5">

                            <div v-for="(follower, index) in followers" v-if="follower.user" class="flex items-center space-x-4 rounded-md -mx-2 p-2 hover:bg-gray-50">
                                <a :href="'/dashboard/timeline/'+follower.user.id" iv="" class="w-12 h-12 flex-shrink-0 overflow-hidden rounded-full relative">
                                    <img v-if="follower.user.profile_photo" :src="follower.user.profile_photo" class="absolute w-full h-full inset-0">
                                    <img v-else :src="defaultPostUrl()" class="absolute w-full h-full inset-0">
                                </a>
                                <div class="flex-1">
                                    <a :href="'/dashboard/timeline/'+follower.user.id" class="text-base font-semibold capitalize"> 
                                        {{ follower.user.name }} 
                                        <verified-badge v-if="follower.user.verified == 'true'" size="small" mt="one"></verified-badge>
                                    </a>
                                </div>
                                <template v-if="currentUser.id != follower.user.id">
                                    <a v-if="!follower.i_follow" @click="followUser(follower.user.id)" href="javascript:;" class="flex items-center justify-center h-8 px-3 bg-orange text-white rounded-md text-sm border font-semibold">
                                        Follow
                                    </a>
                                    <a v-else @click="unfollowUser(follower.user.id)" href="javascript:;" class="flex items-center justify-center h-8 px-3 rounded-md text-sm border font-semibold">
                                        Following
                                    </a>
                                </template>
                            </div>
                            <div v-if="pageLoading == true" class="text-center my-3">
                                <i class="fa fa-spin fa-spinner fa-2x"></i>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div id="progressLoader">
            <progress-loader></progress-loader>
        </div>
        <div v-if="pageLoading == true" class="text-center my-3">
            <i class="fa fa-spin fa-spinner fa-2x"></i>
        </div>
    </div>

    <dashboard-widgets page="page" v-if="page" :createPostName="page.name+' Page'"></dashboard-widgets>
</div>
</template>

<script>
import {
    ApiService
} from '@/services/api.service'
import Header from '@/include/Dashboard/Header.vue'
import Sidebar from '@/include/Dashboard/Sidebar.vue'
import DashboardWidgets from '@/components/DashboardWidgets'
import OpenChatBox from '@/include/Modals/OpenChatBox.vue'
import Feeds from '@/include/Dashboard/Feeds.vue'
import ProfileIcon from '@/include/Dashboard/Feed/ProfileIcon'
import ProgressLoader from '@/components/ProgressLoader'
import ShareIcons from '@/components/ShareIcons'
import AuthStyles from '@/include/Auth/AuthStyles'
import CreatePostComponent from '@/components/CreatePostComponent'
import VerifiedBadge from '@/components/VerifiedBadge'
import About from '@/components/Pages/About'

export default {
    name: 'Page',
    components: {
        Header,
        Sidebar,
        DashboardWidgets,
        OpenChatBox,
        Feeds,
        ProgressLoader,
        ShareIcons,
        AuthStyles,
        CreatePostComponent,
        VerifiedBadge,
        About,
    },

    data() {
        return {
            page: '',
            related_pages: '',
            feeds: '',
            recent_topics: '',
            comment: '',
            reply: '',
            currentUser: {},
            thisPage: '',
            pageLoading: false,
            pagination: {
                limit: 20,
                offset: 0,
                total: 60
            },
            pageNameMain: '',
            pageDescriptionMain: '',
            pageImageMain: '',
            replyToReply: '',
            followers: '',
            post_as: 'user',
            share_as: ['user','page'],
            requestProgress: 'false',
        }
    },
    mounted () {
        this.currentUser = this.$store.getters['user/currentUser'] || {}
        let id = this.$route.params['id']
        this.getPage(id)
        this.thisPage = location.href
        let urlSplit = this.thisPage.split('viewer=')
        if(urlSplit.length == 2){
            this.post_as = urlSplit[1]
        }
    },
    methods: {
        async getPage(id) {
            this.pageLoading = true
            this.toggleProgress('show')
            let apiResponse = await ApiService.GetPage(id);
            if (apiResponse["status"] != "success") {} else {
                $("#page_about").hide()
                this.page = apiResponse["data"]['page'];
                this.followers = apiResponse["data"]['followers'];
                this.feeds = apiResponse["data"]['feeds'];
                this.related_pages = apiResponse["data"]['related_pages'];
                if (this.topic) {
                    this.pageNameMain = this.topic['title']
                    this.pageDescriptionMain = this.topic['title']
                    this.pageImageMain = this.topic['image']
                }
                setTimeout(() => {
                    $(".mcontainer").show()
                }, 1000);
                this.resizeText()
                $("#page_about").show()
            }
            this.toggleProgress('hide')
            this.pageLoading = false
        },
        async deletePagePost() {
            this.toggleProgress('show')
            let apiResponse = await ApiService.DeletePagePost(this.topic['id']);
            this.$store.commit("toast/Notify", {
                type: apiResponse["status"],
                message: apiResponse["message"],
            });
            if (apiResponse["status"] != "success") {} else {
                location.href = '/dashboard/pages'
            }
        },
        confirmDelete() {
            let proceed = confirm("Are you sure you want to proceed?");
            if (proceed) {
                this.deletePagePost()
            } else {
                //don't proceed
            }
        },
        async addPagePostComment(post_id) {
            this.toggleProgress('show')
            let apiResponse = await ApiService.AddPagePostComment(post_id, this.comment);
            this.$store.commit("toast/Notify", {
                type: apiResponse["status"],
                message: apiResponse["message"],
            });
            if (apiResponse["status"] != "success") {} else {
                this.comment = ''
                this.topic = apiResponse["data"];
            }
            this.toggleProgress('hide')
        },
        async replyPagePostComment(post_id, comment_id) {
            this.toggleProgress('show')
            let apiResponse = await ApiService.ReplyPagePostComment(post_id, comment_id, this.reply);
            this.$store.commit("toast/Notify", {
                type: apiResponse["status"],
                message: apiResponse["message"],
            });
            if (apiResponse["status"] != "success") {} else {
                this.reply = ''
                this.topic = apiResponse["data"];
            }
            this.toggleProgress('hide')
        },
        async replyPagePostCommentReply(post_id, reply_id) {
            this.toggleProgress('show')
            let apiResponse = await ApiService.ReplyPagePostCommentReply(post_id, reply_id, this.replyToReply);
            this.$store.commit("toast/Notify", {
                type: apiResponse["status"],
                message: apiResponse["message"],
            });
            if (apiResponse["status"] != "success") {} else {
                this.replyToReply = ''
                this.topic = apiResponse["data"];
            }
            this.toggleProgress('hide')
        },
        async followPage() {
            this.toggleProgress('show')
            let apiResponse = await ApiService.FollowPage(this.page['id']);
            this.toggleProgress('hide')
            this.$store.commit("toast/Notify", {
                type: apiResponse["status"],
                message: apiResponse["message"],
            });
            if (apiResponse["status"] != "success") {} else {
                location.href = ''
            }
        },
        async unfollowPage() {
            let proceed = confirm("Are you sure you want to Unfollow this Page?");
            if (proceed) {
                this.toggleProgress('show')
                let apiResponse = await ApiService.UnfollowPage(this.page['id']);
                this.toggleProgress('hide')
                this.$store.commit("toast/Notify", {
                    type: apiResponse["status"],
                    message: apiResponse["message"],
                });
                if (apiResponse["status"] != "success") {} else {
                    location.href = ''
                }
            } else {
                //don't proceed
            }
        },
        async followUser(id) {
            this.toggleProgress('show')
            let apiResponse = await ApiService.FollowUser(id);
            this.$store.commit("toast/Notify", {
                type: apiResponse["status"],
                message: apiResponse["message"],
            });
            if (apiResponse['status'] == 'success') {
                location.href = ''
            }
            this.toggleProgress('hide')
        },
        async unfollowUser(id) {
            this.toggleProgress('show')
            let apiResponse = await ApiService.UnfollowUser(id);
            this.$store.commit("toast/Notify", {
                type: apiResponse["status"],
                message: apiResponse["message"],
            });
            if (apiResponse['status'] == 'success') {
                location.href = ''
            }
            this.toggleProgress('hide')
        },
        setPageViewer(){
            let post_as = this.post_as
            location.href = '?viewer='+post_as
        },
        getPageLink(name_key) {
            return this.$store.getters["host/appUrl"]+'/page/'+name_key
        },
        resizeText(){
            this.manageLikeTextSize('.page_fb_link')
            this.manageLikeTextSize('.page_in_link')
            this.manageLikeTextSize('.page_tw_link')
            this.manageLikeTextSize('.page_yt_link')
            this.manageLikeTextSize('.page_page_link')
            this.manageLikeTextSize('.page_website_link')
        }
    },
}
</script>

<style lang="scss" scoped>
.some-modal-content {
    min-width: 400px;
    padding: 25px;

    .buttons button {
        padding: 10px;
        margin: 10px;
    }
}
.profile .profiles_banner img {
    object-fit: cover;
}
</style>
