import $store from '../store'

import { API_URL } from '../.env'
import axios from 'axios'

export class ApiService {
    static async ExtraInfo() {
        let data = {}

        return await apiConnect('/extra_info', data)
    }
    static async FindUserEmail(id) {
        let data = {
            id
        }

        return await apiConnect('/find_user_email', data)
    }
    static async LoginUser(email, password) {
        let data = {
            'email': email,
            'password': password
        }

        return await apiConnect('/login', data)
    }
    static async RegisterUser(email, password, repeat_password, first_name, last_name, username, gender, phone, date_of_birth, country, state, referral) {
        let data = {
            'email': email,
            'password': password,
            'repeat_password': repeat_password,
            'first_name': first_name,
            'last_name': last_name,
            'username': username,
            'gender': gender,
            'phone': phone,
            'date_of_birth': date_of_birth,
            'referral': referral,
            'country': country,
            'state': state
        }

        return await apiConnect('/register-slim', data)
    }
    static async RequestAccountActivation(email) {
        let data = {
            'email': email
        }

        return await apiConnect('/request_account_activation', data)
    }
    static async ActivateAccount(code, expire_at) {
        let data = {
            'code': code,
            'expire_at': expire_at
        }

        return await apiConnect('/activate_account', data)
    }
    static async ResetPassword(email) {
        let data = {
            'email': email,
        }

        return await apiConnect('/forgot_password/verify_email', data)
    }
    static async ChangePassword(email, code, password, repeat_password) {
        let data = {
            'email': email,
            'code': code,
            'password': password,
            'repeat_password': repeat_password
        }

        return await apiConnect('/forgot_password/change_password', data)
    }
    static async SaveFeed(content, type, reach, images, audios, videos, group_id, page_id, feed_shared_from = '', post_as = '', template = '') {
        let data = {
            'content': content,
            'type': type,
            'reach': reach,
            'images': images,
            'audios': audios,
            'videos': videos,
            'group_id': group_id,
            'page_id': page_id,
            feed_shared_from,
            post_as,
            template,
        }

        return await apiConnect('/dashboard/save_feed', data)
    }
    static async GetFeeds(page, section, group_id, page_id) {
        let data = {
            'page': page,
            'section': section,
            'group_id': group_id,
            'page_id': page_id,
        }

        return await apiConnect('/dashboard/get_feeds', data)
    }
    static async GetFeedsWithHashTags(page, hash_tag) {
        let data = {
            page,
            hash_tag
        }

        return await apiConnect('/dashboard/get_feeds_with_hash_tags', data)
    }
    static async GetUserFeeds(page) {
        let data = {
            'page': page
        }

        return await apiConnect('/dashboard/get_user_feeds', data)
    }
    static async GetMyFeeds(page) {
        let data = {
            'page': page
        }

        return await apiConnect('/dashboard/get_my_feeds', data)
    }
    static async GetUserFeedsPublic(id, page) {
        let data = {
            'id': id,
            'page': page
        }

        return await apiConnect('/dashboard/get_user_feeds_public', data)
    }
    static async GetFeed(feed_id) {
        let data = {
            'feed_id': feed_id,
        }

        return await apiConnect('/dashboard/get_feed', data)
    }
    static async GetFeedPublic(feed_id) {
        let data = {
            'feed_id': feed_id,
        }

        return await apiConnect('/public/get_feed', data)
    }
    static async AddComment(feed_id, comment) {
        let post_as = commentPostAs()
        let data = {
            feed_id,
            comment,
            post_as
        }

        return await apiConnect('/dashboard/add_comment', data)
    }
    static async ReplyComment(feed_id, comment_id, reply) {
        let post_as = commentPostAs()
        let data = {
            'feed_id': feed_id,
            'comment_id': comment_id,
            'reply': reply,
            post_as
        }

        return await apiConnect('/dashboard/reply_comment', data)
    }
    static async ReplyCommentReply(feed_id, comment_id, reply_id, reply) {
        let post_as = commentPostAs()
        let data = {
            'feed_id': feed_id,
            'comment_id': comment_id,
            'reply_id': reply_id,
            'reply': reply,
            post_as
        }

        return await apiConnect('/dashboard/reply_comment_reply', data)
    }
    static async FeedLikes(feed_id) {
        let data = {
            feed_id,
        }

        return await apiConnect('/dashboard/feed_likes', data)
    }
    static async LikeFeed(feed_id, type) {
        let data = {
            'feed_id': feed_id,
            'type': type,
        }

        return await apiConnect('/dashboard/like_feed', data)
    }
    static async UnlikeFeed(feed_id) {
        let data = {
            'feed_id': feed_id,
        }

        return await apiConnect('/dashboard/unlike_feed', data)
    }
    static async GetFeedPhotos(page, id) {
        let data = {
            'page': page,
            'id': id
        }

        return await apiConnect('/dashboard/feed_photos', data)
    }
    static async GetFeedVideos(page, section) {
        let data = {
            'page': page,
            'section': section
        }

        return await apiConnect('/dashboard/feed_videos', data)
    }
    static async GetUserFeedVideos(page, id, section) {
        let data = {
            'page': page,
            'id': id,
            'section': section
        }

        return await apiConnect('/dashboard/user_feed_videos', data)
    }
    static async LikeComment(feed_id, comment_id, type) {
        let data = {
            'feed_id': feed_id,
            'comment_id': comment_id,
            'type': type,
        }

        return await apiConnect('/dashboard/like_comment', data)
    }
    static async UnlikeComment(feed_id, comment_id) {
        let data = {
            'feed_id': feed_id,
            'comment_id': comment_id,
        }

        return await apiConnect('/dashboard/unlike_comment', data)
    }
    static async LikeCommentReply(feed_id, reply_id, type) {
        let data = {
            'feed_id': feed_id,
            'reply_id': reply_id,
            'type': type,
        }

        return await apiConnect('/dashboard/like_comment_reply', data)
    }
    static async UnlikeCommentReply(feed_id, reply_id) {
        let data = {
            'feed_id': feed_id,
            'reply_id': reply_id,
        }

        return await apiConnect('/dashboard/unlike_comment_reply', data)
    }
    static async LikeCommentReplyReply(feed_id, reply_id, type) {
        let data = {
            'feed_id': feed_id,
            'reply_id': reply_id,
            'type': type,
        }

        return await apiConnect('/dashboard/like_comment_reply_reply', data)
    }
    static async UnlikeCommentReplyReply(feed_id, reply_id) {
        let data = {
            'feed_id': feed_id,
            'reply_id': reply_id,
        }

        return await apiConnect('/dashboard/unlike_comment_reply_reply', data)
    }
    static async EnableFeedComment(feed_id) {
        let data = {
            'feed_id': feed_id
        }

        return await apiConnect('/dashboard/enable_feed_comment', data)
    }
    static async DisableFeedComment(feed_id) {
        let data = {
            'feed_id': feed_id
        }

        return await apiConnect('/dashboard/disable_feed_comment', data)
    }
    static async UpdateFeed(feed_id, content, images, audios, videos, reach) {
        let data = {
            'feed_id': feed_id,
            'content': content,
            images,
            audios,
            videos,
            reach
        }

        return await apiConnect('/dashboard/update_feed', data)
    }
    static async DeleteFeedAudio(feed_id, audio_id) {
        let data = {
            'feed_id': feed_id,
            'audio_id': audio_id,
        }

        return await apiConnect('/dashboard/delete_feed_audio', data)
    }
    static async DeleteFeedVideo(feed_id, video_id) {
        let data = {
            'feed_id': feed_id,
            'video_id': video_id,
        }

        return await apiConnect('/dashboard/delete_feed_video', data)
    }
    static async DeleteFeedImage(feed_id, image_id) {
        let data = {
            'feed_id': feed_id,
            'image_id': image_id,
        }

        return await apiConnect('/dashboard/delete_feed_image', data)
    }
    static async DeleteFeedComment(feed_id, comment_id) {
        let data = {
            'feed_id': feed_id,
            'comment_id': comment_id,
        }

        return await apiConnect('/dashboard/delete_feed_comment', data)
    }
    static async DeleteFeedCommentReply(feed_id, reply_id) {
        let data = {
            'feed_id': feed_id,
            'reply_id': reply_id,
        }

        return await apiConnect('/dashboard/delete_feed_comment_reply', data)
    }
    static async DeleteFeedCommentReplyReply(feed_id, reply_id) {
        let data = {
            'feed_id': feed_id,
            'reply_id': reply_id,
        }

        return await apiConnect('/dashboard/delete_feed_comment_reply_reply', data)
    }
    static async DeleteFeed(feed_id) {
        let data = {
            'feed_id': feed_id
        }

        return await apiConnect('/dashboard/delete_feed', data)
    }
    static async ViewFeed(feed_id) {
        let data = {
            'feed_id': feed_id,
            'device': 'device'
        }

        return await apiConnect('/dashboard/view_feed', data)
    }
    static async ViewFeedPublic(feed_id) {
        let data = {
            'feed_id': feed_id,
            'device': 'device'
        }

        return await apiConnect('/public/view_feed', data)
    }
    static async ViewBoostedFeed(feed_id) {
        let data = {
            'feed_id': feed_id,
            'device': 'device'
        }

        return await apiConnect('/dashboard/view_boosted_feed', data)
    }
    static async ViewPostAd(id) {
        let data = {
            'id': id,
            'device': 'device'
        }

        return await apiConnect('/dashboard/view_post_ad', data)
    }
    static async ClickPostAd(id) {
        let data = {
            'id': id,
            'device': 'device'
        }

        return await apiConnect('/dashboard/click_post_ad', data)
    }
    static async BoostFeed(feed_id) {
        let data = {
            'feed_id': feed_id
        }

        return await apiConnect('/dashboard/boost_feed', data)
    }
    static async UnboostFeed(feed_id) {
        let data = {
            'feed_id': feed_id
        }

        return await apiConnect('/dashboard/unboost_feed', data)
    }
    static async FeedInsighs(feed_id) {
        let data = {
            'feed_id': feed_id
        }

        return await apiConnect('/dashboard/feed_insights', data)
    }
    static async BoostedFeed() {
        let data = {}

        return await apiConnect('/dashboard/boosted_feed', data)
    }
    static async GetFeedBoosts(feed_id) {
        let data = {
            'feed_id': feed_id
        }

        return await apiConnect('/dashboard/get_feed_boosts', data)
    }
    static async CreateFeedBoost(feed_id, country, gender, age_range, transaction_id, coupon_code = '', amount = '') {
        let data = {
            feed_id,
            country,
            gender,
            age_range,
            transaction_id,
            coupon_code,
            amount
        }

        return await apiConnect('/dashboard/create_feed_boost', data)
    }
    static async ActivateFeedBoost(id) {
        let data = {
            id
        }

        return await apiConnect('/dashboard/activate_feed_boost', data)
    }
    static async PauseFeedBoost(id) {
        let data = {
            id
        }

        return await apiConnect('/dashboard/pause_feed_boost', data)
    }
    static async UserBlockFeed(feed_id) {
        let data = {
            feed_id
        }

        return await apiConnect('/dashboard/user_block_feed', data)
    }
    static async UserPinFeed(feed_id) {
        let data = {
            feed_id
        }

        return await apiConnect('/dashboard/user_pin_feed', data)
    }
    static async UserUnpinFeed(feed_id) {
        let data = {
            feed_id
        }

        return await apiConnect('/dashboard/user_unpin_feed', data)
    }
    static async AdminPinFeed(feed_id) {
        let data = {
            feed_id
        }

        return await apiConnect('/dashboard/admin_pin_feed', data)
    }
    static async AdminUnpinFeed(feed_id) {
        let data = {
            feed_id
        }

        return await apiConnect('/dashboard/admin_unpin_feed', data)
    }
    static async AdminDeleteFeed(feed_id) {
        let data = {
            feed_id
        }

        return await apiConnect('/dashboard/admin_delete_feed', data)
    }
    static async ReportFeedContent(type, id, reason) {
        let data = {
            type,
            id,
            reason
        }

        return await apiConnect('/dashboard/report_feed_content', data)
    }
    static async UpdateFeedCommentType(type, id, comment) {
        let data = {
            type,
            id,
            comment
        }

        return await apiConnect('/dashboard/update_feed_comment_type', data)
    }
    static async GetPosts() {
        let data = {}

        return await apiConnect('/dashboard/get_posts', data)
    }
    static async GetPost(id) {
        let data = {}

        return await apiConnect('/dashboard/get_post/' + id, data)
    }
    static async GetForumPostsOld() {
        let data = {}

        return await apiConnect('/dashboard/get_posts', data)
    }
    static async GetForumPostOld(id) {
        let data = {}

        return await apiConnect('/dashboard/get_post/' + id, data)
    }
    static async AddPostComment(post_id, comment) {
        let data = {
            'post_id': post_id,
            'comment': comment,
        }

        return await apiConnect('/dashboard/add_post_comment', data)
    }
    static async ReplyPostComment(post_id, comment_id, reply) {
        let data = {
            'post_id': post_id,
            'comment_id': comment_id,
            'reply': reply,
        }

        return await apiConnect('/dashboard/reply_post_comment', data)
    }
    static async GetBlogPosts(page, section, category, hash_tag = '') {
        let data = {
            page,
            section,
            category,
            hash_tag
        }

        return await apiConnect('/dashboard/get_blogs', data)
    }
    static async GetBlogPost(id) {
        let data = {}

        let accessToken = $store.getters['auth/accessToken']
        if (accessToken) {
            return await apiConnect('/dashboard/get_blog/' + id, data)
        } else {
            return await apiConnect('/auth/get_blog/' + id, data)
        }
    }
    static async AddBlogPostComment(blog_id, comment) {
        let data = {
            'blog_id': blog_id,
            'comment': comment,
        }

        return await apiConnect('/dashboard/add_blog_comment', data)
    }
    static async ReplyBlogPostComment(blog_id, comment_id, reply) {
        let data = {
            'blog_id': blog_id,
            'comment_id': comment_id,
            'reply': reply,
        }

        return await apiConnect('/dashboard/reply_blog_comment', data)
    }
    static async ReplyBlogPostCommentReply(blog_id, reply_id, reply) {
        let data = {
            'blog_id': blog_id,
            'reply_id': reply_id,
            'reply': reply,
        }

        return await apiConnect('/dashboard/reply_blog_comment_reply', data)
    }
    static async AddBlogPost(image, title, content, category) {
        let data = {
            'image': image,
            'title': title,
            'content': content,
            'category': category,
        }

        return await apiConnect('/dashboard/add_blog_post', data)
    }
    static async UpdateBlogPost(id, image, title, content, category) {
        let data = {
            'image': image,
            'title': title,
            'content': content,
            'category': category,
        }

        return await apiConnect('/dashboard/update_blog_post/' + id, data)
    }
    static async DeleteBlogPost(id) {
        let data = {}

        return await apiConnect('/dashboard/delete_blog_post/' + id, data)
    }
    static async GetForumPostTop() {
        let data = {}

        return await apiConnect('/dashboard/forum_top', data)
    }
    static async GetForumPosts(page, section, hash_tag = '') {
        let data = {
            page,
            section,
            hash_tag
        }

        return await apiConnect('/dashboard/get_forums', data)
    }
    static async GetForumPost(id) {
        let data = {}

        let accessToken = $store.getters['auth/accessToken']
        if (accessToken) {
            return await apiConnect('/dashboard/get_forum/' + id, data)
        } else {
            return await apiConnect('/auth/get_forum/' + id, data)
        }
    }
    static async AddForumPostComment(forum_id, comment) {
        let data = {
            'forum_id': forum_id,
            'comment': comment,
        }

        return await apiConnect('/dashboard/add_forum_comment', data)
    }
    static async ReplyForumPostComment(forum_id, comment_id, reply) {
        let data = {
            'forum_id': forum_id,
            'comment_id': comment_id,
            'reply': reply,
        }

        return await apiConnect('/dashboard/reply_forum_comment', data)
    }
    static async ReplyForumPostCommentReply(forum_id, reply_id, reply) {
        let data = {
            'forum_id': forum_id,
            'reply_id': reply_id,
            'reply': reply,
        }

        return await apiConnect('/dashboard/reply_forum_comment_reply', data)
    }
    static async AddForumPost(image, title, content, category) {
        let data = {
            'image': image,
            'title': title,
            'content': content,
            'category': category,
        }

        return await apiConnect('/dashboard/add_forum_post', data)
    }
    static async UpdateForumPost(id, image, title, content, category) {
        let data = {
            'image': image,
            'title': title,
            'content': content,
            'category': category,
        }

        return await apiConnect('/dashboard/update_forum_post/' + id, data)
    }
    static async DeleteForumPost(id) {
        let data = {}

        return await apiConnect('/dashboard/delete_forum_post/' + id, data)
    }
    static async GetMe() {
        let data = {}

        return await apiConnect('/dashboard/me', data)
    }
    static async FindUser(search) {
        let data = {
            'search': search
        }

        return await apiConnect('/dashboard/find_user', data)
    }
    static async GetUser(id) {
        let data = {
            'id': id
        }

        return await apiConnect('/dashboard/user', data)
    }
    static async UserFollowership(id, page) {
        let data = {
            'page': page
        }

        return await apiConnect('/dashboard/user_followership/' + id, data)
    }
    static async FollowUser(id, feed_id) {
        let data = {
            feed_id
        }

        return await apiConnect('/dashboard/follow_user/' + id, data)
    }
    static async UnfollowUser(id) {
        let data = {}

        return await apiConnect('/dashboard/unfollow_user/' + id, data)
    }
    static async SaveProfilePhoto(profile_photo) {
        let data = {
            'profile_photo': profile_photo
        }

        return await apiConnect('/dashboard/update_user', data)
    }
    static async SaveBannerPhoto(banner_photo) {
        let data = {
            'banner_photo': banner_photo
        }

        return await apiConnect('/dashboard/update_user', data)
    }
    static async SavePushToken(push_token) {
        let data = {
            'push_token': push_token
        }

        return await apiConnect('/dashboard/update_user', data)
    }
    static async GetJobExtraData() {
        let data = {}

        return await apiConnect('/dashboard/get_job_extra_data', data)
    }
    static async SaveJobCompany(name, location, website, sector) {
        let data = {
            'name': name,
            'location': location,
            'website': website,
            'sector': sector,
        }

        return await apiConnect('/dashboard/save_job_company', data)
    }
    static async SaveJobRole(name) {
        let data = {
            'name': name
        }

        return await apiConnect('/dashboard/save_job_role', data)
    }
    static async SaveJobPost(job_role_id, job_company_id, max_salary, min_salary, image, description, job_type_id, tags, application_link) {
        let data = {
            'job_role_id': job_role_id,
            'job_company_id': job_company_id,
            'max_salary': max_salary,
            'min_salary': min_salary,
            'image': image,
            'description': description,
            'job_type_id': job_type_id,
            'tags': tags,
            'application_link': application_link,
        }

        return await apiConnect('/dashboard/save_job_post', data)
    }
    static async UpdateJobPost(id, job_role_id, job_company_id, max_salary, min_salary, image, description, job_type_id, application_link) {
        let data = {
            'job_role_id': job_role_id,
            'job_company_id': job_company_id,
            'max_salary': max_salary,
            'min_salary': min_salary,
            'image': image,
            'description': description,
            'job_type_id': job_type_id,
            'application_link': application_link,
        }

        return await apiConnect('/dashboard/update_job_post/' + id, data)
    }
    static async GetJobPosts(page, section, tag = null) {
        let data = {
            'page': page,
            'section': section,
            'tag': tag
        }

        return await apiConnect('/dashboard/get_job_posts', data)
    }
    static async GetJobPost(id) {
        let data = {}

        return await apiConnect('/dashboard/get_job_post/' + id, data)
    }
    static async DeleteJobPost(id) {
        let data = {}

        return await apiConnect('/dashboard/delete_job_post/' + id, data)
    }
    static async GetBirthdays() {
        let data = {}

        return await apiConnect('/dashboard/get_birthdays', data)
    }
    static async SaveUserData(dob, bio, country, state, city, phone, gender) {
        let data = {
            dob,
            bio,
            country,
            state,
            city,
            phone,
            gender
        }

        return await apiConnect('/dashboard/save_user_settings', data)
    }
    static async GetEvents(page, section) {
        let data = {
            page: page,
            section: section
        }

        return await apiConnect('/dashboard/get_events', data)
    }
    static async GetEvent(id) {
        let data = {}

        return await apiConnect('/dashboard/get_event/' + id, data)
    }
    static async DeleteEvent(id) {
        let data = {}

        return await apiConnect('/dashboard/delete_event/' + id, data)
    }
    static async ShowEventInterest(id) {
        let data = {}

        return await apiConnect('/dashboard/show_event_interest/' + id, data)
    }
    static async InviteFriendToEvent(id, user_id) {
        let data = {
            user_id
        }

        return await apiConnect('/dashboard/invite_friend_to_event/' + id, data)
    }
    static async UpdateEvent(id, title, payment_type, type, website, image, description, start_date, start_time, end_date, end_time, sector, timezone) {
        let data = {
            title: title,
            payment_type: payment_type,
            type: type,
            website: website,
            image: image,
            description: description,
            start_date: start_date,
            start_time: start_time,
            end_date: end_date,
            end_time: end_time,
            timezone: timezone,
            sector: sector
        }

        return await apiConnect('/dashboard/update_event/' + id, data)
    }
    static async SaveEvent(title, payment_type, type, website, image, description, start_date, start_time, end_date, end_time, sector, timezone) {
        let data = {
            title: title,
            payment_type: payment_type,
            type: type,
            website: website,
            image: image,
            description: description,
            start_date: start_date,
            start_time: start_time,
            end_date: end_date,
            end_time: end_time,
            timezone: timezone,
            sector: sector
        }

        return await apiConnect('/dashboard/save_event_post', data)
    }
    static async GetProducts(page, section) {
        let data = {
            page: page,
            section: section,
            per_page: 10
        }

        return await apiConnect('/dashboard/get_products', data)
    }
    static async GetProductsBySection(page, section, section_id) {
        let data = {
            page: page,
            section: section,
            section_id: section_id,
            per_page: 10
        }

        return await apiConnect('/dashboard/get_products', data)
    }
    static async GetProduct(id) {
        let data = {}

        return await apiConnect('/dashboard/get_product/' + id, data)
    }
    static async DeleteProduct(id) {
        let data = {}

        return await apiConnect('/dashboard/delete_product/' + id, data)
    }
    static async SaveProduct(name, amount, currency, in_stock, image, description, brand_id, category_id, sub_category_id, sizes, colors) {
        let data = {
            name: name,
            amount: amount,
            currency: currency,
            in_stock: in_stock,
            image: image,
            description: description,
            brand_id: brand_id,
            category_id: category_id,
            sub_category_id: sub_category_id,
            colors: colors,
            sizes: sizes
        }

        return await apiConnect('/dashboard/save_product', data)
    }
    static async UpdateProduct(id, name, amount, currency, in_stock, image, description, brand_id, category_id, sub_category_id, sizes, colors) {
        let data = {
            name: name,
            amount: amount,
            currency: currency,
            in_stock: in_stock,
            image: image,
            description: description,
            brand_id: brand_id,
            category_id: category_id,
            sub_category_id: sub_category_id,
            colors: colors,
            sizes: sizes
        }

        return await apiConnect('/dashboard/update_product/' + id, data)
    }
    static async FavouriteProduct(id) {
        let data = {}

        return await apiConnect('/dashboard/favourite_product/' + id, data)
    }
    static async UnfavouriteProduct(id) {
        let data = {}

        return await apiConnect('/dashboard/unfavourite_product/' + id, data)
    }
    static async GetUserProductCarts() {
        let data = {}

        return await apiConnect('/dashboard/get_user_product_carts', data)
    }
    static async AddProductToCart(id, color, size) {
        let data = {
            color: color,
            size: size
        }

        return await apiConnect('/dashboard/add_product_to_cart/' + id, data)
    }
    static async RemoveProductFromCart(id) {
        let data = {}

        return await apiConnect('/dashboard/remove_product_from_cart/' + id, data)
    }
    static async CreateOrderFromCart(transaction_id) {
        let data = {
            transaction_id: transaction_id
        }

        return await apiConnect('/dashboard/create_order_from_cart', data)
    }
    static async GetProductOrders(section, page, per_page) {
        let data = {
            section: section,
            page: page,
            per_page: per_page
        }

        return await apiConnect('/dashboard/get_user_product_orders', data)
    }
    static async GetProductOrder(id) {
        let data = {}

        return await apiConnect('/dashboard/get_product_order/' + id, data)
    }
    static async MarkShopProductOrder(mark_as, product_order_id) {
        let data = {
            mark_as: mark_as,
            product_order_id: product_order_id
        }

        return await apiConnect('/dashboard/mark_shop_product_order', data)
    }
    static async MarkCustomerProductOrder(mark_as, product_order_id) {
        let data = {
            mark_as: mark_as,
            product_order_id: product_order_id
        }

        return await apiConnect('/dashboard/mark_customer_product_order', data)
    }
    static async SendCustomerMessageToProductOrder(product_order_id, message) {
        let data = {
            message: message,
            product_order_id: product_order_id
        }

        return await apiConnect('/dashboard/send_customer_message_to_product_order', data)
    }
    static async SendShopMessageToProductOrder(product_order_id, message) {
        let data = {
            message: message,
            product_order_id: product_order_id
        }

        return await apiConnect('/dashboard/send_shop_message_to_product_order', data)
    }
    static async GetUserShop() {
        let data = {}

        return await apiConnect('/dashboard/get_user_shop', data)
    }
    static async GetShop(id) {
        let data = {}

        return await apiConnect('/dashboard/get_shop/' + id, data)
    }
    static async SaveShop(name, image, description, address, city, state, country, shop_unique_name) {
        let data = {
            name: name,
            image: image,
            description: description,
            address: address,
            city: city,
            state: state,
            country: country,
            shop_unique_name: shop_unique_name,
        }

        return await apiConnect('/dashboard/save_shop', data)
    }
    static async UpdateShop(id, name, image, description, address, city, state, country, shop_unique_name) {
        let data = {
            name: name,
            image: image,
            description: description,
            address: address,
            city: city,
            state: state,
            country: country,
            shop_unique_name: shop_unique_name,
        }

        return await apiConnect('/dashboard/update_shop/' + id, data)
    }
    static async GetChats(type) {
        let data = {
            'type': type
        }

        return await apiConnect('/dashboard/chats', data)
    };
    static async GetChat(id, type = 'user') {
        let data = {
            type
        }

        return await apiConnect('/dashboard/chat/' + id, data)
    };
    static async BlockChat(id) {
        let data = {}

        return await apiConnect('/dashboard/block_chat/' + id, data)
    };
    static async UnblockChat(id) {
        let data = {}

        return await apiConnect('/dashboard/unblock_chat/' + id, data)
    };
    static async MarkChatMessagesAsSeen(id) {
        let data = {}

        return await apiConnect('/dashboard/mark_chat_messages_as_seen/' + id, data)
    };
    static async FindChat(id, type, other_id, other_type) {
        let data = {
            'id': id,
            'type': type,
            'other_id': other_id,
            'other_type': other_type,
        }

        return await apiConnect('/dashboard/find_chat', data)
    };
    static async SendChatMessage(message, message_type, message_from, id, product_link, original_message_id, images) {
        let data = {
            message,
            message_type,
            message_from,
            id,
            product_link,
            original_message_id,
            images
        }

        return await apiConnect('/dashboard/send_chat_message/' + id, data)
    };
    static async DeleteChatMessage(id) {
        let data = {}

        return await apiConnect('/dashboard/delete_chat_message/' + id, data)
    };
    static async GetFollowers() {
        let data = {}

        return await apiConnect('/dashboard/get_followers', data)
    };
    static async GetCourses(section, category, sub_category) {
        let data = {
            'section': section,
            'category': category,
            'sub_category': sub_category
        }

        return await apiConnect('/dashboard/courses', data)
    };
    static async GetUserCourses(section, category, sub_category) {
        let data = {
            'section': section,
            'category': category,
            'sub_category': sub_category
        }

        return await apiConnect('/dashboard/user_courses', data)
    };
    static async GetCourse(id) {
        let data = {}

        return await apiConnect('/dashboard/course/' + id, data)
    };
    static async CreateCourse(title, sub_title, image, level, payment, currency, amount, category, sub_category, description) {
        let data = {
            'title': title,
            'sub_title': sub_title,
            'image': image,
            'level': level,
            'payment': payment,
            'currency': currency,
            'amount': amount,
            'category': category,
            'sub_category': sub_category,
            'description': description,
        }

        return await apiConnect('/dashboard/create_course', data)
    };
    static async UpdateCourse(id, title, sub_title, image, level, payment, currency, amount, category, sub_category, description) {
        let data = {
            'title': title,
            'sub_title': sub_title,
            'image': image,
            'level': level,
            'payment': payment,
            'currency': currency,
            'amount': amount,
            'category': category,
            'sub_category': sub_category,
            'description': description,
        }

        return await apiConnect('/dashboard/update_course/' + id, data)
    };
    static async ActivateCourse(id) {
        let data = {}

        return await apiConnect('/dashboard/activate_course/' + id, data)
    };
    static async DeactivateCourse(id) {
        let data = {}

        return await apiConnect('/dashboard/deactivate_course/' + id, data)
    };
    static async CreateCourseLesson(id, title, description, lesson_index, lesson_materials) {
        let data = {
            'title': title,
            'lesson_index': lesson_index,
            'lesson_materials': lesson_materials,
            'description': description,
        }

        return await apiConnect('/dashboard/create_course_lesson/' + id, data)
    };
    static async DeleteCourseLesson(id, lesson_id) {
        let data = {
            'lesson_id': lesson_id
        }

        return await apiConnect('/dashboard/delete_course_lesson/' + id, data)
    };
    static async CreateCourseFaq(id, title, description, faq_index) {
        let data = {
            'title': title,
            'faq_index': faq_index,
            'description': description,
        }

        return await apiConnect('/dashboard/create_course_faq/' + id, data)
    };
    static async DeleteCourseFaq(id, faq_id) {
        let data = {
            'faq_id': faq_id
        }

        return await apiConnect('/dashboard/delete_course_faq/' + id, data)
    };
    static async CreateCourseAnnouncement(id, title, description) {
        let data = {
            'title': title,
            'description': description,
        }

        return await apiConnect('/dashboard/create_course_announcement/' + id, data)
    };
    static async DeleteCourseAnnouncement(id, announcement_id) {
        let data = {
            'announcement_id': announcement_id
        }

        return await apiConnect('/dashboard/delete_course_announcement/' + id, data)
    };
    static async CreateCourseReview(id, rating, review) {
        let data = {
            'rating': rating,
            'review': review,
        }

        return await apiConnect('/dashboard/create_course_review/' + id, data)
    };
    static async DeleteCourseReview(id, review_id) {
        let data = {
            'review_id': review_id
        }

        return await apiConnect('/dashboard/delete_course_review/' + id, data)
    };
    static async EnrollCourse(id, transaction_id) {
        let data = {
            'transaction_id': transaction_id
        }

        return await apiConnect('/dashboard/enroll_course/' + id, data)
    };
    static async GetGroups(page, section) {
        let data = {
            page: page,
            section: section
        }

        return await apiConnect('/dashboard/get_groups', data)
    }
    static async GetOtherUserGroups(section, user_id) {
        let data = {
            section: section,
            user_id: user_id
        }

        return await apiConnect('/dashboard/get_groups', data)
    }
    static async GetFollowedGroups(page, section) {
        let data = {
            page: page,
            section: section
        }

        return await apiConnect('/dashboard/get_followed_groups', data)
    }
    static async GetGroup(id) {
        let data = {}

        return await apiConnect('/dashboard/get_group/' + id, data)
    }
    static async DeleteGroup(id) {
        let data = {}

        return await apiConnect('/dashboard/delete_group/' + id, data)
    }
    static async UpdateGroup(id, image, name, description, category, website, email, reach) {
        let data = {
            image: image,
            name: name,
            description: description,
            category: category,
            website: website,
            email: email,
            reach: reach,
        }

        return await apiConnect('/dashboard/update_group/' + id, data)
    }
    static async CreateGroup(image, name, description, category, website, email, reach) {
        let data = {
            image: image,
            name: name,
            description: description,
            category: category,
            website: website,
            email: email,
            reach: reach,
        }

        return await apiConnect('/dashboard/save_group', data)
    }
    static async FollowGroup(id) {
        let data = {}

        return await apiConnect('/dashboard/follow_group/' + id, data)
    }
    static async UnfollowGroup(id) {
        let data = {}

        return await apiConnect('/dashboard/unfollow_group/' + id, data)
    }
    static async GetPages(page, section) {
        let data = {
            page: page,
            section: section
        }

        return await apiConnect('/dashboard/get_pages', data)
    }
    static async GetOtherUserPages(section, page) {
        let data = {
            section: section,
            page: page
        }

        return await apiConnect('/dashboard/get_pages', data)
    }
    static async GetFollowedPages(page, section) {
        let data = {
            page: page,
            section: section
        }

        return await apiConnect('/dashboard/get_followed_pages', data)
    }
    static async GetPage(id) {
        let data = {}

        return await apiConnect('/dashboard/get_page/' + id, data)
    }
    static async GetPagePublic(id) {
        let data = {}

        return await apiConnect('/get_page/' + id, data)
    }
    static async DeletePage(id) {
        let data = {}

        return await apiConnect('/dashboard/delete_page/' + id, data)
    }
    static async UpdatePage(id, image, name, description, category, website, email, reach, hide_email, banner, facebook, twitter, instagram, youtube) {
        let data = {
            image,
            name,
            description,
            category,
            website,
            email,
            reach,
            hide_email,
            banner,
            facebook,
            twitter,
            instagram,
            youtube
        }

        return await apiConnect('/dashboard/update_page/' + id, data)
    }
    static async CreatePage(image, name, description, category, website, email, hide_email, banner, facebook, twitter, instagram, youtube) {
        let data = {
            image,
            name,
            description,
            category,
            website,
            email,
            hide_email,
            banner,
            facebook,
            twitter,
            instagram,
            youtube
        }

        return await apiConnect('/dashboard/save_page', data)
    }
    static async FollowPage(id) {
        let data = {}

        return await apiConnect('/dashboard/follow_page/' + id, data)
    }
    static async UnfollowPage(id) {
        let data = {}

        return await apiConnect('/dashboard/unfollow_page/' + id, data)
    }
    static async SaveStory(type, content, caption) {
        let data = {
            type: type,
            content: content,
            caption: caption
        }

        return await apiConnect('/dashboard/save_story', data)
    }
    static async GetStory() {
        let data = {}

        return await apiConnect('/dashboard/get_story', data)
    }
    static async ViewStoryItem(id) {
        let data = {
            id: id
        }

        return await apiConnect('/dashboard/view_story_item', data)
    }
    static async DeleteStoryItem(id) {
        let data = {
            id: id
        }

        return await apiConnect('/dashboard/delete_story_item', data)
    }
    static async GetPostAds(type, per_page) {
        let data = {
            type: type,
            per_page: per_page
        }

        return await apiConnect('/dashboard/post_ad/get', data)
    }
    static async PostAdAction(id, type) {
        let data = {
            id: id,
            type: type,
        }

        return await apiConnect('/dashboard/post_ad/action', data)
    }
    static async ApplyForVerification(user_id, document, phone) {
        let data = {
            user_id,
            phone,
            document,
        }

        return await apiConnect('/dashboard/apply-for-verification', data)
    }
    static async GetNotifications() {
        let data = {}

        return await apiConnect('/dashboard/notifications/get', data)
    }
    static async MarkNotifications() {
        let data = {}

        return await apiConnect('/dashboard/notifications/mark', data)
    }
    static async MarkNotification(id) {
        let data = {}

        return await apiConnect('/dashboard/notifications/mark/' + id, data)
    }
    static async MarkNotificationsAsSeen() {
        let data = {}

        return await apiConnect('/dashboard/notifications/mark-as-seen', data)
    }
    static async MarkNotificationsAsOpened() {
        let data = {}

        return await apiConnect('/dashboard/notifications/mark-as-opened', data)
    }
    static async Search(search, section = '') {
        let data = { search, section }

        return await apiConnect('/dashboard/search', data)
    }
    static async FeedDonationsSent(page, per_page) {
        let data = { page, per_page }

        return await apiConnect('/dashboard/feed-donations-sent', data)
    }
    static async FeedDonationsReceived(feed_id, page, per_page) {
        let data = { feed_id, page, per_page }

        return await apiConnect('/dashboard/feed-donations-received', data)
    }
    static async DonateToFeed(feed_id, transaction_id) {
        let data = { feed_id, transaction_id }

        return await apiConnect('/dashboard/donate-to-feed', data)
    }
    static async AdminDelistFeed(feed_id) {
        let data = { feed_id }

        return await apiConnect('/dashboard/admin_delist_feed', data)
    }
    static async AdminEnableFeedDonation(feed_id) {
        let data = { feed_id }

        return await apiConnect('/dashboard/admin_enable_feed_donation', data)
    }
    static async AdminDisableFeedDonation(feed_id) {
        let data = { feed_id }

        return await apiConnect('/dashboard/admin_disable_feed_donation', data)
    }
    static async GetWallet() {
        let data = {}

        return await apiConnect('/dashboard/user/wallet', data)
    }
    static async TopupWallet(transaction_id) {
        let data = { transaction_id }

        return await apiConnect('/dashboard/user/topup-wallet', data)
    }
    static async GetBankAccount() {
        let data = {}

        return await apiConnect('/dashboard/user/get-account', data)
    }
    static async AddBankAccount(account_name, account_no, bank, account_type) {
        let data = { account_name, account_no, bank, account_type }

        return await apiConnect('/dashboard/user/add-account', data)
    }
    static async GetWithdrawals(page, per_page) {
        let data = { page, per_page }

        return await apiConnect('/dashboard/user/get-withdrawals', data)
    }
    static async Withdraw(amount) {
        let data = { amount }

        return await apiConnect('/dashboard/user/withdraw', data)
    }
    static async BeamsAuth() {
        let data = {}

        return await apiConnect('/dashboard/user/beams-auth', data)
    }
    static async BlockUser(id) {
        let data = { id }

        return await apiConnect('/dashboard/user/block-user', data)
    }
    static async UnblockUser(id) {
        let data = { id }

        return await apiConnect('/dashboard/user/unblock-user', data)
    }
    static async DeleteAccount(id) {
        let data = { id }

        return await apiConnect('/dashboard/user/delete', data)
    }
    static async SaveAdvert(title, link, amount, image) {
        let data = { title, link, amount, image }

        return await apiConnect('/dashboard/advertise/save', data)
    }
    static async GetAdverts(section, page) {
        let data = {
            section,
            page
        }

        return await apiConnect('/dashboard/advertise/all', data)
    }
    static async GetAdvert(id) {
        let data = { id }

        return await apiConnect('/dashboard/advertise/single', data)
    }
    static async PauseAdvert(id) {
        let data = { id }

        return await apiConnect('/dashboard/advertise/pause', data)
    }
    static async ActivateAdvert(id) {
        let data = { id }

        return await apiConnect('/dashboard/advertise/activate', data)
    }
    static async AdvertPayWithRave(id, transaction_id) {
        let data = { key: id, transaction_id }

        return await apiConnect('/dashboard/advertise/pay-with-rave', data)
    }
    static async AdvertPayWithWallet(id) {
        let data = { key: id }

        return await apiConnect('/dashboard/advertise/pay-with-wallet', data)
    }
    static async GetReferrals(page, per_page) {
        let data = { page, per_page }

        return await apiConnect('/dashboard/referrals/get', data)
    }
    static async GetReferralAdvertCommisions(page, per_page) {
        let data = { page, per_page }

        return await apiConnect('/dashboard/referrals/advert-commisions', data)
    }
    static async GetWithdrawalCommisions(page, per_page) {
        let data = { page, per_page }

        return await apiConnect('/dashboard/referrals/withdrawal-commisions', data)
    }
    static async Logout() {
        let data = {}

        return await apiConnect('/dashboard/user/logout', data)
    }

    // ------------
    // to be removed
    // ----------------------------

    // static async SavePhoto(reach, description, url) {
    //     let data = {
    //         'reach': reach,
    //         'description': description,
    //         'url': url
    //     }

    //     return await apiConnect('/dashboard/save_photo', data)
    // }
    // static async GetPhotos(page, id) {
    //     let data = {
    //         'id': id,
    //         'page': page
    //     }

    //     return await apiConnect('/dashboard/get_photos', data)
    // }
    // static async LikePhoto(id) {
    //     let data = {
    //         'id': id
    //     }

    //     return await apiConnect('/dashboard/like_photo', data)
    // }
    // static async UnlikePhoto(id) {
    //     let data = {
    //         'id': id
    //     }

    //     return await apiConnect('/dashboard/unlike_photo', data)
    // }
    // static async SaveVideo(title, description, reach, url, age_restrict, keywords) {
    //     let data = {
    //         'reach': reach,
    //         'title': title,
    //         'description': description,
    //         'age_restrict': age_restrict,
    //         'url': url,
    //         'keywords': keywords
    //     }

    //     return await apiConnect('/dashboard/save_video', data)
    // }
    // static async GetVideos(page, section) {
    //     let data = {
    //         'page': page,
    //         'section': section
    //     }

    //     return await apiConnect('/dashboard/get_videos', data)
    // }
    // static async GetUserVideos(page, id, section) {
    //     let data = {
    //         'id': id,
    //         'page': page,
    //         'section': section
    //     }

    //     return await apiConnect('/dashboard/get_user_videos', data)
    // }
    // static async LikeVideo(id) {
    //     let data = {
    //         'id': id
    //     }

    //     return await apiConnect('/dashboard/like_video', data)
    // }
    // static async UnlikeVideo(id) {
    //     let data = {
    //         'id': id
    //     }

    //     return await apiConnect('/dashboard/unlike_video', data)
    // }
    // static async DislikeVideo(id) {
    //     let data = {
    //         'id': id
    //     }

    //     return await apiConnect('/dashboard/dislike_video', data)
    // }
    // static async UndislikeVideo(id) {
    //     let data = {
    //         'id': id
    //     }

    //     return await apiConnect('/dashboard/undislike_video', data)
    // }
    // static async AddVideoComment(id, comment) {
    //     let data = {
    //         'id': id,
    //         'comment': comment,
    //     }

    //     return await apiConnect('/dashboard/comment_video', data)
    // }
    // static async DeleteVideoComment(id, comment) {
    //     let data = {
    //         'id': id,
    //         'comment': comment,
    //     }

    //     return await apiConnect('/dashboard/delete_comment_video', data)
    // }
    // static async FavouriteVideo(id) {
    //     let data = {
    //         'id': id
    //     }

    //     return await apiConnect('/dashboard/favourite_video', data)
    // }
    // static async UnfavouriteVideo(id) {
    //     let data = {
    //         'id': id
    //     }

    //     return await apiConnect('/dashboard/favourite_video', data)
    // };
    //-------------------------
}

async function apiConnect(url, data) {
    let accessToken = $store.getters['auth/accessToken']
    // console.log(accessToken, 'accessToken')
    if (accessToken) {
        data['access_token'] = accessToken
    }
    // console.log(data, 'data')
    return await $.ajax({
        url: $store.getters['host/apiUrl'] + '' + url,
        method: 'POST',
        data: data,
        success: function (response) {
            // console.log(response)
            return response
        },
        error: function (response) {
            console.log(response)
            return response
        }
    })

}

function commentPostAs() {
    let post_as = 'user'
    let urlSplit = location.href.split('viewer=')
    if (urlSplit.length == 2) {
        post_as = urlSplit[1]
    }
    return post_as
}