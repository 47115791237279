<template>
<div class="">
    <vue-editor v-model="mainContent" @text-change="updateContent" v-on:keyup="updateContent" useCustomImageHandler @image-added="handleImageAdded"></vue-editor>
</div>
</template>

<script>
import {
    ApiService
} from "@/services/api.service";
import {
    VueEditor
} from "vue2-editor";
import axios from 'axios'

export default {
    name: 'CustomTextEditor',
    props: {
        content: String,
    },
    data() {
        return {
            mainContent: '',
        }
    },
    components: {
        VueEditor
    },
    mounted() {
        // console.log("::: content", this.content)
        this.mainContent = this.content
    },
    methods: {
        updateContent() {
            this.$emit('contentUpdated', this.mainContent)
        },
        handleImageAdded: function (file, Editor, cursorLocation, resetUploader) {
            // An example of using FormData
            // NOTE: Your key could be different such as:
            // formData.append('file', file)

            var formData = new FormData();
            formData.append("file", file);

            axios({
                    url: this.$store.getters['host/apiUrl'].replace('/api/v2', '/files/upload'),
                    method: "POST",
                    data: formData
                })
                .then(result => {
                    // console.log('result', result)
                    const url = result.data.data['file_name']; // Get url from response
                    Editor.insertEmbed(cursorLocation, "image", url);
                    resetUploader();
                })
                .catch(err => {
                    // console.log(err);
                });
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
