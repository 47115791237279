<template>
<div id="feed-donate-modal" class="create-post-old is-story-old uk-modal-container-old" uk-modal="">
    <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical rounded-lg p-0 lg:w-5/12 relative shadow-2xl uk-animation-slide-bottom-small bg-white" style="height: 60vh;">
        <div class="text-center py-3 border-b">
            <h3 class="text-lg font-semibold">Feed Donation</h3>
            <button class="uk-modal-close-default bg-gray-100 rounded-full p-2.5 right-2" type="button" uk-close="" uk-tooltip="title: Close ; pos: bottom ;offset:7"></button>
        </div>
            <div class="bottom-0 p-4 w-full my-3">
                <div class="uk-child-width-4-5@s uk-child-width-2-3@m uk-flex-center" uk-grid>
                    <div >
                        <div class="mt-3">
                            <div class="line my-3">
                                <input v-model="amount" class="line__input" id="amount" name="amount" type="text" onkeyup="this.setAttribute('value', this.value);" value="" autocomplete="off" />
                                <span for="amount" class="line__placeholder"> Amount to donate({{ currency }}) </span>
                            </div>
                            <div class="mb-5 d-flex justify-center">
                                <div v-if="amount">
                                    <pay-with-flutterwave :title="'Donate '+cSymbol+' '+amount" :email="currentUser.email" :phone="currentUser.phone" :name="currentUser.name" :amount="parseFloat(amount)" :currency="currency" :description="description+' '+main_feed_id" @paymentCallback="paymentCallback"></pay-with-flutterwave>
                                </div>
                                <button v-else class="bg-green-600 custom_button mr-1 text-white col-xs-12" disabled>Make Payment</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

    </div>
</div>
</template>

<script>
import {
    ApiService
} from '@/services/api.service'
import PayWithFlutterwave from '@/components/PayWithFlutterwave'
export default {
    name: "FeedDonateModal",
    props: {
        feed_id: String,
    },
    data() {
        return {
            loading: "true",
            insights: "",
            feed_boosts: "",
            ip: '',
            browser: '',
            device: '',
            transaction_id: '',
            currentUser: '',
            description: 'Feed donation',
            amount: '',
            currentYear: new Date().getFullYear(),
            currency: '',
            main_feed_id: '',
            cSymbol: '',
        };
    },
    mounted () {
        this.currentUser = this.$store.getters['user/currentUser'] || {}
        this.getCurrencySymbol()
        let vueThis = this
        this.$root.$on("open-feed-donate-modal",(data) => {
            console.log('main_feed_id', data)

            vueThis.main_feed_id = data['feed_id_donate']
            vueThis.currency = data['currency']
            vueThis.getCurrencySymbol()

        })
    },
    components: {
      PayWithFlutterwave,
    },
    methods: {
        async donateToFeed() {
            this.loading = "true";
            let apiResponse = await ApiService.DonateToFeed(this.main_feed_id, this.transaction_id);
            this.$store.commit("toast/Notify", {
                type: apiResponse["status"],
                message: apiResponse["message"],
            });
            if (apiResponse["status"] != "success") {
            } else {
                this.amount = ''
                // location.href = ''
            }
            this.loading = "false";
        },
        async paymentCallback(response) {
            this.$store.commit("toast/Notify", {
                type: 'success',
                message: 'Payment Received, Please Hold On so we process the donation',
            });
            this.toggleProgress('show')
            // console.log("RESPONSE", response)
            let txRef = response['tx']['txRef'];
            if (txRef) {
                this.toggleProgress('show')
                this.transaction_id = txRef
                let apiResponse = await this.donateToFeed();
                this.toggleProgress('hide')
                this.closeModal()
            } else {
                this.$toastr.w('Warning', 'Transaction reference could not be found')
            }
            this.toggleProgress('hide')
        },
        async open() {},
        closeModal() {
            $("#feed-donate-modal").removeClass("uk-open").hide();
        },
        getCurrencySymbol(){
            this.cSymbol = this.currencySymbol(this.currency) 
        }
    },
};
</script>

<style lang="scss" scoped>
.styled-table {
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  font-family: sans-serif;
  min-width: 400px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  width: 100%;
}
.styled-table thead tr {
  background-color: #2563eb;
  color: #ffffff;
  text-align: left;
}
.styled-table th,
.styled-table td {
    padding: 12px 15px;
}
.styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.styled-table tbody tr:last-of-type {
  border-bottom: 2px solid #2563eb;
}
</style>
