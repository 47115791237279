<template>
<div id="wrapper">
    <auth-styles :pageNameMain="pageNameMain" :pageDescriptionMain="pageDescriptionMain" :pageImageMain="pageImageMain"></auth-styles>
    <Header />

    <sidebar></sidebar>

    <!-- Main Contents -->
    <div class="main_content">
        <div class="mcontainer">
            <div class="lg:flex lg:space-x-12">
                <div class="lg:w-3/4">
                    <div class="flex justify-between items-center relative md:mb-4 mb-3">
                        <div class="flex-1">
                            <h2 class="text-2xl font-semibold">Blog</h2>
                            <nav class="responsive-nav md:m-0 -mx-4 style-2">
                                <ul>
                                    <li :class="recentActive">
                                        <a @click="makeActiveSection('Recent')" class="mouse-pointer lg:px-2"> Recent </a>
                                    </li>
                                    <li :class="trendingActive"><a @click="makeActiveSection('Trending')" class="mouse-pointer lg:px-2"> Trending </a></li>
                                    <li :class="oldestActive"><a @click="makeActiveSection('Oldest')" class="mouse-pointer lg:px-2"> Oldest </a></li>
                                    <li :class="userActive"><a @click="makeActiveSection('User')" class="mouse-pointer lg:px-2"> Mine </a></li>
                                </ul>
                            </nav>
                        </div>
                        <a href="/dashboard/create-blog" class="flex items-center justify-center h-10 w-10 z-10 rounded-full bg-blue-600 text-white hover:text-white absolute right-0" data-tippy-placement="left" title="Create New Blog Post">
                            <svg class="w-6 h-6" fill="currentColor" viewbox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" style="padding:2px">
                                <path fill-rule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clip-rule="evenodd"></path>
                            </svg>
                        </a>
                    </div>

                    <div class="card divide-y divide-gray-100 px-4">
                        <div class="lg:flex lg:space-x-6 py-5" v-for="topic in topics">
                            <router-link :to="'/dashboard/blog/'+topic.name_key">
                                <div class="lg:w-60 w-full h-40 overflow-hidden rounded-lg relative shadow-sm">
                                    <img v-if="topic.image" :src="topic.image" alt="" class="w-full h-full absolute inset-0 object-cover" />
                                    <img v-else :src="defaultPostUrl()" alt="" class="w-12 h-12 rounded-full" />
                                    <div class="absolute bg-blue-100 font-semibold px-2.5 py-1 rounded-full text-blue-500 text-xs top-2.5 left-2.5">
                                        {{ topic.category }}
                                    </div>
                                </div>
                            </router-link>
                            <div class="flex-1 lg:pt-0 pt-4">
                                <router-link :to="'/dashboard/blog/'+topic.name_key" class="text-xl font-semibold line-clamp-2 mouse-pointer text-capitalize" v-html="topic.title">
                                </router-link>
                                <p class="line-clamp-2 pt-3">
                                    {{ blogSubtitle(topic.content,150) }}
                                </p>

                                <div class="flex items-center pt-3">
                                    <div class="flex items-center">
                                        <i class="fa fa-tags mr-2"></i>
                                        {{ topic.unique_page_views }}
                                    </div>
                                    <div class="flex items-center mx-4">
                                        <i class="fa fa-comment mr-2"></i>
                                        {{ topic.comments.length }}
                                        <div class="-space-x-2 flex items-center ml-1" v-html="lastThreeUserComment(topic.comments)">
                                        </div>
                                    </div>
                                    <!-- <div class="flex items-center">
                                        <ion-icon name="bookmark-outline" class="text-xl mr-2"></ion-icon>
                                        12
                                    </div> -->
                                </div>
                            </div>
                        </div>
                        <div v-if="blogLoading == 'false' && topics.length == 0" class="bg-white text-black p-5">
                            <h4>No Blog Post Yet</h4>
                        </div>
                    </div>
                </div>
                <div class="lg:w-1/4 w-full flex-shrink-0 removeSticky">
                    <div uk-sticky="offset:100" class="uk-sticky">
                        <h2 class="text-lg font-semibold mb-3 mt-3 lg:mt-0">Recently Posted</h2>
                        <ul class="card-mobile">
                            <li v-for="rt in recentTopics">
                                <router-link :to="'/dashboard/blog/'+rt.name_key" class="hover:bg-gray-100 rounded-md p-2 -mx-2 block">
                                    <h3 class="font-medium line-clamp-2 text-capitalize">
                                        {{ rt.title }}
                                    </h3>
                                    <div class="flex items-center my-auto text-xs space-x-1.5">
                                        <div>{{ formatDate(rt.created_at) }}</div>
                                        <div class="pb-1">.</div>
                                        <i class="fa fa-comment mr-2"></i>
                                        <div>{{ rt.comments.length }}</div>
                                    </div>
                                </router-link>
                            </li>
                        </ul>
                        <br />
                        <div v-if="blogLoading == 'false' && recentTopics.length == 0" class="bg-white text-black p-5">
                            <h4>No Recent Blog Post Yet</h4>
                        </div>
                           
                        <h4 class="text-lg font-semibold mb-3 mt-5"> Tags </h4>

                        <div class="card-mobile mb-10">
                            <div class="flex flex-wrap gap-2 mb-80">
                                <template v-for="c in postCategories">
                                    <a v-if="category == c" class="bg-blue-100 py-1.5 px-4 rounded-full mouse-pointer text-capitalize"> {{ c }}</a>
                                    <a @click="getBlogPostsByCategory(section,c)" v-else class="bg-gray-100 py-1.5 px-4 rounded-full mouse-pointer"> {{ c }}</a>
                                </template>
                                <br/><br/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <progress-loader v-if="blogLoading == 'true'"></progress-loader>
    <div id="progressLoader">
        <progress-loader></progress-loader>
    </div>

    <dashboard-widgets page="blogs"></dashboard-widgets>
</div>
</template>

<script>
import { ApiService } from '@/services/api.service'
import AuthStyles from '@/include/Auth/AuthStyles'
import Header from "@/include/Dashboard/Header.vue";
import Sidebar from "@/include/Dashboard/Sidebar.vue";
import DashboardWidgets from '@/components/DashboardWidgets'
import OpenChatBox from "@/include/Modals/OpenChatBox.vue";
import Feeds from "@/include/Dashboard/Feeds.vue";
import CustomIcon from '@/components/CustomIcon'
import ProgressLoader from '@/components/ProgressLoader'

export default {
    name: "Blogs",
    components: {
        Header,
        Sidebar,
        DashboardWidgets,
        OpenChatBox,
        Feeds,
        CustomIcon,
        ProgressLoader,
        AuthStyles
    },

    data() {
        return {
            topics: [],
            recentTopics: [],
            page: 0,
            pendingRequest: false,
            recentActive: 'active',
            trendingActive: '',
            oldestActive: '',
            userActive: '',
            postCategories: '',
            section: '',
            category: '',
            pagination: {
                limit: 20,
                offset: 0,
                total: 60,
            },
            pageNameMain: 'Blogs',
            pageDescriptionMain: '',
            pageImageMain: '',
            blogLoading: 'false',
        };
    },
    mounted: function () {
        this.getBlogPosts('recent')
        this.getRecentBlogPosts()
        this.getExtraInfo()
    },
    methods: {
        async getExtraInfo(){
            this.blogLoading = 'true'
            this.toggleProgress('show')
            let apiResponse = await ApiService.ExtraInfo();
            // console.log('getExtraInfo',apiResponse)
            if(apiResponse['status'] == 'success'){
                this.postCategories = apiResponse['data']['post_categories']
            }
            this.blogLoading = 'false'
            this.toggleProgress('hide')
        },
        async getBlogPosts(section,category) {
            this.blogLoading = 'true'
            if (this.pendingRequest == true) {
                // console.log("Pending Req");
                return;
            }
            this.toggleProgress('show')
            this.pendingRequest = true;
            let apiResponse = await ApiService.GetBlogPosts(this.page, section);
            if (apiResponse["status"] != "success") {} else {
                this.pendingRequest = false;
                this.page++;
                this.topics = apiResponse["data"];
            }
            this.blogLoading = 'false'
            this.toggleProgress('hide')
        },
        async getBlogPostsByCategory(section,category) {
            this.blogLoading = 'true'
            this.toggleProgress('show')
            this.page = 0
            // console.log('getBlogPostsByCategory',section,'-----',category)
            this.category = category
            let apiResponse = await ApiService.GetBlogPosts(this.page, section, category);
            if (apiResponse["status"] != "success") {} else {
                this.topics = apiResponse["data"];
            }
            this.blogLoading = 'false'
            this.toggleProgress('hide')
        },
        async getRecentBlogPosts() {
            this.blogLoading = 'true'
            this.toggleProgress('show')
            let apiResponse = await ApiService.GetBlogPosts(0, 'recent');
            if (apiResponse["status"] != "success") {} else {
                let recentTopics = apiResponse["data"];
                this.recentTopics = recentTopics.slice(0,5);
            }
            this.blogLoading = 'false'
            this.toggleProgress('hide')
        },
        async makeActiveSection(a) {
            this.recentActive = ''
            this.oldestActive = ''
            this.trendingActive = ''
            this.userActive = ''

            this.page = 0;
            this.section = a
            this.topics = []

            if(a == 'Recent'){
                this.recentActive = 'active'
                this.getBlogPosts('recent')
            }else if(a == 'Oldest'){
                this.oldestActive = 'active'
                this.getBlogPosts('oldest')
            }else if(a == 'Trending'){
                this.trendingActive = 'active'
                this.getBlogPosts('trending')
            }else if(a == 'User'){
                this.userActive = 'active'
                this.getBlogPosts('user')
            }
        }
    },
};
</script>

<style lang="scss" scoped>
.some-modal-content {
    min-width: 400px;
    padding: 25px;

    .buttons button {
        padding: 10px;
        margin: 10px;
    }
}
</style>
