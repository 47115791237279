<template>
<div class="space-y-4 mt-3" id="page_about" v-if="page">

    <div class="flex items-center space-x-3">
        <i name="people" class="fa fa-users bg-gray-100 p-2-old rounded-full text-xl md hydrated" role="img" aria-label="people"></i>
        <div class="flex-1">
            <div class="font-semibold">
                {{ page['followers_count'] }}
                <span v-if="page['followers_count'] != 1">Members</span>
                <span v-else>Member</span>
            </div>
        </div>
    </div>
    <div class="flex items-center space-x-3">
        <i name="unlink" class="fa fa-link bg-gray-100 p-1.5-old rounded-full text-xl md hydrated" role="img" aria-label="unlink"></i>
        <div class="flex-1">
            <div style="word-break: break-all"> <a :href="getPageLink(page['name_key'])" target="_blank" class="text-blue-500 page_public_link"> {{ getPageLink(page['name_key']) }} </a> </div>
        </div>
    </div>
    <div class="flex items-start space-x-3" v-if="page['description']">
        <i name="globe-outline" class="fa fa-globe bg-gray-100 p-2-old rounded-full text-xl md hydrated" role="img" aria-label="people"></i>
        <div class="flex-1">
            <div class=""> {{ page['description'] }} </div>
        </div>
    </div>
    <div class="flex items-center space-x-3" v-if="page['website']">
        <i name="unlink" class="fa fa-link bg-gray-100 p-1.5-old rounded-full text-xl md hydrated" role="img" aria-label="unlink"></i>
        <div class="flex-1">
            <div> <a id="" :href="page['website']" target="_blank" class="text-blue-500 page_website_link"> {{ page['website'] }} </a> </div>
        </div>
    </div>
    <div class="flex items-center space-x-3" v-if="page['hide_email'] != 'true' && page['email']">
        <i name="mail-open" class="fa fa-envelope bg-gray-100 p-1.5-old rounded-full text-xl md hydrated" role="img" aria-label="mail open"></i>
        <div class="flex-1">
            <div>
                <span class="badge badge-warning" v-if="page['hide_email'] == 'true'">hidden</span>
                <span class="badge badge-success" v-else>
                    <a :href="'mailto:'+page['email']" class="text-blue-500">{{ page['email'] }}</a>
                </span>
            </div>
        </div>
    </div>
    <div class="flex items-center space-x-3" v-if="page['category']">
        <i name="albums" class="fa fa-suitcase bg-gray-100 p-1.5-old rounded-full text-xl md hydrated"></i>
        <div class="flex-1">
            <div> <a href="javascript:;" class="text-blue-500">{{ page['category'] }}</a> </div>
        </div>
    </div>
    <div class="flex items-space-between space-x-3 text-center">

        <div class="flex-1" v-if="page['facebook']">
            <div>
                <a id="" target="_blank" :href="page['facebook']" class="text-blue-500 page_fb_link">
                    <i class="fab fa-facebook text-2xl"></i>
                </a>
            </div>
        </div>
        <div class="flex-1" v-if="page['twitter']">
            <div>
                <a id="" target="_blank" :href="page['twitter']" class="text-blue-500 page_tw_link">
                    <i class="fab fa-twitter text-2xl"></i>
                </a>
            </div>
        </div>
        <div class="flex-1" v-if="page['instagram']">
            <div>
                <a id="" target="_blank" :href="page['instagram']" class="text-blue-500 page_in_link">
                    <i class="fab fa-instagram text-2xl"></i>
                </a>
            </div>
        </div>
        <div class="flex-1" v-if="page['youtube']">
            <div>
                <a id="" target="_blank" :href="page['youtube']" class="text-blue-500 page_yt_link">
                    <i class="fab fa-youtube text-2xl"></i>
                </a>
            </div>
        </div>
    </div>

</div>
</template>

<script>
export default {
    components: {},
    props: {
        page: Object,
    },
    methods: {
        getPageLink(name_key) {
            return this.$store.getters["host/appUrl"]+'/page/'+name_key
        },
    }
}
</script>
