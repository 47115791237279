<template>
<div>
    <create-post v-if="currentUser" :name="createPostName"></create-post>
    <birthday-modal v-if="currentUser"></birthday-modal>
    <story-preview v-if="currentUser"></story-preview>
    <messenger-button v-if="currentUser"></messenger-button>
</div>
</template>

<script>
import CreatePost from '@/include/Modals/CreatePost'
import BirthdayModal from '@/include/Modals/BirthdayModal'
import StoryPreview from '@/include/Modals/StoryPreview'
import MessengerButton from '@/components/MessengerButton'

export default {
    name: 'DashboardWidget',
    components: {
        CreatePost,
        BirthdayModal,
        StoryPreview,
        MessengerButton,
    },
    props: {
        page: String,
        createPostName: String,
    },
    data: function () {
        return {
            currentUser: ''
        }
    },
    mounted() {
        this.currentUser = this.$store.getters['user/currentUser'] || {}
    },
    methods: {}
}
</script>

<style lang="scss" scoped>
</style>
