<template>
<!-- birthdays modal -->
<div id="fcm_permission" uk-modal="">
    <div class="uk-modal-dialog uk-modal-body rounded-xl shadow-lg">
        <!-- close button -->
        <button class="uk-modal-close-default p-2.5 bg-gray-100 rounded-full m-3" type="button" uk-close=""></button>

        
    <div id="allow-push-notification-bar">
        <div>
            <div>
                <h4>We would love send you Vitisocial updates in real time concerning your friends</h4>
            </div>
            <div class="row">
                <div class="col-sm-6">
                    <button>Yes</button>
                </div>
                <div class="col-sm-6">
                    <button>No</button>
                </div>
            </div>
        </div>
    </div>
    </div>
</div>
</template>

<script>
import {
    ApiService
} from '@/services/api.service'
export default {
    name: "FCMPermissionModal",
    data() {
        return {
        };
    },
    mounted: function () {
    },
    mounted() {
        this.currentUser = this.$store.getters["user/currentUser"] || '';
    },
    methods: {
        async getBirthdays() {
        },
    }
};
</script>

<style lang="scss" scoped></style>
