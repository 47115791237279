<template>
<div class="card md:p-6 p-2 max-w-3xl mx-auto">

    <div class="flex justify-between items-start relative md:mb-4 mb-3">
        <div class="flex-1">
            <h2 class="text-xl font-bold"> Groups </h2>
            <nav class="responsive-nav style-2 md:m-0 -mx-4">
                <ul>
                    <li class="active"><a href="javascript:;"> {{currentUser['name']}} Groups </a></li>
                </ul>
            </nav>
        </div>
        <!-- <a href="/dashboard/groups/create" data-tippy-placement="left" data-tippy="" data-original-title="Create group" class="bg-blue-100 font-semibold py-2 px-6 rounded-md text-sm md:mt-0 mt-3 text-blue-600">
            Create
        </a> -->
    </div>

    <div class="grid md:grid-cols-2 grid-cols-2 gap-x-2 gap-y-4 mt-3">
        <div v-if="groups.length == 0 && pendingRequest == false" class="bg-white p-3">
            <h5>{{ currentUser.name }} has no group yet</h5>
        </div>
        <div v-if="pendingRequest == true" class="text-center my-3">
            <i class="fa fa-spin fa-spinner fa-2x"></i>
        </div>

        <div v-for="group in groups" class="flex items-center flex-col md:flex-row justify-center p-4 rounded-md shadow hover:shadow-md md:space-x-4">
            <a :href="'/dashboard/group/'+group['id']" iv="" class="w-16 h-16 flex-shrink-0 overflow-hidden rounded-full relative">
                <img v-if="!group['image']" :src="defaultPostUrl()" class="absolute w-full h-full inset-0 " alt="">
                <img v-else :src="group['image']" class="absolute w-full h-full inset-0 " alt="">
            </a>
            <div class="flex-1">
                <a :href="'/dashboard/group/'+group['id']" class="text-base font-semibold capitalize">{{ group['name'] }}</a>
                <div class="text-sm text-gray-500">{{group.followers_count || 0}} Following </div>
            </div>
            <button v-if="group['user_follow']" class="bg-gray-100 font-semibold py-2 px-3 rounded-md text-sm md:mt-0 mt-3">
                Following
            </button>
            <button v-else @click="followGroup(group['id'])" class="bg-orange font-semibold py-2 px-3 rounded-md text-sm md:mt-0 mt-3 text-white">
                Follow
            </button>
        </div>

    </div>

    <div class="flex justify-center mt-6" v-if="groups.length > 0 && pendingRequest == false">
        <a href="javascript:;" @click="loadMoreGroups" class="bg-white dark:bg-gray-900 font-semibold my-3 px-6 py-2 rounded-full shadow-md dark:bg-gray-800 dark:text-white">
            Load more ..</a>
    </div>

    <div class="mt-5 pt-5">
        <nav class="responsive-nav style-2 md:m-0 -mx-4">
            <ul>
                <li class="active"><a href="javascript:;"> Group Suggestions </a></li>
            </ul>
        </nav>
        <div v-if="random_groups.length == 0 && groupsSuggestedLoading == false" class="bg-white p-3">
            <h5>No suggested groups yet</h5>
        </div>
        <div v-if="groupsSuggestedLoading == true" class="text-center my-3">
            <i class="fa fa-spin fa-spinner fa-2x"></i>
        </div>
    </div>
    <div class="grid md:grid-cols-2  grid-cols-2 gap-x-2 gap-y-4 mt-3">
        <div v-for="group in random_groups" class="flex items-center flex-col md:flex-row justify-center p-4 rounded-md shadow hover:shadow-md md:space-x-4">
            <a :href="'/dashboard/group/'+group['id']" iv="" class="w-16 h-16 flex-shrink-0 overflow-hidden rounded-full relative">
                <img v-if="!group['image']" :src="defaultPostUrl()" class="absolute w-full h-full inset-0 " alt="">
                <img v-else :src="group['image']" class="absolute w-full h-full inset-0 " alt="">
            </a>
            <div class="flex-1">
                <a :href="'/dashboard/group/'+group['id']" class="text-base font-semibold capitalize">{{ group['name'] }}</a>
                <div class="text-sm text-gray-500">{{group.followers_count || 0}} Following </div>
            </div>
            <button v-if="group['user_follow']" class="bg-gray-100 font-semibold py-2 px-3 rounded-md text-sm md:mt-0 mt-3">
                Following
            </button>
            <button v-else @click="followGroup(group['id'])" class="bg-orange font-semibold py-2 px-3 rounded-md text-sm md:mt-0 mt-3 text-white">
                Follow
            </button>
        </div>

    </div>

</div>
</template>

<script>
import {
    ApiService
} from '@/services/api.service'
export default {
    name: "GroupScreen",
    data() {
        return {
            groups: [],
            random_groups: [],
            total_groups: 0,
            page: 0,
            pendingRequest: false,
            groupsSuggestedLoading: false,
        };
    },
    props: {
        currentUser: Object,
    },
    components: {},
    mounted: function () {
        this.getGroups()
        this.getSuggestedGroups()
    },
    methods: {
        async getGroups() {
            if (this.pendingRequest == true) {
                // console.log('Pending Req')
                return
            }
            this.pendingRequest = true
            let apiResponse = await ApiService.GetOtherUserGroups('other_user', this.currentUser['id'])
            if (apiResponse['status'] != 'success') {} else {
                this.pendingRequest = false
                this.groups = apiResponse['data']['groups']
                this.total_groups = apiResponse['data']['total_groups']
                // console.log(this.groups)
            }
        },
        async loadMoreGroups() {
            this.page++
            this.getGroups()
        },
        async followGroup(id) {
            this.toggleProgress('show')
            let apiResponse = await ApiService.FollowGroup(id);
            this.toggleProgress('hide')
            this.$store.commit("toast/Notify", {
                type: apiResponse["status"],
                message: apiResponse["message"],
            });
            if (apiResponse["status"] != "success") {} else {
                setTimeout(() => {
                    location.href = ''
                }, 1500);
            }
        },
        async unfollowGroup() {
            let proceed = confirm("Are you sure you want to Unfollow this Group?");
            if (proceed) {
                this.toggleProgress('show')
                let apiResponse = await ApiService.UnfollowGroup(this.group['id']);
                this.toggleProgress('hide')
                this.$store.commit("toast/Notify", {
                    type: apiResponse["status"],
                    message: apiResponse["message"],
                });
                if (apiResponse["status"] != "success") {} else {
                    setTimeout(() => {
                        location.href = ''
                    }, 1500);
                }
            } else {
                //don't proceed
            }
        },
        async getSuggestedGroups() {
            this.groupsSuggestedLoading = true;
            this.toggleProgress('show')
            let apiResponse = await ApiService.GetGroups(0, 'random');
            if (apiResponse["status"] != "success") {} else {
                this.random_groups = apiResponse["data"]['groups'];
                this.groupsSuggestedLoading = false;
            }
            this.toggleProgress('hide')
        },
    }
};
</script>

<style lang="scss" scoped></style>
