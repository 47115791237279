<template>
<div id="verification-application" class="create-post is-story" uk-modal="">
    <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical rounded-lg p-0 lg:w-5/12 relative shadow-2xl uk-animation-slide-bottom-small" style="height: 90vh;overflow-y: scroll;">
        <div class="text-center py-3 border-b">
            <h3 class="text-lg font-semibold">Apply For verification</h3>
            <button class="uk-modal-close-default bg-gray-100 rounded-full p-2.5 right-2" type="button" uk-close="" uk-tooltip="title: Close ; pos: bottom ;offset:7"></button>
        </div>
        <div>
            <div class="items-start space-x-4 p-3">
                <div class="line-old">
                    <label class="mb-2">Email: </label>
                    <input readonly :value="email" disabled />
                </div>
                <div class="line-old ml-0 mt-3">
                    <label class="mb-2">Phone Number: </label>
                    <input :value="phone" />
                </div>
                <div class="line-old ml-0 mt-3">
                    <single-file-upload id="document_upload" type="image" @uploadSuccess="onImageUploaded">
                        <a href="JavaScript:;" class="mouse-pointer flex items-center justify-center h-8 px-3 rounded-md bg-gray-700 bg-opacity-70 text-white hover:text-white space-x-1.5">
                            <span> Upload Document </span>
                        </a>
                    </single-file-upload>
                </div>
                <div>
                    <img v-if="document" :src="document" style="height: 50px" />
                </div>
            </div>
            <div class="post-buttonsold">
                <div class="flex items-center w-full justify-between border-t px-3 py-2 p-sm-3 pb-3">

                    <div class="flex space-x-2 mb-5old">
                        <button v-if="show_button == false" style="padding: 21px;" class="bg-blue-600 flex h-9 items-center justify-center rounded-md text-white px-5 font-medium" disabled>
                            Apply
                        </button>
                        <button v-else @click="applyForverification()" style="padding: 21px;" class="bg-blue-600 flex h-9 items-center justify-center rounded-md text-white px-5 font-medium">
                            Apply
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    ApiService
} from "@/services/api.service";
import {
    FileUploadService
} from 'v-file-upload'
import SingleFileUpload from '@/components/SingleFileUpload'
import ProgressLoader from '@/components/ProgressLoader'
export default {
    name: "VerificationApplication",
    props: {
        name: String
    },
    data() {
        return {
            currentYear: new Date().getFullYear(),
            phone: "",
            email: "",
            document: "",
            loading: "",
            currentUser: "",
            show_button: false
        };
    },
    components: {
        SingleFileUpload
    },
    mounted: function () {
        this.appName = this.$APP_NAME;
        this.currentUser = this.$store.getters["user/currentUser"];
        if (this.currentUser) {
            this.profilePhoto = this.currentUser['profile_photo']
            this.userFullName = this.currentUser['name']
            this.email = this.currentUser['email']
            this.phone = this.currentUser['phone']
        }
    },
    methods: {
        async applyForverification() {
            this.toggleProgress('show')
            let apiResponse = await ApiService.ApplyForVerification(this.currentUser['id'], this.document, this.phone);
            // console.log('GetUser', apiResponse)
            this.$store.commit("toast/Notify", {
                type: apiResponse["status"],
                message: apiResponse["message"],
            });
            if (apiResponse['status'] == 'success') {
                setTimeout(() => {
                    location.href = ''
                }, 1200);
            }
            this.toggleProgress('hide')
        },
        onImageUploaded(response) {
            if (response['status'] == 'success') {
                this.document = response['data']['file_name']
            }
            this.$store.commit("toast/Notify", {
                type: response["status"],
                message: response["message"],
            });
            this.show_button = true
        },
    },
};
</script>

<style scoped>
.dropup .dropdown-toggle::after {
    display: none;
    height: 0;
    visibility: hidden;
}

@media (max-width: 520px) {
    .post-buttons {
        position: fixed;
        bottom: 0;
        max-width: 100%;
        width: 100%;
    }
}
</style>
<style>

#document_upload .upload-input {
    width: 100%;
}
.videoPreview>div>div {
    width: 100% !important;
}

.videoPreview .vjs-big-play-button {
    display: none !important;
}

#create-post-modal>div {
    max-height: 90vh;
    overflow-x: scroll;
}
</style>
