<template>
    <!-- header-->
    <div class="bg-white py-4 shadow dark:bg-gray-800">
        <div class="max-w-6xl mx-auto">


            <div class="flex items-center lg:justify-between justify-around">
                <a href="/">
                    <img src="/assets/img/vt_social_logo.png" alt="" class="w-32 logo_img">
                </a>

                <div class="capitalize flex font-semibold hidden lg:block my-2 space-x-3 text-center text-sm">
                    <router-link to="/login" class="py-3 px-4">
                        Login
                    </router-link>
                    <router-link to="/register" class="bg-purple-500 purple-500 px-6 py-3 rounded-md shadow text-white hover:text-white">
                        Register
                    </router-link>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AuthHeader',
    props: {
    },
    data: function() {
        return {
        }
    },
    methods: {
        setGlobalVariables(){
            
        }
    },
    beforeCreate: function() {
    }
}
</script>

<!-- <style lang="css" scoped>
    @import '/public/assets/css/icons.css';
    @import '/public/assets/css/uikit.css';
    @import '/public/assets/css/style.css';
    @import '/public/tailwindcss/dist/tailwind.min.css';
</style> -->

<style>
    input , .bootstrap-select.btn-group button, select, .special-input{
        background-color: #f3f4f6  !important;
        height: 44px  !important;
        box-shadow: none  !important; 
        padding: 0 20px;
    }
    .logo_img {
        height: 50px;
        width: auto;
    }
    .mouse-pointer {
        cursor: pointer;
    }
</style>
