<template>
<span style="width: 15px;">
    <img  :class="'verified_icon '+position_margin_top" style="display: inline-block;color: rgb(29, 155, 240);fill: currentcolor;padding: 0;height: 17px;width: 17px;" src="/assets/img/verified.svg" alt="VT" />
</span>
</template>

<script>
export default {
    name: 'VerifiedBadge',
    props: {
        color: String,
        size: String,
        mt: String,
    },
    data() {
        return {
            icon_size: 'normal',
            icon_position: '',
            position_margin_top: 'mt-normal',
        }
    },
    components: {},
    mounted() {
        // this.icon_size = 'small'
        // this.icon_position = 'position_absolute'
        if (this.size == 'small') {
            this.icon_size = 'small'
        }
        if (!this.size || this.size == 'normal') {
            this.icon_position = 'position_absolute'
        }
        if (this.mt == 'zero') {
            this.position_margin_top = 'mt-0'
        }
        if (this.mt == 'one') {
            this.position_margin_top = 'mt-1'
        }
        if (this.mt == 'two') {
            this.position_margin_top = 'mt-2'
        }
        if (this.mt == 'minusZeroPointFive') {
            this.position_margin_top = '-mt-0.5'
        }
    },
    methods: {}
}
</script>

<style>
.position_absolute {
    position: absolute
}

.verified_iconold {
    background: #2563eb !important;
    color: #fff;
    border-radius: 30px;
}

.normal {
    font-size: 15px;
    padding: 5px 6px;
    margin-top: 0;
}

.small {
    font-size: 8px;
    margin-top: 0;
    padding: 3px 4px;
}

.verified_star {
    height: 20px !important;
    width: auto !important;
    display: inline-block;
}

.mt-normal {
    margin-top: -4px;
}

.verified_icon {
    height: 15px;
    margin-bottom: 3px;
}

@media (max-width: 540px) {
    .verified_icon {
        height: 15px;
        margin-bottom: 3px;
    }
}
</style>
