<template>
<div class="mb-3">
    <a class="page-numbers" style="color:#25D366" :href="'https://wa.me/?text='+link" target="_blank" data-action="share/whatsapp/share">
        <i class="fab fa-whatsapp"></i> Share
    </a>
    <a class="page-numbers" style="color:#3b5998" :href="'https://www.facebook.com/sharer/sharer.php?u='+link" target="_blank">
        <i class="fab fa-facebook"></i> Share
    </a>
    <a class="page-numbers" style="color:#00acee" :href="'https://twitter.com/intent/tweet?url='+link" target="_blank">
        <i class="fab fa-twitter"></i> Tweet
    </a>
</div>
</template>

<script>
export default {
    name: 'ShareIcons',
    props: {
        link: String,
    },
    data: function () {
        return {
            widget: ''
        }
    },
    mounted() {
        let name = this.name
    },
    methods: {}
}
</script>

<style lang="scss" scoped>
</style>
