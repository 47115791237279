<template>
    <div class="text-xl mr-2 md hydrated">
        <div class="icon-inner" v-html="widget">
        </div>
    </div>
</template>

<script>
export default {
    name: 'CustomIcon',
    props: {
        name: String,
    },
    data: function () {
        return {
            widget: ''
        }
    },
    mounted() {
        let name = this.name
        switch (name) {
            case "thumbs":
                    this.widget = '<svg xmlns="http://www.w3.org/2000/svg" class="ionicon s-ion-icon" viewBox="0 0 512 512"><title>Thumbs Up</title><path d="M320 458.16S304 464 256 464s-74-16-96-32H96a64 64 0 01-64-64v-48a64 64 0 0164-64h30a32.34 32.34 0 0027.37-15.4S162 221.81 188 176.78 264 64 272 48c29 0 43 22 34 47.71-10.28 29.39-23.71 54.38-27.46 87.09-.54 4.78 3.14 12 7.95 12L416 205" stroke-linecap="round" stroke-linejoin="round" class="ionicon-fill-none ionicon-stroke-width"></path><path d="M416 271l-80-2c-20-1.84-32-12.4-32-30h0c0-17.6 14-28.84 32-30l80-4c17.6 0 32 16.4 32 34v.17A32 32 0 01416 271zM448 336l-112-2c-18-.84-32-12.41-32-30h0c0-17.61 14-28.86 32-30l112-2a32.1 32.1 0 0132 32h0a32.1 32.1 0 01-32 32zM400 464l-64-3c-21-1.84-32-11.4-32-29h0c0-17.6 14.4-30 32-30l64-2a32.09 32.09 0 0132 32h0a32.09 32.09 0 01-32 32zM432 400l-96-2c-19-.84-32-12.4-32-30h0c0-17.6 13-28.84 32-30l96-2a32.09 32.09 0 0132 32h0a32.09 32.09 0 01-32 32z" stroke-miterlimit="10" class="ionicon-fill-none ionicon-stroke-width"></path></svg>'
                break;
        
            default:
                break;
        }
    },
    methods: {
        toggleContent() {
            // console.log('this.name', this.name)
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
