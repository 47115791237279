<template>
<div id="wrapper">
    <auth-styles :pageNameMain="pageNameMain" :pageDescriptionMain="pageDescriptionMain" :pageImageMain="pageImageMain"></auth-styles>
    <Header />

    <sidebar></sidebar>

    <!-- Main Contents -->
    <div class="main_content">
        <div class="mcontainer">

            <div class="flex justify-between items-center relative md:mb-4 mb-3">
                <div class="flex-1">
                    <h2 class="text-2xl font-semibold"> Checkout items </h2>
                    <nav class="responsive-nav border-b md:m-0 -mx-4">
                    </nav>
                </div>
                <a href="/dashboard/products" uk-toggle="" class="flex items-center justify-center h-8 lg:px-3 px-2 rounded-md bg-blue-600 text-white space-x-1 absolute right-0 z-10">
                    <span class="md:block hidden"> Products </span>
                </a>

            </div>

            <div class="sm:my-6 my-3 flex items-center justify-between">
                <div class="header_dropdown dropdown_cart d-block" style="width: 100%; padding: 10px !important">
                    <ul class="dropdown_cart_scrollbar" data-simplebar="">
                        <li v-for="c in cart">
                            <div class="cart_avatar">
                                <img v-if="c.image" :src="c.image" alt="" />
                                <img v-else :src="defaultPostUrl()" alt="" />
                            </div>
                            <div class="cart_text">
                                <div class="font-semibold leading-4 mb-1.5 text-base line-clamp-1">
                                    {{ c.name }}
                                </div>
                                <p class="text-sm">Type {{ c.category }}</p>
                            </div>
                            <div class="cart_price">
                                <span> {{ currencySymbol(c.currency) }}{{ formatAmount(c.amount*c.item_number) }} </span>
                                <button @click="removeCartItem(c.id)" class="type">Remove</button>
                            </div>
                        </li>
                    </ul>

                    <div class="cart_footer">
                        <p>Subtotal : NGN {{ getCartSubTotal(cart) }}</p>
                        <h1>Total : <strong> NGN {{ getCartTotal(cart) }}</strong></h1>
                    </div>
                    <div class="" v-if="amount > 0">
                        <pay-with-flutterwave title="Place Order" :email="email" :phone="phone" :name="name" :amount="amount" :currency="currency" :description="description" :productId="productId" @paymentCallback="paymentCallback" ></pay-with-flutterwave>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <div id="progressLoader">
        <progress-loader></progress-loader>
    </div>

    <dashboard-widgets page="checkout"></dashboard-widgets>
</div>
</template>

<script>
import {
    ApiService
} from '@/services/api.service'
import AuthStyles from '@/include/Auth/AuthStyles'
import Header from "@/include/Dashboard/Header.vue";
import Sidebar from "@/include/Dashboard/Sidebar.vue";
import DashboardWidgets from '@/components/DashboardWidgets'
import OpenChatBox from "@/include/Modals/OpenChatBox.vue";
import Feeds from "@/include/Dashboard/Feeds.vue";
import CustomIcon from '@/components/CustomIcon'
import ProgressLoader from '@/components/ProgressLoader'
import PayWithFlutterwave from '@/components/PayWithFlutterwave'

export default {
    name: "Checkout",
    components: {
        Header,
        Sidebar,
        DashboardWidgets,
        OpenChatBox,
        Feeds,
        CustomIcon,
        ProgressLoader,
        AuthStyles,
        PayWithFlutterwave
    },

    data() {
        return {
            cart: [],
            pagination: {
                limit: 20,
                offset: 0,
                total: 60,
            },
            pageNameMain: 'Checkout',
            pageDescriptionMain: '',
            pageImageMain: '',
            email: '',
            phone: '',
            name: '',
            amount: '',
            currency: '',
            description: '',
            productId: '',
            currentUser: '',
        };
    },
    mounted: function () {
        this.currentUser = this.$store.getters['user/currentUser'] || {}
        this.getUserProductCarts()
    },
    methods: {
        async getUserProductCarts() {
            this.toggleProgress('show')
            let apiResponse = await ApiService.GetUserProductCarts();
            if (apiResponse["status"] != "success") {} else {
                this.cart = apiResponse["data"];
                this.setPaymentData()
            }
            this.toggleProgress('hide')
        },
        async paymentCallback(response) {
            // console.log("RESPONSE",response)
            let txRef = response['tx']['txRef'];
            if(txRef){
                this.toggleProgress('show')
                let apiResponse = await ApiService.CreateOrderFromCart(txRef);
                this.$store.commit("toast/Notify", {
                    type: apiResponse["status"],
                    message: apiResponse["message"],
                });
                if (apiResponse["status"] != "success") {} else {
                    setTimeout(() => {
                        location.href = '/dashboard/products'
                    }, 2000);
                }
                this.toggleProgress('hide')
            }else{
                this.$toastr.w('Warning', 'Transaction reference could not be found')
            }
        },
        async setPaymentData() {
            let totalAmount = parseFloat(this.getCartTotal(this.cart, false))
            let cart = this.cart

            this.email = this.currentUser['email']
            this.phone = this.currentUser['phone']
            this.name = this.currentUser['name']
            this.description = 'Product payment'
            this.currency = 'NGN'
            this.amount = totalAmount
            this.productId = this.cart[0]['product_id']
        },
        async removeCartItem(id) {
            this.toggleProgress('show')
            let apiResponse = await ApiService.RemoveProductFromCart(id);
            if (apiResponse["status"] != "success") {} else {
                this.getUserProductCarts()
            }
            this.toggleProgress('hide')
        },
        async openSectionPage(section, section_id) {
            location.href = '/dashboard/products/' + section + '/' + section_id
        },
        async openProductPage(id) {
            location.href = '/dashboard/product/' + id
        }
    },
};
</script>

<style lang="scss" scoped>
.some-modal-content {
    min-width: 400px;
    padding: 25px;

    .buttons button {
        padding: 10px;
        margin: 10px;
    }
}
</style>
