<template>
    <div id="wrapper" class="flex flex-col justify-between h-screen">
        <auth-styles pageNameMain="Register"></auth-styles>
        <auth-header></auth-header>

        <!-- Content-->
        <div>
            <div class="lg:p-12 max-w-xl lg:my-0 my-12 mx-auto p-6 space-y-5">
                <form
                class="lg:p-10 p-6 space-y-3 relative bg-white shadow-xl rounded-md"
                @submit.prevent="makeRegister"
                >
                <h1 class="lg:text-2xl text-xl font-semibold mb-6">Register</h1>

                <div class="grid lg:grid-cols-2 gap-3">
                    <div>
                        <label class="mb-0"> First Name </label>
                        <input
                            required
                            v-model="first_name"
                            type="text"
                            placeholder="Your Name"
                            class="bg-gray-100 h-12 mt-2 px-3 rounded-md w-full"
                        />
                    </div>
                    <div>
                        <label class="mb-0"> Last Name </label>
                        <input
                            required
                            v-model="last_name"
                            type="text"
                            placeholder="Last  Name"
                            class="bg-gray-100 h-12 mt-2 px-3 rounded-md w-full"
                        />
                    </div>
                </div>
                <div >
                    <label class="mb-0"> Username </label>
                    <input
                    required
                    v-model="username"
                    type="text"
                    placeholder="Username"
                    class="bg-gray-100 h-12 mt-2 px-3 rounded-md w-full"
                    />
                </div>
                <div>
                    <label class="mb-0"> Email Address </label>
                    <input
                    required
                    v-model="email"
                    type="email"
                    placeholder="Info@example.com"
                    class="bg-gray-100 h-12 mt-2 px-3 rounded-md w-full special-input"
                    />
                </div>
                <div>
                    <label class="mb-0"> Password </label>
                    <input
                    required
                    v-model="password"
                    type="password"
                    placeholder="**********"
                    class="bg-gray-100 h-12 mt-2 px-3 rounded-md w-full special-input"
                    />
                </div>
                <div>
                    <label class="mb-0"> Repeat Password </label>
                    <input
                    required
                    v-model="repeat_password"
                    type="password"
                    placeholder="**********"
                    class="bg-gray-100 h-12 mt-2 px-3 rounded-md w-full"
                    />
                </div>
                <!-- <div>
                    <label class="mb-0"> Phone: </label>
                    <input
                    v-model="phone"
                    required
                    type="phone"
                    placeholder="+543 5445 0543"
                    class="bg-gray-100 h-12 mt-2 px-3 rounded-md w-full"
                    />
                </div>
                <div class="old-grid lg:grid-cols-2 old-gap-3">
                    <div class="mb-3">
                        <label class="mb-0"> Gender </label>
                        <select required class="mt-2" v-model="gender">
                            <option value="">Select gender</option>
                            <option>Male</option>
                            <option>Female</option>
                        </select>
                    </div>
                    <div class="">
                        <label class="mb-1"> Date of birth: </label>
                        <DobSelectorComponent @setDate="setDob"></DobSelectorComponent>
                    </div>
                </div>
                <div class="grid lg:grid-cols-2 gap-3">
                    <div>
                    <label class="mb-0"> Country </label>
                    <country-select
                        :countryName="countryName"
                        v-model="country"
                        :country="country"
                        topCountry="Nigeria"
                    />
                    </div>
                    <div>
                    <label class="mb-0"> States: </label>
                    <region-select
                        :countryName="countryName"
                        :regionName="regionName"
                        v-model="state"
                        :country="country"
                        :region="state"
                    />
                    </div>
                </div> -->
                <!-- <div>
                    <label class="mb-0"> Referral(Optional): </label>
                    <input
                    v-model="referral"
                    required
                    type="text"
                    placeholder="Email of referral"
                    class="bg-gray-100 h-12 mt-2 px-3 rounded-md w-full"
                    />
                </div> -->

                <div class="checkbox">
                    <input required type="checkbox" id="chekcbox1" v-model="terms" />
                    <label for="chekcbox1"
                    ><span class="checkbox-icon"></span> I agree to the
                    <a
                        href="/terms-and-conditions"
                        target="_blank"
                        class="uk-text-bold uk-text-small uk-link-reset"
                    >
                        Terms and Conditions
                    </a>
                    </label>
                </div>

                <div>
                    <p>
                    have an account? <router-link to="/login">Click here to login</router-link>
                    </p>
                </div>

                <div>
                    <button
                    @click="makeRegister"
                    type="button"
                    class="bg-blue-600 font-semibold p-4 mt-5 rounded-md text-center text-white w-full"
                    >
                    Get Started
                    </button>
                </div>
                </form>
            </div>
        </div>

        <auth-scripts></auth-scripts>
    </div>
</template>

<script>
import { ApiService } from '@/services/api.service'
import AuthStyles from "../../include/Auth/AuthStyles";
import AuthScripts from "../../include/Auth/AuthScripts";
import birthDatepicker from "vue-birth-datepicker/src/birth-datepicker";
import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import AuthHeader from '@/include/Auth/AuthHeader'
import Datepicker from 'vuejs-datepicker';
import DobSelectorComponent from '@/components/DobSelectorComponent'

export default {
    name: "RegisterPage",
    setup () {
        return { v$: useVuelidate() }
    },
    data() {
        return {
            id: "",
            email: "",
            password: "",
            repeat_password: "",
            first_name: "",
            last_name: "",
            username: "",
            gender: "",
            phone: "",
            date_of_birth: "",
            country: "",
            state: "",
            referral: "",
            terms: "",
            error: "",
            countryName: true,
            regionName: true,
        };
    },
    validations () {
        return {
            email: { required, email },
            password: { required },
            first_name: { required },
            last_name: { required },
            username: { required },
            gender: { required },
            phone: { required },
            date_of_birth: { required },
            country: { required },
            state: { required },
        }
    },
    components: {
        birthDatepicker,
        Datepicker,
        AuthStyles,
        AuthScripts,
        AuthHeader,
        DobSelectorComponent,
    },
    mounted(){
        let id = this.$route.params['id']
        this.id = id
        if(id){
            this.findUserEmail()
        }
        let accessToken = this.$store.getters['auth/accessToken']
        if(accessToken){
            location.href = '/dashboard/feeds'
        }
    },
    methods: {
        async makeRegister() {
            if (!this.email) {
                return this.$store.commit("toast/Notify", {
                type: "warning",
                message: "email is required",
                });
            }
            if (!this.password) {
                return this.$store.commit("toast/Notify", {
                type: "warning",
                message: "password is required",
                });
            }
            if (!this.terms) {
                return this.$store.commit("toast/Notify", {
                type: "warning",
                message: "please check the terms and conditions box",
                });
            }
            $('button').attr('disabled', true)
            let apiResponse = await ApiService.RegisterUser(
                this.email,
                this.password,
                this.repeat_password,
                this.first_name,
                this.last_name,
                this.username,
                this.gender,
                this.phone,
                this.date_of_birth,
                this.country,
                this.state,
                this.referral
            );
            // console.log(apiResponse);
            this.$store.commit("toast/Notify", {
                type: apiResponse["status"],
                message: apiResponse["message"],
            });
            if (apiResponse["status"] != "success") {
            }else{
                this.$router.push("/register-success");
            }
            $('button').attr('disabled', false)
        },
        async findUserEmail() {
            if (!this.id) {
                return this.$store.commit("toast/Notify", {
                type: "warning",
                message: "id is required",
                });
            }
            let apiResponse = await ApiService.FindUserEmail(
                this.id
            );
            if (apiResponse["status"] != "success") {
                this.$store.commit("toast/Notify", {
                    type: apiResponse["status"],
                    message: apiResponse["message"],
                });
            }else{
                this.referral = apiResponse['data']['email']
            }
        },
        async setDob(a){
            // console.log(":::::::::",a)
            this.date_of_birth = a
        }
    },
};
</script>

<style lang="scss" scoped>
</style>
