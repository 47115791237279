<template>
<!-- Share post modal -->
<div id="share-post-modal" class="create-post is-story" uk-modal="">
    <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical rounded-lg p-0 lg:w-5/12 relative shadow-2xl uk-animation-slide-bottom-small">
        <div class="text-center py-3 border-b">
            <h3 class="text-lg font-semibold">Share</h3>
            <button class="uk-modal-close-default bg-gray-100 rounded-full p-2.5 right-2" type="button" uk-close="" uk-tooltip="title: Close ; pos: bottom ;offset:7"></button>
        </div>
        <div class="bsolute bottom-0 p-2 space-x-4 w-full mb-5">
          <input id="full_url" v-model="link" readonly="readonly" disabled="disabled">
          <p class="text-right">
              <a href="javascript:;" @click="copyToClipboard('#full_url')">copy</a>
          </p>
            <div class="flex bg-gray-50-old border-old border-purple-100-old rounded-2xl p-2 shadow-sm-old items-center">
                <div class="flex flex-1 items-center lg:justify-center justify-center space-x-2old">
                    <a class="md:mr-5 p-2" target="_blank" :href="'https://www.facebook.com/sharer/sharer.php?u='+link" uk-tooltip="Facebook post">
                        <i class="fa-brands fa-facebook fa-2x"></i>
                    </a>
                    <a class="md:mr-5 p-2" target="_blank" :href="'https://wa.me/?text='+link" uk-tooltip="Whatsapp message">
                        <i class="fa-brands fa-whatsapp fa-2x"></i>
                    </a>
                    <a class="md:mr-5 p-2" target="_blank" :href="'https://twitter.com/intent/tweet?url='+link" uk-tooltip="Twitter post">
                        <i class="fa-brands fa-twitter fa-2x"></i>
                    </a>
                    <a class="md:mr-5 p-2" href="Javascript:;" @click="copyToClipboard('#full_url')" uk-tooltip="copy url to clipboard">
                        <i class="fa-solid fa-copy fa-2x"></i>
                    </a>
                    <a class="md:mr-5 p-2 mouse-pointer" v-if="link.search('feed/') > -1" uk-toggle="target: #share-feed-post-modal" @click="shareOnVitisocial" uk-tooltip="Vitisocial feed">
                        <img src="/assets/img/icon.png" style="height:25px" />
                    </a>
                </div>
            </div>
        </div>
    </div>
    <share-feed-post></share-feed-post>
</div>
</template>

<script>
import ShareFeedPost from '@/include/Modals/ShareFeedPost'
export default {
    name: "SharePostModal",
    props: {
    },
    data() {
        return {
            currentYear: new Date().getFullYear(),
            link: '',
            description: '',
            feed: ''
        };
    },
    mounted () {
        this.$root.$on("open-share-feed-modal",(data) => {
            console.log("open-share-feed-modal", data)
            if(data){
                if(data['link']){
                    this.link = data['link']
                    this.description = data['description']
                    this.feed = data['feed']
                }
            }
        })
    },
    components: {
        ShareFeedPost
    },
    methods: {
        async open() {},
        closeModal() {
            $('#create-post-modal').removeClass('uk-open').hide();
        },
        shareOnVitisocial() {
            this.$root.$emit("open-share-feed-post-modal", this.feed)
        }
    },
};
</script>

<style lang="scss" scoped></style>
