<template>
<div id="wrapper">
    <auth-styles pageNameMain="Dashboard"></auth-styles>

    <Header />

    <sidebar></sidebar>

    <!-- Main Contents -->
    <div class="main_content">
        <div class="mcontainer">

            <!--  Feeds  -->
            <div class="lg:flex lg:space-x-10">
                <div class="lg:w-full lg:px-20 space-y-7 mb-5">
                    <feeds></feeds>
                </div>
                <div class="">
                    <div class="uk-sticky bg-white rounded-md" uk-sticky="offset:20" style="">
    
                        <nav class="responsive-nav border-b extanded px-3 py-4">
                            <ul uk-switcher="connect: #group-details; animation: uk-animation-fade">
                                <li class="uk-active" >
                                    #{{ hash_tag }}
                                </li>
                            </ul>
                        </nav>
    
                    </div>
                </div>
            </div>

        </div>
    </div>

    <dashboard-widgets></dashboard-widgets>

</div>
</template>

<script>
import {
    ApiService
} from "@/services/api.service";
import Header from '@/include/Dashboard/Header.vue'
import Sidebar from '@/include/Dashboard/Sidebar.vue'
import DashboardWidgets from '@/components/DashboardWidgets'
import OpenChatBox from '@/include/Modals/OpenChatBox.vue'
import Feeds from '@/include/Dashboard/Feeds.vue'
import AuthStyles from '@/include/Auth/AuthStyles'
import CreatePostComponent from '@/components/CreatePostComponent'
import BirthdayContactsCardComponent from '@/components/BirthdayContactsCardComponent'
import GoogleBannerAd from '@/include/Dashboard/Feed/GoogleBannerAd'
import VerifiedBadge from '@/components/VerifiedBadge'

export default {
    name: 'FeedHashTags',
    components: {
        Header,
        Sidebar,
        DashboardWidgets,
        OpenChatBox,
        Feeds,
        AuthStyles,
        CreatePostComponent,
        BirthdayContactsCardComponent,
        GoogleBannerAd,
        VerifiedBadge,
    },

    data() {
        return {
            msg: '',
            isShownModal: false,
            inputError: false,
            checkboxState: false,
            storyLoading: false,
            saving_feed: 'false',
            currentUser: '',
            hash_tag: '',
            stories: [],
            my_story: [],
            current_story: {},
            pagination: {
                limit: 20,
                offset: 0,
                total: 60
            },
            googleAd: '',
        }
    },
    mounted: function () {
        let currentUser = this.$store.getters["user/currentUser"] || "";
        this.currentUser = currentUser;

        let vueThis = this

        let id = this.$route.params['id']
        this.hash_tag = id
    },

    methods: {}
}
</script>

<style lang="scss" scoped>
.some-modal-content {
    min-width: 400px;
    padding: 25px;

    .buttons button {
        padding: 10px;
        margin: 10px;
    }
}

.single_story_div {
    width: 100%;
    height: 100%;
    position: absolute;
    padding: 10px;
    color: #fff;
    background-color: #333;
}

input[type=text] {
    -webkit-transition: all .2s ease-out;
    -moz-transition: all .2s ease-out;
    -ms-transition: all .2s ease-out;
    -o-transition: all .2s ease-out;
    transition: all .2s ease-out
}

input[type=text]:focus {
    -webkit-transition: all .2s ease-out;
    -moz-transition: all .2s ease-out;
    -ms-transition: all .2s ease-out;
    -o-transition: all .2s ease-out;
    transition: all .2s ease-out
}

@media (max-width: 768px) {

    .user_story a:nth-child(3),
    .user_story a:nth-child(4) {
        display: block;
    }
}
</style>
