<template>
<div class="px-4 py-2 space-y-3" v-if="thisFeed" :key="userFeedCommentKey" :key_id="userFeedCommentKey">
    <div class="md:flex items-center space-x-3old py-2 justify-between comment_stat_old">
        <div class="dark:text-gray-100 d-flex mouse-pointer mb-3" v-if="thisFeed.likes > 0" @click="openLikersModal" uk-toggle-old="target: #feed-likers-modal">
            <span class="d-flex comment_box_reaction_png mr-1" :uk-tooltip="thisFeed.likes" v-html="userReactionsIcon(thisFeed, 'feed')"></span>
            <template v-if="thisFeed.last_like && thisFeed.last_like.user && thisFeed.last_like.user.name">
                <span :id="'feed_reaction_text_'+thisFeed.id">
                    <strong v-if="thisFeed.last_like.user.name">
                        <a class="text-gray-700 dark:text-gray-100" :href="'/dashboard/timeline/'+thisFeed.last_like.user.id" target="_blank">
                            {{ thisFeed.last_like.user.name }}
                        </a>
                    </strong>
                    <span class="ml-1" v-if="thisFeed.likes == 2"> and 1 Other</span>
                    <span class="ml-1" v-if="thisFeed.likes > 2"> and {{ thisFeed.likes-1 }} Others</span>
                </span>
            </template>
            <strong v-else>
                {{ thisFeed.likes }}
                <template v-if="thisFeed.likes > 1">People</template>
                <template v-else>Person</template>
            </strong>
        </div>
        <div class="dark:text-gray-100 mb-3" v-else>
            No <strong> Like</strong> <strong> yet </strong>
        </div>
        <div class="flex">
            <template v-if="thisFeed.shares_count > 0">
                <div class="mouse-pointer" uk-tooltip="Shares">
                    {{ shortenNumber(thisFeed.shares_count) }}
                    <span>
                        <i class="fa fa-share-nodes"></i>
                    </span>
                    <!-- <span v-if="thisFeed.shares_count > 1">shares</span>
                    <span v-else>share</span> -->
                </div>
                <span class="mx-1">
                    <i class="fa fa-circle" style="font-size: 5px;"></i>
                </span>
            </template>
            <template v-if="thisFeed.views > 0">
                <div class="mouse-pointer" uk-tooltip="Views">
                    {{ shortenNumber(thisFeed.views) }}
                    <span>
                        <i class="fa fa-chart-simple"></i>
                    </span>
                    <!-- <span v-if="thisFeed.views > 1">views</span>
                    <span v-else>view</span> -->
                </div>
                <span class="mx-1">
                    <i class="fa fa-circle" style="font-size: 5px;"></i>
                </span>
            </template>
            <div @click="toggleCommentSection(thisFeed.id)" class="mouse-pointer" uk-tooltip="Comments">
                {{ shortenNumber(thisFeedCommentsLength) }}
                <span>
                    <i class="fa fa-comments"></i>
                </span>
                <span v-if="thisFeed.allow_donation == 'true'" class="mx-1">
                    <i class="fa fa-circle" style="font-size: 5px;"></i>
                </span>
            </div>
            <div v-if="thisFeed.allow_donation == 'true'" @click="viewFeedDonate(thisFeed.id)" class="mouse-pointer" uk-tooltip="Donate">
                <span>
                    <i class="fa fa-donate"></i>
                </span>
                <span class="ml-2">Donate</span>
            </div>
        </div>
    </div>

    <div class="flex space-x-4 lg:font-bold">
        <template v-if="thisFeed.user_like == '0'">
            <a @click="togglePopover('reaction_holder_'+thisFeed.id)" class="mouse-pointer text-black text-black-hover flex items-center space-x-2" clickon="likeFeed" href="javascript:;">
                <div class="p-2 rounded-full  text-black">
                    <i class="icon-feather-thumbs-up text-black" style="padding: 4px 1px;color:#fff"></i>
                </div>
                <div> Like</div>
            </a>
            <div :id="'reaction_holder_'+thisFeed.id" @click="togglePopover('reaction_holder_'+thisFeed.id)" class="bg-white comment_reaction_holder" style="width: 95%;max-width: 90vw;" uk-drop-old="mode: hover,click;shift: true; flip: true;pos: bottom-right;animation: uk-animation-slide-bottom-small;boundary:!.main_content">
                <div class="row">
                    <div class="col py-3 d-flex">
                        <a @click="likeFeed('like')" uk-tooltip="like" href="javascript:;" class="p-1 mr-1">
                            <img class="icon_gif" src="/assets/img/reaction/gif/like.gif" />
                        </a>
                        <a @click="likeFeed('love')" uk-tooltip="love" href="javascript:;" class="p-1 mr-1">
                            <img class="icon_gif" src="/assets/img/reaction/gif/love.gif" />
                        </a>
                        <a @click="likeFeed('care')" uk-tooltip="care" href="javascript:;" class="p-1 mr-1">
                            <img class="icon_gif" src="/assets/img/reaction/gif/care.svg" />
                        </a>
                        <a @click="likeFeed('laugh')" uk-tooltip="laugh" href="javascript:;" class="p-1 mr-1">
                            <img class="icon_gif" src="/assets/img/reaction/gif/haha.gif" />
                        </a>
                        <a @click="likeFeed('surprise')" uk-tooltip="surprise" href="javascript:;" class="p-1 mr-1">
                            <img class="icon_gif" src="/assets/img/reaction/gif/wow.gif" />
                        </a>
                        <a @click="likeFeed('sad')" uk-tooltip="sad" href="javascript:;" class="p-1 mr-1">
                            <img class="icon_gif" src="/assets/img/reaction/gif/sad.gif" />
                        </a>
                        <a @click="likeFeed('angry')" uk-tooltip="angry" href="javascript:;" class="p-1 mr-1">
                            <img class="icon_gif" src="/assets/img/reaction/gif/angry.gif" />
                        </a>
                    </div>
                </div>
            </div>
        </template>
        <a v-else class="mouse-pointer text-black text-black-hover flex items-center space-x-2 text-orange" @click="unlikeFeed" href="javascript:;">
            <div class="text-orange flex user_reaction_holder" v-html="userReactionsIcon(thisFeed, 'feed_reaction', 'big')"></div>
            
        </a>
        <a v-if="thisFeed.comment_disabled == 'true'" class="mouse-pointer text-black text-black-hover flex items-center space-x-2" href="javascript:;">
            <div class="p-2 rounded-full  text-black bg-orange">
                <svg xmlns="http://www.w3.org/2000/svg" viewbox="0 0 20 20" fill="currentColor" width="22" height="22" class="dark:text-gray-100">
                    <path fill-rule="evenodd" d="M18 5v8a2 2 0 01-2 2h-5l-5 4v-4H4a2 2 0 01-2-2V5a2 2 0 012-2h12a2 2 0 012 2zM7 8H5v2h2V8zm2 0h2v2H9V8zm6 0h-2v2h2V8z" clip-rule="evenodd"></path>
                </svg>
            </div>
            <div> Comment Disabled</div>
        </a>
        <a v-else class="mouse-pointer text-black text-black-hover flex items-center space-x-2" @click="toggleCommentSection(thisFeed.id)" href="javascript:;">
            <div class="p-2 rounded-full  text-black">
                <i class="icon-feather-message-square text-black" style="padding: 4px 1px;color:#fff"></i>
            </div>
            <div> Comment</div>
        </a>
        <a class="mouse-pointer text-black text-black-hover flex items-center space-x-2 flex-1 justify-end" uk-toggle="target: #share-post-modal" @click="setCurrentLink(thisFeed.id, '')">
            <div class="p-2 rounded-full  text-black">
                <i class="icon-feather-share-2 text-black" style="padding: 4px 1px;color:#fff"></i>
            </div>
            <div> Share</div>
        </a>
    </div>

    <div v-if="thisFeed && thisFeed.comments.length > 0" :class="'border-t py-4 space-y-4 dark:border-gray-600 feed-comments-'+thisFeed.id" :style="'display:'+commentInitialDisplay">
        <template v-for="thisComment in thisFeed.comments">
            <div class="flex" :id="'comment_'+thisComment.id">
                <div uk-lightbox="" class="w-10 h-10 rounded-full relative flex-shrink-0">
                    <template v-if="thisComment.post_as == 'page'">
                        <profile-icon :url="thisFeed.page.image"></profile-icon>
                    </template>
                    <template v-else-if="thisComment.post_as == 'group'">
                        <profile-icon :url="thisFeed.group.image"></profile-icon>
                    </template>
                    <template v-else>
                        <profile-icon v-if="thisComment.user" :url="thisComment.user.profile_photo"></profile-icon>
                    </template>
                </div>
                <div>
                    <div class="text-gray-700 py-2 px-3 rounded-md bg-gray-100 relative lg:ml-5 ml-2 lg:mr-3  dark:bg-gray-800 dark:text-gray-100">
                        <template v-if="thisComment.post_as == 'page'">
                            <h4 class="text-base m-0 font-semibold" v-if="thisFeed.page">
                                <a class="text-gray-700 dark:text-gray-100" :href="'/dashboard/page/'+thisFeed.page.id" target="_blank">
                                    {{ thisFeed.page.name }}
                                    <verified-badge v-if="thisFeed.page.verified == 'true'" mt="one"></verified-badge>
                                </a>
                            </h4>
                        </template>
                        <template v-else-if="thisComment.post_as == 'group'">
                            <h4 class="text-base m-0 font-semibold" v-if="thisFeed.group">
                                <a class="text-gray-700 dark:text-gray-100" :href="'/dashboard/group/'+thisFeed.group.id" target="_blank">
                                    {{ thisFeed.group.name }}
                                    <verified-badge v-if="thisFeed.group.verified == 'true'" mt="one"></verified-badge>
                                </a>
                            </h4>
                        </template>
                        <template v-else>
                            <h4 class="text-base m-0 font-semibold" v-if="thisComment.user">
                                <a class="text-gray-700 dark:text-gray-100" :href="'/dashboard/timeline/'+thisComment.user.id" target="_blank">
                                    {{ thisComment.user.name }}
                                    <verified-badge v-if="thisComment.user.verified == 'true'" mt="one"></verified-badge>
                                </a>
                            </h4>
                        </template>
                        <p class="leading-6">
                            <comment-content :comment="thisComment.comment"></comment-content>
                        </p>
                        <div class="absolute w-3 h-3 top-3 -left-1 bg-gray-100 transform rotate-45 dark:bg-gray-800"></div>
                        <p class="text-right comment_content_reaction_holder mouse-pointer" v-if="thisComment.likes > 0" @click="triggerLikersModal(thisComment.likes_all)">
                            <span class="d-flex" :uk-tooltip="thisComment.likes" v-html="userReactionsIcon(thisComment, 'feed')"></span>
                        </p>
                    </div>
                    <div class="text-sm flex items-center space-x-3 mt-2 mb-2 ml-5">
                        <span :id="'tt_c_'+thisComment.id" v-on:click="toggleTooltip('#tt_c_'+thisComment.id)" :uk-tooltip="'title: '+formatDateTimeNice(thisComment.created_at)"> {{ timeAgo(thisComment.created_at) }} </span>
                        <template v-if="thisComment.user_like == 0">
                            <a @click="togglePopover('reaction_comment_holder_'+thisComment.id)" clickon="likeComment(thisComment.id)" class="text-red-600 mouse-pointer flex"> Like </a>
                            <div :id="'reaction_comment_holder_'+thisComment.id" @click="togglePopover('reaction_comment_holder_'+thisComment.id)" class="bg-white reaction_holder" style="width: 95%;max-width: 80vw" uk-drop-old="mode: hover,click;shift: true; flip: true;pos: bottom-right;animation: uk-animation-slide-bottom-small;boundary:!.main_content">
                                <div class="row">
                                    <div class="col py-3 d-flex">
                                        <a @click="likeComment(thisComment.id, 'like')" uk-tooltip="like" href="javascript:;" class="p-1 mr-1">
                                            <img class="icon_gif" src="/assets/img/reaction/gif/like.gif" />
                                        </a>
                                        <a @click="likeComment(thisComment.id, 'love')" uk-tooltip="love" href="javascript:;" class="p-1 mr-1">
                                            <img class="icon_gif" src="/assets/img/reaction/gif/love.gif" />
                                        </a>
                                        <a @click="likeComment(thisComment.id, 'care')" uk-tooltip="care" href="javascript:;" class="p-1 mr-1">
                                            <img class="icon_gif" src="/assets/img/reaction/gif/care.svg" />
                                        </a>
                                        <a @click="likeComment(thisComment.id, 'laugh')" uk-tooltip="laugh" href="javascript:;" class="p-1 mr-1">
                                            <img class="icon_gif" src="/assets/img/reaction/gif/haha.gif" />
                                        </a>
                                        <a @click="likeComment(thisComment.id, 'surprise')" uk-tooltip="surprise" href="javascript:;" class="p-1 mr-1">
                                            <img class="icon_gif" src="/assets/img/reaction/gif/wow.gif" />
                                        </a>
                                        <a @click="likeComment(thisComment.id, 'sad')" uk-tooltip="sad" href="javascript:;" class="p-1 mr-1">
                                            <img class="icon_gif" src="/assets/img/reaction/gif/sad.gif" />
                                        </a>
                                        <a @click="likeComment(thisComment.id, 'angry')" uk-tooltip="angry" href="javascript:;" class="p-1 mr-1">
                                            <img class="icon_gif" src="/assets/img/reaction/gif/angry.gif" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <a v-else @click="unlikeComment(thisComment.id)" class="text-red-600 mouse-pointer flex f_r_a" v-html="userReactionsIcon(thisComment, 'feed_reaction')"></a>
                        <a @click="toggleReplyForm(thisComment.id+'-comment-reply')" class="mouse-pointer"> Reply </a>
                    </div>
                    <div class="commentSection" :id="thisComment.id+'-comment-reply'" style="display:none">
                        <form v-on:submit.prevent="replyComment(thisComment.id)" class="d-flex mb-3">
                            <!-- <input v-model="reply" placeholder="Add your Reply.." class="bg-transparent max-h-10 shadow-none px-3"> -->
                            <textarea @input='e => reply = e.target.value' @keyup="showCommentBox('#feed_comment_reply_post_'+thisComment.id)" :id="'feed_comment_reply_post_'+thisComment.id" v-model="reply" placeholder="Add your Reply.." class="bg-transparentold bg-gray-200 max-h-10-old shadow-none pl-4 pr-5" style="min-height: auto;padding-top: 10px;" />
                            <button v-if="reply" class="reply_button bg-orange text-white mouse-pointer">
                                <i class="fa fa-paper-plane"></i>
                            </button>
                        </form>
                    </div>
                    <div :id="'feed_comment_reply_post_'+thisComment.id+'_tags'"></div>
                </div>
                <div style="height: 50px;">
                    <a href="javascript:;" @click="toggleItem('comment_more_options_'+thisComment.id, 'moreOptions')">
                        <i class="icon-feather-more-vertical"></i>
                    </a>
                    <div class="moreOptions bg-white" :id="'comment_more_options_'+thisComment.id">
                        <p>
                            <a @click="triggerReportContent('feed_comment', thisComment.id)" uk-toggle="target: #report-content-modal" href="javascript:;" class="ml-1">
                                Report
                            </a>
                        </p>
                        <p v-if="currentUser.id == thisComment.user_id">
                            <a @click="triggerEditFeedContent('feed_comment', thisComment.id, thisComment.comment)" uk-toggle="target: #edit-feed-comment-content-modal" href="javascript:;" class="ml-1">
                                Edit
                            </a>
                        </p>
                        <p v-if="currentUser.id == thisFeed.user.id || currentUser.id == thisComment.user_id">
                            <a @click="confirmDeleteFeedComment(thisComment.id)" class="ml-1 mouse-pointer">
                                Delete
                            </a>
                        </p>
                    </div>
                </div>
            </div>
            <p v-if="thisComment['replies'].length > 0" class="ml-5">
                <a v-if="preview == 'true'" :class="'mouse-pointer comment_replies_show_button_'+thisComment.id" @click="toggleCommentRepliesSection('.comment_replies_'+thisComment.id, '.comment_replies_show_button_'+thisComment.id)">
                    show all {{ thisComment['replies'].length }}
                    <span v-if="thisComment['replies'].length == 1">reply</span>
                    <span v-else>replies</span>
                </a>
            </p>
            <div v-for="thisReply in thisComment['replies']" :id="'comment_replies_'+thisComment.id" :class="'comment_replies_'+thisComment.id" :style="'display:'+commentInitialDisplay">
                <div class="flex pl-10" :id="'comment_reply_'+thisReply.id">
                    <div uk-lightbox="" class="w-10 h-10 rounded-full relative flex-shrink-0">
                        <template v-if="thisReply.post_as == 'page'">
                            <profile-icon :url="thisFeed.page.image"></profile-icon>
                        </template>
                        <template v-else-if="thisReply.post_as == 'group'">
                            <profile-icon :url="thisFeed.group.image"></profile-icon>
                        </template>
                        <template v-else>
                            <profile-icon :url="thisReply.user.profile_photo"></profile-icon>
                        </template>
                    </div>
                    <div>
                        <div class="text-gray-700 py-2 px-3 rounded-md bg-gray-100 relative lg:ml-5 ml-2 lg:mr-3  dark:bg-gray-800 dark:text-gray-100">
                            <template v-if="thisReply.post_as == 'page'">
                                <h4 class="text-base m-0 font-semibold" v-if="thisFeed.page">
                                    <a class="text-gray-700 dark:text-gray-100" :href="'/dashboard/page/'+thisFeed.page.id" target="_blank">
                                        {{ thisFeed.page.name }}
                                        <verified-badge v-if="thisFeed.page.verified == 'true'" mt="one"></verified-badge>
                                    </a>
                                </h4>
                            </template>
                            <template v-else-if="thisReply.post_as == 'group'">
                                <h4 class="text-base m-0 font-semibold" v-if="thisFeed.group">
                                    <a class="text-gray-700 dark:text-gray-100" :href="'/dashboard/group/'+thisFeed.group.id" target="_blank">
                                        {{ thisFeed.group.name }}
                                        <verified-badge v-if="thisFeed.group.verified == 'true'" mt="one"></verified-badge>
                                    </a>
                                </h4>
                            </template>
                            <template v-else>
                                <h4 class="text-base m-0 font-semibold" v-if="thisReply.user">
                                    <a class="text-gray-700 dark:text-gray-100" :href="'/dashboard/timeline/'+thisReply.user.id" target="_blank">
                                        {{ thisReply.user.name }}
                                        <verified-badge v-if="thisReply.user.verified == 'true'" mt="one"></verified-badge>
                                    </a>
                                </h4>
                            </template>
                            <p class="leading-6">
                                <!-- <a target="_blank" class="text-black" :href="'/dashboard/timeline/'+thisComment.user.id">
                                        @<b>{{ thisComment.user.name }}</b>
                                        <verified-badge v-if="thisComment.user.verified == 'true'" mt="zero"></verified-badge>
                                    </a>  -->
                                <!-- <span v-html="customTextRender(thisReply.reply)"></span> -->
                                <comment-content :comment="thisReply.reply"></comment-content>
                            </p>
                            <div class="absolute w-3 h-3 top-3 -left-1 bg-gray-100 transform rotate-45 dark:bg-gray-800"></div>
                            <p class="text-right comment_content_reaction_holder mouse-pointer" v-if="thisReply.likes > 0" @click="triggerLikersModal(thisReply.likes_all)">
                                <span class="d-flex" :uk-tooltip="thisReply.likes" v-html="userReactionsIcon(thisReply, 'feed')"></span>
                            </p>
                        </div>
                        <div class="text-sm flex items-center space-x-3 mt-2 mb-2 ml-5">
                            <span :id="'tt_cr_'+thisReply.id" v-on:click="toggleTooltip('#tt_cr_'+thisReply.id)" :uk-tooltip="'title: '+formatDateTimeNice(thisReply.created_at)"> {{ timeAgo(thisReply.created_at) }} </span>
                            <template v-if="thisReply.user_like == 0">
                                <a @click="togglePopover('reaction_comment_reply_holder_'+thisReply.id)" clickon="likeCommentReply(thisReply.id)" class="text-red-600 mouse-pointer flex"> Like </a>
                                <div :id="'reaction_comment_reply_holder_'+thisReply.id" @click="togglePopover('reaction_comment_reply_holder_'+thisReply.id)" class="bg-white reaction_holder" style="width: 95%;max-width: 80vw" uk-drop-old="mode: hover,click;shift: true; flip: true;pos: bottom-right;animation: uk-animation-slide-bottom-small;boundary:!.main_content">
                                    <div class="row">
                                        <div class="col py-3 d-flex">
                                            <a @click="likeCommentReply(thisReply.id, 'like')" uk-tooltip="like" href="javascript:;" class="p-1 mr-1">
                                                <img class="icon_gif" src="/assets/img/reaction/gif/like.gif" />
                                            </a>
                                            <a @click="likeCommentReply(thisReply.id, 'love')" uk-tooltip="love" href="javascript:;" class="p-1 mr-1">
                                                <img class="icon_gif" src="/assets/img/reaction/gif/love.gif" />
                                            </a>
                                            <a @click="likeCommentReply(thisReply.id, 'care')" uk-tooltip="care" href="javascript:;" class="p-1 mr-1">
                                                <img class="icon_gif" src="/assets/img/reaction/gif/care.svg" />
                                            </a>
                                            <a @click="likeCommentReply(thisReply.id, 'laugh')" uk-tooltip="laugh" href="javascript:;" class="p-1 mr-1">
                                                <img class="icon_gif" src="/assets/img/reaction/gif/haha.gif" />
                                            </a>
                                            <a @click="likeCommentReply(thisReply.id, 'surprise')" uk-tooltip="surprise" href="javascript:;" class="p-1 mr-1">
                                                <img class="icon_gif" src="/assets/img/reaction/gif/wow.gif" />
                                            </a>
                                            <a @click="likeCommentReply(thisReply.id, 'sad')" uk-tooltip="sad" href="javascript:;" class="p-1 mr-1">
                                                <img class="icon_gif" src="/assets/img/reaction/gif/sad.gif" />
                                            </a>
                                            <a @click="likeCommentReply(thisReply.id, 'angry')" uk-tooltip="angry" href="javascript:;" class="p-1 mr-1">
                                                <img class="icon_gif" src="/assets/img/reaction/gif/angry.gif" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <a v-else @click="unlikeCommentReply(thisReply.id)" class="text-red-600 mouse-pointer flex f_r_a" v-html="userReactionsIcon(thisReply, 'feed_reaction')"> </a>
                            <a @click="toggleReplyForm(thisReply.id+'-comment-reply-reply')" class="mouse-pointer"> Reply </a>
                        </div>
                        <div class="commentSection" :id="thisReply.id+'-comment-reply-reply'" style="display:none">
                            <form v-on:submit.prevent="replyCommentReply(thisFeed['id'],thisComment['id'],thisReply.id)" class="d-flex mb-3 commentSection">
                                <!-- <input v-model="replyReply" placeholder="Add your Reply.." class="bg-transparent max-h-10 shadow-none px-3"> -->
                                <textarea @input='e => replyReply = e.target.value' @keyup="showCommentBox('#feed_comment_reply_reply_post_'+thisReply.id)" :id="'feed_comment_reply_reply_post_'+thisReply.id" v-model="replyReply" placeholder="Add your Reply.." class="bg-transparent-old bg-gray-200 max-h-10-old shadow-none pl-4 pr-5" style="min-height: auto;padding-top: 10px;" />
                                <button v-if="replyReply" class="reply_button bg-orange text-white mouse-pointer">
                                    <i class="fa fa-paper-plane"></i>
                                </button>
                            </form>
                        </div>
                        <div :id="'feed_comment_reply_reply_post_'+thisReply.id+'_tags'"></div>
                    </div>
                    <div style="height: 50px;">
                        <a href="javascript:;" @click="toggleItem('comment_reply_more_options_'+thisReply.id, 'moreOptions')">
                            <i class="icon-feather-more-vertical"></i>
                        </a>
                        <div class="moreOptions bg-white" :id="'comment_reply_more_options_'+thisReply.id">
                            <p>
                                <a @click="triggerReportContent('feed_comment_reply', thisReply.id)" uk-toggle="target: #report-content-modal" href="javascript:;" class="ml-1">
                                    Report
                                </a>
                            </p>
                            <p v-if="currentUser.id == thisReply.user_id">
                                <a @click="triggerEditFeedContent('feed_comment_reply', thisReply.id, thisReply.reply)" uk-toggle="target: #edit-feed-comment-content-modal" href="javascript:;" class="ml-1">
                                    Edit
                                </a>
                            </p>
                            <p v-if="currentUser.id == thisFeed.user.id || currentUser.id == thisReply.user_id">
                                <a @click="confirmDeleteFeedCommentReply(thisReply.id)" class="ml-1 mouse-pointer">
                                    Delete
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
                <!-- <p v-if="thisReply['replies'].length > 0" class="ml-5 pl-5">
                    <a v-if="preview == 'true'" :class="'mouse-pointer comment_reply_replies_show_button_'+thisReply.id" @click="toggleCommentRepliesSection('.comment_reply_reply_'+thisReply.id, '.comment_reply_replies_show_button_'+thisReply.id)">
                        show all {{ thisReply['replies'].length }}
                        <span v-if="thisReply['replies'].length == 1">reply</span>
                        <span v-else>replies</span>
                    </a>
                </p> -->
                <div :class="'flex pl-12 comment_reply_reply_'+thisReplyReply.id" v-for="thisReplyReply in thisReply['replies']" :id="'comment_reply_reply_'+thisReplyReply.id" :style="'display:'+commentReplyInitialDisplay">
                    <div uk-lightbox="" class="w-10 h-10 rounded-full relative flex-shrink-0">
                        <template v-if="thisReplyReply.post_as == 'page'">
                            <profile-icon :url="thisFeed.page.image"></profile-icon>
                        </template>
                        <template v-else-if="thisReplyReply.post_as == 'group'">
                            <profile-icon :url="thisFeed.group.image"></profile-icon>
                        </template>
                        <template v-else>
                            <profile-icon :url="thisReplyReply.user.profile_photo"></profile-icon>
                        </template>
                    </div>
                    <div>
                        <div class="text-gray-700 py-2 px-3 rounded-md bg-gray-100 relative lg:ml-5 ml-2 lg:mr-3  dark:bg-gray-800 dark:text-gray-100">
                            <template v-if="thisReplyReply.post_as == 'page'">
                                <h4 class="text-base m-0 font-semibold" v-if="thisFeed.page">
                                    <a class="text-black" :href="'/dashboard/page/'+thisFeed.page.id" target="_blank">
                                        {{ thisFeed.page.name }}
                                        <verified-badge v-if="thisFeed.page.verified == 'true'" mt="one"></verified-badge>
                                    </a>
                                </h4>
                            </template>
                            <template v-else-if="thisReplyReply.post_as == 'group'">
                                <h4 class="text-base m-0 font-semibold" v-if="thisFeed.group">
                                    <a class="text-black" :href="'/dashboard/group/'+thisFeed.group.id" target="_blank">
                                        {{ thisFeed.group.name }}
                                        <verified-badge v-if="thisFeed.group.verified == 'true'" mt="one"></verified-badge>
                                    </a>
                                </h4>
                            </template>
                            <template v-else>
                                <h4 class="text-base m-0 font-semibold" v-if="thisReplyReply.user">
                                    <a class="text-black" :href="'/dashboard/timeline/'+thisReplyReply.user.id" target="_blank">
                                        {{ thisReplyReply.user.name }}
                                        <verified-badge v-if="thisReplyReply.user.verified == 'true'" mt="one"></verified-badge>
                                    </a>
                                </h4>
                            </template>
                            <p class="leading-6">
                                <!-- <a target="_blank" class="text-black" :href="'/dashboard/timeline/'+thisReply.user.id">
                                        @<b>{{ thisReply.user.name }}</b>
                                        <verified-badge v-if="thisReply.user.verified == 'true'" mt="zero"></verified-badge>
                                    </a>  -->
                                <!-- <span v-html="customTextRender(thisReplyReply.reply)"></span> -->
                                <comment-content :comment="thisReplyReply.reply"></comment-content>
                            </p>
                            <div class="absolute w-3 h-3 top-3 -left-1 bg-gray-100 transform rotate-45 dark:bg-gray-800"></div>
                            <p class="text-right comment_content_reaction_holder mouse-pointer" v-if="thisReplyReply.likes > 0" @click="triggerLikersModal(thisReplyReply.likes_all)">
                                <span class="d-flex" :uk-tooltip="thisReplyReply.likes" v-html="userReactionsIcon(thisReplyReply, 'feed')"></span>
                            </p>
                        </div>
                        <div class="text-sm flex items-center space-x-3 mt-2 mb-2 ml-5">
                            <span :id="'tt_crr_'+thisReplyReply.id" v-on:click="toggleTooltip('#tt_crr_'+thisReplyReply.id)" :uk-tooltip="'title: '+formatDateTimeNice(thisReplyReply.created_at)"> {{ timeAgo(thisReplyReply.created_at) }} </span>
                            <template v-if="thisReplyReply.user_like == 0">
                                <a @click="togglePopover('reaction_comment_reply_reply_holder_'+thisReplyReply.id)" clickon="likeCommentReplyReply(thisReplyReply.id)" class="text-red-600 mouse-pointer flex">Like </a>
                                <div :id="'reaction_comment_reply_reply_holder_'+thisReplyReply.id" @click="togglePopover('reaction_comment_reply_reply_holder_'+thisReplyReply.id)" class="bg-white reaction_holder" style="width: 95%;max-width: 80vw" uk-drop-old="mode: hover,click;shift: true; flip: true;pos: bottom-right;animation: uk-animation-slide-bottom-small;boundary:!.main_content">
                                    <div class="row">
                                        <div class="col py-3 d-flex">
                                            <a @click="likeCommentReplyReply(thisReplyReply.id, 'like')" uk-tooltip="like" href="javascript:;" class="p-1 mr-1">
                                                <img class="icon_gif" src="/assets/img/reaction/gif/like.gif" />
                                            </a>
                                            <a @click="likeCommentReplyReply(thisReplyReply.id, 'love')" uk-tooltip="love" href="javascript:;" class="p-1 mr-1">
                                                <img class="icon_gif" src="/assets/img/reaction/gif/love.gif" />
                                            </a>
                                            <a @click="likeCommentReplyReply(thisReplyReply.id, 'care')" uk-tooltip="care" href="javascript:;" class="p-1 mr-1">
                                                <img class="icon_gif" src="/assets/img/reaction/gif/care.svg" />
                                            </a>
                                            <a @click="likeCommentReplyReply(thisReplyReply.id, 'laugh')" uk-tooltip="laugh" href="javascript:;" class="p-1 mr-1">
                                                <img class="icon_gif" src="/assets/img/reaction/gif/haha.gif" />
                                            </a>
                                            <a @click="likeCommentReplyReply(thisReplyReply.id, 'surprise')" uk-tooltip="surprise" href="javascript:;" class="p-1 mr-1">
                                                <img class="icon_gif" src="/assets/img/reaction/gif/wow.gif" />
                                            </a>
                                            <a @click="likeCommentReplyReply(thisReplyReply.id, 'sad')" uk-tooltip="sad" href="javascript:;" class="p-1 mr-1">
                                                <img class="icon_gif" src="/assets/img/reaction/gif/sad.gif" />
                                            </a>
                                            <a @click="likeCommentReplyReply(thisReplyReply.id, 'angry')" uk-tooltip="angry" href="javascript:;" class="p-1 mr-1">
                                                <img class="icon_gif" src="/assets/img/reaction/gif/angry.gif" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <a v-else @click="unlikeCommentReplyReply(thisReplyReply.id)" class="text-red-600 mouse-pointer flex f_r_a" v-html="userReactionsIcon(thisReplyReply, 'feed_reaction')"> </a>
                            <!-- <a @click="toggleReplyForm(thisReplyReply.id+'-comment-reply')" class="mouse-pointer"> Reply </a> -->
                        </div>
                    </div>
                    <div style="height: 50px;">
                        <a href="javascript:;" @click="toggleItem('comment_reply_reply_more_options_'+thisReplyReply.id, 'moreOptions')">
                            <i class="icon-feather-more-vertical"></i>
                        </a>
                        <div class="moreOptions bg-white" :id="'comment_reply_reply_more_options_'+thisReplyReply.id">
                            <p>
                                <a @click="triggerReportContent('feed_comment_reply_replies', thisReplyReply.id)" uk-toggle="target: #report-content-modal" href="javascript:;" class="ml-1">
                                    Report
                                </a>
                            </p>
                            <p v-if="currentUser.id == thisReplyReply.user_id">
                                <a @click="triggerEditFeedContent('feed_comment_reply_replies', thisReplyReply.id, thisReplyReply.reply)" uk-toggle="target: #edit-feed-comment-content-modal" href="javascript:;" class="ml-1">
                                    Edit
                                </a>
                            </p>
                            <p v-if="currentUser.id == thisFeed.user.id || currentUser.id == thisReplyReply.user_id">
                                <a @click="confirmDeleteFeedCommentReplyReply(thisReplyReply.id)" class="ml-1 mouse-pointer">
                                    Delete
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <div>

        </div>
    </div>

    <!-- <template v-if="preview == 'true' && thisFeed.comments.length > noOfPreviewComments">
        <a @click="showAllComments()" v-if="unviewedComments > 0" href="javasacript:;" class="hover:text-blue-600 hover:underline"> View {{ unviewedComments }} more Comments </a>
        <a @click="commentChecker()" v-else href="javasacript:;" class="hover:text-blue-600 hover:underline"> Hide some Comments </a>
    </template> -->

    <google-banner-ad v-if="show_ad == 'true'"></google-banner-ad>

    <div class="bg-gray-100 rounded-full relative dark:bg-gray-800 border-t commentSection" :id="thisFeed.id+'-comment'" onstyle="display:none">
        <form v-on:submit.prevent="addComment(thisFeed.id)">
            <textarea @input='e => comment = e.target.value' @keyup="showCommentBox('#feed_comment_post_'+thisFeed.id)" :id="'feed_comment_post_'+thisFeed.id" v-model="comment" placeholder="Add your Comment.." class="bg-transparent max-h-10-old shadow-none pl-4 pr-5" style="min-height: auto;padding-top: 10px;" />
            <button v-if="comment" class="comment_button bg-orange text-white mouse-pointer">
                <i class="fa fa-paper-plane"></i>
            </button>
        </form>
        <div class="-m-0.5 absolute bottom-0 flex items-center right-3 text-xl">
        </div>
    </div>
    <div v-if="thisFeed" :id="'feed_comment_post_'+thisFeed.id+'_tags'"></div>

    <feed-insight-modal v-if="feed_id_insight" :feed_id="feed_id_insight"></feed-insight-modal>
    <feed-likers-modal v-if="thisFeed && likers" :likers="likers"></feed-likers-modal>

</div>
</template>

<script>
import {
    ApiService
} from '@/services/api.service'
import ProfileIcon from '@/include/Dashboard/Feed/ProfileIcon'
import FeedInsightModal from '@/include/Modals/FeedInsightModal'
import GoogleBannerAd from '@/include/Dashboard/Feed/GoogleBannerAd'
import VerifiedBadge from '@/components/VerifiedBadge'
import FeedLikersModal from '@/include/Modals/FeedLikersModal'
import CommentContent from '@/components/Feed/CommentContent'

export default {
    name: "CommentsManager",
    props: {
        feed: Object,
        is_admin: String,
        preview: String,
        currency: String,
        can_show_ad: String,
    },
    data() {
        return {
            feedContent: '',
            reply: '',
            replyReply: '',
            comment: '',
            comments: '',
            noOfPreviewComments: 0,
            unviewedComments: 0,
            currentUser: '',
            thisFeed: '',
            shareLink: '',
            feed_id_insight: '',
            shareDescription: '',
            show_more_less_button: 'false',
            is_less: 'true',
            likers: '',
            commentInitialDisplay: 'none',
            commentReplyInitialDisplay: 'none',
            feed_id_donate: '',
            userFeedCommentKey: '',
            thisFeedCommentsLength: 0,
            show_ad: 'false',
        };
    },
    components: {
        ProfileIcon,
        FeedInsightModal,
        GoogleBannerAd,
        VerifiedBadge,
        FeedLikersModal,
        CommentContent,
    },
    mounted () {
        // console.log('==============currency', this.currency)
        this.resetDropdownKey()
        this.currentUser = this.$store.getters["user/currentUser"] || '';
        this.thisFeed = this.feed
        this.moreLessManager()
        this.commentChecker()
        if (this.currentUser) {
            // this.getFeedLikes()
            this.likers = this.thisFeed["likes_all"];
        }
        if (this.preview == 'true') {
            this.commentInitialDisplay = 'none'
            this.commentReplyInitialDisplay = 'flex'
        } else {
            this.commentInitialDisplay = 'block'
            this.commentReplyInitialDisplay = 'flex'
        }
        if(this.can_show_ad == 'true'){
            this.show_ad = this.can_show_ad
        }
        // console.log('===========commentReplyInitialDisplay', this.commentReplyInitialDisplay)
        let thisPage = location.href
        if (thisPage.search('/feed/') > -1 || thisPage.search('/event/') > -1 || thisPage.search('/product/') > -1) {
        }
        let vueThis = this
        setTimeout(() => {
            vueThis.locateCommentForSingleFeed()
        }, 2000);
    },
    methods: {
        async getFeedLikes() {
            this.loading = "true";
            let apiResponse = await ApiService.FeedLikes(this.feed.id);
            if (apiResponse["status"] != "success") {
                this.$store.commit("toast/Notify", {
                    type: apiResponse["status"],
                    message: apiResponse["message"],
                });
            } else {
                if (apiResponse["data"] && apiResponse["data"].length > 0) {
                    this.likers = apiResponse["data"];
                }
            }
            this.loading = "false";
        },
        async addComment(id) {
            this.toggleProgress('show')
            this.comment = $("#feed_comment_post_" + id).val()
            let apiResponse = await ApiService.AddComment(id, this.comment)
            if (apiResponse['status'] != 'success') {
                this.$store.commit("toast/Notify", {
                    type: apiResponse["status"],
                    message: apiResponse["message"],
                });
            } else {
                this.comment = ''
                this.thisFeed = apiResponse['data']
                this.sumCommentsWithReplies()

                setTimeout(() => {
                    $(".feed-comments-" + id).show()
                }, 1200);
            }
            this.toggleProgress('hide')
        },
        async likeFeed(type) {
            this.toggleProgress('show')
            let apiResponse = await ApiService.LikeFeed(this.thisFeed.id, type)
            if (apiResponse['status'] != 'success') {
                this.$store.commit("toast/Notify", {
                    type: apiResponse["status"],
                    message: apiResponse["message"],
                });
            } else {
                this.thisFeed = apiResponse['data']
                this.sumCommentsWithReplies()

            }
            this.toggleProgress('hide')
        },
        async unlikeFeed() {
            this.toggleProgress('show')
            let apiResponse = await ApiService.UnlikeFeed(this.thisFeed.id)
            if (apiResponse['status'] != 'success') {
                this.$store.commit("toast/Notify", {
                    type: apiResponse["status"],
                    message: apiResponse["message"],
                });
            } else {
                this.thisFeed = apiResponse['data']
                this.sumCommentsWithReplies()

            }
            this.toggleProgress('hide')
        },
        async likeComment(comment_id, type) {
            this.toggleProgress('show')
            let apiResponse = await ApiService.LikeComment(this.thisFeed.id, comment_id, type)
            if (apiResponse['status'] != 'success') {
                this.$store.commit("toast/Notify", {
                    type: apiResponse["status"],
                    message: apiResponse["message"],
                });
            } else {
                this.thisFeed = apiResponse['data']
                this.sumCommentsWithReplies()

            }
            this.toggleProgress('hide')
        },
        async unlikeComment(comment_id) {
            this.toggleProgress('show')
            let apiResponse = await ApiService.UnlikeComment(this.thisFeed.id, comment_id)
            if (apiResponse['status'] != 'success') {
                this.$store.commit("toast/Notify", {
                    type: apiResponse["status"],
                    message: apiResponse["message"],
                });
            } else {
                this.thisFeed = apiResponse['data']
                this.sumCommentsWithReplies()

            }
            this.toggleProgress('hide')
        },
        async shareAction(id) {
            this.toggleProgress('show')
            let apiResponse = await ApiService.ShareFeed(this.thisFeed.id)
            if (apiResponse['status'] != 'success') {} else {}
            this.$store.commit("toast/Notify", {
                type: apiResponse["status"],
                message: apiResponse["message"],
            });
            this.toggleProgress('hide')
        },
        async replyComment(comment_id) {
            this.toggleProgress('show')
            this.reply = $("#feed_comment_reply_post_" + comment_id).val()
            let apiResponse = await ApiService.ReplyComment(this.thisFeed.id, comment_id, this.reply)
            if (apiResponse['status'] != 'success') {
                this.$store.commit("toast/Notify", {
                    type: apiResponse["status"],
                    message: apiResponse["message"],
                });
            } else {
                this.reply = ''
                this.thisFeed = apiResponse['data']
                this.openCommentSection(this.thisFeed.id)
                this.sumCommentsWithReplies()
            }
            this.toggleProgress('hide')
        },
        async replyCommentReply(feed_id, comment_id, reply_id) {
            this.toggleProgress('show')
            this.replyReply = $("#feed_comment_reply_reply_post_" + reply_id).val()
            let apiResponse = await ApiService.ReplyCommentReply(feed_id, comment_id, reply_id, this.replyReply)
            if (apiResponse['status'] != 'success') {
                this.$store.commit("toast/Notify", {
                    type: apiResponse["status"],
                    message: apiResponse["message"],
                });
            } else {
                this.replyReply = ''
                this.thisFeed = apiResponse['data']
                this.openCommentSection(this.thisFeed.id)
                this.sumCommentsWithReplies()
            }
            this.toggleProgress('hide')
        },
        async likeCommentReply(reply_id, type) {
            this.toggleProgress('show')
            let apiResponse = await ApiService.LikeCommentReply(this.thisFeed.id, reply_id, type)
            if (apiResponse['status'] != 'success') {
                this.$store.commit("toast/Notify", {
                    type: apiResponse["status"],
                    message: apiResponse["message"],
                });
            } else {
                this.thisFeed = apiResponse['data']
                this.sumCommentsWithReplies()

            }
            this.toggleProgress('hide')
        },
        async unlikeCommentReply(reply_id) {
            this.toggleProgress('show')
            let apiResponse = await ApiService.UnlikeCommentReply(this.thisFeed.id, reply_id)
            if (apiResponse['status'] != 'success') {
                this.$store.commit("toast/Notify", {
                    type: apiResponse["status"],
                    message: apiResponse["message"],
                });
            } else {
                this.thisFeed = apiResponse['data']
                this.sumCommentsWithReplies()

            }
            this.toggleProgress('hide')
        },
        async likeCommentReplyReply(reply_id, type) {
            this.toggleProgress('show')
            let apiResponse = await ApiService.LikeCommentReplyReply(this.thisFeed.id, reply_id, type)
            if (apiResponse['status'] != 'success') {
                this.$store.commit("toast/Notify", {
                    type: apiResponse["status"],
                    message: apiResponse["message"],
                });
            } else {
                this.thisFeed = apiResponse['data']
                this.sumCommentsWithReplies()

            }
            this.toggleProgress('hide')
        },
        async unlikeCommentReplyReply(reply_id) {
            this.toggleProgress('show')
            let apiResponse = await ApiService.UnlikeCommentReplyReply(this.thisFeed.id, reply_id)
            if (apiResponse['status'] != 'success') {
                this.$store.commit("toast/Notify", {
                    type: apiResponse["status"],
                    message: apiResponse["message"],
                });
            } else {
                this.thisFeed = apiResponse['data']
                this.sumCommentsWithReplies()

            }
            this.toggleProgress('hide')
        },
        async deleteFeedComment(comment_id) {
            this.toggleProgress('show')
            let apiResponse = await ApiService.DeleteFeedComment(this.thisFeed.id, comment_id)
            if (apiResponse['status'] != 'success') {
                this.$store.commit("toast/Notify", {
                    type: apiResponse["status"],
                    message: apiResponse["message"],
                });
            } else {
                this.thisFeed = apiResponse['data']
                this.sumCommentsWithReplies()

            }
            this.toggleProgress('hide')
        },
        async deleteFeedCommentReply(reply_id) {
            this.toggleProgress('show')
            let apiResponse = await ApiService.DeleteFeedCommentReply(this.thisFeed.id, reply_id)
            if (apiResponse['status'] != 'success') {
                this.$store.commit("toast/Notify", {
                    type: apiResponse["status"],
                    message: apiResponse["message"],
                });
            } else {
                this.thisFeed = apiResponse['data']
                this.sumCommentsWithReplies()

            }
            this.toggleProgress('hide')
        },
        async deleteFeedCommentReplyReply(reply_id) {
            this.toggleProgress('show')
            let apiResponse = await ApiService.DeleteFeedCommentReplyReply(this.thisFeed.id, reply_id)
            if (apiResponse['status'] != 'success') {
                this.$store.commit("toast/Notify", {
                    type: apiResponse["status"],
                    message: apiResponse["message"],
                });
            } else {
                this.thisFeed = apiResponse['data']

            }
            this.toggleProgress('hide')
        },
        async getFeed(){
            let apiResponse = await ApiService.GetFeed(this.thisFeed.id)
            if(apiResponse['status'] != 'success'){
            }else{
                this.thisFeed = apiResponse['data']
                this.resetDropdownKey()
                this.sumCommentsWithReplies()
            }
        },
        resetDropdownKey(){
            this.userFeedCommentKey = this.randomNumber()
        },
        confirmDeleteFeedComment(comment_id) {
            let proceed = confirm("Are you sure you want to delete this Feed Comment?");
            if (proceed) {
                this.deleteFeedComment(comment_id)
            } else {
                //don't proceed
            }
        },
        confirmDeleteFeedCommentReply(reply_id) {
            let proceed = confirm("Are you sure you want to delete this Feed Comment Reply?");
            if (proceed) {
                this.deleteFeedCommentReply(reply_id)
            } else {
                //don't proceed
            }
        },
        confirmDeleteFeedCommentReplyReply(reply_id) {
            let proceed = confirm("Are you sure you want to delete this Feed Comment Reply Reply?");
            if (proceed) {
                this.deleteFeedCommentReplyReply(reply_id)
            } else {
                //don't proceed
            }
        },
        setCurrentLink(id, description) {
            let section = 'feed'
            let url = location.href
            if (url.search('/event/') > -1) {
                section = 'event'
            } else if (url.search('/product/') > -1) {
                section = 'product'
            }
            // console.log('=========SET C LINKE', id)
            let thisLink = this.$store.getters["host/seoUrl"] + '/' + section + '/' + id

            let data = {
                link: thisLink,
                description,
                feed: this.thisFeed,
            }
            this.$root.$emit("open-share-feed-modal", data)
            console.log('Emmmited open-share-feed-modal')
            this.shareLink = thisLink
        },
        showCommentBox(a) {
            this.$forceUpdate();
            this.manageTagging(a)
        },
        triggerReportContent(type, id) {
            let d = {
                type,
                id
            }
            this.$root.$emit("open-report-content-modal", d)
        },
        triggerEditFeedContent(type, id, comment) {
            let d = {
                type,
                id,
                comment
            }
            this.$root.$emit("open-edit-feed-comment-content-modal", d)
        },
        triggerLikersModal(likers) {
            this.$root.$emit("open-likers-modal", likers)
            UIkit.modal('#likers-modal').toggle();
        },
        commentChecker() {
            let comments = this.thisFeed.comments.length
            if (comments > this.noOfPreviewComments) {
                this.unviewedComments = comments - this.noOfPreviewComments
            }
            if (this.preview == 'true') {
                this.comments = this.thisFeed.comments.slice(0, this.noOfPreviewComments)
            } else {
                this.comments = this.thisFeed.comments
            }
            this.sumCommentsWithReplies()
            this.manageLikeTextSize('#feed_reaction_text_'+this.thisFeed.id)
        },
        showAllComments() {
            this.unviewedComments = 0
            this.comments = this.thisFeed.comments
        },
        moreLessManager() {
            this.show_more_less_button = 'false'
            let a = this.customTextRender(this.thisFeed['content'])
            let max_length = 50
            let textSplit = a.split(" ")
            if (textSplit.length > max_length) {
                this.show_more_less_button = 'true'
                if (this.is_less == 'true') {
                    textSplit = textSplit.slice(0, max_length)
                } else {}
            }
            a = textSplit.join(' ')
            this.feedContent = a
        },
        lessChange(a) {
            this.is_less = a
            this.moreLessManager()
        },
        triggerClick() {
            // console.log('TCLIECK')
        },
        toggleCommentSection(feed_id) {
            if (feed_id) {
                $(".feed-comments-" + feed_id).toggle()
                this.scrollToCommentBox(feed_id + '-comment')
            }
        },
        openCommentSection(feed_id) {
            if (feed_id) {
                $(".feed-comments-" + feed_id).show()
                $(".commentSection").hide()
                $("#" + feed_id + '-comment').show()
            }
        },
        toggleCommentRepliesSection(show_element, hide_element) {
            if (show_element) {
                $(show_element).show()
            }
            if (hide_element) {
                $(hide_element).hide()
            }
        },
        openLikersModal() {
            if (this.thisFeed.user_id == this.currentUser.id) {
                UIkit.modal('#feed-likers-modal').toggle();
            }
        },
        locateCommentForSingleFeed() {
            let url = location.href
            // if(url.search('/feed/') > -1){
            let contentLocator = ''
            if (url.search('comment_id=') > -1) {
                let urlSplit = url.split('comment_id=')
                let commentId = urlSplit[1]
                contentLocator = '#comment_' + commentId
            } else if (url.search('comment_reply_id=') > -1) {
                let urlSplit = url.split('comment_reply_id=')
                let commentReplyId = urlSplit[1]
                contentLocator = '#comment_reply_' + commentReplyId
            } else if (url.search('comment_reply_reply_id=') > -1) {
                let urlSplit = url.split('comment_reply_reply_id=')
                let commentReplyReplyId = urlSplit[1]
                contentLocator = '#comment_reply_reply_' + commentReplyReplyId
            }
            if (contentLocator) {
                $(contentLocator).addClass('obvious')
                this.scrollToContent(contentLocator)
                setTimeout(() => {
                    $(contentLocator).removeClass('obvious', {
                        duration: 2000,
                        effect: 'blind'
                    })
                }, 4000);
            }
            // }
        },
        viewFeedDonate(feed_id_donate) {
            console.log('feed_id_donate',feed_id_donate)
            this.feed_id_donate = feed_id_donate
            let currency = this.currency
            UIkit.modal('#feed-donate-modal').toggle();
            let data = {
                feed_id_donate,
                currency
            }
            this.$root.$emit("open-feed-donate-modal", data)
        },
        sumCommentsWithReplies(){
            let thisFeedCommentsLength = 0
            for (let i = 0; i < this.thisFeed.comments.length; i++) {
                thisFeedCommentsLength++
                const thisFeedComment = this.thisFeed.comments[i];
                for (let j = 0; j < thisFeedComment['replies'].length; j++) {
                    const thisFeedCommentReply = thisFeedComment['replies'][j];
                    thisFeedCommentsLength++
                    
                    thisFeedCommentsLength = thisFeedCommentsLength+thisFeedCommentReply['replies'].length
                }
            }
            this.thisFeedCommentsLength = thisFeedCommentsLength
        },
    }
};
</script>

<style scoped>
.reply_button {
    padding: 5px 10px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    margin-left: -5px;
}

@media (max-width: 540px) {
    .comment_stat {
        font-size: 11px;
    }

    .icon_image {
        height: 15px;
    }
}
</style>
