<template>
<div id="wrapper">

    <Header />

    <sidebar></sidebar>

    <!-- Main Contents -->
    <div class="main_content">
        <div class="mcontainer">

            <!--  breadcrumb -->
            <div class="breadcrumb-area py-0">
                <div class="breadcrumb">
                    <ul class="m-0">
                        <li>
                            <a href="/dashboard/events">Events</a>
                        </li>
                        <li class="active">
                            <a href="">Create New Event </a>
                        </li>
                    </ul>
                </div>
            </div>

            <!-- create page-->
            <div class="max-w-2xl m-auto shadow-md rounded-md bg-white lg:mt-20">

                <!-- form header -->
                <div class="text-center border-b border-gray-100 py-6">
                    <h3 class="font-bold text-xl"> Create New Event </h3>
                </div>

                <!-- form body -->
                <div class="sm:p-10 p-3 space-y-7">

                    <div class="line">
                        <input v-model="title" class="line__input" id="title" name="title" type="text" onkeyup="this.setAttribute('value', this.value);" value="" autocomplete="off">
                        <span for="title" class="line__placeholder"> Title </span>
                    </div>
                    <div class="line">
                        <input v-model="website" class="line__input" id="website" name="website" type="url" onkeyup="this.setAttribute('value', this.value);" value="" autocomplete="off">
                        <span for="website" class="line__placeholder"> Website </span>
                    </div>
                    <div class="flex items-center">
                        <div class="mr-2">
                            <p>Event Image</p>
                            <img v-if="image" :src="image" class="postImgheight" />
                            <img v-else :src="defaultPostUrl()" class="postImgheight" />
                        </div>
                        <single-file-upload type="image" @uploadSuccess="onImageUploaded">
                            <p>Select<br /> Image</p>
                        </single-file-upload>
                    </div>
                    <div>
                        <!-- <label for=""> Category </label><br/><br/> -->
                        <select v-model="type" id="" name="" class="shadow-none with-border ">
                            <option value="">Event Type</option>
                            <option v-for="c in eventTypes" :value="c">{{ c }}</option>
                        </select>
                    </div>
                    <div>
                        <!-- <label for=""> Category </label><br/><br/> -->
                        <select v-model="payment_type" id="" name="" class="shadow-none with-border ">
                            <option value="" disable>Payment Type</option>
                            <option v-for="c in eventPaymentTypes" :value="c">{{ c }}</option>
                        </select>
                    </div>
                    <div>
                        <!-- <label for=""> Category </label><br/><br/> -->
                        <select v-model="sector" id="" name="" class="shadow-none with-border ">
                            <option value="" disable>Sector</option>
                            <option v-for="c in sectorTypes" :value="c">{{ c }}</option>
                        </select>
                    </div>
                    <div>
                        <label>Start Date</label>
                    </div>
                    <div class="line" style="margin-top: 5px !important">
                        <input v-model="start_date" class="line__input" id="start_date" name="start_date" :min="start_min" :max="start_max" @change="setEndMinMax" type="date" onkeyup="this.setAttribute('value', this.value);" value="" autocomplete="off">
                        <span for="start_date" class="line__placeholder">  </span>
                    </div>
                    <div>
                        <label>Start Time</label>
                    </div>
                    <div class="line" style="margin-top: 5px !important">
                        <input v-model="start_time" class="line__input" id="start_time" name="start_time" type="time" onkeyup="this.setAttribute('value', this.value);" value="" autocomplete="off">
                        <span for="start_time" class="line__placeholder">  </span>
                    </div>
                    <div>
                        <label>End Date</label>
                    </div>
                    <div class="line" style="margin-top: 5px !important">
                        <input v-model="end_date" class="line__input" id="end_date" name="end_date" :min="end_min" :max="end_max" type="date" onkeyup="this.setAttribute('value', this.value);" value="" autocomplete="off">
                        <span for="end_date" class="line__placeholder"> </span>
                    </div>
                    <div>
                        <label>End Time</label>
                    </div>
                    <div class="line" style="margin-top: 5px !important">
                        <input v-model="end_time" class="line__input" id="end_time" name="end_time" type="time" onkeyup="this.setAttribute('value', this.value);" value="" autocomplete="off">
                        <span for="end_time" class="line__placeholder">  </span>
                    </div>
                    <div>
                        <select v-model="timezone" class="shadow-none with-border ">
                            <option value="" disabled>Select Timezone</option>
                            <option data-value="Etc/GMT+12">(GMT-12:00) International Date Line West</option>
                            <option data-value="Pacific/Midway">(GMT-11:00) Midway Island, Samoa</option>
                            <option data-value="Pacific/Honolulu">(GMT-10:00) Hawaii</option>
                            <option data-value="US/Alaska">(GMT-09:00) Alaska</option>
                            <option data-value="America/Los_Angeles">(GMT-08:00) Pacific Time (US & Canada)</option>
                            <option data-value="America/Tijuana">(GMT-08:00) Tijuana, Baja California</option>
                            <option data-value="US/Arizona">(GMT-07:00) Arizona</option>
                            <option data-value="America/Chihuahua">(GMT-07:00) Chihuahua, La Paz, Mazatlan</option>
                            <option data-value="US/Mountain">(GMT-07:00) Mountain Time (US & Canada)</option>
                            <option data-value="America/Managua">(GMT-06:00) Central America</option>
                            <option data-value="US/Central">(GMT-06:00) Central Time (US & Canada)</option>
                            <option data-value="America/Mexico_City">(GMT-06:00) Guadalajara, Mexico City, Monterrey</option>
                            <option data-value="Canada/Saskatchewan">(GMT-06:00) Saskatchewan</option>
                            <option data-value="America/Bogota">(GMT-05:00) Bogota, Lima, Quito, Rio Branco</option>
                            <option data-value="US/Eastern">(GMT-05:00) Eastern Time (US & Canada)</option>
                            <option data-value="US/East-Indiana">(GMT-05:00) Indiana (East)</option>
                            <option data-value="Canada/Atlantic">(GMT-04:00) Atlantic Time (Canada)</option>
                            <option data-value="America/Caracas">(GMT-04:00) Caracas, La Paz</option>
                            <option data-value="America/Manaus">(GMT-04:00) Manaus</option>
                            <option data-value="America/Santiago">(GMT-04:00) Santiago</option>
                            <option data-value="Canada/Newfoundland">(GMT-03:30) Newfoundland</option>
                            <option data-value="America/Sao_Paulo">(GMT-03:00) Brasilia</option>
                            <option data-value="America/Argentina/Buenos_Aires">(GMT-03:00) Buenos Aires, Georgetown</option>
                            <option data-value="America/Godthab">(GMT-03:00) Greenland</option>
                            <option data-value="America/Montevideo">(GMT-03:00) Montevideo</option>
                            <option data-value="America/Noronha">(GMT-02:00) Mid-Atlantic</option>
                            <option data-value="Atlantic/Cape_Verde">(GMT-01:00) Cape Verde Is.</option>
                            <option data-value="Atlantic/Azores">(GMT-01:00) Azores</option>
                            <option data-value="Africa/Casablanca">(GMT+00:00) Casablanca, Monrovia, Reykjavik</option>
                            <option data-value="Etc/Greenwich">(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London</option>
                            <option data-value="Europe/Amsterdam">(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna</option>
                            <option data-value="Europe/Belgrade">(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague</option>
                            <option data-value="Europe/Brussels">(GMT+01:00) Brussels, Copenhagen, Madrid, Paris</option>
                            <option data-value="Europe/Sarajevo">(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb</option>
                            <option data-value="Africa/Lagos">(GMT+01:00) West Central Africa</option>
                            <option data-value="Asia/Amman">(GMT+02:00) Amman</option>
                            <option data-value="Europe/Athens">(GMT+02:00) Athens, Bucharest, Istanbul</option>
                            <option data-value="Asia/Beirut">(GMT+02:00) Beirut</option>
                            <option data-value="Africa/Cairo">(GMT+02:00) Cairo</option>
                            <option data-value="Africa/Harare">(GMT+02:00) Harare, Pretoria</option>
                            <option data-value="Europe/Helsinki">(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius</option>
                            <option data-value="Asia/Jerusalem">(GMT+02:00) Jerusalem</option>
                            <option data-value="Europe/Minsk">(GMT+02:00) Minsk</option>
                            <option data-value="Africa/Windhoek">(GMT+02:00) Windhoek</option>
                            <option data-value="Asia/Kuwait">(GMT+03:00) Kuwait, Riyadh, Baghdad</option>
                            <option data-value="Europe/Moscow">(GMT+03:00) Moscow, St. Petersburg, Volgograd</option>
                            <option data-value="Africa/Nairobi">(GMT+03:00) Nairobi</option>
                            <option data-value="Asia/Tbilisi">(GMT+03:00) Tbilisi</option>
                            <option data-value="Asia/Tehran">(GMT+03:30) Tehran</option>
                            <option data-value="Asia/Muscat">(GMT+04:00) Abu Dhabi, Muscat</option>
                            <option data-value="Asia/Baku">(GMT+04:00) Baku</option>
                            <option data-value="Asia/Yerevan">(GMT+04:00) Yerevan</option>
                            <option data-value="Asia/Kabul">(GMT+04:30) Kabul</option>
                            <option data-value="Asia/Yekaterinburg">(GMT+05:00) Yekaterinburg</option>
                            <option data-value="Asia/Karachi">(GMT+05:00) Islamabad, Karachi, Tashkent</option>
                            <option data-value="Asia/Calcutta">(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi</option>
                            <option data-value="Asia/Calcutta">(GMT+05:30) Sri Jayawardenapura</option>
                            <option data-value="Asia/Katmandu">(GMT+05:45) Kathmandu</option>
                            <option data-value="Asia/Almaty">(GMT+06:00) Almaty, Novosibirsk</option>
                            <option data-value="Asia/Dhaka">(GMT+06:00) Astana, Dhaka</option>
                            <option data-value="Asia/Rangoon">(GMT+06:30) Yangon (Rangoon)</option>
                            <option data-value="Asia/Bangkok">(GMT+07:00) Bangkok, Hanoi, Jakarta</option>
                            <option data-value="Asia/Krasnoyarsk">(GMT+07:00) Krasnoyarsk</option>
                            <option data-value="Asia/Hong_Kong">(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi</option>
                            <option data-value="Asia/Kuala_Lumpur">(GMT+08:00) Kuala Lumpur, Singapore</option>
                            <option data-value="Asia/Irkutsk">(GMT+08:00) Irkutsk, Ulaan Bataar</option>
                            <option data-value="Australia/Perth">(GMT+08:00) Perth</option>
                            <option data-value="Asia/Taipei">(GMT+08:00) Taipei</option>
                            <option data-value="Asia/Tokyo">(GMT+09:00) Osaka, Sapporo, Tokyo</option>
                            <option data-value="Asia/Seoul">(GMT+09:00) Seoul</option>
                            <option data-value="Asia/Yakutsk">(GMT+09:00) Yakutsk</option>
                            <option data-value="Australia/Adelaide">(GMT+09:30) Adelaide</option>
                            <option data-value="Australia/Darwin">(GMT+09:30) Darwin</option>
                            <option data-value="Australia/Brisbane">(GMT+10:00) Brisbane</option>
                            <option data-value="Australia/Canberra">(GMT+10:00) Canberra, Melbourne, Sydney</option>
                            <option data-value="Australia/Hobart">(GMT+10:00) Hobart</option>
                            <option data-value="Pacific/Guam">(GMT+10:00) Guam, Port Moresby</option>
                            <option data-value="Asia/Vladivostok">(GMT+10:00) Vladivostok</option>
                            <option data-value="Asia/Magadan">(GMT+11:00) Magadan, Solomon Is., New Caledonia</option>
                            <option data-value="Pacific/Auckland">(GMT+12:00) Auckland, Wellington</option>
                            <option data-value="Pacific/Fiji">(GMT+12:00) Fiji, Kamchatka, Marshall Is.</option>
                            <option data-value="Pacific/Tongatapu">(GMT+13:00) Nuku'alofa</option>
                        </select>
                    </div>
                    <div class="">
                        <label for=""> Description </label><br />
                        <vue-editor id="editor" v-model="description" useCustomImageHandler @image-added="handleImageAdded"></vue-editor>
                    </div>

                </div>

                <!-- form footer -->
                <div class="border-t flex justify-between lg:space-x-10 p-7 bg-gray-50 rounded-b-md">
                    <button type="button" class="button lg:w-1/2" @click="saveEvent">
                        Post Event
                    </button>
                </div>

            </div>

        </div>
    </div>

    <progress-loader v-if="eventPreloader == 'true'"></progress-loader>
    <dashboard-widgets></dashboard-widgets>

</div>
</template>

<script>
import {
    VueEditor
} from "vue2-editor";
import {
    ApiService
} from '@/services/api.service'
import Header from '@/include/Dashboard/Header.vue'
import Sidebar from '@/include/Dashboard/Sidebar.vue'
import DashboardWidgets from '@/components/DashboardWidgets'
import OpenChatBox from '@/include/Modals/OpenChatBox.vue'
import Feeds from '@/include/Dashboard/Feeds.vue'
import ProfileIcon from '@/include/Dashboard/Feed/ProfileIcon'
import ProgressLoader from '@/components/ProgressLoader'
import SingleFileUpload from '@/components/SingleFileUpload'
import axios from 'axios'

export default {
    name: 'CreateEvent',
    components: {
        Header,
        Sidebar,
        DashboardWidgets,
        OpenChatBox,
        Feeds,
        ProgressLoader,
        SingleFileUpload,
        VueEditor
    },

    data() {
        return {
            image: '',
            company_id: '',
            event_role_id: '',
            title: '',
            payment_type: '',
            type: '',
            website: '',
            image: '',
            description: '',
            sector: '',
            start_date: '',
            start_time: '',
            end_date: '',
            end_time: '',
            sectorTypes: [],
            eventTypes: [],
            eventPaymentTypes: [],
            application_link: '',
            timezone: '',
            start_min: '',
            start_max: '',
            end_min: '',
            end_max: '',
            eventPreloader: 'false'
        }
    },
    mounted: function () {
        let today = new Date()
        const start_min = new Date(today)
        const start_max = new Date(today)
        start_min.setDate(start_min.getDate() + 1)
        start_max.setDate(start_max.getDate() + 100)
        this.start_min = start_min.toISOString().split('T')[0]
        this.start_max = start_max.toISOString().split('T')[0]
        this.setEndMinMax()
        this.getExtraInfo()
    },
    methods: {
        async getExtraInfo() {
            this.eventPreloader = 'true'
            this.requestProgress = 'true'
            let apiResponse = await ApiService.ExtraInfo();
            if (apiResponse['status'] == 'success') {
                this.sectorTypes = apiResponse['data']['event_sectors']
                this.eventTypes = apiResponse['data']['event_types']
                this.eventPaymentTypes = apiResponse['data']['event_payment_types']
            }
            this.requestProgress = 'false'
            this.eventPreloader = 'false'
        },
        async saveEvent() {
            this.eventPreloader = 'true'
            let apiResponse = await ApiService.SaveEvent(this.title, this.payment_type, this.type, this.website, this.image, this.description, this.start_date, this.start_time, this.end_date, this.end_time, this.sector, this.timezone);
            this.$store.commit("toast/Notify", {
                type: apiResponse["status"],
                message: apiResponse["message"],
            });
            if (apiResponse["status"] != "success") {} else {
                this.event_role_id = ''
                this.company_id = ''
                this.website = ''
                this.title = ''
                this.image = ''
                this.description = ''
                this.event_type_id = ''
                this.selectedTags = ''
                this.application_link = ''

                location.href = '/dashboard/events'
            }
            this.eventPreloader = 'false'
        },
        onImageUploaded(response) {
            // Handle files like:
            // console.log('::::',response)
            if (response['status'] == 'success') {
                this.image = response['data']['file_name']
            }
            this.$store.commit("toast/Notify", {
                type: response["status"],
                message: response["message"],
            });
        },
        handleImageAdded: function (file, Editor, cursorLocation, resetUploader) {
            // An example of using FormData
            // NOTE: Your key could be different such as:
            // formData.append('file', file)

            var formData = new FormData();
            formData.append("file", file);

            axios({
                    url: this.$store.getters['host/apiUrl'].replace('/api/v2', '/files/upload'),
                    method: "POST",
                    data: formData
                })
                .then(result => {
                    // console.log('result', result)
                    const url = result.data.data['file_name']; // Get url from response
                    Editor.insertEmbed(cursorLocation, "image", url);
                    resetUploader();
                })
                .catch(err => {
                    console.log(err);
                });
        },
        setEndMinMax(){
            let start_date = this.start_date || new Date()
            const end_min = new Date(start_date)
            const end_max = new Date(start_date)
            end_min.setDate(end_min.getDate() + 1)
            end_max.setDate(end_max.getDate() + 100)
            this.end_min = end_min.toISOString().split('T')[0]
            this.end_max = end_max.toISOString().split('T')[0]

            // console.log('::::-----', this.start_min, this.start_max, this.end_min, this.end_max)
        }
    },
}
</script>

<style lang="scss" scoped>
.some-modal-content {
    min-width: 400px;
    padding: 25px;

    .buttons button {
        padding: 10px;
        margin: 10px;
    }
}

.postImgheight {
    height: 100px
}

.editor {
    height: 350px;
}
</style>
