<template>
<div id="wrapper">

    <Header />

    <sidebar></sidebar>

    <!-- Main Contents -->
    <div class="main_content">
        <div class="mcontainer">

            <!-- Profile cover -->
            <div class="profile user-profile">

                <div class="profiles_banner" v-if="loading == false" uk-lightbox>
                    <template v-if="bannerPhoto">
                        <a v-if="bannerPhoto" target="_blank" :href="bannerPhoto">
                            <img :src="bannerPhoto" alt="">
                        </a>
                    </template>
                    <template v-else>
                        <a v-if="currentUser['banner_photo']" target="_blank" :href="currentUser['banner_photo']">
                            <img :src="currentUser['banner_photo']" alt="">
                        </a>
                        <img v-else src="/assets/images/avatars/profile-cover.jpg" alt="">
                    </template>
                    <div v-if="thisUser['id'] == currentUser['id']" class="bannerCamera" @click="openBannerChange"> 
                        <i class="fa fa-camera"></i>
                    </div>
                    <div v-if="thisUser['id'] == currentUser['id']" class="profile_action absolute bottom-0 right-0 space-x-1.5 p-3 text-sm z-50 hidden lg:flex profilePage123">
                        <template v-if="bannerPhoto">
                            <a @click="saveBannerPhoto" href="JavaScript:Void(0);" class="mouse-pointer flex items-center justify-center h-8 px-3 rounded-md bg-gray-700 bg-opacity-70 text-white hover:text-white space-x-1.5">
                                <span> Save Banner Photo </span>
                            </a>
                            <a @click="removeBannerPhoto" href="JavaScript:Void(0);" class="mouse-pointer flex items-center justify-center h-8 px-3 rounded-md bg-gray-700 bg-opacity-70 text-white hover:text-white space-x-1.5">
                                <span> Remove Banner Photo </span>
                            </a>
                        </template>
                        <single-file-upload v-else id="banner_profile_change" type="image" @uploadSuccess="onBannerUploaded">
                            <a href="JavaScript:Void(0);" class="mouse-pointer flex items-center justify-center h-8 px-3 rounded-md bg-gray-700 bg-opacity-70 text-white hover:text-white space-x-1.5">
                                <span> Change Banner Photo </span>
                            </a>
                        </single-file-upload>
                        <template v-if="profilePhoto">
                            <a @click="saveProfilePhoto" href="JavaScript:Void(0);" class="mouse-pointer flex items-center justify-center h-8 px-3 rounded-md bg-gray-700 bg-opacity-70 text-white hover:text-white space-x-1.5">
                                <span> Save Profile Photo </span>
                            </a>
                            <a @click="removeProfilePhoto" href="JavaScript:Void(0);" class="mouse-pointer flex items-center justify-center h-8 px-3 rounded-md bg-gray-700 bg-opacity-70 text-white hover:text-white space-x-1.5">
                                <span> Remove Profile Photo </span>
                            </a>
                        </template>
                        <single-file-upload id="user_profile_change" v-else type="image" @uploadSuccess="onImageUploaded">
                            <a href="JavaScript:Void(0);" class="mouse-pointer flex items-center justify-center h-8 px-3 rounded-md bg-gray-700 bg-opacity-70 text-white hover:text-white space-x-1.5">
                                <span> Change Profile Photo </span>
                            </a>
                        </single-file-upload>
                    </div>
                </div>
                <div class="profiles_content" v-if="loading == false">

                    <div class="profile_avatar">
                        <div class="profile_avatar_holder" uk-lightbox>
                            <template v-if="profilePhoto">
                                <a target="_blank" :href="profilePhoto">
                                    <img :src="profilePhoto" alt="">
                                </a>
                            </template>
                            <template v-else>
                                <a v-if="currentUser['profile_photo']" target="_blank" :href="currentUser['profile_photo']">
                                    <img :src="currentUser['profile_photo']" alt="">
                                </a>
                                <img v-else :src="defaultImageUrl()" alt="">
                            </template>
                        </div>
                        <div v-if="thisUser.online == 'true'" class="user_status status_online"></div>
                        <div v-if="currentUser['id'] == thisUser['id']" class="icon_change_photo" @click="openProfileChange">
                            <i class="fa fa-camera"></i>
                        </div>
                    </div>

                    <div class="profile_info">
                        <h1> 
                            {{ currentUser['name'] }} 
                            <verified-badge v-if="currentUser['verified'] == 'true'" mt="one"></verified-badge>
                        </h1>
                        <!-- <p> {{ currentUser['email'] }} </p>
                            <p> {{ currentUser['bio'] }} <a v-if="thisUser['id'] == currentUser['id']" href="javascript:;">Edit </a></p> -->
                    </div>

                </div>

                <div v-if="loading == false" class="flex justify-between lg:border-t border-gray-100 flex-col-reverse lg:flex-row pt-2">
                    <nav v-if="!blocked_info" class="responsive-nav pl-3">
                        <ul uk-switcher="connect: #timeline-tab; animation: uk-animation-fade">
                            <li><a href="javascript:;">Timeline</a></li>
                            <li><a href="javascript:;">Friend <span>{{ followers }}</span> </a></li>
                            <li><a href="javascript:;">Photos </a></li>
                            <li><a href="javascript:;">Pages</a></li>
                            <li><a href="javascript:;">Groups</a></li>
                            <!-- <li><a href="#">Videos</a></li>  -->
                        </ul>
                    </nav>

                    <!-- button actions -->
                    <div class="flex items-center space-x-1.5 flex-shrink-0 pr-4 mb-2 justify-center order-1 relative mx-auto">

                        <template v-if="!blocked_info">
                            <!-- add story -->
                            <a v-if="follow == 'no'" @click="followUser" href="Javascript:;" class="flex items-center justify-center h-10 sm:px-5 px-3 rounded-md bg-blue-600 text-white space-x-1.5 text-white mouse-pointer hover:text-white">
                                <i class="fa fa-plus-circle"></i>
                                <span v-if="follows_me == 'yes'"> Follow back </span>
                                <span v-else> Follow </span>
                            </a>
                            <a v-if="follow == 'yes'" @click="unfollowUser" href="Javascript:;" class="flex items-center justify-center h-10 sm:px-5 px-3 rounded-md bg-gray-600 text-white space-x-1.5 text-white mouse-pointer hover:text-white">
                                <i class="fa fa-minus-circle"></i>
                                <span> Following </span>
                            </a>
                            <a v-if="follow == 'yes'" :href="'/dashboard/chats?type=user&id='+id" class="flex items-center justify-center h-10 sm:px-5 px-3 rounded-md bg-green-600 text-white space-x-1.5 text-white mouse-pointer hover:text-white">
                                <i class="fa fa-message"></i>
                                <span> Chat </span>
                            </a>
                        </template>
                        <template v-else>
                            <!-- add story -->
                            <a v-if="follow == 'no'" href="Javascript:;" class="flex items-center justify-center h-10 sm:px-5 px-3 rounded-md bg-gray-600 text-white space-x-1.5 text-white mouse-pointer hover:text-white">
                                <i class="fa fa-plus-circle"></i>
                                <span v-if="follows_me == 'yes'"> Follow back </span>
                                <span v-else> Follow </span>
                            </a>
                            <a v-if="follow == 'yes'" href="Javascript:;" class="flex items-center justify-center h-10 sm:px-5 px-3 rounded-md bg-gray-600 text-white space-x-1.5 text-white mouse-pointer hover:text-white">
                                <i class="fa fa-minus-circle"></i>
                                <span> Following </span>
                            </a>
                            <a v-if="follow == 'yes'" class="flex items-center justify-center h-10 sm:px-5 px-3 rounded-md bg-gray-600 text-white space-x-1.5 text-white mouse-pointer hover:text-white">
                                <i class="fa fa-message"></i>
                                <span> Chat </span>
                            </a>
                        </template>
                        <template v-if="disableBlockButtons == true">
                            <div>
                                <i class="fa fa-spin fa-spinner fa-2x"></i>
                            </div>
                        </template>
                        <template v-else>
                            <template v-if="currentUser.id != thisUser.id">
                                <a v-if="!blocked_info" @click="blockUser" href="Javascript:;" class="flex items-center justify-center h-10 sm:px-5 px-3 rounded-md bg-red-600 text-white space-x-1.5 text-white mouse-pointer hover:text-white">
                                    <i class="fa fa-block"></i>
                                    <span> Block </span>
                                </a>
                                <template v-else>
                                    <template v-if="blocked_info.user_id ==  thisUser.id">
                                        <a href="Javascript:;" @click="unblockUser" class="flex items-center justify-center h-10 sm:px-5 px-3 rounded-md bg-green-600 text-white space-x-1.5 text-white mouse-pointer hover:text-white">
                                            <i class="fa fa-block"></i>
                                            <span> Unblock </span>
                                        </a>
                                    </template>
                                </template>
                            </template>
                        </template>

                        <!-- search icon -->
                        <!-- <a href="#" class="flex items-center justify-center h-10 w-10 rounded-md bg-gray-100" uk-toggle="target: #profile-search;animation: uk-animation-slide-top-small">
                            <i name="" class="fa fa-search text-xl"></i>
                        </a> -->
                        <!-- search dropdown -->
                        <!-- <div class="absolute right-3 bg-white z-10 w-full flex items-center border rounded-md" id="profile-search" hidden="">
                            <input type="text" placeholder="Search.." class="flex-1">
                            <ion-icon name="close-outline" class="text-2xl hover:bg-gray-100 p-1 rounded-full mr-2 cursor-pointer" uk-toggle="target: #profile-search;animation: uk-animation-slide-top-small"></ion-icon>
                        </div> -->

                        <!-- more icon -->
                        <!-- <a href="#" class="flex items-center justify-center h-10 w-10 rounded-md bg-gray-100">
                            <i name="ellipsis-horizontal" class="fa fa-ellipsis-h text-xl"></i>
                        </a> -->
                        <!-- more drowpdown -->
                        <!-- <div class="bg-white w-56 shadow-md mx-auto p-2 mt-12 rounded-md text-gray-500 hidden border border-gray-100 dark:bg-gray-900 dark:text-gray-100 dark:border-gray-700" uk-drop="mode: click;pos: bottom-right;animation: uk-animation-slide-bottom-small; offset:5">
                            <ul class="space-y-1">
                                <li>
                                    <a href="#" class="flex items-center px-3 py-2 hover:bg-gray-100 hover:text-gray-800 rounded-md dark:hover:bg-gray-800">
                                        <ion-icon name="arrow-redo-outline" class="pr-2 text-xl"></ion-icon> Share Profile
                                    </a>
                                </li>
                                <li>
                                    <a href="#" class="flex items-center px-3 py-2 hover:bg-gray-100 hover:text-gray-800 rounded-md dark:hover:bg-gray-800">
                                        <ion-icon name="create-outline" class="pr-2 text-xl"></ion-icon> Account setting
                                    </a>
                                </li>
                                <li>
                                    <a href="#" class="flex items-center px-3 py-2 hover:bg-gray-100 hover:text-gray-800 rounded-md dark:hover:bg-gray-800">
                                        <ion-icon name="notifications-off-outline" class="pr-2 text-lg"></ion-icon> Disable notifications
                                    </a>
                                </li>
                                <li>
                                    <a href="#" class="flex items-center px-3 py-2 hover:bg-gray-100 hover:text-gray-800 rounded-md dark:hover:bg-gray-800">
                                        <ion-icon name="star-outline" class="pr-2 text-xl"></ion-icon> Add favorites
                                    </a>
                                </li>
                                <li>
                                    <hr class="-mx-2 my-2 dark:border-gray-800">
                                </li>
                                <li>
                                    <a href="#" class="flex items-center px-3 py-2 text-red-500 hover:bg-red-50 hover:text-red-500 rounded-md dark:hover:bg-red-600">
                                        <ion-icon name="stop-circle-outline" class="pr-2 text-xl"></ion-icon> Unfriend
                                    </a>
                                </li>
                            </ul>
                        </div> -->
                    </div>

                </div>

            </div>

            <div v-if="!blocked_info" class="uk-switcher lg:mt-8 mt-4" id="timeline-tab">

                <!-- Timeline -->
                <timeline-screen :currentUser="currentUser" :followers_count="followers" :followings_count="followings" :followers_list="followers_list_6" :followings_list="followings_list"></timeline-screen>

                <!-- Friends  -->
                <friend-screen :currentUser="currentUser"></friend-screen>

                <!-- Photos  -->
                <photo-screen :currentUser="currentUser"></photo-screen>

                <!-- Pages  -->
                <page-screen :currentUser="currentUser"></page-screen>

                <!-- Groups  -->
                <group-screen :currentUser="currentUser"></group-screen>

                <!-- Videos -->
                <video-screen :currentUser="currentUser"></video-screen>

            </div>
            <div v-else class="bg-white px-2 py-3 text-center">
                <h5 v-if="blocked_info.user_id ==  thisUser.id">You blocked this user</h5>
                <h5 v-else>You have been blocked by this user</h5>
            </div>
            <div v-if="loading == true" class="bg-white px-2 py-3 text-center">
                <i class="fa fa-spin fa-spinner fa-2x"></i>
            </div>

        </div>
    </div>
    <update-user-data></update-user-data>

    <dashboard-widgets page="user-timeline"></dashboard-widgets>
    <div id="progressLoader">
        <progress-loader></progress-loader>
    </div>

</div>
</template>

<script>
import {
    VueEditor
} from "vue2-editor";
import {
    ApiService
} from '@/services/api.service'
import Header from '@/include/Dashboard/Header.vue'
import Sidebar from '@/include/Dashboard/Sidebar.vue'
import DashboardWidgets from '@/components/DashboardWidgets'
import OpenChatBox from '@/include/Modals/OpenChatBox.vue'
import ProfileIcon from '@/include/Dashboard/Feed/ProfileIcon'
import ProgressLoader from '@/components/ProgressLoader'
import TimelineScreen from '@/include/Dashboard/Timeline/TimelineScreen'
import FriendScreen from '@/include/Dashboard/Timeline/FriendScreen'
import PhotoScreen from '@/include/Dashboard/Timeline/PhotoScreen'
import PageScreen from '@/include/Dashboard/Timeline/PageScreen'
import GroupScreen from '@/include/Dashboard/Timeline/GroupScreen'
import VideoScreen from '@/include/Dashboard/Timeline/VideoScreen'
import SingleFileUpload from '@/components/SingleFileUpload'
import IonIcon from '@/components/IonIcon'
import axios from 'axios'
import VerifiedBadge from '@/components/VerifiedBadge'
import UpdateUserData from '@/include/Modals/UpdateUserData'

export default {
    name: 'UserTimeline',
    components: {
        Header,
        Sidebar,
        DashboardWidgets,
        ProgressLoader,
        VueEditor,
        TimelineScreen,
        FriendScreen,
        PhotoScreen,
        PageScreen,
        GroupScreen,
        VideoScreen,
        SingleFileUpload,
        IonIcon,
        VerifiedBadge,
        UpdateUserData,
    },
    data() {
        return {
            user: '',
            feeds: '',
            followers_list: [],
            followers_list_6: [],
            followings_list: [],
            currentUser: {},
            thisUser: {},
            profilePhoto: '',
            bannerPhoto: '',
            followings: '',
            followers: '',
            follow: '',
            follows_me: '',
            id: '',
            blocked_info: '',
            disableBlockButtons: true,
            loading: true,
        }
    },
    mounted () {
        this.thisUser = this.$store.getters["user/currentUser"];
        let id = this.$route.params['id']
        this.id = id
        this.getUserInfo(id)
        this.userFollowership()
    },
    methods: {
        async getUserInfo(id) {
            this.toggleProgress('show')
            this.loading = true
            let apiResponse = await ApiService.GetUser(id);
            // console.log('GetUser', apiResponse)
            if (apiResponse['status'] == 'success') {
                this.currentUser = apiResponse['data']['user']
                if(this.currentUser.id != this.id){
                    location.href = '/dashboard/timeline/'+this.currentUser.id
                }
                this.followings = apiResponse['data']['followings_count'].toString()
                this.followers = apiResponse['data']['followers_count'].toString()
                this.follow = apiResponse['data']['follow']
                this.follows_me = apiResponse['data']['follows_me']
                this.blocked_info = apiResponse['data']['blocked_info']
                this.disableBlockButtons = false
            }
            this.loading = false
            this.toggleProgress('hide')
        },
        async followUser() {
            this.toggleProgress('show')
            let apiResponse = await ApiService.FollowUser(this.id);
            // console.log('FollowUser', apiResponse)
            this.$store.commit("toast/Notify", {
                type: apiResponse["status"],
                message: apiResponse["message"],
            });
            if (apiResponse['status'] == 'success') {
                this.userFollowership()
            }
            this.toggleProgress('hide')
        },
        async unfollowUser() {
            this.toggleProgress('show')
            let apiResponse = await ApiService.UnfollowUser(this.id);
            // console.log('UnfollowUser', apiResponse)
            this.$store.commit("toast/Notify", {
                type: apiResponse["status"],
                message: apiResponse["message"],
            });
            if (apiResponse['status'] == 'success') {
                this.userFollowership()
            }
            this.toggleProgress('hide')
        },
        async userFollowership() {
            this.toggleProgress('show')
            let apiResponse = await ApiService.UserFollowership(this.id, 0);
            // console.log('UserFollowership', apiResponse)
            if (apiResponse['status'] == 'success') {
                this.followings = apiResponse['data']['followings_count'].toString()
                this.followers = apiResponse['data']['followers_count'].toString()
                this.follow = apiResponse['data']['follow']
                this.followers_list = apiResponse['data']['followers']
                this.followers_list_6 = this.followers_list.slice(0, 6)
            }
            this.toggleProgress('hide')
        },
        async saveBannerPhoto() {
            this.toggleProgress('show')
            let apiResponse = await ApiService.SaveBannerPhoto(this.bannerPhoto);
            // console.log('getUserInfo', apiResponse)
            this.$store.commit("toast/Notify", {
                type: apiResponse["status"],
                message: apiResponse["message"],
            });
            if (apiResponse['status'] == 'success') {
                let currentUser = this.userDataToCommitToStore(apiResponse['user'])
                this.$store.commit('user/SAVE_CURRENT_USER', currentUser)
                location.href = ''
            }
            this.toggleProgress('hide')
        },
        async saveProfilePhoto() {
            this.toggleProgress('show')
            let apiResponse = await ApiService.SaveProfilePhoto(this.profilePhoto);
            // console.log('getUserInfo', apiResponse)
            this.$store.commit("toast/Notify", {
                type: apiResponse["status"],
                message: apiResponse["message"],
            });
            if (apiResponse['status'] == 'success') {
                let currentUser = this.userDataToCommitToStore(apiResponse['user'])
                this.$store.commit('user/SAVE_CURRENT_USER', currentUser)
                location.href = ''
            }
            this.toggleProgress('hide')
        },
        async blockUser() {
            this.toggleProgress('show')
            this.disableBlockButtons = true
            let apiResponse = await ApiService.BlockUser(this.id);
            this.$store.commit("toast/Notify", {
                type: apiResponse["status"],
                message: apiResponse["message"],
            });
            if (apiResponse['status'] == 'success') {
                setTimeout(() => {
                    location.href = ''
                }, 1300);
            }
            this.disableBlockButtons = false
            this.toggleProgress('hide')
        },
        async unblockUser() {
            this.toggleProgress('show')
            this.disableBlockButtons = true
            let apiResponse = await ApiService.UnblockUser(this.id);
            this.$store.commit("toast/Notify", {
                type: apiResponse["status"],
                message: apiResponse["message"],
            });
            if (apiResponse['status'] == 'success') {
                setTimeout(() => {
                    location.href = ''
                }, 1300);
            }
            this.disableBlockButtons = false
            this.toggleProgress('hide')
        },
        onImageUploaded(response) {
            // Handle files like:
            // console.log('::::',response)
            if (response['status'] == 'success') {
                this.profilePhoto = response['data']['file_name']
            }
            this.$store.commit("toast/Notify", {
                type: response["status"],
                message: response["message"],
            });
            this.saveProfilePhoto()
        },
        onBannerUploaded(response) {
            // Handle files like:
            // console.log('::::',response)
            if (response['status'] == 'success') {
                this.bannerPhoto = response['data']['file_name']
            }
            this.$store.commit("toast/Notify", {
                type: response["status"],
                message: response["message"],
            });
            this.saveBannerPhoto()
        },
        removeBannerPhoto() {
            this.bannerPhoto = ''
        },
        removeProfilePhoto() {
            this.profilePhoto = ''
        }
    },
}
</script>

<style>
.profilePage123 input {
    width: 157px !important;
    height: 32px !important;
}

@media (max-width: 640px) {
    .line {
        height: 55px !important;
    }
}
</style>
