<template>
<div id="wrapper">

    <Header />

    <sidebar></sidebar>

    <!-- Main Contents -->
    <div class="main_content">
        <div class="mcontainer">

            <div class="flex justify-between relative md:mb-4 mb-3">
                <div class="flex-1">
                    <h2 class="text-2xl font-semibold"> Courses </h2>
                    <nav class="responsive-nav border-b md:m-0 -mx-4">
                        <ul>
                            <li :class="randomActive"><a @click="changeCousesSection('random')" href="javascript:;" class="lg:px-2"> Suggestions </a></li>
                            <li :class="recentActive"><a @click="changeCousesSection('recent')" href="javascript:;" class="lg:px-2"> Newest </a></li>
                            <li :class="enrolledActive"><a @click="changeCousesSection('enrolled')" href="javascript:;" class="lg:px-2"> Enrolled Courses </a></li>
                        </ul>
                    </nav>
                </div>
                <a href="/dashboard/courses/create" class="flex items-center justify-center h-9 lg:px-5 px-2 rounded-md bg-blue-600 text-white space-x-1.5 absolute right-0">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5">
                        <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clip-rule="evenodd"></path>
                    </svg>
                    <span class="md:block hidden"> Create </span>
                </a>
            </div>

            <div class="relative uk-slider" uk-slider="finite: true">

                <div class="uk-slider-container px-1 py-3">
                    <ul class="uk-slider-items uk-child-width-1-4@m uk-child-width-1-3@s uk-child-width-1-2 uk-grid-small uk-grid" style="transform: translate3d(0px, 0px, 0px);">
                        <li v-for="c in courses" tabindex="-1" class="uk-active">
                            <div>
                                <a :href="'/dashboard/course/'+c.id" class="w-full md:h-36 h-28 overflow-hidden rounded-lg relative inline-block">
                                    <img v-if="c.id" :src="c.image" alt="" class="w-full h-full absolute inset-0 object-cover">
                                    <img v-else :src="defaultPostImage" alt="" class="w-full h-full absolute inset-0 object-cover">
                                    <span class="absolute bg-black bg-opacity-60 bottom-1 font-semibold px-1.5 py-0.5 right-1 rounded text-white text-xs"> -</span>
                                    <img src="/assets/images/icon-play.svg" class="w-12 h-12 uk-position-center" alt="">
                                </a>
                                <div class="pt-3">
                                    <a href="course-intro.html" class="font-semibold line-clamp-2"> {{ c.title }} </a>
                                    <div class="pt-2">
                                        <a :href="'/dashboard/timeline/'+c.user.id" class="text-sm font-medium" v-if="c.user"> 
                                            {{ c.user.name }} 
                                            <verified-badge v-if="c.user.verified == 'true'" size="small"></verified-badge>
                                        </a>
                                        <div class="flex space-x-2 items-center text-sm">
                                            <div> {{ c.level }} level</div>
                                            <div class="md:block hidden">·</div>
                                            <div> {{ c.enrolled_number || 0 }} Enrolled</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <div v-if="coursesPreloader == 'false' && courses.length == 0" class="bg-white p-2">
                        <h5>No Course</h5>
                    </div>

                    <a class="absolute bg-white top-16 flex items-center justify-center p-2 -left-4 rounded-full shadow-md text-xl w-9 z-10 dark:bg-gray-800 dark:text-white uk-invisible" href="#" uk-slider-item="previous"> <i class="icon-feather-chevron-left"></i></a>
                    <a class="absolute bg-white top-16 flex items-center justify-center p-2 -right-4 rounded-full shadow-md text-xl w-9 z-10 dark:bg-gray-800 dark:text-white" href="#" uk-slider-item="next"> <i class="icon-feather-chevron-right"></i></a>

                </div>
            </div>

            <br>

            <div class="md:mb-4 mb-3">
                <h2 class="text-2xl font-semibold"> My Courses </h2>
                <nav class="responsive-nav border-b md:m-0 -mx-4">
                    <ul>
                        <li :class="randomActiveUser"><a @click="changeUserCousesSection('random')" href="javascript:;" class="lg:px-2"> Suggestions </a></li>
                        <li :class="recentActiveUser"><a @click="changeUserCousesSection('recent')" href="javascript:;" class="lg:px-2"> Newest </a></li>
                        <li :class="popularActiveUser"><a @click="changeUserCousesSection('popular')" href="javascript:;" class="lg:px-2"> Popular </a></li>
                    </ul>
                </nav>
            </div>

            <!--  videos  -->

            <div class="divide-y card px-4">

                <div v-for="c in userCourses" @click="courseClicked(c.id)" class="flex md:space-x-6 space-x-4 md:py-5 py-3 relative">
                    <a :href="'/dashboard/course/'+c.id" class="md:w-64 md:h-40 w-36 h-24 overflow-hidden rounded-lg relative shadow-sm">
                        <img v-if="c.image" :src="c.image" alt="" class="w-full h-full absolute inset-0 object-cover">
                        <img v-else :src="defaultPostImage" alt="" class="w-full h-full absolute inset-0 object-cover">
                        <div class="absolute bg-yellow-100 font-semibold px-2.5 py-1 rounded-full text-yellow-500 text-xs top-2.5 left-2.5">
                            {{ c.sub_category }}
                        </div>
                        <img src="/assets/images/icon-play.svg" class="w-12 h-12 uk-position-center" alt="">
                    </a>
                    <div class="flex-1 space-y-2">

                        <a href="javascript:;" class="md:text-xl font-semibold line-clamp-2"> {{ c.title }} </a>
                        <p class="leading-6 pr-4 line-clamp-2 md:block hidden"> {{ c.sub_title }}</p>
                        <a v-if="c.user" :href="'/dashboard/timeline/'+c.user.id" class="font-semibold block text-sm"> 
                            {{ c.user.name }}
                            <verified-badge v-if="c.user.verified == 'true'" size="small"></verified-badge>
                        </a>

                        <div class="flex items-center justify-between">
                            <div class="flex space-x-3 items-center text-sm md:pt-3 font-medium">
                                <div> <i class="icon-feather-sliders mr-2"></i> {{ c.level }} level </div>
                                <div class="md:block hidden">·</div>
                                <div> <i class="icon-feather-users mr-2"></i> {{ c.enrollment_number || 0 }} Enrolled</div>
                            </div>
                            <a href="Javascript:;" class="md:flex items-center justify-center h-9 px-8 rounded-md border hidden bg-gray-100">View Course </a>
                        </div>

                        <div class="absolute top-1 right-0 md:inline hidden">
                            <a href="javascript:;" class="hover:bg-gray-200 p-1.5 inline-block rounded-full" aria-expanded="false">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"></path>
                                </svg>
                            </a>
                            <div class="bg-white w-56 shadow-md mx-auto p-2 mt-12 rounded-md text-gray-500 hidden text-base border border-gray-100 dark:bg-gray-900 dark:text-gray-100 dark:border-gray-700 uk-drop uk-drop-bottom-right" uk-drop="mode: hover;pos: top-right" style="left: -188px; top: -12px;">

                                <ul class="space-y-1">
                                    <li>
                                        <a @click="setCurrentLink(c.id, c.title)" href="javascript:;" uk-toggle="target: #share-post-modal" x class="flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800">
                                            <i class="uil-share-alt mr-1"></i> Share
                                        </a>
                                    </li>
                                    <li>
                                        <a :href="'/dashboard/update-course/'+c.id" class="flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800">
                                            <i class="uil-edit-alt mr-1"></i> Edit Course
                                        </a>
                                    </li>
                                    <li>
                                        <hr class="-mx-2 my-2 dark:border-gray-800">
                                    </li>
                                </ul>

                            </div>
                        </div>

                    </div>
                </div>

                <div v-if="userCoursesPreloader == 'false' && userCourses.length == 0" class="bg-white p-2">
                    <h5>You dont have any saved course yet, add one</h5>
                </div>

            </div>

        </div>
    </div>

    <progress-loader v-if="coursesPreloader == 'true'"></progress-loader>
    <progress-loader v-if="userCoursesPreloader == 'true'"></progress-loader>
    <dashboard-widgets page="courses"></dashboard-widgets>
    <share-post-modal v-if="shareLink" :link="shareLink" :description="shareDescription"></share-post-modal>

</div>
</template>

<script>
import {
    VueEditor
} from "vue2-editor";
import {
    ApiService
} from '@/services/api.service'
import Header from '@/include/Dashboard/Header.vue'
import Sidebar from '@/include/Dashboard/Sidebar.vue'
import DashboardWidgets from '@/components/DashboardWidgets'
import OpenChatBox from '@/include/Modals/OpenChatBox.vue'
import Feeds from '@/include/Dashboard/Feeds.vue'
import ProfileIcon from '@/include/Dashboard/Feed/ProfileIcon'
import ProgressLoader from '@/components/ProgressLoader'
import SingleFileUpload from '@/components/SingleFileUpload'
import axios from 'axios'
import SharePostModal from '@/include/Modals/SharePostModal'
import VerifiedBadge from '@/components/VerifiedBadge'

export default {
    name: 'Courses',
    components: {
        Header,
        Sidebar,
        DashboardWidgets,
        OpenChatBox,
        Feeds,
        ProgressLoader,
        SingleFileUpload,
        SharePostModal,
        VueEditor,
        VerifiedBadge,
    },

    data() {
        return {
            image: '',
            category: '',
            category_user: '',
            brand_id: '',
            name: '',
            currency: 'USD',
            amount: '',
            description: '',
            in_stoack: '',
            brands: '',
            sizes: '',
            colors: '',
            product_sizes: [],
            product_colors: [],
            categories: '',
            currencies: '',
            categorySubcategory: '',
            category_id: '',
            sub_category: '',
            sub_category_user: '',
            userCourses: '',
            courses: '',
            randomActive: '',
            recentActive: '',
            popularActive: '',
            enrolledActive: '',
            randomActiveUser: '',
            recentActiveUser: '',
            popularActiveUser: '',
            shareLink: '',
            requestProgress: 'false',
            coursesPreloader: 'false',
            userCoursesPreloader: 'false'
        }
    },
    mounted: function () {
        this.changeCousesSection('random')
        this.changeUserCousesSection('random')
    },
    methods: {
        async getUserCourses() {
            this.userCoursesPreloader = 'true'
            this.requestProgress = 'true'
            let apiResponse = await ApiService.GetUserCourses(this.activeUserCourseSection, this.category_user, this.sub_category_user);
            // console.log('getUserCourses', apiResponse)
            if (apiResponse['status'] == 'success') {
                this.userCourses = apiResponse['data']['courses']
            }
            this.userCoursesPreloader = 'false'
            this.requestProgress = 'false'
        },
        async getCourses() {
            this.requestProgress = 'true'
            this.coursePreloader = 'true'
            let apiResponse = await ApiService.GetCourses(this.activeCourseSection, this.category, this.sub_category);
            // console.log('getUserCourses', apiResponse)
            if (apiResponse['status'] == 'success') {
                this.courses = apiResponse['data']['courses']
            }
            this.requestProgress = 'false'
            this.coursePreloader = 'false'
        },
        changeCousesSection(a){
            this.randomActive = ''
            this.recentActive = ''
            this.popularActive = ''
            this.enrolledActive = ''
            switch (a) {
                case 'random':
                    this.randomActive = 'active'
                    this.activeCourseSection = 'random'
                    this.getCourses()
                    break;
            
                case 'recent':
                    this.recentActive = 'active'
                    this.activeCourseSection = 'recent'
                    this.getCourses()
                    break;
            
                case 'popular':
                    this.popularActive = 'active'
                    this.activeCourseSection = 'popular'
                    this.getCourses()
                    break;
            
                case 'enrolled':
                    this.enrolledActive = 'active'
                    this.activeCourseSection = 'enrolled'
                    this.getCourses()
                    break;
            
                default:
                    break;
            }
        },
        changeUserCousesSection(a){
            this.randomActiveUser = ''
            this.recentActiveUser = ''
            this.popularActiveUser = ''
            this.enrolledActive = ''
            switch (a) {
                case 'random':
                    this.randomActiveUser = 'active'
                    this.activeUserCourseSection = 'random'
                    this.getUserCourses()
                    break;
            
                case 'recent':
                    this.recentActiveUser = 'active'
                    this.activeUserCourseSection = 'recent'
                    this.getUserCourses()
                    break;
            
                case 'popular':
                    this.popularActiveUser = 'active'
                    this.activeUserCourseSection = 'popular'
                    this.getUserCourses()
                    break;
            
                default:
                    break;
            }
        },
        setCurrentLink(id, description) {
            let thisLink = this.$store.getters["host/appUrl"]+'/dashboard/course/'+id
            this.shareLink = thisLink
        },
        courseClicked(id) {
            let thisLink = this.$store.getters["host/appUrl"]+'/dashboard/course/'+id
            this.openLink(thisLink)
        },
        onImageUploaded(response) {
            // Handle files like:
            // console.log('::::',response)
            if (response['status'] == 'success') {
                this.image = response['data']['file_name']
            }
            this.$store.commit("toast/Notify", {
                type: response["status"],
                message: response["message"],
            });
        },
        handleImageAdded: function (file, Editor, cursorLocation, resetUploader) {
            // An example of using FormData
            // NOTE: Your key could be different such as:
            // formData.append('file', file)

            var formData = new FormData();
            formData.append("file", file);

            axios({
                    url: this.$store.getters['host/apiUrl'].replace('/api/v2', '/files/upload'),
                    method: "POST",
                    data: formData
                })
                .then(result => {
                    // console.log('result', result)
                    const url = result.data.data['file_name']; // Get url from response
                    Editor.insertEmbed(cursorLocation, "image", url);
                    resetUploader();
                })
                .catch(err => {
                    console.log(err);
                });
        }
    },
}
</script>

<style lang="scss" scoped>
.some-modal-content {
    min-width: 400px;
    padding: 25px;

    .buttons button {
        padding: 10px;
        margin: 10px;
    }
}

.postImgheight {
    height: 100px
}

.editor {
    height: 350px;
}
</style>
