<template>
<!-- Craete post modal -->
<div id="create-post-modal" class="create-post is-story" uk-modal="">
    <div v-if="currentUser" class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical rounded-lg p-0 lg:w-5/12 relative shadow-2xl uk-animation-slide-bottom-small" style="height: 90vh;overflow-y: scroll;">
        <div class="text-center py-3 border-b">
            <h3 class="text-lg font-semibold">Create Post</h3>
            <button class="uk-modal-close-default bg-gray-100 rounded-full p-2.5 right-2" type="button" uk-close="" uk-tooltip="title: Close ; pos: bottom ;offset:7"></button>
        </div>
        <div>
            <div class="flex items-start space-x-4 p-3 justify-between">
                <div>
                    <img v-if="profilePhoto" :src="profilePhoto" class="bg-gray-200 border border-white rounded-full w-11 h-11">
                    <img v-else :src="defaultImageUrl()" class="bg-gray-200 border border-white rounded-full w-11 h-11">
                </div>
                <div class="">
                    <div class="flex flex-1 items-center lg:justify-end justify-center space-x-2">
                        <single-file-preload type="image" @uploadSuccess="onImageUploaded">
                            <svg class="bg-blue-100 h-9 p-1.5 rounded-full text-blue-600 w-9 cursor-pointer" fill="none" stroke="currentColor" viewbox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"></path>
                            </svg>
                        </single-file-preload>
                        <single-file-preload type="audio" @uploadSuccess="onAudioUploaded">
                            <svg class="text-purple-600 h-9 p-1.5 rounded-full bg-purple-100 w-9 cursor-pointer" old-id="veiw-more" fill="none" stroke="currentColor" viewbox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 19V6l12-3v13M9 19c0 1.105-1.343 2-3 2s-3-.895-3-2 1.343-2 3-2 3 .895 3 2zm12-3c0 1.105-1.343 2-3 2s-3-.895-3-2 1.343-2 3-2 3 .895 3 2zM9 10l12-3"></path>
                            </svg>
                        </single-file-preload>
                        <single-file-preload type="video" @uploadSuccess="onVideoUploaded">
                            <svg class="text-pink-600 h-9 p-1.5 rounded-full bg-pink-100 w-9 cursor-pointer" old-id="veiw-more" fill="none" stroke="currentColor" viewbox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z"></path>
                            </svg>
                        </single-file-preload>
                    </div>
                </div>
            </div>
            <div class="flex flex-1 items-start space-x-4 p-3 template_bg">
                <div class="flex-1 pt-2">
                    <textarea id="feed_content" v-model="content" autofocus @keyup="manageKeyUp" class="create_post_input uk-textare shadow-none focus:shadow-none text-xl font-medium resize-none" rows="5" :placeholder="'What\'s up? ' + nameOfPoster + '!'"></textarea>
                </div>
            </div>
            <div class="create_post_input_tags"></div>
            <div>
                <hr/>
            </div>
            <template v-if="templateOpen == 'true'">
                <div v-if="show_template_list == 'true'" class="flex justify-centerold my-2">
                    <div class="my-2.5 mx-3 px-2.5 py-2" @click="toggleFeedTemplateList()">
                        <i class="fa fa-remove"></i>
                    </div>
                    <div class="my-2.5 mx-3">
                    </div>
                    <div v-if="show_template_list == 'true'" v-for="feed_template in feed_templates" class="my-1 mx-1" @click="setFeedTemplate(feed_template)">
                        <img v-if="template == feed_template" :src="apiUrl+''+feed_template" class="feed_template_img feed_template_img_active" alt="" />
                        <img v-else :src="apiUrl+''+feed_template" class="feed_template_img" alt="" />
                    </div>
                </div>
                <div v-else class="flex my-2">
                    <div class="my-2.5 mx-3 px-2.5 py-2" @click="toggleFeedTemplateList()">
                        <i class="fa fa-display"></i>
                    </div>
                </div>
            </template>
            <div v-if="templateOpen == 'true'">
                <hr/>
            </div>
            <div class="mx-3">
                <div class="row m-0" v-if="images_raw.length > 0">
                    <h5 class="col-sm-12 p-0 mt-1 pt-2 mb-3 pb-3">Selected Images</h5>
                    <new-images-component :images="images_raw" @removeImage="removeImage" ></new-images-component>
                </div>
                <div class="row m-0" v-if="audios_raw.length > 0">
                    <h5 class="col-sm-12 p-0 mt-1 pt-2 mb-3 pb-3">Selected Audios</h5>
                    <div v-for="(audio, index) in audios_raw" class="p-0 text-center col-sm-12 mb-3 pb-3">
                        <audio-player-component :src="audio"></audio-player-component>
                        <a href="javascript:;" v-on:click="removeAudio(index)">remove audio</a>
                    </div>
                </div>
                <div class="row m-0" v-if="videos_raw.length > 0">
                    <h5 class="col-sm-12 p-0 mt-1 pt-2 mb-3 pb-3">Selected Videos</h5>
                    <div v-for="(video, index) in videos_raw" class="p-0 text-center videoPreview col-sm-12 mb-3 pb-3">
                        <video-player-component :type="videos_type[index]":src="video"></video-player-component>
                        <a href="javascript:;" v-on:click="removeVideo(index)">remove video</a>
                    </div>
                </div>
            </div>
            <div v-if="images_raw.length > 0 || audios_raw.length > 0 || videos_raw.length > 0">
                <hr/>
            </div>
            <div class="post-buttonsold">
                <div class="bsolute bottom-0 px-3 py-2 p-sm-3 space-x-4 w-full">
                    <!-- <div class="flex bg-gray-50 border border-purple-100 rounded-2xl p-2 shadow-sm items-center">
                        <div class="lg:block hidden ml-1">Add to your post</div>
                        <div class="flex flex-1 items-center lg:justify-end justify-center space-x-2">
                            <single-file-preload type="image" @uploadSuccess="onImageUploaded">
                                <svg class="bg-blue-100 h-9 p-1.5 rounded-full text-blue-600 w-9 cursor-pointer" fill="none" stroke="currentColor" viewbox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"></path>
                                </svg>
                            </single-file-preload>
                            <single-file-preload type="audio" @uploadSuccess="onAudioUploaded">
                                <svg class="text-purple-600 h-9 p-1.5 rounded-full bg-purple-100 w-9 cursor-pointer" old-id="veiw-more" fill="none" stroke="currentColor" viewbox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 19V6l12-3v13M9 19c0 1.105-1.343 2-3 2s-3-.895-3-2 1.343-2 3-2 3 .895 3 2zm12-3c0 1.105-1.343 2-3 2s-3-.895-3-2 1.343-2 3-2 3 .895 3 2zM9 10l12-3"></path>
                                </svg>
                            </single-file-preload>
                            <single-file-preload type="video" @uploadSuccess="onVideoUploaded">
                                <svg class="text-pink-600 h-9 p-1.5 rounded-full bg-pink-100 w-9 cursor-pointer" old-id="veiw-more" fill="none" stroke="currentColor" viewbox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z"></path>
                                </svg>
                            </single-file-preload>
                        </div>
                    </div> -->
                    <div class="mt-4 mb-2 mx-0" v-if="showPostAs == 'true'">
                        <h5>Post as</h5>
                        <select class="story" v-model="post_as">
                            <option v-for="s in share_as" :value="s">
                                <span v-if="s.toLowerCase() == 'user'">
                                    {{ currentUser['name'] }}({{ s }})
                                </span>
                                <span v-else>
                                    {{ nameOfPoster }}({{ s }})
                                </span>
                            </option>
                        </select>
                    </div>
                </div>
                <div v-if="showPostAs == 'true'">
                    <hr/>
                </div>
                <div class="flex items-center w-full justify-between border-t px-3 py-2 p-sm-3 pb-3">
                    <div class="mt-2old mr-2">
                        <select class="story" v-model="reach">
                            <option value="only-you">Only me</option>
                            <option value="every-one">Every one</option>
                            <option value="people-i-follow">People I Follow</option>
                            <option value="people-who-follow-me">People Who Follow Me</option>
                        </select>
                    </div>

                    <div class="flex space-x-2 mb-5old">
                        <button @click="saveFeedFlow()" style="padding: 21px;" class="bg-blue-600 flex h-9 items-center justify-center rounded-md text-white px-5 font-medium">
                            Share
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    ApiService
} from "@/services/api.service";
import {
    FileUploadService
} from 'v-file-upload'
import SingleFilePreload from "../../components/SingleFilePreload"
import AudioPlayerComponent from '@/components/AudioPlayerComponent'
import VideoPlayerComponent from '@/components/VideoPlayerComponent'
import NewImagesComponent from '@/components/Feed/NewImagesComponent'
import ProgressLoader from '@/components/ProgressLoader'
export default {
    name: "CreatePost",
    props: {
        name: String
    },
    data() {
        return {
            currentYear: new Date().getFullYear(),
            reach: "every-one",
            appName: "",
            content: "",
            profilePhoto: "",
            userFullName: "",
            group_id: "",
            page_id: "",
            event_id: "",
            product_id: "",
            nameOfPoster: '',
            post_as: 'user',
            share_as: ['user'],
            headers: {},
            images_raw: [],
            videos_raw: [],
            videos_type: [],
            audios_raw: [],
            images_raw_data: [],
            videos_raw_data: [],
            audios_raw_data: [],
            images: [],
            videos: [],
            audios: [],
            loading: false,
            type: '',
            url: '',
            noOfImages: 0,
            currentUser: '',
            showPostAs: 'false',
            apiUrl: '',
            template: '',
            show_template_list: 'true',
            templateOpen: 'true',
            feed_templates: [],
        };
    },
    components: {
        SingleFilePreload,
        AudioPlayerComponent,
        VideoPlayerComponent,
        NewImagesComponent,
    },
    mounted () {
        this.appName = this.$APP_NAME;
        this.uploadUrl = this.$store.getters["host/apiUrl"] + "/upload_file";
        this.url = this.$store.getters['host/apiUrl'].replace('/api/v2', '/files/upload')
        this.currentUser = this.$store.getters["user/currentUser"];
        if (this.currentUser) {
            this.profilePhoto = this.currentUser['profile_photo']
            this.userFullName = this.currentUser['name']
        }
        let urlSplit = location.href.split('viewer=')
        if(urlSplit.length == 2){
            this.post_as = urlSplit[1]
        }
        let id = this.$route.params['id']
        if (location.pathname == "/dashboard" || location.pathname == "/dashboard/feeds" || location.pathname == "/dashboard/feed" || location.pathname == "/dashboard/timeline") {
            this.showPostAs = 'false'
        }else{
            this.showPostAs = 'true'
        }
        console.log('showPostAs', this.showPostAs, location.pathname, location.pathname == "/dashboard/feeds", location.pathname == "/dashboard/feeds" > -1 )
        if (location.href.search("/page/") > -1) {
            this.page_id = id
            this.share_as.push('page')
        }
        if (location.href.search("/group/") > -1) {
            this.group_id = id
            this.share_as.push('group')
        }

        if(this.name){
            this.nameOfPoster = this.name
        }else{
            this.nameOfPoster = this.userFullName
        }
        this.getExtraInfo()
        this.apiUrl = this.$store.getters['host/apiUrl'].replace('/api/v1','').replace('/api/v2','').replace('/api/v3','')
    },
    methods: {
        async saveFeed() {
            this.content = $("#feed_content").val()
            if (!this.content) {
                if (this.images_raw_data.length == 0 && this.audios_raw_data.length == 0 && this.videos_raw_data.length == 0) {
                    return this.$store.commit("toast/Notify", {
                        type: "warning",
                        message: "what up is required",
                    });
                }
            }
            if(this.template){
                this.template = this.apiUrl+''+this.template
            }
            let apiResponse = await ApiService.SaveFeed(
                this.content,
                this.type,
                this.reach,
                this.images,
                this.audios,
                this.videos,
                this.group_id,
                this.page_id,
                '',
                this.post_as,
                this.template,
            );
            if (apiResponse["status"] != "success") {
                this.$store.commit("toast/Notify", {
                    type: apiResponse["status"],
                    message: apiResponse["message"],
                });
            } else {
                this.$root.$emit("refresh-feeds")
                this.content = ''
                this.images = []
                this.audios = []
                this.videos = []

                location.href = ''
            }
        },
        async saveFeedFlow(){
            this.content = $("#feed_content").val()
            if (!this.content) {
                if (this.images_raw_data.length == 0 && this.audios_raw_data.length == 0 && this.videos_raw_data.length == 0) {
                    return this.$store.commit("toast/Notify", {
                        type: "warning",
                        message: "whats up is required",
                    });
                }
            }
            this.toggleProgress('show')
            this.$root.$emit("saving-feed")
            this.closeModal()
            // this.noty('success','Processing, kindly do not refresh the page')
            this.noty('success','Posting')
            if(this.images_raw_data.length > 0){
                // this.noty('success','Saving photos')
                let images = this.images_raw_data
                for (let im = 0; im < images.length; im++) {
                    const element = images[im];
                    
                    if(element){
                        let apiResponse = await this.saveFile(element)
                        // console.log('apiResponse', apiResponse)
                        if(apiResponse && apiResponse['status'] == 'success'){
                            this.images.push(apiResponse['data']['file_name'])
                        }

                    }
                }
            }
            if(this.audios_raw_data.length > 0){
                // this.noty('success','Saving photos')
                let audios = this.audios_raw_data
                for (let im = 0; im < audios.length; im++) {
                    const element = audios[im];
                    
                    if(element){
                        let apiResponse = await this.saveFile(element)
                        // console.log('apiResponse', apiResponse)
                        if(apiResponse && apiResponse['status'] == 'success'){
                            this.audios.push(apiResponse['data']['file_name'])
                        }
                    }
                }
            }
            if(this.videos_raw_data.length > 0){
                // this.noty('success','Saving videos')
                let videos = this.videos_raw_data
                for (let im = 0; im < videos.length; im++) {
                    const element = videos[im];
                    
                    if(element){
                        let apiResponse = await this.saveFile(element)
                        // console.log('apiResponse', apiResponse)
                        if(apiResponse && apiResponse['status'] == 'success'){
                            this.videos.push(apiResponse['data']['file_name'])
                        }
                    }
                }
            }
            this.saveFeed()
            this.$root.$emit("saved-feed")
            this.toggleProgress('hide')
        },
        async getExtraInfo() {
            let apiResponse = await ApiService.ExtraInfo();
            if (apiResponse['status'] == 'success') {
                this.feed_templates = ''
                this.feed_templates = apiResponse['data']['feed_templates']
            }
        },
        onImageUploaded(response) {
            this.images_raw_data.push(response)
            this.images_raw.push(URL.createObjectURL(response))
            this.syncNoOfImages()
            this.manageTemplate()
        },
        onAudioUploaded(response) {
            this.audios_raw_data.push(response)
            this.audios_raw.push(URL.createObjectURL(response))
            this.manageTemplate()
        },
        onVideoUploaded(response) {
            this.videos_type.push(response['type'])
            this.videos_raw_data.push(response)
            this.videos_raw.push(URL.createObjectURL(response))
            this.manageTemplate()
        },
        closeModal() {
            // $('#create-post-modal').removeClass('uk-open').hide();
            $(".uk-modal-close-default").click()
        },
        removeImage(i){
            this.images_raw_data.splice(i,1)
            this.images_raw.splice(i,1)
            this.syncNoOfImages()
            this.manageTemplate()
        },
        removeAudio(i){
            this.audios_raw_data.splice(i,1)
            this.audios_raw.splice(i,1)
            this.manageTemplate()
        },
        removeVideo(i){
            this.videos_type.splice(i,1)
            this.videos_raw_data.splice(i,1)
            this.videos_raw.splice(i,1)
            this.manageTemplate()
        },
        setFeedTemplate(a){
            this.content = $("#feed_content").val()
            this.template = a
            if(a.search('-six') > -1){
                $("#feed_content").css('color','#333')
                $("#feed_content").removeClass('white_ph')
                $("#feed_content").addClass('black_ph')
            }else{
                $("#feed_content").css('color','#fff')
                $("#feed_content").removeClass('black_ph')
                $("#feed_content").addClass('white_ph')
            }
            $("#feed_content").css('text-align','center')
            $("#feed_content").css('background','transparent')
            $(".template_bg").css('background','url("'+this.apiUrl+''+a+'")')
        },
        clearFeedTemplate(){
            this.content = $("#feed_content").val()
            $("#feed_content").css('color','#333')
            $("#feed_content").removeClass('white_ph')
            $("#feed_content").removeClass('black_ph')
            $("#feed_content").css('text-align','left')
            $("#feed_content").css('background','transparent')
            $(".template_bg").css('background','')
        },
        toggleFeedTemplateList(){
            this.content = $("#feed_content").val()
            if(this.show_template_list == 'true'){
                this.show_template_list = 'false'
                this.template = ''
                this.clearFeedTemplate()
            }else{
                this.show_template_list = 'true'
            }
            console.log('this.show_template_list',this.show_template_list)
        },
        async saveFile(file) {
            // this.noty('success','uploading '+file['name'])
            this.loading = true
            let fileUpload = new FileUploadService(this.url, this.headers, this.onProgress)
            let e = await fileUpload.upload(file, {})
            e = e.target.response
            this.loading = false
            // this.noty('success','uploaded '+file['name'])
            // console.log('EEE', e)
            return e
        },
        onProgress(event) {
            // Handdle the progress
            // console.log(event)
        },
        syncNoOfImages(){
            this.noOfImages = this.images_raw.length
            if(this.noOfImages == 2){
                this.imageLayout = 'long'
            }else if(this.noOfImages == 3){
                this.imageLayout = 'long'
            }else if(this.noOfImages == 5){
                this.imageLayout = 'short'
            }
        },
        manageKeyUp(){
            this.resizeTextArea('#feed_content')
            this.manageTagging('.create_post_input')
        },
        manageTemplate(){
            let templateOpen = 'true'
            if(this.images_raw_data.length > 0){
                templateOpen = 'false'
            }else if(this.audios_raw_data.length > 0){
                templateOpen = 'false'
            }else if(this.videos_raw_data.length > 0){
                templateOpen = 'false'
            }else{
                templateOpen = 'true'
            }

            if(templateOpen == 'true'){
                this.templateOpen = 'true'
            }else{
                this.template = ''
                this.templateOpen = 'false'
                this.clearFeedTemplate()
            }
        }
    },
};
</script>
<style scoped>
.dropup .dropdown-toggle::after {
    display: none;
    height: 0;
    visibility: hidden;
}
#feed_content {
    overflow-y: auto;
    word-wrap:break-word;
    max-height: 50vh
}
@media (max-width: 520px) {
    .post-buttons {
        position: fixed;
        bottom: 0;
        max-width: 100%;
        width: 100%;
    }
  }
</style>
<style >
    .videoPreview>div>div {
        width: 100% !important;
    }
    .videoPreview .vjs-big-play-button {
        display: none !important;
    }
    #create-post-modal > div {
        max-height: 90vh;
        overflow-x: scroll;
    }
    .feed_template_img {
        height: 40px;
        width: 40px;
        border-radius: 10px;
        object-fit: fill;
        cursor: pointer;
        border: 2px solid #eee;
    }
    .template_control_buttons {
        border: 2px solid #eee;
        border-radius: 10px;
    }
    .feed_template_img_active {
        border: 2px solid #000;
    }
    .white_ph::-webkit-input-placeholder {
        color: #fff
    }
    .black_ph::-webkit-input-placeholder {
        color: #000
    }
</style>
