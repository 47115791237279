<template>
<div id="wrapper">
    <auth-styles v-if="pageNameMain" :pageNameMain="pageNameMain" :pageDescriptionMain="pageDescriptionMain" :pageImageMain="pageImageMain"></auth-styles>
    <Header />

    <sidebar></sidebar>

    <!-- Main Contents -->
    <div class="main_content">
        <div class="mcontainer" v-if="group">

            <div class="profile is_group">

                <div class="profiles_banner">
                    <img v-if="group.image" :src="group.image" alt="">
                    <img v-else :src="defaultPostUrl()" alt="">
                </div>
                <div class="profiles_content">

                    <div class="profile_info">
                        <h1> 
                            {{ group.name }} 
                            <verified-badge v-if="group.verified == 'true'" size="small" mt="one"></verified-badge>
                        </h1>
                        <p> {{ group.reach }} group · {{ group.followers_count }} members</p>
                        <div v-if="group.user_id == currentUser.id" class="mt-4 mb-2 mx-0">
                            <h5 class="">View as</h5>
                            <select @change="setPageViewer()" class="story col-sm-4" v-model="post_as">
                                <option v-for="s in share_as" :value="s">
                                    <span v-if="s.toLowerCase() == 'user'">
                                        {{ currentUser['name'] }}({{ s }})
                                    </span>
                                    <span v-else>
                                        {{ group['name'] }}({{ s }})
                                    </span>
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="sm:flex items-center space-x-4">
                        <div class="mb-2 sm:flex items-center -space-x-4">
                            <template v-if="group.last_five_followers && group.last_five_followers.length > 0">
                                <template v-for="follower in group.last_five_followers" v-if="follower.user">
                                    <img v-if="follower.user.profile_photo" alt="Image placeholder" :src="follower.user.profile_photo" class="m-auto w-10 h-10 rounded-full border-2 border-white">
                                    <img v-else alt="Image placeholder" :src="defaultPostUrl()" class="m-auto w-10 h-10 rounded-full border-2 border-white">
                                </template>
                            </template>
                            <div v-if="group.followers_count > group.last_five_followers.length" class="w-10 h-10 rounded-full flex justify-center items-center bg-red-100 text-red-500 font-semibold"> {{ group.followers_count-group.last_five_followers.length }}+
                            </div>
                        </div>

                        <a v-if="!group.user_follow" href="javascript:;" @click="followGroup" class="mb-2 flex items-center justify-center h-9 px-5 rounded-md bg-blue-600 text-white  space-x-1.5">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5">
                                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clip-rule="evenodd"></path>
                            </svg>
                            <span> Follow </span>
                        </a>
                        <a v-else href="javascript:;" @click="unfollowGroup" class="mb-2 flex items-center justify-center h-9 px-3 rounded-md bg-green-100 text-blue-500">
                            Following
                        </a>
                        <a v-if="group.user_id == currentUser.id" :href="'/dashboard/update-group/'+group.id" class="mb-2 flex items-center justify-center h-9 px-5 rounded-md bg-yellow-600 text-white  space-x-1.5">
                            <span> Edit Group Info </span>
                        </a>
                    </div>

                </div>

                <nav class="responsive-nav border-t -mb-0.5 lg:pl-3.5">
                    <ul>
                        <li class="active"><a href="#0"> Home</a></li>
                    </ul>
                </nav>

            </div>

            <div class="md:flex md:space-x-6 lg:mx-16">
                <div class="space-y-5 flex-shrink-0 md:w-7/12">
                    <template v-if="group.user_follow || group.user_id ==  currentUser.id">
                        <create-post-component v-if="currentUser && currentUser.id == group.user_id" :name="group.name+' Group'"></create-post-component>
    
                        <feeds section="group"></feeds>
                    </template>
                    <template v-else>
                        <div class="bg-white px-2 py-3">
                            <h5>Only Members of the Group can see the Group Feeds</h5>
                        </div>
                    </template>

                </div>
                <div class="w-full flex-shirink-0">

                    <div class="card p-4 mb-5">

                        <h1 class="block text-lg font-bold"> About </h1>

                        <div class="space-y-4 mt-3">

                            <div class="flex items-center space-x-3">
                                <i name="people" class="fa fa-users bg-gray-100 p-2 rounded-full text-2xl md hydrated" role="img" aria-label="people"></i>
                                <div class="flex-1">
                                    <div class="font-semibold"> {{ group['followers_count'] }} Members </div>
                                </div>
                            </div>
                            <div class="flex items-start space-x-3" v-if="group['reach']">
                                <i name="globe-outline" class="fa fa-globe bg-gray-100 p-2 rounded-full text-2xl md hydrated" role="img" aria-label="people"></i>
                                <div class="flex-1">
                                    <div class="font-bold text-capitalize"> {{ group['reach'] }} </div>
                                    <div v-if="group['reach'] == 'public'"> Anyone can see who's in the group and what they post. </div>
                                    <div v-if="group['reach'] == 'private'"> Only group members can see who's in the group and what they post. </div>
                                </div>
                            </div>
                            <div class="flex items-center space-x-3" v-if="group['website']">
                                <i name="unlink" class="fa fa-link bg-gray-100 p-1.5 rounded-full text-xl md hydrated" role="img" aria-label="unlink"></i>
                                <div class="flex-1">
                                    <div> <a :href="group['website']" target="_blank" class="text-blue-500"> {{ group['website'] }} </a> </div>
                                </div>
                            </div>
                            <div class="flex items-center space-x-3" v-if="group['email']">
                                <i name="mail-open" class="fa fa-envelope bg-gray-100 p-1.5 rounded-full text-xl md hydrated" role="img" aria-label="mail open"></i>
                                <div class="flex-1">
                                    <div> <a :href="'mailto:'+group['email']" class="text-blue-500">{{ group['email'] }}</a> </div>
                                </div>
                            </div>
                            <div class="flex items-center space-x-3" v-if="group['category']">
                                <i name="albums" class="fa fa-suitcase bg-gray-100 p-1.5 rounded-full text-xl md hydrated" role="img" aria-label="albums"></i>
                                <div class="flex-1">
                                    <div> <a href="javascript:;" class="text-blue-500">{{ group['category'] }}</a> </div>
                                </div>
                            </div>

                        </div>

                    </div>

                    <div class="widget card p-5 border-t">
                        <div class="flex items-center justify-between mb-2">
                            <div>
                                <h4 class="text-lg font-semibold"> Related groups </h4>
                            </div>
                            <a href="javascript:;" class="text-blue-600 d-none"> See all</a>
                        </div>
                        <div>
                            <div v-for="rg in related_groups" class="flex items-center space-x-4 rounded-md -mx-2 p-2 hover:bg-gray-50">
                                <a :href="'/dashboard/group/'+rg.id" class="w-12 h-12 flex-shrink-0 overflow-hidden rounded-full relative">
                                    <img v-if="rg.image" :src="rg.image" class="absolute w-full h-full inset-0 " alt="">
                                    <img v-else :src="defaultPostUrl()" class="absolute w-full h-full inset-0 " alt="">
                                </a>
                                <div class="flex-1">
                                    <a :href="'/dashboard/group/'+rg.id" class="text-base font-semibold capitalize"> 
                                        {{ rg.name }} 
                                        <verified-badge v-if="rg.verified == 'true'" size="small" mt="one"></verified-badge>
                                    </a>
                                    <div class="text-sm text-gray-500 mt-0.5"> {{ rg.followers_count }} Following</div>
                                </div>
                                <a v-if="!rg.user_follow" href="javascript:;" class="flex items-center justify-center h-8 px-3 rounded-md text-sm border font-semibold bg-blue-500 text-white">
                                    Follow
                                </a>
                                <a v-else href="javascript:;" class="flex items-center justify-center h-8 px-3 rounded-md text-sm border font-semibold bg-green-500 text-white">
                                    Following
                                </a>
                            </div>
                            <div class="bg-white p-2">
                                <h5 v-if="related_groups.length == 0">No related group yet</h5>
                            </div>

                        </div>
                    </div>

                </div>
            </div>

        </div>
        <div id="progressLoader">
            <progress-loader></progress-loader>
        </div>
    </div>

    <dashboard-widgets page="group" v-if="group" :createPostName="group.name+' Group'"></dashboard-widgets>
</div>
</template>

<script>
import {
    ApiService
} from '@/services/api.service'
import Header from '@/include/Dashboard/Header.vue'
import Sidebar from '@/include/Dashboard/Sidebar.vue'
import DashboardWidgets from '@/components/DashboardWidgets'
import OpenChatBox from '@/include/Modals/OpenChatBox.vue'
import Feeds from '@/include/Dashboard/Feeds.vue'
import ProfileIcon from '@/include/Dashboard/Feed/ProfileIcon'
import ProgressLoader from '@/components/ProgressLoader'
import ShareIcons from '@/components/ShareIcons'
import AuthStyles from '@/include/Auth/AuthStyles'
import CreatePostComponent from '@/components/CreatePostComponent'
import VerifiedBadge from '@/components/VerifiedBadge'

export default {
    name: 'Group',
    components: {
        Header,
        Sidebar,
        DashboardWidgets,
        OpenChatBox,
        Feeds,
        ProgressLoader,
        ShareIcons,
        AuthStyles,
        CreatePostComponent,
        VerifiedBadge,
    },

    data() {
        return {
            group: '',
            related_groups: '',
            feeds: '',
            recent_topics: '',
            comment: '',
            reply: '',
            currentUser: {},
            thisPage: '',
            pagination: {
                limit: 20,
                offset: 0,
                total: 60
            },
            pageNameMain: '',
            pageDescriptionMain: '',
            pageImageMain: '',
            replyToReply: '',
            post_as: 'user',
            share_as: ['user','group'],
            requestProgress: 'false'
        }
    },
    mounted: function () {
        this.currentUser = this.$store.getters['user/currentUser'] || {}
        let id = this.$route.params['id']
        this.getGroup(id)
        this.thisPage = location.href
        let urlSplit = this.thisPage.split('viewer=')
        if(urlSplit.length == 2){
            this.post_as = urlSplit[1]
        }
    },
    methods: {
        async getGroup(id) {
            this.toggleProgress('show')
            let apiResponse = await ApiService.GetGroup(id);
            if (apiResponse["status"] != "success") {} else {
                this.group = apiResponse["data"]['group'];
                this.feeds = apiResponse["data"]['feeds'];
                this.related_groups = apiResponse["data"]['related_groups'];
                if (this.topic) {
                    this.pageNameMain = this.topic['title']
                    this.pageDescriptionMain = this.topic['title']
                    this.pageImageMain = this.topic['image']
                }
            }
            this.toggleProgress('hide')
        },
        async deleteGroupPost() {
            this.toggleProgress('show')
            let apiResponse = await ApiService.DeleteGroupPost(this.topic['id']);
            this.$store.commit("toast/Notify", {
                type: apiResponse["status"],
                message: apiResponse["message"],
            });
            if (apiResponse["status"] != "success") {} else {
                location.href = '/dashboard/groups'
            }
        },
        confirmDelete() {
            let proceed = confirm("Are you sure you want to proceed?");
            if (proceed) {
                this.deleteGroupPost()
            } else {
                //don't proceed
            }
        },
        async addGroupPostComment(post_id) {
            this.toggleProgress('show')
            let apiResponse = await ApiService.AddGroupPostComment(post_id, this.comment);
            this.$store.commit("toast/Notify", {
                type: apiResponse["status"],
                message: apiResponse["message"],
            });
            if (apiResponse["status"] != "success") {} else {
                this.comment = ''
                this.topic = apiResponse["data"];
            }
            this.toggleProgress('hide')
        },
        async replyGroupPostComment(post_id, comment_id) {
            this.toggleProgress('show')
            let apiResponse = await ApiService.ReplyGroupPostComment(post_id, comment_id, this.reply);
            this.$store.commit("toast/Notify", {
                type: apiResponse["status"],
                message: apiResponse["message"],
            });
            if (apiResponse["status"] != "success") {} else {
                this.reply = ''
                this.topic = apiResponse["data"];
            }
            this.toggleProgress('hide')
        },
        async replyGroupPostCommentReply(post_id, reply_id) {
            this.toggleProgress('show')
            let apiResponse = await ApiService.ReplyGroupPostCommentReply(post_id, reply_id, this.replyToReply);
            this.$store.commit("toast/Notify", {
                type: apiResponse["status"],
                message: apiResponse["message"],
            });
            if (apiResponse["status"] != "success") {} else {
                this.replyToReply = ''
                this.topic = apiResponse["data"];
            }
            this.toggleProgress('hide')
        },
        async followGroup() {
            this.toggleProgress('show')
            let apiResponse = await ApiService.FollowGroup(this.group['id']);
            this.toggleProgress('hide')
            this.$store.commit("toast/Notify", {
                type: apiResponse["status"],
                message: apiResponse["message"],
            });
            if (apiResponse["status"] != "success") {} else {
                location.href = ''
            }
        },
        async unfollowGroup() {
            let proceed = confirm("Are you sure you want to Unfollow this Group?");
            if (proceed) {
                this.toggleProgress('show')
                let apiResponse = await ApiService.UnfollowGroup(this.group['id']);
                this.toggleProgress('hide')
                this.$store.commit("toast/Notify", {
                    type: apiResponse["status"],
                    message: apiResponse["message"],
                });
                if (apiResponse["status"] != "success") {} else {
                    location.href = ''
                }
            } else {
                //don't proceed
            }
        },
        setPageViewer(){
            let post_as = this.post_as
            location.href = '?viewer='+post_as
        },
    },
}
</script>

<style lang="scss" scoped>
.some-modal-content {
    min-width: 400px;
    padding: 25px;

    .buttons button {
        padding: 10px;
        margin: 10px;
    }
}

.profile .profiles_banner img {
    object-fit: contain;
    margin: auto;
    text-align: center;
}
</style>
