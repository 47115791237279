<template>
    <span v-if="comment" class="p-4old space-y-3">
        <span v-html="thisComment"></span>
        <span v-if="show_more_less_button == 'true'">
            <span class="mouse-pointer text-blue" v-if="is_less == 'true'" @click="lessChange('false')">show more</span>
            <span class="mouse-pointer text-blue" v-else @click="lessChange('true')">show less</span>
        </span>
    </span>
</template>

<script>
import {
    ApiService
} from '@/services/api.service'

export default {
    name: "CommentsManager",
    props: {
        comment: String,
    },
    data() {
        return {
            show_more_less_button: 'false',
            is_less: 'true',
            thisComment: '',
        };
    },
    components: {
    },
    mounted: function () {
        this.currentUser = this.$store.getters["user/currentUser"];
        this.lessChange('true')
    },
    methods: {
        moreLessManager() {
            this.show_more_less_button = 'false'
            let a = this.customTextRender(this.comment)
            let max_length = 50
            let textSplit = a.split(" ")
            if (textSplit.length > max_length) {
                this.show_more_less_button = 'true'
                if (this.is_less == 'true') {
                    textSplit = textSplit.slice(0, max_length)
                } else {
                }
            }
            a = textSplit.join(' ')
            if(this.show_more_less_button == 'true' && this.is_less == 'true'){
                a = a+'...'
            }
            this.thisComment = a
        },
        lessChange(a) {
            this.is_less = a
            this.moreLessManager()
        },
    }
};
</script>
    
    
<style >
</style>
