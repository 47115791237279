<template>

    <div class="space-y-7">
        <auth-styles v-if="pageImageMain" pageNameMain="Feed" :pageDescriptionMain="pageDescriptionMain" :pageImageMain="pageImageMain"></auth-styles>
        <progress-loader v-if="pendingRequest == true"></progress-loader>
        <template v-else>
            <template v-if="feed">
                <user-feed v-if="feed" :feed="feed" :is_admin="is_admin" :preview="show_preview" :user_currency="currency" :can_show_ad="can_show_ad"></user-feed>
                <edit-feed-post></edit-feed-post>
            </template>
            <template v-else>
                <div class="bg-white p-2 text-center">
                    <p>
                        Feed not found or has been deleted
                    </p>
                </div>
            </template>
        </template>
        <progress-loader-simple v-if="pendingRequest == true"></progress-loader-simple>
        <share-post-modal></share-post-modal>
        <edit-feed-post></edit-feed-post>
        <report-content></report-content>
        <edit-feed-comment></edit-feed-comment>
        <likers-modal></likers-modal>
    </div>
</template>

<script>
import AuthStyles from '@/include/Auth/AuthStyles'
import { ApiService } from '@/services/api.service'
import UserFeed from '@/include/Dashboard/Feed/UserFeed'
import SharePostModal from '@/include/Modals/SharePostModal'
import ProgressLoaderSimple from '@/components/ProgressLoaderSimple'
import ProgressLoader from '@/components/ProgressLoader'
import GoogleBannerAd from '@/include/Dashboard/Feed/GoogleBannerAd'
import ShareFeedPost from '@/include/Modals/ShareFeedPost'
import EditFeedPost from '@/include/Modals/EditFeedPost'
import ReportContent from '@/include/Modals/ReportContent'
import EditFeedComment from '@/include/Modals/EditFeedComment'
import LikersModal from '@/include/Modals/LikersModal'
export default {
    name: "Feeds",
    props: {
        section: String,
        preview: String,
        can_show_ad: String
    },
    data() {
        return {
            feed: '',
            id: 0,
            pendingRequest: false,
            group_id: "",
            page_id: "",
            is_admin: '',
            pageDescriptionMain: '',
            pageImageMain: '',
            show_preview: 'true',
            currentUser: '',
            currency: '',
        };
    },
    components: {
        AuthStyles,
        UserFeed,
        ProgressLoaderSimple,
        ProgressLoader,
        GoogleBannerAd,
        ShareFeedPost,
        SharePostModal,
        EditFeedPost,
        ReportContent,
        EditFeedComment,
        LikersModal,
    },
    mounted () {
        let currentUser = this.$store.getters["user/currentUser"] || "";
        this.currentUser = currentUser;

        let id = this.$route.params['id']
        if(this.preview){
            this.show_preview = this.preview 
        }
        this.id = id
        this.pageImageMain = this.defaultImageUrl()
        console.log('===currentUser',currentUser)
        if(!currentUser){
            this.getFeedPublic()
        }else{
            this.getMe()
            this.getFeed()
        }
    },
    methods: {
        async getFeed(){
            if(this.pendingRequest == true){
                // console.log('Pending Req')
                return
            }
            this.pendingRequest = true
            let apiResponse = await ApiService.GetFeed(this.id)
            if(apiResponse['status'] != 'success'){
                this.checkTokenValidity(apiResponse)
            }else{
                this.feed = apiResponse['data']
                this.currency = apiResponse['currency']

                this.pageDescriptionMain = this.feed.content.substr(0,150)
            }
            this.pendingRequest = false
        },
        async getMe(){
            let apiResponse = await ApiService.GetMe()
            if(apiResponse['status'] != 'success'){
                this.checkTokenValidity(apiResponse)
            }else{
                this.is_admin = apiResponse['data']['is_admin']
            }
        },
        async getFeedPublic(){
            if(this.pendingRequest == true){
                // console.log('Pending Req')
                return
            }
            this.pendingRequest = true
            let apiResponse = await ApiService.GetFeedPublic(this.id)
            if(apiResponse['status'] != 'success'){
            }else{
                this.pendingRequest = false
                this.feed = apiResponse['data']

                this.pageDescriptionMain = this.feed.content.substr(0,150)
            }
        },
    }
};
</script>

<style lang="scss" scoped></style>
