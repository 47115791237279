<template>
<div class="flex items-center space-x-4" v-if="group">
    <div class="w-20 h-20 flex-shrink-0 rounded-md relative mb-3">
        <img v-if="group.image" :src="group.image" class="absolute w-full h-full inset-0 rounded-md object-cover shadow-sm" alt="">
        <img v-else :src="defaultPostUrl()" class="absolute w-full h-full inset-0 rounded-md object-cover shadow-sm" alt="">
    </div>
    <div class="flex-1 border-b pb-3" @click="openGroup(group.id)">
        <a href="javascript:;" class="text-lg font-semibold capitalize"> 
            {{ group.name }}
            <verified-badge v-if="page.verified == 'true'" size="small" mt="one"></verified-badge>
        </a>
        <div class="flex space-x-2 items-center text-sm">
            <div> {{ group.followers_count }} Members</div>
            <div>·</div>
            <div> </div>
        </div>
        <div class="flex items-center mt-2">
            <template v-if="group.last_five_followers && group.last_five_followers.length > 0">
                <template v-for="follower in group.last_five_followers" v-if="follower.user">
                    <img v-if="follower.user.profile_photo" alt="Image placeholder" :src="follower.user.profile_photo" class="border-2 border-white rounded-full w-7">
                    <img v-else alt="Image placeholder" :src="defaultPostUrl()" class="border-2 border-white rounded-full w-7">
                </template>
            </template>
            <div class="text-sm text-gray-500 ml-2"></div>
        </div>

    </div>
    <a v-if="!group.user_follow" href="javascript:;" @click="followGroup" class="flex items-center justify-center h-9 px-3 rounded-md bg-blue-100 text-blue-500">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 mr-2">
            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clip-rule="evenodd"></path>
        </svg>Follow
    </a>
    <a v-else href="javascript:;" @click="unfollowGroup" class="flex items-center justify-center h-9 px-3 rounded-md bg-green-100 text-blue-500">
        Following
    </a>
</div>
</template>

<script>
import {
    ApiService
} from "@/services/api.service";
import SharePostModal from '@/include/Modals/SharePostModal'
import VerifiedBadge from '@/components/VerifiedBadge'

export default {
    name: 'GroupSuggestionComponent',
    props: {
        group: Object
    },
    data() {
        return {
            currentUser: '',
            shareLink: '',
            shareDescription: ''
        }
    },
    components: {
        SharePostModal,
        VerifiedBadge,
    },
    mounted() {
        this.currentUser = this.$store.getters["user/currentUser"];
        this.shareDescription = 'Group on Vitisocial'
    },
    methods: {
        async followGroup() {
            this.toggleProgress('show')
            let apiResponse = await ApiService.FollowGroup(this.group['id']);
            this.toggleProgress('hide')
            this.$store.commit("toast/Notify", {
                type: apiResponse["status"],
                message: apiResponse["message"],
            });
            if (apiResponse["status"] != "success") {} else {
                location.href = ''
            }
        },
        async unfollowGroup() {
            this.toggleProgress('show')
            let apiResponse = await ApiService.UnfollowGroup(this.group['id']);
            this.toggleProgress('hide')
            this.$store.commit("toast/Notify", {
                type: apiResponse["status"],
                message: apiResponse["message"],
            });
            if (apiResponse["status"] != "success") {} else {
                location.href = ''
            }
        },
        isHappening() {},
        setCurrentLink(id, description) {
            let thisLink = this.$store.getters["host/seoUrl"] + '/group/' + id
            this.shareLink = thisLink
        },
        openGroup(id) {
            location.href = '/dashboard/group/' + id
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
