<template>
    <div id="wrapper">
    
        <Header />
    
        <sidebar></sidebar>
    
        <!-- Main Contents -->
        <div class="main_content">
            <div class="mcontainer">
                
                <div>
                    <div class="bg-white px-3 py-2 text-center">
                        <h4>Under Maintainance</h4>
                    </div>
                </div>
    
            </div>
        </div>
    
    </div>
    </template>
    
        
        
    <script>
    import {
        VueEditor
    } from "vue2-editor";
    import {
        ApiService
    } from '@/services/api.service'
    import Header from '@/include/Dashboard/Header.vue'
    import Sidebar from '@/include/Dashboard/Sidebar.vue'
    
    export default {
        name: 'UserTimeline',
        components: {
            Header,
            Sidebar,
        },
        data() {
            return {
                user: '',
                feeds: '',
                loading: true,
            }
        },
        mounted () {
            this.thisUser = this.$store.getters["user/currentUser"];
            let id = this.$route.params['id']
            this.id = id
        },
        methods: {
            async getUserInfo(id) {
                this.toggleProgress('show')
                this.loading = true
                let apiResponse = await ApiService.GetUser(id);
                // console.log('GetUser', apiResponse)
                if (apiResponse['status'] == 'success') {
                    this.currentUser = apiResponse['data']['user']
                    this.followings = apiResponse['data']['followings_count'].toString()
                    this.followers = apiResponse['data']['followers_count'].toString()
                    this.follow = apiResponse['data']['follow']
                    this.blocked_info = apiResponse['data']['blocked_info']
                    this.disableBlockButtons = false
                }
                this.loading = false
                this.toggleProgress('hide')
            },
        },
    }
    </script>
        
        
    <style>
    .profilePage123 input {
        width: 157px !important;
        height: 32px !important;
    }
    
    @media (max-width: 640px) {
        .line {
            height: 55px !important;
        }
    }
    </style>
    