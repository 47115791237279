<template>
    <div v-if="chat.is_blocked == 'true'" class="message-time-sign">
        <span v-if="chat.blocked_by == currentUser['id']">This conversation has been blocked by you</span>
        <span v-else>This conversation has been blocked</span>
    </div>
</template>

<script>
export default {
    props: {
        chat: Object,
    },
    name: 'BlockChat',
    data() {
        return {
            currentUser: '',
            currentShop: '',
        }
    },
    mounted: function () {
        this.currentUser = this.$store.getters['user/currentUser'] || {}
    },
    methods: {
        setMessageToReply(a) {},
    },
}
</script>
