<template>
<div v-if="group" class="flex items-center space-x-4 py-3 hover:bg-gray-100 rounded-md -mx-2 px-2">
    <div class="w-14 h-14 flex-shrink-0 rounded-md relative">
        <img v-if="group.image" :src="group.image" alt="" class="absolute w-full h-full inset-0 rounded-md object-cover">
        <img v-else :src="defaultPostUrl()" alt="" class="absolute w-full h-full inset-0 rounded-md object-cover">
    </div>
    <div class="flex-1">
        <a :href="'/dashboard/group/'+group.id" class="text-lg font-semibold capitalize"> 
            {{ group.name }} 
            <verified-badge v-if="page.verified == 'true'" size="small" mt="one"></verified-badge>
        </a>
        <div class="text-sm text-gray-500 mt-0.5"> {{ group.followers_count }} Member</div>
    </div>
    <a v-if="!group.user_follow" href="javascript:;" @click="followGroup" class="flex items-center justify-center h-9 px-4 rounded-md bg-orange text-white font-semibold">
        Follow
    </a>
    <a v-else href="javascript:;" class="flex items-center justify-center h-9 px-4 rounded-md bg-gray-200 font-semibold">
        Following
    </a>
</div>
</template>

<script>
import {
    ApiService
} from "@/services/api.service";
import SharePostModal from '@/include/Modals/SharePostModal'
import VerifiedBadge from '@/components/VerifiedBadge'

export default {
    name: 'GroupsFollowedComponent',
    props: {
        group: Object
    },
    data() {
        return {
            currentUser: '',
            shareLink: '',
            shareDescription: ''
        }
    },
    components: {
        SharePostModal,
        VerifiedBadge,
    },
    mounted() {
        this.currentUser = this.$store.getters["user/currentUser"];
        this.shareDescription = 'Group on Vitisocial'
    },
    methods: {
        async followGroup() {
            this.toggleProgress('show')
            let apiResponse = await ApiService.FollowGroup(this.group['id']);
            this.toggleProgress('hide')
            this.$store.commit("toast/Notify", {
                type: apiResponse["status"],
                message: apiResponse["message"],
            });
            if (apiResponse["status"] != "success") {} else {
                location.href = ''
            }
        },
        isHappening() {},
        setCurrentLink(id, description) {
            let thisLink = this.$store.getters["host/seoUrl"] + '/group/' + id
            this.shareLink = thisLink
        },
        openGroup(id) {
            location.href = '/dashboard/group/' + id
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
