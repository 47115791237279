<template>
<li v-if="event" tabindex="-1" class="uk-active mouse-pointer">
    <div class="card">
        <div class="card-media h-32" @click="openEvent(event.id)">
            <div class="card-media-overly"></div>
            <img v-if="event.image" :src="event.image" alt="" class="">
            <img v-else :src="defaultPostUrl()" alt="" class="">
            <div v-if="isHappening() == 'true'" class="absolute bg-red-100 font-semibold px-2.5 py-1 rounded-full text-red-500 text-xs top-2.5 left-2.5">
                Happening now </div>
        </div>
        <div class="card-body">
            <div class="text-xs uppercase text-red-500 font-semibold"> {{ event.payment_type }} </div>
            <a @click="openEvent(event.id)" class="box-title mb-1"> {{ event.title }} </a>
            <div class="text-sm font-medium mt-1">{{ event.sector }}</div>

            <div class="flex items-center space-x-2 text-sm text-gray-500 capitalize">
                <div> {{ formatAmount(event.response_count) }} intersted</div>
                <div>·</div>
                <div class="d-none"> {{ formatAmount(event.attendance) }} going </div>
            </div>

            <div class="flex mt-2 space-x-2 text-sm">
                <a v-if="!event.user_interest" @click="showEventInterest()" href="Javascript:;" class="mouse-pointer bg-blue-600 flex flex-1 h-8 items-center justify-center rounded-md text-white capitalize">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 mr-1.5">
                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z">
                        </path>
                    </svg> show interest
                </a>
                <a v-else href="Javascript:;" class="mouse-pointer bg-green-600 flex flex-1 h-8 items-center justify-center rounded-md text-white capitalize">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 mr-1.5">
                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z">
                        </path>
                    </svg> interest shown
                </a>
                <a class="bg-gray-200 flex h-8 items-center px-3 rounded-md" uk-toggle="target: #share-post-modal" @click="setCurrentLink(event.id, event.title)">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-5">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z">
                        </path>
                    </svg>
                </a>
            </div>
            <share-post-modal v-if="shareLink" :link="shareLink" :description="shareDescription"></share-post-modal>

        </div>
    </div>
</li>
</template>

<script>
import {
    ApiService
} from "@/services/api.service";
import SharePostModal from '@/include/Modals/SharePostModal'

export default {
    name: 'EventComponent',
    props: {
        event
    },
    data() {
        return {
            currentUser: '',
            shareLink: '',
            shareDescription: ''
        }
    },
    components: {
        SharePostModal
    },
    mounted() {
        this.currentUser = this.$store.getters["user/currentUser"];
        this.shareDescription = 'Event on Vitisocial'
    },
    methods: {
        async showEventInterest() {
            this.toggleProgress('show')
            let apiResponse = await ApiService.ShowEventInterest(this.event['id']);
            this.toggleProgress('hide')
            this.$store.commit("toast/Notify", {
                type: apiResponse["status"],
                message: apiResponse["message"],
            });
            if (apiResponse["status"] != "success") {} else {
                location.href = ''
            }
        },
        isHappening() {
        },
        setCurrentLink(id, description) {
            let thisLink = this.$store.getters["host/seoUrl"]+'/event/'+id
            this.shareLink = thisLink
        },
        openEvent(id) {
            location.href = '/dashboard/event/'+id
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
