<template>
<!-- Share post modal -->
<div id="customer-order-product-modal" class="create-post is-story" uk-modal="">
    <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical rounded-lg p-0 lg:w-5/12 relative shadow-2xl uk-animation-slide-bottom-small">
        <div class="text-center py-3 border-b">
            <h3 class="text-lg font-semibold">Order Products</h3>
            <button class="uk-modal-close-default bg-gray-100 rounded-full p-2.5 right-2" type="button" uk-close="" uk-tooltip="title: Close ; pos: bottom ;offset:7"></button>
        </div>
        <div class="bsolute bottom-0 p-4 space-x-4-old w-full" v-if="order">
            <div class="flex-old bg-gray-50 border-bottom border-black p-5 shadow-sm items-center" v-for="product in order['products']">
                <div>
                    <p class="mb-2">Product Name: {{ product.name }}</p>
                    <p class="mb-2">Product Amount: {{ currencySymbol(product.currency) }}{{ formatAmount(product.amount) }}</p>
                    <p v-if="product.date_accepted" class="mb-2">Product order accepted on {{ unixToUtc(product.date_accepted) }}</p>
                    <p v-if="product.date_rejected" class="mb-2">Product order rejected on {{ unixToUtc(product.date_rejected) }}</p>
                    <p v-if="product.date_shipped" class="mb-2">Product order shipped on {{ unixToUtc(product.date_shipped) }}</p>
                    <p v-if="product.date_delivered" class="mb-2">Product order delivered on {{ unixToUtc(product.date_delivered) }}</p>
                    <p v-if="product.date_received" class="mb-2">Product order received on {{ unixToUtc(product.date_received) }}</p>
                    <p v-if="product.date_confirmed" class="mb-2">Product order confirmed on {{ unixToUtc(product.date_confirmed) }}</p>
                    <p v-if="product.date_disputed" class="mb-2">Product order disputed on {{ unixToUtc(product.date_disputed) }}</p>
                </div>
                <div class="flex-old flex-1 items-center lg:justify-center justify-center space-x-2">
                    <button @click="receivedOrder(product.id)" class="mb-3 h-8 lg:px-3 px-2 rounded-md bg-blue-600 text-white space-x-1" v-if="product.date_delivered && !product.date_received">Received Order</button>
                    <template v-if="product.date_accepted || product.date_rejected">
                        <button @click="disputeOrder(product.id)" class="mb-3 h-8 lg:px-3 px-2 rounded-md bg-red-600 text-white space-x-1" v-if="!product.date_disputed">Dispute Order</button>
                    </template>
                    <p v-else>Order has to be accepted or rejcted by vendor before {{ formatDateTimeToFuture(product.created_at) }}</p>
                </div>
                <br/>
                <div>
                    <a class="mt-4 mb-3 h-8 lg:px-3 px-5 py-3 rounded-md bg-blue-600 text-white space00-x-1" :href="'/dashboard/products/customer-order/'+product.id" >view product order</a>
                </div>
                <br/>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    ApiService
} from '@/services/api.service'
export default {
    name: "CustomerOrderProductModal",
    props: {
        order: Object,
        description: String
    },
    data() {
        return {
            currentYear: new Date().getFullYear(),
        };
    },
    mounted: function () {
        // console.log('order', this.order)
    },
    components: {},
    methods: {
        async receivedOrder(id) {
            this.toggleProgress('show')
            let apiResponse = await ApiService.MarkCustomerProductOrder('received', id);
            if (apiResponse["status"] != "success") {} else {
                location.href = ''
            }
            this.toggleProgress('hide')
        },
        async disputeOrder(id) {
            this.toggleProgress('show')
            let apiResponse = await ApiService.MarkCustomerProductOrder('disputed', id);
            if (apiResponse["status"] != "success") {} else {
                location.href = ''
            }
            this.toggleProgress('hide')
        },
        async confirmOrder(id) {
            this.toggleProgress('show')
            let apiResponse = await ApiService.MarkCustomerProductOrder('confirmed', id);
            if (apiResponse["status"] != "success") {} else {
                location.href = ''
            }
            this.toggleProgress('hide')
        },
        async open() {},
        closeModal() {
            $('#customer-order-product-modal').removeClass('uk-open').hide();
        }
    },
};
</script>

<style lang="scss" scoped></style>
