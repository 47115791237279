<template>
<div id="offcanvas-chat" uk-offcanvas="flip: true; overlay: true" class="uk-offcanvas">
    <div class="uk-offcanvas-bar bg-white p-0 w-full lg:w-80 shadow-2xl uk-offcanvas-bar-animation uk-offcanvas-slide">

        <div class="relative pt-5 px-4">

            <h3 class="text-2xl font-bold mb-2"> Chats </h3>

            <div class="absolute right-3 top-4 flex items-center space-x-2">

                <button class="uk-offcanvas-close px-2 -mt-1 relative rounded-full inset-0 lg:hidden blcok uk-icon uk-close" type="button" uk-close="">
                    <svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg" data-svg="close-icon">
                        <line fill="none" stroke="#000" stroke-width="1.1" x1="1" y1="1" x2="13" y2="13"></line>
                        <line fill="none" stroke="#000" stroke-width="1.1" x1="13" y1="1" x2="1" y2="13"></line>
                    </svg>
                </button>

            </div>

        </div>

        <nav class="responsive-nav border-b extanded mb-2 -mt-2">
            <ul uk-switcher="connect: #chats-tab; animation: uk-animation-fade">
                <li class="uk-active"><a class="active" href="#0" aria-expanded="true"> Friends </a></li>
                <li><a href="#0" aria-expanded="false">Groups <span> {{ groups_followed.length }} </span> </a></li>
            </ul>
        </nav>

        <div class="contact-list px-2 uk-switcher" id="chats-tab" style="touch-action: pan-y pinch-zoom;">

            <div class="p-1 uk-active">
                <div v-if="followers.length == 0 && followersLoading == false">
                    <h5>You dont follow any one yet</h5>
                </div>
                <div v-if="followersLoading == true" class="text-center my-3">
                    <i class="fa fa-spin fa-spinner fa-2x"></i>
                </div>
                <a v-for="f in followers" v-if="otherChatUserID(f)" :href="'/dashboard/chats?type=user&id='+otherChatUserID(f)">
                    <div class="contact-avatar">
                        <img :src="otherChatUserPhoto(f)" alt="">
                        <span v-if="otherChatUserOnline(f) == 'true'" class="user_status status_online"></span>
                    </div>
                    <div class="contact-username"> {{ otherChatUserName(f) }}</div>
                </a>

            </div>
            <div class="p-1">
                <div v-if="groups_followed.length == 0 && groupsLoading == false">
                    <h5>You have not followed any Group</h5>
                </div>
                <div v-if="groupsLoading == true" class="text-center my-3">
                    <i class="fa fa-spin fa-spinner fa-2x"></i>
                </div>
                <div class="" v-for="gf in groups_followed">
                    <a :href="'/dashboard/group/'+gf.group.id" aria-expanded="false" class="">
                        <div class="contact-avatar">
                            <img v-if="gf.group.image" :src="gf.group.image" alt="">
                            <img v-else :src="defaultPostUrl()" alt="">
                        </div>
                        <div class="contact-username"> {{ gf.group.name }}</div>
                    </a>
                </div>

            </div>

        </div>
    </div>
</div>
</template>

<script>
import {
    ApiService
} from "@/services/api.service";
export default {
    name: 'MessengerComponent',
    props: {
        link: String,
    },
    data: function () {
        return {
            followers: '',
            followings: '',
            groups: '',
            groups_followed: '',
            pendingGroupsRequest: '',
            groupsLoading: false,
            followersLoading: false,
        }
    },
    mounted() {
        this.getFollowers()
        this.getFollowedGroups()
    },
    methods: {
        async getFollowers() {
            this.toggleProgress('show')
            this.followersLoading = true;
            let apiResponse = await ApiService.GetFollowers();
            if (apiResponse['status'] == 'success') {
                this.followers = apiResponse['data']['followers']
                this.followings = apiResponse['data']['followings']
            } else {
                this.$store.commit("toast/Notify", {
                    type: apiResponse['status'],
                    message: apiResponse['message'],
                });
            }
            this.followersLoading = false;
            this.toggleProgress('hide')
        },
        async getFollowedGroups(section) {
            this.pendingGroupsRequest = true;
            this.groupsLoading = true;
            this.toggleProgress('show')
            let apiResponse = await ApiService.GetFollowedGroups(this.page, section);
            if (apiResponse["status"] != "success") {} else {
                this.groups_followed = apiResponse["data"]['groups'];
                this.pendingRequest = false;
            }
            this.groupsLoading = false;
            this.pendingGroupsRequest = false;
        },
    }
}
</script>

<style lang="scss" scoped>
</style>
