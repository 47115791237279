<template>
<!-- birthdays modal -->
<div id="view-status-stat-modal" uk-modal="">
    <div class="uk-modal-dialog uk-modal-body rounded-xl shadow-lg">
        <div>
            <!-- close button -->
            <button class="uk-modal-close-default p-2.5 bg-gray-100 rounded-full m-3" type="button" uk-close=""></button>
        </div>
        <div class="m-auto">
            <br /><br />
            <div class="my-3">
                <div v-if="story && story.items.length > 0" class="row">
                    <div v-for="(items, index) in story.items" class="col px-1">
                        <div :id="'sic_'+items.id" class="mx-1 story-ind-custom mouse-pointer" @click="setCurrentStoryItemByIndex(index)">
                            <div></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="story-content">
                <div class="row" v-if="storyItem != '' && storyItem['id']">
                    <div class="col-sm-8 mb-4">
                        <template v-if="storyItem.type == 'image'">
                            <div class="relative">
                                <span uk-switcher-item="previous" class="d-none slider-icon is-left"> </span>
                                <span uk-switcher-item="next" class="d-none slider-icon is-right"> </span>

                                <div uk-lightbox="">
                                    <a :href="storyItem.content" target="_blank" data-alt="Image">
                                        <img :src="storyItem.content" class="story-slider-image m-auto" data-alt="Image" />
                                    </a>
                                    <p class="mt-5 text-center" v-html="customTextRender(storyItem.caption)">{{ storyItem.caption }}</p>
                                </div>
                            </div>
                        </template>
                        <template v-if="storyItem.type == 'link'">
                            <div class="relative">
                                <span uk-switcher-item="previous" class="d-none slider-icon is-left"> </span>
                                <span uk-switcher-item="next" class="d-none slider-icon is-right"> </span>

                                <div class="status_text_holder text-center text-middle">
                                    <div>
                                        <a :href="storyItem.content" target="_blank" data-alt="Image" v-html="customTextRender(storyItem.content)">
                                            {{ storyItem.content }}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </template>
                        <template v-if="storyItem.type == 'text'">
                            <div class="relative">
                                <span uk-switcher-item="previous" class="d-none slider-icon is-left"> </span>
                                <span uk-switcher-item="next" class="d-none slider-icon is-right"> </span>

                                <div class="status_text_holder text-center text-middle">
                                    <div>
                                        <a target="_blank" data-alt="Image">
                                            <p class="text-black" v-html="customTextRender(storyItem.content)">{{ storyItem.content }}</p>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>
                    <div class="col-sm-4">
                        <div class="text-right">
                            <div class="">
                                <div>
                                    <h5 class="mb-4">Viewed by</h5>
                                    <ul>
                                        <li v-for="viewer in storyItem.viewers" v-if="viewer['user']" class="mb-2">
                                            {{ viewer['user']['name'] }}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12">
                        <br/><br/><br/><br/>
                        <div class="mt-5">
                            <span @click="setCurrentStoryItemByIndex(prevIntIndex)" uk-switcher-itemold="previous" class="mouse-pointer slider-icon is-left" style="left: auto"> </span>
                            <span @click="setCurrentStoryItemByIndex(nextIntIndex)" uk-switcher-itemold="next" class="mouse-pointer slider-icon is-right" style="right: 0"> </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    ApiService
} from "@/services/api.service";
export default {
    name: "ViewStatusStatModal",
    props: {
        story: Object
    },
    data() {
        return {
            currentYear: new Date().getFullYear(),
            appName: "-",
            storyItem: "",
            this_story: "",
            is_last: 'false',
            prevIntIndex: 0,
            nextIntIndex: 0,
            currentIntIndex: 0,
            maxIndex: 4,
            storyTime: 6000,
            thisInterval: '',
            allTimeout: [],
        };
    },
    mounted: function () {
        this.appName = this.$APP_NAME;
        if (this.story && this.story['items'].length > 0) {
            this.setCurrentStoryItemByIndex(0)
        }
    },
    methods: {
        resetStory() {
            this.this_story = ''
            if (this.thisInterval) {
                clearInterval(this.thisInterval)
            }
            if (this.allTimeout) {
                let allTimeout = this.allTimeout
                for (let i = 0; i < allTimeout.length; i++) {
                    const timeout = allTimeout[i];
                    clearTimeout(timeout);
                }
            }
            this.is_last = 'false'
            this.$root.$emit("close-view-status-modal")
        },
        setCurrentStoryItemByIndex(index) {
            if (index > -1) {
                if (this.story['items'][index]) {
                    let item = this.story['items'][index]
                    if (item) {
                        if(index > 0){
                            this.prevIntIndex = index-1
                        }else{
                            this.prevIntIndex = 0
                        }
                        if(index < this.story['items'].length-1){
                            this.nextIntIndex = index+1
                        }else{
                            this.nextIntIndex = 0
                        }
                        this.currentIntIndex = index
                        this.storyItem = item
                        this.setCurrentItemActive(item['id'])
                    }
                }
            }
        },
        setCurrentItemActive(id) {
            $(".story-ind-custom").css('background', '#ddd')
            $(".story-ind-custom > div").css('width', '100%')

            $("#sic_" + id).css('background', '#aaa')
            $("#sic_" + id).css('width', '100%')
        }
    }
};
</script>

<style lang="scss" scoped>
.story-ind-custom {
    padding: 2px;
    height: 2px;
    width: 100%;
    background: #ddd;
    border-radius: 5px;
    margin-bottom: 10px;
}

.story-ind-custom>div {
    background: #ddd;
}

.text-middle {
    height: 400px;
    background: #eee;
    vertical-align: middle;
    display: flex;
    align-items: center;
}

.text-middle>div {
    width: 100%;
}

.story-slider-image {
    height: 400px;
    max-width: 100%;
}
</style>
