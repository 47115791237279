import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyBVjV0iuLFOUn6hc95miKBU1e5NtLUFQU4",
    authDomain: "vitisocial-7a81d.firebaseapp.com",
    projectId: "vitisocial-7a81d",
    storageBucket: "vitisocial-7a81d.appspot.com",
    messagingSenderId: "151046802195",
    appId: "1:151046802195:web:2b9ec0283f9be9cce5a8e6",
    measurementId: "G-WQ8HEWB2R3"
  };

const app = initializeApp(firebaseConfig);

const messaging = getMessaging(app);


export default messaging