<template>
<div v-if="feed && feed.id" v-view.once="viewBoostedFeed">
    <user-feed v-if="!feed.title" :feed="feed" :preview="preview" boosted="true"></user-feed>
    <div v-else>
        
        <a class="mouse-pointer" @click="clickPostAd" :href="feed.link" target="_blank">
            <img v-if="feed.image && feed.image.search('undefined') < 0" :src="convertToVTSocialLink(feed.image)" style="width: 100%; max-height: 360px; max-width: 100%" />
            <p v-else>{{ feed.title }}</p>
            <p>
                <small style="font-size: 11px">
                    <i>Advert</i>
                </small>
            </p>
        </a>
    </div>
</div>
</template>

<script>
import { ApiService } from '@/services/api.service'
import UserFeed from '@/include/Dashboard/Feed/UserFeed'
export default {
    name: "BoostedFeed",
    props: {
        feed: Object,
        preview: String
    },
    data() {
        return {
        };
    },
    components: {
        UserFeed,
    },
    mounted: function () {
        // console.log('this.feed', this.feed)
        // if(this.feed && this.feed.id){
        //     this.viewBoostedFeed()
        // }
    },
    methods: {
        async viewBoostedFeed(){
            if(this.feed.title && this.feed.link){
                this.viewPostAd()
            }else{
                let apiResponse = await ApiService.ViewBoostedFeed(this.feed.id)
            }
        },
        async viewPostAd(){
            let apiResponse = await ApiService.ViewPostAd(this.feed.id)
        },
        async clickPostAd(){
            let apiResponse = await ApiService.ClickPostAd(this.feed.id)
        },
    }
};
</script>

<style lang="scss" scoped></style>
