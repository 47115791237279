<template>
<a v-if="the_user" :href="'/dashboard/timeline/'+user.id" :target="the_target">{{ user.name }}</a>
</template>

<script>
export default {
    name: 'UserProfileLink',
    props: {
        user: Object,
        target: String,
    },
    data() {
        return {
            the_target: '',
            the_user: '',
        }
    },
    components: {},
    mounted() {
        this.the_target = this.target || '_self'
        if(this.target){
            this.the_target = this.target
        }
        if(this.user){
            this.the_user = this.user
        }
    },
    methods: {}
}
</script>

<style>
</style>
