<template>
<div class="messages-container-inner" style="display: -webkit-box;">
    <progress-loader-simple></progress-loader-simple>

    <div class="messages-inbox">
        <div class="messages-headline">
            <div class="input-with-icon" hidden="">
                <input id="autocomplete-input" type="text" placeholder="Search">
                <i class="icon-material-outline-search"></i>
            </div>
            <h2 class="text-2xl font-semibold">Chats</h2>
            <span class="absolute icon-feather-edit mr-4 text-xl uk-position-center-right cursor-pointer"></span>
        </div>
        <div class="messages-inbox-inner" data-simplebar="init">
            <div class="simplebar-wrapper" style="margin: 0px;">
                <div class="simplebar-height-auto-observer-wrapper">
                    <div class="simplebar-height-auto-observer"></div>
                </div>
                <div class="simplebar-mask">
                    <div class="simplebar-offset" style="right: -20px; bottom: 0px;">
                        <div class="simplebar-content" style="padding: 0px calc(20px) 0px 0px; height: 100%; overflow: hidden scroll;">
                            <ul>
                                <li class="chats_class" uk-toggle="target: .message-content;">
                                </li>
                            </ul>
                            <div class="text-center mt-5">
                                <i class="fa fa-spin fa-spinner fa-2x"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="simplebar-placeholder" style="width: 339px; height: 584px;"></div>
            </div>
            <div class="simplebar-track simplebar-horizontal" style="visibility: hidden;">
                <div class="simplebar-scrollbar simplebar-visible" style="transform: translate3d(0px, 0px, 0px); visibility: hidden;"></div>
            </div>
            <div class="simplebar-track simplebar-vertical" style="visibility: visible;">
                <div class="simplebar-scrollbar simplebar-visible" style="transform: translate3d(0px, 0px, 0px); visibility: visible; height: 265px;"></div>
            </div>
        </div>
    </div>

    <div class="message-content">

        <div class="messages-headline"></div>

        <div class="message-content-scrolbar" data-simplebar="init">
            <div class="simplebar-wrapper" style="margin: 0px;">
                <div class="simplebar-height-auto-observer-wrapper">
                    <div class="simplebar-height-auto-observer"></div>
                </div>
                <div class="simplebar-mask">
                    <div class="simplebar-offset" style="right: -20px; bottom: 0px;">
                        <div class="simplebar-content" ref="messageDisplay" style="padding: 0px calc(20px) 0px 0px; height: 100%; overflow: hidden scroll;">

                            <!-- Message Content Inner -->
                            <div class="message-content-inner">
                            </div>
                            <!-- Message Content Inner / End -->

                            <!-- Reply Area -->
                            <div></div>
                            <div class="message-reply bg-gray-50">
                            </div>

                        </div>
                    </div>
                </div>
                <div class="simplebar-placeholder" style="width: 18232px; height: 753px;"></div>
            </div>
            <div class="simplebar-track simplebar-horizontal" style="visibility: hidden;">
                <div class="simplebar-scrollbar" style="transform: translate3d(0px, 0px, 0px); visibility: hidden;"></div>
            </div>
            <div class="simplebar-track simplebar-vertical" style="visibility: visible;">
                <div class="simplebar-scrollbar" style="height: 204px; transform: translate3d(0px, 0px, 0px); visibility: visible;"></div>
            </div>
        </div>

    </div>

</div>
</template>

<script>
import ProgressLoaderSimple from '@/components/ProgressLoaderSimple'
export default {
    components: {
        ProgressLoaderSimple
    },
}
</script>
