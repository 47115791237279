<template>
    <div class="mx-2 mb-2" :id="id">
        <vm-player controls ref="player">
            <vm-audio cross-origin>
              <source :data-src="src" type="audio/mp3" />
            </vm-audio>
        </vm-player>
    </div>
</template>

<script>
import { Player, Video, DefaultUi } from '@vime/vue';
export default {
    components: {
        Player,
        Video,
        DefaultUi,
    },
    props: {
        src: String,
    },
    computed: {
        player() {
            return this.$refs.player;
        },
    },
    data() {
        return {
            id: '',
            muted: true,
        };
    },
    mounted () {
        this.id = Math.floor(Math.random() * 11122233344400)
        this.$root.$on("page-scrolled",(data) => {
            let inVP = this.isInViewport(this.id)
            if(inVP == false){
                this.player.paused = true
            }
        })
    }
}
</script>
