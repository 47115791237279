<template>
<div id="wrapper">
    <auth-styles :pageNameMain="pageNameMain" :pageDescriptionMain="pageDescriptionMain" :pageImageMain="pageImageMain"></auth-styles>
    <Header />

    <sidebar></sidebar>

    <!-- Main Contents -->
    <div class="main_content">
        <div class="mcontainer">

            <div class="flex justify-between items-center relative md:mb-4 mb-3">
                <div class="flex-1">
                    <h2 class="text-2xl font-semibold"> New Products </h2>
                </div>
                <a href="/dashboard/create-product" uk-toggle="" class="flex items-center justify-center h-8 lg:px-3 px-2 rounded-md bg-blue-600 text-white space-x-1 absolute right-0 z-10">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-4">
                        <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clip-rule="evenodd"></path>
                    </svg>
                    <span class="md:block hidden"> Create </span>
                </a>

            </div>

            <div class="relative uk-slider" uk-slider="finite: true">

                <div class="px-1 py-3">
                    <ul class="uk-child-width-1-5@m uk-child-width-1-3@s uk-child-width-1 uk-grid-small uk-grid" style="transform: translate3d(0px, 0px, 0px);">
                        <li tabindex="-1" class="uk-active mb-3" v-for="p in newProducts">
                            <a :href="'/dashboard/product/'+p.id">
                                <div class="card">
                                    <div class="card-media h-40">
                                        <div class="card-media-overly"></div>
                                        <img v-if="p.image" :src="p.image" alt="">
                                        <img v-else :src="defaultPostUrl()" alt="">
                                        <span class="absolute bg-white px-2 py-1 text-sm rounded-md m-2"> Label</span>
                                        <button v-if="p.user_favourite" class="bg-green-100 absolute right-2 top-2 p-0.5 px-1.5 rounded-full text-red-500">
                                            <i class="icon-feather-heart"> </i>
                                        </button>
                                        <button v-else  class="bg-blue-100 absolute right-2 top-2 p-0.5 px-1.5 rounded-full text-red-500">
                                            <i class="icon-feather-heart"> </i>
                                        </button>
                                    </div>
                                    <div class="card-body">
                                        <div class="-top-3 absolute bg-blue-100 font-medium px-2 py-1 right-2 rounded-full text text-blue-500 text-sm">
                                            {{ currencySymbol(p.currency) }}{{ formatAmount(p.amount) }}
                                        </div>
                                        <div class="text-xs font-semibold uppercase text-yellow-500">
                                            <span v-if="p.category">{{ p.category.name }}</span>
                                        </div>
                                        <div class="ext-lg font-medium mt-1 t truncate"> {{ p.name }} </div>
                                    </div>
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>

        </div>
    </div>
    <div id="progressLoader">
        <progress-loader></progress-loader>
    </div>

    <dashboard-widgets page="create-product"></dashboard-widgets>
</div>
</template>

<script>
import {
    ApiService
} from '@/services/api.service'
import AuthStyles from '@/include/Auth/AuthStyles'
import Header from "@/include/Dashboard/Header.vue";
import Sidebar from "@/include/Dashboard/Sidebar.vue";
import DashboardWidgets from '@/components/DashboardWidgets'
import OpenChatBox from "@/include/Modals/OpenChatBox.vue";
import Feeds from "@/include/Dashboard/Feeds.vue";
import CustomIcon from '@/components/CustomIcon'
import ProgressLoader from '@/components/ProgressLoader'

export default {
    name: "NewProducts",
    components: {
        Header,
        Sidebar,
        DashboardWidgets,
        OpenChatBox,
        Feeds,
        CustomIcon,
        ProgressLoader,
        AuthStyles
    },

    data() {
        return {
            products: [],
            recentTopics: [],
            page: 0,
            pendingRequest: false,
            recentActive: 'active',
            trendingActive: '',
            randomActive: '',
            userActive: '',
            brands: '',
            categories: '',
            section: '',
            category: '',
            newProducts: '',
            pagination: {
                limit: 20,
                offset: 0,
                total: 60,
            },
            pageNameMain: 'Blogs',
            pageDescriptionMain: '',
            pageImageMain: '',
        };
    },
    mounted: function () {
        this.getNewProducts()
        this.getExtraInfo()
    },
    methods: {
        async getExtraInfo() {
            this.toggleProgress('show')
            let apiResponse = await ApiService.ExtraInfo();
            if (apiResponse['status'] == 'success') {
                this.brands = apiResponse['data']['product_brands']
                this.categories = apiResponse['data']['product_categories']
            }
            this.toggleProgress('hide')
        },
        async getNewProducts() {
            this.toggleProgress('show')
            let apiResponse = await ApiService.GetProducts(0, 'recent');
            if (apiResponse["status"] != "success") {} else {
                this.newProducts = apiResponse["data"]['products'];
            }
            this.toggleProgress('hide')
        },
    },
};
</script>

<style lang="scss" scoped>
.some-modal-content {
    min-width: 400px;
    padding: 25px;

    .buttons button {
        padding: 10px;
        margin: 10px;
    }
}
</style>
