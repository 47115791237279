<template>
<div id="wrapper">
    <auth-styles v-if="pageNameMain" :pageNameMain="pageNameMain" :pageDescriptionMain="pageDescriptionMain" :pageImageMain="pageImageMain"></auth-styles>

    <Header />

    <sidebar></sidebar>

    <!-- Main Contents -->
    <div class="main_content">
        <div class="mcontainer">

            <div class="lg:flex lg:space-x-12">

                <div class="lg:w-3/4">

                    <div class="flex justify-between items-center relative md:mb-4 mb-3">
                        <div class=" md:mb-4 mb-3">
                            <h2 class="text-2xl font-semibold"> Jobs </h2>

                            <nav class="responsive-nav style-2 md:m-0 -mx-4">
                                <ul>
                                    <li :class="randomActive"><a @click="makeActiveSection('random')" class="mouse-pointer lg:px-2"> Suggestions </a></li>
                                    <li :class="recentActive"><a @click="makeActiveSection('recent')" class="mouse-pointer lg:px-2"> Newest </a></li>
                                    <li :class="userActive"><a @click="makeActiveSection('user')" class="mouse-pointer lg:px-2"> My Jobs </a></li>
                                </ul>
                            </nav>
                        </div>
                        <a href="/dashboard/create-job" class="flex items-center justify-center h-10 w-10 z-10 rounded-full bg-blue-600 text-white hover:text-white absolute right-0" data-tippy-placement="left" title="Create New Job Post">
                            <svg class="w-6 h-6" fill="currentColor" viewbox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" style="padding:2px">
                                <path fill-rule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clip-rule="evenodd"></path>
                            </svg>
                        </a>
                    </div>

                    <div class="card divide-y divide-gray-100 sm:m-0 -mx-4">

                        <!-- job 1 -->
                        <div v-for="job in jobs" class="flex items-start flex-wrap p-7 sm:space-x-6">
                            <a :href="'/dashboard/job/'+job.id" class="w-14 h-14 relative mt-1 order-1">
                                <img v-if="job.image" :src="job.image" alt="" class="rounded-md">
                                <img v-else :src="defaultPostUrl" alt="" class="rounded-md">
                            </a>
                            <div class="flex-1 sm:order-2">
                                <h4 class="text-base text-gray-500 font-medium mb-2" v-if="job.company">{{ job.company.name }} </h4>
                                <a :href="'/dashboard/job/'+job.id">
                                    <h3 class="text-xl font-medium mb-4" v-if="job.job_role"> {{ job.job_role.name }} </h3>
                                </a>
                                <p> {{ blogSubtitle(job.description,200) }}
                                </p>
                                <div class="job-tags mt-5 mb-2 space-x-2.5 space-y-2" v-html="pullJobtags(job.tags)">
                                </div>
                            </div>
                        </div>

                        <div v-if="jobsPreloader == 'false' && jobs.length == 0" class="p-5 text-center bg-white">
                            <h4>No Jobs Found</h4>
                        </div>

                    </div>

                </div>
                <div class="lg:w-1/4 w-full flex-shrink-0">

                    <div uk-sticky="media @m ; offset:80 ; bottom : true" class="lg:mt-0 mt-6 uk-sticky" style="">

                        <h2 class="text-xl font-semibold mb-2"> Recently Posted </h2>
                        <ul class="-space-y-2 card-mobile mb-10">
                            <li v-for="rj in recentJobs">
                                <a :href="'/dashboard/job/'+rj.id" class="hover:bg-gray-100 rounded-md p-3 -mx-3 block">
                                    <h3 class="font-medium line-clamp-2">{{ rj.job_role.name }}</h3>
                                    <div class="flex font-medium items-center mt-1 space-x-1.5 text-xs">
                                        <div class="">{{ rj.job_type.name }}</div>
                                        <div class="pb-1"> . </div>
                                        <div class="text-blue-500">${{ formatAmount(rj.min_salary) }} - ${{ formatAmount(rj.max_salary) }} / month</div>
                                    </div>
                                </a>
                            </li>
                        </ul>
                        <br>

                        <h4 class="text-lg font-semibold mb-3"> Tags </h4>

                        <div class="flex flex-wrap gap-2 card-mobile">
                            <a v-for="tag in tags" @click="getJobPostsByTag(tag)" class="mouse-pointer bg-gray-100 py-1.5 px-4 rounded-full"> {{ tag }}</a>
                        </div>

                    </div>
                    <div class="uk-sticky-placeholder" style="height: 481px; margin: 0px;" hidden=""></div>
                </div>

            </div>

        </div>
    </div>

    <dashboard-widgets page="jobs"></dashboard-widgets>
    <progress-loader-simple v-if="jobsPreloader == 'true' || recentJobsPreloader == 'true'"></progress-loader-simple>

</div>
</template>

<script>
import {
    VueEditor
} from "vue2-editor";
import {
    ApiService
} from '@/services/api.service'
import Header from '@/include/Dashboard/Header.vue'
import Sidebar from '@/include/Dashboard/Sidebar.vue'
import DashboardWidgets from '@/components/DashboardWidgets'
import OpenChatBox from '@/include/Modals/OpenChatBox.vue'
import ProfileIcon from '@/include/Dashboard/Feed/ProfileIcon'
import ProgressLoader from '@/components/ProgressLoader'
import ProgressLoaderSimple from '@/components/ProgressLoaderSimple'
import TimelineScreen from '@/include/Dashboard/Timeline/TimelineScreen'
import FriendScreen from '@/include/Dashboard/Timeline/FriendScreen'
import PhotoScreen from '@/include/Dashboard/Timeline/PhotoScreen'
import PageScreen from '@/include/Dashboard/Timeline/PageScreen'
import GroupScreen from '@/include/Dashboard/Timeline/GroupScreen'
import VideoScreen from '@/include/Dashboard/Timeline/VideoScreen'
import SingleFileUpload from '@/components/SingleFileUpload'
import CreatePhotoPost from '@/components/CreatePhotoPost'
import IonIcon from '@/components/IonIcon'
import axios from 'axios'

export default {
    name: 'Job',
    components: {
        Header,
        Sidebar,
        DashboardWidgets,
        OpenChatBox,
        ProgressLoader,
        ProgressLoaderSimple,
        VueEditor,
        TimelineScreen,
        FriendScreen,
        PhotoScreen,
        PageScreen,
        GroupScreen,
        VideoScreen,
        SingleFileUpload,
        CreatePhotoPost,
        IonIcon
    },
    data() {
        return {
            jobs: [],
            tags: [],
            categories: [],
            category: '',
            total_jobs: 0,
            pendingRequest: false,
            jobsPreloader: 'false',
            pageNameMain: '',
            pageDescriptionMain: '',
            pageImageMain: '',
            section: '',
            randomActive: '',
            recentActive: '',
            userActive: '',
            recentJobsPreloader: '',
            recentJobs: [],
            tags: [],
        }
    },
    mounted: function () {
        this.thisUser = this.$store.getters["user/currentUser"];
        let id = this.thisUser['id']
        this.id = id
        this.makeActiveSection('random')
        this.getRecentJobs()
        this.getJobExtraInfo()
    },
    methods: {
        async getJobExtraInfo() {
            let apiResponse = await ApiService.GetJobExtraData();
            // console.log('getJobExtraInfo', apiResponse)
            if (apiResponse['status'] == 'success') {
                this.tags = apiResponse['data']['job_tags']
            }
        },
        async getJobs(id) {
            if (this.pendingRequest == true) {
                // console.log('Pending Req')
                return
            }
            this.pendingRequest = true
            this.jobsPreloader = 'true'
            let apiResponse = await ApiService.GetJobPosts(this.page, this.section)
            if (apiResponse['status'] != 'success') {} else {
                this.total_jobs = apiResponse['data']['total_jobs']
                let jobs = apiResponse['data']['job_posts']
                if (jobs.length > 0) {
                    this.page++
                }
                // console.log('this.page', this.page)
                if (this.jobs.length == 0) {
                    this.jobs = jobs
                } else {
                    if (photos.length > 0) {
                        this.jobs = this.jobs.concat(jobs)
                    }
                }
            }
            this.pendingRequest = false
            this.jobsPreloader = 'false'
        },
        async getRecentJobs() {
            this.recentJobsPreloader = 'true'
            let apiResponse = await ApiService.GetJobPosts(this.page, 'recent')
            if (apiResponse['status'] != 'success') {} else {
                this.recentJobs = apiResponse['data']['job_posts']
            }
            this.recentJobsPreloader = 'false'
        },
        async getJobPostsByTag(tag) {
            this.page = 0
            this.jobs = []
            this.jobsPreloader = 'true'
            let apiResponse = await ApiService.GetJobPosts(this.page, 'tag', tag)
            if (apiResponse['status'] != 'success') {} else {
                this.total_jobs = apiResponse['data']['total_jobs']
                this.jobs = apiResponse['data']['job_posts']
            }
            this.pendingRequest = false
            this.jobsPreloader = 'false'
        },
        async makeActiveSection(a) {
            this.recentActive = ''
            this.randomActive = ''
            this.userActive = ''

            this.section = a;
            this.page = 0;
            this.jobs = []

            if(a.toLowerCase() == 'recent'){
                this.recentActive = 'active'
                this.getJobs('recent')
            }else if(a.toLowerCase() == 'random'){
                this.randomActive = 'active'
                this.getJobs('oldest')
            }else if(a.toLowerCase() == 'trending'){
                this.trendingActive = 'active'
                this.getJobs('trending')
            }else if(a.toLowerCase() == 'user'){
                this.userActive = 'active'
                this.getJobs('user')
            }
        }
    },
}
</script>

<style>
.profilePage123 input {
    width: 157px !important;
    height: 32px !important;
}
</style>
