<template>
<div v-if="thisFeed.videos" class="feed_videos mb-2">
    <template v-for="feedVideo in thisFeed.videos.slice(0, video_length)">
        <video-player-component class="feed_video" :src="feedVideo.url" type="video/mp4" :poster="feedVideo.thumbnail_image" :id="feedVideo.id"></video-player-component>
    </template>
    <p v-if="thisFeed.videos.length > 1" class="ml-3">
        <a class="custom_link" v-if="is_less_video == 'false'" href="javascript:;" @click="moreLessVideoManager()">show less video(s)</a>
        <a class="custom_link" v-else href="javascript:;" @click="moreLessVideoManager()">show more video(s)</a>
    </p>
</div>
</template>

    
    
<script>
import {
    ApiService
} from '@/services/api.service'
import GoogleBannerAd from '@/include/Dashboard/Feed/GoogleBannerAd'
import VerifiedBadge from '@/components/VerifiedBadge'
import VideoPlayerComponent from '@/components/VideoPlayerComponent'

export default {
    name: "VideosComponent",
    props: {
        feed: Object,
    },
    data() {
        return {
            currentUser: '',
            thisFeed: '',
            is_less_video: 'true',
            video_length: 1,
        };
    },
    components: {
        GoogleBannerAd,
        VerifiedBadge,
        VideoPlayerComponent,
    },
    mounted: function () {
        this.currentUser = this.$store.getters["user/currentUser"];
        this.thisFeed = this.feed
    },
    methods: {
        moreLessVideoManager() {
            if (this.is_less_video == 'true') {
                this.video_length = this.thisFeed.videos.length
                this.is_less_video = 'false'
            } else {
                this.video_length = 1
                this.is_less_video = 'true'
            }
        },
        triggerClick() {
            // console.log('TCLIECK')
        },
    }
};
</script>
    
    
<style >
    </style>
