<template>
<!-- Share post modal -->
<div id="feed-insight-modal" class="create-post-old is-story-old uk-modal-container-old" uk-modal="">
    <div class="uk-modal-body uk-margin-auto-vertical rounded-lg p-0  relative shadow-2xl-old uk-animation-slide-bottom-small bg-white">
        <div class="text-center py-3 border-b">
            <h3 class="text-lg font-semibold">Feed Insight</h3>
            <button class="uk-modal-close-default bg-gray-100 rounded-full p-2.5 right-2" type="button" uk-close="" uk-tooltip="title: Close ; pos: bottom ;offset:7"></button>
        </div>
        <template v-if="feed_boost_section == 'view'">
            <div class="bsolute bottom-0 p-4 w-full my-3">
                <div style="justify-content: space-between;" class="flex">
                    <div>
                        <p>
                            Views: {{ formatAmount(feed_views) }}
                        </p>
                    </div>
                    <div>
                        <button class="bg-blue-600 custom_button mr-1 text-white col-xs-12" @click="changeSection('add')">Boost This Feed</button>
                    </div>
                </div>
                <div class="flex-old bg-gray-50 border border-purple-100 rounded-2xl-old p-2 shadow-sm items-center mt-3 mb-5">
                    <div class="table-responsive">
                        <table class="styled-table">
                            <thead>
                                <tr>
                                    <th>Country</th>
                                    <th>Gender</th>
                                    <th>Age</th>
                                    <th>Price</th>
                                    <th>Views</th>
                                    <th>Clicks</th>
                                    <th>Amount Left</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="feed_boost in feed_boosts">
                                    <td>{{ feed_boost['country'] }}</td>
                                    <td>{{ feed_boost['gender'] }}</td>
                                    <td>{{ feed_boost['age_range'] }}</td>
                                    <td>{{ feed_boost['currency'] }} {{ feed_boost['amount'] }}</td>
                                    <td>{{ feed_boost['views'] }}</td>
                                    <td>{{ feed_boost['clicks'] }}</td>
                                    <td>{{ feed_boost['currency'] }} {{ feed_boost['amount_left'] }}</td>
                                    <td>{{ feed_boost['status'] }}</td>
                                    <td>
                                        <button v-if="feed_boost.status == 'active'" @click="pauseFeedBoosts(feed_boost['id'])" class="btn btn-danger">Pause</button>
                                        <button v-if="feed_boost.status == 'paused'" @click="activateFeedBoosts(feed_boost['id'])" class="btn btn-success">Activate</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div v-if="feed_boosts.length == 0" class="mt-3 text-center">
                        <h5>
                            You have not Boosted this feed
                        </h5>
                    </div>
                </div>
                <div class="" v-if="loading == 'true'">
                    <i class="fa fa-spin fa-spinner fa-2x"></i>
                </div>
            </div>
        </template>
        <template v-if="feed_boost_section == 'add'">
            <div class="bottom-0 p-4 w-full my-3">
                <div>
                    <button class="bg-blue-600 custom_button mr-1 text-white col-xs-12" @click="changeSection('view')">View Feed Boosts</button>
                </div>
                <div class="uk-child-width-1-2@s uk-child-width-1-3@m uk-flex-center" uk-grid>
                    <div >
                        <div class="mt-3">
                            <div>
                                <label for=""> Target Country </label><br/><br/>
                                <select v-model="country" id="" name="" class="shadow-none with-border ">
                                    <option value="" disabled>Select Country</option>
                                    <option>All</option>
                                </select>
                            </div>
                            <div class="my-3">
                                <label for=""> Target Gender </label><br/><br/>
                                <select v-model="gender" id="" name="" class="shadow-none with-border ">
                                    <option value="" disabled>Select Gender</option>
                                    <option>All</option>
                                    <option>Male</option>
                                    <option>Female</option>
                                </select>
                            </div>
                            <div>
                                <label for=""> Target Age Range </label><br/><br/>
                                <select v-model="age_range" id="" name="" class="shadow-none with-border ">
                                    <option value="" disabled>Select Age Range</option>
                                    <option value="all">All</option>
                                    <option value="youth">15-30</option>
                                    <option value="middle-aged">31-50</option>
                                    <option value="elderly">51 Above</option>
                                </select>
                            </div>
                            <div>
                                <p v-if="prices">
                                    Price per view: {{ prices.currency }}{{ prices.view }}<br />
                                    Price per click: {{ prices.currency }}{{ prices.click }}
                                </p>
                            </div>
                            <div class="line my-3">
                                <input v-model="amount" class="line__input" id="amount" name="amount" type="text" onkeyup="this.setAttribute('value', this.value);" value="" autocomplete="off">
                                <span for="amount" class="line__placeholder"> Amount to spend on the ad </span>
                            </div>
                            <div class="line my-3">
                                <input v-model="coupon_code" class="line__input" id="coupon_code" name="coupon_code" type="text" onkeyup="this.setAttribute('value', this.value);" value="" autocomplete="off">
                                <span for="coupon_code" class="line__placeholder"> Coupon Code </span>
                            </div>
                            <div class="mb-5 d-flex" style="justify-content: space-between;">
                                <div v-if="amount && country && gender && age_range">
                                    <pay-with-flutterwave :title="'Pay with card '+currencySymbol(prices.currency)+formatAmount(amount)" :email="currentUser.email" :phone="currentUser.phone" :name="currentUser.name" :amount="parseFloat(amount)" :currency="prices.currency" :description="description+' '+main_feed_id" @paymentCallback="paymentCallback"></pay-with-flutterwave>
                                </div>
                                <button v-else class="bg-green-600 custom_button mr-1 text-white col-xs-12" disabled>Make Payment</button>
                                <button v-if="amount && country && gender && age_range && coupon_code" @click="payWithCouponCode" class="bg-green-600 custom_button mr-1 text-white col-xs-12" >Apply Coupon</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>

    </div>
</div>
</template>

<script>
import {
    ApiService
} from '@/services/api.service'
import PayWithFlutterwave from '@/components/PayWithFlutterwave'
export default {
    name: "FeedInsightModal",
    props: {
        feed_id: String,
    },
    data() {
        return {
            loading: "true",
            insights: "",
            feed_boosts: "",
            ip: '',
            browser: '',
            device: '',
            country: 'All',
            gender: 'All',
            age_range: 'all',
            transaction_id: '',
            feed_boost_section: 'view',
            currentUser: '',
            description: 'Payment for feed boost ',
            amount: '',
            main_feed_id: '',
            coupon_code: '',
            feed_views: '',
            currentYear: new Date().getFullYear(),
        };
    },
    mounted: function () {
        this.currentUser = this.$store.getters['user/currentUser'] || {}
        let vueThis = this
        this.$root.$on("open-feed-insight-modal",(main_feed_id) => {
            // console.log('main_feed_id', main_feed_id)
            vueThis.main_feed_id = main_feed_id
            if (vueThis.main_feed_id) {
                vueThis.getFeedBoosts();
            }

        })
        if (this.feed_id) {
          this.main_feed_id = this.feed_id
            this.getFeedBoosts();
        }
    },
    components: {
      PayWithFlutterwave,
    },
    methods: {
        async changeSection(a) {
            this.feed_boost_section = a
        },
        async getFeedInsight() {
            this.loading = "true";
            let apiResponse = await ApiService.FeedInsighs(this.main_feed_id);
            if (apiResponse["status"] != "success") {
                this.$store.commit("toast/Notify", {
                    type: apiResponse["status"],
                    message: apiResponse["message"],
                });
            } else {
                this.insights = apiResponse["data"];
            }
            this.loading = "false";
        },
        async getFeedBoosts() {
            this.loading = "true";
            let apiResponse = await ApiService.GetFeedBoosts(this.main_feed_id);
            if (apiResponse["status"] != "success") {
                this.$store.commit("toast/Notify", {
                    type: apiResponse["status"],
                    message: apiResponse["message"],
                });
            } else {
                this.feed_boosts = apiResponse["data"]['boosts'];
                this.rave_key = apiResponse["data"]['rave_key'];
                this.prices = apiResponse["data"]['prices'];
                this.feed_views = apiResponse["data"]['views'];
            }
            this.loading = "false";
        },
        async pauseFeedBoosts(id) {
            this.loading = "true";
            let apiResponse = await ApiService.PauseFeedBoost(id);
            this.$store.commit("toast/Notify", {
                type: apiResponse["status"],
                message: apiResponse["message"],
            });
            if (apiResponse["status"] != "success") {} else {
                this.feed_boosts = apiResponse["data"]['boosts'];
            }
            this.loading = "false";
        },
        async activateFeedBoosts(id) {
            this.loading = "true";
            let apiResponse = await ApiService.ActivateFeedBoost(id);
            this.$store.commit("toast/Notify", {
                type: apiResponse["status"],
                message: apiResponse["message"],
            });
            if (apiResponse["status"] != "success") {} else {
                this.feed_boosts = apiResponse["data"]['boosts'];
            }
            this.loading = "false";
        },
        async createFeedBoosts() {
            this.loading = "true";
            let apiResponse = await ApiService.CreateFeedBoost(this.main_feed_id, this.country, this.gender, this.age_range, this.transaction_id, this.coupon_code, this.amount);
            this.$store.commit("toast/Notify", {
                type: apiResponse["status"],
                message: apiResponse["message"],
            });
            if (apiResponse["status"] != "success") {} else {
                this.amount = ''
                this.feed_boosts = apiResponse["data"]['boosts'];
                this.feed_boost_section = 'view'
            }
            this.loading = "false";
        },
        async paymentCallback(response) {
            this.$store.commit("toast/Notify", {
                type: 'success',
                message: 'Payment Received, Please Hold On so we Boost the Ad',
            });
            this.toggleProgress('show')
            // console.log("RESPONSE", response)
            let txRef = response['tx']['txRef'];
            if (txRef) {
                this.toggleProgress('show')
                this.transaction_id = txRef
                let apiResponse = await this.createFeedBoosts();
                this.toggleProgress('hide')
            } else {
                this.$toastr.w('Warning', 'Transaction reference could not be found')
            }
            this.toggleProgress('hide')
        },
        async payWithCouponCode(){
            if(!this.coupon_code){
                this.$store.commit("toast/Notify", {
                    type: 'success',
                    message: 'Coupon code is required',
                });
                return
            }
            this.toggleProgress('show')
            await this.createFeedBoosts();
            this.toggleProgress('hide')
        },
        async open() {},
        closeModal() {
            $("#feed-insight-modal").removeClass("uk-open").hide();
        },
    },
};
</script>

<style lang="scss" scoped>
.styled-table {
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  font-family: sans-serif;
  min-width: 400px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  width: 100%;
}
.styled-table thead tr {
  background-color: #2563eb;
  color: #ffffff;
  text-align: left;
}
.styled-table th,
.styled-table td {
    padding: 12px 15px;
}
.styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.styled-table tbody tr:last-of-type {
  border-bottom: 2px solid #2563eb;
}
</style>
