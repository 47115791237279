<template>
<div id="wrapper">

    <Header />

    <sidebar></sidebar>

    <!-- Main Contents -->
    <div class="main_content">
        <div class="mcontainer">

            <div class="flex justify-between items-center relative md:mb-4 mb-3">
                <div class="flex-1">
                    <h2 class="text-2xl font-semibold"> Photos </h2>
                    <nav class="responsive-nav border-b md:m-0 -mx-4">
                        <ul>
                            <li class="active"><a href="#" class="lg:px-2"> All Photos <span> {{ total_photos }}</span> </a></li>
                        </ul>
                    </nav>
                    <progress-loader v-if="userPhotosPreloader == 'true'"></progress-loader>
                </div>
            </div>

            <div class="grid md:grid-cols-4 grid-cols-2 gap-3 mt-5">
                <div v-for="photo in photos">
                    <div class="bg-green-400 max-w-full lg:h-56 h-48 rounded-lg relative overflow-hidden shadow uk-transition-toggle">
                        <img v-if="photo.url" :src="photo.url" class="w-full h-full absolute object-cover inset-0">
                        <img v-else :src="defaultImageUrl()" class="w-full h-full absolute object-cover inset-0">
                        <!-- overly-->
                        <div class="-bottom-12 absolute bg-gradient-to-b from-transparent h-1/2 to-gray-800 uk-transition-slide-bottom-small w-full"></div>
                        <div class="absolute bottom-0 w-full p-3 text-white uk-transition-slide-bottom-small flex items-center">
                            <div class="flex-1">
                                <div class="text-lg"> {{ photo.description }} </div>
                                <div class="flex space-x-3 lg:flex-initial text-sm">
                                    <a href="Javascript:;" class="hover:text-white" @onclick="likePhoto(photo.id)">{{ photo.likes }} Likes</a>
                                    <a href="Javascript:;" class="hover:text-white">{{ photo.comments }} Comments </a>
                                    <a href="Javascript:;"> </a>
                                </div>
                            </div>
                            <i class="btn-down text-2xl uil-cloud-download px-1"></i>
                        </div>
                    </div>
                </div>
            </div>

            <div class="flex justify-center mt-6">
                <a @click="loadMore" href="Javascript:;" class="bg-white dark:bg-gray-900 font-semibold my-3 px-6 py-2 rounded-full shadow-md dark:bg-gray-800 dark:text-white">
                    Load more ..</a>
            </div>

        </div>
    </div>

    <dashboard-widgets page="photos"></dashboard-widgets>
    <progress-loader v-if="userPhotosPreloader == 'true'"></progress-loader>

</div>
</template>

<script>
import {
    VueEditor
} from "vue2-editor";
import {
    ApiService
} from '@/services/api.service'
import Header from '@/include/Dashboard/Header.vue'
import Sidebar from '@/include/Dashboard/Sidebar.vue'
import DashboardWidgets from '@/components/DashboardWidgets'
import OpenChatBox from '@/include/Modals/OpenChatBox.vue'
import ProfileIcon from '@/include/Dashboard/Feed/ProfileIcon'
import ProgressLoader from '@/components/ProgressLoader'
import TimelineScreen from '@/include/Dashboard/Timeline/TimelineScreen'
import FriendScreen from '@/include/Dashboard/Timeline/FriendScreen'
import PhotoScreen from '@/include/Dashboard/Timeline/PhotoScreen'
import PageScreen from '@/include/Dashboard/Timeline/PageScreen'
import GroupScreen from '@/include/Dashboard/Timeline/GroupScreen'
import VideoScreen from '@/include/Dashboard/Timeline/VideoScreen'
import SingleFileUpload from '@/components/SingleFileUpload'
import CreatePhotoPost from '@/components/CreatePhotoPost'
import IonIcon from '@/components/IonIcon'
import axios from 'axios'

export default {
    name: 'Photos',
    components: {
        Header,
        Sidebar,
        DashboardWidgets,
        ProgressLoader,
        VueEditor,
        TimelineScreen,
        FriendScreen,
        PhotoScreen,
        PageScreen,
        GroupScreen,
        VideoScreen,
        SingleFileUpload,
        CreatePhotoPost,
        IonIcon
    },
    data() {
        return {
            photos: [],
            page: 0,
            photo_reach: 'Public',
            photo_description: '',
            image: '',
            id: '',
            thisUser: {},
            total_photos: '',
            pendingRequest: false,
            userPhotosPreloader: 'false',
        }
    },
    mounted: function () {
        this.thisUser = this.$store.getters["user/currentUser"];
        let id = this.thisUser['id']
        this.id = id
        this.getPhotos(this.id)
    },
    methods: {
        async getPhotos(id) {
            if (this.pendingRequest == true) {
                // console.log('Pending Req')
                return
            }
            this.pendingRequest = true
            this.userPhotosPreloader = 'true'
            let apiResponse = await ApiService.GetFeedPhotos(this.page, id)
            if (apiResponse['status'] != 'success') {} else {
                this.total_photos = apiResponse['data']['total_photos']
                let photos = apiResponse['data']['photos']
                if (photos.length > 0) {
                    this.page++
                }
                // console.log('this.page', this.page)
                if (this.photos.length == 0) {
                    this.photos = photos
                } else {
                    if (photos.length > 0) {
                        this.photos = this.photos.concat(photos)
                    }
                }
            }
            this.pendingRequest = false
            this.userPhotosPreloader = 'false'
        },
        async likePhoto(id) {
            if (this.pendingRequest == true) {
                // console.log('Pending Req')
                return
            }
            this.pendingRequest = true
            let apiResponse = await ApiService.LikePhoto(id)
            if (apiResponse['status'] != 'success') {} else {}
            this.$store.commit("toast/Notify", {
                type: apiResponse["status"],
                message: apiResponse["message"],
            });
            this.pendingRequest = false
        },
        async savePhoto(photo_reach, photo_description, image) {
            if (this.pendingRequest == true) {
                // console.log('Pending Req')
                return
            }
            this.pendingRequest = true
            let apiResponse = await ApiService.SavePhoto(photo_reach, photo_description, image)
            this.$store.commit("toast/Notify", {
                type: apiResponse["status"],
                message: apiResponse["message"],
            });
            if (apiResponse['status'] != 'success') {} else {
                this.photo_reach = ''
                this.photo_description = ''
                this.image = ''

                this.page = 0
                this.getPhotos(this.id)
            }
            this.pendingRequest = false
        },
        onImageUploaded(response) {
            // Handle files like:
            // console.log('::::',response)
            if (response['status'] == 'success') {
                this.image = response['data']['file_name']
            }
            this.$store.commit("toast/Notify", {
                type: response["status"],
                message: response["message"],
            });
        },
        async restart() {
            this.page = 0
            this.photos = []
            this.getPhotos(this.id)
        },
        async loadMore() {
            this.getPhotos(this.id)
        },
    },
}
</script>

<style>
.profilePage123 input {
    width: 157px !important;
    height: 32px !important;
}
</style>
