<template>
    <div class="grid lg:grid-cols-3 gap-2">
        <div class="">
            <select required class="mt-1" v-model="year" @change="selectYear">
                <option value="" disabled>Year</option>
                <option v-for="y in years">{{ y }}</option>
            </select>
        </div>
        <div>
            <select required class="mt-1" v-model="month" @change="selectMonth">
                <option value="" disabled>Month</option>
                <option v-for="(m, index) in months" :value="index+1">{{ m }}</option>
            </select>
        </div>
        <div>
            <select required class="mt-1" v-model="day" @change="selectDay">
                <option value="" disabled>Day</option>
                <option v-for="m in days">{{ m }}</option>
            </select>
        </div>
    </div>
</template>

<script>

export default {
    name: 'DobSelectorComponent',
    props: {
        title: String,
        set_year: String,
        set_month: String,
        set_day: String,
    },
    data() {
        return {
            year: '',
            month: '',
            day: '',
            months: [
                'Jan',
                'Feb',
                'Mar',
                'Apr',
                'May',
                'Jun',
                'Jul',
                'Aug',
                'Sep',
                'Oct',
                'Nov',
                'Dec',
            ],
            days: []
        }
    },
    computed : {
        years () {
            const year = new Date().getFullYear()-10
            return Array.from({length: year - 1900}, (value, index) => 1901 + index)
        }
    },
    components: {
    },
    mounted() {
        if(this.set_day){
            this.day = parseInt(this.set_day)
        }
        if(this.set_month){
            this.month = parseInt(this.set_month)+1
            this.setDayLength()
        }
        if(this.set_year){
            this.year = this.set_year
        }
    },
    watch: {
        set_day(old, new_val) { 
            this.day = this.set_day
        },
        set_month(old, new_val) { 
            this.month = parseInt(this.set_month)+1
            this.setDayLength()
        },
        set_year(old, new_val) { 
            this.year = this.set_year
        }
    },
    methods: {
        selectYear(file) {
            this.sendDateBack()
        },
        selectMonth(){
            this.setDayLength()
            this.sendDateBack()
        },
        selectDay(){
            this.sendDateBack()
        },
        sendDateBack(){
            if(this.day && this.month && this.year){
                let day = this.day
                let month = this.month
                if(this.month < 10){
                    month = '0'+this.month
                }
                if(this.day < 10){
                    day = '0'+this.day
                }
                // let fullDate = this.day+'/'+this.month+'/'+this.year
                let fullDate = this.year+'-'+month+'-'+day
                this.$emit('setDate', fullDate)
            }else{
                // console.log('Missing dropdown')
            }
        },
        setDayLength(){
            let monthDays = 31
            if(this.month == '2'){
                monthDays = 28
            }else if(this.month == '9' || this.month == '4' || this.month == '6' || this.month == '11'){
                monthDays = 30
            }

            this.days = Array.from({length: monthDays}, (value, index) => 1 + index)
            // console.log(':::::::',monthDays)
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
