<template>
<div id="wrapper">
    <Header />

    <sidebar></sidebar>

    <!-- Main Contents -->
    <div class="main_content">
        <div class="mcontainer">
            <div class="lg:flex lg:space-x-10">

                <div class="lg:w-2/3">
                    <div class="flex justify-between items-center relative md:mb-4 mb-3 dark:bg-white">
                        <div class="flex-1">
                            <h2 class="text-2xl font-semibold"> Pages </h2>
                            <nav class="responsive-nav border-b md:m-0 -mx-4">
                                <ul>
                                    <li :class="randomActive"><a @click="makeActiveSection('random')" class="mouse-pointer lg:px-2">   Suggestions </a></li>
                                    <li :class="recentActive"><a @click="makeActiveSection('recent')" class="mouse-pointer lg:px-2"> Newest </a></li>
                                    <li :class="userActive"><a @click="makeActiveSection('user')" class="mouse-pointer lg:px-2"> My pages </a></li>
                                </ul>
                            </nav>
                        </div>
                        <a href="/dashboard/pages/create" class="flex items-center justify-center h-10 w-10 z-10 rounded-full bg-blue-600 text-white absolute right-0" data-tippy-placement="left" data-tippy="" data-original-title="Create New Page">
                            <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clip-rule="evenodd"></path>
                            </svg>
                        </a>
                    </div>

                    <div class="relative uk-slider" uk-slider="finite: true">
                        <div class="uk-slider-container px-1 py-3">
                            <ul class="uk-slider-items uk-child-width-1-3@m uk-child-width-1-3@s uk-child-width-1-2 uk-grid-small uk-grid" style="transform: translate3d(0px, 0px, 0px);">

                                <li v-for="page in pages" tabindex="-1" class="uk-active">
                                    <a :href="'/dashboard/page/'+page.id" class="uk-link-reset">
                                        <div class="card">
                                            <img v-if="page.image" :src="page.image" class="h-44 object-cover rounded-t-md shadow-sm w-full">
                                            <img v-else :src="defaultPostUrl()" class="h-44 object-cover rounded-t-md shadow-sm w-full">
                                            <div class="py-4 px-2">
                                                <h4 class="text-base font-semibold mb-1"> 
                                                    {{ page.name }} 
                                                    <verified-badge v-if="page.verified == 'true'" size="small" mt="one"></verified-badge>
                                                </h4>
                                                <p class="font-medium text-sm">{{ page.followers_count }} Following
                                                </p>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                            </ul>

                            <a class="absolute bg-white bottom-1/2 flex items-center justify-center p-2 -left-4 rounded-full shadow-md text-xl w-9 z-10 dark:bg-gray-800 dark:text-white uk-invisible" href="#" uk-slider-item="previous"> <i class="icon-feather-chevron-left"></i></a>
                            <a class="absolute bg-white bottom-1/2 flex items-center justify-center p-2 -right-4 rounded-full shadow-md text-xl w-9 z-10 dark:bg-gray-800 dark:text-white" href="#" uk-slider-item="next"> <i class="icon-feather-chevron-right"></i></a>

                        </div>
                        <div v-if="pages.length == 0 && pagesLoading == false" class="bg-white p-2">
                            <h5>No {{ section }} page </h5>
                        </div>
                        <div v-if="pagesLoading == true" class="text-center my-3">
                            <i class="fa fa-spin fa-spinner fa-2x"></i>
                        </div>
                    </div>

                    <br>

                    <div class="my-2 flex items-center justify-between pb-3">
                        <div>
                            <h2 class="text-xl font-semibold"> Pages you follow</h2>
                        </div>
                        <a href="#" class="text-blue-500 d-none"> See all </a>
                    </div>

                    <div class="relative uk-slider" uk-slider="finite: true">
                        <div class="uk-slider-container px-1 py-3">
                            <ul class="uk-slider-items uk-child-width-1-3@m uk-child-width-1-3@s uk-child-width-1-2 uk-grid-small uk-grid" style="transform: translate3d(0px, 0px, 0px);">
                                <li v-for="page in pages_followed" tabindex="-1" class="uk-active">
                                    <a :href="'/dashboard/page/'+page.page.id"  class="uk-link-reset">
                                        <div class="card">
                                            <img v-if="page.page.image" :src="page.page.image" class="h-44 object-cover rounded-t-md shadow-sm w-full">
                                            <img v-else :src="defaultPostUrl()" class="h-44 object-cover rounded-t-md shadow-sm w-full">
                                            <div class="py-4 px-2">
                                                <h4 class="text-base font-semibold mb-1"> 
                                                    {{ page.page.name }} 
                                                    <verified-badge v-if="page.page.verified == 'true'" size="small" mt="one"></verified-badge>
                                                </h4>
                                                <p class="font-medium text-sm">{{ page.page.followers_count }} Following </p>
                                            </div>
                                        </div>
                                    </a>
                                </li>

                            </ul>

                            <a class="absolute bg-white bottom-1/2 flex items-center justify-center p-2 -left-4 rounded-full shadow-md text-xl w-9 z-10 dark:bg-gray-800 dark:text-white uk-invisible" href="#" uk-slider-item="previous"> <i class="icon-feather-chevron-left"></i></a>
                            <a class="absolute bg-white bottom-1/2 flex items-center justify-center p-2 -right-4 rounded-full shadow-md text-xl w-9 z-10 dark:bg-gray-800 dark:text-white" href="#" uk-slider-item="next"> <i class="icon-feather-chevron-right"></i></a>

                        </div>
                        <div v-if="pagesFollowedLoading == false && pages_followed.length == 0">
                            <h5>You are not following any page yet</h5>
                        </div>
                        <div v-if="pagesFollowedLoading == true" class="text-center my-3">
                            <i class="fa fa-spin fa-spinner fa-2x"></i>
                        </div>
                    </div>

                </div>

                <!-- Sidebar -->
                <div class="lg:w-1/3 w-full">
                    <div uk-sticky="media @m ; offset:80 ; bottom : true" class="card uk-sticky uk-active uk-sticky-fixed" style="position: fixed; top: 80px; width: 313px;">

                        <div class="border-b flex items-center justify-between p-4">
                            <div>
                                <h2 class="text-lg font-semibold">Top Pages</h2>
                            </div>
                            <a href="#" class="text-blue-500 d-none"> See all </a>
                        </div>

                        <div class="p-4 -mt-1.5">

                            <div v-for="(page, index) in top_pages" class="flex items-center space-x-4 rounded-md -mx-2 p-2 hover:bg-gray-50">
                                <a :href="'/dashboard/page/'+page.id" iv="" class="w-12 h-12 flex-shrink-0 overflow-hidden rounded-full relative">
                                    <img v-if="page.image" :src="page.image" class="absolute w-full h-full inset-0">
                                    <img v-else :src="defaultPostUrl()" class="absolute w-full h-full inset-0">
                                </a>
                                <div class="flex-1">
                                    <a :href="'/dashboard/page/'+page.id" class="text-base font-semibold capitalize"> 
                                        {{ page.name }} 
                                        <verified-badge v-if="page.verified == 'true'" size="small" mt="one"></verified-badge>
                                    </a>
                                    <div class="text-sm text-gray-500 mt-0.5"> {{ page.followers_count }} Following</div>
                                </div>
                                <a v-if="!page.user_follow" @click="followPage(index, page)" href="javascript:;" class="flex items-center justify-center h-8 px-3 bg-orange text-white rounded-md text-sm border font-semibold">
                                    Follow
                                </a>
                                <a v-else @click="unfollowPage(index, page)" href="javascript:;" class="flex items-center justify-center h-8 px-3 rounded-md text-sm border font-semibold">
                                    Following
                                </a>
                            </div>
                            <div v-if="pagesTopLoading == false && top_pages.length == 0" class="bg-white p-2">
                                <h5>No Top pages yet</h5>
                            </div>
                            <div v-if="pagesTopLoading == true" class="text-center my-3">
                                <i class="fa fa-spin fa-spinner fa-2x"></i>
                            </div>

                        </div>

                        <a href="#" class="block-old d-none text-center pb-4 font-medium text-blue-500"> See all </a>

                    </div>
                    <div class="uk-sticky-placeholder" style="height: 510px; margin: 0px;"></div>
                </div>

            </div>
        </div>
        <div id="progressLoader">
            <progress-loader></progress-loader>
        </div>
    </div>

    <dashboard-widgets page="pages"></dashboard-widgets>
</div>
</template>

<script>
import {
    ApiService
} from "@/services/api.service";
import Header from "@/include/Dashboard/Header.vue";
import Sidebar from "@/include/Dashboard/Sidebar.vue";
import DashboardWidgets from '@/components/DashboardWidgets'
import OpenChatBox from "@/include/Modals/OpenChatBox.vue";
import Feeds from "@/include/Dashboard/Feeds.vue";
import ProgressLoader from '@/components/ProgressLoader'
import VerifiedBadge from '@/components/VerifiedBadge'

export default {
    name: "Pages",
    components: {
        Header,
        Sidebar,
        DashboardWidgets,
        OpenChatBox,
        Feeds,
        ProgressLoader,
        VerifiedBadge,
    },

    data() {
        return {
            pages: [],
            pages_followed: [],
            top_pages: [],
            page: 0,
            page_followed: 0,
            pendingRequest: false,
            pagesLoading: false,
            pagesFollowedLoading: false,
            pagesTopLoading: false,
            recentActive: 'active',
            section: '',
            trendingActive: '',
            oldestActive: '',
            userActive: '',
            randomActive: '',
            topContributions: '',
            topContributors: '',
            pagination: {
                limit: 20,
                offset: 0,
                total: 60,
            },
        };
    },
    mounted: function () {
        this.makeActiveSection('random');
        this.getFollowedPages()
        this.getTopPages()
    },
    methods: {
        async getPages(section) {
            this.pagesLoading = true;
            this.toggleProgress('show')
            let apiResponse = await ApiService.GetPages(this.page, section);
            if (apiResponse["status"] != "success") {} else {
                this.page++;
                this.pages = apiResponse["data"]['pages'];
                this.pagesLoading = false;
            }
            this.toggleProgress('hide')
        },
        async getTopPages() {
            this.pagesTopLoading = true;
            this.toggleProgress('show')
            let apiResponse = await ApiService.GetPages(0, 'popular');
            if (apiResponse["status"] != "success") {} else {
                this.top_pages = apiResponse["data"]['pages'];
                this.pagesTopLoading = false;
            }
            this.toggleProgress('hide')
        },
        async getFollowedPages(section) {
            this.pagesFollowedLoading = true;
            this.toggleProgress('show')
            let apiResponse = await ApiService.GetFollowedPages(this.page_followed, section);
            if (apiResponse["status"] != "success") {} else {
                this.pages_followed = apiResponse["data"]['pages'];
                this.pagesFollowedLoading = false;
            }
            this.toggleProgress('hide') 
        },
        async followPage(index, page) {
            this.toggleProgress('show')
            let apiResponse = await ApiService.FollowPage(page.id);
            this.toggleProgress('hide')
            this.$store.commit("toast/Notify", {
                type: apiResponse["status"],
                message: apiResponse["message"],
            });
            if (apiResponse["status"] != "success") {} else {
                // location.href = ''
                page['user_follow'] = {'following': 'true'}
                this.pages[index] = page

                this.getFollowedPages()
            }
        },
        async unfollowPage(index, page) {
            this.toggleProgress('show')
            let apiResponse = await ApiService.UnfollowPage(page.id);
            this.toggleProgress('hide')
            this.$store.commit("toast/Notify", {
                type: apiResponse["status"],
                message: apiResponse["message"],
            });
            if (apiResponse["status"] != "success") {} else {
                // location.href = ''
                page['user_follow'] = ''
                this.top_pages[index] = page

                this.getFollowedPages()
            }
        },
        async makeActiveSection(a) {
            this.recentActive = ''
            this.oldestActive = ''
            this.trendingActive = ''
            this.userActive = ''
            this.randomActive = ''

            this.page = 0;
            this.pages = []

            this.section = a

            if (a.toLowerCase() == 'recent') {
                this.recentActive = 'active'
                this.getPages('recent')
            } else if (a.toLowerCase() == 'oldest') {
                this.oldestActive = 'active'
                this.getPages('oldest')
            } else if (a.toLowerCase() == 'trending') {
                this.trendingActive = 'active'
                this.getPages('trending')
            } else if (a.toLowerCase() == 'random') {
                this.randomActive = 'active'
                this.getPages('random')
            } else if (a.toLowerCase() == 'user') {
                this.userActive = 'active'
                this.getPages('user')
            }
        },
    },
};
</script>

<style>
.page-image img {
    width: 3rem !important;
    height: 3rem !important;
    border-radius: 9999px;
    position: relative;
}
</style>

<style lang="scss" scoped>
.some-modal-content {
    min-width: 400px;
    padding: 25px;

    .buttons button {
        padding: 10px;
        margin: 10px;
    }
}
</style>
