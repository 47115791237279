<template>
<!-- birthdays modal -->
<div id="save-status-modal" uk-modal="">
    <div class="uk-modal-dialog uk-modal-body rounded-xl shadow-lg sm:px-3 px-1">
        <!-- close button -->
        <button class="uk-modal-close-default p-2.5 bg-gray-100 rounded-full m-3" type="button" uk-close=""></button>

        <div class="col-lg-12 col-md-12 col-sm-12">
            <div class="message-reply d-block bg-gray-50-old p-0 border-0 pt-5">
                <div class="mb-5">
                    <div class="flex mt-2 justify-content-center">
                        <a href="Javascript:;" class="mr-3 p-2 bg-gray-200 rounded" @click="setMessageType('text')" uk-tooltip="Share Text Story">
                            <i class="fa fa-pencil"></i>
                        </a>
                        <a href="Javascript:;" class="mr-3 p-2 bg-gray-200 rounded" @click="setMessageType('image')" uk-tooltip="Share Image Story">
                            <i class="fa fa-image"></i>
                        </a>
                    </div>
                </div>
                <hr />

                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="message-reply d-block bg-gray-50-old p-0 border-0">
                        <div class="mt-3">
                            <template v-if="messageType == 'text'">
                                <div class="rounded" style="height: 70vh; background-color: #2563eb;display: flex;align-items: center; text-align: center;">
                                    <div class="w-full ">
                                        <textarea @keyup="resizeTextArea('#textStory')" v-model="message" id="textStory" cols="1" class="pl-2 px-1 text-white text-center" rows="1" placeholder="what's up" style="padding: 10px; background-color: #2563eb; font-size: 20px;max-height: 60vh;" />
                                    </div>
                            </div>
                            <!-- <button v-if="messageType == 'text'" @click="saveStory" class="mt-3 button ripple-effect">Send</button> -->
                        </template>
                        <template v-if="messageType == 'link'">
                            <input v-model="message" class="pl-2 bg-gray-50 px-1" placeholder="https://example.co" type="url" />
                            <!-- <button v-if="messageType == 'link'" @click="saveStory" class="mt-3 button ripple-effect">Send</button> -->
                        </template>
                        <template v-if="messageType == 'image'">
                            <div v-if="image" class="story_image" >
                                <img :src="image" alt="story item" style="" />
                                <div class="text-center" v-html="customTextRender(caption,'','')">
                                </div>
                            </div>
                            <div v-else class="text-center">
                                <img :src="defaultImageUrl()" class="object-contain" style="height: 50vh" />
                            </div>
                            <p class="mt-3"></p>
                            <single-file-upload type="image" @uploadSuccess="onImageUploaded">
                                <div class="flex">
                                    <svg class="bg-blue-100 h-9 p-1.5 rounded-full text-blue-600 w-9 cursor-pointer" fill="none" stroke="currentColor" viewbox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"></path>
                                    </svg>
                                    <span class="mt-2.5 ml-2"> Select an image</span>
                                </div>
                            </single-file-upload>
                            <p class="mt-3">Add caption:</p>
                            <input v-model="caption" class="pl-2 bg-gray-200-old px-1 mt-2" placeholder="caption" type="text" />
                            <!-- <button v-if="messageType == 'image'" @click="saveStory" class="ml-4old mt-3 button ripple-effect">Send</button> -->
                        </template>
                        <div class="flex">
                            <button v-if="image || message" @click="saveStory" class="mt-3 button btn-block ripple-effect">Send</button>
                            <button v-else disabled class="mt-3 button btn-block bg-smoke ripple-effect">Send</button>
                            <button @click="setStoryContent('view','')" class="ml-1 mt-3 button btn-block ripple-effect bg-red">Close</button>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    ApiService
} from "@/services/api.service";
import SingleFileUpload from '@/components/SingleFileUpload'
export default {
    name: "SaveStatusModal",
    data() {
        return {
            currentYear: new Date().getFullYear(),
            appName: "-",
            messageType: 'text',
            message: '',
            caption: '',
            image: '',
            story: '',
            stories: '',
            my_story: '',
            storyItem: '',
        }
    },
    components: {
        SingleFileUpload
    },
    mounted: function () {
        this.currentUser = this.$store.getters["user/currentUser"];
        this.getStory()
    },
    methods: {
        async getStory() {
            this.storyLoading = true
            let apiResponse = await ApiService.GetStory()
            if (apiResponse['status'] != 'success') {} else {
                this.storyLoading = false
                this.my_story = apiResponse['data']['my_story']
                this.stories = apiResponse['data']['user_stories']
                // console.log(this.stories)
            }
        },
        async viewStoryItem(id) {
            this.storyLoading = true
            let apiResponse = await ApiService.ViewStoryItem(id)
            if (apiResponse['status'] != 'success') {} else {}
        },
        setStoryContent(mainSection, story) {
            if (story) {
                this.setStory(story)
            }
            this.mainSection = mainSection
        },
        setStory(story) {
            this.story = story
            if (story.items.length > 0) {
                for (let i = 0; i < story.items.length; i++) {
                    const item = story.items[i];

                    let vueThis = this
                    setTimeout(() => {
                        vueThis.storyItem = item
                        vueThis.viewStoryItem(item.id)
                        vueThis.manageStatusItemInView(6000, item.id)
                    }, 6000 * i);

                    // vueThis.storyItem = item
                    // break
                }
            }
        },
        manageStatusItemInView(time, id) {
            // console.log('lll', time, id)
        },
        setMessageType(messageType) {
            this.messageType = messageType
        },
        async saveStory() {
            if (!this.message) {
                return this.$store.commit("toast/Notify", {
                    type: "warning",
                    message: this.messageType + " is required",
                });
            }
            let apiResponse = await ApiService.SaveStory(
                this.messageType,
                this.message,
                this.caption,
            );
            this.$store.commit("toast/Notify", {
                type: apiResponse["status"],
                message: apiResponse["message"],
            });
            if (apiResponse["status"] != "success") {} else {
                location.href = ''
            }
        },
        onImageUploaded(response) {
            // Handle files like:
            // console.log('::::',response)
            if (response['status'] == 'success') {
                this.image = (response['data']['file_name'])
                this.message = (response['data']['file_name'])
            }
            this.$store.commit("toast/Notify", {
                type: response["status"],
                message: response["message"],
            });
        },
    }
};
</script>

<style lang="scss" scoped></style>
