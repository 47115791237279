<template>
    <div id="wrapper" class="flex flex-col justify-between h-screen">
        <auth-styles pageNameMain="Login"></auth-styles>
        <auth-header></auth-header>

        <!-- Content-->
        <div>
            <div class="lg:p-12 max-w-xl lg:my-0 my-12 mx-auto p-6 space-y-">
                
                <form class="lg:p-10 p-6 space-y-3 relative bg-white shadow-xl rounded-md">
                    
                    <div>
                        <h1 class="lg:text-2xl text-xl font-semibold mb-6"> Request Account Activation </h1>
                        <div>
                            <label class="mb-0"> Email Address </label>
                            <input v-model="email" type="email" placeholder="Info@example.com" class="bg-gray-100 h-12 mt-2 px-3 rounded-md w-full">
                        </div>

                        <div>
                            <button v-on:click="requestAccountActivation" type="button" class="bg-blue-600 font-semibold p-4 mt-5 rounded-md text-center text-white w-full">
                                Request</button>
                        </div>
                    </div>
                    <div class="text-center">
                        <p>
                            <router-link to="/login">Click here to login</router-link>
                        </p>
                    </div>
                </form>

            </div>
        </div>

        <auth-scripts></auth-scripts>

    </div>
</template>

<script>
import { ApiService } from '@/services/api.service'
import AuthStyles from '../../include/Auth/AuthStyles'
import AuthScripts from '../../include/Auth/AuthScripts'
import AuthHeader from '@/include/Auth/AuthHeader'

export default {
  name: 'RequestAccountActivation',
  data () {
    return {
      email: '',
      code: '',
      password: '',
      repeat_password: '',
      stage: '1',
      error: ''
    }
  },
  components: {
    AuthStyles,
    AuthScripts,
    AuthHeader
  },
  methods: {
    async requestAccountActivation () {
        if(!this.email){
            return this.$store.commit('toast/Notify', { type: 'warning', message: 'email is required' })
        }
        let apiResponse = await ApiService.RequestAccountActivation(this.email)
        // console.log(apiResponse)
        this.$store.commit('toast/Notify', { type: apiResponse['status'], message: apiResponse['message'] })
        if(apiResponse['status'] == 'success'){
        }
    },
  }
}
</script>

<style lang="scss" scoped>
</style>
