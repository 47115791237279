<template>
<section data-section-id="1" data-category="logo-clouds" data-component-id="2c84e5fe_05_awz" class="py-20 bg-white" data-path="0">
    <div class="container px-4 mx-auto" data-path="0.0">
        <h2 class="mb-12 text-center text-xl font-bold font-heading" data-config-id="header" data-path="0.0.0">
            As an Advertiser, you can create tasks for the following and<br/>
as a promoter, you can earn from tasks on the following platforms.
        </h2>
        <div class="flex flex-wrap -m-4" data-path="0.0.1">
            <div class="w-full md:w-1/2 lg:w-1/4 p-4" data-path="0.0.1.0">
                <div class="flex w-full h-32 items-center justify-center shadow-2xl rounded-lg" data-path="0.0.1.0.0">
                    <img class="h-12" src="/assets/img/fb.png" alt="" data-config-id="logo1" data-path="0.0.1.0.0.0">
                </div>
            </div>
            <div class="w-full md:w-1/2 lg:w-1/4 p-4" data-path="0.0.1.1">
                <div class="flex w-full h-32 items-center justify-center shadow-2xl rounded-lg" data-path="0.0.1.1.0">
                    <img class="h-12" src="/assets/img/tw.png" alt="" data-config-id="logo2" data-path="0.0.1.1.0.0">
                </div>
            </div>
            <div class="w-full md:w-1/2 lg:w-1/4 p-4" data-path="0.0.1.2">
                <div class="flex w-full h-32 items-center justify-center shadow-2xl rounded-lg" data-path="0.0.1.2.0">
                    <img class="h-12" src="/assets/img/ig.png" alt="" data-config-id="logo3" data-path="0.0.1.2.0.0">
                </div>
            </div>
            <div class="w-full md:w-1/2 lg:w-1/4 p-4" data-path="0.0.1.3">
                <div class="flex w-full h-32 items-center justify-center shadow-2xl rounded-lg" data-path="0.0.1.3.0">
                    <img class="h-12" src="/assets/img/www.png" alt="" data-config-id="logo4" data-path="0.0.1.3.0.0">
                </div>
            </div>
        </div>
    </div>
</section>
</template>

<script>
export default {
    name: 'AdvertPlatforms',
    props: {
        imgLink: String,
        title: String,
        subtitle: String
    },
    components: {},
    data() {
        return {

        }
    },
    mounted() {},
    methods: {}
}
</script>
