<template>
<div class="card md:p-6 p-2 max-w-3xl mx-auto">

    <h2 class="text-xl font-bold"> Friends</h2>

    <nav class="responsive-nav border-b d-none">
        <ul>
            <li class="active"><a href="#" class="lg:px-2"> All Friends <span> 3,4510 </span> </a></li>
            <li><a href="#" class="lg:px-2"> Recently added </a></li>
            <li><a href="#" class="lg:px-2"> Family </a></li>
            <li><a href="#" class="lg:px-2"> University </a></li>
        </ul>
    </nav>

    <div class="grid md:grid-cols-4 sm:grid-cols-3 grid-cols-2 gap-x-2 gap-y-4 mt-3">

        <div class="card p-2" v-for="fl in followers_list">
            <a :href="'/dashboard/timeline/'+fl.follower.id">
                <img v-if="fl.follower.profile_photo" :src="fl.follower.profile_photo" alt="" class="h-36 object-cover rounded-md shadow-sm w-full">
                <img v-else :src="defaultImageUrl()" alt="" class="h-36 object-cover rounded-md shadow-sm w-full">
            </a>
            <div class="pt-3 px-1">
                <a :href="'/dashboard/timeline/'+fl.follower.id" class="text-base font-semibold mb-0.5"> 
                    {{ fl.follower.name }} 
                    <verified-badge v-if="fl.follower.verified == 'true'" size="small" mt="one"></verified-badge>
                </a>
                <p class="font-medium text-sm">{{ fl.follower.followers }} Following </p>
                <button v-if="fl.i_follow" class="bg-blue-100 w-full flex font-semibold h-8 items-center justify-center mt-3 px-3 rounded-md text-blue-600 text-sm mb-1">
                    Following
                </button>
                <button @click="followUser(fl.follower.id)" v-else class="bg-blue-600 w-full flex font-semibold h-8 items-center justify-center mt-3 px-3 rounded-md text-white text-sm mb-1">
                    Follow
                </button>
            </div>
        </div>

    </div>

    <div class="flex justify-center mt-6">
        <a v-if="followers_list.length > 0" @click="loadMoreFollowers" href="Javascript:;" class="bg-white font-semibold my-3 px-6 py-2 rounded-full shadow-md dark:bg-gray-800 dark:text-white">
            Load more ..</a>
    </div>

</div>
</template>

<script>
import {
    ApiService
} from '@/services/api.service'
import VerifiedBadge from '@/components/VerifiedBadge'
export default {
    name: "FriendScreen",
    data() {
        return {
            followings: '',
            followers: '',
            followers_list: [],
            groups: [],
            page: 0,
            pendingRequest: false,
        };
    },
    props: {
        currentUser: Object,
    },
    components: {
        VerifiedBadge
    },
    mounted: function () {
        this.thisUser = this.$store.getters["user/currentUser"];
        let id = this.$route.params['id'] || this.thisUser['id']
        this.id = id
        this.userFollowership()
    },
    methods: {
        async userFollowership(){
            let apiResponse = await ApiService.UserFollowership(this.id, this.page);
            // console.log('UserFollowership',apiResponse)
            if(apiResponse['status'] == 'success'){
                this.followings = apiResponse['data']['followings_count'].toString()
                this.followers = apiResponse['data']['followers_count'].toString()
                let followers_list = apiResponse['data']['followers']
                if(followers_list.length > 0){
                    this.page++
                }
                // console.log('this.page',this.page)
                if(this.followers_list.length == 0){
                    this.followers_list = followers_list
                }else{
                    if(followers_list.length > 0){
                        this.followers_list = this.followers_list.concat(followers_list)
                    }
                }
            }
        },
        async followUser(id){
            // console.log('this.page',this.page)
            this.page--
            // console.log('this.page',this.page)
            let apiResponse = await ApiService.FollowUser(id);
            // console.log('FollowUser',apiResponse)
            this.$store.commit("toast/Notify", {
                type: apiResponse["status"],
                message: apiResponse["message"],
            });
            if(apiResponse['status'] == 'success'){
                // this.page--
                // this.userFollowership()
            }
        },
        async loadMoreFollowers() {
            this.userFollowership()
        },
    }
};
</script>

<style lang="scss" scoped></style>
