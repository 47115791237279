<template>
<div class="page-wrapper">

    <div class="d-none" oldclass="preloader">
        <div class="lds-ripple">
            <div></div>
            <div></div>
        </div>
    </div><!-- /.preloader -->
    
    <header class="main-nav__header-one ">
        <nav class="header-navigation stricky">
            <div class="container p-0">
                <!-- Brand and toggle get grouped for better mobile display -->
                <div class="main-nav__logo-box">
                    <a href="/" class="main-nav__logo">
                        <img src="/assets/img/vt_social_logo.png" width="150" alt="Awesome Image" class="mobile_logo" style="margin-top: 12px;" />
                    </a>
                    <!-- <a href="#" class="side-menu__toggler"><i class="fa fa-bars"></i></a> -->
                </div><!-- /.logo-box -->
                <!-- Collect the nav links, forms, and other content for toggling -->
                <div class="main-nav__main-navigation">
                    <ul class="one-page-scroll-menu main-nav__navigation-box">
                        <li class="current scrollToLink">
                            <a href="#banner">Home</a>
                        </li>
                        <li class="scrollToLink">
                            <a href="#features">About Us</a>
                        </li>
                        <li class="scrollToLink">
                            <a href="#why-choose">Why Choose Us</a>
                        </li>

                        <li class="scrollToLink" id="faqMenu">
                            <a href="#faq">Faqs</a>

                        </li>
                        <li class="scrollToLink" id="contactMenu">
                            <a href="#contact">Contact</a>
                        </li>
                    </ul>
                </div><!-- /.navbar-collapse -->
                <div class="main-nav__right">
                    <a href="/login" class="thm-btn header__btn btn-header-login scroll-to-target">Login</a><!-- /.thm-btn header__btn -->
                    <a href="/register" class="thm-btn header__btn btn-header-sign scroll-to-target"> Join Now </a><!-- /.thm-btn header__btn -->
                </div><!-- /.main-nav__right -->
            </div>
            <!-- /.container -->
        </nav>
    </header><!-- /.main-nav__header-one -->

    <section class="banner-one section_banner" id="banner">

        <div class="container mobile_bg">
            <div class="row">
                <div class="col-lg-7 col-order">
                    <div class="banner-one__content wow fadeInLeft" data-wow-delay="000ms" data-wow-duration="100ms">

                        <h3> The Social Sharing<br /> Network that pays<br /> you to socialize </h3>
                        <p>We pay our content creators and promoters<br /> because we believe that time is<br /> money. </p>
                        <a href="/register" class="thm-btn scroll-to-target btn-signin">Join Now</a>
                        <a href="/login" class="thm-btn scroll-to-target btn-login">Login</a>

                        <!-- /.thm-btn banner-one__btn -->
                    </div><!-- /.banner-one__content -->
                </div><!-- /.col-lg-7 -->

                <div class="col-lg-5 wow fadeInRight" data-wow-delay="100ms" data-wow-duration="1500ms">
                    <div class="banner-one__img ">

                        <img src="/home/images/shapes/model.png" alt="" class="img-fluid">

                        <!-- /.thm-btn banner-one__btn -->
                    </div><!-- /.banner-one__content -->
                </div><!-- /.col-lg-7 -->

            </div><!-- /.row -->
        </div><!-- /.container -->

    </section><!-- /.banner-one -->

    <section class="sections section_features service-one" id="features">
        <div class="container">
            <div class="block-title text-center">
                <span class="block-title__bubbles"></span>
                <h3 class="wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="100ms">Go Viral and Get Paid</h3>
                <p class="wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="200ms">Create a campaign and reach out to millions of targeted users for less.</p>

            </div><!-- /.block-title -->

            <div class="row align-items-center">
                <div class="col-xl-5 col-lg-6">
                    <div class="cta-two__content wow fadeInLeft" data-wow-duration="2000ms" data-wow-delay="100ms">

                        <img src="/home/images/shapes/verified.png" alt="" class="cta-two-check">
                        <h3>Get verified and<br /> increase your<br /> earnings.</h3>
                        <p>We pay our content creators because we believe that time is money.</p>
                        <a href="/register" class="thm-btn cta-two__btn">Join Now</a><!-- /.thm-btn cta-two__btn -->
                    </div><!-- /.cta-two__content -->
                </div><!-- /.col-lg-5 -->

                <div class="col-xl-7 col-lg-6">
                    <div class="cta-two__img wow fadeInRight" data-wow-delay="200ms">
                        <img src="/home/images/shapes/get_paid.png" alt="" class="cta-two__service img-fluid">
                    </div><!-- /.cta-two__content -->
                </div><!-- /.col-lg-5 -->

            </div>

        </div><!-- /.container -->
    </section><!-- /.service-one -->

    <section class="sections section_why_choose cta-two" id="why-choose">

        <div class="container">
            <div class="block-title text-center">

                <h3 class="text-white">Why Choose Us</h3>

            </div>
            <div class="service-inner wow fadeInUp" data-wow-duration="1500ms">
                <div class="row">
                    <div class="service-one__col wow fadeInUp" data-wow-delay="000ms">
                        <div class="service-one__single">
                            <img src="/home/images/shapes/creator.png" alt="creator">
                            <h3>Creator </h3>
                            <p>We empower and pay our content creators for their time and content</p>

                        </div><!-- /.service-one__single -->
                        <ul class="list-unstyled why-choose__list">

                            <li><i class="fa fa-check"></i> Get Verified</li>
                            <li><i class="fa fa-check"></i> Monetize Content </li>
                            <li><i class="fa fa-check"></i> Get Paid</li>
                        </ul>
                    </div><!-- /.service-one__col -->
                    <div class="service-one__col wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="100ms">
                        <div class="service-one__single">
                            <img src="/home/images/shapes/promotion.png" alt="creator">
                            <h3>Promoters </h3>
                            <p>As a promoter, you can earn from task on the following plaforms. </p>

                        </div><!-- /.service-one__single -->
                        <ul class="list-unstyled why-choose__list">
                            <li><i class="fa fa-check"></i>Add Social Media Account </li>
                            <li><i class="fa fa-check"></i> Perform Tasks </li>
                            <li><i class="fa fa-check"></i> Get Paid For Task </li>
                            <li><i class="fa fa-check"></i>Withdraw Funds From Wallet </li>
                        </ul>
                    </div><!-- /.service-one__col -->

                    <div class="service-one__col wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="200ms">
                        <div class="service-one__single">
                            <img src="/home/images/shapes/ad.png" alt="creator">
                            <h3>Advertisers</h3>
                            <p>As and Advertiser, you can create tasks for the follwing </p>

                        </div><!-- /.service-one__single -->
                        <ul class="list-unstyled why-choose__list">
                            <li><i class="fa fa-check"></i>Select Advert Platform </li>
                            <li><i class="fa fa-check"></i> Select Advert Type </li>
                            <li><i class="fa fa-check"></i> Create Advert(Campaign) </li>
                            <li><i class="fa fa-check"></i>Pay For Advert </li>
                            <li><i class="fa fa-check"></i>Sponsor Feeds </li>
                        </ul>
                    </div><!-- /.service-one__col -->

                </div><!-- /.row -->

            </div>

        </div><!-- /.container -->
    </section><!-- /.cta-two -->

    <section class="sections section_funfact funfact-one">

        <div class="block-title text-center">
            <span class="block-title__bubbles"></span>
            <h3 class="">Fun & Facts</h3>

        </div>

        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-3 col-md-6">
                    <div class="funfact-one__single wow fadeInUp" data-wow-delay="000ms">
                        <div class="coubnder-inner">
                            <h3 class="counter">550</h3>
                            <h2 class="counter-sign">m +</h2>
                        </div>

                        <p>Amount Earned</p>
                    </div><!-- /.funfact-one__single -->
                </div><!-- /.col-lg-3 -->
                <div class="col-lg-3 col-md-6">
                    <div class="funfact-one__single wow fadeInUp" data-wow-delay="100ms">
                        <div class="coubnder-inner">
                            <h3 class="counter">240</h3>
                            <h2 class="counter-sign">k +</h2>
                        </div>

                        <p>Tasks Completed</p>
                    </div><!-- /.funfact-one__single -->
                </div><!-- /.col-lg-3 -->
                <div class="col-lg-3 col-md-6">
                    <div class="funfact-one__single wow fadeInUp" data-wow-delay="200ms">
                        <div class="coubnder-inner">
                            <h3 class="counter">500</h3>
                            <h2 class="counter-sign">k +</h2>
                        </div>

                        <p>Promoters</p>
                    </div><!-- /.funfact-one__single -->
                </div><!-- /.col-lg-3 -->

            </div><!-- /.row -->
        </div><!-- /.container -->
    </section><!-- /.funfact-one -->

    <section class="sections section_brand brand-one brand-one__home-one">
        <div class="container">

            <div class="block-title text-center">
                <span class="block-title__bubbles"></span>

                <p class="wow fadeInUp animated text-white" data-wow-duration="1500ms" data-wow-delay="200ms">As an Advertiser you can create tasks for the following and
                    as a promoter you can earn from tasks on the following platforms </p>

            </div>

            <div class="brand-one__carousel">
                <div class="item">
                    <img src="/home/images/shapes/facebook.png" alt="">
                </div><!-- /.item -->
                <div class="item">
                    <img src="/home/images/shapes/twitter.png" alt="">
                </div><!-- /.item -->
                <div class="item">
                    <img src="/home/images/shapes/instagram.png" alt="">
                </div><!-- /.item -->
                <div class="item">
                    <img src="/home/images/shapes/www.png" alt="">
                </div><!-- /.item -->

            </div><!-- /.brand-one__carousel owl-carousel thm__owl-carousel owl-theme -->
        </div><!-- /.container -->
    </section><!-- /.brand-one -->

    <section class="sections section_faq faq-one" id="faq">
        <div class="container" style="display: block">
            <div class="block-title text-center">
                <span class="block-title__bubbles"></span>
                <p>Frequently Asked Questions</p>
                <h3>Have any Question?</h3>
            </div><!-- /.block-title -->
            <div class="accrodion-grp wow fadeIn" data-wow-duration="1500ms" data-grp-name="faq-accrodion">
                <div class="accrodion active ">
                    <div class="accrodion-inner">
                        <div class="accrodion-title">
                            <h4>How do I register either as an advertiser or a promoter?</h4>
                        </div>
                        <div class="accrodion-content">
                            <div class="inner">
                                <div data-v-564697ec="" class="mt-6 border-l-2 border-gray-50pl-0">
                                    <p data-v-564697ec="">Hey! So you wish to go viral or earn some cool cash performing some social tasks with vitisocial, but don't know how to go about registration?</p>
                                    <p data-v-564697ec="">Don't beat yourself too much, registration is quite simple and the website is user-friendly.</p>
                                    <p data-v-564697ec="">To register all you need to do is visit our website <a data-v-564697ec="" href="https://www.vitisocial.com/">https://www.vitisocial.com</a></p>
                                    <p data-v-564697ec="">Please ensure that you choose 'promote and earn' while registering to start performing tasks or you can choose 'Advertiser' to publish adverts.</p>
                                    <p data-v-564697ec="">Once you have registered, the website would automatically send to you a verification link to the email address that you just registered with. </p>
                                    <p data-v-564697ec="">Once you receive the verification link, login into your email and click on 'verify' email. Choose a package, basic or premium account type, pay and you are now good to go!</p>
                                    <p data-v-564697ec="">Simple right?</p>
                                    <h4 data-v-564697ec="">NOTE:</h4>
                                    <p data-v-564697ec="">You can also successfully register with vitisocial, using the direct links below:</p>
                                    <p data-v-564697ec=""><a data-v-564697ec="" href="https://advertisers.vitisocial.com/register">https://advertisers.vitisocial.com/register</a></p>
                                    <p data-v-564697ec=""><a data-v-564697ec="" href="https://promoters.vitisocial.com/register">https://promoters.vitisocial.com/register</a></p>
                                </div>
                            </div><!-- /.inner -->
                        </div>
                    </div><!-- /.accrodion-inner -->
                </div>
                <div class="accrodion  ">
                    <div class="accrodion-inner">
                        <div class="accrodion-title">
                            <h4>How do I link my social media account to my viral trend account?</h4>
                        </div>
                        <div class="accrodion-content">
                            <div class="inner">

                                <div data-v-564697ec="" class="mt-6 border-l-2 border-gray-50pl-0">
                                    <p data-v-564697ec="">So you have successfully registered with Vitisocial? Congratulations!</p>
                                    <p data-v-564697ec=""> But you are now confused aboutout how you can link your social media accounts to your vitisocial account? </p>
                                    <p data-v-564697ec=""> Well, it is not as hard as you may think! </p>
                                    <p data-v-564697ec=""> To link your social media accounts you would need to first log in your social media accounts which include <b data-v-564697ec="">Facebook, Twitter, YouTube,</b> and <b data-v-564697ec="">Instagram</b> accounts into different tabs in your browser (Firefox/chrome).</p>
                                    <p data-v-564697ec=""> Then go to your vitisocial dashboard, click on profile and connect your social media usernames correctly to the site. </p>
                                    <p data-v-564697ec=""> However, you should note that you can always leave any social media network which you don't want to link. Be informed that such action would make you not see tasks from that particular social media on your Vitisocial dashboard. </p>
                                    <p data-v-564697ec="">You can also skip the linking and continue from your profile when you are done with your registration.</p>
                                    <p data-v-564697ec="">Please note that you must link only your active social media accounts with our platform as nonactive or dormant accounts are not accepted on our platform, and when used on our platform can lead to our team banning your account.</p>
                                </div>

                            </div><!-- /.inner -->
                        </div>
                    </div><!-- /.accrodion-inner -->
                </div>
                <div class="accrodion ">
                    <div class="accrodion-inner">
                        <div class="accrodion-title">
                            <h4>How to perform social media tasks such as Facebook, Twitter, Instagram, and YouTube.</h4>
                        </div>
                        <div class="accrodion-content">
                            <div class="inner">
                                <div data-v-564697ec="" class="mt-6 border-l-2 border-gray-50pl-0">
                                    <p data-v-564697ec="">Performing social media tasks Is quite simple on Vitisocial. </p>
                                    <p data-v-564697ec="">To effectively do that, you need to ensure that your Instagram account is active in your browser/ app.</p>
                                    <p data-v-564697ec="">After that go to your dashboard, and click 'perform' once you see Instagram tasks, then input your email and username to complete the task.</p>
                                    <p data-v-564697ec="">Simple right?</p>
                                </div>
                            </div><!-- /.inner -->
                        </div>
                    </div><!-- /.accrodion-inner -->
                </div>
                <div class="accrodion ">
                    <div class="accrodion-inner">
                        <div class="accrodion-title">
                            <h4>I was restricted from commenting or liking on Instagram (action blocked)</h4>
                        </div>
                        <div class="accrodion-content">
                            <div class="inner">
                                <div data-v-564697ec="" class="mt-6 border-l-2 border-gray-50pl-0">
                                    <p data-v-564697ec=""> oops! We are sorry for that. </p>
                                    <p data-v-564697ec="">There are quite a few things that could cause that and fixing the problem isn't such a big deal.</p>
                                    <p data-v-564697ec="">Firstly try logging out and logging back in from your Instagram account or you can try changing your Instagram password or just switch to another Instagram account.</p>
                                    <p data-v-564697ec="">If the problem persists, you can contact an Admin to change your Instagram account. Usually, the action block lasts for about 3-7 days. </p>
                                </div>
                            </div><!-- /.inner -->
                        </div>
                    </div><!-- /.accrodion-inner -->
                </div>

                <div id="target">
                    <div class="accrodion ">
                        <div class="accrodion-inner">
                            <div class="accrodion-title">
                                <h4>Site not opening or loading</h4>
                            </div>
                            <div class="accrodion-content">
                                <div class="inner">
                                    <div data-v-564697ec="" class="mt-6 border-l-2 border-gray-50pl-0">
                                        <p data-v-564697ec=""> We understand how frustrating it could be when you have a task to perform and the site is not opening or loading! </p>
                                        <p data-v-564697ec="">You can fix this by kindly checking if your network is connected to the internet and refreshing. You can also clear your browser history and cache then try again.</p>
                                        <p data-v-564697ec=""></p>
                                    </div>
                                </div><!-- /.inner -->
                            </div>
                        </div><!-- /.accrodion-inner -->
                    </div>

                    <div class="accrodion ">
                        <div class="accrodion-inner">
                            <div class="accrodion-title">
                                <h4>Issue of tasks being deactivated, can't be retrieved or paused.</h4>
                            </div>
                            <div class="accrodion-content">
                                <div class="inner">
                                    <div data-v-564697ec="" class="mt-6 border-l-2 border-gray-50pl-0">
                                        <p data-v-564697ec=""> This happens when the task has gotten the required number just before you finished performing it. However, if this happens too frequently please contact any of the admins. </p>
                                        <p data-v-564697ec=""></p>
                                        <p data-v-564697ec=""></p>
                                    </div>
                                </div><!-- /.inner -->
                            </div>
                        </div><!-- /.accrodion-inner -->
                    </div>

                    <div class="accrodion ">
                        <div class="accrodion-inner">
                            <div class="accrodion-title">
                                <h4>how much one can make a day or how many tasks can one perform a day.</h4>
                            </div>
                            <div class="accrodion-content">
                                <div class="inner">
                                    <div data-v-564697ec="" class="mt-6 border-l-2 border-gray-50pl-0">
                                        <p data-v-564697ec=""> With Vitisocial there is no limit to how much you can make on the site daily. All you have to do is ensure all your social media accounts are connected correctly to the site. You also earn more money when you engage in referrals which gives you 25% or 40% depending on your registered package. And also stay active on the site to perform more tasks. </p>
                                        <p data-v-564697ec=""></p>
                                        <p data-v-564697ec=""></p>
                                    </div>
                                </div><!-- /.inner -->
                            </div>
                        </div><!-- /.accrodion-inner -->
                    </div>
                    <div class="accrodion ">
                        <div class="accrodion-inner">
                            <div class="accrodion-title">
                                <h4>Why is my account Banned?</h4>
                            </div>
                            <div class="accrodion-content">
                                <div class="inner">
                                    <div data-v-564697ec="" class="mt-6 border-l-2 border-gray-50pl-0">
                                        <p data-v-564697ec=""> User account can get banned when a user get involved in some fraudulent activities like submitting success for a task or tasks he or she didn’t or couldn’t do. Or owning multiple accounts as to defraud the system and cheat our advertisers. </p>
                                        <p data-v-564697ec="">If the case is for multiple accounts, all accounts will be banned except for one. If ban is due to other irregularities, we will review such account and then decide the next step.</p>
                                        <p data-v-564697ec=""></p>
                                    </div>
                                </div><!-- /.inner -->
                            </div>
                        </div><!-- /.accrodion-inner -->
                    </div>

                    <div class="accrodion ">
                        <div class="accrodion-inner">
                            <div class="accrodion-title">
                                <h4>Can I lose Money on Vitisocial?</h4>
                            </div>
                            <div class="accrodion-content">
                                <div class="inner">
                                    <div data-v-564697ec="" class="mt-6 border-l-2 border-gray-50pl-0">
                                        <p data-v-564697ec=""> Vitisocial.com is 100% safe and we are not an investment company therefore you can not lose what you never invested with us. We render services to advertisers and we pay you the promoter to promote our services. We only charge you a one time registration fee to become a member. That simple. </p>
                                        <p data-v-564697ec=""></p>
                                        <p data-v-564697ec=""></p>
                                    </div>
                                </div><!-- /.inner -->
                            </div>
                        </div><!-- /.accrodion-inner -->
                    </div>

                    <div class="accrodion ">
                        <div class="accrodion-inner">
                            <div class="accrodion-title">
                                <h4>How to play *fun play and win*</h4>
                            </div>
                            <div class="accrodion-content">
                                <div class="inner">
                                    <div data-v-564697ec="" class="mt-6 border-l-2 border-gray-50pl-0">
                                        <p data-v-564697ec=""> Do you know you can win up to 200k by playing 'fun play and win'? </p>
                                        <p data-v-564697ec="">How?</p>
                                        <p data-v-564697ec="">Simply fund your fun play wallet, choose a number, and play. Note you can choose multiple numbers and when all the numbers are taken, the winner is automatically announced. No one has any access to the winning numbers as they are computer generated and therefore cannot be rigged. ></p>

                                    </div>
                                </div><!-- /.inner -->
                            </div>
                        </div><!-- /.accrodion-inner -->
                    </div>

                    <div class="accrodion ">
                        <div class="accrodion-inner">
                            <div class="accrodion-title">
                                <h4>Differences between basic and premium plans</h4>
                            </div>
                            <div class="accrodion-content">
                                <div class="inner">
                                    <div data-v-564697ec="" class="mt-6 border-l-2 border-gray-50pl-0">
                                        <p data-v-564697ec="">As you already know we have two plans on Vitisocial; the basic and premium plans. </p>
                                        <p data-v-564697ec="">The major difference between both plans is Referral Commission, basic members earn 25% while premium earns 40%. </p>
                                        <p data-v-564697ec="">Please note that the task's earnings are the same.</p>
                                    </div>
                                </div><!-- /.inner -->
                            </div>
                        </div><!-- /.accrodion-inner -->
                    </div>

                    <div class="accrodion ">
                        <div class="accrodion-inner">
                            <div class="accrodion-title">
                                <h4>Issue of No new tasks for you</h4>
                            </div>
                            <div class="accrodion-content">
                                <div class="inner">
                                    <div data-v-564697ec="" class="mt-6 border-l-2 border-gray-50pl-0">
                                        <p data-v-564697ec=""> To fix this, Kindly ensure all your social media accounts are connected correctly to your profile and also opened in a different tab in your browser, Also stay active on the site to get more tasks. </p>
                                        <p data-v-564697ec=""></p>
                                        <p data-v-564697ec=""></p>
                                    </div>
                                </div><!-- /.inner -->
                            </div>
                        </div><!-- /.accrodion-inner -->
                    </div>

                    <div class="accrodion ">
                        <div class="accrodion-inner">
                            <div class="accrodion-title">
                                <h4>How To Perform Instagram Tasks illustrated</h4>
                            </div>
                            <div class="accrodion-content">
                                <div class="inner">
                                    <div data-v-564697ec="" class="mt-6 border-l-2 border-gray-50pl-0">
                                        <p data-v-564697ec=""> Click view more 👉 Accept Task 👉 Click on the link to perform the task 👉Then come back to your dashboard 👉 Click done👉 input your Instagram username and email address 👉Then click Submit* </p>
                                        <p data-v-564697ec=""></p>
                                        <p data-v-564697ec=""></p>
                                    </div>
                                </div><!-- /.inner -->
                            </div>
                        </div><!-- /.accrodion-inner -->
                    </div>

                    <div class="accrodion ">
                        <div class="accrodion-inner">
                            <div class="accrodion-title">
                                <h4>How To Perform Other Social Media Tasks illustrated</h4>
                            </div>
                            <div class="accrodion-content">
                                <div class="inner">
                                    <div data-v-564697ec="" class="mt-6 border-l-2 border-gray-50pl-0">
                                        <p data-v-564697ec=""> *Click Accept Task 👉 Click Perform Task 👉 Perform The Task 👉Then come back to your dashboard 👉 Wait For About 50 Seconds To Be Credited* </p>
                                        <p data-v-564697ec=""></p>
                                        <p data-v-564697ec=""></p>
                                    </div>
                                </div><!-- /.inner -->
                            </div>
                        </div><!-- /.accrodion-inner -->
                    </div>

                    <div class="accrodion ">
                        <div class="accrodion-inner">
                            <div class="accrodion-title">
                                <h4>Not receiving verification mail</h4>
                            </div>
                            <div class="accrodion-content">
                                <div class="inner">
                                    <div data-v-564697ec="" class="mt-6 border-l-2 border-gray-50pl-0">
                                        <p data-v-564697ec=""> If you requested verification mail and didn’t get it in your mailbox, please kindly check through your spam inbox or contact any of the Admin for further assistance. Meanwhile you can click on this link and input the email address again; <a data-v-564697ec="" href="https://www.vitisocial.com/request-activate-account">www.vitisocial.com/request-activate-account</a></p>
                                        <p data-v-564697ec=""></p>
                                        <p data-v-564697ec=""></p>
                                    </div>
                                </div><!-- /.inner -->
                            </div>
                        </div><!-- /.accrodion-inner -->
                    </div>

                    <div class="accrodion ">
                        <div class="accrodion-inner">
                            <div class="accrodion-title">
                                <h4>I have requested withdrawals but have yet to receive it</h4>
                            </div>
                            <div class="accrodion-content">
                                <div class="inner">
                                    <div data-v-564697ec="" class="mt-6 border-l-2 border-gray-50pl-0">
                                        <p data-v-564697ec=""> Withdrawals are processed within 24hrs and sometimes might exceed if we have more requests to attend to at that time. Please note that you can only withdraw 45 days after the first withdrawal. This helps you to earn and save more money for withdrawals. This also avail us the time to minimize user abuse and fraudulent activities. </p>
                                        <p data-v-564697ec=""></p>
                                        <p data-v-564697ec=""></p>
                                    </div>
                                </div><!-- /.inner -->
                            </div>
                        </div><!-- /.accrodion-inner -->
                    </div>
                </div>

                <div class="show-all-btn text-center my-3">
                    <button class="toggle">Show All</button>
                </div>
            </div>
        </div><!-- /.container -->
    </section><!-- /.faq-one -->

    <section class="sections section_contact contact-one" id="contact">

        <div class="container" style="display: block">
            <div class="block-title text-center">
                <span class="block-title__bubbles"></span>
                <p>Contact Us Now</p>
                <h3>Write a Message</h3>
            </div><!-- /.block-title -->
            <div class="row">
                <div class="col-lg-6">
                    <div class="contact-one__infos-single">
                        <div class="contact-one__infos-icon">
                            <i class="zimed-icon-message"></i>
                        </div><!-- /.contact-one__infos-icon -->
                        <div class="contact-one__infos-content">
                            <h3>Admin Email</h3>
                            <p><a href="mailto:admin@vitisocial.com">admin@vitisocial.com</a></p>
                        </div><!-- /.contact-one__infos-content -->
                    </div><!-- /.contact-one__infos-single -->

                </div>

                <div class="col-lg-6">
                    <div class="contact-one__infos-single">
                        <div class="contact-one__infos-icon">
                            <i class="zimed-icon-message"></i>
                        </div><!-- /.contact-one__infos-icon -->
                        <div class="contact-one__infos-content">
                            <h3>Support Email</h3>
                            <p><a href="mailto:support@vitisocial.com">support@vitisocial.com</a></p>
                        </div><!-- /.contact-one__infos-content -->
                    </div><!-- /.contact-one__infos-single -->

                </div>

                <div class="d-none" oldclass="col-lg-4">
                    <div class="contact-one__infos-single">
                        <div class="contact-one__infos-icon">
                            <i class="zimed-icon-phone-call"></i>
                        </div><!-- /.contact-one__infos-icon -->
                        <div class="contact-one__infos-content">
                            <h3>Phone</h3>
                            <p><a href="tel:+16144055997">+16144055997</a></p>
                        </div><!-- /.contact-one__infos-content -->
                    </div><!-- /.contact-one__infos-single -->

                </div>

            </div><!-- /.row -->

            <div class="whatsapp-btn text-center mt-5">
                <a href="https://api.whatsapp.com/send?phone=+16144055997" class="thm-btn contact-one__form-btn">Chat on Whatsapp</a>

            </div>

        </div><!-- /.container -->
    </section><!-- /.contact-one -->

    <footer class="sections section_footer site-footer">

        <div class="site-footer__upper">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="footer-widget footer-widget__about text-center">
                            <a class="footer-widget__logo" href="/"><img src="/assets/img/vt_social_logo.png" width="220" alt="Awesome Image" /></a>

                        </div><!-- /.footer-widget -->
                    </div><!-- /.col-lg-4 -->
                    <div class="col-lg-12">
                        <div class="footer-widget footer-widget__links">

                            <div class="footer-widget__links-wrap">
                                <ul class="list-unstyled">
                                    <li class="current scrollToLink">
                                        <a href="#banner">Home</a>
                                    </li>
                                    <li class="scrollToLink">
                                        <a href="#features">About Us</a>
                                    </li>
                                    <li class="scrollToLink">
                                        <a href="#faq">Faqs</a>

                                    </li>
                                    <li class="">
                                        <a href="/dashboard/forum">Forums</a>
                                    </li>

                                    <li class="">
                                        <a href="/dashboard">Feeds</a>
                                    </li>

                                    <li class="scrollToLink">
                                        <a href="#contact">Contact</a>
                                    </li>
                                </ul><!-- /.list-unstyled -->

                            </div><!-- /.footer-widget__links-wrap -->
                        </div><!-- /.footer-widget footer-widget__links -->
                    </div><!-- /.col-lg-4 -->

                </div><!-- /.row -->
            </div><!-- /.container -->
        </div><!-- /.site-footer__upper -->
        <div class="site-footer__bottom">
            <div class="container">
                <div class="inner-container">
                    <div class="site-footer__social">
                        <a class="fab fa-facebook-f" href="#"></a>
                        <a class="fab fa-twitter" href="#"></a>
                        <a class="fab fa-instagram" href="#"></a>
                        <a class="fab fa-pinterest-p" href="#"></a>
                    </div><!-- /.site-footer__social -->
                    <p>© 2018 - {{ currentYear }} Vitisocial. All rights reserved.</p>
                </div><!-- /.inner-container -->
            </div><!-- /.container -->
        </div><!-- /.site-footer__bottom -->
    </footer><!-- /.site-footer -->

    <a href="#" data-target="html" class="scroll-to-target scroll-to-top"><i class="fa fa-angle-up"></i></a>

    <div class="sections section_dd side-menu__block">

        <div class="side-menu__block-overlay custom-cursor__overlay">
            <div class="cursor"></div>
            <div class="cursor-follower"></div>
        </div><!-- /.side-menu__block-overlay -->
        <div class="side-menu__block-inner ">
            <div class="side-menu__top justify-content-end">

                <a href="#" class="side-menu__toggler side-menu__close-btn"><img src="/home/images/shapes/close-1-1.png" alt=""></a>
            </div><!-- /.side-menu__top -->

            <nav class="mobile-nav__container">
                <!-- content is loading via js -->
            </nav>
            <div class="side-menu__sep"></div><!-- /.side-menu__sep -->
            <div class="side-menu__content">

                <div class="side-menu__social">
                    <a class="fab fa-facebook-f" href="#"></a>
                    <a class="fab fa-twitter" href="#"></a>
                    <a class="fab fa-instagram" href="#"></a>
                    <a class="fab fa-pinterest-p" href="#"></a>
                </div>
            </div><!-- /.side-menu__content -->
        </div><!-- /.side-menu__block-inner -->
    </div><!-- /.side-menu__block -->
    
    <div class="section_mobile_menu">
        <div class="flex">
            <p class="mm_option mouse-pointer mm_option_home mm_active" @click="mobilePageSwitch('home')">
                Home
            </p>
            <p class="mm_option mouse-pointer mm_option_features" @click="mobilePageSwitch('about')">
                About
            </p>
            <p class="mm_option mouse-pointer mm_option_wcu" @click="mobilePageSwitch('why_choose_us')">
                Why Us
            </p>
            <p class="mm_option mouse-pointer mm_option_faq" @click="mobilePageSwitch('faq')">
                Faq
            </p>
            <p class="mm_option mouse-pointer mm_option_contact" @click="mobilePageSwitch('contact')">
                Contact
            </p>
        </div>
    </div>

</div>
</template>

<script>
export default {
    name: 'WelcomePage',
    components: {},
    data() {
        return {
            currentYear: new Date().getFullYear(),
            faqData: [{
                'question': 'How do I register either as an advertiser or a promoter?',
                'answer': ""
            }]
        }
    },
    head: {
        style: [{
                type: 'text/css',
                inner: "",
                undo: true
            },
            {
                type: 'text/css',
                inner: '',
                undo: true
            },
        ],
        script: [{
                type: 'text/javascript',
                src: '/home/js/jquery.min.js',
                async: false,
                body: true
            },
            {
                type: 'text/javascript',
                src: '/home/js/bootstrap.bundle.min.js',
                async: false,
                body: true
            },
            {
                type: 'text/javascript',
                src: '/home/js/TweenMax.min.js',
                async: false,
                body: true
            },
            {
                type: 'text/javascript',
                src: '/home/js/jquery.mCustomScrollbar.concat.min.js',
                async: false,
                body: true
            },
            {
                type: 'text/javascript',
                src: '/home/js/bootstrap-datepicker.min.js',
                async: false,
                body: true
            },
            {
                type: 'text/javascript',
                src: '/home/js/jquery.easing.min.js',
                async: false,
                body: true
            },
            {
                type: 'text/javascript',
                src: '/home/js/bootstrap-select.min.js',
                async: false,
                body: true
            },
            {
                type: 'text/javascript',
                src: '/home/js/jquery.validate.min.js',
                async: false,
                body: true
            },
            {
                type: 'text/javascript',
                src: '/home/js/waypoints.min.js',
                async: false,
                body: true
            },
            {
                type: 'text/javascript',
                src: '/home/js/wow.js',
                async: false,
                body: true
            },
            //   { type: 'text/javascript', src: '/home/js/jquery.counterup.min.js', async: false, body: true},
            {
                type: 'text/javascript',
                src: '/home/js/owl.carousel.min.js',
                async: false,
                body: true
            },
            {
                type: 'text/javascript',
                src: '/home/js/jquery.bxslider.min.js',
                async: false,
                body: true
            },
            {
                type: 'text/javascript',
                src: '/home/js/jquery.magnific-popup.min.js',
                async: false,
                body: true
            },
            {
                type: 'text/javascript',
                src: '/home/js/jquery.ajaxchimp.min.js',
                async: false,
                body: true
            },
            {
                type: 'text/javascript',
                src: '/home/js/theme.js',
                async: false,
                body: true
            },
        ],
    },
    beforeCreate: function () {
        $('link[title="styleMain"]').remove();
    },
    mounted: function () {
        this.loadPage()
        this.loadCss()
        setTimeout(() => {
            $("#contactMenu").click(function () {
                $("#contact > div").show()
            })
            $("#faqMenu").click(function () {
                $("#faq > div").show()
            })
        }, 5000);
        let accessToken = this.$store.getters['auth/accessToken']
        if(accessToken){
        location.href = '/dashboard/feeds'
        }
    },
    methods: {
        loadPage() {
            $('.Show').click(function () {
                $('#target').show(500);
                $('.Show').hide(0);
                $('.Hide').show(0);
            });
            $('.Hide').click(function () {
                $('#target').hide(500);
                $('.Show').show(0);
                $('.Hide').hide(0);
            });
            $('.toggle').click(function () {
                $('#target').toggle('slow');
            });
        },
        loadCss() {
            // console.log('----------------')
            let a = [
                '/public/home/css/bootstrap.min.css',
                '/public/home/css/animate.min.css',
                '/public/home/css/fontawesome-all.min.css',
                '/public/home/css/bootstrap-datepicker.min.css',
                '/public/home/css/bootstrap-select.min.css',
                '/public/home/css/jquery.mCustomScrollbar.min.css',
                '/public/home/css/owl.carousel.min.css',
                '/public/home/css/owl.theme.default.min.css',
                '/public/home/css/zimed-icon.css',
                '/public/home/css/jquery.bxslider.css',
                '/public/home/css/magnific-popup.css',
                '/public/home/css/style.css',
                '/public/home/css/responsive.css',
            ];
            for (let i = 0; i < a.length; i++) {
                const cssFile = a[i];
                let style = document.createElement('link');
                style.type = "text/css";
                style.rel = "stylesheet";
                style.href = cssFile;
                // console.log('----------------', cssFile, style)
                // document.head.appendChild(style);
            }
        },
        mobilePageSwitch(a){
            $(".sections").hide()
            $(".section_banner").hide()
            $(".mm_option").removeClass('mm_active')
            switch (a) {
                case 'home':
                    $(".section_banner").show()
                    $(".mm_option_home").addClass('mm_active')
                    break;
            
                case 'about':
                    $(".section_features").show()
                    $(".mm_option_features").addClass('mm_active')
                    break;
            
                case 'why_choose_us':
                    $(".section_why_choose").show()
                    $(".mm_option_wcu").addClass('mm_active')
                    break;
            
                case 'faq':
                    $(".section_faq").show()
                    $(".mm_option_faq").addClass('mm_active')
                    break;
            
                case 'contact':
                    $(".section_contact").show()
                    $(".mm_option_contact").addClass('mm_active')
                    break;
            
                default:
                    break;
            }
            console.log('====', a)
        }
    }
}
</script>

<style lang="scss" scoped>


.mobile_logo {
    height: 50px;
    width: auto;
}

@import '/public/home/css/bootstrap.min.css';
@import '/public/home/css/animate.min.css';
@import '/public/home/css/fontawesome-all.min.css';
@import '/public/home/css/bootstrap-datepicker.min.css';
@import '/public/home/css/bootstrap-select.min.css';
@import '/public/home/css/jquery.mCustomScrollbar.min.css';
@import '/public/home/css/owl.carousel.min.css';
@import '/public/home/css/owl.theme.default.min.css';
@import '/public/home/css/zimed-icon.css';
@import '/public/home/css/jquery.bxslider.min.css';
@import '/public/home/css/magnific-popup.css';
/*--------------------------------------------------------------
# Common
--------------------------------------------------------------*/

/*font-family: 'Barlow', sans-serif;*/

@import url('https://fonts.googleapis.com/css?family=Poppins&amp;display=swap');

:root {
    --thm-font: 'Barlow', sans-serif;
    --thm-base: #ee464b;
    --thm-base-rgb: 238, 70, 75;
    --thm-black: #272839;
    --thm-black-rgb: 39, 40, 57;
    --thm-gray: #f4f4f8;
}

body {
    font-family: var(--thm-font);
    color: #7c7d8a;
    font-size: 18px;
    line-height: 34px;
    font-weight: 500;
}

#target {
    display: none;
}

a:active,
a:hover,
a:focus,
a:visited {
    text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: var(--thm-font);
}

.page-wrapper {
    position: relative;
    margin: 0 auto;
    width: 100%;
    min-width: 300px;
    overflow: hidden;
}

@media (min-width: 1200px) {
    .container {
        max-width: 1200px;
    }
}

.block-title {
    margin-bottom: 50px;
}

.block-title__bubbles {
    position: relative;
    display: block;
    width: 19px;
    height: 27px;
    padding-bottom: 8px;
    margin-bottom: 0px;
}

.text-center .block-title__bubbles {
    margin-left: auto;
    margin-right: auto;
}

.block-title__bubbles::after,
.block-title__bubbles::before {
    content: '';
    width: 19px;
    height: 19px;
    border-radius: 50%;
    opacity: 0.4;
    display: block;
}

.block-title__bubbles::after {
    background-color: #dd8201;
}

.block-title__bubbles::before {
    background-color: #127efe;
}

.block-title__bubbles::after {
    position: absolute;
    bottom: 0;
    left: calc(50% - 9.5px);
}

.block-title p {
    line-height: 31px;
    color: #e08301;
    font-weight: 500;
    font-size: 25px;
    font-family: poppins;
    max-width: 700px;
    margin: 10px auto 0;
}

.block-title h3 {
    margin: 0;
    color: #003b65;
    font-weight: 700;
    font-size: 42px;
    font-family: 'Poppins', sans-serif;
}

.thm-btn {
    display: inline-block;
    vertical-align: middle;
    border: none;
    outline: none;
    background-image: linear-gradient(90deg, #e41256 0%, #ffa337 51%, #e41256 100%);
    text-align: center;
    background-size: 200% auto;
    border-radius: 5px;
    color: #fff;
    padding: 18px 50px;
    font-size: 18px;
    font-weight: 600;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
}

.thm-btn:hover {
    color: #fff;
    background-position: right center;
}

.bootstrap-select .btn-light:not(:disabled):not(.disabled).active,
.bootstrap-select .btn-light:not(:disabled):not(.disabled):active,
.bootstrap-select .show>.btn-light.dropdown-toggle {
    background-color: transparent !important;
    box-shadow: none !important;
    outline: none !important;
}

.bootstrap-select>.dropdown-toggle {
    box-shadow: none !important;
}

.bootstrap-select .dropdown-toggle:focus,
.bootstrap-select>select.mobile-device:focus+.dropdown-toggle {
    outline: none !important;
}

.bootstrap-select .dropdown-menu {
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 0;
    z-index: 991;
    border-radius: 0;
}

.bootstrap-select .dropdown-menu>li+li>a {
    border-top: 1px solid var(--thm-gray);
}

.bootstrap-select .dropdown-menu>li.selected>a {
    background: var(--thm-base);
    color: #fff;
}

.bootstrap-select .dropdown-menu>li>a {
    font-size: 16px;
    font-weight: 500;
    padding: 4px 20px;
    color: var(--thm-black);
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
}

.bootstrap-select .dropdown-menu>li>a:hover {
    background: var(--thm-base);
    color: #fff;
    cursor: pointer;
}

/* datepicker */

.datepicker.dropdown-menu {
    min-width: 17rem;
}

.datepicker table {
    width: 100%;
}

.post-pagination {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    flex-wrap: wrap;
}

.post-pagination a {
    border-radius: 50%;
    background-color: #fff;
    color: var(--thm-black);
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    padding: 0;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    width: 57px;
    height: 57px;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
}

.side-menu__toggler {
    display: none;
}

.mobile-nav__container {
    background-color: #fff;
    margin-top: 50px;
    padding: 30px 20px;
}

@media(max-width: 480px) {
    .post-pagination a {
        width: 40px;
        height: 40px;
        font-size: 16px;
    }

    .side-menu__toggler {
        display: block;
    }

    .mobile_bg {
        background: #1077F7;
        margin-bottom: 10px;
    }
}

.post-pagination a:hover {
    background-color: var(--thm-black);
    color: #fff;
}

.post-pagination a.active {
    background-color: var(--thm-base);
    color: #fff;
    cursor: auto;
}

.post-pagination a+a {
    margin-left: 15px;
}

.scroll-to-top {
    display: inline-block;
    width: 45px;
    height: 45px;
    background: #df8300;
    position: fixed;
    bottom: 40px;
    right: 40px;
    z-index: 99;
    text-align: center;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
    display: none;
    border-radius: 50%;
    transition: all .4s ease;
}

.scroll-to-top i {
    color: #ffffff;
    font-size: 18px;
    line-height: 45px;
}

.scroll-to-top:hover {
    background-color: var(--thm-black);
}

.scroll-to-top:hover i {
    color: #fff;
}

.thm-base-bg {
    background-color: var(--thm-base);
}

.thm-base-bg-2 {
    background-color: var(--thm-base);
}

.thm-gray-bg {
    background-color: var(--thm-gray);
}

/* Cursor Style */

.cursor {
    position: absolute;
    background-color: #fff;
    width: 6px;
    height: 6px;
    border-radius: 100%;
    z-index: 1;
    -webkit-transition: 0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity, 0.3s cubic-bezier(0.75, -1.27, 0.3, 2.33) -webkit-transform;
    transition: 0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity, 0.3s cubic-bezier(0.75, -1.27, 0.3, 2.33) -webkit-transform;
    transition: 0.3s cubic-bezier(0.75, -1.27, 0.3, 2.33) transform, 0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity;
    transition: 0.3s cubic-bezier(0.75, -1.27, 0.3, 2.33) transform, 0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity, 0.3s cubic-bezier(0.75, -1.27, 0.3, 2.33) -webkit-transform;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: none;
    z-index: 10000;
    -webkit-transform: scale(1);
    transform: scale(1);
    visibility: hidden;
}

.cursor {
    visibility: visible;
}

.cursor.active {
    opacity: 0.5;
    -webkit-transform: scale(0);
    transform: scale(0);
}

.cursor.hovered {
    opacity: 0.08;
}

.cursor-follower {
    position: absolute;
    background-color: RGBA(255, 255, 255, 0.3);
    width: 50px;
    height: 50px;
    border-radius: 100%;
    z-index: 1;
    -webkit-transition: 0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity, 0.6s cubic-bezier(0.75, -1.27, 0.3, 2.33) -webkit-transform;
    transition: 0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity, 0.6s cubic-bezier(0.75, -1.27, 0.3, 2.33) -webkit-transform;
    transition: 0.6s cubic-bezier(0.75, -1.27, 0.3, 2.33) transform, 0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity;
    transition: 0.6s cubic-bezier(0.75, -1.27, 0.3, 2.33) transform, 0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity, 0.6s cubic-bezier(0.75, -1.27, 0.3, 2.33) -webkit-transform;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    pointer-events: none;
    z-index: 10000;
    visibility: hidden;
}

.cursor-follower {
    visibility: visible;
}

.cursor-follower.active {
    opacity: 0.7;
    -webkit-transform: scale(1);
    transform: scale(1);
}

.cursor-follower.hovered {
    opacity: 0.08;
}

.cursor-follower.close-cursor:before {
    position: absolute;
    content: '';
    height: 25px;
    width: 2px;
    background: #fff;
    left: 48%;
    top: 12px;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    display: inline-block;
}

.cursor-follower.close-cursor:after {
    position: absolute;
    content: '';
    height: 25px;
    width: 2px;
    background: #fff;
    right: 48%;
    top: 12px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

/*preloader*/

.preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    background-color: #fff;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
}

.lds-ripple {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds-ripple div {
    position: absolute;
    border: 4px solid #e41256;
    opacity: 1;
    border-radius: 50%;
    -webkit-animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
    -webkit-animation-delay: -0.5s;
    animation-delay: -0.5s;
}

@-webkit-keyframes lds-ripple {
    0% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 1;
    }

    100% {
        top: 0px;
        left: 0px;
        width: 72px;
        height: 72px;
        opacity: 0;
    }
}

@keyframes lds-ripple {
    0% {
        top: 36px;
        left: 36px;
        width: 0;
        height: 0;
        opacity: 1;
    }

    100% {
        top: 0px;
        left: 0px;
        width: 72px;
        height: 72px;
        opacity: 0;
    }
}

/*--------------------------------------------------------------
# Animations
--------------------------------------------------------------*/

@keyframes ImgBounce {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    100% {
        -webkit-transform: translateY(-30px);
        transform: translateY(-30px);
    }
}

@keyframes shapeMover {

    0%,
    100% {
        transform: perspective(400px) translateY(0) rotate(0deg) translateZ(0px) translateX(0);
    }

    50% {
        transform: perspective(400px) rotate(-45deg) translateZ(20px) translateY(20px) translateX(20px);
    }
}

@keyframes bubbleMover {
    0% {
        -webkit-transform: translateY(0px) translateX(0) rotate(0);
        transform: translateY(0px) translateX(0) rotate(0);
    }

    30% {
        -webkit-transform: translateY(30px) translateX(50px) rotate(15deg);
        transform: translateY(30px) translateX(50px) rotate(15deg);
        -webkit-transform-origin: center center;
        transform-origin: center center;
    }

    50% {
        -webkit-transform: translateY(50px) translateX(100px) rotate(45deg);
        transform: translateY(50px) translateX(100px) rotate(45deg);
        -webkit-transform-origin: right bottom;
        transform-origin: right bottom;
    }

    80% {
        -webkit-transform: translateY(30px) translateX(50px) rotate(15deg);
        transform: translateY(30px) translateX(50px) rotate(15deg);
        -webkit-transform-origin: left top;
        transform-origin: left top;
    }

    100% {
        -webkit-transform: translateY(0px) translateX(0) rotate(0);
        transform: translateY(0px) translateX(0) rotate(0);
        -webkit-transform-origin: center center;
        transform-origin: center center;
    }
}

@keyframes banner3Shake {
    0% {
        -webkit-transform: rotate3d(0, 1, 0, 0deg);
        transform: rotate3d(0, 1, 0, 0deg);
    }

    30% {
        -webkit-transform: rotate3d(0, 0, 1, 5deg);
        transform: rotate3d(0, 0, 1, 5deg);
    }

    60% {
        -webkit-transform: rotate3d(1, 0, 0, 0deg);
        transform: rotate3d(1, 0, 0, 0deg);
    }

    80% {
        -webkit-transform: rotate3d(0, 0, 1, 5deg);
        transform: rotate3d(0, 0, 1, 5deg);
    }

    100% {
        -webkit-transform: rotate3d(0, 1, 0, 0deg);
        transform: rotate3d(0, 1, 0, 0deg);
    }
}

/*--------------------------------------------------------------
# Main Menu
--------------------------------------------------------------*/

.main-nav__header-one {
    width: 100%;
    border-bottom: 1px solid rgba(255, 255, 255, .08);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 91;
}

.main-nav__header-one .container {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.main-nav__logo-box .side-menu__toggler {
    font-size: 30px;
    color: #e08401;
}

.main-nav__main-navigation .main-nav__navigation-box {
    margin: 0;
    padding: 0;
    list-style: none;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}

.main-nav__main-navigation .main-nav__navigation-box,
.main-nav__main-navigation .main-nav__navigation-box ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.main-nav__main-navigation .main-nav__navigation-box>li+li {
    margin-left: 45px;
}

.main-nav__main-navigation .main-nav__navigation-box>li {
    position: relative;
    padding: 20px 0px;
}

.main-nav__main-navigation .main-nav__navigation-box>li>a {
    color: #1177fb;
    font-size: 16px;
    font-weight: 600;
    font-family: poppins;
    position: relative;
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
}

.main-nav__main-navigation .main-nav__navigation-box>li>a::before {
    content: '';
    width: 100%;
    height: 2px;
    background-color: #1176f6;
    position: absolute;
    top: calc(100% + 3px);
    left: 0;
    transform: scale(0, 1);
    transform-origin: right center;
    transition: transform 500ms ease;
}

.main-nav__main-navigation .main-nav__navigation-box>li:hover>a,
.main-nav__main-navigation .main-nav__navigation-box>li.current>a,
.main-nav__main-navigation .main-nav__navigation-box>li.current-menu-item>a {
    color: #e08301;
}

.main-nav__main-navigation .main-nav__navigation-box>li:hover>a::before,
.main-nav__main-navigation .main-nav__navigation-box>li.current>a::before,
.main-nav__main-navigation .main-nav__navigation-box>li.current-menu-item>a::before {
    transform: scale(1, 1);
    transform-origin: left center;
}

.main-nav__main-navigation .main-nav__navigation-box>li.current>a::before,
.main-nav__main-navigation .main-nav__navigation-box>li:hover>a::before {
    background: #e08301
}

/* Dropdown menu */

.main-nav__main-navigation .dropdown-btn {
    display: none;
}

.main-nav__main-navigation .main-nav__navigation-box>li ul {
    position: absolute;
    width: 230px;
    background-color: #fff;
    border-top: 2px solid var(--thm-black);
    box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05), -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
    -webkit-transform-origin: top;
    transform-origin: top;
    visibility: hidden;
    opacity: 0;
    z-index: 991;
    -webkit-transition: opacity 500ms ease, visibility 500ms ease, -webkit-transform 500ms ease;
    transition: opacity 500ms ease, visibility 500ms ease, -webkit-transform 500ms ease;
    transition: transform 500ms ease, opacity 500ms ease, visibility 500ms ease;
    transition: transform 500ms ease, opacity 500ms ease, visibility 500ms ease, -webkit-transform 500ms ease;
}

.main-nav__main-navigation .main-nav__navigation-box>li ul>li {
    position: relative;
}

.main-nav__main-navigation .main-nav__navigation-box>li ul>li+li {
    border-top: 1px solid rgba(var(--thm-black-rgb), .3);
}

.main-nav__main-navigation .main-nav__navigation-box>li ul>li>a {
    display: block;
    color: var(--thm-black);
    font-size: 14px;
    font-family: var(--thm-font);
    word-break: break-all;
    padding-top: 9.5px;
    padding-bottom: 9.5px;
    padding-left: 20px;
    padding-right: 20px;
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
}

.main-nav__main-navigation .main-nav__navigation-box>li ul>li:hover>a {
    color: #fff;
    background-color: var(--thm-black);
}

/* Second level menu */

.main-nav__main-navigation .main-nav__navigation-box>li>ul {
    top: 100%;
    left: 0;
    -webkit-transform: translateY(30px);
    transform: translateY(30px);
    visibility: hidden;
    opacity: 0;
}

.main-nav__main-navigation .main-nav__navigation-box>li:hover>ul {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    visibility: visible;
    opacity: 1;
}

/* Third level menu */

.main-nav__main-navigation .main-nav__navigation-box>li>ul>li>ul {
    top: 0;
    left: 100%;
    -webkit-transform: translateX(30px);
    transform: translateX(30px);
    visibility: hidden;
    opacity: 0;
}

.main-nav__main-navigation .main-nav__navigation-box>li>ul>li:hover>ul {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
    visibility: visible;
    opacity: 1;
}

/* After Third level menu */

.main-nav__main-navigation .main-nav__navigation-box>li>ul>li>ul>li ul {
    display: none;
}

.header__btn {
    background-image: none;
    border: 2px solid #fff;
    padding: 7px 30px;
}

.btn-header-login:hover {
    background: #fff;
    border-color: #e08401;
    color: #e08401 !important;
}

.btn-header-login {
    background: #e08401;
    border-radius: 15px;
}

.btn-header-sign {
    background: #1177f6;
    border-radius: 15px;
}

.btn-header-sign:hover {
    background: #fff;
    border-color: #1177f6;
    color: #1177f6 !important;
}

.header__btn:hover {
    background-color: #fff;
    color: var(--thm-black);
}

.stricked-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    background-color: #0f2642;
    box-shadow: 0px 6px 13px 0px rgba(0, 0, 0, 0.06);
    opacity: 0;
    -webkit-transform: translateY(-110%);
    transform: translateY(-110%);
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
}

.stricked-menu.stricky-fixed {
    opacity: 1;
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
}

.stricked-menu .main-nav__navigation-box>li {
    padding-top: 25px;
    padding-bottom: 25px;
}

/*--------------------------------------------------------------
# Banner
--------------------------------------------------------------*/

.banner-one {
    margin-top: 76px;
    position: relative;
    text-align: left;
    background-image: url('/public/home/images/shapes/background.png');
    color: white;
    z-index: 10;
    background-position: center -400px;
    background-repeat: no-repeat;
    background-size: cover;
    height: 800px;
}

@media(max-width: 991px) {
    .banner-one::before {
        height: 80px;
    }
}

.banner-one .container {
    position: relative;
    padding-top: 50px;
    padding-bottom: 50px;
}

.banner-one__moc {
    position: absolute;
    top: 0;
    right: -65px;
    /* animation: ImgBounce 5s ease-in-out 0s infinite alternate; */
}

.banner-one__content h3 {
    margin: 0;
    font-size: 50px;
    text-transform: capitalize;
    line-height: normal;
    font-weight: 700;
    color: #fff;
    margin-top: 60px;
    margin-bottom: 25px;
    font-family: poppins;
}

.banner-one__content p {
    margin: 0;
    font-size: 28px;
    line-height: 43px;
    font-weight: 300;
    color: #bfd9fa;
    font-family: 'Poppins', sans-serif;
}

a.btn-login {
    padding: 10px 35px;
    line-height: normal;
    background: #e18400;
    border-radius: 50px;
    font-family: poppins;
    font-size: 17px;
    font-weight: 500;
    margin-top: 35px;
    border: 2px solid #fff;
    margin-right: 20px;
}

a.btn-login:hover {
    background: #1075f4;
    transform: scale(0.9);
}

a.btn-signin {
    padding: 10px 35px;
    line-height: normal;
    background: #fff;
    border-radius: 50px;
    font-family: poppins;
    font-size: 17px;
    font-weight: 500;
    margin-top: 35px;
    border: 2px solid #e18400;
    margin-right: 20px;
    color: #1175f2;
}

a.btn-signin:hover {
    transform: scale(0.9);
    background: #1175f2;
}

.banner-one__btn {
    padding: 16px 56px;
    background-image: none;
    border: 2px solid #fff;
    margin-top: 40px;
}

.banner-one__btn:hover {
    background-color: #fff;
    color: var(--thm-black);
}

[class*=banner-one__shape-] {
    position: absolute;
}

.banner-one__shape-moc-1 {
    top: 7%;
    left: -11%;
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-name: bounce;
    animation-name: bounce;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    -webkit-animation-duration: 5s;
    animation-duration: 5s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
}

.banner-one__shape-1 {
    top: 3%;
    left: 0;
}

.banner-one__shape-2 {
    top: 17%;
    left: 7%;
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-name: banner3Shake;
    animation-name: banner3Shake;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    -webkit-animation-duration: 5s;
    animation-duration: 5s;
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
}

.banner-one__shape-3 {
    bottom: 19%;
    left: 6%;
}

.banner-one__shape-4 {
    top: 19%;
    right: 45%;
    -webkit-animation-name: bubbleMover;
    animation-name: bubbleMover;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    animation-duration: 8s;
}

.banner-one__shape-5 {
    bottom: 19%;
    left: 6%;
    -webkit-animation-name: bubbleMover;
    animation-name: bubbleMover;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    animation-duration: 10s;
}

.banner-one__shape-6 {
    bottom: 29%;
    right: 30%;
    -webkit-animation-name: shapeMover;
    animation-name: shapeMover;
    -webkit-animation-duration: 9s;
    animation-duration: 9s;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
}

.banner-one__shape-7 {
    bottom: 19%;
    right: 2%;
    -webkit-animation-name: shapeMover;
    animation-name: shapeMover;
    -webkit-animation-duration: 9s;
    animation-duration: 9s;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    animation-delay: 2s;
    width: 150px;
}

/*--------------------------------------------------------------
# Service
--------------------------------------------------------------*/

.thm-btn.cta-two__btn {
    background: #1075f5;
    border-radius: 15px;
    padding: 12px 25px;
    line-height: normal;
}

.thm-btn.cta-two__btn:hover,
.show-all-btn .toggle:hover,
.whatsapp-btn .contact-one__form-btn:hover {
    background: #e08401;
    transform: scale(0.9)
}

.service-one {
    padding-bottom: 90px;
    padding-top: 30px;
}

.cta-two__content {
    background: #003b65;
    border-radius: 35px;
    padding: 40px;
    box-shadow: 0px 10px 20px #e083018a;
}

.cta-two__img {
    width: 500px;
    margin-left: auto;
}

.cta-two__service {
    animation: ImgBounce 10s ease-in-out 0s infinite alternate
}

.service-one .cta-two__content h3 {
    color: #fff;
    font-size: 40px;
    font-weight: 600;
    font-family: poppins;
    margin-top: 18px;
}

.service-one__col {
    padding-left: 15px;
    padding-right: 15px;
    flex: 1 1 20%;
    text-align: center;
}

.service-one__single {
    padding: 20px;
    /* padding-bottom: 0px; */
    border-style: solid;
    border-width: 1px;
    border-color: rgb(232, 232, 239);
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 10px 20px rgba(55, 55, 89, 0.08);
    border-radius: 25px;
    min-height: 315px;
}

.service-one__single h3 {
    color: #117dff;
    font-size: 25px;
    font-weight: 700;
    margin: 20px 0;
    font-family: poppins;
}

.service-one__single p {
    color: #003b65;
    font-size: 20px;
    font-weight: 400;
    margin: 0 10px 10px 10px;
    font-family: poppins;
    line-height: 30px;
}

.why-choose__list {
    /* border-top: 1px solid #e8e8ef; */
    padding: 0;
    text-align: left;
    margin-top: 40px;
}

.why-choose__list li i {
    margin: 0;
    width: auto;
    height: auto;
    display: inline-block;
    text-align: left !important;
    font-size: 16px;
    color: #1074f3;
    background-image: none;
    background: none !important;
    margin-right: 5px;
}

.why-choose__list li {
    font-family: poppins;
    font-size: 20px;
    color: #bfd9fa;
    padding-bottom: 10px;
}

.service-one__single i {
    width: 111px;
    height: 111px;
    border-radius: 50%;
    background-image: linear-gradient(90deg, #4a0e8f 0%, #b430a7 51%, #4a0e8f 100%);
    font-size: 64px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15px;
    background-size: 200% auto;
    transition: all 500ms ease;
}

.service-one__single:hover i {
    background-position: right center;
}

.service-one__col:nth-child(1) .service-one__single i {
    background-image: linear-gradient(90deg, #4a0e8f 0%, #b430a7 51%, #4a0e8f 100%);
}

.service-one__col:nth-child(2) .service-one__single i {
    background-image: linear-gradient(90deg, #4453ac 0%, #03a9db 51%, #4453ac 100%);
}

.service-one__col:nth-child(3) .service-one__single i {
    background-image: linear-gradient(90deg, #0349a6 0%, #15c27b 51%, #0349a6 100%);
}

.service-one__col:nth-child(4) .service-one__single i {
    background-image: linear-gradient(90deg, #e41256 0%, #ffa337 51%, #e41256 100%);
}

.service-one__col:nth-child(5) .service-one__single i {
    background-image: linear-gradient(90deg, #03a631 0%, #d6c10f 51%, #03a631 100%);
}

/*--------------------------------------------------------------
# Call to Action
--------------------------------------------------------------*/

.cta-one {
    position: relative;
    background-color: #fff;
    z-index: 10;
    padding-top: 65px;
}

[class*=cta-one__circle-] {
    position: absolute;
    opacity: 0.03;
    border-radius: 50%;
    background-color: var(--thm-black);
}

.cta-one__circle-1 {
    width: 999px;
    height: 999px;
    left: calc(50% - 499.5px);
    bottom: calc(0% - 499.5px);
}

.cta-one__circle-2 {
    width: 792px;
    height: 792px;
    left: calc(50% - 396px);
    bottom: calc(0% - 396px);
}

.cta-one__circle-3 {
    width: 613px;
    height: 613px;
    left: calc(50% - 306.5px);
    bottom: calc(0% - 306.5px);
}

.cta-one .container {
    position: relative;
    padding-top: 80px;
    padding-bottom: 120px;
}

.cta-one__content {
    position: relative;
}

.cta-one__content h3 {
    margin: 0;
    color: var(--thm-black);
    font-weight: 800;
    font-size: 46px;
}

.cta-one__content p {
    margin: 0;
    color: #7c7d8a;
    font-weight: 300;
    font-size: 24px;
    margin-top: 25px;
}

.cta-one__btn {
    background-image: linear-gradient(90deg, #4a0e8f 0%, #b430a7 51%, #4a0e8f 100%);
    margin-top: 55px;
}

.cta-two {
    background-color: #1177f7;
    padding-top: 65px;
    padding-bottom: 70px;
    position: relative;
    margin-bottom: 0;
}

.cta-two .container {
    position: relative;
}

.service-inner {
    background: #0f2642;
    padding: 70px 40px 40px 40px;
    box-shadow: 0px 10px 20px #e083018a;
    border-radius: 10px;
}

.service-one__single img {
    height: 100px;
    padding-top: 0;
}

[class*=cta-two__shape-] {
    position: absolute;
}

.cta-two__shape-1 {
    top: -8%;
    left: 7%;
    -webkit-animation-name: bubbleMover;
    animation-name: bubbleMover;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    animation-duration: 12s;
}

.cta-two__shape-2 {
    bottom: -6%;
    right: 9%;
    -webkit-animation-name: bubbleMover;
    animation-name: bubbleMover;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    animation-duration: 8s;
}

.cta-two__shape-3 {
    top: 5%;
    right: 3%;
    -webkit-animation-name: bubbleMover;
    animation-name: bubbleMover;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    animation-duration: 8s;
}

.cta-two__moc {
    position: absolute;
    top: 30px;
    right: -30px;
    animation: ImgBounce 10s ease-in-out 0s infinite alternate;
}

.cta-two__content .block-title {
    margin-bottom: 40px;
}

.cta-two__content>p {
    margin: 0;
    font-size: 20px;
    /* font-weight: 500; */
    line-height: 33px;
    color: #bfd9fa;
    margin-bottom: 20px;
    margin-top: 22px;
    font-family: poppins;
}

.cta-two__content>.cta-two__btn {
    padding: 12px 25px;
}

.service-one .cta-two__content img {
    margin-bottom: 5px;
}

.cta-three {
    position: relative;
    padding-bottom: 120px;
}

.cta-three .container {
    position: relative;
}

[class*=cta-three__shape-] {
    position: absolute;
}

.cta-three__shape-1 {
    top: -14%;
    left: -1%;
}

.cta-three__shape-2 {
    top: -8%;
    right: 0;
}

.cta-three__shape-3 {
    bottom: 15%;
    right: 0;
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-name: banner3Shake;
    animation-name: banner3Shake;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    -webkit-animation-duration: 5s;
    animation-duration: 5s;
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
}

.cta-three__moc {
    position: absolute;
    top: -45px;
    left: -47px;
    animation: ImgBounce 10s ease-in-out 0s infinite alternate;
}

.cta-three__content {
    padding-left: 70px;
}

.cta-three__box {
    display: flex;
    position: relative;
}

.cta-three__box+.cta-three__box {
    margin-top: 40px;
}

.cta-three__box::before {
    content: '';
    width: 1px;
    height: 110%;
    background-color: #e8e8ef;
    position: absolute;
    left: 37.5px;
    top: 75px;
}

.cta-three__box:last-child::before {
    display: none;
}

.cta-three__box-icon i {
    width: 75px;
    background-image: linear-gradient(90deg, #4a0e8f 0%, #b430a7 51%, #4a0e8f 100%);
    height: 75px;
    border-radius: 50%;
    font-size: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #fff;
    position: relative;
    top: 5px;
    background-size: 200% auto;
    transition: all 500ms ease;
}

.cta-three__box:hover .cta-three__box-icon i {
    background-position: right center;
}

.cta-three__box:nth-child(1) .cta-three__box-icon i {
    background-image: linear-gradient(90deg, #4a0e8f 0%, #b430a7 51%, #4a0e8f 100%);
}

.cta-three__box:nth-child(2) .cta-three__box-icon i {
    background-image: linear-gradient(90deg, #4453ac 0%, #03a9db 51%, #4453ac 100%);
}

.cta-three__box-content {
    padding-left: 25px;
}

.cta-three__box-content h3 {
    margin: 0;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 15px;
    color: var(--thm-black);
}

.cta-three__box-content p {
    margin: 0;
    font-size: 18px;
    line-height: 34px;
    font-weight: 500;
}

.cta-three__btn {
    margin-top: 50px;
}

/*--------------------------------------------------------------
# Main Footer
--------------------------------------------------------------*/

.site-footer {
    background-color: #0f2642;
    position: relative;
    overflow: hidden;
    border-top: 4px solid #df8300;
}

[class*=site-footer__shape-] {
    position: absolute;
}

.site-footer__shape-1 {
    top: -2.5%;
    left: 3%;
    -webkit-animation-name: shapeMover;
    animation-name: shapeMover;
    -webkit-animation-duration: 9s;
    animation-duration: 9s;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    animation-delay: 0s;
}

.site-footer__shape-2 {
    bottom: 15%;
    right: 4%;
    -webkit-animation-name: shapeMover;
    animation-name: shapeMover;
    -webkit-animation-duration: 9s;
    animation-duration: 9s;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    animation-delay: 2s;
}

.site-footer__shape-3 {
    position: absolute;
    left: calc(50% - 231.5px);
    bottom: calc(0% - 231px);
    opacity: 0.15;
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-name: bounce;
    animation-name: bounce;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    -webkit-animation-duration: 5s;
    animation-duration: 5s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
}

.site-footer .container {
    position: relative;
    z-index: 20;
}

.site-footer__upper {
    padding-bottom: 60px;
    padding-top: 20px;
}

/*footer widgets*/

.footer-widget__title {
    margin: 0;
    font-weight: 600;
    font-size: 20px;
    color: #fff;
    margin-bottom: 40px;
}

.footer-widget p {
    margin: 0;
    font-size: 16px;
    line-height: 32px;
    font-weight: 500;
    color: #a3a4b3;
}

.footer-widget p+p {
    margin-top: 25px;
}

.footer-widget p a {
    color: var(--thm-base);
    transition: all 500ms ease;
}

.footer-widget p a:hover {
    color: #fff;
}

.footer-widget__logo {
    display: inline-block;
    margin-top: 0;
    margin-bottom: 0;
}

@media(min-width: 992px) {
    .footer-widget__links-wrap {
        padding-right: 0px;
    }

    .footer-widget__about {
        padding-right: 0px;
    }
}

.footer-widget__links ul {
    margin-bottom: 0;
    display: flex;
    justify-content: space-evenly;
    margin-top: 15px;
}

.footer-widget__links ul li {
    line-height: 1em;
    font-size: 16px;
    font-weight: 500;
}

.footer-widget__links ul li+li {
    margin-top: 0px;
}

.footer-widget__links ul li a {
    color: #9fcbfe;
    display: block;
    position: relative;
    transition: all 500ms ease;
    font-size: 18px;
}

.footer-widget__links ul li a:hover {
    color: var(--thm-base);
    transform: translateX(20px);
}

.footer-widget__links ul li a::before {
    content: '\f101';
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    font-size: 14px;
    color: var(--thm-base);
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-40%) translateX(-20px);
    opacity: 0;
    transition: all 500ms ease;
}

.footer-widget__links ul li a:hover::before {
    opacity: 1;
}

.footer-widget__mc-form input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]) {
    display: block;
    width: 100%;
    border: none;
    outline: none;
    background-color: #fff;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    border-radius: 5px;
    height: 70px;
}

.footer-widget__mc-form ::placeholder {
    color: #7c7d8a;
    opacity: 1;
}

.footer-widget__mc-btn {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    margin-top: 10px;
}

/* footer bottom */

.site-footer__bottom {
    border-top: 1px solid rgba(255, 255, 255, .1);
    background: #0273fa;
}

.site-footer__bottom .inner-container {
    border-top: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 15px;
    padding-bottom: 15px;
}

.site-footer__bottom .inner-container>p {
    margin: 0;
    font-size: 17px;
    font-weight: 500;
    color: #fff;
    text-transform: capitalize;
}

.site-footer__social {
    display: flex;
    align-items: center;
    justify-content: center;
}

.site-footer__social a {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: linear-gradient(90deg, #0349a6 0%, #15c27b 51%, #0349a6 100%);
    color: #fff;
    font-size: 16px;
    background-size: 200% auto;
    transition: all 500ms ease;
}

.site-footer__social a:hover {
    background: #df8300;
}

.site-footer__social a+a {
    margin-left: 10px;
}

.site-footer__social .fa-facebook-f {
    background: #0f2642;
}

.site-footer__social .fa-twitter {
    background: #0f2642;
}

.site-footer__social .fa-instagram {
    background: #0f2642;
}

.site-footer__social .fa-pinterest-p {
    background: #0f2642;
}

/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/

.contact-one {
    padding: 60px 0;
    position: relative;
    background: var(--thm-gray);
}

.contact-one .container {
    position: relative;
    z-index: 11;
}

[class*=contact-one__shape-] {
    position: absolute;
}

.whatsapp-btn .contact-one__form-btn,
.show-all-btn .toggle {
    background: #1177f6;
    padding: 16px 25px;
    font-family: poppins;
    font-size: 17px;
}

.show-all-btn .toggle {
    border: medium;
    border-radius: 10px;
    color: #fff;
    transition: all .4s ease;
}

.contact-one__shape-1 {
    bottom: -241px;
    left: 0;
    z-index: 11;
}

.contact-one__shape-2 {
    right: 2%;
    top: -62px;
    -webkit-animation-name: bubbleMover;
    animation-name: bubbleMover;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    animation-duration: 8s;
}

.contact-one__shape-3 {
    right: -180px;
    top: 70px;
    z-index: 11;
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-name: bounce;
    animation-name: bounce;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    -webkit-animation-duration: 5s;
    animation-duration: 5s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    width: 350px;
}

@media(min-width: 992px) {
    .contact-one__content {
        padding-right: 30px;
    }
}

.contact-one__images {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.contact-one__infos {
    margin-top: 55px;
}

.contact-one__infos-single {
    display: flex;
    align-items: center;
}

.contact-one__infos-single+.contact-one__infos-single {
    margin-top: 35px;
}

.contact-one__infos-icon i {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 18px;
    background-image: linear-gradient(90deg, #0f2642 0%, #1077f7 100%);
    margin-right: 20px;
}

.contact-one__infos-single:nth-child(2) .contact-one__infos-icon i {
    background-image: linear-gradient(90deg, #4a0e8f 0%, #b430a7 100%);
}

.contact-one__infos-single:nth-child(3) .contact-one__infos-icon i {
    background-image: linear-gradient(90deg, #e41256 0%, #ffa337 100%);
}

.contact-one__infos-single h3 {
    margin: 0;
    color: var(--thm-black);
    font-size: 20px;
    font-weight: 600;
    line-height: 1em;
}

.contact-one__infos-single p {
    font-weight: 300;
    font-size: 22px;
    line-height: 36px;
    margin: 0;
    margin-top: 5px;
}

.contact-one__infos-single p a {
    color: inherit;
    transition: all 500ms ease;
}

.contact-one__infos-single p a:hover {
    color: var(--thm-base);
}

.contact-one__form .row {
    margin-left: -5px;
    margin-right: -5px;
}

.contact-one__form .row>[class*=col-] {
    padding-left: 5px;
    padding-right: 5px;
}

.contact-one__form input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]),
.contact-one__form textarea {
    display: block;
    border: none;
    width: 100%;
    margin-bottom: 10px;
    background-color: var(--thm-gray);
    font-size: 16px;
    font-weight: 500;
    color: #7c7d8a;
    padding-left: 30px;
    height: 68px;
}

.contact-one__form textarea {
    height: 188px;
    padding-top: 20px;
}

.contact-one__form ::placeholder {
    opacity: 1;
    color: #7c7d8a;
}

.contact-one__form .bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
    width: 100%;
}

.contact-one__form .bootstrap-select>.dropdown-toggle {
    border-radius: 0;
    padding-left: 30px;
    margin-bottom: 10px;
    background-color: var(--thm-gray);
    font-size: 16px;
    font-weight: 500;
    color: #7c7d8a;
    padding-left: 30px;
    height: 68px;
    padding-right: 30px;
}

.contact-one__form .bootstrap-select .dropdown-toggle .filter-option {
    display: flex;
    align-items: center;
}

.contact-one__form label.error {
    display: block;
}

/*--------------------------------------------------------------
# Blog
--------------------------------------------------------------*/

.blog-one__home {
    padding-top: 120px;
    padding-bottom: 60px;
    background-color: var(--thm-gray);
    position: relative;
}

.blog-one__grid {
    padding-bottom: 120px;
}

.blog-one__shape-1 {
    position: absolute;
    top: 6%;
    right: 8%;
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-name: banner3Shake;
    animation-name: banner3Shake;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    -webkit-animation-duration: 5s;
    animation-duration: 5s;
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
}

.blog-one .container {
    position: relative;
}

.blog-one__single {
    margin-bottom: 90px;
}

.blog-one__image {
    background-image: linear-gradient(90deg, #4a0e8f 0%, #b430a7 51%, #4a0e8f 100%);
    padding: 10px;
    position: relative;
    background-size: 200% auto;
    overflow: hidden;
}

.blog-one__image>img {
    width: 100%;
    transform: scale(1);
    transition: all 500ms ease;
}

.blog-one__single:hover .blog-one__image {
    background-position: right center;
}

.blog-one__single:hover .blog-one__image>img {
    opacity: 0.5;
    transform: scale(1.1);
}

.blog-one__content {
    padding-top: 35px;
    padding-bottom: 45px;
    padding-left: 50px;
    padding-right: 50px;
    position: relative;
    box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.05);
    background-color: #fff;
}

@media(max-width: 375px) {
    .blog-one__content {
        padding-top: 30px;
        padding-bottom: 30px;
        padding-left: 25px;
        padding-right: 25px;
    }
}

.blog-one__meta {
    display: flex;
    margin: 0;
    align-items: center;
}

.blog-one__meta li a {
    color: #7c7d8a;
    font-size: 14px;
    display: flex;
    align-items: center;
    transition: all 500ms ease;
}

.blog-one__meta li a i {
    color: #ee464b;
    margin-right: 3px;
}

.blog-one__meta li+li {
    margin-left: 10px;
}

.blog-one__meta li a:hover {
    color: var(--thm-black);
}

.blog-one__link {
    display: block;
    width: 68px;
    height: 68px;
    background-image: linear-gradient(90deg, #4a0e8f 0%, #b430a7 51%, #4a0e8f 100%);
    display: flex;
    text-align: center;
    font-size: 18px;
    align-items: center;
    justify-content: center;
    color: #fff;
    position: absolute;
    bottom: -34px;
    right: 10px;
    transition: all 500ms ease;
    background-size: 200% auto;
}

.blog-one__link:hover {
    color: #fff;
    background-position: right center;
}

@media(max-width: 375px) {
    .blog-one__link {
        width: 50px;
        height: 50px;
        bottom: -25px;
        font-size: 14px;
    }
}

.blog-one__content h3 {
    color: var(--thm-black);
    margin: 0;
    font-size: 26px;
    line-height: 40px;
    font-weight: 600;
}

@media(max-width: 375px) {
    .blog-one__content h3 {
        font-size: 22px;
        line-height: 1.3em;
    }
}

.blog-one__content h3 a {
    transition: all 500ms ease;
    color: inherit;
}

.blog-one__content h3 a:hover {
    color: var(--thm-base);
}

.blog-one [class*=col-]:nth-child(1) .blog-one__image,
.blog-one [class*=col-]:nth-child(1) .blog-one__link {
    background-image: linear-gradient(90deg, #4a0e8f 0%, #b430a7 51%, #4a0e8f 100%);
}

.blog-one [class*=col-]:nth-child(2) .blog-one__image,
.blog-one [class*=col-]:nth-child(2) .blog-one__link {
    background-image: linear-gradient(90deg, #e41256 0%, #ffa337 51%, #e41256 100%);
}

.blog-one [class*=col-]:nth-child(3) .blog-one__image,
.blog-one [class*=col-]:nth-child(3) .blog-one__link {
    background-image: linear-gradient(90deg, #4453ac 0%, #03a9db 51%, #4453ac 100%);
}

.blog-one [class*=col-]:nth-child(4) .blog-one__image,
.blog-one [class*=col-]:nth-child(4) .blog-one__link {
    background-image: linear-gradient(90deg, #0349a6 0%, #15c27b 51%, #0349a6 100%);
}

.blog-one [class*=col-]:nth-child(5) .blog-one__image,
.blog-one [class*=col-]:nth-child(5) .blog-one__link {
    background-image: linear-gradient(90deg, #272839 0%, #717284 51%, #272839 100%);
}

.blog-one [class*=col-]:nth-child(6) .blog-one__image,
.blog-one [class*=col-]:nth-child(6) .blog-one__link {
    background-image: linear-gradient(90deg, #03a631 0%, #d6c10f 51%, #03a631 100%);
}

/*--------------------------------------------------------------
## Blog Details
--------------------------------------------------------------*/

.blog-details {
    padding-top: 120px;
    padding-bottom: 120px;
}

.blog-details__content h3 {
    margin: 0;
    color: var(--thm-black);
    font-size: 40px;
    font-weight: 600;
    margin-bottom: 35px;
}

.blog-details__image {
    margin-bottom: 20px;
    padding: 10px;
    background-image: linear-gradient(90deg, #e41256 0%, #ffa337 51%, #e41256 100%);
}

.blog-details__image>img {
    width: 100%;
}

.blog-details__content p {
    margin: 0;
}

.blog-details__content p+p {
    margin-top: 40px;
}

.blog-details__content .blog-one__meta li a {
    font-size: 18px;
}

.blog-details__bottom {
    border-top: 1px solid #e8e8ef;
    padding-top: 30px;
    padding-bottom: 30px;
    margin-top: 50px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.blog-details__tags {
    margin: 0;
    display: flex;
    align-items: center;
}

.blog-details__tags span {
    font-size: 20px;
    font-weight: 600;
    color: var(--thm-black);
}

.blog-details__tags a {
    color: #7c7d8a;
    font-size: 16px;
    margin-left: 5px;
    transition: all 500ms ease;
}

.blog-details__tags a:hover {
    color: var(--thm-base);
}

.author-one {
    padding: 60px;
    background-color: var(--thm-gray);
    position: relative;
    margin-bottom: 60px;
}

.author-one__image,
.author-one__content {
    display: table-cell;
    vertical-align: middle;
}

.author-one__image {
    width: 170px;
}

.author-one__content {
    padding-left: 40px;
}

.author-one__content h3 {
    margin: 0;
    color: var(--thm-black);
    font-weight: 600;
    font-size: 20px;
}

.author-one__content p {
    margin: 0;
    font-size: 18px;
    line-height: 34px;
    font-weight: 500;
    color: #7c7d8a;
    margin-top: 40px;
}

.comment-one__title,
.comment-form__title {
    margin: 0;
    color: var(--thm-black);
    font-size: 26px;
    font-weight: 800;
    margin-bottom: 55px;
}

.comment-one__single {
    position: relative;
    border-bottom: 1px solid #e8e8ef;
    margin-bottom: 60px;
    padding-bottom: 50px;
}

.comment-one__image,
.comment-one__content {
    display: table-cell;
    vertical-align: top;
}

.comment-one__image {
    width: 90px;
}

.comment-one__image>img {
    width: 100%;
    border-radius: 50%;
}

.comment-one__content {
    padding-left: 40px;
    padding-right: 105px;
}

.comment-one__date {
    display: flex;
    color: var(--thm-base);
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 25px;
}

.comment-one__date span {
    margin-left: 10px;
    margin-right: 10px;
}

.comment-one__content h3 {
    margin: 0;
    font-size: 20px;
    color: var(--thm-black);
    font-weight: 600;
}

.comment-one__content p {
    margin: 0;
    font-size: 18px;
    line-height: 34px;
    color: #7c7d8a;
    font-weight: 500;
}

.comment-one__btn {
    padding: 8px 29.5px;
    position: absolute;
    top: 0;
    right: 0;
}

/*--------------------------------------------------------------
# Page Header
--------------------------------------------------------------*/

.page-header {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    position: relative;
    padding-top: 245px;
    padding-bottom: 190px;
}

@media(max-width: 991px) {
    .page-header {
        padding-top: 180px;
        padding-bottom: 120px;
    }
}

.page-header::before {
    content: '';
    width: 100%;
    height: 200px;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: var(--thm-gray);
    clip-path: polygon(71.5% 0, 0% 100%, 100% 100%);
}

@media(max-width: 991px) {
    .page-header::before {
        height: 80px;
    }
}

.page-header__white-curv::before {
    background-color: #fff;
}

.page-header .container {
    position: relative;
}

.page-header h2 {
    margin: 0;
    color: #fff;
    text-transform: uppercase;
    font-size: 70px;
    font-weight: 300;
    line-height: 1em;
}

/*--------------------------------------------------------------
# Sidemenu
--------------------------------------------------------------*/

.side-menu__block {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    -webkit-transform: scale(0, 1);
    transform: scale(0, 1);
    -webkit-transform-origin: left center;
    transform-origin: left center;
    -webkit-transition: -webkit-transform .7s ease;
    transition: -webkit-transform .7s ease;
    transition: transform .7s ease;
    transition: transform .7s ease, -webkit-transform .7s ease;
}

.side-menu__block.active {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
}

.side-menu__block-overlay {
    width: 100%;
    height: 100%;
    background-color: var(--thm-black);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    opacity: .7;
}

.side-menu__block-overlay .cursor-follower {
    background-color: rgba(var(--thm-black-rgb), 0.3);
}

.side-menu__block-inner {
    position: relative;
    max-width: 532px;
    width: 100%;
    margin-right: auto;
    height: 100vh;
    background-color: var(--thm-gray);
    z-index: 999999;
    overflow-y: auto;
    padding: 40px 0;
    padding-top: 20px;
    position: relative;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    align-content: space-between;
}

@media(max-width: 575px) {
    .side-menu__block-inner {
        max-width: 480px;
    }
}

@media(max-width: 480px) {
    .side-menu__block-inner {
        max-width: 400px;
    }
}

@media(max-width: 375px) {
    .side-menu__block-inner {
        max-width: 300px;
    }
}

.side-menu__block-inner .mCustomScrollBox {
    width: 100%;
}

.side-menu__top {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding-left: 100px;
    padding-right: 40px;
}

.mobile-nav__container {
    padding-left: 100px;
    padding-right: 100px;
    padding-top: 50px;
}

.mobile-nav__container ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.mobile-nav__container li.dropdown ul {
    display: none;
    padding-left: 0.5em;
}

.mobile-nav__container li.dropdown {
    position: relative;
}

.mobile-nav__container li.dropdown .dropdown-btn {
    border: none;
    outline: none;
    background-color: transparent;
    color: #9ca3a9;
    font-size: 14px;
    padding-left: 10px;
    padding-right: 10px;
    width: auto;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-transition: transfrom 500ms ease;
    transition: transfrom 500ms ease;
}

.mobile-nav__container li.dropdown .dropdown-btn.open {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
}

.mobile-nav__container li+li {
    margin-top: 10px;
}

.mobile-nav__container li a {
    text-transform: uppercase;
    color: #9ca3a9;
    font-size: 18px;
    font-weight: 500;
    display: block;
    font-family: var(--thm-font);
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
    position: relative;
}

.mobile-nav__container li a:hover {
    color: var(--thm-base);
}

.mobile-nav__container li.current-menu-item>a,
.mobile-nav__container li.current>a {
    color: #e08401;
}

.side-menu__social {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    margin-top: 80px;
    margin-bottom: 40px;
}

.side-menu__social a {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: linear-gradient(90deg, #0349a6 0%, #15c27b 51%, #0349a6 100%);
    color: #fff;
    font-size: 16px;
    background-size: 200% auto;
    transition: all 500ms ease;
}

.side-menu__social a:hover {
    background-position: right center;
}

.side-menu__social a+a {
    margin-left: 10px;
}

.side-menu__social .fa-facebook-f {
    background-image: linear-gradient(90deg, #0349a6 0%, #15c27b 51%, #0349a6 100%);
}

.side-menu__social .fa-twitter {
    background-image: linear-gradient(90deg, #4453ac 0%, #03a9db 51%, #4453ac 100%);
}

.side-menu__social .fa-instagram {
    background-image: linear-gradient(90deg, #4a0e8f 0%, #b430a7 51%, #4a0e8f 100%);
}

.side-menu__social .fa-pinterest-p {
    background-image: linear-gradient(90deg, #e41256 0%, #ffa337 51%, #e41256 100%);
}

.side-menu__content {
    padding-left: 100px;
    padding-right: 100px;
}

.side-menu__content p {
    margin: 0;
    font-size: 18px;
    line-height: 30px;
    font-weight: 500;
    color: #7c7d8a;
    letter-spacing: .02em;
}

.side-menu__content p+p {
    margin-top: 30px;
}

.side-menu__content p a {
    color: var(--thm-base);
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
}

.side-menu__content p a:hover {
    color: var(--thm-base);
}

.side-menu__sep {
    width: calc(100% - 200px);
    margin-left: auto;
    margin-right: auto;
    height: 1px;
    background-color: #fff;
    margin-top: 70px;
    margin-bottom: 70px;
}

.side-menu__text p {
    font-size: 16px;
    line-height: 32px;
    font-weight: 500;
    color: #7c7d8a;
    margin: 0;
    margin-bottom: 25px;
}

.side-menu__text a {
    color: var(--thm-base);
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
}

.side-menu__text a:hover {
    color: var(--thm-black);
}

.side-menu__block__copy {
    font-size: 16px;
    font-weight: 500;
    color: #7c7d8a;
    margin: 0;
}

@media(max-width: 575px) {

    .side-menu__top,
    .side-menu__content,
    .mobile-nav__container {
        padding-left: 50px;
        padding-right: 50px;
    }

    .side-menu__sep {
        margin-top: 40px;
        margin-bottom: 40px;
        width: calc(100% - 100px);
    }
}

@media(max-width: 480px) {

    .side-menu__top,
    .side-menu__content,
    .mobile-nav__container {
        padding-left: 25px;
        padding-right: 25px;
    }

    .side-menu__sep {
        width: calc(100% - 50px);
    }

    .side-menu__social {
        margin-top: 40px;
    }

    .side-menu__social a {
        width: 45px;
        height: 45px;
    }
}

/*--------------------------------------------------------------
# Sidebar
--------------------------------------------------------------*/

.sidebar-single:not(.sidebar__search) {
    background-color: var(--thm-gray);
    padding: 50px;
}

.sidebar-single+.sidebar-single {
    margin-top: 30px;
}

.sidebar__title {
    margin: 0;
    color: var(--thm-black);
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 25px;
    line-height: 1em;
}

.sidebar__search-form {
    background-image: linear-gradient(90deg, #4a0e8f 0%, #b430a7 100%);
    display: block;
    width: 100%;
    position: relative;
}

.sidebar__search-form input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]) {
    display: block;
    width: 100%;
    border: none;
    outline: none;
    background-color: transparent;
    color: #fff;
    font-size: 16px;
    padding-left: 50px;
    height: 83px;
}

.sidebar__search-form ::placeholder {
    opacity: 1;
    color: #fff;
}

.sidebar__search-form button[type=submit] {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: auto;
    right: 50px;
    color: #fff;
    border: none;
    outline: none;
    background-color: transparent;
}

.sidebar__post-single {
    position: relative;
    padding-left: 80px;
}

.sidebar__post-single+.sidebar__post-single {
    margin-top: 30px;
}

.sidebar__post-single>img {
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
}

.sidebar__post-single h3 {
    font-size: 16px;
    line-height: 28px;
    color: #7c7d8a;
    margin: 0;
}

.sidebar__post-single h3 a {
    color: inherit;
}

.sidebar__post-single h3 a:hover {
    color: var(--thm-base);
}

.sidebar__cat-list {
    margin: 0;
}

.sidebar__cat-list li {
    line-height: 1em;
}

.sidebar__cat-list li+li {
    margin-top: 20px;
}

.sidebar__cat-list li a {
    color: #7c7d8a;
    font-size: 16px;
    font-weight: 500;
    transition: all 500ms ease;
}

.sidebar__cat-list li a:hover {
    color: var(--thm-base);
}

.sidebar__tags-list {
    margin-top: -10px;
}

.sidebar__tags-list a {
    font-size: 16px;
    line-height: 34px;
    color: #7c7d8a;
    font-weight: 500;
    transition: all 500ms ease;
}

.sidebar__tags-list a:hover {
    color: var(--thm-base);
}

/*--------------------------------------------------------------
# FAQ
--------------------------------------------------------------*/

.faq-one {
    padding: 80px 0;
    position: relative;
    counter-reset: faqNumber;
}

[class*=faq-one__shape-] {
    position: absolute;
}

.faq-one__shape-1 {
    top: -145.5px;
    left: 4%;
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-name: banner3Shake;
    animation-name: banner3Shake;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    -webkit-animation-duration: 5s;
    animation-duration: 5s;
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
}

.faq-one__shape-3 {
    left: 4%;
    bottom: -4%;
    z-index: 11;
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-name: banner3Shake;
    animation-name: banner3Shake;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    -webkit-animation-duration: 5s;
    animation-duration: 5s;
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
}

.faq-one__shape-2 {
    top: 0;
    right: 0;
}

.faq-one .container {
    position: relative;
}

@media (min-width: 992px) {
    .faq-one .container {
        max-width: 1000px;
    }
}

.faq-accrodion .accrodion {
    position: relative;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
    counter-increment: faqNumber;
}

.faq-accrodion .accrodion:before {
    content: '';
    position: absolute;
    top: -1px;
    bottom: -1px;
    left: 0px;
    right: 0px;
    border-radius: 0px;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    background-image: linear-gradient(90deg, #4a0e8f 0%, #b330a7 100%);
    -webkit-transition: -webkit-transform .4s ease;
    transition: -webkit-transform .4s ease;
    transition: transform .4s ease;
    transition: transform .4s ease, -webkit-transform .4s ease;
}

.faq-accrodion .accrodion::after {
    font-family: var(--thm-font);
    font-size: 60px;
    line-height: 1em;
    color: #e8e8ef;
    font-weight: 300;
    content: counters(faqNumber, ".", decimal-leading-zero);
    position: absolute;
    top: 11px;
    left: 0;
    transform: translateX(-50%);
    z-index: 11;
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
}

.faq-accrodion .accrodion+.accrodion {
    margin-top: 20px;
}

.faq-accrodion .accrodion.active {
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.04);
}

.faq-accrodion .accrodion.active .accrodion-inner {
    margin: 0 1px;
}

.faq-accrodion .accrodion.active:before {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
}

.faq-accrodion .accrodion.active .accrodion-title {
    padding-bottom: 0;
}

.faq-accrodion .accrodion.active .accrodion-title h4 {
    color: #df8300;
}

.faq-accrodion .accrodion .accrodion-inner {
    background-color: #fff;
    padding: 30.5px 0;
    border-style: solid;
    border-width: 1px;
    border-color: #e8e8ef;
    border-radius: 0px;
    position: relative;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
}

.faq-accrodion .accrodion .accrodion-title {
    padding: 0px 60px;
    cursor: pointer;
    position: relative;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
}

@media (max-width: 375px) {
    .faq-accrodion .accrodion .accrodion-title {
        padding-right: 50px;
    }
}

.faq-accrodion .accrodion .accrodion-title::before {
    content: '\f067';
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    font-size: 14px;
    color: #ffffff;
    text-align: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: absolute;
    top: 50%;
    right: 60px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    opacity: 1;
    background-image: linear-gradient(90deg, #0f2642 0%, #1077f7 100%);
    transition: all 500ms ease;
}

.faq-accrodion .accrodion .accrodion-title::after {
    content: '\f068';
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    font-size: 14px;
    color: #ffffff;
    text-align: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: absolute;
    top: 50%;
    right: 60px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    opacity: 0;
    background-image: linear-gradient(90deg, #e41256 0%, #ffa337 100%);
    transition: all 500ms ease;
}

.faq-accrodion .accrodion.active .accrodion-title::before {
    opacity: 0;
}

.faq-accrodion .accrodion.active .accrodion-title::after {
    opacity: 1;
}

.faq-accrodion .accrodion .accrodion-title h4 {
    margin: 0;
    color: #30283d;
    font-size: 27px;
    font-weight: 600;
}

@media (max-width: 375px) {
    .faq-accrodion .accrodion .accrodion-title h4 {
        line-height: 1.4em;
    }
}

.faq-accrodion .accrodion .accrodion-content .inner {
    /* padding: 0 60px; */
    /* padding-right: 0; */
    /* padding-top: 15px; */
    /* padding-bottom: 0px; */
    padding: 15px 60px 0px;
}

.faq-accrodion .accrodion .accrodion-content p {
    margin: 0;
    color: #0f2847;
    font-size: 16px;
    line-height: normal;
    font-weight: 500;
    font-family: poppins;
    margin-bottom: 11px;
}

.faq-accrodion .accrodion .accrodion-content h4 {
    margin: 0;
    color: #de8200;
    font-size: 18px;
    line-height: normal;
    font-weight: 700;
    /* font-family: poppins; */
    margin-bottom: 11px;
    border-bottom: 3px solid;
    display: inline-block;
    padding-bottom: 4px;
}

/*--------------------------------------------------------------
# Brand
--------------------------------------------------------------*/

.brand-one {
    position: relative;
    z-index: 11;
    padding-top: 50px;
    padding-bottom: 60px;
    background-image: linear-gradient(90deg, #0f2642 0%, #1077f7 100%);
    box-shadow: 0px 10px 20px #e083018a;
}

.brand-one__home-one p {
    font-size: 30PX;
    line-height: 35px;
    margin-top: 20px;
}

.brand-one__home-one .brand-one__carousel {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.brand-one__carousel .item {
    background: #fff;
    border-radius: 20px;
    padding: 25px;
}

.brand-one img {
    height: 50px;
    transition: all 500ms ease;
}

.brand-one img:hover {
    opacity: 1;
}

/*--------------------------------------------------------------
# Testimonials
--------------------------------------------------------------*/

.testimonials__one {
    background-color: #f4f4f8;
    padding: 120px 0;
    position: relative;
}

[class*=testimonials__one-shape-] {
    position: absolute;
}

.testimonials__one-shape-1 {
    top: -43px;
    left: 2%;
    -webkit-animation-name: bubbleMover;
    animation-name: bubbleMover;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    animation-duration: 8s;
}

.testimonials__one-shape-2 {
    top: 6%;
    right: 6%;
    -webkit-animation-name: shapeMover;
    animation-name: shapeMover;
    -webkit-animation-duration: 9s;
    animation-duration: 9s;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
}

.testimonials__one .map-img {
    position: absolute;
    top: 50%;
    left: 10%;
    transform: translateY(-50%);
    animation-name: mapMover;
    animation-duration: 5s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    transition: all .4s ease;
    transform-origin: center center;
}

@keyframes mapMover {
    0% {
        transform: translateY(-50%) translateX(0);
    }

    50% {
        transform: translateY(-55%) translateX(-20px);
    }

    100% {
        transform: translateY(-50%) translateX(0);
    }
}

.testimonials__one .block-title {
    margin-bottom: 40px;
}

.testimonials__one .testimonials__one__single p {
    margin: 0;
    color: #7c7d8a;
    font-size: 24px;
    line-height: 46px;
    font-weight: 300;
}

.testimonials__one .testimonials__one__single h3 {
    color: #ee464b;
    font-weight: 600;
    font-size: 20px;
    margin: 0;
    margin-top: 40px;
}

.testimonials__one .testi-carousel-one .owl-nav {
    margin: 0;
    position: absolute;
    bottom: -10px;
    right: 0;
    background-color: #F2FFF8;
}

.testimonials__one .testi-carousel-one .owl-nav [class*=owl-] {
    margin: 0px 5px;
    padding: 0;
    background-color: transparent;
    opacity: .3;
    color: var(--thm-black);
    font-size: 32px;
    transition: all .4s ease;
}

.testimonials__one .testi-carousel-one .owl-nav [class*=owl-]:hover {
    opacity: 1;
}

.testimonials__one .bx-wrapper {
    border: none;
    box-shadow: none;
    background-color: transparent;
    margin-bottom: 0;
}

.testimonials__one .bx-wrapper ul.slider {
    padding: 0;
    margin: 0;
}

.testimonials__one .bx-wrapper .bx-controls-direction {
    position: absolute;
    bottom: -10px;
    background-color: #f4f4f8;
    right: 0;
}

.testimonials__one .bx-wrapper .bx-controls-direction a {
    text-indent: 0;
    background-image: none;
    font-size: 32px;
    position: relative;
    top: 0px;
    opacity: .3;
    color: var(--thm-black);
    transition: all .4s ease;
}

.testimonials__one .bx-wrapper .bx-controls-direction a:hover {
    opacity: 1;
}

.testimonials__one .bx-wrapper .bx-prev {
    left: 0px;
    margin-right: 25px;
}

.testimonials__one .bx-wrapper .bx-next {
    right: 0px;
}

#testimonials-slider-pager {
    width: 474px;
    height: 474px;
    border-radius: 50%;
    position: relative;
    /* background-image: url('/public/home/images/shapes/testi-circle.png'); */
    background-position: center center;
    background-repeat: no-repeat;
    animation-name: tesiMove;
    animation-duration: 5s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    transition: all .4s ease;
    transform-origin: center center;
}

@keyframes tesiMove {
    0% {
        transform: rotate(0deg);
    }

    15% {
        transform: rotate(2deg);
    }

    33% {
        transform: rotate(4deg);
    }

    66% {
        transform: rotate(2deg);
    }

    100% {
        transform: rotate(0deg);
    }
}

#testimonials-slider-pager .testimonials-slider-pager-one .pager-item {
    width: 71px;
    height: 71px;
    display: inline-block;
    vertical-align: middle;
    position: absolute;
}

#testimonials-slider-pager .testimonials-slider-pager-one .pager-item:nth-child(1) {
    top: 70px;
    left: 10px;
}

#testimonials-slider-pager .testimonials-slider-pager-one .pager-item:nth-child(2) {
    top: 70px;
    right: 10px;
}

#testimonials-slider-pager .testimonials-slider-pager-one .pager-item:nth-child(3) {
    bottom: 70px;
    left: 10px;
}

#testimonials-slider-pager .testimonials-slider-pager-one .pager-item:nth-child(4) {
    bottom: 70px;
    right: 10px;
}

#testimonials-slider-pager .testimonials-slider-pager-one .pager-item:nth-child(5) {
    bottom: -35px;
    left: 50%;
    transform: translateX(-50%);
}

#testimonials-slider-pager .testimonials-slider-pager-one .pager-item:nth-child(6) {
    top: -35px;
    left: 50%;
    transform: translateX(-50%);
}

#testimonials-slider-pager .testimonials-slider-pager-one .pager-item>img {
    width: 100%;
    border-radius: 50%;
    transition: all .4s ease;
}

#testimonials-slider-pager .testimonials-slider-pager-one .pager-item.active img {
    opacity: .75;
    transform: scale(0.95);
}

#testimonials-slider-pager .testimonials-slider-pager-two .pager-item {
    width: 163px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    opacity: 0;
    transition: all .4s ease;
}

#testimonials-slider-pager .testimonials-slider-pager-two .pager-item.active {
    opacity: 1;
    transform: translate(-50%, -50%) scale(0.95);
}

#testimonials-slider-pager .testimonials-slider-pager-two .pager-item>img {
    width: 100%;
    border-radius: 50%;
}

/*--------------------------------------------------------------
# App Screens
--------------------------------------------------------------*/

.app-shot-one {
    padding-top: 120px;
    padding-bottom: 170px;
    position: relative;
}

[class*=app-shot__shape-] {
    position: absolute;
}

.app-shot__shape-2 {
    top: 3%;
    left: 12%;
    -webkit-animation-name: bubbleMover;
    animation-name: bubbleMover;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    animation-duration: 8s;
}

.app-shot__shape-1 {
    right: -231.5px;
    top: -90px;
    z-index: 11;
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-name: bounce;
    animation-name: bounce;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    -webkit-animation-duration: 5s;
    animation-duration: 5s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
}

.app-shot-one__bg {
    position: absolute;
    bottom: 25%;
    left: 0;
}

.app-shot-one .container-fluid {
    max-width: 1595px;
    width: 100%;
    position: relative;
}

.app-shot-one .app-shot-one__carousel {
    padding-top: 100.5px;
    padding-bottom: 100.5px;
    margin-top: -20px;
}

.app-shot-one .app-shot-one__carousel:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -49%) scaleY(1);
    transform: translate(-50%, -49%) scaleY(1);
    /* background-image: url('/public/home/images/app-shots/app-screen-moc.png'); */
    background-position: center center;
    background-repeat: no-repeat;
    width: 423px;
    height: 739px;
}

@media (max-width: 1440px) {
    .app-shot-one .app-shot-one__carousel:before {
        -webkit-transform: translate(-50%, -49%) scaleY(0.95);
        transform: translate(-50%, -49%) scaleY(0.95);
    }
}

@media (max-width: 1280px) {
    .app-shot-one .app-shot-one__carousel:before {
        -webkit-transform: translate(-50%, -49%) scale(0.8);
        transform: translate(-50%, -49%) scale(0.8);
    }
}

.app-shot-one .app-shot-one__carousel .owl-dots {
    position: absolute;
    bottom: -60px;
    left: 0;
    text-align: center;
    z-index: 10;
    width: 100%;
}

.app-shot-one .app-shot-one__carousel .owl-dots .owl-dot span {
    width: 7px;
    height: 7px;
    background-color: #30283d;
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0.2;
    margin: 0 3px;
    -webkit-transition: opacity .4s ease, background .4s ease, -webkit-transform .4s ease;
    transition: opacity .4s ease, background .4s ease, -webkit-transform .4s ease;
    transition: transform .4s ease, opacity .4s ease, background .4s ease;
    transition: transform .4s ease, opacity .4s ease, background .4s ease, -webkit-transform .4s ease;
}

.app-shot-one .app-shot-one__carousel .owl-dots .owl-dot:hover span,
.app-shot-one .app-shot-one__carousel .owl-dots .owl-dot.active span {
    opacity: 1;
    background-color: #ee464b;
    -webkit-transform: scale(1.285);
    transform: scale(1.285);
}

.app-shot-one .owl-item img {
    -webkit-transition: -webkit-transform .4s ease;
    transition: -webkit-transform .4s ease;
    transition: transform .4s ease;
    transition: transform .4s ease, -webkit-transform .4s ease;
}

/*--------------------------------------------------------------
# Pricing
--------------------------------------------------------------*/

.pricing-one {
    position: relative;
}

.pricing-one__sep {
    width: 100%;
    height: 1px;
    background-color: #e8e8ef;
    display: block;
    margin-bottom: 120px;
}

.pricing-one__single {
    text-align: center;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(232, 232, 239);
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.05);
}

.pricing-one__inner {
    position: relative;
    background-color: #fff;
    overflow: hidden;
    padding-top: 60px;
    padding-bottom: 60px;
}

.pricing-one__icon {
    position: relative;
}

.pricing-one__single h3 {
    margin: 0;
    color: var(--thm-black);
    font-size: 46px;
    font-weight: 500;
    position: relative;
    line-height: 1em;
    margin-top: 20px;
    margin-bottom: 20px;
}

.pricing-one__single p {
    margin: 0;
    font-size: 18px;
    font-weight: 500;
    color: #7c7d8a;
    position: relative;
    line-height: 1em;
}

.pricing-one__line {
    position: absolute;
    top: -145.5px;
    left: calc(50% - 147.5px);
}

.pricing-one__list {
    margin-bottom: 0;
    position: relative;
    border-top: 1px solid #e8e8ef;
    padding-bottom: 55px;
    padding-top: 55px;
    margin-top: 50px;
}

.pricing-one__list li {
    line-height: 1em;
    font-size: 18px;
    font-weight: 500;
    color: #7c7d8a;
}

.pricing-one__list li i {
    color: #ee464b;
    font-size: 14px;
    margin-right: 5px;
}

.pricing-one__list li+li {
    margin-top: 20px;
}

.pricing-one__single span {
    display: block;
    text-transform: uppercase;
    line-height: 1em;
    color: #7c7d8a;
    font-weight: 500;
    position: relative;
    font-size: 12px;
    letter-spacing: .2em;
    margin-top: 20px;
}

.pricing-one__btn {
    position: relative;
}

.pricing-one .block-title {
    margin-bottom: 30px;
}

.pricing-one .switch {
    position: relative;
    display: inline-block;
    width: 79px;
    height: 36px;
    vertical-align: middle;
    margin: 0;
}

.pricing-one .switch input {
    display: none;
}

.pricing-one .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    -webkit-transition: .4s;
    transition: .4s;
    background-image: linear-gradient(90deg, #4a0e8f 0%, #b430a7 100%);
    box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.03);
}

.pricing-one .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 6px;
    bottom: 6px;
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
    background-color: #fff;
    -webkit-transition: .4s;
    transition: .4s;
}

.pricing-one input:focus+.slider {
    -webkit-box-shadow: 0 0 1px #2196F3;
    box-shadow: 0 0 1px #2196F3;
}

.pricing-one .switch.off .slider:before {
    -webkit-transform: translateX(42px);
    transform: translateX(42px);
}

.pricing-one .slider.round {
    border-radius: 34px;
}

.pricing-one .slider.round:before {
    border-radius: 50%;
}

.pricing-one ul.switch-toggler-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 60px;
}

.pricing-one ul.switch-toggler-list li a {
    font-size: 18px;
    font-weight: 500;
    color: #7c7d8a;
    padding-left: 10px;
    padding-right: 10px;
    display: block;
}

.pricing-one ul.switch-toggler-list li.active a {
    color: var(--thm-black);
}

/*--------------------------------------------------------------
# Funfact
--------------------------------------------------------------*/

.funfact-one {
    padding-top: 60px;
    padding-bottom: 100px;
    position: relative;
}

.funfact-one__single {
    box-shadow: 0px 10px 20px rgba(55, 55, 89, 0.08);
    border-radius: 10px;
    border: 1px solid #f1f1f1;
}

[class*=funfact-one__shape-] {
    position: absolute;
}

.funfact-one__shape-1 {
    top: 20px;
    left: 0;
}

.funfact-one__shape-2 {
    bottom: -10%;
    left: 14%;
    z-index: 11;
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-name: banner3Shake;
    animation-name: banner3Shake;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    -webkit-animation-duration: 5s;
    animation-duration: 5s;
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
}

.funfact-one__shape-3 {
    bottom: -100%;
    right: 4%;
    -webkit-animation-name: shapeMover;
    animation-name: shapeMover;
    -webkit-animation-duration: 9s;
    animation-duration: 9s;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
}

.funfact-one__single {
    text-align: center;
}

.funfact-one__single .coubnder-inner {
    /* line-height: 1em; */
    margin-bottom: 0;
    background-image: linear-gradient(90deg, #1c498a 0%, #1075f5 100%);
    padding: 5px 0;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.funfact-one__single h3 {
    margin: 0;
    font-size: 40px;
    color: #f8fafd;
    font-weight: 600;
    margin-right: 5px;
}

.funfact-one__single h2 {
    margin: 0;
    font-size: 40px;
    color: #f8fafd;
    font-weight: 600;
}

.funfact-one__single p {
    margin: 0;
    font-size: 20px;
    font-weight: 700;
    line-height: 1em;
    color: #272839;
    background-image: linear-gradient(90deg, #df8300 0%, #e6caa3 100%);
    padding: 25px 0;
    font-family: poppins;
}

.funfact-one__single p::before {
    content: '';
    width: 12px;
    height: 12px;
    display: inline-block;
    border-radius: 50%;
    margin-right: 10px;
    background-image: linear-gradient(90deg, #4453ac 0%, #03a9db 100%);
}

.funfact-one [class*=col-]:nth-child(1) .funfact-one__single p::before {
    background-image: linear-gradient(90deg, #4453ac 0%, #03a9db 100%);
}

.funfact-one [class*=col-]:nth-child(2) .funfact-one__single p::before {
    background-image: linear-gradient(90deg, #e41256 0%, #ffa337 100%);
}

.funfact-one [class*=col-]:nth-child(3) .funfact-one__single p::before {
    background-image: linear-gradient(90deg, #4a0e8f 0%, #b430a7 100%);
}

.funfact-one [class*=col-]:nth-child(4) .funfact-one__single p::before {
    background-image: linear-gradient(90deg, #0349a6 0%, #15c27b 100%);
}

/*--------------------------------------------------------------
# Video
--------------------------------------------------------------*/

.video-one__box {
    background-image: linear-gradient(90deg, rgb(74, 14, 143) 0%, rgb(180, 48, 167) 100%);
    position: relative;
}

.video-one__box>img {
    width: 100%;
    opacity: 0.4;
}

.video-one__content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.video-one__content::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(90deg, rgb(74, 14, 143) 0%, rgb(180, 48, 167) 100%);
    opacity: 0.4;
}

.video-one__content h3 {
    font-weight: 800;
    color: #fff;
    font-size: 65px;
    margin: 0;
    position: relative;
}

.video-one__content .video-popup {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: var(--thm-black);
    font-size: 18px;
    position: relative;
    margin-bottom: 40px;
}

.video-one__content .video-popup::before {
    content: '';
    position: absolute;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    border: 2px solid #fff;
    opacity: .4;
    top: calc(50% - 60px);
    left: calc(50% - 60px);
    -webkit-animation: videoBtnAnim 3s ease-in infinite;
    animation: videoBtnAnim 3s ease-in infinite;
}

.video-one__content .video-popup::after {
    content: '';
    position: absolute;
    width: 160px;
    height: 160px;
    border-radius: 50%;
    border: 2px solid #fff;
    opacity: .15;
    top: calc(50% - 80px);
    left: calc(50% - 80px);
    -webkit-animation: videoBtnAnim 5s ease-in-out infinite;
    animation: videoBtnAnim 5s ease-in-out infinite;
}

@keyframes videoBtnAnim {
    0% {
        -webkit-transform: scale(0.8);
        transform: scale(0.8);
    }

    25% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    50% {
        -webkit-transform: scale(1.2);
        transform: scale(1.2);
    }

    75% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    100% {
        -webkit-transform: scale(0.8);
        transform: scale(0.8);
    }
}

@import url(https://fonts.googleapis.com/css?family=Lato:300:400);

body {
    margin: 0;
}

h1 {
    font-family: 'Lato', sans-serif;
    font-weight: 300;
    letter-spacing: 2px;
    font-size: 48px;
}

.header {
    position: relative;
    text-align: center;
    background: linear-gradient(60deg, rgba(84, 58, 183, 1) 0%, rgba(0, 172, 193, 1) 100%);
    color: white;
}

.inner-header {
    height: 65vh;
    width: 100%;
    margin: 0;
    padding: 0;
}

.flex {
    /*Flexbox for containers*/
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.waves {
    position: relative;
    width: 100%;
    height: 15vh;
    margin-bottom: 0px;
    /*Fix for safari gap*/
    min-height: 100px;
    max-height: 150px;
}

/* Animation */

.parallax>use {
    animation: move-forever 25s cubic-bezier(.55, .5, .45, .5) infinite;
}

.parallax>use:nth-child(1) {
    animation-delay: -2s;
    animation-duration: 7s;
}

.parallax>use:nth-child(2) {
    animation-delay: -3s;
    animation-duration: 10s;
}

.parallax>use:nth-child(3) {
    animation-delay: -4s;
    animation-duration: 13s;
}

.parallax>use:nth-child(4) {
    animation-delay: -5s;
    animation-duration: 20s;
}

@keyframes move-forever {
    0% {
        transform: translate3d(-90px, 0, 0);
    }

    100% {
        transform: translate3d(85px, 0, 0);
    }
}

/*Shrinking for mobile*/

@media (max-width: 768px) {
    .waves {
        height: 40px;
        min-height: 40px;
    }

    h1 {
        font-size: 24px;
    }
}

.banner-one__img {
    animation: ImgBounce 10s ease-in-out 0s infinite alternate;
}

@import '/public/home/css/responsive.css';
</style>


<style lang="scss" scoped>
.sections {
    display: block;
}

.mm_option {
    padding: 10px 10px;
}
.mm_active {
    color: #1177f6;
}

@media (max-width: 540px) {
    .sections {
        display: none;
    }
    .scroll-to-target.scroll-to-top {
        visibility: hidden;
    }

    .sections, .section_banner {
        margin-bottom: 50px;
    }
    .banner-one {
        height: auto;
    }
    .section_mobile_menu {
        position: fixed;
        z-index: 99999;
        bottom: 0;
        background-color: #fff;
        padding: 10px;
        width: 100vw;
    }
    .service-one, .section_why_choose, .section_contact {
        margin-top: 50px;
    }
    .section_contact {
        padding-top: 30px;
    }
    .section_why_choose {
        margin-top: 80px;
    }

    .banner-one__content h3 {
        font-size: 25px;
        margin-top: 10px;
        margin-bottom: 5px;
    }
    .banner-one__content p {
        font-size: 14px;
        line-height: 23px;
    }
    .block-title h3 {
        font-size:28px;
    }
    .block-title p {
        font-size: 15px;
        line-height: 21px;
    }
    .service-one .cta-two__content h3 {
        font-size: 28px;
    }
    .service-one__single h3 {
        font-size: 24px;
    }
    .service-one__single p {
        font-size: 18px;
        line-height: 20px;
    }
    .why-choose__list li {
        font-size: 18px;
        padding-bottom: 0;
    }
    .faq-accrodion .accrodion .accrodion-title h4 {
        font-size: 22px;
    }
    .contact-one__infos-single h3 {
        font-size: 18px;
        line-height: 0.8em;
    }
    .contact-one__infos-icon i {
        width: 50px;
        height: 50px;
    }

    body {
        font-size: 12px;
    }

    .main-nav__logo {
        margin:auto;
    }
}
</style>
